import { CircularProgress } from '@mui/material';
import type { FC } from 'react';

import { useStyles } from './styles';

const SceneLoader: FC = () => {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.loader} data-id="loader" data-testid="loader" disableShrink size={120} />
    );
};

export default SceneLoader;
