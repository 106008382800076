import type { ActionTypes as ContainerStatusActionTypes } from '~/data/containerstatus';
import { ActionTypeKeys as ContainerStatusActionTypeKeys } from '~/data/containerstatus';
import type { ActionTypes as DriverHoursActionTypes } from '~/data/driverhours';
import { ActionTypeKeys as DriverHoursActionTypeKeys } from '~/data/driverhours';
import type { ActionTypes as InhibitorStatusActionTypes } from '~/data/inhibitorstatus';
import { ActionTypeKeys as InhibitorStatusActionTypeKeys } from '~/data/inhibitorstatus';
import type { ActionTypes as RouteStatusActionTypes } from '~/data/routestatus';
import { ActionTypeKeys as RouteStatusActionTypeKeys } from '~/data/routestatus';
import type { ActionTypes as TrailerStatusActionTypes } from '~/data/trailerstatus';
import { ActionTypeKeys as TrailerStatusActionTypeKeys } from '~/data/trailerstatus';
import type { ActionTypes as VehicleConnectionsActionTypes } from '~/data/vehicleconnections';
import { ActionTypeKeys as VehicleConnectionsActionTypeKeys } from '~/data/vehicleconnections';
import type { ActionTypes as VehicleStatusActionTypes } from '~/data/vehiclestatus';
import { ActionTypeKeys as VehicleStatusActionTypeKeys } from '~/data/vehiclestatus';
import type { NumericDictionary } from '~/libs/utility';
import { isEmpty, keyBy } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type {
    ContainerStatus,
    DriverStatus,
    InhibitorStatus,
    RouteStatus,
    TrailerStatus,
    VehicleConnection,
    VehicleConnectionsUpdate,
    VehicleDriverHoursStatus,
    VehicleStatus,
} from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

import type { ActionTypes as MonitoringActionTypes } from './actionTypes';
import { ActionTypeKeys as MonitoringActionTypeKeys } from './actionTypes';
import { applyVehicleConnectionsUpdate, mergeRevisionData, mergeVehicleConnectionsUpdates } from './utils';

export interface DynamicMonitoringStoreState {
    containerStatus: RetrievableData<NumericDictionary<ContainerStatus>>;
    driverStatus: RetrievableData<NumericDictionary<DriverStatus>>;
    inhibitorStatus: RetrievableData<NumericDictionary<InhibitorStatus>>;
    routeStatus: { [keys in AssetType]: RetrievableData<NumericDictionary<RouteStatus>> };
    trailerStatus: RetrievableData<NumericDictionary<TrailerStatus>>;
    vehicleConnections: RetrievableData<NumericDictionary<VehicleConnection>>;
    vehicleConnectionsByTrailer: NumericDictionary<VehicleConnection>;
    vehicleDriverHoursStatus: RetrievableData<NumericDictionary<VehicleDriverHoursStatus>>;
    vehicleStatus: RetrievableData<NumericDictionary<VehicleStatus>>;
}

export interface PendingMonitoringStoreState {
    pendingContainerStatusUpdates: NumericDictionary<ContainerStatus>;
    pendingDriversStatusUpdates: NumericDictionary<DriverStatus>;
    pendingInhibitorStatusUpdates: NumericDictionary<InhibitorStatus>;
    pendingRouteStatusUpdates: { [keys in AssetType]?: NumericDictionary<RouteStatus> };
    pendingTrailerStatusUpdates: NumericDictionary<TrailerStatus>;
    pendingVehicleConnectionsUpdate?: VehicleConnectionsUpdate;
    pendingVehicleDriverHoursStatusUpdates: NumericDictionary<VehicleDriverHoursStatus>;
    pendingVehicleStatusUpdates: NumericDictionary<VehicleStatus>;
}

export interface MonitoringStoreState {
    dynamicMonitoring: DynamicMonitoringStoreState;
    pendingMonitoring: PendingMonitoringStoreState;
}

export const defaultStoreState: MonitoringStoreState = {
    dynamicMonitoring: {
        containerStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        driverStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        inhibitorStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        routeStatus: {
            container: { data: {}, fulfilled: false, pending: false, rejected: false },
            driver: { data: {}, fulfilled: false, pending: false, rejected: false },
            trailer: { data: {}, fulfilled: false, pending: false, rejected: false },
            vehicle: { data: {}, fulfilled: false, pending: false, rejected: false },
        },
        trailerStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        vehicleConnections: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        vehicleConnectionsByTrailer: {},
        vehicleDriverHoursStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        vehicleStatus: {
            data: {},
            fulfilled: false,
            pending: false,
            rejected: false,
        },
    },

    pendingMonitoring: {
        pendingContainerStatusUpdates: {},
        pendingDriversStatusUpdates: {},
        pendingInhibitorStatusUpdates: {},
        pendingRouteStatusUpdates: {},
        pendingTrailerStatusUpdates: {},
        pendingVehicleDriverHoursStatusUpdates: {},
        pendingVehicleStatusUpdates: {},
    },
};

type ActionTypes =
    | ContainerStatusActionTypes
    | DriverHoursActionTypes
    | InhibitorStatusActionTypes
    | MonitoringActionTypes
    | RouteStatusActionTypes
    | TrailerStatusActionTypes
    | VehicleConnectionsActionTypes
    | VehicleStatusActionTypes;

export const monitoringReducer = (
    state: MonitoringStoreState = defaultStoreState,
    action: ActionTypes
): MonitoringStoreState => {
    switch (action.type) {
        case DriverHoursActionTypeKeys.DRIVERHOURSBYVEHICLE_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleDriverHoursStatus: {
                        ...state.dynamicMonitoring.vehicleDriverHoursStatus,
                        pending: true,
                    },
                },
            };
        case DriverHoursActionTypeKeys.DRIVERHOURSBYVEHICLE_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleDriverHoursStatus: {
                        data: mergeRevisionData(
                            keyBy(action.payload.items, 'vehicleId'),
                            state.pendingMonitoring.pendingVehicleDriverHoursStatusUpdates
                        ),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingVehicleDriverHoursStatusUpdates: {},
                },
            };
        case DriverHoursActionTypeKeys.DRIVERHOURSBYVEHICLE_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleDriverHoursStatus: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        case DriverHoursActionTypeKeys.DRIVERHOURSBYVEHICLE_UPDATE: {
            const pendingVehicleDriverHoursStatusUpdate =
                state.pendingMonitoring.pendingVehicleDriverHoursStatusUpdates[action.payload.vehicleId];

            if (
                !pendingVehicleDriverHoursStatusUpdate ||
                action.payload.revision > pendingVehicleDriverHoursStatusUpdate.revision
            ) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingVehicleDriverHoursStatusUpdates: {
                            ...state.pendingMonitoring.pendingVehicleDriverHoursStatusUpdates,
                            [action.payload.vehicleId]: action.payload,
                        },
                    },
                };
            }

            return state;
        }
        case VehicleStatusActionTypeKeys.VEHICLESTATUS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleStatus: { ...state.dynamicMonitoring.vehicleStatus, pending: true },
                },
            };
        case VehicleStatusActionTypeKeys.VEHICLESTATUS_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleStatus: {
                        data: mergeRevisionData(
                            keyBy(action.payload.items, 'vehicleId'),
                            state.pendingMonitoring.pendingVehicleStatusUpdates
                        ),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingVehicleStatusUpdates: {},
                },
            };
        case VehicleStatusActionTypeKeys.VEHICLESTATUS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleStatus: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };

        case VehicleStatusActionTypeKeys.VEHICLESTATUS_UPDATE: {
            const pendingUpdate = state.pendingMonitoring.pendingVehicleStatusUpdates[action.payload.vehicleId];

            if (!pendingUpdate || action.payload.revision > pendingUpdate.revision) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingVehicleStatusUpdates: {
                            ...state.pendingMonitoring.pendingVehicleStatusUpdates,
                            [action.payload.vehicleId]: action.payload,
                        },
                    },
                };
            }

            return state;
        }

        case InhibitorStatusActionTypeKeys.INHIBITORSTATUS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    inhibitorStatus: { ...state.dynamicMonitoring.inhibitorStatus, pending: true },
                },
            };
        case InhibitorStatusActionTypeKeys.INHIBITORSTATUS_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    inhibitorStatus: {
                        data: mergeRevisionData(
                            keyBy(action.payload.items, 'vehicleId'),
                            state.pendingMonitoring.pendingInhibitorStatusUpdates
                        ),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingInhibitorStatusUpdates: {},
                },
            };
        case InhibitorStatusActionTypeKeys.INHIBITORSTATUS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    inhibitorStatus: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };

        case InhibitorStatusActionTypeKeys.INHIBITORSTATUS_UPDATE: {
            const pendingUpdate = state.pendingMonitoring.pendingInhibitorStatusUpdates[action.payload.vehicleId];

            if (!pendingUpdate || action.payload.revision > pendingUpdate.revision) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingInhibitorStatusUpdates: {
                            ...state.pendingMonitoring.pendingInhibitorStatusUpdates,
                            [action.payload.vehicleId]: action.payload,
                        },
                    },
                };
            }

            return state;
        }
        case MonitoringActionTypeKeys.MONITORING_CLEAR_DATA:
            return defaultStoreState;

        case MonitoringActionTypeKeys.MONITORING_APPLY_PENDING: {
            let nextState = { ...state };

            if (
                state.dynamicMonitoring.vehicleStatus.fulfilled &&
                !state.dynamicMonitoring.vehicleStatus.pending &&
                !isEmpty(state.pendingMonitoring.pendingVehicleStatusUpdates)
            ) {
                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        vehicleStatus: {
                            ...nextState.dynamicMonitoring.vehicleStatus,
                            data: mergeRevisionData(
                                nextState.dynamicMonitoring.vehicleStatus.data,
                                nextState.pendingMonitoring.pendingVehicleStatusUpdates
                            ),
                        },
                    },
                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingVehicleStatusUpdates: {},
                    },
                };
            }

            if (
                nextState.dynamicMonitoring.vehicleDriverHoursStatus.fulfilled &&
                !nextState.dynamicMonitoring.vehicleDriverHoursStatus.pending &&
                !isEmpty(nextState.pendingMonitoring.pendingVehicleDriverHoursStatusUpdates)
            ) {
                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        vehicleDriverHoursStatus: {
                            ...nextState.dynamicMonitoring.vehicleDriverHoursStatus,
                            data: mergeRevisionData(
                                nextState.dynamicMonitoring.vehicleDriverHoursStatus.data,
                                nextState.pendingMonitoring.pendingVehicleDriverHoursStatusUpdates
                            ),
                        },
                    },
                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingVehicleDriverHoursStatusUpdates: {},
                    },
                };
            }

            if (
                nextState.dynamicMonitoring.trailerStatus.fulfilled &&
                !nextState.dynamicMonitoring.trailerStatus.pending
            ) {
                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        trailerStatus: {
                            ...nextState.dynamicMonitoring.trailerStatus,
                            data: mergeRevisionData(
                                nextState.dynamicMonitoring.trailerStatus.data,
                                nextState.pendingMonitoring.pendingTrailerStatusUpdates
                            ),
                        },
                    },
                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingTrailerStatusUpdates: {},
                    },
                };
            }

            if (
                nextState.dynamicMonitoring.vehicleConnections.fulfilled &&
                !nextState.dynamicMonitoring.vehicleConnections.pending &&
                nextState.pendingMonitoring.pendingVehicleConnectionsUpdate
            ) {
                const vehicleConnections = applyVehicleConnectionsUpdate(
                    Object.values(nextState.dynamicMonitoring.vehicleConnections.data),
                    nextState.pendingMonitoring.pendingVehicleConnectionsUpdate
                );

                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        vehicleConnections: {
                            ...nextState.dynamicMonitoring.vehicleConnections,
                            data: keyBy(vehicleConnections, 'vehicle.id'),
                        },
                        vehicleConnectionsByTrailer: keyBy(
                            vehicleConnections.filter((x) => x.trailerConnection),
                            'trailerConnection.trailer.id'
                        ),
                    },

                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingVehicleConnectionsUpdate: undefined,
                    },
                };
            }

            if (
                nextState.dynamicMonitoring.containerStatus.fulfilled &&
                !nextState.dynamicMonitoring.containerStatus.pending
            ) {
                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        containerStatus: {
                            ...nextState.dynamicMonitoring.containerStatus,
                            data: mergeRevisionData(
                                nextState.dynamicMonitoring.containerStatus.data,
                                nextState.pendingMonitoring.pendingContainerStatusUpdates
                            ),
                        },
                    },
                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingTrailerStatusUpdates: {},
                    },
                };
            }

            if (!isEmpty(nextState.pendingMonitoring.pendingDriversStatusUpdates)) {
                nextState = {
                    ...nextState,
                    dynamicMonitoring: {
                        ...nextState.dynamicMonitoring,
                        driverStatus: {
                            ...nextState.dynamicMonitoring.driverStatus,
                            data: {
                                ...nextState.dynamicMonitoring.driverStatus.data,
                                ...nextState.pendingMonitoring.pendingDriversStatusUpdates,
                            },
                        },
                    },
                    pendingMonitoring: {
                        ...nextState.pendingMonitoring,
                        pendingDriversStatusUpdates: {},
                    },
                };
            }

            nextState = Object.values(AssetType).reduce((currentState, assetType) => {
                const retrievableData = currentState.dynamicMonitoring.routeStatus[assetType];
                const pendingUpdates = currentState.pendingMonitoring.pendingRouteStatusUpdates[assetType];
                if (
                    retrievableData.fulfilled &&
                    !currentState.dynamicMonitoring.routeStatus[assetType].pending &&
                    pendingUpdates
                ) {
                    return {
                        ...currentState,
                        dynamicMonitoring: {
                            ...currentState.dynamicMonitoring,
                            routeStatus: {
                                ...currentState.dynamicMonitoring.routeStatus,
                                [assetType]: {
                                    ...retrievableData,
                                    data: mergeRevisionData(retrievableData.data, pendingUpdates),
                                },
                            },
                        },
                        pendingMonitoring: {
                            ...currentState.pendingMonitoring,
                            pendingRouteStatusUpdates: {
                                ...currentState.pendingMonitoring.pendingRouteStatusUpdates,
                                [assetType]: undefined,
                            },
                        },
                    };
                }
                return currentState;
            }, nextState);

            return nextState;
        }

        case RouteStatusActionTypeKeys.ROUTESTATUS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    routeStatus: {
                        ...state.dynamicMonitoring.routeStatus,
                        [action.meta]: {
                            ...state.dynamicMonitoring.routeStatus[action.meta],
                            pending: true,
                        },
                    },
                },
            };
        case RouteStatusActionTypeKeys.ROUTESTATUS_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    routeStatus: {
                        ...state.dynamicMonitoring.routeStatus,
                        [action.meta]: {
                            data: mergeRevisionData(
                                keyBy(action.payload.items, 'asset.id'),
                                state.pendingMonitoring.pendingRouteStatusUpdates[action.meta] ?? {}
                            ),
                            fulfilled: true,
                            pending: false,
                            rejected: false,
                        },
                    },
                },
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingRouteStatusUpdates: {
                        ...state.pendingMonitoring.pendingRouteStatusUpdates,
                        [action.meta]: undefined,
                    },
                },
            };
        case RouteStatusActionTypeKeys.ROUTESTATUS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    routeStatus: {
                        ...state.dynamicMonitoring.routeStatus,
                        [action.meta]: {
                            data: {},
                            fulfilled: false,
                            pending: false,
                            rejected: true,
                        },
                    },
                },
            };
        case RouteStatusActionTypeKeys.ROUTESTATUS_UPDATE: {
            const { id, type } = action.payload.asset;
            const pendingRouteStatusUpdate = (state.pendingMonitoring.pendingRouteStatusUpdates[type] ?? {})[id];

            if (!pendingRouteStatusUpdate || action.payload.revision > pendingRouteStatusUpdate.revision) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingRouteStatusUpdates: {
                            ...state.pendingMonitoring.pendingRouteStatusUpdates,
                            [type]: {
                                ...state.pendingMonitoring.pendingRouteStatusUpdates[type],
                                [id]: action.payload,
                            },
                        },
                    },
                };
            }

            return state;
        }

        case TrailerStatusActionTypeKeys.TRAILERSTATUS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    trailerStatus: { ...state.dynamicMonitoring.trailerStatus, pending: true },
                },
            };
        case TrailerStatusActionTypeKeys.TRAILERSTATUS_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    trailerStatus: {
                        data: mergeRevisionData(
                            keyBy(action.payload.items, 'trailerId'),
                            state.pendingMonitoring.pendingTrailerStatusUpdates
                        ),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingTrailerStatusUpdates: {},
                },
            };
        case TrailerStatusActionTypeKeys.TRAILERSTATUS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    trailerStatus: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        case TrailerStatusActionTypeKeys.TRAILERSTATUS_UPDATE: {
            const pendingTrailerStatusUpdate =
                state.pendingMonitoring.pendingTrailerStatusUpdates[action.payload.trailerId];

            if (!pendingTrailerStatusUpdate || action.payload.revision > pendingTrailerStatusUpdate.revision) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingTrailerStatusUpdates: {
                            ...state.pendingMonitoring.pendingTrailerStatusUpdates,
                            [action.payload.trailerId]: action.payload,
                        },
                    },
                };
            }

            return state;
        }

        case ContainerStatusActionTypeKeys.CONTAINERSTATUS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    containerStatus: { ...state.dynamicMonitoring.containerStatus, pending: true },
                },
            };
        case ContainerStatusActionTypeKeys.CONTAINERSTATUS_FULFILLED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    containerStatus: {
                        data: mergeRevisionData(
                            keyBy(action.payload.items, 'containerId'),
                            state.pendingMonitoring.pendingContainerStatusUpdates
                        ),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        case ContainerStatusActionTypeKeys.CONTAINERSTATUS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    containerStatus: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };

        case ContainerStatusActionTypeKeys.CONTAINERSTATUS_UPDATE: {
            const pendingContainerStatusUpdate =
                state.pendingMonitoring.pendingContainerStatusUpdates[action.payload.containerId];

            if (!pendingContainerStatusUpdate || action.payload.revision > pendingContainerStatusUpdate.revision) {
                return {
                    ...state,
                    pendingMonitoring: {
                        ...state.pendingMonitoring,
                        pendingContainerStatusUpdates: {
                            ...state.pendingMonitoring.pendingContainerStatusUpdates,
                            [action.payload.containerId]: action.payload,
                        },
                    },
                };
            }

            return state;
        }

        case VehicleConnectionsActionTypeKeys.VEHICLECONNECTIONS_PENDING:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleConnections: { ...state.dynamicMonitoring.vehicleConnections, pending: true },
                },
            };

        case VehicleConnectionsActionTypeKeys.VEHICLECONNECTIONS_FULFILLED: {
            const vehicleConnections = state.pendingMonitoring.pendingVehicleConnectionsUpdate
                ? applyVehicleConnectionsUpdate(
                      action.payload.items,
                      state.pendingMonitoring.pendingVehicleConnectionsUpdate
                  )
                : action.payload.items;

            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleConnections: {
                        data: keyBy(vehicleConnections, 'vehicle.id'),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                    vehicleConnectionsByTrailer: keyBy(
                        vehicleConnections.filter((x) => x.trailerConnection),
                        'trailerConnection.trailer.id'
                    ),
                },
            };
        }

        case VehicleConnectionsActionTypeKeys.VEHICLECONNECTIONS_REJECTED:
            return {
                ...state,
                dynamicMonitoring: {
                    ...state.dynamicMonitoring,
                    vehicleConnections: {
                        data: {},
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                    vehicleConnectionsByTrailer: {},
                },
            };

        case VehicleConnectionsActionTypeKeys.VEHICLECONNECTIONS_UPDATE:
            return {
                ...state,
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingVehicleConnectionsUpdate: state.pendingMonitoring.pendingVehicleConnectionsUpdate
                        ? mergeVehicleConnectionsUpdates(
                              state.pendingMonitoring.pendingVehicleConnectionsUpdate,
                              action.payload.data
                          )
                        : action.payload.data,
                },
            };
        case MonitoringActionTypeKeys.MONITORING_DRIVERSTATUS_UPDATE:
            return {
                ...state,
                pendingMonitoring: {
                    ...state.pendingMonitoring,
                    pendingDriversStatusUpdates: {
                        ...state.pendingMonitoring.pendingDriversStatusUpdates,
                        [action.payload.driver]: action.payload,
                    },
                },
            };

        default:
            return state;
    }
};
