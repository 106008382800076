import { ActionButtonGroup } from '@fv/components/Actions';
import type { ActionItem } from '@fv/components/Actions';
import * as React from 'react';

import { ProfileSubpageShell } from '~/common';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { ResolvedVehicle } from '~/services/ApiClient';
import { formatVehicleCategory, formatVehicleName } from '~/services/Formatters';

import type { VehicleAdministrationEntry } from '../../../../models';

import { useStyles } from './styles';

export interface HeaderSectionProps {
    entry: VehicleAdministrationEntry;
    headerActions: ActionItem[];
}

export interface HeaderSectionInnerProps
    extends HeaderSectionProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const HeaderSectionComponent: React.FunctionComponent<HeaderSectionInnerProps> = ({
    displayPreferences,
    entry,
    headerActions,
    t,
}) => {
    const classes = useStyles();

    const primaryTitle = React.useMemo(() => {
        return formatVehicleName(entry.adminVehicle as ResolvedVehicle, displayPreferences.vehicleDisplayFormat);
    }, [displayPreferences.vehicleDisplayFormat, entry.adminVehicle]);

    const secondaryTitle = React.useMemo(() => {
        const formattedVehicleCategory =
            entry.adminVehicle.category && formatVehicleCategory(t, entry.adminVehicle.category);
        const vehicleCategory = !isUndefined(entry.adminVehicle.vehicleType)
            ? `${formattedVehicleCategory} - ${entry.adminVehicle.vehicleType.name}`
            : formattedVehicleCategory;

        return vehicleCategory || '';
    }, [entry.adminVehicle, t]);

    return (
        <>
            <ProfileSubpageShell
                icon={<TruckIcon className={classes.vehicleIcon} />}
                primaryTitle={primaryTitle}
                secondaryTitle={secondaryTitle}
            />
            <ActionButtonGroup items={headerActions} testId="action-buttons-admin-vehicle" />
        </>
    );
};
