import { useStyles } from './styles';

export interface MarkerProps<T> {
    animationDuration: number;
    classes?: ReturnType<typeof useStyles>;
    dataId?: string;
    entry: T;
    Icon: React.ComponentType<{ entry: T }>;
    IconBadge?: React.ComponentType<{ entry: T }>;
    NameFormatter?: React.ComponentType<{ entry: T }>;
    selected: boolean;
    updateAnimationVisible: boolean;
}

const Marker = <T,>(props: MarkerProps<T>) => {
    const { animationDuration, dataId, entry, Icon, IconBadge, NameFormatter, selected, updateAnimationVisible } =
        props;

    const classes = useStyles({ animationDuration, classes: props.classes });

    return (
        <div className={classes.root} data-id={dataId} data-testid={dataId}>
            <div className={classes.icon}>
                <div className={classes.iconBox}>
                    <Icon entry={entry} />
                </div>

                {IconBadge && (
                    <div className={classes.iconBadge}>
                        <IconBadge entry={entry} />
                    </div>
                )}

                {updateAnimationVisible && <div className={classes.highlight} />}

                {selected && <div className={classes.selected} />}
            </div>

            {NameFormatter && (
                <div className={classes.assetName}>
                    <NameFormatter entry={entry} />
                </div>
            )}
        </div>
    );
};

Marker.displayName = 'Marker';
export default Marker;
