import { isUndefined, orderBy } from '~/libs/utility';
import type { AssetReference, AssetType } from '~/services/ApiClient';

import type { AssetNode, AssetReferenceHash, AssetReferenceWithDisplayName } from '../models';
import { NodeTypes } from '../models';

export const assetIdsToNodes = (
    assetTypes: AssetType[],
    assetIds: AssetReferenceHash<AssetReference>,
    assetsHash: AssetReferenceHash<AssetReferenceWithDisplayName>,
    selectedAssetIds: AssetReferenceHash<AssetReference>,
    selectedParentGroupId?: number,
    parentGroupId?: number
): AssetNode[] => {
    if (assetIds === undefined) {
        return [];
    }

    // TODO: Probably do something with assetTypes for ordering the items

    return assetTypes.reduce<AssetNode[]>((outerAcc, assetType) => {
        const dict = assetIds[assetType];
        if (!dict) {
            return outerAcc;
        }

        outerAcc.push(
            ...orderBy(
                Object.values(dict).reduce((acc: AssetNode[], assetId): AssetNode[] => {
                    const asset = assetsHash[assetId.type]?.[assetId.id];
                    if (!asset) {
                        return acc;
                    }

                    const checked =
                        !!selectedAssetIds[assetId.type]?.[assetId.id] &&
                        (isUndefined(selectedParentGroupId) || selectedParentGroupId === parentGroupId);

                    acc.push({
                        assetReference: assetId,
                        id: `${assetId.type}-${assetId.id}`,
                        name: asset.displayName,
                        parentGroupId,
                        state: { checked },
                        type: NodeTypes.ASSET,
                    });
                    return acc;
                }, []),
                'name'
            )
        );
        return outerAcc;
    }, []);
};
