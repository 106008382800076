import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type VehicleTypesAdministrationClassKeys = 'backDrop' | 'loader' | 'messageText' | 'root';

export const useStyles = makeStyles<Theme, {}, VehicleTypesAdministrationClassKeys>((theme) => ({
    backDrop: {
        color: '#fff',
        position: 'absolute',
        zIndex: theme.zIndex.drawer,
    },
    loader: {
        color: theme.palette.primary.main,
    },
    messageText: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        width: '100%',
    },
    root: {
        height: '100%',
    },
}));
