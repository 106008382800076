import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type CompartmentSubSectionContentClassKey =
    | 'avatar'
    | 'avatarPlaceholder'
    | 'compartmentStatus'
    | 'delimiter'
    | 'detailsRow'
    | 'flexRow'
    | 'infoIcon'
    | 'primaryInfoIcon'
    | 'primaryReeferValue'
    | 'reeferValues'
    | 'root'
    | 'sensorRow'
    | 'statusRow';

export const CompartmentSubSectionContentStyleRules: StyleRulesCallback<
    Theme,
    {},
    CompartmentSubSectionContentClassKey
> = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
        },
        avatarPlaceholder: {
            width: theme.spacing(7),
        },
        compartmentStatus: {
            flexGrow: 1,
        },
        delimiter: {
            margin: theme.spacing(0, 0.5),
        },
        detailsRow: {
            lineHeight: '14px',
        },
        flexRow: {
            alignItems: 'center',
            display: 'flex',
        },
        infoIcon: {
            fontSize: 14,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(0.5),
        },
        primaryInfoIcon: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(0.5),
        },
        primaryReeferValue: {
            justifyContent: 'flex-end',
        },
        reeferValues: {
            display: 'flex',
            flexDirection: 'column',
        },
        root: {
            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(1),
            },
        },
        sensorRow: {
            '& > *': {
                flexGrow: 1,
            },
            '& > *:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
            cursor: 'pointer',
        },
        statusRow: {
            cursor: 'pointer',
        },
    });
