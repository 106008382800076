import { MAP_MODES } from '@fv/components';
import type { MapModes } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const DETAILSPANE_CONTAINER_USERPREFERENCES_KEY = 'detailspane-container-state';

export interface ContainerDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const defaultContainerDetailsPaneUserPreferences: ContainerDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MINIMAP, SectionName.LOCATION, SectionName.TEMPERATURES],
};

export const processResponse = (
    preferences: Partial<ContainerDetailsPaneUserPreferences>
): ContainerDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultContainerDetailsPaneUserPreferences);
};

export const requiredContainerDetailsPaneUserPreferences = {
    defaultState: defaultContainerDetailsPaneUserPreferences,
    key: DETAILSPANE_CONTAINER_USERPREFERENCES_KEY,
    processResponse,
};
