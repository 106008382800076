import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { vehiclesHashSelector } from '~/common';
import type { DocumentViewerFile, DocumentViewerFileWithContent } from '~/components/DocumentViewer';
import { DocumentViewer } from '~/components/DocumentViewer';
import { useUserPreferences } from '~/components/UserPreferences';
import type { StoreState } from '~/reducers';
import type { DisplayUserPreferences, ResolvedAttachment, ResolvedMessage } from '~/services/ApiClient';
import { ApiClient, QueryAttachmentsContentRequest, createApiModel } from '~/services/ApiClient';
import { formatMessageFormName, formatVehicleName } from '~/services/Formatters';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import { createBlob } from './utility';

export interface AttachmentViewerProps {
    attachments: ResolvedAttachment[];
    defaultAttachment?: ResolvedAttachment;
    message: ResolvedMessage;
    onClose: () => void;
}

export const AttachmentViewerComponent: React.FC<AttachmentViewerProps> = ({
    attachments,
    defaultAttachment,
    message,
    onClose,
}) => {
    const files: DocumentViewerFile[] = React.useMemo(() => {
        return attachments.map((a) => ({
            filename: a.filename,
            type: a.type,
        }));
    }, [attachments]);

    const defaultFile = (defaultAttachment && files[attachments.indexOf(defaultAttachment)]) ?? files[0];
    const { id: messageId, vehicleId } = message.value;

    const vehicle = useSelector((s: StoreState) => vehiclesHashSelector(s)[vehicleId]);
    const [displayPreferences] = useUserPreferences<DisplayUserPreferences>(PredefinedUserPreferencesKeys.DISPLAY);

    const downloadFiles = React.useCallback(
        async (requestedFiles: DocumentViewerFile[]): Promise<DocumentViewerFileWithContent[]> => {
            const requestedAttachmentIds = requestedFiles.map((f) => {
                const requestedFileIndex = files.indexOf(f);
                return attachments[requestedFileIndex].id;
            });

            const response = await ApiClient.queryConversationMessageAttachmentsContent(
                vehicleId,
                messageId,
                createApiModel(QueryAttachmentsContentRequest, { attachmentIds: requestedAttachmentIds })
            );
            return response.items
                .map((c): DocumentViewerFileWithContent | undefined => {
                    const index = requestedAttachmentIds.indexOf(c.attachmentId);
                    if (index < 0) {
                        return undefined;
                    }
                    return { contents: createBlob(c.fileContent), file: requestedFiles[index] };
                })
                .filter(Boolean) as DocumentViewerFileWithContent[];
        },
        [vehicleId, messageId, attachments, files]
    );

    const exportFilename = React.useMemo(() => {
        const vehicleName = formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat);
        const date = moment(message.value.dateTime).format('YYYYMMDD');
        const formName = formatMessageFormName(message.body);

        if (formName) {
            return `${vehicleName}-${formName}-${date}.zip`;
        }

        return `${vehicleName}-${date}.zip`;
    }, [vehicle, message.value.dateTime, message.body, displayPreferences]);

    return (
        <DocumentViewer
            defaultFile={defaultFile}
            downloadFiles={downloadFiles}
            files={files}
            onClose={onClose}
            saveAllFilename={exportFilename}
        />
    );
};
