import type { VehicleStatusForPeriodStoreState } from '~/data/monitoring';

import type { ActionTypes } from './actionTypes';
import { ActionTypeKeys } from './actionTypes';

const initialState: VehicleStatusForPeriodStoreState = {
    error: false,
    items: [],
    pending: false,
};

const vehicleStatusForPeriodReducer = (state = initialState, action: ActionTypes): VehicleStatusForPeriodStoreState => {
    switch (action.type) {
        case ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_PENDING:
            return { ...state, error: false, pending: true };
        case ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_REJECTED:
            return { ...state, error: true, pending: false };
        case ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_FULFILLED: {
            return {
                ...state,
                error: false,
                items: action.payload.items,
                pending: false,
            };
        }

        default:
            return state;
    }
};

export { vehicleStatusForPeriodReducer };
