import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './consts';

export const defaultDriversListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.DRIVER_ACTIVITY,
        ColumnName.SUBACTIVITY,
        ColumnName.DRIVER,
        ColumnName.DRIVER_ID,
        ColumnName.VEHICLE,
        ColumnName.CODRIVER_NAME,
        ColumnName.CODRIVER_ID,
        ColumnName.LAST_EVENT,
        ColumnName.DURATION,
        ColumnName.CARD_STATUS,
        ColumnName.POSITION_DATETIME,
        ColumnName.POI,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.CITY,
        ColumnName.POSTAL_CODE,
        ColumnName.STREET,
        ColumnName.HOUSE_NUMBER,
        ColumnName.SHIFT_DURATION,
        ColumnName.CONTINUOUS_DRIVING,
        ColumnName.DRIVE_BREAK,
        ColumnName.SPLIT_DAILY_REST,
        ColumnName.CONTINUOUS_WORK_WAIT,
        ColumnName.CONTINUOUS_LABOUR_SHORT,
        ColumnName.SHORT_LABOUR_BREAK,
        ColumnName.CONTINUOUS_LABOUR_LONG,
        ColumnName.LONG_LABOUR_BREAK,
        ColumnName.DAILY_DRIVING,
        ColumnName.AVAILABLE_DAILY_DRIVING,
        ColumnName.DAILY_DUTY,
        ColumnName.NIGHT_LABOUR,
        ColumnName.OPERATION_WEEK_DURATION,
        ColumnName.WEEKLY_DRIVING,
        ColumnName.AVAILABLE_WEEKLY_DRIVING,
        ColumnName.WEEKLY_DUTY,
        ColumnName.WEEKLY_LABOUR,
        ColumnName.AVERAGE_WEEKLY_LABOUR,
        ColumnName.REDUCED_DAILY_REST_COUNT,
        ColumnName.EXTENDED_DAILY_DRIVING_COUNT,
        ColumnName.AVAILABLE_TWO_WEEKLY_DRIVING,
        ColumnName.MONTHLY_DUTY,
        ColumnName.MONTHLY_DRIVE_OVER_DUTY,
        ColumnName.REGULAR_WEEKLY_REST_REQUIRED,
        ColumnName.WEEKLY_REST_COMPENSATION,
        ColumnName.TOTAL_WEEKLY_REST_COMPENSATION,
        ColumnName.WEEKLY_REST_VIOLATION,
        ColumnName.WEEKLY_REST_COMPENSATION_ON_VIOLATION,
    ],
    columnWidths: [
        { columnName: ColumnName.DRIVER_ACTIVITY, width: 75 },
        { columnName: ColumnName.SUBACTIVITY, width: 75 },
        { columnName: ColumnName.DRIVER, width: 200 },
        { columnName: ColumnName.DRIVER_ID, width: 75 },
        { columnName: ColumnName.VEHICLE, width: 150 },
        { columnName: ColumnName.CODRIVER_NAME, width: 200 },
        { columnName: ColumnName.CODRIVER_ID, width: 75 },
        { columnName: ColumnName.LAST_EVENT, width: 100 },
        { columnName: ColumnName.DURATION, width: 75 },
        { columnName: ColumnName.CARD_STATUS, width: 75 },
        { columnName: ColumnName.POSITION_DATETIME, width: 100 },
        { columnName: ColumnName.POI, width: 75 },
        { columnName: ColumnName.ALTITUDE, width: 75 },
        { columnName: ColumnName.LATITUDE, width: 75 },
        { columnName: ColumnName.LONGITUDE, width: 75 },
        { columnName: ColumnName.CITY, width: 150 },
        { columnName: ColumnName.POSTAL_CODE, width: 75 },
        { columnName: ColumnName.STREET, width: 75 },
        { columnName: ColumnName.HOUSE_NUMBER, width: 75 },
        { columnName: ColumnName.SHIFT_DURATION, width: 75 },
        { columnName: ColumnName.CONTINUOUS_DRIVING, width: 75 },
        { columnName: ColumnName.DRIVE_BREAK, width: 75 },
        { columnName: ColumnName.SPLIT_DAILY_REST, width: 75 },
        { columnName: ColumnName.CONTINUOUS_WORK_WAIT, width: 75 },
        { columnName: ColumnName.CONTINUOUS_LABOUR_SHORT, width: 75 },
        { columnName: ColumnName.SHORT_LABOUR_BREAK, width: 75 },
        { columnName: ColumnName.CONTINUOUS_LABOUR_LONG, width: 75 },
        { columnName: ColumnName.LONG_LABOUR_BREAK, width: 75 },
        { columnName: ColumnName.DAILY_DRIVING, width: 75 },
        { columnName: ColumnName.AVAILABLE_DAILY_DRIVING, width: 75 },
        { columnName: ColumnName.DAILY_DUTY, width: 75 },
        { columnName: ColumnName.NIGHT_LABOUR, width: 75 },
        { columnName: ColumnName.OPERATION_WEEK_DURATION, width: 75 },
        { columnName: ColumnName.WEEKLY_DRIVING, width: 75 },
        { columnName: ColumnName.AVAILABLE_WEEKLY_DRIVING, width: 75 },
        { columnName: ColumnName.WEEKLY_DUTY, width: 75 },
        { columnName: ColumnName.WEEKLY_LABOUR, width: 75 },
        { columnName: ColumnName.AVERAGE_WEEKLY_LABOUR, width: 75 },
        { columnName: ColumnName.REDUCED_DAILY_REST_COUNT, width: 75 },
        { columnName: ColumnName.EXTENDED_DAILY_DRIVING_COUNT, width: 75 },
        { columnName: ColumnName.AVAILABLE_TWO_WEEKLY_DRIVING, width: 75 },
        { columnName: ColumnName.MONTHLY_DUTY, width: 75 },
        { columnName: ColumnName.MONTHLY_DRIVE_OVER_DUTY, width: 75 },
        { columnName: ColumnName.REGULAR_WEEKLY_REST_REQUIRED, width: 75 },
        { columnName: ColumnName.WEEKLY_REST_COMPENSATION, width: 75 },
        { columnName: ColumnName.TOTAL_WEEKLY_REST_COMPENSATION, width: 75 },
        { columnName: ColumnName.WEEKLY_REST_VIOLATION, width: 75 },
        { columnName: ColumnName.WEEKLY_REST_COMPENSATION_ON_VIOLATION, width: 75 },
    ],
    filteringEnabled: false,
    filters: [],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    sorting: [{ columnName: ColumnName.DRIVER, direction: GridSortingDirection.ASC }],
    visibleColumns: [
        ColumnName.DRIVER_ACTIVITY,
        ColumnName.SUBACTIVITY,
        ColumnName.DRIVER,
        ColumnName.DRIVER_ID,
        ColumnName.VEHICLE,
        ColumnName.CODRIVER_NAME,
        ColumnName.CODRIVER_ID,
        ColumnName.LAST_EVENT,
        ColumnName.DURATION,
        ColumnName.CARD_STATUS,
        ColumnName.POSITION_DATETIME,
        ColumnName.POI,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.CITY,
        ColumnName.POSTAL_CODE,
        ColumnName.STREET,
        ColumnName.HOUSE_NUMBER,
        ColumnName.SHIFT_DURATION,
        ColumnName.CONTINUOUS_DRIVING,
        ColumnName.DRIVE_BREAK,
        ColumnName.SPLIT_DAILY_REST,
        ColumnName.CONTINUOUS_WORK_WAIT,
        ColumnName.CONTINUOUS_LABOUR_SHORT,
        ColumnName.SHORT_LABOUR_BREAK,
        ColumnName.CONTINUOUS_LABOUR_LONG,
        ColumnName.LONG_LABOUR_BREAK,
        ColumnName.DAILY_DRIVING,
        ColumnName.AVAILABLE_DAILY_DRIVING,
        ColumnName.DAILY_DUTY,
        ColumnName.NIGHT_LABOUR,
        ColumnName.OPERATION_WEEK_DURATION,
        ColumnName.WEEKLY_DRIVING,
        ColumnName.AVAILABLE_WEEKLY_DRIVING,
        ColumnName.WEEKLY_DUTY,
        ColumnName.WEEKLY_LABOUR,
        ColumnName.AVERAGE_WEEKLY_LABOUR,
        ColumnName.REDUCED_DAILY_REST_COUNT,
        ColumnName.EXTENDED_DAILY_DRIVING_COUNT,
        ColumnName.AVAILABLE_TWO_WEEKLY_DRIVING,
        ColumnName.MONTHLY_DUTY,
        ColumnName.MONTHLY_DRIVE_OVER_DUTY,
        ColumnName.REGULAR_WEEKLY_REST_REQUIRED,
        ColumnName.WEEKLY_REST_COMPENSATION,
        ColumnName.TOTAL_WEEKLY_REST_COMPENSATION,
        ColumnName.WEEKLY_REST_VIOLATION,
        ColumnName.WEEKLY_REST_COMPENSATION_ON_VIOLATION,
    ],
};
