import type { Option } from '../models';

interface ClassifyOptionsArgs<T, O extends Option<T>> {
    equalityComparer: (a: T, b: T) => boolean;
    options: O[];
    value: T[];
}

interface ClassifyOptionsResult<O> {
    available: O[];
    selected: O[];
}

const classifyOptions = <T, O extends Option<T>>({ equalityComparer, options, value }: ClassifyOptionsArgs<T, O>) => {
    return options.reduce(
        (acc, option) => {
            if ('id' in option) {
                if (value.find((v) => equalityComparer(v, option.id))) {
                    acc.selected.push(option);
                } else {
                    acc.available.push(option);
                }
            } else {
                const nestedClassification = classifyOptions({ equalityComparer, options: option.options, value });
                acc.selected.push({ ...option, options: nestedClassification.selected });
                acc.available.push({ ...option, options: nestedClassification.available });
            }
            return acc;
        },
        { available: [], selected: [] } as ClassifyOptionsResult<O>
    );
};

export { classifyOptions };
export type { ClassifyOptionsArgs, ClassifyOptionsResult };
