import _CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { styled } from '@mui/material/styles';
import _Typography from '@mui/material/Typography';

const MessageContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const Spinner = styled(_CircularProgress)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const Text = styled(_Typography)({
    fontStyle: 'italic',
    fontWeight: 'normal',
});

export { MessageContainer, Spinner, Text };
