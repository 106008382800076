import type { MapViewport } from '~/common';
import type { Action, BaseAction, RequiredAndNonNullable } from '~/types';

export enum ActionTypeKeys {
    HISTORYMAP_CHANGEVIEWPORT = 'HISTORYMAP_CHANGEVIEWPORT',
    HISTORYMAP_FLYTOPOSITIONGROUP = 'HISTORYMAP_FLYTOPOSITIONGROUP',
    HISTORYMAP_PANTOPOSITION = 'HISTORYMAP_PANTOPOSITION',
    HISTORYMAP_PANTOPOSITIONGROUP = 'HISTORYMAP_PANTOPOSITIONGROUP',
}

export type ActionTypes =
    | Action<ActionTypeKeys.HISTORYMAP_CHANGEVIEWPORT, RequiredAndNonNullable<MapViewport>>
    | BaseAction<ActionTypeKeys.HISTORYMAP_FLYTOPOSITIONGROUP>
    | BaseAction<ActionTypeKeys.HISTORYMAP_PANTOPOSITION>
    | BaseAction<ActionTypeKeys.HISTORYMAP_PANTOPOSITIONGROUP>;
