import { Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { DriverActivityEntry } from '../../../../models';

import { ActivitiesBar } from './components/ActivitiesBar';
import { ActivityDetails } from './components/ActivityDetails';
import { Ticks } from './components/Ticks';
import { isActivityEntryInCursor } from './isActivityEntryInCursor';
import type { Cursor } from './models';
import type { ZoomPopupClassKey } from './styles';

export interface ZoomPopupProps {
    activityEntries?: DriverActivityEntry[];
    className?: string;
    currentTime: Date;
    cursor: Cursor;
}

export interface ZoomPopupInnerProps extends ZoomPopupProps, WithStyles<ZoomPopupClassKey> {}

export const ZoomPopupComponent: React.FC<ZoomPopupInnerProps> = ({
    activityEntries,
    classes,
    className,
    currentTime,
    cursor,
}) => {
    const activityEntriesInCursor =
        activityEntries &&
        activityEntries.filter((entry) => {
            return isActivityEntryInCursor(entry, cursor);
        });

    const activityDetailsElement = activityEntriesInCursor && !!activityEntriesInCursor.length && (
        <ActivityDetails activityEntries={activityEntriesInCursor} className={classes.activityDetails} />
    );

    return (
        <Paper className={classNames(classes.root, className)} data-id="zoom-popup">
            {activityDetailsElement}
            <ActivitiesBar activityEntries={activityEntries} currentTime={currentTime} cursor={cursor} />
            <Ticks cursor={cursor} />
        </Paper>
    );
};
