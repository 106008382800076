import { orderBy } from '~/libs/utility';
import type { AssetGroup, AssetReference, AssetType } from '~/services/ApiClient';

import type { AssetReferenceHash, AssetReferenceWithDisplayName, GroupNode } from '../models';
import { NodeTypes } from '../models';

import { getOwnAssetGroupAssetIds } from './assetgroups';
import { assetIdsToNodes } from './mapasset';
import { groupHasAssetChildren, groupHasCheckedChildren, groupIsIndeterminate } from './predicates';

export const assetGroupsToNodes = (
    assetTypes: AssetType[],
    assetGroups: AssetGroup[] | undefined,
    assetIds: AssetReferenceHash<AssetReference>,
    assetsHash: AssetReferenceHash<AssetReferenceWithDisplayName>,
    expandedAllGroups: boolean,
    expandedGroupIds: number[],
    selectedAssetIds: AssetReferenceHash<AssetReference>,
    selectedParentGroupId?: number,
    parentGroupId?: number
): GroupNode[] => {
    if (assetGroups === undefined) {
        return [];
    }

    const selectedAllVehicles = !selectedAssetIds;

    return orderBy(
        assetGroups.reduce((acc: GroupNode[], assetGroup): GroupNode[] => {
            const assetNodes = assetIdsToNodes(
                assetTypes,
                getOwnAssetGroupAssetIds(assetGroup, assetIds),
                assetsHash,
                selectedAssetIds,
                selectedParentGroupId,
                assetGroup.id
            );

            const groupNodes = assetGroupsToNodes(
                assetTypes,
                assetGroup.assetGroups,
                assetIds,
                assetsHash,
                expandedAllGroups,
                expandedGroupIds,
                selectedAssetIds,
                selectedParentGroupId,
                assetGroup.id
            );

            const expanded = expandedAllGroups || expandedGroupIds.includes(assetGroup.id);
            const hasCheckedChildren = groupHasCheckedChildren(selectedAllVehicles, groupNodes, assetNodes);

            const indeterminate = groupIsIndeterminate(selectedAllVehicles, hasCheckedChildren, groupNodes, assetNodes);

            const hasAssetChildren = groupHasAssetChildren(assetNodes, groupNodes);
            const checked = hasAssetChildren && hasCheckedChildren && !indeterminate;

            acc.push({
                children: [...groupNodes, ...assetNodes],
                id: assetGroup.id,
                name: assetGroup.name,
                parentGroupId,
                state: { checked, disabled: false, expanded, hasAssetChildren, indeterminate },
                type: NodeTypes.GROUP,
            });
            return acc;
        }, []),
        'name'
    );
};
