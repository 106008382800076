import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

export interface ConfirmationDialogProps {
    confirmationActionText?: string;
    dataId: string;
    isDisabled?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
    title: string;
}

export interface ConfirmationDialogInnerProps extends ConfirmationDialogProps, InjectedTranslationProps {}

/**
 * @deprecated This component is deprecated in favor of `@fv/components/Dialogs/ConfirmDialog`
 */
export const ConfirmationDialogComponent: FC<ConfirmationDialogInnerProps> = ({
    children,
    confirmationActionText,
    dataId,
    isDisabled,
    onCancel,
    onConfirm,
    open,
    t,
    title,
}) => {
    const handleClick = (event: SyntheticEvent) => {
        event.stopPropagation();
    };

    return (
        <Dialog onClick={handleClick} open={open}>
            <div data-id={dataId}>
                <DialogTitle data-id="title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button color="secondary" data-id="cancel" onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button color="secondary" data-id="confirm" disabled={isDisabled} onClick={onConfirm}>
                        {confirmationActionText || t('ok')}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
