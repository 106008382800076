export const sunflower = {
    100: '#FFEDB8',

    200: '#FFE189',

    300: '#FFD559',

    400: '#FFCC36',

    50: '#FFF8E3',

    500: '#FFC312',

    600: '#FFBD10',

    700: '#FFB50D',

    800: '#FFAE0A',

    900: '#FFA105',
};

export const energos = {
    100: '#EDF7C3',

    200: '#E2F29C',

    300: '#D6ED74',

    400: '#CDE956',

    50: '#F8FCE7',

    500: '#C4E538',

    600: '#BEE232',

    700: '#B6DE2B',

    800: '#AFDA24',

    900: '#A2D317',
};

export const blueMartina = {
    100: '#B8EFED',

    200: '#89E5E2',

    300: '#59DBD6',

    400: '#36D3CD',

    50: '#E3F9F8',

    500: '#12CBC4',

    600: '#10C6BE',

    700: '#0DBEB6',

    800: '#0AB8AF',

    900: '#05ACA2',
};

export const lavenderRose = {
    100: '#FEE5F5',

    200: '#FED3EF',

    300: '#FEC1E9',

    400: '#FDB4E4',

    50: '#FFF4FB',

    500: '#FDA7DF',

    600: '#FD9FDB',

    700: '#FC96D7',

    800: '#FC8CD2',

    900: '#FC7CCA',
};

export const baraRed = {
    100: '#FAC9D1',

    200: '#F6A6B3',

    300: '#F28295',

    400: '#F0677E',

    50: '#FDEAED',

    500: '#ED4C67',

    600: '#EB455F',

    700: '#E83C54',

    800: '#FC8CD2',

    900: '#E02439',
};

export const radianYellow = {
    100: '#FDE2BC',

    200: '#FBCF8F',

    300: '#F9BC62',

    400: '#F8AD41',

    50: '#FEF3E4',

    500: '#F79F1F',

    600: '#F6971B',

    700: '#FFB50D',

    800: '#F38312',

    900: '#F1720A',
};

export const androidGreen = {
    100: '#E3EFC3',

    200: '#D1E59C',

    300: '#BFDB74',

    400: '#B1D356',

    50: '#F4F9E7',

    500: '#A3CB38',

    600: '#9BC632',

    700: '#91BE2B',

    800: '#88B824',

    900: '#77AC17',
};

export const mediterraneanSea = {
    100: '#B8DCE5',

    200: '#89C4D3',

    300: '#59ACC1',

    400: '#369BB4',

    50: '#E3F1F4',

    500: '#1289A7',

    600: '#10C6BE',

    700: '#0D7696',

    800: '#0A6C8C',

    900: '#05597C',
};

export const lavenderTea = {
    100: '#F4D9FE',

    200: '#ECC0FD',

    300: '#E4A6FC',

    400: '#FDB4E4',

    50: '#FAF0FE',

    500: '#D980FA',

    600: '#D578F9',

    700: '#CF6DF9',

    800: '#CA63F8',

    900: '#C050F6',
};

export const veryBerry = {
    100: '#E9C2D4',

    200: '#DA9AB8',

    300: '#CB719C',

    400: '#C05286',

    50: '#F6E7EE',

    500: '#B53471',

    600: '#AE2F69',

    700: '#A5275E',

    800: '#9D2154',

    900: '#8D1542',
};

export const puffinsBill = {
    100: '#FACEBD',

    200: '#F7AD92',

    300: '#F38C66',

    400: '#F17345',

    50: '#FDEBE5',

    500: '#EE5A24',

    600: '#EC5220',

    700: '#E9481B',

    800: '#E73F16',

    900: '#E22E0D',
};

export const pixelatedGrass = {
    100: '#B3DFC2',

    200: '#80CA99',

    300: '#4DB470',

    400: '#26A451',

    50: '#E0F2E6',

    500: '#009432',

    600: '#008C2D',

    700: '#008126',

    800: '#00771F',

    900: '#006513',
};

export const merchantMarineBlue = {
    100: '#B4CBF5',

    200: '#83A9EE',

    300: '#5186E7',

    400: '#2B6CE2',

    50: '#E1EAFB',

    500: '#0652DD',

    600: '#054BD9',

    700: '#0441D4',

    800: '#0338CF',

    900: '#0228C7',
};

export const forgottenPurple = {
    100: '#E0D9FE',

    200: '#CCC0FD',

    300: '#B8A6FC',

    400: '#A893FB',

    50: '#F3F0FE',

    500: '#9980FA',

    600: '#9178F9',

    700: '#866DF9',

    800: '#7C63F8',

    900: '#6B50F6',
};

export const holyhock = {
    100: '#DAC2D4',

    200: '#C19AB8',

    300: '#A8719C',

    400: '#965286',

    50: '#F0E7EE',

    500: '#833471',

    600: '#7B2F69',

    700: '#70275E',

    800: '#662154',

    900: '#531542',
};

export const redPigment = {
    100: '#F9BCBE',

    200: '#F59093',

    300: '#F06368',

    400: '#ED4147',

    50: '#5758BB',

    500: '#EA2027',

    600: '#E71C23',

    700: '#E4181D',

    800: '#E11317',

    900: '#DB0B0E',
};

export const turkishAqua = {
    100: '#B3D0D1',

    200: '#80B1B3',

    300: '#4D9194',

    400: '#267A7D',

    50: '#E0ECED',

    500: '#006266',

    600: '#005A5E',

    700: '#005053',

    800: '#004649',

    900: '#003438',
};

export const twoThousandLeaguesUnderTheSea = {
    100: '#BBB9D1',

    200: '#8D8AB2',

    300: '#5F5B93',

    400: '#3D377B',

    50: '#E4E3EC',

    500: '#1B1464',

    600: '#18125C',

    700: '#140E52',

    800: '#100B48',

    900: '#080636',
};

export const circumorbitalRing = {
    100: '#CDCDEB',

    200: '#ABACDD',

    300: '#898ACF',

    400: '#7071C5',

    50: '#EBEBF7',

    500: '#5758BB',

    600: '#4F50B5',

    700: '#4647AC',

    800: '#3C3DA4',

    900: '#2C2D96',
};

export const magentaPurple = {
    100: '#D4BCCB',

    200: '#B78FA8',

    300: '#9A6285',

    400: '#85406B',

    50: '#EEE4EA',

    500: '#6F1E51',

    600: '#671A4A',

    700: '#5C1640',

    800: '#521237',

    900: '#400A27',
};
