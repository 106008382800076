import type { SettingsKey } from '~/components/EnsureSettings';
import type { SettingsDataType } from '~/components/EnsureSettings/settings';
import type {
    Position as ApiPosition,
    AssetReference as AssetReferenceApi,
    Container as ContainerApi,
    DeepPojoModel,
    Driver as DriverApi,
    Impersonator as ImpersonatorApi,
    PojoModel,
    ServerResultStatus,
    Trailer as TrailerApi,
    Vehicle as VehicleApi,
} from '~/services/ApiClient';

export type Position = PojoModel<ApiPosition>;

export enum StaticDataType {
    ASSETGROUPS = 'assetGroups',
    CONTAINERS = 'containers',
    DEPOTS = 'depots',
    DEVICES = 'devices',
    DRIVERS = 'drivers',
    DRIVERSTATUS = 'driver-status',
    TACHOLINCSTATUS = 'tacholinc-status',
    TRAILERS = 'trailers',
    VEHICLES = 'vehicles',
    VEHICLESTATUS = 'vehicle-status',
}

export interface StaticDataProvider {
    isLoaded: (type: StaticDataType) => boolean;
    retrieveAssetGroups(forceLoad?: boolean): void;
    retrieveContainers(forceLoad?: boolean): void;
    retrieveDepots(forceLoad?: boolean): void;
    retrieveDevices(forceLoad?: boolean): void;
    retrieveDrivers(forceLoad?: boolean): void;
    retrieveDriverStatus(forceLoad?: boolean): void;
    retrieveTrailers(forceLoad?: boolean): void;
    retrieveVehicles(forceLoad?: boolean): void;
    retrieveVehicleStatus(forceLoad?: boolean): void;
}

export interface TriggerDataRefresh {
    initialLoad?: boolean;
    showBanner?: boolean;
    triggered: boolean;
}

export interface MapViewport {
    center: [number, number] | undefined;
    zoom: number;
}

export interface GenericState<T> {
    error: boolean;
    items: T[];
    pending: boolean;
}

export interface BaseGridScene<T> {
    crud: { status: ServerResultStatus | undefined };
    list: GenericState<T>;
}

export type settingsStoreStateBuilderProps = {
    [Key in SettingsKey]?: SettingsDataType[Key];
};

export type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;
export type DotNestedKeys<T> = T extends Array<unknown> | Date
    ? ''
    : (
            T extends object
                ?
                      | { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<
                            keyof T,
                            symbol
                        >]
                      | Exclude<keyof T, symbol>
                : ''
        ) extends infer D
      ? Extract<D, string>
      : never;

export interface SourcesProps<T> {
    a: T | undefined;
    b: T | undefined;
    paths: DotNestedKeys<T>[];
}

type AssetReference = PojoModel<AssetReferenceApi>;
type Vehicle = DeepPojoModel<VehicleApi>;
type Driver = DeepPojoModel<DriverApi>;
type Trailer = DeepPojoModel<TrailerApi>;
type Container = DeepPojoModel<ContainerApi>;
type Impersonator = DeepPojoModel<ImpersonatorApi>;
type KeysFromUseStyles<T extends (props?: unknown) => unknown> = keyof ReturnType<T>;

export * from './driver';
export * from './geolocation';
export * from './vehicle';
export type { AssetReference, Container, Driver, Impersonator, KeysFromUseStyles, Trailer, Vehicle };
