import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmationDialog } from '~/components/Dialogs';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { disconnectTrailerAction } from '~/data/vehicleconnections';
import { reportError } from '~/reporting';
import type {
    NamedVehicleReference,
    PojoModel,
    ResolvedTrailerConnection,
    ResolvedVehicle,
    Vehicle,
} from '~/services/ApiClient';

export interface DisconnectTrailerFromVehicleDialogProps {
    onClose: () => void;
    trailerConnection: ResolvedTrailerConnection;
    vehicle: PojoModel<NamedVehicleReference> | PojoModel<Vehicle> | ResolvedVehicle;
}

export interface DisconnectTrailerFromVehicleDialogInnerProps
    extends DisconnectTrailerFromVehicleDialogProps,
        InjectedTranslationProps {}

export const DisconnectTrailerFromVehicleDialogComponent: React.FC<DisconnectTrailerFromVehicleDialogInnerProps> = ({
    onClose,
    t,
    trailerConnection,
    vehicle,
}) => {
    const dispatch = useDispatch();
    const handleConfirm = () => {
        dispatch(disconnectTrailerAction(vehicle.id, trailerConnection.trailerReference.id)).catch(reportError);
        onClose();
    };

    return (
        <ConfirmationDialog
            confirmationActionText={t('disconnect')}
            dataId="disconnect-trailer-confirmation-dialog"
            onCancel={onClose}
            onConfirm={handleConfirm}
            open
            title={t('disconnect-trailer')}
        >
            <span>{t('confirm-disconnect-trailer')}</span>
        </ConfirmationDialog>
    );
};
