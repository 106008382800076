import classNames from 'classnames';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';

import type { DurationFnsFormatterProps } from './models';
import { useStyles } from './styles';
import { formatDuration } from './utils';
import { isViolation } from './violations';

const DurationFnsFormatterComponent: FC<DurationFnsFormatterProps> = (props) => {
    const { options, threshold, value, violationType } = props;
    const classes = useStyles();

    return !isUndefined(value) ? (
        <span
            className={classNames(classes.root, {
                [classes.violation]: threshold && violationType && isViolation({ threshold, value, violationType }),
            })}
        >
            {formatDuration({ options, value })}
        </span>
    ) : null;
};

DurationFnsFormatterComponent.displayName = 'DurationFnsFormatter';
export default DurationFnsFormatterComponent;
