import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { DateTimeWorkflowFieldProps } from './component';

export type DateTimeWorkflowFieldClassKey = 'input' | 'toolbarIcon';

export const DateTimeWorkflowFieldStyleRules: StyleRulesCallback<
    Theme,
    DateTimeWorkflowFieldProps,
    DateTimeWorkflowFieldClassKey
> = (theme: Theme) =>
    createStyles({ input: { width: '100%' }, toolbarIcon: { color: theme.palette.primary.contrastText } });
