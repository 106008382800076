import type {
    AssetType,
    Driver,
    ResolvedDriverEvent,
    ResolvedTrailerEvent,
    ResolvedVehicle,
    Trailer,
    VehicleEvent,
} from '~/services/ApiClient';

export interface HistoryVehicleEntry {
    vehicle?: ResolvedVehicle;
    vehicleEvent: VehicleEvent;
}

export interface HistoryTrailerEntry {
    trailer?: Trailer;
    trailerEvent: ResolvedTrailerEvent;
}

export interface HistoryDriverEntry {
    driver?: Driver;
    driverEvent: ResolvedDriverEvent;
}

export type HistoryAssetEntry = HistoryDriverEntry | HistoryTrailerEntry | HistoryVehicleEntry;

export enum HistoryEventType {
    DRIVER = 'driver-activities',
    TRAILER = 'trailer-events',
    VEHICLE = 'historic-positions',
}

export interface ConstructHistoryUrlArgs {
    eventType: HistoryEventType;
    selectedAssetId?: number;
    selectedAssetType?: AssetType;
}

export interface PeriodSelection {
    endDate: Date | undefined;
    startDate: Date | undefined;
}
