import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ReeferSectionClassKey = 'captionIcon' | 'delimiter' | 'reeferStatusTimeDetails' | 'reeferTimeElement';

export const ReeferSectionStyleRules: StyleRulesCallback<Theme, {}, ReeferSectionClassKey> = (theme: Theme) =>
    createStyles({
        captionIcon: {
            fontSize: theme.typography.pxToRem(14),
            marginRight: theme.spacing(0.5),
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        reeferStatusTimeDetails: {
            alignItems: 'center',
            display: 'flex',
            marginBottom: theme.spacing(),
        },
        reeferTimeElement: {
            paddingTop: 0,
        },
    });
