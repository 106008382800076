import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import type { PercentageFormatterProps } from '~/components/Formatters';
import {
    AltitudeFormatter,
    PercentageFormatter as BasePercentageFormatter,
    BooleanFormatter,
    DensityFormatter,
    DistanceFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    PressureFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createAddressColumn,
    createClassificationColumn,
    createDateTimeColumn,
    createTemperatureColumn,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { MonitoringContainerEntry } from '~/data/monitoring';
import type { DisplayUserPreferencesUnitSystem, NearestCity } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    AddressFormat,
    exportFormatterFactory,
    formatBoolean,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    formatPercentage,
    getAltitudeUnitSystemUtils,
    getDensityUnitSystemUtils,
    getDistanceUnitSystemUtils,
    getPressureUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';
import { getContainerValue } from './getCellValue.container';
import {
    getAddressValue,
    getAltitudeValue,
    getBarrelFillLevelValue,
    getBarrelPressureValue,
    getCityValue,
    getCountryCodeValue,
    getEventDateValue,
    getEventTypeValue,
    getFirstContentTemperatureValue,
    getHeatingSystemTankLevelValue,
    getIsHeatingSystemOnValue,
    getLatitudeValue,
    getLongitudeValue,
    getNearestCityValue,
    getOdometerValue,
    getPostalCodeValue,
    getProductDensityValue,
} from './getCellValue.containerstatus';

export const getColumns = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem
): Array<GridColumnDefinition<MonitoringContainerEntry>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const defaultPercentageFormatterProps: Partial<PercentageFormatterProps> = { precision: 1 };
    const PercentageFormatter = defaultProps(defaultPercentageFormatterProps)(BasePercentageFormatter);

    const altitudeUtils = getAltitudeUnitSystemUtils(t, unitSystem);
    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);
    const pressureUtils = getPressureUnitSystemUtils(t);
    const densityUtils = getDensityUnitSystemUtils(t);

    return [
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getContainerValue(),
            groupTitle: t('general'),
            name: ColumnName.CONTAINER,
            title: t('container'),
        },
        createClassificationColumn(
            t,
            ColumnName.EVENTTYPE,
            t('event-type'),
            t('general'),
            getEventTypeValue,
            ClassificationType.ContainerEventType
        ),
        createDateTimeColumn(ColumnName.EVENTDATETIME, t('container-last-event'), t('general'), getEventDateValue),
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: '0.0"%"',
            exportValueFormatter: exportConvertorFactory((v: number) => v),
            getCellValue: getBarrelFillLevelValue,
            groupTitle: t('contents'),
            name: ColumnName.BARRELFILLLEVEL,
            title: t('barrel-fill-level'),
            valueFormatterComponent: PercentageFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${pressureUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory(pressureUtils.converter),
            getCellValue: getBarrelPressureValue,
            getFilterValue: pressureUtils.converter,
            groupTitle: t('contents'),
            name: ColumnName.BARRELPRESSURE,
            title: t('barrel-pressure'),
            valueFormatterComponent: withTranslation()(PressureFormatter),
            valueTextFormatter: valueTextFormatterFactory(pressureUtils.formatter),
        },
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: getIsHeatingSystemOnValue,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('container-status'),
            name: ColumnName.HEATINGSYSTEMON,
            title: t('is-heating-system-on'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: '0.0"%"',
            exportValueFormatter: exportConvertorFactory((v: number) => v),
            getCellValue: getHeatingSystemTankLevelValue,
            groupTitle: t('container-status'),
            name: ColumnName.HEATINGSYSTEMTANKLEVEL,
            title: t('heating-system-fuel-level'),
            valueFormatterComponent: PercentageFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.0 "${densityUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory(densityUtils.converter),
            getCellValue: getProductDensityValue,
            getFilterValue: densityUtils.converter,
            groupTitle: t('contents'),
            name: ColumnName.PRODUCTDENSITY,
            title: t('product-density'),
            valueFormatterComponent: withTranslation()(DensityFormatter),
            valueTextFormatter: valueTextFormatterFactory(densityUtils.formatter),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            getCellValue: getOdometerValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            groupTitle: t('container-status'),
            name: ColumnName.ODOMETER,
            title: t('distance'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
        },
        createTemperatureColumn(
            t,
            unitSystem,
            ColumnName.TEMPERATURE,
            t('temperature'),
            t('contents'),
            getFirstContentTemperatureValue
        ),
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCityValue,
            groupTitle: t('location'),
            name: ColumnName.CITY,
            title: t('city'),
        },
        createAddressColumn(ColumnName.ADDRESS, t('address'), t('location'), getAddressValue, {
            formatOptions: { format: AddressFormat.FirstLine },
        }),
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getPostalCodeValue,
            groupTitle: t('location'),
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCountryCodeValue,
            groupTitle: t('location'),
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
        },
        {
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            getCellValue: getNearestCityValue,
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            groupTitle: t('location'),
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(NearestCityFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'string',
            excelCellFormat: `0.0 "${altitudeUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => altitudeUtils.converter(v, { precision: 1 })),
            getCellValue: getAltitudeValue,
            getFilterValue: (v: number) => altitudeUtils.converter(v, { precision: 1 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            groupTitle: t('location'),
            name: ColumnName.ALTITUDE,
            title: t('altitude'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
        },
        {
            compare: compareNumbers,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            getCellValue: getLatitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            getCellValue: getLongitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
    ];
};

export const getRowId = (row: MonitoringContainerEntry): number => row.container.id;

export const getColumnsMemoized = memoizeOne(getColumns);
