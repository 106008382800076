import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

import { mToKm } from './mToKm';
import { mToMiles } from './mToMiles';

interface ConvertDistanceArgs {
    precision?: number;
    unitSystem: UnitSystem;
    value: number;
}

const convertDistance = (args: ConvertDistanceArgs) => {
    const { precision = 0, unitSystem, value } = args;
    switch (unitSystem) {
        case UNIT_SYSTEMS.IMPERIAL:
            return mToMiles(value).toFixed(precision);
        case UNIT_SYSTEMS.METRIC:
            return mToKm(value).toFixed(precision);
        default:
            throw new Error('Unknown unit system.');
    }
};
export { convertDistance };
export type { ConvertDistanceArgs };
