import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { RetryAction } from '~/components/RetryAction';
import type { Status as RetryStatus } from '~/components/RetryAction';
import type { Disposable } from '~/listeners';
import type { Status as SaveStatus, SaveUserPreferencesService } from '~/services/SaveUserPreferences';

import { mapRetryStatus } from './mapRetryStatus';

export interface SaveUserPreferencesStatusMonitorProps {
    savePreferencesService: SaveUserPreferencesService;
}

export interface SaveUserPreferencesStatusMonitorInnerProps
    extends SaveUserPreferencesStatusMonitorProps,
        InjectedTranslationProps {}

export interface SaveUserPreferencesStatusMonitorState {
    retryStatus: RetryStatus;
}

export class SaveUserPreferencesStatusMonitorComponent extends React.Component<
    SaveUserPreferencesStatusMonitorInnerProps,
    SaveUserPreferencesStatusMonitorState
> {
    private disposableHandlers: Disposable[] = [];

    private saveAllPreferences = () => {
        this.props.savePreferencesService.saveAll();
    };

    constructor(props: SaveUserPreferencesStatusMonitorInnerProps) {
        super(props);

        this.state = {
            retryStatus: mapRetryStatus(props.savePreferencesService.getStatus()),
        };
    }

    public componentDidMount(): void {
        const { savePreferencesService } = this.props;

        this.disposableHandlers.push(
            savePreferencesService.onStatusChanged((saveStatus: SaveStatus, prevSaveStatus: SaveStatus) => {
                this.setState({
                    retryStatus: mapRetryStatus(saveStatus, prevSaveStatus),
                });
            })
        );
    }

    public componentDidUpdate(prevProps: SaveUserPreferencesStatusMonitorInnerProps): void {
        if (prevProps.savePreferencesService !== this.props.savePreferencesService) {
            throw new Error('Property savePreferencesService is not supposed to be changed.');
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
    }

    public render(): React.ReactNode {
        return (
            <RetryAction
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                dataIdPrefix="save-user-preferences"
                onRetry={this.saveAllPreferences}
                retryMessage={this.props.t('failed-to-save-user-preferences')}
                status={this.state.retryStatus}
                successMessage={this.props.t('save-user-preferences-retry-succeeded')}
            />
        );
    }
}
