import { useTheme } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { TimeSeriesChartDefinition } from '~/components/TimeSeriesDialog';
import { TimeSeriesDialogFactory } from '~/components/TimeSeriesDialog';
import { subDates } from '~/libs/dates';
import { isUndefined, range } from '~/libs/utility';
import type { CompartmentStatus, ResolvedTrailer } from '~/services/ApiClient';
import { formatTrailerName, getTemperatureUnitSystemUtils } from '~/services/Formatters';

import { getChartData } from './getChartData';

export interface TemperaturesGraphDialogProps {
    defaultCompartmentNumber: number;
    onClose: () => void;
    trailer: ResolvedTrailer;
}

const TrailerStatusTimeSeriesDialog = TimeSeriesDialogFactory<CompartmentStatus>();

export interface TemperaturesGraphDialogInnerProps
    extends TemperaturesGraphDialogProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const TemperaturesGraphDialogComponent: FC<TemperaturesGraphDialogInnerProps> = (props) => {
    const { defaultCompartmentNumber, displayPreferences, onClose, t, trailer } = props;

    const currentDate = useMemo(() => new Date(), []);
    const theme = useTheme();
    const trailerName = formatTrailerName(trailer, displayPreferences.trailerDisplayFormat);
    const title = t('measurements-of-asset', { assetName: trailerName });
    const getData = useCallback(
        () => getChartData(trailer.id, subDates(currentDate, { hours: 48 }), currentDate),
        [currentDate, trailer.id]
    );

    const charts: TimeSeriesChartDefinition<CompartmentStatus>[] = useMemo(() => {
        const temperatureUtils = getTemperatureUnitSystemUtils(t, displayPreferences.unitSystem);

        return range(1, trailer.compartmentCount + 1).map((compartmentNumber) => ({
            filterData: (items) => items.filter((item) => item.compartmentNumber === compartmentNumber),
            getItemDate: (item) => item.dateTime,
            name: `compartment-${compartmentNumber}`,
            series: [
                {
                    color: theme.palette.success.main,
                    getValue: (item) =>
                        isUndefined(item.maxTemperature) || isUndefined(item.minTemperature)
                            ? undefined
                            : [
                                  temperatureUtils.converter(item.minTemperature),
                                  temperatureUtils.converter(item.maxTemperature),
                              ],
                    name: 'bandwidth',
                    title: t('bandwidth'),
                    type: 'area',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.supply.value,
                    getValue: (item) =>
                        isUndefined(item.supplyTemperature)
                            ? undefined
                            : temperatureUtils.converter(item.supplyTemperature),
                    name: 'supply',
                    title: t('supply-temperature'),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.return.value,
                    getValue: (item) =>
                        isUndefined(item.returnTemperature)
                            ? undefined
                            : temperatureUtils.converter(item.returnTemperature),
                    name: 'return',
                    title: t('return-temperature'),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.setpoint.value,
                    getValue: (item) =>
                        isUndefined(item.setpointTemperature)
                            ? undefined
                            : temperatureUtils.converter(item.setpointTemperature),
                    name: 'setpoint',
                    title: t('setpoint-temperature'),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.sensor1.value,
                    getValue: (item) => {
                        const sensor = item.temperatureSensors.find((s) => s.sensorNumber === 1);
                        return !sensor || isUndefined(sensor.value)
                            ? undefined
                            : temperatureUtils.converter(sensor.value);
                    },
                    name: 'sensor-1',
                    title: t('nth-sensor', { sensorNumber: 1 }),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.sensor2.value,
                    getValue: (item) => {
                        const sensor = item.temperatureSensors.find((s) => s.sensorNumber === 2);
                        return !sensor || isUndefined(sensor.value)
                            ? undefined
                            : temperatureUtils.converter(sensor.value);
                    },
                    name: 'sensor-2',
                    title: t('nth-sensor', { sensorNumber: 2 }),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.sensor3.value,
                    getValue: (item) => {
                        const sensor = item.temperatureSensors.find((s) => s.sensorNumber === 3);
                        return !sensor || isUndefined(sensor.value)
                            ? undefined
                            : temperatureUtils.converter(sensor.value);
                    },
                    name: 'sensor-3',
                    title: t('nth-sensor', { sensorNumber: 3 }),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
                {
                    color: theme.functionalItemsColors.temperature.sensor4.value,
                    getValue: (item) => {
                        const sensor = item.temperatureSensors.find((s) => s.sensorNumber === 4);
                        return !sensor || isUndefined(sensor.value)
                            ? undefined
                            : temperatureUtils.converter(sensor.value);
                    },
                    name: 'sensor-4',
                    title: t('nth-sensor', { sensorNumber: 4 }),
                    type: 'line',
                    unit: temperatureUtils.unit,
                },
            ],
            title: t('nth-compartment', { compartmentNumber }),
        }));
    }, [t, theme, trailer.compartmentCount, displayPreferences.unitSystem]);

    return (
        <TrailerStatusTimeSeriesDialog
            charts={charts}
            defaultActiveChartName={`compartment-${defaultCompartmentNumber}`}
            getData={getData}
            onClose={onClose}
            title={title}
        />
    );
};
