import { Box, useTheme } from '@mui/material';
import printJS from 'print-js';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { themeSpacingToNumber } from '~/common/utils';
import { AutoSizer } from '~/components/AutoSizer';
import type { PDFDocumentProxy, PDFPageProxy } from '~/libs/react-pdf';
import Document from '~/libs/react-pdf/Document';
import Page from '~/libs/react-pdf/Page';

import { useRotation, useScaling } from '../../hooks';
import type {
    DocumentViewerPaging,
    SingleDocumentViewerElement as PdfViewerElement,
    SingleDocumentViewerProps as PdfViewerProps,
    Size,
} from '../../models';
import { DocumentViewerState } from '../../models';

import { CONTENT_MARGIN } from './constants';

const PdfViewerComponent = forwardRef<PdfViewerElement, PdfViewerProps>(
    ({ data, onPagingChange, onRotationChange, onScalingChange, onStateChange, state }, ref) => {
        const theme = useTheme();
        const rotation = useRotation(0);
        const scaling = useScaling();
        const [paging, setPaging] = useState<DocumentViewerPaging | undefined>(undefined);
        const isLoaded = state === DocumentViewerState.Success;

        useEffect(() => {
            onStateChange(DocumentViewerState.Loading);
            setPaging(undefined);
        }, [data, onStateChange]);

        useEffect(() => onPagingChange && onPagingChange(paging), [onPagingChange, paging]);
        useEffect(() => onScalingChange && onScalingChange(scaling), [onScalingChange, scaling]);
        useEffect(() => onRotationChange && onRotationChange(rotation), [onRotationChange, rotation]);

        const { setContentSize } = scaling;

        useEffect(() => {
            if (rotation.transformedSize) {
                setContentSize(rotation.transformedSize);
                onStateChange(DocumentViewerState.Success);
            }
        }, [rotation.transformedSize, setContentSize, onStateChange]);

        const onPrint = useMemo(
            () => data && (() => printJS({ printable: URL.createObjectURL(data), type: 'pdf' })),
            [data]
        );

        const onDocumentLoadSuccess = (document: PDFDocumentProxy) => {
            setPaging({
                numberOfPages: document.numPages,
                onPageNumberChange: (pageNumber) => setPaging((s) => s && { ...s, pageNumber }),
                pageNumber: 1,
            });
            onStateChange(DocumentViewerState.Success);
        };
        const onLoadError = () => onStateChange(DocumentViewerState.NoPreview);
        const onPageLoadSuccess = (page: PDFPageProxy) => {
            rotation.setOriginalSize({ height: page.view[3], width: page.view[2] });
        };
        const setScreenSizeExcludingMargin = (size: Size) => {
            scaling.setScreenSize({
                height: size.height - themeSpacingToNumber(theme.spacing(CONTENT_MARGIN * 2)),
                width: size.width - themeSpacingToNumber(theme.spacing(CONTENT_MARGIN * 2)),
            });
        };

        useImperativeHandle(ref, () => ({ print: onPrint }), [onPrint]);

        return (
            <AutoSizer onResize={setScreenSizeExcludingMargin}>
                {(size) => (
                    <Box display={isLoaded ? 'flex' : 'none'} {...size} overflow="auto">
                        <Box margin="auto" p={CONTENT_MARGIN}>
                            {data && (
                                <Document file={data} onLoadError={onLoadError} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page
                                        onLoadError={onLoadError}
                                        onLoadSuccess={onPageLoadSuccess}
                                        pageNumber={paging?.pageNumber ?? 1}
                                        rotate={rotation.value}
                                        scale={scaling.value}
                                    />
                                </Document>
                            )}
                        </Box>
                    </Box>
                )}
            </AutoSizer>
        );
    }
);

PdfViewerComponent.displayName = 'PdfViewerComponent';
export default PdfViewerComponent;
