import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import type { RequiredUserPreferences } from '~/components/UserPreferences';

import { SectionName } from './constants';

export interface MessageDetailsUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const defaultMessageDetailsUserPreferences: MessageDetailsUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 12,
    sectionOrder: [
        SectionName.MESSAGEINFO,
        SectionName.MINIMAP,
        SectionName.LOCATION,
        SectionName.WORKFLOWBODY,
        SectionName.ATTACHMENTS,
        SectionName.METADATA,
    ],
};

export const requiredMessageDetailsUserPreferencesFactory = (userPreferencesKey: string): RequiredUserPreferences => ({
    defaultState: defaultMessageDetailsUserPreferences,
    key: userPreferencesKey,
});
