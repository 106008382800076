import { Avatar, Tooltip } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { AssetAvatarClassKey } from './styles';

export interface AssetAvatarProps extends StyledComponentProps<AssetAvatarClassKey> {
    badge?: JSX.Element;
    icon: JSX.Element;
    tooltip?: string;
}

export interface AssetAvatarInnerProps
    extends Omit<AssetAvatarProps, 'classes'>,
        InjectedTranslationProps,
        WithStyles<AssetAvatarClassKey> {}

export const AssetAvatarComponent: React.FC<AssetAvatarInnerProps> = ({ badge, classes, icon, tooltip }) => {
    const avatar = (
        <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar}>{icon}</Avatar>
            {badge && <span className={classes.badge}>{badge}</span>}
        </div>
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{avatar}</Tooltip>;
    }

    return avatar;
};
