import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DefaultHeaderClassKey = 'checkbox' | 'itemsCounter' | 'root' | 'searchIcon' | 'toggleExpandAllButton';

export const DefaultHeaderStyleRules: StyleRulesCallback<Theme, {}, DefaultHeaderClassKey> = (theme: Theme) =>
    createStyles({
        checkbox: {
            '&:hover': {
                backgroundColor: 'inherit',
            },
            width: 40,
        },
        itemsCounter: {
            flexGrow: 1,
            marginLeft: theme.spacing(),
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            height: 24,
            margin: theme.spacing(1.5, 0),
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
        },
        searchIcon: {
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            margin: theme.spacing(),
        },
        toggleExpandAllButton: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            width: 24,
        },
    });
