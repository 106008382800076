import type { FC } from 'react';

import { isUndefined } from '../../utility';
import { NumberInput } from '../NumberInput';

import type { ConvertedNumberInputProps } from './models';

const ConvertedNumberInput: FC<ConvertedNumberInputProps> = (props) => {
    const { convertFromInputValue, convertToInputValue, onChange, value, ...restProps } = props;

    const convertedValue = isUndefined(value) ? undefined : Number(convertToInputValue(value));
    const handleOnChange = (numberValue: number | undefined) => {
        if (!isUndefined(numberValue)) {
            const convertedNumber = convertFromInputValue(numberValue);
            const rounded = Number(convertedNumber);
            onChange?.(rounded);
        } else {
            onChange?.(undefined);
        }
    };

    return <NumberInput {...restProps} onChange={handleOnChange} value={convertedValue} />;
};

ConvertedNumberInput.displayName = 'ConvertedNumberInput';
export { ConvertedNumberInput };
