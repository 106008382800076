import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { formatDate } from '~/services/Formatters';

export interface DateFormatterProps {
    format?: string;
    value?: Date;
}

export interface DateFormatterInnerProps extends DateFormatterProps {}

const DateFormatter: React.FunctionComponent<DateFormatterInnerProps> = ({ format, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatDate(value, format)}</>;
};
DateFormatter.displayName = 'DateFormatter';

export { DateFormatter };
