import { createSelector } from 'reselect';

import type { StaticDataStoreState } from '~/common';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { DynamicMonitoringStoreState } from '~/data/monitoring';
import { dynamicMonitoringStateSelector } from '~/data/monitoring';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type {
    Position,
    ResolvedTrailerConnection,
    ResolvedVehicleConnection,
    Securables,
    Trailer,
    Vehicle,
    VehicleInhibitorCapabilities,
} from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import type { InhibitorStatusEnum } from '~/services/ApiClient/models';

export interface AssetContextMenuReduxProps {
    assetId?: number;
    assetType?: AssetType;
}

export interface AssetContextMenuReduxState {
    allowDriverStatus?: boolean;
    allowMessaging?: boolean;
    allowTrailers?: boolean;
    assetPosition?: Position;
    canConnectTrailer?: boolean;
    canConnectVehicle?: boolean;

    canDisconnectTrailer?: boolean;
    canDisconnectVehicle?: boolean;

    canUseInhibitor?: boolean;
    canViewHistory?: boolean;
    canViewMap?: boolean;
    hasConversation?: boolean;
    hasPosition?: boolean;
    inhibitorStatus?: InhibitorStatusEnum;
    trailer?: Trailer;
    trailerConnection?: ResolvedTrailerConnection;
    vehicle?: Vehicle;

    vehicleConnection?: ResolvedVehicleConnection;
    vehicleSupportsInhibitorService?: boolean;
}

export const mapStateToProps = (
    staticDataStoreState: StaticDataStoreState,
    dynamicMonitoring: DynamicMonitoringStoreState,
    vehicleInhibitorCapabilities: NumericDictionary<VehicleInhibitorCapabilities>,
    securables: Securables,
    ownProps: AssetContextMenuReduxProps
): AssetContextMenuReduxState => {
    if (isUndefined(ownProps.assetId) || !ownProps.assetType) {
        return {};
    }

    switch (ownProps.assetType) {
        case AssetType.Container: {
            return {
                assetPosition: dynamicMonitoring.containerStatus.data[ownProps.assetId]?.location?.position,
                canViewHistory: securables.monitoring.historicPosition,
                canViewMap: securables.monitoring.map,
                hasPosition: !!dynamicMonitoring.containerStatus.data[ownProps.assetId]?.location,
            };
        }
        case AssetType.Vehicle: {
            const trailerAccess = securables.monitoring.trailers;
            const conversationAccess = securables.messaging.view;
            const vehicleConnection = dynamicMonitoring.vehicleConnections.data[ownProps.assetId];
            const vehicleSupportsInhibitorService =
                !!vehicleInhibitorCapabilities[ownProps.assetId]?.supportsInhibitorService;

            return {
                allowDriverStatus: securables.monitoring.driverStatus,
                allowMessaging: conversationAccess,
                allowTrailers: trailerAccess,
                assetPosition: dynamicMonitoring.vehicleStatus.data[ownProps.assetId]?.position,
                canConnectTrailer:
                    trailerAccess &&
                    vehicleConnection?.canManuallyConnectTrailers &&
                    !vehicleConnection?.trailerConnection,
                canDisconnectTrailer: trailerAccess && vehicleConnection?.canManuallyDisconnect,
                canUseInhibitor: securables.services.inhibitorService && securables.messaging.startInhibitor,

                canViewHistory: securables.monitoring.historicPosition,
                canViewMap: securables.monitoring.map,

                hasPosition: !!dynamicMonitoring.vehicleStatus.data[ownProps.assetId]?.position,
                inhibitorStatus: dynamicMonitoring.inhibitorStatus.data[ownProps.assetId]?.status,
                trailerConnection: vehicleConnection?.trailerConnection && {
                    isManuallyConnected: vehicleConnection.trailerConnection.isManuallyConnected,
                    trailerReference: vehicleConnection.trailerConnection.trailer,
                },
                vehicle: staticDataStoreState.vehicles.data.hash[ownProps.assetId],
                vehicleSupportsInhibitorService,
            };
        }
        case AssetType.Trailer: {
            const vehicleConnection = dynamicMonitoring.vehicleConnectionsByTrailer[ownProps.assetId];

            return {
                assetPosition: dynamicMonitoring.trailerStatus.data[ownProps.assetId]?.location?.position,
                canConnectVehicle: !vehicleConnection,
                canDisconnectVehicle: !!vehicleConnection?.canManuallyDisconnect,
                canViewHistory: securables.monitoring.historicPosition,

                canViewMap: securables.monitoring.map,
                hasPosition: !!dynamicMonitoring.trailerStatus.data[ownProps.assetId]?.location,
                trailer: staticDataStoreState.trailers.data.hash[ownProps.assetId],
                vehicleConnection: vehicleConnection && {
                    canManuallyDisconnect: vehicleConnection.canManuallyDisconnect,
                    isManuallyConnected: !!vehicleConnection.trailerConnection?.isManuallyConnected,
                    vehicleReference: vehicleConnection.vehicle,
                },
            };
        }
        default:
            throw new Error(`Not supported asset type ${ownProps.assetType}`);
    }
};

export const reduxPropsSelector: (
    storeState: StoreState,
    ownProps: AssetContextMenuReduxProps
) => AssetContextMenuReduxState = createSelector(
    staticDataStoreStateSelector,
    dynamicMonitoringStateSelector,
    settingDataSelector(SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES),
    settingDataSelector(SettingsKey.SECURABLES),
    (_: never, ownProps: AssetContextMenuReduxProps) => ownProps,
    mapStateToProps
);
