import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RecipientsListClassKey =
    | 'avatar'
    | 'chipCloseIcon'
    | 'chipsIcon'
    | 'chipsValue'
    | 'delimiter'
    | 'firstLine'
    | 'flex'
    | 'flexColumn'
    | 'groupName'
    | 'input'
    | 'listItemDivider'
    | 'option'
    | 'optionPadding'
    | 'rootDiv'
    | 'secondaryIdentifier'
    | 'secondaryTextPlaceholder'
    | 'secondLine';

export const maxInputRows = 2;

export const RecipientsListStyleRules: StyleRulesCallback<Theme, {}, RecipientsListClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.vehicle.value,
            marginRight: theme.spacing(),
        },
        chipCloseIcon: {
            height: 12,
            width: 12,
        },
        chipsIcon: {
            '& > *': {
                height: 12,
                width: 12,
            },
            alignItems: 'center',
            color: theme.palette.text.secondary,
            display: 'flex',
            marginRight: theme.spacing(0.5),
        },
        chipsValue: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 60,
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        firstLine: {
            fontSize: 12,
        },
        flex: {
            display: 'flex',
        },
        flexColumn: {
            alignItems: 'center',
            display: 'flex',
            width: '100%',
        },
        groupName: {
            alignItems: 'center',
            display: 'flex',
            paddingLeft: theme.spacing(2),
            position: 'relative',
        },
        input: {
            '&::placeholder': {
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontStyle: 'italic',
                opacity: 1,
            },
        },
        listItemDivider: {
            bottom: 0,
            left: 64,
            position: 'absolute',
            right: 0,
        },
        option: {
            '&[aria-disabled="true"]': {
                cursor: 'default',
                pointerEvents: 'initial',
            },
            padding: theme.spacing(0, 0, 0, 2),
        },
        optionPadding: {
            padding: theme.spacing(1, 0),
        },
        rootDiv: {
            margin: theme.spacing(1, 2),
        },
        secondaryIdentifier: {
            opacity: 0.54,
        },
        secondaryTextPlaceholder: {
            height: 20,
        },
        secondLine: {
            fontSize: 12,
        },
    });
