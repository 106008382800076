import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { InhibitorDisabledIcon } from '~/components/Icons/InhibitorDisabledIcon';
import { InhibitorEnabledIcon } from '~/components/Icons/InhibitorEnabledIcon';
import type { Classification } from '~/services/ApiClient';
import { InhibitorStatusKeys } from '~/services/ApiClient';

import type { InhibitorIconFormatterClassKey } from './styles';

export interface InhibitorIconFormatterProps {
    value: Classification;
}

export interface InhibitorIconFormatterInnerProps
    extends InhibitorIconFormatterProps,
        WithStyles<InhibitorIconFormatterClassKey> {}

export const InhibitorIconFormatterComponent: React.FC<InhibitorIconFormatterInnerProps> = ({ classes, value }) => {
    let enabled = false;
    if (value.key === InhibitorStatusKeys.ENABLED || value.key === InhibitorStatusKeys.ENABLEDBYIBUTTON) {
        enabled = true;
    }
    if (value.key === InhibitorStatusKeys.UNKNOWN) {
        return null;
    }
    const inhibitorIcon = enabled ? <InhibitorEnabledIcon /> : <InhibitorDisabledIcon />;
    const avatarClassName = classNames(classes.iconSize, {
        [classes.inhibitorDisabled]: !enabled,
        [classes.inhibitorEnabled]: enabled,
    });
    return <Avatar className={avatarClassName}>{inhibitorIcon}</Avatar>;
};
