import { Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { logEvent } from '~/services/Analytics';
import type { WorkflowFieldValue } from '~/services/ApiClient';
import {
    BooleanFieldWorkflowFormElement,
    BooleanWorkflowFieldValue,
    DateTimeDisplayPrecision,
    DateTimeFieldWorkflowFormElement,
    DateTimeWorkflowFieldValue,
    DurationFieldWorkflowFormElement,
    DurationWorkflowFieldValue,
    EnumFieldWorkflowFormElement,
    EnumWorkflowFieldValue,
    NumericFieldWorkflowFormElement,
    NumericWorkflowFieldValue,
    PositionFieldWorkflowFormElement,
    PositionWorkflowFieldValue,
    RawFieldWorkflowFormElement,
    RawWorkflowFieldValue,
    TextFieldWorkflowFormElement,
    TextWorkflowFieldValue,
} from '~/services/ApiClient';
import {
    DurationFormat,
    FormatLength,
    formatDate,
    formatDateTime,
    formatDuration,
    formatTime,
} from '~/services/Formatters';

import { GeolocationField } from './components/GeolocationField';
import type { WorkflowReadOnlyFieldInnerProps } from './models';
import { useStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/ban-types
const valueTypeMapping = new Map<Function, Function>([
    [BooleanFieldWorkflowFormElement, BooleanWorkflowFieldValue],
    [DateTimeFieldWorkflowFormElement, DateTimeWorkflowFieldValue],
    [DurationFieldWorkflowFormElement, DurationWorkflowFieldValue],
    [EnumFieldWorkflowFormElement, EnumWorkflowFieldValue],
    [NumericFieldWorkflowFormElement, NumericWorkflowFieldValue],
    [PositionFieldWorkflowFormElement, PositionWorkflowFieldValue],
    [RawFieldWorkflowFormElement, RawWorkflowFieldValue],
    [TextFieldWorkflowFormElement, TextWorkflowFieldValue],
]);

const getFiledTypeTranslation = (value: WorkflowFieldValue, t: SingleTFunction): string => {
    switch (value.constructor) {
        case RawWorkflowFieldValue:
            return t('workflow-raw-field-type');

        case TextWorkflowFieldValue:
            return t('workflow-text-field-type');

        case PositionWorkflowFieldValue:
            return t('workflow-position-field-type');

        case NumericWorkflowFieldValue:
            return t('workflow-numeric-field-type');

        case BooleanWorkflowFieldValue:
            return t('workflow-boolean-field-type');

        case EnumWorkflowFieldValue:
            return t('workflow-enum-field-type');

        case DateTimeWorkflowFieldValue:
            return t('workflow-date-time-field-type');

        case DurationWorkflowFieldValue:
            return t('workflow-duration-field-type');

        default:
            return t('workflow-unknown-field-type');
    }
};

const WorkflowReadOnlyField: FC<WorkflowReadOnlyFieldInnerProps> = (props) => {
    const { dataId, field, label, value } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const expectedValueType = valueTypeMapping.get(field.constructor);
    if (value && expectedValueType) {
        if (!(value instanceof expectedValueType)) {
            throw new Error(`Expected value type '${expectedValueType.name}', but got '${value.constructor.name}'`);
        }
    }

    if (value?.invalidValue) {
        logEvent('messaging', 'invalid-workflow-field', 'Invalid workflow field value', { value: field.id });

        return (
            <Tooltip
                data-id={`invalid-value-tooltip-${dataId}`}
                title={t('workflow-field-invalid-value', { filedType: getFiledTypeTranslation(value, t) })}
            >
                <TextField
                    data-id={`invalid-value-${dataId}`}
                    fullWidth
                    InputProps={{
                        classes: {
                            disabled: classes.readOnlyUnderlineDisabled,
                            input: classes.invalidValue,
                            root: classes.readOnlyRoot,
                            underline: classes.readOnlyUnderline,
                        },
                        readOnly: true,
                    }}
                    label={label}
                    size="small"
                    value={value?.invalidValue}
                    variant="filled"
                />
            </Tooltip>
        );
    }

    if (field instanceof RawFieldWorkflowFormElement) {
        const rawValue = value as RawWorkflowFieldValue | undefined;

        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={rawValue?.value}
                variant="filled"
            />
        );
    }

    if (field instanceof TextFieldWorkflowFormElement) {
        const textValue = value as TextWorkflowFieldValue | undefined;
        const textInputClassName = classes[`${field.horizontalAlignment}Alignment`];
        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        input: textInputClassName,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={textValue?.value}
                variant="filled"
            />
        );
    }

    if (field instanceof NumericFieldWorkflowFormElement) {
        const numericValue = value as NumericWorkflowFieldValue | undefined;
        const numericInputClassName = classes[`${field.horizontalAlignment}Alignment`];
        const formattedValue = numericValue?.value?.toFixed(field.decimals) ?? '';

        const formattedValueWithUnit = (field.displayFormat ?? '{0}').replace('{0}', formattedValue);

        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        input: numericInputClassName,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={formattedValueWithUnit}
                variant="filled"
            />
        );
    }

    if (field instanceof BooleanFieldWorkflowFormElement) {
        const booleanValue = value as BooleanWorkflowFieldValue | undefined;

        const checkbox = (
            <Checkbox checked={booleanValue?.value} data-id={dataId} disableRipple inputProps={{ readOnly: true }} />
        );
        return label
            ? checkbox && <FormControlLabel control={checkbox} label={label} labelPlacement="end" />
            : checkbox;
    }

    if (field instanceof EnumFieldWorkflowFormElement) {
        const enumValue = value as EnumWorkflowFieldValue | undefined;

        const selectedValue = field.options.find((it) => it.id === enumValue?.optionId);

        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={selectedValue?.text}
                variant="filled"
            />
        );
    }

    if (field instanceof DateTimeFieldWorkflowFormElement) {
        const dateTimeValue = value as DateTimeWorkflowFieldValue | undefined;
        let formatted;

        if (dateTimeValue?.value) {
            const includeDate =
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Date;
            const includeTime =
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Time;
            if (includeDate === includeTime) {
                formatted = formatDateTime(dateTimeValue?.value);
            } else if (includeDate) {
                formatted = formatDate(dateTimeValue?.value);
            } else {
                formatted = formatTime(dateTimeValue?.value);
            }
        }

        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={formatted}
                variant="filled"
            />
        );
    }

    if (field instanceof DurationFieldWorkflowFormElement) {
        const durationField = value as DurationWorkflowFieldValue | undefined;

        const formattedDuration = formatDuration(moment.duration(durationField?.value), {
            durationFormat: DurationFormat.DayHourMinute,
            durationFormatLength: FormatLength.Short,
        });

        return (
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={formattedDuration}
                variant="filled"
            />
        );
    }

    if (field instanceof PositionFieldWorkflowFormElement) {
        return <GeolocationField dataId={dataId} label={label} value={value} />;
    }

    throw new Error(`Unhandled form element of type '${field.constructor.name}'`);
};

WorkflowReadOnlyField.displayName = 'WorkflowReadOnlyField';
export default WorkflowReadOnlyField;
