import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';
import { getWeightUnitSystemUtils } from '~/services/Formatters';

import type { WeightFormatterProps } from './model';

const WeightFormatter: FC<WeightFormatterProps> = (props) => {
    const { precision, unitSystem, value } = props;
    const { t } = useTranslation();
    if (isUndefined(value)) {
        return null;
    }

    return <>{getWeightUnitSystemUtils(t, unitSystem).formatter(value, { precision })}</>;
};

WeightFormatter.displayName = 'WeightFormatter';

export { WeightFormatter };
