import createStyles from '@mui/styles/createStyles';

export type PositionGroupRouteClassKey = 'box' | 'movementPopup';

export const PositionGroupRouteStyleRules = createStyles({
    box: {
        '& > :first-child': {
            display: 'block',
        },
        '& > p > :last-child': {
            marginLeft: '20px',
        },
        padding: '0 10px',
    },

    movementPopup: {
        '@global .leaflet-popup-tip-container ': {
            left: '50%',
        },
        left: '-75px !important',
    },
});
