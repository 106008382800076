import { createAction } from 'redux-actions';

import { ApiClient } from '~/services/ApiClient';

import { ACTIONS_TYPE } from './actionTypes';

const getReportSettings = createAction(ACTIONS_TYPE.LIST, () => ApiClient.getReportSettings());
const getDefaultValues = createAction(ACTIONS_TYPE.TEMPLATE, () => ApiClient.getReportTemplate());

export { getDefaultValues, getReportSettings };
