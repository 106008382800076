import { IconButton, Tooltip as MuiTooltip } from '@mui/material';
import type { IconButtonProps } from '@mui/material/IconButton';
import * as React from 'react';

import { CloseOnClickTooltip } from '~/components/CloseOnClickTooltip';

/* Common problem on material-ui v4, several issues still open since several months,
 check https://github.com/mui-org/material-ui/issues/15827 */
interface ExtendedMuiIconButtonProps extends IconButtonProps {
    component?: React.ElementType;
}

export interface TitledIconButtonProps extends ExtendedMuiIconButtonProps {
    closeTooltipOnClick?: boolean;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    title?: string;
}

export interface TitledIconButtonInnerProps extends TitledIconButtonProps {}

const TitledIconButton: React.FunctionComponent<TitledIconButtonInnerProps> = ({
    children,
    closeTooltipOnClick,
    placement,
    title,
    ...restProps
}) => {
    const button = (
        <IconButton {...restProps} size="large">
            {children}
        </IconButton>
    );

    if (!title) {
        return button;
    }

    const Tooltip = closeTooltipOnClick ? CloseOnClickTooltip : MuiTooltip;

    /**
     * there is a need to wrap the button in a span element in order for Materia-UI
     * to detect mouse events and be able to show tooltip when the button is disabled
     * (this is because disable buttons do not trigger mouse events)
     */

    return (
        <Tooltip placement={placement} title={title}>
            <span>{button}</span>
        </Tooltip>
    );
};
TitledIconButton.displayName = 'TitledIconButton';

export { TitledIconButton };
