import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import type { StaticDataStoreState } from '~/common';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type { Classification, DeviceType, Securables, VehicleCategory, VehicleType } from '~/services/ApiClient';

import { getColumnsMemoized } from '../../grid';

import type {
    VehicleAdministrationListViewHeaderInnerProps,
    VehicleAdministrationListViewHeaderProps,
} from './component';
import { VehicleAdministrationListViewHeaderComponent } from './component';

export interface ReduxProps {
    billingGroups: NumericDictionary<Classification>;
    billingStatuses: NumericDictionary<Classification>;
    deviceTypes: NumericDictionary<DeviceType>;
    fuelTankTypes: NumericDictionary<Classification>;
    imageCapturingTypes: NumericDictionary<Classification>;
    inhibitorServiceTypes: NumericDictionary<Classification>;
    navigationTypes: NumericDictionary<Classification>;
    networks: NumericDictionary<Classification>;
    securables: Securables;
    tachoGenerations: NumericDictionary<Classification>;
    tachographs: NumericDictionary<Classification>;
    tachoProtocols: NumericDictionary<Classification>;
    tellTaleServiceTypes: NumericDictionary<Classification>;
    vehicleCategories: NumericDictionary<VehicleCategory>;
    vehicleTypes: NumericDictionary<VehicleType>;
    workflowTypes: NumericDictionary<Classification>;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    billingGroups: settingDataSelector(SettingsKey.BILLING_GROUP),
    billingStatuses: settingDataSelector(SettingsKey.BILLING_STATUS),
    deviceTypes: settingDataSelector(SettingsKey.DEVICETYPES),
    fuelTankTypes: settingDataSelector(SettingsKey.FUEL_TANK_TYPE),
    imageCapturingTypes: settingDataSelector(SettingsKey.IMAGE_CAPTURING_TYPE),
    inhibitorServiceTypes: settingDataSelector(SettingsKey.INHIBITOR_SERVICE_TYPE),
    navigationTypes: settingDataSelector(SettingsKey.NAVIGATION_TYPE),
    networks: settingDataSelector(SettingsKey.NETWORK),
    securables: settingDataSelector(SettingsKey.SECURABLES),
    tachoGenerations: settingDataSelector(SettingsKey.TACHO_GENERATION),
    tachographs: settingDataSelector(SettingsKey.TACHOGRAPH),
    tachoProtocols: settingDataSelector(SettingsKey.TACHO_PROTOCOL),
    tellTaleServiceTypes: settingDataSelector(SettingsKey.TELL_TALE_SERVICE_TYPE),
    vehicleCategories: settingDataSelector(SettingsKey.VEHICLECATEGORIES),
    vehicleTypes: settingDataSelector(SettingsKey.VEHICLETYPES),
    workflowTypes: settingDataSelector(SettingsKey.WORKFLOW_TYPE),
});

export const VehicleAdministrationListViewHeader = compose<
    VehicleAdministrationListViewHeaderInnerProps,
    VehicleAdministrationListViewHeaderProps
>(
    setDisplayName('VehicleAdministrationListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    connect(staticDataStoreStateSelector),
    withProps(
        (ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps & StaticDataStoreState) => ({
            columns: getColumnsMemoized({
                billingGroups: ownProps.billingGroups,
                billingStatuses: ownProps.billingStatuses,
                depots: ownProps.depots.data.hash,
                deviceTypes: ownProps.deviceTypes,
                fuelTankTypes: ownProps.fuelTankTypes,
                imageCapturingTypes: ownProps.imageCapturingTypes,
                inhibitorServiceTypes: ownProps.inhibitorServiceTypes,
                navigationTypes: ownProps.navigationTypes,
                networks: ownProps.networks,
                servicesSecurables: ownProps.securables.services,
                t: ownProps.t,
                tachoGenerations: ownProps.tachoGenerations,
                tachographs: ownProps.tachographs,
                tachoProtocols: ownProps.tachoProtocols,
                tellTaleServiceTypes: ownProps.tellTaleServiceTypes,
                unitSystem: ownProps.displayPreferences.unitSystem,
                vehicleCategories: ownProps.vehicleCategories,
                vehicleTypes: ownProps.vehicleTypes,
                workflowTypes: ownProps.workflowTypes,
            }),
            gridDataId: 'administration-header-vehicle',
        })
    )
)(VehicleAdministrationListViewHeaderComponent);
