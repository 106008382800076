export const RTDSSCHEDULES_USERPREFERENCES_KEY = 'rtdsschedules-state';

export const RTDSSCHEDULES_ASSETSELECTOR_USERPREFERENCES_KEY = 'rtdsschedules-assetselector-state';

export interface RtdsSchedulesUserPreferences {
    leftPaneIsOpen: boolean;
}

export const defaultRtdsSchedulesUserPreferences: RtdsSchedulesUserPreferences = {
    leftPaneIsOpen: true,
};

export const requiredRtdsSchedulesUserPreferences = {
    defaultState: defaultRtdsSchedulesUserPreferences,
    key: RTDSSCHEDULES_USERPREFERENCES_KEY,
};
