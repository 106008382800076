import { Backdrop, CircularProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';

import type { TriggerDataRefresh } from '~/common';
import { AssetSelector, useStaticDataProvider } from '~/common';
import { RefreshSceneBar, RefreshSceneBarState } from '~/common/components/RefreshSceneBar';
import { AskForPageReload } from '~/components/AskForPageReload';
import type { ContextMenuPosition } from '~/components/ContextMenu';
import { DateTimeRangePicker } from '~/components/DateTimeRangePicker';
import type { GridRowId } from '~/components/Grid';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PageTemplate } from '~/components/PageTemplate';
import { SceneLoader } from '~/components/SceneLoader';
import { debounce } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import { AssetType, QueryRtdsSessionsRequestRequestMode } from '~/services/ApiClient';

import { RtdsSessionsListView, RtdsSessionsListViewHeader } from './components/List';
import { RtdsSessionContextMenu } from './components/RtdsSessionContextMenu';
import { RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY } from './preferences';
import type { RtdsSessionsReduxProps } from './redux';
import type { RtdsSessionsClassKeys } from './styles';

export interface RtdsSessionsProps {}
export interface RtdsSessionsInnerProps
    extends RtdsSessionsProps,
        InjectedTranslationProps,
        RtdsSessionsReduxProps,
        WithStyles<RtdsSessionsClassKeys> {}

export interface RtdsSessionsComponentState {
    requestModeState: QueryRtdsSessionsRequestRequestMode;
    rtdsSessionContextMenu?: {
        position: ContextMenuPosition;
        sessionId: number;
    };
    triggerDataRefresh: TriggerDataRefresh;
}

export const RtdsSessionsComponent: FC<RtdsSessionsInnerProps> = (props) => {
    const {
        authorized,
        changeDateTimeRange,
        classes,
        clearData,
        contentLoading,
        dateTimeRange,
        dateTimeThreshold,
        getRtdsSessions,
        getVehicleStatus,
        leftPaneIsOpen,
        loading,
        rejected,
        rtdsSessionEntries,
        selectedDriverIds,
        selectedVehicleIds,
        t,
        toggleLeftPaneVisibility,
    } = props;

    const staticDataProvider = useStaticDataProvider();
    const [state, setState] = useState<RtdsSessionsComponentState>({
        requestModeState: QueryRtdsSessionsRequestRequestMode.All,
        triggerDataRefresh: {
            initialLoad: true,
            showBanner: false,
            triggered: false,
        },
    });

    const onCloseRefreshSceneBar = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                initialLoad: false,
                showBanner: false,
                triggered: prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const onRefreshData = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                initialLoad: false,
                showBanner: true,
                triggered: !prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const onChangeRequestMode = useCallback(() => {
        if (state.requestModeState === QueryRtdsSessionsRequestRequestMode.All) {
            setState((prevState) => ({
                ...prevState,
                requestModeState: QueryRtdsSessionsRequestRequestMode.MostRecent,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                requestModeState: QueryRtdsSessionsRequestRequestMode.All,
            }));
        }
    }, [state.requestModeState]);

    const dispatchGetRtdsSessionsDebounced = useMemo(
        () =>
            debounce(
                (
                    startDateTime: Date,
                    stopDateTime: Date,
                    requestMode: QueryRtdsSessionsRequestRequestMode,
                    vehicleIds: number[] | undefined,
                    driverIds: number[] | undefined
                ) => {
                    getRtdsSessions(startDateTime, stopDateTime, requestMode, vehicleIds, driverIds);
                },
                500
            ),
        [getRtdsSessions]
    );

    const closeContextMenu = useCallback(
        () => setState((prevState) => ({ ...prevState, rtdsSessionContextMenu: undefined })),
        []
    );

    useEffect(() => {
        dispatchGetRtdsSessionsDebounced(
            dateTimeRange.startDate,
            dateTimeRange.endDate,
            state.requestModeState,
            selectedVehicleIds,
            selectedDriverIds
        );
    }, [
        dateTimeRange.endDate,
        dateTimeRange.startDate,
        dispatchGetRtdsSessionsDebounced,
        selectedDriverIds,
        selectedVehicleIds,
        state.requestModeState,
        state.triggerDataRefresh.triggered,
    ]);

    useEffect(() => {
        staticDataProvider.retrieveVehicles();
        staticDataProvider.retrieveDrivers();
        staticDataProvider.retrieveVehicleStatus();

        return () => clearData();
    }, [clearData, staticDataProvider]);

    useEffect(() => {
        getVehicleStatus();
    }, [getVehicleStatus]);

    if (!authorized) {
        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
    }
    if (rejected && state.triggerDataRefresh.initialLoad) {
        return <AskForPageReload />;
    }

    if (loading && !rejected && !contentLoading) {
        return <SceneLoader />;
    }

    const header = (
        <RtdsSessionsListViewHeader
            contentLoading={contentLoading}
            dataSource={rtdsSessionEntries}
            filters={
                <DateTimeRangePicker onChange={changeDateTimeRange} value={dateTimeRange} {...dateTimeThreshold} />
            }
            gridActionsDisabled={selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0}
            onChangeRequestMode={onChangeRequestMode}
            onRefreshData={onRefreshData}
            requestMode={state.requestModeState}
            title={t('rtds-sessions')}
        />
    );

    const getLeftPane = (): JSX.Element | undefined => {
        if (!leftPaneIsOpen) {
            return undefined;
        }

        const assetTypes: AssetType[] = [AssetType.Driver, AssetType.Vehicle];

        return (
            <AssetSelector assetTypes={assetTypes} preferencesKey={RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY} />
        );
    };

    const onRowContextMenu = (evt: MouseEvent, rowId: GridRowId) => {
        setState({
            ...state,
            rtdsSessionContextMenu: {
                position: { clientX: evt.clientX, clientY: evt.clientY },
                sessionId: rowId as number,
            },
        });
        evt.preventDefault();
        evt.stopPropagation();
    };

    const getViewContent = (): JSX.Element | string => {
        if (selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0) {
            return (
                <div className={classes.messageText} data-id="no-selection">
                    {t('no-assets-selected')}
                </div>
            );
        }

        return (
            <>
                <Backdrop
                    className={classes.backDrop}
                    data-id="back-drop"
                    open={contentLoading && !!state.triggerDataRefresh.initialLoad}
                >
                    <CircularProgress className={classes.loader} data-id="backdrop-loader" size={120} />
                </Backdrop>
                <RtdsSessionsListView
                    dataSource={rtdsSessionEntries}
                    highlightedAssetId={state.rtdsSessionContextMenu?.sessionId}
                    onRowContextMenu={onRowContextMenu}
                />
            </>
        );
    };

    const getTopBanner = () => {
        if (
            (contentLoading || rejected) &&
            !state.triggerDataRefresh.initialLoad &&
            state.triggerDataRefresh.showBanner
        ) {
            return (
                <RefreshSceneBar
                    disableClose={contentLoading}
                    onClose={onCloseRefreshSceneBar}
                    state={rejected ? RefreshSceneBarState.ERROR : RefreshSceneBarState.REFRESHING}
                    stateAction={rejected ? onRefreshData : undefined}
                />
            );
        }
        return undefined;
    };

    const leftPaneTitle = t(leftPaneIsOpen ? 'collapse-asset-selector' : 'expand-asset-selector');

    return (
        <div className={classes.root} data-id="rtds-session">
            <PageTemplate
                header={header}
                leftPaneElement={getLeftPane()}
                leftPaneToggleTitle={leftPaneTitle}
                toggleLeftPaneVisibility={toggleLeftPaneVisibility}
                topPaneElement={getTopBanner()}
            >
                {getViewContent()}
            </PageTemplate>
            <RtdsSessionContextMenu
                {...state.rtdsSessionContextMenu}
                onAbort={onRefreshData}
                onClose={closeContextMenu}
            />
        </div>
    );
};
