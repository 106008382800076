import type { Dispatch } from 'redux';

import type { RetrievableData } from '~/reducers';
import { reportError } from '~/reporting';

import { clearTimeSeriesDialogDataAction, retrieveTimeSeriesDialogDataAction } from './data';
import type { TimeSeriesDialogStoreState } from './reducers';

export interface TimeSeriesDialogStateProps<T> {
    timeSeriesData: RetrievableData<T[]>;
}

export interface TimeSeriesDialogDispatchProps<T> {
    clearDialogData: () => void;
    getDialogData: (
        startDate: Date,
        stopDate: Date,
        getData: (startDate: Date, stopDate: Date) => Promise<T[]>
    ) => void;
}

export interface TimeSeriesDialogReduxProps<T>
    extends TimeSeriesDialogStateProps<T>,
        TimeSeriesDialogDispatchProps<T> {}

export const mapStateToProps = (state: TimeSeriesDialogStoreState): TimeSeriesDialogStateProps<unknown> => ({
    timeSeriesData: state.timeSeriesData,
});

export const mapDispatchProps = (dispatch: Dispatch): TimeSeriesDialogDispatchProps<unknown> => ({
    clearDialogData: () => {
        dispatch(clearTimeSeriesDialogDataAction());
    },
    getDialogData: (
        startDate: Date,
        stopDate: Date,
        getData: (startDate: Date, stopDate: Date) => Promise<unknown[]>
    ) => {
        dispatch(retrieveTimeSeriesDialogDataAction(startDate, stopDate, getData)).catch(reportError);
    },
});
