import { isUndefined } from '~/libs/utility';

import type { ComputeNumberOfChangeArgs } from './models';

const defaultEqualityComparer = <T>(a: T, b: T) => a === b;

export const computeNumberOfChanges = <T>({
    after,
    attributeDefinitions,
    before,
}: ComputeNumberOfChangeArgs<T>): number => {
    let changesCount = 0;

    attributeDefinitions.forEach((attrDef) => {
        const oldValue = attrDef.getValue(before);
        const newValue = attrDef.getValue(after);
        const equalityComparer = attrDef.equalityComparer ?? defaultEqualityComparer;

        if (
            isUndefined(oldValue) !== isUndefined(newValue) ||
            (!isUndefined(oldValue) && !equalityComparer(oldValue, newValue))
        ) {
            changesCount += 1;
        }
    });

    return changesCount;
};
