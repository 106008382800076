import classNames from 'classnames';
import type { FC } from 'react';
import { Popup } from 'react-leaflet';

import type { StyledPopupProps } from './models';
import { useStyles } from './styles';

const StyledPopup: FC<StyledPopupProps> = (props) => {
    const { children, className, forwardedRef, ...restProps } = props;
    const classes = useStyles();

    return (
        <Popup className={classNames(classes.popup, className)} ref={forwardedRef} {...restProps}>
            {children}
        </Popup>
    );
};

StyledPopup.displayName = 'StyledPopup';
export default StyledPopup;
