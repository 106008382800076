import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type VehicleTypeChangelogsSubpageClassKeys = 'loaderContainer' | 'messageText';

export const useStyles = makeStyles<Theme, {}, VehicleTypeChangelogsSubpageClassKeys>((theme) => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    messageText: {
        display: 'flex',
        fontStyle: 'italic',
        justifyContent: 'center',
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));
