import * as React from 'react';
import type { Subtract } from 'utility-types';

import type {
    AvailableIconProps,
    DrivingIconProps,
    RestingIconProps,
    WorkingIconProps,
} from '~/components/DriverActivity';
import { AvailableIcon, DrivingIcon, RestingIcon, WorkingIcon } from '~/components/DriverActivity';
import { DriverActivityKeys } from '~/services/ApiClient';

export interface IconFormatterProps {
    iconProps?: Subtract<
        AvailableIconProps | DrivingIconProps | RestingIconProps | WorkingIconProps,
        { ref?: unknown }
    >;
    value?: string;
}

const IconFormatter: React.FunctionComponent<IconFormatterProps> = ({ iconProps, value }) => {
    switch (value) {
        case DriverActivityKeys.DRIVE: {
            return <DrivingIcon {...iconProps} />;
        }

        case DriverActivityKeys.WORK: {
            return <WorkingIcon {...iconProps} />;
        }

        case DriverActivityKeys.AVAILABLE: {
            return <AvailableIcon {...iconProps} />;
        }

        case DriverActivityKeys.REST: {
            return <RestingIcon {...iconProps} />;
        }

        default: {
            return null;
        }
    }
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
