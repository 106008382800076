import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps, SingleTFunction } from '~/components/LanguageSelector';
import { SendingMessageDisabledReason } from '~/modules/Communication/models';
import { MessageBodyType } from '~/services/ApiClient';
import { FormatNewLineToBr } from '~/services/Formatters';

import type { SendingMessagesDisabledClassKey } from './styles';

export interface SendingMessagesDisabledProps {
    textMessageDisabledReason: SendingMessageDisabledReason;
    workflowMessageDisabledReason: SendingMessageDisabledReason;
}

export interface SendingMessagesDisabledInnerProps
    extends SendingMessagesDisabledProps,
        WithStyles<SendingMessagesDisabledClassKey>,
        InjectedTranslationProps {}

export interface DisabledReasonData {
    dataId: string;
    message: string;
}

export const getDisabledReasonData = (
    t: SingleTFunction,
    reason: SendingMessageDisabledReason,
    messageType: MessageBodyType
): DisabledReasonData => {
    const messageTypeTranslation =
        messageType === MessageBodyType.Text ? t('message-type-text') : t('message-type-workflow');
    switch (reason) {
        case SendingMessageDisabledReason.NotAllowed:
            return { dataId: 'sending-messages-disabled', message: t('sending-messages-disabled') };
        case SendingMessageDisabledReason.NotSupportedByDevice:
            return {
                dataId: 'sending-messages-disabled-no-device',
                message: t('sending-messages-disabled-no-device', { messageType: messageTypeTranslation }),
            };
        case SendingMessageDisabledReason.VehicleMesagingServiceDisabled:
            return {
                dataId: 'sending-messages-disabled-vehicle-service-disabled',
                message: t('sending-messages-disabled-vehicle-service-disabled', {
                    messageType: messageTypeTranslation,
                }),
            };
        default:
            throw Error('Not supported SendingMessageDisabledReason.');
    }
};

export const SendingMessagesDisabledComponent: React.FunctionComponent<SendingMessagesDisabledInnerProps> = ({
    classes,
    t,
    textMessageDisabledReason,
    workflowMessageDisabledReason,
}) => {
    let disabledReasonData;

    // Reason enumeration values are sorted by priority and in case they have same value Text reason will have the priority
    if (textMessageDisabledReason <= workflowMessageDisabledReason) {
        disabledReasonData = getDisabledReasonData(t, textMessageDisabledReason, MessageBodyType.Text);
    } else {
        disabledReasonData = getDisabledReasonData(t, workflowMessageDisabledReason, MessageBodyType.Workflow);
    }

    return (
        <Typography
            align="center"
            className={classes.root}
            color="textSecondary"
            component="div"
            data-id={disabledReasonData.dataId}
            variant="caption"
        >
            <FormatNewLineToBr text={disabledReasonData.message} />
        </Typography>
    );
};
