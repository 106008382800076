import { Button, List, ListItem } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { TrailerNameFormatter } from '~/components/Formatters';
import { ConnectIcon, DisconnectIcon } from '~/components/Icons'; // TODO import ReplaceIcon
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { MonitoringVehicleEntry } from '~/data/monitoring';

import { ConnectTrailerDialog, DisconnectTrailerFromVehicleDialog } from '../../../../../Dialogs';

import { Trailer } from './components/Trailer';
import type { TrailersSectionClassKey } from './styles';

export interface TrailersSectionProps {
    dragHandleElement: JSX.Element;
    entry: MonitoringVehicleEntry;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface TrailersSectionInnerProps
    extends TrailersSectionProps,
        WithStyles<TrailersSectionClassKey>,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const TrailersSectionComponent: React.FC<TrailersSectionInnerProps> = ({
    classes,
    displayPreferences,
    dragHandleElement,
    entry,
    isCollapsed,
    t,
    toggleCollapsed,
}) => {
    const [showDisconnectTrailerConfirmationDialog, setShowDisconnectTrailerConfirmationDialog] = React.useState(false);
    const [showConnectTrailerDialog, setShowConnectTrailerDialog] = React.useState(false);

    const trailerOrReference = entry.trailerConnection?.trailer || entry.trailerConnection?.trailerReference;
    const header = trailerOrReference ? (
        <TrailerNameFormatter
            trailerDisplayNameFormat={displayPreferences.trailerDisplayFormat}
            value={trailerOrReference}
        />
    ) : (
        t('not-connected')
    );

    const headerClassName = classNames({
        [classes.disabled]: !entry.trailerConnection?.trailer,
        [classes.notConnected]: !entry.trailerConnection,
    });

    const headerElement = (
        <TitledTruncatedText>
            <span className={headerClassName} data-id="trailer-name">
                {header}
            </span>
        </TitledTruncatedText>
    );

    const handleConnectClick = React.useCallback(() => {
        setShowConnectTrailerDialog(true);
    }, [setShowConnectTrailerDialog]);

    const connectButton = (
        <Button
            className={classes.button}
            color="secondary"
            data-id="connect"
            disabled={!entry.canManuallyConnectTrailers}
            onClick={handleConnectClick}
        >
            <ConnectIcon className={classes.buttonIcon} />
            {t('connect')}
        </Button>
    );

    const handleDisconnectClick = React.useCallback(() => {
        setShowDisconnectTrailerConfirmationDialog(true);
    }, [setShowDisconnectTrailerConfirmationDialog]);

    const disconnectButton = (
        <Button
            className={classes.button}
            color="secondary"
            data-id="disconnect"
            disabled={!entry.canManuallyDisconnect || !entry.trailerConnection?.trailer}
            onClick={handleDisconnectClick}
        >
            <DisconnectIcon className={classes.buttonIcon} />
            {t('disconnect')}
        </Button>
    );

    // TODO uncomment it later

    // const handleReplaceClick = React.useCallback(() => {
    //     setShowConnectTrailerDialog(true);
    // }, [setShowConnectTrailerDialog]);

    // const replaceButton = (
    //     <Button
    //         color="secondary"
    //         disabled={!entry.canManuallyDisconnect || !entry.trailerConnection?.trailer}
    //         onClick={handleReplaceClick}
    //         data-id="replace"
    //         className={classes.button}
    //     >
    //         <ReplaceIcon className={classes.buttonIcon} />
    //         {t('replace')}
    //     </Button>
    // );

    const closeDisconnectDialog = React.useCallback(() => {
        setShowDisconnectTrailerConfirmationDialog(false);
    }, [setShowDisconnectTrailerConfirmationDialog]);

    const handleConnectTrailerDialogClose = React.useCallback(
        () => setShowConnectTrailerDialog(false),
        [setShowConnectTrailerDialog]
    );

    const connectTrailerDialog = showConnectTrailerDialog && (
        <ConnectTrailerDialog
            onClose={handleConnectTrailerDialogClose}
            trailerConnection={entry.trailerConnection}
            vehicle={entry.vehicle}
        />
    );

    const disconnectTrailerDialog = showDisconnectTrailerConfirmationDialog && entry.trailerConnection && (
        <DisconnectTrailerFromVehicleDialog
            onClose={closeDisconnectDialog}
            trailerConnection={entry.trailerConnection}
            vehicle={entry.vehicle}
        />
    );

    return (
        <ReorderableSection
            dataId="trailers"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={headerElement}
            title={t('trailers')}
            toggleCollapsed={toggleCollapsed}
        >
            <List disablePadding>
                <Trailer
                    trailerConnection={entry.trailerConnection}
                    trailerDisplayNameFormat={displayPreferences.trailerDisplayFormat}
                    trailerStatus={entry.trailerConnection?.trailerStatus}
                />

                <ListItem className={classes.buttons}>
                    {!entry.trailerConnection && connectButton}
                    {!!entry.trailerConnection && disconnectButton}
                    {/* {!!entry.trailerConnection && replaceButton} */}
                </ListItem>

                {disconnectTrailerDialog}
                {connectTrailerDialog}
            </List>
        </ReorderableSection>
    );
};
