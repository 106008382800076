import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { generatePath } from 'react-router';

import { CustomLink } from '~/components/CustomLink';
import { IconDivider } from '~/components/IconDivider';
import { ContainerIcon, DriverIcon, TrailerIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { AssetSubpage } from '~/data/monitoring';
import { MonitoringPerspective, MonitoringViewMode } from '~/data/monitoring';

import { MONITORING_PATH_STRUCTURE } from '../../consts';

import type { ModuleViewBarActionsClassKey } from './styles';

export interface ModuleBarActionsProps {
    canViewMap: boolean;
    perspective: MonitoringPerspective;
    selectedAssetId?: number;
    subpage?: AssetSubpage;
    supportedPerspectives: MonitoringPerspective[];
    viewMode: MonitoringViewMode;
}

export interface ModuleBarActionsInnerProps
    extends ModuleBarActionsProps,
        InjectedTranslationProps,
        WithStyles<ModuleViewBarActionsClassKey> {}

interface MonitoringPerspectiveBarItem {
    icon: JSX.Element;
    key: string;
    perspective: MonitoringPerspective;
    title: string;
}

const getPerspectiveBarItems = (): MonitoringPerspectiveBarItem[] => {
    return [
        {
            icon: <TruckIcon />,
            key: 'vehicle',
            perspective: MonitoringPerspective.VEHICLE,
            title: 'switch-to-vehicle-perspective',
        },
        {
            icon: <TrailerIcon />,
            key: 'trailer',
            perspective: MonitoringPerspective.TRAILER,
            title: `switch-to-trailer-perspective`,
        },
        {
            icon: <ContainerIcon />,
            key: 'cotainer',
            perspective: MonitoringPerspective.CONTAINER,
            title: 'switch-to-container-perspective',
        },
        {
            icon: <DriverIcon />,
            key: 'driver',
            perspective: MonitoringPerspective.DRIVER,
            title: 'switch-to-driver-perspective',
        },
    ];
};

const customLink = (url: string) =>
    forwardRef((props: React.HTMLAttributes<HTMLAnchorElement>, ref: React.RefObject<HTMLAnchorElement>) => (
        <CustomLink {...props} innerRef={ref} to={url} />
    ));

export const ModuleBarActionsComponent: React.FC<ModuleBarActionsInnerProps> = ({
    canViewMap,
    classes,
    perspective,
    selectedAssetId,
    subpage,
    supportedPerspectives,
    t,
    viewMode,
}) => {
    const renderViewModeIcon =
        canViewMap &&
        (viewMode === MonitoringViewMode.MAP ? (
            <TitledIconButton
                closeTooltipOnClick
                color="inherit"
                component={customLink(
                    generatePath(MONITORING_PATH_STRUCTURE, {
                        id: selectedAssetId,
                        perspective,
                        subpage,
                        viewMode: MonitoringViewMode.LIST,
                    })
                )}
                data-id="switch-to-list"
                placement="bottom-end"
                title={t('list-view')}
            >
                <ListIcon />
            </TitledIconButton>
        ) : (
            <TitledIconButton
                closeTooltipOnClick
                color="inherit"
                component={customLink(
                    generatePath(MONITORING_PATH_STRUCTURE, {
                        id: selectedAssetId,
                        perspective,
                        subpage,
                        viewMode: MonitoringViewMode.MAP,
                    })
                )}
                data-id="switch-to-map"
                placement="bottom-end"
                title={t('map-view')}
            >
                <MapIcon />
            </TitledIconButton>
        ));

    const perspectiveBarItem = getPerspectiveBarItems();

    const renderPerspectiveIcons = supportedPerspectives.length > 0 && (
        <>
            {supportedPerspectives.map((it) => {
                const supportedPerspective = perspectiveBarItem.find((pbi) => pbi.perspective === it);
                if (!supportedPerspective) {
                    return undefined;
                }

                return (
                    <TitledIconButton
                        area-selected={`${perspective === supportedPerspective.perspective}`}
                        className={classNames({
                            [classes.selected]: perspective === supportedPerspective.perspective,
                        })}
                        closeTooltipOnClick
                        color="inherit"
                        component={customLink(
                            generatePath(MONITORING_PATH_STRUCTURE, {
                                id: perspective === supportedPerspective.perspective ? selectedAssetId : undefined,
                                perspective: supportedPerspective.perspective,
                                subpage: perspective === supportedPerspective.perspective ? subpage : undefined,
                                viewMode,
                            })
                        )}
                        data-id={supportedPerspective.title}
                        key={supportedPerspective.key}
                        placement="bottom-end"
                        title={t(supportedPerspective.title)}
                    >
                        {supportedPerspective.icon}
                    </TitledIconButton>
                );
            })}
        </>
    );

    const divider = canViewMap && <IconDivider />;

    return (
        <>
            {renderPerspectiveIcons}
            {divider}
            {renderViewModeIcon}
        </>
    );
};
