import { useSelector } from 'react-redux';

import type { StoreState } from '~/reducers';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesTrailerDisplayFormat,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';

export interface DisplayPreferences {
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat;
    theme: string;
    trailerDisplayFormat: DisplayUserPreferencesTrailerDisplayFormat;
    unitSystem: DisplayUserPreferencesUnitSystem;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

interface UserPreferencesKeyState<T> {
    actualData: T;
    defaultData: T;
    fulfilled: boolean;
    pending: boolean;
    processResponse?: (preferences: T) => T;
    rejected: boolean;
    updated: boolean;
}

const displayPreferencesSelector = (state: StoreState) =>
    state.userPreferences.display as UserPreferencesKeyState<DisplayPreferences>;

const useDisplayPreferences = () => {
    const displayPreferences = useSelector(displayPreferencesSelector);
    return displayPreferences?.actualData || displayPreferences.defaultData;
};

export { useDisplayPreferences };
