import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type TooltipClassKey = 'label' | 'root' | 'value' | 'valueRow';

export const useChartTooltipStyles = makeStyles<Theme, {}, TooltipClassKey>((theme) => ({
    label: {
        flexGrow: 1,
        margin: theme.spacing(0, 1),
    },
    root: {
        '&>*:not(:last-child)': {
            marginBottom: theme.spacing(0.5),
        },
        padding: theme.spacing(),
    },
    value: {
        fontWeight: theme.typography.fontWeightBold,
    },
    valueRow: {
        alignItems: 'center',
        display: 'flex',
    },
}));
