import React from 'react';

import type { Option } from '~/components/RemoteAutocomplete';
import { RemoteAutocompleteFactory } from '~/components/RemoteAutocomplete';
import type { NumericDictionary } from '~/libs/utility';
import type { Customer, CustomerSecurables } from '~/services/ApiClient';

import { CustomerOptionChild } from './components/CustomerOptionChild';
import { filterCustomerOptions } from './filterCustomerOptions';
import { mapCustomersToCustomerOptions } from './mapCustomersToCustomerOptions';

export interface CustomerRemoteAutocompleteProps {
    disabled?: boolean;
    onChange: (value: Option<Customer>) => void;
    selectedCustomer?: Option<Customer>;
}

export interface StateProps {
    customers: NumericDictionary<Customer>;
    customerSecurables: NumericDictionary<CustomerSecurables>;
    loadingCustomers: boolean;
    retrieveCustomersFulfilled: boolean;
}

export interface DispatchProps {
    getCustomers: () => void;
}

export interface CustomerRemoteAutocompleteInnerProps
    extends CustomerRemoteAutocompleteProps,
        StateProps,
        DispatchProps {}

const CustomerRemoteAutocomplete = RemoteAutocompleteFactory<Customer>(CustomerOptionChild);

export const CustomerRemoteAutocompleteComponent: React.FC<CustomerRemoteAutocompleteInnerProps> = ({
    customers,
    customerSecurables,
    disabled,
    getCustomers,
    loadingCustomers,
    onChange,
    retrieveCustomersFulfilled,
    selectedCustomer,
}) => {
    const [localSelectedCustomer, setLocalSelectedCustomer] = React.useState(selectedCustomer);

    const customerOptions = React.useMemo(
        () =>
            Object.keys(customers).length
                ? mapCustomersToCustomerOptions(customers, customerSecurables)
                : selectedCustomer
                  ? [selectedCustomer]
                  : [],
        [customers, customerSecurables, selectedCustomer]
    );

    const onChangeCallback = React.useCallback(
        (customer: Option<Customer>) => {
            if (customer.value !== localSelectedCustomer?.value) {
                setLocalSelectedCustomer(customer);
                onChange(customer);
            }
        },
        [localSelectedCustomer, onChange]
    );

    return (
        <CustomerRemoteAutocomplete
            dataId="customer-name-autocomplete"
            filterOption={filterCustomerOptions}
            isDisabled={disabled}
            isLoading={loadingCustomers}
            loadSucceeded={retrieveCustomersFulfilled}
            onChange={onChangeCallback}
            options={customerOptions}
            retrieveOptions={getCustomers}
            selectedOption={localSelectedCustomer}
        />
    );
};
