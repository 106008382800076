import { applyColumnPreferences } from './applyColumnPreferences';
import { dataSourceExportProcessor } from './dataSourceExportProcessor';
import { getRawCellValue } from './getRawCellValue';
import type { GridColumnDefinition, GridSorting } from './models';
import { sortDataSource } from './sortDataSource';

export async function exportToExcel<T>(
    dataSource: T[],
    columnDefinitions: Array<GridColumnDefinition<T>>,
    sorting: GridSorting[],
    columnOrder: string[],
    visibleColumns: string[],
    fileName: string,
    worksheetTitle: string
): Promise<void> {
    const { utils, writeFile } = await import('xlsx');
    const sortedData = sortDataSource(dataSource, columnDefinitions, sorting);
    const processedColumns = applyColumnPreferences(columnDefinitions, visibleColumns, columnOrder);
    const header = processedColumns.map((c) => c.title);
    const generatedData = dataSourceExportProcessor(sortedData, processedColumns);

    generatedData.unshift(header);

    const worksheet = utils.aoa_to_sheet(generatedData, { cellDates: true });
    const workbook = utils.book_new();

    /**
     * Use 1904 date system to support negative time.
     * https://www.extendoffice.com/documents/excel/1423-excel-display-show-negative-time.html
     */
    workbook.Workbook = {
        ...workbook.Workbook,
        WBProps: {
            ...(workbook.Workbook && workbook.Workbook.WBProps),
            date1904: true,
        },
    };

    for (let i = 0; i < processedColumns.length; i++) {
        const column = processedColumns[i];

        if (column && column.excelCellFormat) {
            // start from 1 to ignore the header row
            for (let j = 1; j < generatedData.length; j++) {
                const cellAddress = { c: i, r: j };
                const cellRef = utils.encode_cell(cellAddress);

                if (worksheet[cellRef]) {
                    worksheet[cellRef].z =
                        typeof column.excelCellFormat === 'function'
                            ? column.excelCellFormat(getRawCellValue(column, sortedData[j - 1]))
                            : column.excelCellFormat;
                    utils.format_cell(worksheet[cellRef]);
                }
            }
        }
    }

    // Excel only supports 31 characters as worksheet title, truncating it
    const truncatedWorksheetTitle = worksheetTitle.substring(0, 31);

    utils.book_append_sheet(workbook, worksheet, truncatedWorksheetTitle);
    writeFile(workbook, `${fileName}.xlsx`, { cellDates: true });
}
