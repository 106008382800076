import { Typography } from '@mui/material';
import * as React from 'react';

import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import type { InjectedImpersonatorTokenProps } from '~/components/AuthenticationData/components/ImpersonatorToken';
import { CustomerRemoteAutocomplete } from '~/components/CustomerRemoteAutocomplete';
import type { Option } from '~/components/RemoteAutocomplete';
import { UserRemoteAutocomplete } from '~/components/UserRemoteAutocomplete';
import { Customer, createApiModel } from '~/services/ApiClient';

export interface UserInfoProps {}

export interface StateProps {
    impersonateUserFailed: boolean;
    impersonateUserFulfilled: boolean;
    impersonateUserPending: boolean;
}

export interface DispatchProps {
    openUnseenCarousel: () => void;
    selectCustomer: (name: string) => void;
    selectUser: (name: string) => void;
}

export interface OwnProps extends InjectedImpersonatorTokenProps, InjectedUserDataProps {}

export interface UserInfoInnerProps
    extends UserInfoProps,
        StateProps,
        DispatchProps,
        InjectedImpersonatorTokenProps,
        InjectedUserDataProps {}

export interface UserInfoState {
    customerValue: Option<Customer>;
    userValue: Option<undefined>;
}

export class UserInfoComponent extends React.Component<UserInfoInnerProps, UserInfoState> {
    private getCurrentCustomerOption = (id: number, name: string, fvNextEnabled: boolean) => {
        const currentCustomer = createApiModel(Customer, {
            fvNextEnabled,
            id,
            name,
        });

        return {
            data: currentCustomer,
            isDisabled: !currentCustomer.fvNextEnabled,
            label: currentCustomer.name,
            value: currentCustomer.name,
        };
    };

    private getCurrentUserOption = (username: string, isDisabled: boolean = false): Option<undefined> => ({
        data: undefined,
        isDisabled,
        label: username,
        value: username,
    });

    private handleChangeCustomer = (customerOption: Option<Customer>) => {
        if (customerOption.data.id !== this.props.cid) {
            this.setState({ customerValue: customerOption });
            this.props.selectCustomer(customerOption.value);
        }
    };

    private handleChangeUser = (userOption: Option<undefined>) => {
        if (userOption.value !== this.props.userName) {
            this.setState({ userValue: userOption });
            this.props.selectUser(userOption.value);
        }
    };

    constructor(props: UserInfoInnerProps) {
        super(props);
        this.state = {
            customerValue: this.getCurrentCustomerOption(props.cid, props.customerName, true),
            userValue: this.getCurrentUserOption(props.userName),
        };
    }

    public componentDidUpdate(prevProps: UserInfoInnerProps): void {
        if (!prevProps.impersonateUserFulfilled && this.props.impersonateUserFulfilled) {
            this.props.openUnseenCarousel();
        }
        if (!prevProps.impersonateUserFailed && this.props.impersonateUserFailed) {
            if (this.state.userValue.value !== this.props.userName) {
                this.setState({ userValue: this.getCurrentUserOption(this.props.userName) });
            }

            if (this.state.customerValue.data.id !== this.props.cid) {
                this.setState({
                    customerValue: this.getCurrentCustomerOption(this.props.cid, this.props.customerName, true),
                });
            }
        }
    }

    public render(): JSX.Element {
        const { cid, customerName, impersonateUserPending, userFullName, userIsImpersonated } = this.props;
        const { customerValue, userValue } = this.state;

        const usernameElement = userIsImpersonated ? (
            <UserRemoteAutocomplete
                customerId={cid}
                disabled={impersonateUserPending}
                onChange={this.handleChangeUser}
                selectedUser={userValue}
            />
        ) : (
            <Typography data-id="user-full-name" gutterBottom variant="subtitle2">
                {userFullName}
            </Typography>
        );

        const customerNameElement = userIsImpersonated ? (
            <CustomerRemoteAutocomplete
                disabled={impersonateUserPending}
                onChange={this.handleChangeCustomer}
                selectedCustomer={customerValue}
            />
        ) : (
            <Typography data-id="customer-name" gutterBottom variant="body2">
                {customerName}
            </Typography>
        );

        return (
            <div>
                {usernameElement}
                {customerNameElement}
            </div>
        );
    }
}
