import * as React from 'react';

import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import { SceneLoader } from '~/components/SceneLoader';
import type { CommunicationUserPreferences } from '~/data/communication';
import type { RetrievableData } from '~/reducers';
import type { ConversationProfile } from '~/services/ApiClient';

import type { ConversationUserPreferences } from '../../preferences';

export interface InjectedUserMessagingProfilesProps {
    defaultConversationProfileId: number;
    userCommunicationProfileId: number;
    userConversationProfileId: number;
}

export interface StateProps {
    communicationUserPreferences: CommunicationUserPreferences;
    conversationUserPreferences: ConversationUserPreferences;
    profiles: RetrievableData<ConversationProfile[]>;
}

export interface DispatchProps {
    retrieveConversationProfiles: () => void;
    updateUserConversationProfile(conversationProfileId: number): void;
}

export interface EnsureUserConversationProfileInnerProps extends StateProps, DispatchProps, InjectedFeatureFlagsProps {}

export const ensureUserConversationProfileHoc =
    (showLoaderWhileLoadingProfiles: boolean) =>
    <WrappedProps extends InjectedUserMessagingProfilesProps>(
        WrappedComponent: React.ComponentType<WrappedProps>
    ): React.FunctionComponent<EnsureUserConversationProfileInnerProps & WrappedProps> => {
        return (props: EnsureUserConversationProfileInnerProps & WrappedProps) => {
            const {
                communicationUserPreferences,
                conversationUserPreferences,
                profiles,
                retrieveConversationProfiles,
                updateUserConversationProfile,
                ...restProps
            } = props;

            if (!profiles.pending && !profiles.fulfilled && !profiles.rejected) {
                retrieveConversationProfiles();
                return showLoaderWhileLoadingProfiles ? <SceneLoader /> : null;
            }

            if (profiles.pending) {
                return showLoaderWhileLoadingProfiles ? <SceneLoader /> : null;
            }

            if (profiles.rejected) {
                throw Error('Failed to load conversation profiles.');
            }

            const defaultConversationProfileId = profiles.data.find((it) => it.isDefaultProfile)?.id;

            if (!defaultConversationProfileId) {
                throw Error('Failed to find default conversation profiles among loaded profiles.');
            }

            let userConversationProfileId: number | undefined;

            const useUserPreferenceConversationProfile =
                conversationUserPreferences.conversationProfileId &&
                profiles.fulfilled &&
                profiles.data.some((it) => it.id === conversationUserPreferences.conversationProfileId && it.isEnabled);

            if (useUserPreferenceConversationProfile) {
                userConversationProfileId = conversationUserPreferences.conversationProfileId;
            }

            if (!userConversationProfileId) {
                userConversationProfileId = defaultConversationProfileId;
            }

            let userCommunicationProfileId: number | undefined;

            const useUserPreferenceCommunicationProfile =
                communicationUserPreferences.messageProfileId &&
                profiles.fulfilled &&
                profiles.data.some((it) => it.id === communicationUserPreferences.messageProfileId && it.isEnabled);

            if (useUserPreferenceCommunicationProfile) {
                userCommunicationProfileId = communicationUserPreferences.messageProfileId;
            }

            if (!userCommunicationProfileId) {
                userCommunicationProfileId = defaultConversationProfileId;
            }

            if (userConversationProfileId && userCommunicationProfileId) {
                return (
                    <WrappedComponent
                        {...(restProps as WrappedProps)}
                        defaultConversationProfileId={defaultConversationProfileId}
                        userCommunicationProfileId={userCommunicationProfileId}
                        userConversationProfileId={userConversationProfileId}
                    />
                );
            }

            return showLoaderWhileLoadingProfiles ? <SceneLoader /> : null;
        };
    };
