import type { CalendarSpec } from 'moment';
import * as React from 'react';
import Moment from 'react-moment';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoizeOne } from '~/services/Memoize';

import { getMillisecondsUntilMidnight } from './getMillisecondsUntilMidnight';

export interface AdaptiveDateTimeFormatterProps {
    t: SingleTFunction;
    value: Date;
}

export interface AdaptiveDateTimeFormatterInnerProps extends AdaptiveDateTimeFormatterProps {}

export interface AdaptiveDateTimeStoreState {
    updateInterval: number;
}

export const createAdaptiveDateTimeComponent = (
    getAdaptiveCalendarSpec: (t: SingleTFunction) => CalendarSpec
): React.ComponentType<AdaptiveDateTimeFormatterInnerProps> => {
    const getCalendarSpecMemoized = memoizeOne(getAdaptiveCalendarSpec);

    return class AdaptiveDateTimeFormatterComponent extends React.PureComponent<
        AdaptiveDateTimeFormatterInnerProps,
        AdaptiveDateTimeStoreState
    > {
        private formattedValue?: string;

        /**
         * onChange method from Moment triggers each time the date is updated (including the first time is rendered).
         * Avoid setting update interval after first render or after the interval was just updated.
         */
        private handleChange = (value: string) => {
            if (!this.formattedValue) {
                this.formattedValue = value;
                return;
            }

            if (value === this.formattedValue) {
                return;
            }

            this.formattedValue = value;
            this.setState({ updateInterval: getMillisecondsUntilMidnight() });
        };

        constructor(props: AdaptiveDateTimeFormatterInnerProps) {
            super(props);

            this.state = {
                updateInterval: getMillisecondsUntilMidnight(),
            };
        }

        /**
         * Re-calculate update interval when value is changed but the formatted value remains the same,
         * to avoid having a wrong update interval.
         */
        public componentDidUpdate(prevProps: AdaptiveDateTimeFormatterInnerProps) {
            if (prevProps.value !== this.props.value) {
                this.formattedValue = undefined;
                this.setState({ updateInterval: getMillisecondsUntilMidnight() });
            }
        }

        public render() {
            return (
                <Moment
                    calendar={getCalendarSpecMemoized(this.props.t)}
                    date={this.props.value}
                    interval={this.state.updateInterval}
                    onChange={this.handleChange}
                />
            );
        }
    };
};
