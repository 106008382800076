import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ContainerMarkerClassKey = 'assetName' | 'containerBackgroundColor' | 'containerBorderColor';

export const ContainerMarkerStyleRules: StyleRulesCallback<Theme, {}, ContainerMarkerClassKey> = (theme: Theme) =>
    createStyles({
        assetName: {
            backgroundColor: theme.functionalItemsColors.assetType.container.contrast,
            borderColor: theme.functionalItemsColors.assetType.container.value,
            color: theme.functionalItemsColors.assetType.container.value,
        },
        containerBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.assetType.container.value,
            color: theme.functionalItemsColors.assetType.container.contrast,
        },
        containerBorderColor: {
            borderColor: theme.functionalItemsColors.assetType.container.value,
        },
    });
