import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReorderableSection } from '~/components/Sections';
import { getVehicleStatusesForTheLastThreeHours } from '~/data/driverActivitiesReport';
import { dynamicMonitoringStateSelector } from '~/data/monitoring';
import { subDates } from '~/libs/dates';
import { isUndefined } from '~/libs/utility';
import type { VehicleStatus } from '~/services/ApiClient';

import { FuelLevelDialog, FuelLevelGraph } from './components';
import { TooltipSize } from './components/FuelLevelGraph/models';
import { useFuelLevelGraphData } from './hooks';
import type { FuelLevelSectionProps } from './models';
import { GraphContainer } from './styles';

const FuelLevelSection = (props: FuelLevelSectionProps) => {
    const { dragHandleElement, isCollapsed, toggleCollapsed, vehicleId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [items, setItems] = useState<VehicleStatus[]>([]);

    const { vehicleStatus } = useSelector(dynamicMonitoringStateSelector);

    const [openDialog, setOpenDialog] = useState(false);

    const graphData = useFuelLevelGraphData({ initialItems: items, vehicleId, vehicleStatus: vehicleStatus.data });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!isUndefined(vehicleId)) {
            const startDate = subDates(new Date(), { hours: 3 });
            const stopDate = new Date();

            const getGraphData = async () => {
                const data = await getVehicleStatusesForTheLastThreeHours(vehicleId, startDate, stopDate);
                setItems(data);
            };
            getGraphData();
        }
    }, [dispatch, vehicleId]);

    return (
        <ReorderableSection
            dataId="fuelLevel"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('fuel-level')}
            toggleCollapsed={toggleCollapsed}
        >
            <GraphContainer onClick={handleOpenDialog}>
                <FuelLevelGraph data={graphData} tooltipSize={TooltipSize.Small} />
            </GraphContainer>
            {openDialog && <FuelLevelDialog onCloseModal={handleCloseDialog} vehicleId={vehicleId} />}
        </ReorderableSection>
    );
};

FuelLevelSection.displayName = 'FuelLevelSection';

export { FuelLevelSection };
