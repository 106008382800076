import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type ImpersonationClassKeys = 'formSelector' | 'impersonateButton' | 'impersonationForm';

export const useStyles = makeStyles<Theme, {}, ImpersonationClassKeys>((theme) => ({
    formSelector: {
        color: theme.palette.text.primary,
        display: 'block',
        width: '200px',
    },
    impersonateButton: {
        flexBasis: '34%',
        marginLeft: '77%',
        marginTop: theme.spacing(2),
    },
    impersonationForm: {
        flexBasis: '34%',
        marginLeft: '33%',
    },
}));
