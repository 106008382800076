import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import type { FC } from 'react';

import { themes } from '../theme';

const ThemeProvider: FC = ({ children }) => {
    const theme = themes.default;

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};

ThemeProvider.displayName = 'ThemeProvider';
export { ThemeProvider };
