import { MAP_MODES } from '@fv/components';
import type { MapModes } from '@fv/components';

export const CONVERSATION_USERPREFERENCES_KEY = 'conversation';

export interface ConversationUserPreferences {
    conversationProfileId?: number;
    pickerMapMode: MapModes;
    previewMapMode: MapModes;
    rememberLastTtl: boolean;
    showDeletedMessagesInCommunicationModule: boolean;
    showDeletedMessagesInConversations: boolean;
    ttl: string;
}

export const defaultConversationUserPreferences: ConversationUserPreferences = {
    conversationProfileId: undefined,
    pickerMapMode: MAP_MODES.ROAD,
    previewMapMode: MAP_MODES.ROAD,
    rememberLastTtl: false,
    showDeletedMessagesInCommunicationModule: false,
    showDeletedMessagesInConversations: false,
    ttl: 'PT30M',
};

export const requiredConversationUserPreferences = {
    defaultState: defaultConversationUserPreferences,
    key: CONVERSATION_USERPREFERENCES_KEY,
};
