import { Grid, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import logo from '~/assets/fv-logo.svg';
import type { InjectedAppVersionProps } from '~/components/AppVersion';
import { LanguageSelector } from '~/components/LanguageSelector';

import type { AuthTemplateClassKey } from './styles';

export interface AuthTemplateProps {
    children: JSX.Element;
    hideLanguageSelector?: boolean;
}

export interface AuthTemplateInnerProps
    extends AuthTemplateProps,
        WithStyles<AuthTemplateClassKey>,
        InjectedAppVersionProps {
    appVersion: string;
}

export class AuthTemplateComponent extends React.Component<AuthTemplateInnerProps> {
    public render(): JSX.Element {
        const { appVersion, children, classes, hideLanguageSelector } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    alignItems="center"
                    className={classes.container}
                    container
                    direction="column"
                    justifyContent="center"
                    spacing={0}
                >
                    <Grid className={classes.gridItem} item>
                        <Paper className={classes.paper}>
                            <div className={classes.header}>
                                <img alt="FleetVisor" className={classes.logo} src={logo} />
                                {appVersion}
                            </div>
                            {children}
                            {!hideLanguageSelector && (
                                <div className={classes.language}>
                                    <LanguageSelector />
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
