import { useTheme } from '@mui/styles';
import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { buildDictionary } from '../AssetSelector/utils';
import { omit } from '../utility';

import { ItemContent } from './components';
import type { BaseItem, InternalOnCheckArgs, OnCollapseArgs, VirtualListProps } from './models';
import { getChildrenIds } from './utils';

const VirtualList = <T extends BaseItem<T>, U>(props: VirtualListProps<T, U>) => {
    const {
        checkedKeys = {},
        data,
        height,
        isDisabled = false,
        onCheck,
        testId,
        type,
        withExpand,
        ...restProps
    } = props;

    const { custom } = useTheme();
    const [internalCheckedKeys, setInternalCheckedKeys] = useState<{ [key: string]: number | string }>(checkedKeys);
    const [internalExpandedKeys, setInternalExpandedKeys] = useState<{ [key: string]: number | string }>({});

    const handleCheck = (args: InternalOnCheckArgs<T>) => {
        const { event, isChecked, value } = args;

        const ids = [value.id, ...getChildrenIds(value.children ?? [])];
        const hashValues = buildDictionary(ids);

        setInternalCheckedKeys((prev) => {
            const newCheckIds = isChecked ? { ...prev, ...hashValues } : omit(prev, ids);

            onCheck({ event, isChecked, value: Object.values(newCheckIds) as U });
            return newCheckIds;
        });
    };

    const handleToggleCollapse = (args: OnCollapseArgs<T>) => {
        const { isCollapsed, value } = args;

        setInternalExpandedKeys((prev) => (isCollapsed ? { ...prev, [value.id]: value.id } : omit(prev, [value.id])));
    };

    return (
        <Virtuoso
            {...restProps}
            data={data}
            data-testid={testId}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(index, itemProps: T) => (
                <ItemContent<T>
                    checkedKeys={internalCheckedKeys}
                    expandedKeys={internalExpandedKeys}
                    index={index}
                    isDisabled={isDisabled}
                    isRoot
                    item={itemProps}
                    onCheck={handleCheck}
                    onToggleCollapse={handleToggleCollapse}
                    type={type}
                    withExpand={withExpand}
                />
            )}
            style={{ height: height ?? custom.virtualList.height }}
        />
    );
};

VirtualList.displayName = 'VirtualList';
export { VirtualList };
