import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type FuelInformationClassKeys = 'reeferElement' | 'reeferHours' | 'reeferStatus';

export const FuelInformationStyleRules: StyleRulesCallback<Theme, {}, FuelInformationClassKeys> = (theme: Theme) =>
    createStyles({
        reeferElement: {
            paddingBottom: 0,
        },
        reeferHours: {
            fontSize: 12,
        },
        reeferStatus: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'right',
        },
    });
