import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { zoomPopupWidth } from '../../styles';

export type ActivitiesBarClassKey = 'activitySurface' | 'cursor' | 'root';

export const ActivitiesBarStyleRules: StyleRulesCallback<Theme, {}, ActivitiesBarClassKey> = (theme: Theme) =>
    createStyles({
        activitySurface: {
            bottom: 0,
            position: 'absolute',
        },
        cursor: {
            border: '2px solid red',
            bottom: 0,
            height: 40,
            left: 0,
            position: 'absolute',
            zIndex: 10,
        },
        root: {
            background: theme.palette.divider,
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            height: 40,
            overflow: 'hidden',
            position: 'relative',
            width: zoomPopupWidth,
        },
    });
