import { Error, Warning } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';

import { SceneBarState } from './models';
import { useStyles } from './styles';

export interface SceneBarTemplateProps {
    actionButton?: JSX.Element;
    disableClose?: boolean;
    icon?: JSX.Element;
    message: string;
    onClose?: () => void;
    state: SceneBarState;
}

const SceneBarTemplate: FC<SceneBarTemplateProps> = (props) => {
    const { actionButton, disableClose, icon, message, onClose, state } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);

    return (
        <Paper elevation={1}>
            <div className={classes.wrapper} data-id="scene-bar-wrapper">
                <div>
                    {state === SceneBarState.NORMAL && icon}
                    {state === SceneBarState.WARNING &&
                        (icon || <Warning className={classes.warningIcon} data-id="warning-icon" />)}
                    {state === SceneBarState.ERROR &&
                        (icon || <Error className={classes.errorIcon} data-id="error-icon" />)}
                    <Typography className={classes.message} data-id="message" variant="caption">
                        {message}
                        {actionButton}
                    </Typography>
                </div>
                {onClose && (
                    <TitledIconButton
                        color="inherit"
                        data-id="close"
                        disabled={disableClose}
                        onClick={onClose}
                        size="small"
                        title={t('close')}
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </TitledIconButton>
                )}
            </div>
        </Paper>
    );
};

SceneBarTemplate.displayName = 'SceneBarTemplate';
export default SceneBarTemplate;
