import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TrailerIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M23 14.6V6.1H1v8.5h9.7c-0.1 0.1-0.2 0.2-0.2 0.3H2.1v1.9h1.4v0.7h0.9v-0.7H10c0 0.2 0.1 0.4 0.3 0.6c0.5 1.5 2 2.3 3.5 1.8 c0.6-0.2 1.1-0.5 1.4-1c0.9 1.2 2.7 1.5 3.9 0.6c0.3-0.3 0.6-0.6 0.8-1c0.3-0.2 0.5-0.5 0.5-0.8c0-0.3 0-0.7-0.1-1h1.2 c0.2-0.2 0.5-0.4 0.9-0.4v-0.7h-2.5c-0.1-0.1-0.1-0.2-0.2-0.3L23 14.6z M13 17.3L13 17.3c-0.4 0-0.8-0.4-0.8-0.8 c0-0.4 0.4-0.8 0.8-0.8s0.8 0.4 0.8 0.8l0 0C13.8 17 13.4 17.3 13 17.3z M17.4 17.3c-0.4 0-0.8-0.4-0.8-0.8s0.4-0.8 0.8-0.8c0.4 0 0.8 0.4 0.8 0.8v0C18.2 17 17.9 17.3 17.4 17.3z  M3.2 17.6h1.5v0.4H3.2V17.6z" />
        </SvgIcon>
    );
};
TrailerIcon.displayName = 'TrailerIcon';

export { TrailerIcon };
