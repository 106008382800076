import { Button, List, ListItem, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { VehicleNameFormatter } from '~/components/Formatters';
import { ConnectIcon, DisconnectIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { MonitoringTrailerEntry } from '~/data/monitoring';

import { ConnectVehicleDialog, DisconnectVehicleFromTrailerDialog } from '../../../../../Dialogs';

import { Vehicle } from './components/Vehicle';
import type { VehicleSectionClassKey } from './styles';

export interface VehicleSectionProps {
    dragHandleElement: JSX.Element;
    entry: MonitoringTrailerEntry;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface VehicleSectionInnerProps
    extends VehicleSectionProps,
        WithStyles<VehicleSectionClassKey>,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const VehicleSectionComponent: React.FC<VehicleSectionInnerProps> = ({
    classes,
    displayPreferences,
    dragHandleElement,
    entry,
    isCollapsed,
    t,
    toggleCollapsed,
}) => {
    const [showDisconnectVehicleConfirmationDialog, setShowDisconnectVehicleConfirmationDialog] = React.useState(false);
    const [showConnectVehicleDialog, setShowConnectVehicleDialog] = React.useState(false);

    const vehicleOrReference = entry.vehicleConnection?.vehicle || entry.vehicleConnection?.vehicleReference;

    const header = vehicleOrReference ? (
        <VehicleNameFormatter
            value={vehicleOrReference}
            vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat}
        />
    ) : (
        t('not-connected')
    );

    const headerClassName = classNames({
        [classes.disabled]: !entry.vehicleConnection?.vehicle,
        [classes.notConnected]: !entry.vehicleConnection,
    });

    const headerElement = (
        <TitledTruncatedText>
            <span className={headerClassName} data-id="vehicle-name">
                {header}
            </span>
        </TitledTruncatedText>
    );

    const handleConnectClick = React.useCallback(() => {
        setShowConnectVehicleDialog(true);
    }, [setShowConnectVehicleDialog]);

    const connectButton = (
        <Tooltip data-id="connect-button-tooltip" title={t('connect-vehicle-to-trailer')}>
            <span>
                <Button className={classes.button} color="secondary" data-id="connect" onClick={handleConnectClick}>
                    <ConnectIcon className={classes.buttonIcon} />
                    {t('connect')}
                </Button>
            </span>
        </Tooltip>
    );

    const handleDisconnectClick = React.useCallback(() => {
        setShowDisconnectVehicleConfirmationDialog(true);
    }, [setShowDisconnectVehicleConfirmationDialog]);

    const disconnectDisabled = !entry.vehicleConnection?.canManuallyDisconnect || !entry.vehicleConnection?.vehicle;

    const disconnectButton = (
        <Tooltip
            data-id="disconnect-button-tooltip"
            title={
                disconnectDisabled
                    ? t('disconnect-vehicle-form-trailer-disabled')
                    : t('disconnect-vehicle-form-trailer')
            }
        >
            <span>
                <Button
                    className={classes.button}
                    color="secondary"
                    data-id="disconnect"
                    disabled={disconnectDisabled}
                    onClick={handleDisconnectClick}
                >
                    <DisconnectIcon className={classes.buttonIcon} />
                    {t('disconnect')}
                </Button>
            </span>
        </Tooltip>
    );

    const handleCloseDisconnect = React.useCallback(() => {
        setShowDisconnectVehicleConfirmationDialog(false);
    }, [setShowDisconnectVehicleConfirmationDialog]);

    const handleConnectVehicleDialogClose = React.useCallback(
        () => setShowConnectVehicleDialog(false),
        [setShowConnectVehicleDialog]
    );

    const connectVehicleDialog = showConnectVehicleDialog && (
        <ConnectVehicleDialog
            onClose={handleConnectVehicleDialogClose}
            trailer={entry.trailer}
            vehicleConnection={entry.vehicleConnection}
        />
    );
    const disconnectVehicleDialog = showDisconnectVehicleConfirmationDialog && entry.vehicleConnection && (
        <DisconnectVehicleFromTrailerDialog
            onClose={handleCloseDisconnect}
            trailer={entry.trailer}
            vehicleConnection={entry.vehicleConnection}
        />
    );

    return (
        <ReorderableSection
            dataId="vehicle"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={headerElement}
            title={t('vehicle')}
            toggleCollapsed={toggleCollapsed}
        >
            <List disablePadding>
                <Vehicle
                    vehicleConnection={entry.vehicleConnection}
                    vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat}
                />
                <ListItem className={classes.buttons}>
                    {!entry.vehicleConnection && connectButton}
                    {!!entry.vehicleConnection && disconnectButton}
                </ListItem>
            </List>

            {disconnectVehicleDialog}
            {connectVehicleDialog}
        </ReorderableSection>
    );
};
