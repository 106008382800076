import type { NumericDictionary } from '~/libs/utility';
import { orderBy } from '~/libs/utility';
import type { DriverActivity, DriverActivityType } from '~/services/ApiClient';

import type { DriverActivityEntry } from './models';

export const generateDriverActivitiesEntries = (
    activities: DriverActivity[],
    activityTypes: NumericDictionary<DriverActivityType>
): DriverActivityEntry[] =>
    orderBy(
        activities.map((activity) => ({
            activityType: activityTypes[activity.activityId],
            id: activity.id,
            startDate: activity.startDate,
            stopDate: activity.stopDate,
        })),
        'startDate'
    );
