import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: { marginRight: theme.spacing(0.5) },
    violation: {
        backgroundColor: theme.palette.error.main,
        borderRadius: 3,
        color: theme.palette.error.contrastText,
        marginRight: theme.spacing(0),
        padding: theme.spacing(0.25, 0.5),
    },
}));
