import { AirportShuttle, DirectionsCar } from '@mui/icons-material';
import type { FC } from 'react';

import { TruckIcon } from '~/components/Icons';
import { ClassificationType, VehicleTypeCategories } from '~/services/ApiClient';

import { ClassificationFormatter } from '../Classification';

import type { VehicleTypeCategoryProps } from './models';

const VehicleTypeCategory: FC<VehicleTypeCategoryProps> = (props) => {
    const { t, value } = props;

    if (!value) {
        return null;
    }

    return value.key === VehicleTypeCategories.VAN ? (
        <AirportShuttle />
    ) : value.key === VehicleTypeCategories.TRUCK ? (
        <TruckIcon />
    ) : value.key === VehicleTypeCategories.CAR ? (
        <DirectionsCar />
    ) : (
        <ClassificationFormatter classificationKey={ClassificationType.VehicleTypeCategory} t={t} value={value} />
    );
};
VehicleTypeCategory.displayName = 'VehicleTypeCategory';

export { VehicleTypeCategory };
