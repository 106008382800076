import { Box, Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';
import { formatMessageBodyType } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';
import type { MessageBodyTypeFormatterProps } from './models';

export const MessageBodyTypeFormatter: FC<MessageBodyTypeFormatterProps> = (props) => {
    const { value } = props;
    const { t } = useTranslation();

    if (isUndefined(value)) {
        return null;
    }

    return (
        <Tooltip title={formatMessageBodyType(t, value)}>
            <Box data-id={`message-body-type:${value}`} display="inline-flex">
                <IconFormatter value={value} />
            </Box>
        </Tooltip>
    );
};

MessageBodyTypeFormatter.displayName = 'MessageBodyTypeFormatter';
export default MessageBodyTypeFormatter;
