import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PageTemplateClassKey =
    | 'body'
    | 'content'
    | 'dockedWidgets'
    | 'floatingWidgets'
    | 'leftPaneHandle'
    | 'leftPanePaper'
    | 'leftPanePaperClosed'
    | 'mainView'
    | 'openedPanePaper'
    | 'panePaper'
    | 'rightPanePaper'
    | 'root'
    | 'topPanePaper'
    | 'topPanePaperClosed'
    | 'widgets'
    | 'widgetsContainer';

export const topPaneHeight = 28;
export const leftPaneWidth = 320;
export const rightPaneWidth = 408;
export const headerHeight = 56;
export const dockingAreaHeight = 48;

export const PageTemplateStyleRules: StyleRulesCallback<Theme, {}, PageTemplateClassKey> = (theme) =>
    createStyles({
        body: {
            display: 'flex',
            height: `calc(100% - ${headerHeight}px)`,
            position: 'relative',
            zIndex: 0,
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            overflow: 'auto',
            position: 'relative',
            transition: theme.transitions.create(['width', 'margin'], {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.easeOut,
            }),
            zIndex: 1,
        },
        dockedWidgets: {
            borderTop: `1px solid ${theme.palette.divider}`,
            height: dockingAreaHeight,
        },
        floatingWidgets: {
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
        },
        leftPaneHandle: {
            left: leftPaneWidth,
            position: 'absolute',
            top: theme.spacing(4),
            visibility: 'visible',
            zIndex: -1,
        },
        leftPanePaper: {
            width: leftPaneWidth,
        },
        leftPanePaperClosed: {
            overflow: 'visible',
            transform: `translateX(-${leftPaneWidth}px) !important`,
        },
        mainView: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        },
        /* used to solve the positioning problem on reordering sections
           (when you open vehicle details pane, item disappears when dragged) */
        openedPanePaper: {
            overflow: 'visible',
            transform: 'none !important',
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.easeOut,
            }),
        },
        panePaper: {
            bottom: 0,
            position: 'absolute',
            top: 0,
        },
        rightPanePaper: {
            width: rightPaneWidth,
        },
        root: {
            height: '100%',
            position: 'relative',
        },
        topPanePaper: {
            height: '28px',
        },
        topPanePaperClosed: {
            overflow: 'visible',
            transform: `translateY(-28px) !important`,
        },
        widgets: {
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        widgetsContainer: {
            position: 'relative',
            zIndex: 2,
        },
    });
