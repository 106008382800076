import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TemperatureSensorGroupClassKey = 'high' | 'low' | 'root';

export const TemperatureSensorGroupStyleRules: StyleRulesCallback<Theme, {}, TemperatureSensorGroupClassKey> = (
    theme: Theme
) =>
    createStyles({
        high: {
            backgroundColor: theme.functionalItemsColors.temperature.high.value,
            color: theme.palette.common.white,
        },
        low: {
            backgroundColor: theme.functionalItemsColors.temperature.low.value,
            color: theme.palette.common.white,
        },
        root: {
            borderRadius: 3,
            paddingBottom: theme.spacing(0.25),
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            paddingTop: theme.spacing(0.25),
        },
    });
