import type { SvgIconProps } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';
import type { ClassNameMap, WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import { Feature } from '~/components/FeatureFlags';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory, SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import type { PositionGroup, PositionStatus } from '~/services/ApiClient';

import type { TimelineItemClassKey } from './components/TimelineSection';
import type { SectionName } from './constants';
import type { HistorySubpageUserPreferences } from './preferences';
import { HISTORYSUBPAGE_PREFERENCES_KEY } from './preferences';
import type { HistoryRedux } from './redux';
import { getSectionsMemoized, getUnauthorizedSections } from './sections';
import type { HistorySubpageClassKey } from './styles';

export interface HistorySubpageProps {
    AssetIcon: React.ComponentType<SvgIconProps>;
    retrievePositionGroups: (startDate: Date, stopDate: Date, includePositions: boolean) => Promise<PositionGroup[]>;
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
    title: string;
}

export interface HistorySubpageInnerProps
    extends HistorySubpageProps,
        InjectedTranslationProps,
        WithStyles<HistorySubpageClassKey>,
        HistoryRedux,
        InjectedFeatureFlagsProps {}

export const HistorySubpageComponent: React.FC<HistorySubpageInnerProps> = ({
    AssetIcon,
    classes,
    endDate,
    featureFlags,
    getPositionGroups,
    items,
    loading,
    rejected,
    retrievePositionGroups,
    retrievePositions,
    showHistoryMap,
    startDate,
    t,
    timelineClasses,
    title,
}) => {
    const DetailsPaneComponent = React.useMemo(
        () =>
            SceneDetailsPaneFactory<undefined, HistorySubpageUserPreferences, SectionName>(
                HISTORYSUBPAGE_PREFERENCES_KEY
            ),
        []
    );

    const getSections = React.useCallback(
        (
            userPreferences: HistorySubpageUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized(
                userPreferences,
                toggleCollapsedState,
                AssetIcon,
                items,
                endDate,
                startDate,
                retrievePositions,
                timelineClasses
            ),
        [AssetIcon, items, endDate, startDate, retrievePositions, timelineClasses]
    );

    const unauthorizedSections = React.useMemo(() => {
        return getUnauthorizedSections(featureFlags.isEnabled(Feature.HistorySubpageSummarySection));
    }, [featureFlags]);

    React.useEffect(() => {
        showHistoryMap(true);
        return () => {
            showHistoryMap(false);
        };
    }, [showHistoryMap]);

    React.useEffect(() => {
        getPositionGroups(retrievePositionGroups, startDate, endDate, true);
    }, [endDate, startDate, getPositionGroups, retrievePositionGroups]);

    const header = <SceneDetailsSubpageHeader title={title} />;

    if (loading) {
        return (
            <div data-id="history-subpage">
                {header}
                <div className={classes.loaderContainer} data-id="history-subpage-loading-data">
                    <CircularProgress size={32} />
                </div>
            </div>
        );
    }

    if (rejected) {
        return (
            <div data-id="history-subpage">
                {header}
                <Typography className={classes.messageText} data-id="history-subpage-loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </div>
        );
    }

    if (!items.length) {
        return (
            <div data-id="history-subpage">
                {header}
                <Typography className={classes.messageText} data-id="history-subpage-no-data" variant="caption">
                    {t('no-available-data')}
                </Typography>
            </div>
        );
    }

    return (
        <DetailsPaneComponent
            dataId="history-subpage"
            entry={undefined}
            getSections={getSections}
            HeaderReactNode={header}
            unauthorizedSections={unauthorizedSections}
        />
    );
};
