import { SettingsKey } from '~/components/EnsureSettings';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type { ActionTypes as SettingsActionTypes } from '~/data/settings';
import { ActionTypeKeys as SettingsActionTypeKeys } from '~/data/settings';
import type { ActionTypes as VehicleTypeAdministrationActionTypes } from '~/data/vehicletypeadministration';
import { ActionTypeKeys as VehicleTypeAdministrationActionTypeKeys } from '~/data/vehicletypeadministration';
import { keyBy } from '~/libs/utility';
import type { RetrievableHashedData } from '~/reducers';
import type { ResolvedVehicleType, VehicleTypesResponse } from '~/services/ApiClient';
import { ServerResultStatus } from '~/services/ApiClient';
import type { BaseAction } from '~/types';

import { resolveAdminVehicleTypes } from './reducers.resolveAdminVehicleTypes';

export interface AdminVehicleTypesStoreState {
    adminVehicleTypes: RetrievableHashedData<ResolvedVehicleType>;
    createVehicleTypeServerResultStatus?: ServerResultStatus;
    deleteVehicleTypeServerResultStatus?: ServerResultStatus;
    updateVehicleTypeServerResultStatus?: ServerResultStatus;
}

const defaultStoreState: AdminVehicleTypesStoreState = {
    adminVehicleTypes: {
        data: {
            array: [],
            hash: {},
        },
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    createVehicleTypeServerResultStatus: undefined,
    deleteVehicleTypeServerResultStatus: undefined,
    updateVehicleTypeServerResultStatus: undefined,
};

type ActionTypes = SettingsActionTypes | VehicleTypeAdministrationActionTypes;

export const adminVehicleTypeReducer = (
    state: AdminVehicleTypesStoreState = defaultStoreState,
    action: ActionTypes | BaseAction<''>,
    settingsStoreState: SettingsStoreState
): AdminVehicleTypesStoreState => {
    if ((action as SettingsActionTypes).meta === SettingsKey.VEHICLETYPES) {
        switch (action.type) {
            case SettingsActionTypeKeys.SETTINGS_FULFILLED: {
                const vehicleTypesResponse = action.payload as VehicleTypesResponse;
                const resolvedVehicleTypes = resolveAdminVehicleTypes(
                    vehicleTypesResponse.items,
                    settingsStoreState.vehicleTypeCategories.data,
                    settingsStoreState.axles.data,
                    settingsStoreState.emissionClass.data
                );
                return {
                    ...state,
                    adminVehicleTypes: {
                        data: {
                            array: resolvedVehicleTypes,
                            hash: keyBy(resolvedVehicleTypes, 'id'),
                        },
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                };
            }

            case SettingsActionTypeKeys.SETTINGS_PENDING:
                return {
                    ...state,
                    adminVehicleTypes: {
                        ...state.adminVehicleTypes,
                        pending: true,
                    },
                };

            case SettingsActionTypeKeys.SETTINGS_REJECTED:
                return {
                    ...state,
                    adminVehicleTypes: {
                        ...state.adminVehicleTypes,
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                };
            default:
                return state;
        }
    } else {
        switch (action.type) {
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_UPDATE_PENDING:
                return { ...state, updateVehicleTypeServerResultStatus: ServerResultStatus.PENDING };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_UPDATE_FULFILLED:
                return { ...state, updateVehicleTypeServerResultStatus: action.payload };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_UPDATE_REJECTED:
                return { ...state, updateVehicleTypeServerResultStatus: ServerResultStatus.SERVERERROR };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_UPDATE_RESET:
                return { ...state, updateVehicleTypeServerResultStatus: undefined };

            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_DELETE_PENDING:
                return { ...state, deleteVehicleTypeServerResultStatus: ServerResultStatus.PENDING };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_DELETE_FULFILLED:
                return { ...state, deleteVehicleTypeServerResultStatus: action.payload };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_DELETE_REJECTED:
                return { ...state, deleteVehicleTypeServerResultStatus: ServerResultStatus.SERVERERROR };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_DELETE_RESET:
                return { ...state, deleteVehicleTypeServerResultStatus: undefined };

            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_CREATE_PENDING:
                return { ...state, createVehicleTypeServerResultStatus: ServerResultStatus.PENDING };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_CREATE_FULFILLED:
                return { ...state, createVehicleTypeServerResultStatus: action.payload };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_CREATE_REJECTED:
                return { ...state, createVehicleTypeServerResultStatus: ServerResultStatus.SERVERERROR };
            case VehicleTypeAdministrationActionTypeKeys.VEHICLETYPE_CREATE_RESET:
                return { ...state, createVehicleTypeServerResultStatus: undefined };
            default:
                return state;
        }
    }
};
