import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationHeaderClassKey = 'paneTitle' | 'root';

export const ConversationHeaderStyleRules: StyleRulesCallback<Theme, {}, ConversationHeaderClassKey> = (theme: Theme) =>
    createStyles({
        paneTitle: {
            lineHeight: 'unset',
            marginRight: theme.spacing(),
        },
        root: {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
        },
    });
