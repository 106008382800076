import DateRange from '@mui/icons-material/DateRange';
import Schedule from '@mui/icons-material/Schedule';
import TextField from '@mui/material/TextField';
import type { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, FocusEvent, MouseEvent as RMouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { FilterDateTimeValue } from '../../../../models';
import { GridFilterOperation } from '../../../../models';
import type { FilterEditorProps } from '../../models';

import { useStyles } from './styles';
import { createFilterValue, getInputValue } from './utility';

export interface DateTimeEditorProps extends FilterEditorProps {}

export interface DateTimeEditorInnerProps extends DateTimeEditorProps {}

const DateTimeEditor: FC<DateTimeEditorInnerProps> = (props) => {
    const { dataId, disabled, getMessage, inputRef, onChange, operation, value: filterValue } = props;

    const classes = useStyles();
    const [inputValue, setInputValue] = useState<Moment | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const operationRef = useRef(operation);

    useEffect(() => {
        setInputValue((currentInputValue) =>
            getInputValue(filterValue ? (JSON.parse(filterValue) as FilterDateTimeValue) : undefined, currentInputValue)
        );
    }, [filterValue, setInputValue]);

    const applyFilter = useCallback(
        (value: Moment | null) => {
            const newFilterValue = createFilterValue(operation, value);
            if (newFilterValue) {
                onChange(JSON.stringify(newFilterValue));
            }
        },
        [onChange, operation]
    );

    // Apply filter again in case operation changes
    useEffect(() => {
        if (operationRef.current !== operation) {
            applyFilter(inputValue);
            operationRef.current = operation;
        }
    }, [applyFilter, operation, inputValue]);

    const handleFocus = useCallback(
        (event: FocusEvent<HTMLElement>) => {
            if (!inputValue) {
                if (event.currentTarget.parentNode) {
                    // only able to open the picker using a simulated click
                    // when starting to use the open-prop, the autoOk doesn't work anymore, and it doesn't expose when the datepicker is 'finished' in the onChange
                    const simulatedEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
                    event.currentTarget.parentNode.dispatchEvent(simulatedEvent);
                }
            }
        },
        [inputValue]
    );
    const handleClick = useCallback(
        (event: RMouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const applyInputValue = useCallback(
        (date: Moment) => {
            setInputValue(date);
            applyFilter(date);
        },
        [setInputValue, applyFilter]
    );

    const helpers = useMemo(() => {
        const timeFormat = moment.localeData().longDateFormat('LT');
        return {
            ampm: timeFormat.toLowerCase().includes('a'),
            dateFormat: moment.localeData().longDateFormat('L'),
            timeFormat,
        };
    }, []);

    const baseProps: DesktopDatePickerProps<Moment, Moment> = {
        componentsProps: {
            actionBar: {
                actions: ['clear'],
                onClick: (event) => {
                    setInputValue(null);
                    onChange('');
                    event.stopPropagation();
                },
            },
        },
        InputProps: {
            className: classes.input,
            disabled,
            fullWidth: true,
            inputRef,
            onClick: handleClick,
            onFocus: handleFocus,
            size: 'small',
        },
        onChange: applyInputValue,
        PopperProps: { anchorEl, id: dataId },
        renderInput: (renderProps) => (
            <TextField
                {...renderProps}
                inputProps={{ ...renderProps.inputProps, placeholder: getMessage('filterPlaceholder') }}
                variant="standard"
            />
        ),
        value: inputValue,
    };

    switch (operation) {
        case GridFilterOperation.YearEqual: {
            return <DesktopDatePicker {...baseProps} openTo="year" views={['year']} />;
        }

        case GridFilterOperation.MonthEqual: {
            return <DesktopDatePicker {...baseProps} openTo="month" views={['year', 'month']} />;
        }

        default: {
            return (
                <DesktopDateTimePicker
                    {...baseProps}
                    ampm={helpers.ampm}
                    dateRangeIcon={<DateRange className={classes.toolbarIcon} data-id="date-range-icon-id" />}
                    inputFormat={`${helpers.dateFormat} ${helpers.timeFormat}`}
                    timeIcon={<Schedule className={classes.toolbarIcon} data-id="time-icon-id" />}
                />
            );
        }
    }
};
DateTimeEditor.displayName = 'DateTimeEditor';

export { DateTimeEditor };
