import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';

import { SceneLoader } from '~/components/SceneLoader';
import type { NonEmptyArray } from '~/types';

import type { UserPreferencesState } from '../../reducers';

export interface RequiredUserPreferences {
    defaultState: object;
    key: string;
    processResponse?: (preferences: unknown) => unknown;
}

export interface StateProps {
    userPreferencesState: UserPreferencesState;
}

export interface DispatchProps {
    initializeUserPreferencesState: (
        key: string,
        value: object,
        processResponse?: (preferences: unknown) => unknown
    ) => void;
}

export interface UserPreferencesInnerProps extends StateProps, DispatchProps {}

export const ensureUserPreferencesHoc =
    (requiredUserPreferences: NonEmptyArray<RequiredUserPreferences>, showLoaderWhileRetrievingForFirstTime: boolean) =>
    <WrappedProps extends object>(
        WrappedComponent: React.ComponentType<WrappedProps>
    ): React.ComponentType<UserPreferencesInnerProps & WrappedProps> => {
        return class extends React.Component<UserPreferencesInnerProps & WrappedProps> {
            public static displayName = wrapDisplayName(WrappedComponent, 'EnsureUserPreferences');

            private requiredUserPreferencesAreBeingRetrieved = ({ key }: RequiredUserPreferences): boolean => {
                const userPreferencesKeyState = this.props.userPreferencesState[key];
                return (
                    !userPreferencesKeyState || !(userPreferencesKeyState.fulfilled || userPreferencesKeyState.rejected)
                );
            };

            private initializeUserPreferencesState(): void {
                const { initializeUserPreferencesState, userPreferencesState } = this.props;

                requiredUserPreferences.forEach(({ defaultState, key, processResponse }) => {
                    if (!userPreferencesState[key]) {
                        initializeUserPreferencesState(key, defaultState, processResponse);
                    }
                });
            }

            private retrievingUserPreferencesForFirstTime(): boolean {
                return requiredUserPreferences.some(this.requiredUserPreferencesAreBeingRetrieved);
            }

            public componentDidMount() {
                this.initializeUserPreferencesState();
            }

            public render() {
                const { initializeUserPreferencesState, userPreferencesState, ...restProps } = this.props;

                if (this.retrievingUserPreferencesForFirstTime()) {
                    return showLoaderWhileRetrievingForFirstTime ? <SceneLoader /> : null;
                }

                return <WrappedComponent {...(restProps as WrappedProps)} />;
            }
        };
    };
