import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RetryLoadClassKey = 'retryIcon' | 'root';

export const RetryLoadStyleRules: StyleRulesCallback<Theme, {}, RetryLoadClassKey> = (theme: Theme) =>
    createStyles({
        retryIcon: {
            fontSize: theme.typography.pxToRem(24),
        },
        root: {
            alignItems: 'center',
            borderRadius: '50%',
            display: 'flex',
            height: 32,
            justifyContent: 'center',
            width: 32,
        },
    });
