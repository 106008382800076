import type { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import { createElement, forwardRef } from 'react';
import { compose, setDisplayName, wrapDisplayName } from 'react-recompose';

import { withAutoSizer } from '~/components/AutoSizer';

import { GridComponentFactory } from './component';
import type { GridForwardRefProps, GridInnerProps, GridProps, GridVirtualTableElement } from './models';

export { applyColumnPreferences } from './applyColumnPreferences';
export { classificationFilterEquals, getClassificationOptions } from './classifications.utility';
export { GridSortingDirection } from './component';
export { createAddressColumn } from './createAddressColumn';
export { createClassificationColumn } from './createClassificationColumn';
export { createClassificationsColumn } from './createClassificationsColumn';
export { createDateColumn } from './createDateColumn';
export { createDateTimeColumn } from './createDateTimeColumn';
export { createDurationColumn } from './createDurationColumn';
export { createDurationWithViolationColumn } from './createDurationWithViolationColumn';
export { createFormattedColumn } from './createFormattedColumn';
export { createNumberColumn } from './createNumberColumn';
export { createNumberWithViolationColumn } from './createNumberWithViolationColumn';
export { createStringColumn } from './createStringColumn';
export { createTemperatureColumn } from './createTemperatureColumn';
export { createTemperatureSensorColumn } from './createTemperatureSensorColumn';
export { exportToExcel } from './exportToExcel';
export { filterDataSourceByFilter } from './filterDataSourceByFilter';
export { filterDataSourceBySearchQuery } from './filterDataSourceBySearchQuery';
export { GridFilterOperation } from './models';
export type {
    GridColumnDefinition,
    GridFilter,
    GridHeaderProps,
    GridListProps,
    GridRowId,
    GridSorting,
    GridVirtualTableElement,
} from './models';
export type { GridProps } from './models';

export { minColumnWidth } from './styles';

export { createColumn } from './utils/createColumn';
export type GridColumnInfo = TableColumnWidthInfo;

export const GridFactory: <T extends object>() => ForwardRefExoticComponent<
    GridProps<T> & RefAttributes<GridVirtualTableElement>
> = <T extends object>() => {
    const BaseGrid = compose<GridInnerProps<T>, GridForwardRefProps & GridProps<T>>(
        setDisplayName('Grid'),
        withAutoSizer
    )(GridComponentFactory<T>());

    const Grid = setDisplayName(wrapDisplayName(BaseGrid, 'ForwardRef'))(
        forwardRef<GridVirtualTableElement, Exclude<GridProps<T>, GridForwardRefProps>>((props, ref) =>
            createElement(BaseGrid, { ...props, forwardRef: ref })
        )
    );
    return Grid;
};
