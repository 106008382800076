import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { MapViewMode } from './mapViewMode';

export const MAP_USERPREFERENCES_KEY = 'map-state';

export interface MapUserPreferences {
    followSelectedAsset: boolean;
    mapMode: MapModes;
    markerClusterRadius: number;
    trafficIncidentsOverlayEnabled: boolean;
    truckAttributesOverlayEnabled: boolean;

    /**
    @deprecated has been replaced by mapMode
    */
    viewMode?: MapViewMode;
}

export const defaultMapUserPreferences: MapUserPreferences = {
    followSelectedAsset: true,
    mapMode: MAP_MODES.ROAD,
    markerClusterRadius: 65,
    trafficIncidentsOverlayEnabled: false,
    truckAttributesOverlayEnabled: false,
};

export const requiredMapUserPreferences = {
    defaultState: defaultMapUserPreferences,
    key: MAP_USERPREFERENCES_KEY,
    processResponse: (retrievedValue: Partial<MapUserPreferences>): MapUserPreferences => {
        if (Number.isInteger(retrievedValue.viewMode)) {
            return {
                ...defaultMapUserPreferences,
                ...retrievedValue,
                mapMode:
                    retrievedValue.viewMode === 3
                        ? MAP_MODES.SATELLITE
                        : retrievedValue.viewMode === 2
                          ? MAP_MODES.HYBRID
                          : MAP_MODES.ROAD,
                viewMode: undefined,
            };
        }

        return {
            ...defaultMapUserPreferences,
            ...retrievedValue,
        };
    },
};
