import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConfirmMarkAllMessagesAsReadDialogClassKey = 'checkboxWrapper' | 'root';

export const ConfirmMarkAllMessagesAsReadDialogStyleRules: StyleRulesCallback<
    Theme,
    {},
    ConfirmMarkAllMessagesAsReadDialogClassKey
> = () =>
    createStyles({
        checkboxWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden',
        },
    });
