import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { Action, BaseAction } from '~/types';

export enum ActionTypeKeys {
    RTDSSESSIONS_CHANGE_DATETIMERANGE = 'RTDSSESSIONS_CHANGE_DATETIMERANGE',
    RTDSSESSIONS_CLEAR_DATA = 'RTDSSESSIONS_CLEAR_DATA',
}

export type ActionTypes =
    | Action<ActionTypeKeys.RTDSSESSIONS_CHANGE_DATETIMERANGE, DateTimeRange>
    | BaseAction<ActionTypeKeys.RTDSSESSIONS_CLEAR_DATA>;
