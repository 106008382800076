import { withStyles } from '@mui/styles';
import { compose } from 'react-recompose';

import { InfoBoxStyleRules } from '../../styles';

import { DriverInfoBox as _DriverInfoBox } from './component';
import type { DriverInfoBoxInnerProps, DriverInfoBoxProps } from './models';

export const DriverInfoBox = compose<DriverInfoBoxInnerProps, DriverInfoBoxProps>(withStyles(InfoBoxStyleRules))(
    _DriverInfoBox
);
