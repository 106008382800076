import { TextField as _TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface TextFieldProps {
    warning?: boolean;
}

const TextField = styled(_TextField, {
    shouldForwardProp: (propName) => propName !== 'warning',
})<TextFieldProps>(({ theme, warning }) => ({
    '& .MuiFilledInput-underline': {
        borderColor: warning ? theme.palette.warning.main : '',
    },
    '& .MuiInput-underline': {
        borderColor: warning ? theme.palette.warning.main : '',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: warning ? theme.palette.warning.main : '',
    },
}));

export { TextField };
