import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    message: { fontStyle: 'italic', fontWeight: 'normal' },
    messageContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    spinner: { marginRight: theme.spacing(2) },
}));
