import { ListItemAvatar, ListItemText, Typography } from '@mui/material';

import { WeightFormatter } from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';

import { Container } from '../styles';

import type { WeightProps } from './model';

const Weight = (props: WeightProps) => {
    const { avatar, notAvailable, title, unitSystem, weight } = props;

    return (
        <Container data-testid="weight-component">
            <ListItemAvatar data-testid="avatar">{avatar}</ListItemAvatar>
            <ListItemText data-testid="title" primary={title} />
            <Typography color="textPrimary" data-testid="weight-content" variant="subtitle2">
                {isUndefined(weight) ? (
                    <span data-testid="not-available">{notAvailable}</span>
                ) : (
                    <WeightFormatter precision={0} unitSystem={unitSystem} value={weight} />
                )}
            </Typography>
        </Container>
    );
};

Weight.displayName = 'Weight';

export { Weight };
