import { difference } from '~/libs/utility';

export interface SceneDetailsPaneUserPreferences<S> {
    collapsedSections: S[];
    sectionOrder: S[];
}

export const processSceneDetailsPanePreferencesResponse = <
    P extends SceneDetailsPaneUserPreferences<S>,
    S extends string,
>(
    preferences: Partial<P>,
    defaultPreferences: P
): P => {
    const processedPreferences = {
        ...defaultPreferences,
        ...preferences,
    };

    const missingSections =
        preferences.sectionOrder &&
        preferences.sectionOrder.length &&
        difference(defaultPreferences.sectionOrder, processedPreferences.sectionOrder);

    if (missingSections && missingSections.length) {
        processedPreferences.sectionOrder = [...processedPreferences.sectionOrder, ...missingSections];
    }

    return processedPreferences;
};
