import type { SearchResult } from '@fv/components/Map';
import { SearchBar } from '@fv/components/Map';
import { bounds, point } from 'leaflet';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguageCode } from '~/components/LanguageSelector';
import { createApiModel } from '~/services/ApiClient';
import type { Address, Location } from '~/services/MapGatewayClient';
import { SearchLocationRequest, SearchLocationResultOptions, getMapGatewayClient } from '~/services/MapGatewayClient';

const SearchLocationBar: FC = () => {
    const { t } = useTranslation();
    const language = useLanguageCode();

    const handleSearch = (text: string) => {
        const query = createApiModel(SearchLocationRequest, {
            resultOptions: createApiModel(SearchLocationResultOptions, {
                language,
                maxNumberOfResults: 5,
            }),
            text,
        });
        return getMapGatewayClient()
            .searchLocation(query)
            .then((response) => {
                return (response.items || []).map((location): SearchResult<Location> => {
                    const {
                        address,
                        position: { bbox, coordinates },
                    } = location;
                    const { city, countryCode, county, houseNumber, postalCode, street } = address as Address;

                    const label = [street, houseNumber, city, county, countryCode, postalCode]
                        .filter(Boolean)
                        .join(', ');

                    return {
                        bounds:
                            bbox?.length === 4
                                ? bounds([point([bbox[0], bbox[1]]), point([bbox[2], bbox[3]])])
                                : bounds([point(coordinates[0], coordinates[1])]),
                        label,
                        raw: location,
                        x: coordinates[0],
                        y: coordinates[1],
                    };
                });
            });
    };

    return <SearchBar notFoundMessage={t('error.location-not-found')} onSearch={handleSearch} />;
};

SearchLocationBar.displayName = 'SearchLocationbar';
export { SearchLocationBar };
