import type { Position } from '~/services/ApiClient';

export interface FieldValueDictionary {
    [fieldId: number]: boolean | Date | null | number | Position | string;
}

export enum SendingMessageDisabledReason {
    NotAllowed = 1,
    NotSupportedByDevice = 3,
    VehicleMesagingServiceDisabled = 2,
}
