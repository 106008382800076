import { CircularProgress, Typography } from '@mui/material';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { SceneDetailsPaneFactory, SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import { isEmpty } from '~/libs/utility';
import { reportError } from '~/reporting';

import type { ChangelogEntry, ChangelogSubpageProps } from './models';
import { CHANGELOGSUBPAGE_PREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';
import { useStyles } from './styles';

interface ChangelogSubpageComponentState<T> {
    changelogs: ChangelogEntry<T>[];
    pending: boolean;
    rejected: boolean;
}

const ChangelogSubpage = <T,>({
    attributeDefinitions,
    changelogs,
    changelogSubpageTitle,
    entityType,
}: ChangelogSubpageProps<T>): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    const DetailsPaneComponent = useMemo(
        () =>
            SceneDetailsPaneFactory<undefined, SceneDetailsPaneUserPreferences<''>, ''>(
                CHANGELOGSUBPAGE_PREFERENCES_KEY
            ),
        []
    );
    const [status, setStatus] = useState<ChangelogSubpageComponentState<T>>({
        changelogs: [],
        pending: true,
        rejected: false,
    });

    const getSections = useCallback(
        () => getSectionsMemoized({ attributeDefinitions, changelogs: status.changelogs, entityType }),
        [attributeDefinitions, status.changelogs, entityType]
    );

    useEffect(() => {
        Promise.resolve(changelogs)
            .then(
                (cl) => setStatus({ changelogs: cl, pending: false, rejected: false }),
                () => setStatus({ changelogs: [], pending: false, rejected: true })
            )
            .catch(reportError);
    }, [changelogs]);

    const header = <SceneDetailsSubpageHeader title={changelogSubpageTitle} />;

    if (status.pending) {
        return (
            <>
                {header}
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            </>
        );
    }

    if (status.rejected) {
        return (
            <>
                {header}
                <Typography className={classes.messageText} data-id="loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </>
        );
    }

    if (isEmpty(status.changelogs)) {
        return (
            <div data-id="changelog-subpage">
                {header}
                <Typography className={classes.messageText} data-id="no-data" variant="caption">
                    {t('changelog-subpage-no-data')}
                </Typography>
            </div>
        );
    }

    return (
        <DetailsPaneComponent
            dataId="changelog-subpage"
            entry={undefined}
            getSections={getSections}
            HeaderReactNode={header}
        />
    );
};

ChangelogSubpage.displayName = 'ChangelogSubpage';
export { ChangelogSubpage };
