import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const RTDSSESSIONS_LIST_USERPREFERENCES_KEY = 'rtds-session-grid-state';

export const defaultRtdsSessionsListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.TYPE,
        ColumnName.MSISDN,
        ColumnName.DRIVERID,
        ColumnName.DRIVER,
        ColumnName.VEHICLE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.STATUS,
        ColumnName.SCHEDULED,
        ColumnName.EXPIRATION,
        ColumnName.QUEUED,
        ColumnName.STARTED,
        ColumnName.AUTHENTICATED,
        ColumnName.DOWNLOADED,
        ColumnName.RECEIVED,
        ColumnName.FAILED,
        ColumnName.LASTCHANGE,
        ColumnName.ERROR,
        ColumnName.FREQUENCY,
        ColumnName.RECURRENCE,
        ColumnName.REQUESTEDTACHODATA,
        ColumnName.SCHEDULEID,
        ColumnName.INSTANCE,
    ],
    columnWidths: [
        { columnName: ColumnName.TYPE, width: 150 },
        { columnName: ColumnName.VEHICLE, width: 150 },
        { columnName: ColumnName.DRIVER, width: 150 },
        { columnName: ColumnName.IGNITIONSTATUS, width: 100 },
        { columnName: ColumnName.STATUS, width: 120 },
        { columnName: ColumnName.SCHEDULED, width: 150 },
        { columnName: ColumnName.EXPIRATION, width: 150 },
        { columnName: ColumnName.QUEUED, width: 150 },
        { columnName: ColumnName.STARTED, width: 150 },
        { columnName: ColumnName.AUTHENTICATED, width: 150 },
        { columnName: ColumnName.DOWNLOADED, width: 150 },
        { columnName: ColumnName.RECEIVED, width: 150 },
        { columnName: ColumnName.FAILED, width: 150 },
        { columnName: ColumnName.LASTCHANGE, width: 150 },
        { columnName: ColumnName.ERROR, width: 150 },
        { columnName: ColumnName.FREQUENCY, width: 120 },
        { columnName: ColumnName.RECURRENCE, width: 100 },
        { columnName: ColumnName.REQUESTEDTACHODATA, width: 150 },
        { columnName: ColumnName.SCHEDULEID, width: 90 },
        { columnName: ColumnName.INSTANCE, width: 100 },
        { columnName: ColumnName.DRIVERID, width: 200 },
        { columnName: ColumnName.MSISDN, width: 150 },
    ],
    filteringEnabled: false,
    filters: [],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: false,
    sorting: [{ columnName: ColumnName.VEHICLE, direction: GridSortingDirection.DESC }],
    visibleColumns: [
        ColumnName.TYPE,
        ColumnName.DRIVER,
        ColumnName.VEHICLE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.STATUS,
        ColumnName.SCHEDULED,
        ColumnName.EXPIRATION,
        ColumnName.QUEUED,
        ColumnName.STARTED,
        ColumnName.AUTHENTICATED,
        ColumnName.DOWNLOADED,
        ColumnName.RECEIVED,
        ColumnName.FAILED,
        ColumnName.LASTCHANGE,
        ColumnName.ERROR,
        ColumnName.FREQUENCY,
        ColumnName.RECURRENCE,
        ColumnName.REQUESTEDTACHODATA,
        ColumnName.SCHEDULEID,
        ColumnName.INSTANCE,
    ],
};
