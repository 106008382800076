import { debounce } from '@fv/components/utility';
import InputAdornment from '@mui/material/InputAdornment';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useEffect } from 'react';

import { ESCAPE_KEY } from './consts';
import type { SearchHeaderProps } from './models';
import { ClearIcon, Container, Input, SearchIcon } from './styles';

const SearchHeader: FC<SearchHeaderProps> = (props) => {
    const { changeSearchQuery, closeSearch, debouncePeriod = 500, defaultSearchQuery } = props;

    const debouncedChangeSearchQuery = debounce(changeSearchQuery, debouncePeriod);

    useEffect(() => {
        return () => {
            debouncedChangeSearchQuery.flush();
        };
    }, [debouncedChangeSearchQuery]);

    const handleKeyPress = (event: ChangeEvent<HTMLTextAreaElement> & KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ESCAPE_KEY) {
            debouncedChangeSearchQuery.cancel();
            closeSearch();
        } else {
            debouncedChangeSearchQuery(event.target.value);
        }
    };

    return (
        <Container data-id="search-header-open">
            <Input
                autoFocus
                data-id="search-input"
                defaultValue={defaultSearchQuery}
                disableUnderline
                endAdornment={
                    <InputAdornment position="end">
                        <ClearIcon aria-label="closeSearch" data-id="close-search" onClick={closeSearch} />
                    </InputAdornment>
                }
                fullWidth
                onKeyUp={handleKeyPress}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
            />
        </Container>
    );
};

SearchHeader.displayName = 'SearchHeader';
export { SearchHeader };
