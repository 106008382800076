import { createAction } from 'redux-actions';

import { ApiClient, ApiException, QueryDriverActivitiesReportByIdsApi, ServerResultStatus } from '~/services/ApiClient';

import { ACTION_TYPE_KEYS, ACTION_TYPE_PREFIXES } from './actionsTypes';
import type { QueryDriverActivitiesReportByIds } from './models';

const queryDriverActivitiesList = createAction(
    ACTION_TYPE_PREFIXES.LIST,
    async (query: QueryDriverActivitiesReportByIds) => {
        try {
            const parsedQuery = QueryDriverActivitiesReportByIdsApi.fromJS(query);
            const { items } = await ApiClient.queryDriverActivitiesReportByIds(parsedQuery);

            return items;
        } catch (error) {
            if (ApiException.isApiException(error) && error.status === 400) {
                throw ServerResultStatus.BADREQUEST;
            }

            throw ServerResultStatus.SERVERERROR;
        }
    },
    ({ assetType, viewMode }: QueryDriverActivitiesReportByIds) => ({ assetType, viewMode })
);

const resetState = createAction(ACTION_TYPE_KEYS.RESET);

const getVehicleStatusesForTheLastThreeHours = async (vehicleId: number, startDate: Date, stopDate: Date) => {
    try {
        const { items } = await ApiClient.getVehicleStatusesForPeriod(vehicleId, startDate, stopDate);
        return items;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
};

export { getVehicleStatusesForTheLastThreeHours, queryDriverActivitiesList, resetState };
