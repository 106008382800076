import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationSummaryFirstLineClassKey =
    | 'root'
    | 'timestamp'
    | 'vehicleDisplayName'
    | 'vehicleDisplayNameHighlighted';

export const ConversationSummaryFirstLineStyleRules: StyleRulesCallback<
    Theme,
    {},
    ConversationSummaryFirstLineClassKey
> = (theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            display: 'flex',
        },
        timestamp: {
            fontWeight: theme.typography.fontWeightMedium,
            marginLeft: theme.spacing(),
        },
        vehicleDisplayName: {
            flexGrow: 1,
            fontWeight: theme.typography.fontWeightMedium,
            width: 0,
        },
        vehicleDisplayNameHighlighted: {
            fontWeight: 600,
        },
    });
