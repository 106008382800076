import GetApp from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ListItemText, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { UploadIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps } from '~/components/SceneList';
import { SceneListViewHeaderFactory } from '~/components/SceneList';
import { TitledIconButton } from '~/components/TitledIconButton';
import { zipFiles } from '~/scenes/TachoFiles/zipUtils';
import {
    ApiClient,
    RmsStatusType,
    TachoFileBatchRequest,
    TachoFileReference,
    TachoFileType,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import type { TachoFileEntry } from '../../../../models';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { TACHOFILES_LIST_USERPREFERENCES_KEY, defaultTachoFilesListUserPreferences } from '../../preferences';

import { logEventWhenEnablingColumns } from './logEventWhenEnablingColumns';

export const HeaderComponent = SceneListViewHeaderFactory<TachoFileEntry>(
    TACHOFILES_LIST_USERPREFERENCES_KEY,
    defaultTachoFilesListUserPreferences,
    filterDataSourceMemoized,
    'tacho-files'
);

export interface TachoFilesListViewHeaderProps
    extends Omit<
        SceneListViewHeaderProps<TachoFileEntry>,
        'columns' | 'excelSheetTitle' | 'moduleBarActions' | 'onFilteredDataSourceChange'
    > {
    contentLoading: boolean;
    onRefreshData: () => void;
    rmsEnabled: boolean;
}

export interface TachoFilesListViewHeaderInnerProps
    extends TachoFilesListViewHeaderProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const securablesSelector = settingDataSelector(SettingsKey.SECURABLES);

export const TachoFilesListViewHeaderComponent: React.FC<TachoFilesListViewHeaderInnerProps> = ({
    contentLoading,
    dataSource,
    displayPreferences,
    i18n,
    onRefreshData,
    rmsEnabled,
    t,
    ...restProps
}) => {
    const [filteredDataSource, setFilteredDataSource] = React.useState(dataSource);

    const [exportFileMenuAnchorRef, setExportFileMenuAnchorRef] = React.useState<HTMLDivElement | null>(null);

    const [exportFileMenuOpen, setExportFileMenuOpen] = React.useState<boolean>(false);

    const securables = useSelector(securablesSelector);

    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat,
        securables.tachoFile.rms
    );

    const reuploadIsDisabled = React.useMemo(
        () => !filteredDataSource.some((it) => it.file.rmsStatus?.type === RmsStatusType.Failed),
        [filteredDataSource]
    );

    const exportIsDisabled = React.useCallback(
        (fileType?: TachoFileType) => {
            if (!fileType) {
                return filteredDataSource.length === 0;
            } else {
                return filteredDataSource.filter((it) => it.file.id.type === fileType).length === 0;
            }
        },
        [filteredDataSource]
    );

    const reuploadTachoFiles = React.useCallback(() => {
        const filesToUpload = filteredDataSource
            .filter((it) => it.file.rmsStatus?.type === RmsStatusType.Failed)
            .map((it) => createApiModel(TachoFileReference, { id: it.file.id.id, type: it.file.id.type }));

        if (filesToUpload.length === 0) {
            return;
        }
        retryableRequest(() =>
            ApiClient.uploadTachoFiles(createApiModel(TachoFileBatchRequest, { tachoFileIds: filesToUpload }))
        );
        onRefreshData();
    }, [filteredDataSource, onRefreshData]);

    const exportTachoFiles = React.useCallback(
        async (fileType?: TachoFileType) => {
            setExportFileMenuOpen(false);
            let filesToUpload = filteredDataSource.map((it) =>
                createApiModel(TachoFileReference, { id: it.file.id.id, type: it.file.id.type })
            );

            if (fileType) {
                filesToUpload = filesToUpload.filter((it) => it.type === fileType);
            }

            if (filesToUpload.length === 0) {
                return;
            }
            const response = await retryableRequest(() =>
                ApiClient.downloadTachoFiles(createApiModel(TachoFileBatchRequest, { tachoFileIds: filesToUpload }))
            );

            zipFiles({ fileType, response, t });
        },
        [filteredDataSource, t]
    );

    const reuploadButton = React.useMemo(() => {
        return rmsEnabled ? (
            <TitledIconButton
                color="inherit"
                data-id="reupload-tacho-files"
                disabled={reuploadIsDisabled}
                onClick={reuploadTachoFiles}
                placement="bottom-end"
                title={reuploadIsDisabled ? t('no-tacho-files-to-reupload') : t('reupload-tacho-files')}
            >
                <UploadIcon />
            </TitledIconButton>
        ) : undefined;
    }, [reuploadIsDisabled, reuploadTachoFiles, rmsEnabled, t]);

    const exportButton = React.useMemo(() => {
        return (
            <>
                <div ref={setExportFileMenuAnchorRef}>
                    <TitledIconButton
                        color="inherit"
                        data-id="export-tacho-files"
                        disabled={exportIsDisabled()}
                        onClick={() => setExportFileMenuOpen(true)}
                        placement="bottom-end"
                        title={exportIsDisabled() ? t('no-tacho-files-to-export') : t('export-tacho-files')}
                    >
                        <GetApp />
                    </TitledIconButton>
                </div>

                <Menu
                    anchorEl={exportFileMenuAnchorRef}
                    onClose={() => setExportFileMenuOpen(false)}
                    open={exportFileMenuOpen}
                >
                    <MenuItem
                        data-id="export-tacho-files"
                        dense
                        disabled={exportIsDisabled()}
                        onClick={() => exportTachoFiles()}
                    >
                        <ListItemText primary={t('export-all-rtds-files')} />
                    </MenuItem>
                    <MenuItem
                        data-id="export-driver-card-images"
                        dense
                        disabled={exportIsDisabled(TachoFileType.DriverCard)}
                        onClick={() => exportTachoFiles(TachoFileType.DriverCard)}
                    >
                        <ListItemText primary={t('export-driver-card-images')} />
                    </MenuItem>
                    <MenuItem
                        data-id="export-tacho-data-images"
                        dense
                        disabled={exportIsDisabled(TachoFileType.Tachograph)}
                        onClick={() => exportTachoFiles(TachoFileType.Tachograph)}
                    >
                        <ListItemText primary={t('export-tacho-data-images')} />
                    </MenuItem>
                </Menu>
            </>
        );
    }, [exportIsDisabled, exportFileMenuOpen, exportFileMenuAnchorRef, exportTachoFiles, t]);

    const refreshDataButton = React.useMemo(() => {
        return (
            <TitledIconButton
                color="inherit"
                data-id="refresh-data-button"
                disabled={restProps.gridActionsDisabled || contentLoading}
                onClick={onRefreshData}
                placement="bottom-end"
                title={t('refresh')}
            >
                <RefreshIcon />
            </TitledIconButton>
        );
    }, [contentLoading, onRefreshData, restProps.gridActionsDisabled, t]);

    const moduleBarActions = React.useMemo(
        () => (
            <>
                {reuploadButton}
                {exportButton}
                {refreshDataButton}
            </>
        ),
        [exportButton, reuploadButton, refreshDataButton]
    );

    return (
        <HeaderComponent
            {...restProps}
            columns={columns}
            dataSource={dataSource}
            excelSheetTitle={t('tacho-files')}
            moduleBarActions={moduleBarActions}
            onFilteredDataSourceChange={setFilteredDataSource}
            onShowColumns={logEventWhenEnablingColumns}
        />
    );
};
