import { Avatar } from '@mui/material';
import type { FC } from 'react';

import { DriverActivityIconFormatter } from '~/components/Formatters';
import { DriverIcon } from '~/components/Icons';

import type { DriverAvatarProps } from './models';
import { useStyles } from './styles';

const DriverAvatar: FC<DriverAvatarProps> = (props) => {
    const { activity } = props;
    const classes = useStyles();

    return (
        <div className={classes.driver} data-id="driver-avatar">
            <Avatar className={classes.driverAvatar}>
                <DriverIcon />
            </Avatar>
            {activity && (
                <Avatar className={classes.activityAvatar} data-id="activity-badge">
                    <DriverActivityIconFormatter iconProps={{ fontSize: 'inherit' }} value={activity.key} />
                </Avatar>
            )}
        </div>
    );
};

DriverAvatar.displayName = 'DriverAvatar';
export default DriverAvatar;
