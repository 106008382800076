import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { ResolvedRtdsSchedule } from '../ApiClient';
import { ClassificationType, TachoDataBlockTypes } from '../ApiClient';

import { formatClassification } from './formatClassification';

export const formatTachoDatablocks = (t: SingleTFunction, schedule: ResolvedRtdsSchedule): string => {
    if (isUndefined(schedule.dataBlocks) || schedule.dataBlocks?.length === 0) {
        return t('requested-tacho-data-empty');
    } else {
        return schedule.dataBlocks
            ?.map((tachoDataBlock) => {
                if (tachoDataBlock.id === TachoDataBlockTypes.ActivityData) {
                    return `${t('tachograph-data-blocks-activitydata-days', {
                        days: schedule.requestedDays,
                        key: formatClassification(t, ClassificationType.TachographDataBlocks, tachoDataBlock),
                    })}`;
                } else {
                    return formatClassification(t, ClassificationType.TachographDataBlocks, tachoDataBlock);
                }
            })
            .join(', ');
    }
};
