import { languageReducer } from './reducers';

export { ensureCurrentLanguage } from './components/EnsureCurrentLanguageHoc';
export { LanguageSelector } from './components/LanguageSelector';
export { withTranslation } from './components/WithTranslationHoc';
export type { InjectedTranslationProps } from './components/WithTranslationHoc';

export * from './hooks';
export type { SingleTFunction } from './models';
export type { LanguageStoreState } from './reducers';

export const languageComponentReducer = languageReducer;
