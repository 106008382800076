import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import { getDistanceUnitSystemUtils } from '~/services/Formatters';

import type { DistanceFormatterProps } from './models';

const DistanceFormatter: FC<DistanceFormatterProps> = (props) => {
    const { autoFormat, precision, t, undefinedValueMessage, unitSystem, value } = props;

    const content = isUndefined(value)
        ? undefinedValueMessage
        : getDistanceUnitSystemUtils(t, unitSystem).formatter(value, { autoFormat, precision });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
};

DistanceFormatter.displayName = 'DistanceFormatter';
export { DistanceFormatter };
