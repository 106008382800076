import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationsSummaryWidgetListClassKey = 'list' | 'listItemDivider';

export const ConversationsSummaryWidgetListStyleRules: StyleRulesCallback<
    Theme,
    {},
    ConversationsSummaryWidgetListClassKey
> = () =>
    createStyles({
        list: {
            outline: 'none',
        },
        listItemDivider: {
            bottom: 0,
            left: 56,
            position: 'absolute',
            right: 0,
        },
    });
