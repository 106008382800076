import { MAP_MODES } from '@fv/components';
import type { Dispatch } from 'redux';
import type { Subtract } from 'utility-types';

import { showAllSelectedAssetsAction } from '../../../../data';

import type { DispatchProps, MapActionsInnerProps } from './component';

export const mapDispatchToProps = (
    dispatch: Dispatch,
    { changeMapMode, mapMode }: Subtract<MapActionsInnerProps, DispatchProps>
): DispatchProps => {
    return {
        changeMapModeToHybrid: () => {
            if (mapMode === MAP_MODES.HYBRID) {
                return;
            }
            changeMapMode(MAP_MODES.HYBRID);
        },
        changeMapModeToMap: () => {
            if (mapMode === MAP_MODES.ROAD) {
                return;
            }
            changeMapMode(MAP_MODES.ROAD);
        },
        changeMapModeToSatellite: () => {
            if (mapMode === MAP_MODES.SATELLITE) {
                return;
            }
            changeMapMode(MAP_MODES.SATELLITE);
        },
        showAllSelectedAssets: () => {
            dispatch(showAllSelectedAssetsAction());
        },
    };
};
