import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    LoginStatus,
    NumberViolationField,
    ViolationField,
} from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';
import { parseOptionalDuration, parseOptionalDurationViolationField } from '~/services/Parsers';

export const getCoDriverIdValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.driver &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.driver.id;

export const getCoDriverNameValue =
    (driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat) =>
    (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined => {
        const coDriver =
            monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.driver;

        if (!coDriver) {
            return '';
        }

        return formatDriverName(coDriver, driverDisplayNameFormat);
    };

export const getAvailableDailyDrivingTimeCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.availableDailyDrive
    );

export const getAvailableWeeklyDrivingTimeCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.availableWeeklyDrive
    );

export const getAvailableBiWeeklyDrivingTimeCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.availableBiWeeklyDrive
    );

export const getShiftDurationCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.shiftDuration
    );

export const getDailyDutyCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.dailyDuty
    );

export const getActivityDurationCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.currentActivityDuration
    );

export const getContinuousWorkAvailableCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.continuousWorkWait
    );

export const getOperationalWeekDurationCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.operationalWeekDuration
    );

export const getLoginManualStatusCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): LoginStatus | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.loginStatus;

export const getContinuousDrivingCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.continuousDrive
    );

export const getDriveBreakCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.driveBreak
    );

export const getDailyDrivingCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.dailyDrive
    );

export const getWeeklyDrivingCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weeklyDrive
    );

export const getExtendedDrivingCountCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): NumberViolationField | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.extendedDayDriveCount;

export const getContinuousLaborShortCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.continuousLabourShort
    );

export const getShortLaborBreakCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.labourShortBreak
    );

export const getContinuousLaborLongCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.continuousLabourLong
    );

export const getLongLaborBreakCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.labourLongBreak
    );

export const getNightLaborCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.nightLabour
    );

export const getWeeklyLaborCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weekLabour
    );

export const getAverageWeeklyLaborCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weekLabourAverage
    );

export const getWeeklyDutyCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weeklyDuty
    );

export const getMonthlyDutyCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.monthlyDuty
    );

export const getMonthlyEffectivityPercentageCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): number | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.monthlyEffectivityPercentage;

export const getFirstSplitDailyRestCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.splitDailyRest
    );

export const getReducedDailyRestCountCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): NumberViolationField | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.reducedDayRestCount;

export const getDailyRestingCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.dailyRest
    );

export const getWeeklyRestCompensationCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weeklyRestCompensation
    );

export const getTotalWeeklyRestCompensationCoDriverValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.totalWeeklyRestCompensation
    );

export const getWeeklyRestDueCoDriverValue = (monitoringVehicleEntry: MonitoringVehicleEntry): Date | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.coDriverHoursStatus.weeklyRestDueDateTime;
