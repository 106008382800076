import type { MapModes } from '@fv/components';
import type { Dispatch } from 'react';

import { LocationSection, MiniMapSectionFactory } from '~/common';
import { ContainerMarker } from '~/components/Map';
import type { SectionDefinition } from '~/components/Sections';
import type { MonitoringContainerEntry, MonitoringViewMode } from '~/data/monitoring';
import { MonitoringPerspective } from '~/data/monitoring';
import { ApiClient, AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { monitoringContainerEntryEquals } from '../../../../utils';
import { AssetHistorySubpageFactory } from '../AssetHistorySubpage';
import { LocationSectionActionButtons } from '../LocationSectionActionButtons';

import { ContainerTemperaturesSection } from './components/TemperaturesSection';
import { SectionName } from './constants';

const MiniMapSectionComponent = MiniMapSectionFactory<MonitoringContainerEntry>(
    (entry: MonitoringContainerEntry) => entry.container.id,
    async ({ endDate, id, startDate }) => {
        const { items } = await ApiClient.getContainerPositions(id, startDate, endDate);
        return items;
    },
    monitoringContainerEntryEquals
);

export const ContainerHistorySubpage = AssetHistorySubpageFactory(AssetType.Container);

export const getSections = (
    viewMode: MonitoringViewMode,
    entry: MonitoringContainerEntry,
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    miniMapZoomLevel: number,
    changeMiniMapZoomLevel: (zoomLevel: number) => void,
    handleLocate: () => void,
    miniMapMode: MapModes,
    changeMiniMapMode: Dispatch<MapModes>
): SectionDefinition[] => {
    const locationSectionActionButtons = (
        <LocationSectionActionButtons
            assetId={entry.container.id}
            onLocate={handleLocate}
            perspective={MonitoringPerspective.CONTAINER}
            position={entry.status?.location?.position}
            showHistoryButton
            viewMode={viewMode}
        />
    );
    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    actionButtons={locationSectionActionButtons}
                    address={entry.status?.location?.address}
                    datetime={entry.status?.location?.dateTime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    position={entry.status?.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <MiniMapSectionComponent
                    AssetMarker={ContainerMarker}
                    changeMapMode={changeMiniMapMode}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    lastLocation={entry.status?.location?.position}
                    mapMode={miniMapMode}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ContainerTemperaturesSection
                    container={entry.container}
                    containerStatus={entry.status}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.TEMPERATURES)}
                    toggleCollapsed={toggleCollapsedState(SectionName.TEMPERATURES)}
                />
            ),
            name: SectionName.TEMPERATURES,
        },
    ];
};

export const unauthorizedSectionsNames = (canViewMap: boolean): SectionName[] => {
    const result = [];

    if (!canViewMap) {
        result.push(SectionName.MINIMAP);
    }

    return result;
};

export const getSectionsMemoized = memoizeOne(getSections);
export const unauthorizedSectionsNamesMemoized = memoizeOne(unauthorizedSectionsNames);
