import { CRUD_ACTIONS } from '@fv/components/Dialogs';

const EDITOR_TITLE = {
    [CRUD_ACTIONS.CLONE]: 'vehicle-clone',
    [CRUD_ACTIONS.CREATE]: 'vehicle-new',
    [CRUD_ACTIONS.EDIT]: 'vehicle-modify',
};

const noneServiceValue = 0;

const dropdownDefaultValue = -1;

export { dropdownDefaultValue, EDITOR_TITLE, noneServiceValue };
