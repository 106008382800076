import type { AssetRecipient, DisplayUserPreferencesDriverDisplayFormat, Driver } from '~/services/ApiClient';
import { AssetRecipientType } from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';

export const mapDriverOptions = (
    drivers: Driver[],
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat,
    driverPredicate?: (driverId: number) => boolean
): AssetRecipient[] =>
    drivers
        .filter((driver) => (driverPredicate ? driverPredicate(driver.id) : driver.id))
        .map((driver) => {
            return {
                ids: [driver.id],
                name: formatDriverName(driver, driverDisplayFormat),
                type: AssetRecipientType.DRIVER,
            };
        });
