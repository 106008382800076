import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { DurationFormatterInnerProps, DurationFormatterProps } from './component';
import { DurationFormatterComponent } from './component';
import { DurationStyleRules } from './styles';

export type { DurationFormatterProps } from './component';
export { ViolationType } from './violations';

export const DurationFormatter = compose<DurationFormatterInnerProps, DurationFormatterProps>(
    setDisplayName('DurationFormatter'),
    withStyles(DurationStyleRules)
)(DurationFormatterComponent);
