import { getValueFromHash } from '~/common';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, keyBy } from '~/libs/utility';
import type {
    Classification,
    CompartmentStatus,
    Container,
    ContainerStatus,
    DoorStatus,
    DriverActivityType,
    DriverHoursStatus,
    DriverSubActivityType,
    InhibitorStatus,
    LastTrailerEvent,
    ResolvedContainer,
    ResolvedContainerStatus,
    ResolvedDriverHoursStatus,
    ResolvedInhibitorStatus,
    ResolvedLastTrailerEvent,
    ResolvedRouteStatus,
    ResolvedTrailerConnection,
    ResolvedTrailerStatus,
    ResolvedVehicleConnection,
    ResolvedVehicleDriverHoursStatus,
    RouteStatus,
    VehicleDriverHoursStatus,
} from '~/services/ApiClient';
import {
    compartmentStatusResolver,
    doorStatusResolver,
    namedVehicleReferenceResolver,
    reeferStatusResolver,
    trailerBatteryStatusResolver,
    trailerResolver,
    vehicleResolver,
} from '~/services/ModelResolvers';

import type {
    DriverStatusResolverProps,
    ResolvedDriverStatus,
    TrailerConnectionResolverArgs,
    TrailerStatusResolverArgs,
    VehicleConnectionResolverArgs,
} from './models';

export const driverHoursStatusResolver = (
    driverHoursStatus: DriverHoursStatus | undefined,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>
): ResolvedDriverHoursStatus | undefined => {
    if (!driverHoursStatus) {
        return undefined;
    }

    return {
        ...driverHoursStatus,
        activity: !isUndefined(driverHoursStatus?.activity)
            ? driverActivityTypes[driverHoursStatus.activity]
            : undefined,
        subActivity: !isUndefined(driverHoursStatus?.subActivity)
            ? driverSubActivityTypes[driverHoursStatus.subActivity]
            : undefined,
    };
};

export const driverStatusResolver = (props: DriverStatusResolverProps): ResolvedDriverStatus | undefined => {
    const {
        driverActivityTypes,
        driversHash,
        driverStatus,
        driverSubActivityTypes,
        vehicleCategories,
        vehicleStatuses,
        vehicleTypes,
    } = props;

    if (!driverStatus) {
        return;
    }
    const vehicle =
        driverStatus.vehicle !== undefined
            ? namedVehicleReferenceResolver({
                  categories: vehicleCategories,
                  namedVehicleReference: driverStatus.vehicle,
                  vehicleTypes,
              })
            : undefined;
    const vehicleStatus = vehicle !== undefined ? getValueFromHash(vehicleStatuses, vehicle.id) : undefined;
    return {
        ...driverStatus,
        activity: getValueFromHash(driverActivityTypes, driverStatus.activity),
        coDriver: getValueFromHash(driversHash, driverStatus.coDriver),
        coDriverActivity: getValueFromHash(driverActivityTypes, driverStatus.coDriverActivity),
        coDriverSubActivity: getValueFromHash(driverSubActivityTypes, driverStatus.coDriverSubActivity),
        driver: getValueFromHash(driversHash, driverStatus.driver),
        subactivity: getValueFromHash(driverSubActivityTypes, driverStatus.subactivity),
        vehicle,
        vehicleStatus,
    };
};

export const containerResolver = (containerData: Container): ResolvedContainer => ({
    ...containerData,
});

export const containerStatusResolver = (
    restProps: ContainerStatus,
    containerEventType: NumericDictionary<Classification>,
    containerTemperatureType: NumericDictionary<Classification>
): ResolvedContainerStatus => ({
    ...restProps,
    lastEvent: {
        dateTime: restProps.lastEvent.dateTime,
        eventType: containerEventType[restProps.lastEvent.eventType],
    },
    temperatures: restProps.temperatures.map(({ type, ...t }) => ({
        ...t,
        type: containerTemperatureType[type],
    })),
});

export const inhibitorStatusResolver = (
    restProps: InhibitorStatus,
    inhibitorStatuses: NumericDictionary<Classification>
): ResolvedInhibitorStatus => ({
    ...restProps,
    inhibitorStatus: inhibitorStatuses[restProps.status],
});

export const lastEventResolver = (
    lastEventData: LastTrailerEvent,
    lastEvent: NumericDictionary<Classification>
): ResolvedLastTrailerEvent => ({
    ...lastEventData,
    trailerEventType: lastEvent[lastEventData.trailerEventType],
});

export const routeStatusResolver = (
    routeStatus: RouteStatus,
    routeStatusTypes: NumericDictionary<Classification>
): ResolvedRouteStatus => {
    return {
        ...routeStatus,
        status: routeStatusTypes[routeStatus.status],
    };
};

export const trailerStatusResolver = (args: TrailerStatusResolverArgs): ResolvedTrailerStatus => {
    const {
        compartmentStatus,
        doorStatus,
        hookedStatus,
        reeferAlarm,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        trailerBatteryStatus,
        trailerEventType,
        trailerStatus,
    } = args;
    return {
        ...trailerStatus,
        battery: trailerStatus.battery
            ? trailerBatteryStatusResolver(trailerStatus.battery, trailerBatteryStatus)
            : undefined,
        compartments: keyBy(
            trailerStatus.compartments.map((compartment: CompartmentStatus) =>
                compartmentStatusResolver(compartment, compartmentStatus)
            ),
            'compartmentNumber'
        ),
        doors: keyBy(
            trailerStatus.doors.map((door: DoorStatus) => doorStatusResolver(door, doorStatus)),
            'doorNumber'
        ),
        hooked: !isUndefined(trailerStatus.hooked) ? hookedStatus[trailerStatus.hooked] : undefined,
        lastEvent: lastEventResolver(trailerStatus.lastEvent, trailerEventType),
        reefer:
            trailerStatus.reefer &&
            reeferStatusResolver({
                reeferAlarm,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                reeferStatus,
                reeferStatusData: trailerStatus.reefer,
            }),
    };
};

export const trailerConnectionResolver = (args: TrailerConnectionResolverArgs): ResolvedTrailerConnection => {
    const {
        compartmentStatus,
        doorStatus,
        hookedStatus,
        reeferAlarm,
        reeferManufacturers,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        trailer,
        trailerBatteryStatus,
        trailerConnection,
        trailerEventType,
        trailerManufacturers,
        trailerStatus,
    } = args;
    const resolvedTrailerConnection: ResolvedTrailerConnection = {
        isManuallyConnected: trailerConnection.isManuallyConnected,
        trailerReference: trailerConnection.trailer,
    };

    if (trailer) {
        resolvedTrailerConnection.trailer = trailerResolver(trailer, trailerManufacturers, reeferManufacturers);
        resolvedTrailerConnection.trailerStatus =
            trailerStatus &&
            trailerStatusResolver({
                compartmentStatus,
                doorStatus,
                hookedStatus,
                reeferAlarm,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                reeferStatus,
                trailerBatteryStatus,
                trailerEventType,
                trailerStatus,
            });
    }

    return resolvedTrailerConnection;
};

export const vehicleDriverHoursStatusResolver = (
    vehicleDriverHoursStatus: VehicleDriverHoursStatus,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>
): ResolvedVehicleDriverHoursStatus => ({
    ...vehicleDriverHoursStatus,
    coDriverHoursStatus: driverHoursStatusResolver(
        vehicleDriverHoursStatus.coDriverHoursStatus,
        driverActivityTypes,
        driverSubActivityTypes
    ),
    driverHoursStatus: driverHoursStatusResolver(
        vehicleDriverHoursStatus.driverHoursStatus,
        driverActivityTypes,
        driverSubActivityTypes
    ),
});

export const vehicleConnectionResolver = (args: VehicleConnectionResolverArgs): ResolvedVehicleConnection => {
    const {
        deviceTypes,
        driverActivityTypes,
        driverSubActivityTypes,
        vehicleCategories,
        vehicleConnection,
        vehicleDriverHoursStatus,
        vehiclesHash,
        vehicleTypes,
    } = args;
    const vehicleDriverHourStatus = vehicleDriverHoursStatus[vehicleConnection.vehicle.id];

    return {
        canManuallyDisconnect: vehicleConnection.canManuallyDisconnect,
        isManuallyConnected: !!vehicleConnection.trailerConnection?.isManuallyConnected,
        vehicle: vehiclesHash[vehicleConnection.vehicle.id]
            ? vehicleResolver({
                  categories: vehicleCategories,
                  deviceTypes,
                  vehicle: vehiclesHash[vehicleConnection.vehicle.id],
                  vehicleTypes,
              })
            : undefined,
        vehicleDriverHoursStatus: vehicleDriverHourStatus
            ? vehicleDriverHoursStatusResolver(vehicleDriverHourStatus, driverActivityTypes, driverSubActivityTypes)
            : undefined,
        vehicleReference: vehicleConnection.vehicle,
    };
};
