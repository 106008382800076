import * as React from 'react';
import { useSelector } from 'react-redux';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory } from '~/components/SceneList';

import { getRowId, tachoFilesEntryEquals } from '../../../../entryUtils';
import type { TachoFileEntry } from '../../../../models';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { TACHOFILES_LIST_USERPREFERENCES_KEY, defaultTachoFilesListUserPreferences } from '../../preferences';

export type TachoFilesListViewProps = Omit<
    SceneListViewProps<TachoFileEntry>,
    'columns' | 'getCellClassName' | 'gridDataId'
>;

export interface TachoFilesListViewInnerProps
    extends TachoFilesListViewProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const ListViewComponent = SceneListViewFactory<TachoFileEntry>(
    TACHOFILES_LIST_USERPREFERENCES_KEY,
    defaultTachoFilesListUserPreferences,
    getRowId,
    tachoFilesEntryEquals,
    filterDataSourceMemoized
);

const securablesSelector = settingDataSelector(SettingsKey.SECURABLES);

export const TachoFilesListViewComponent: React.FC<TachoFilesListViewInnerProps> = ({
    displayPreferences,
    i18n,
    t,
    ...restProps
}) => {
    const securables = useSelector(securablesSelector);

    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat,
        securables.tachoFile.rms
    );
    return <ListViewComponent columns={columns} gridDataId="tacho-files-grid" {...restProps} />;
};
