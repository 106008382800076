import { Button, Snackbar } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Disposable } from '~/listeners';
import { reportError } from '~/reporting';
import type { Client as SignalRClient } from '~/services/SignalR';
import { ConnectionStatus } from '~/services/SignalR';

export interface SignalRConnectionMonitorProps {
    signalRConnection: SignalRClient;
}

export interface SignalRConnectionMonitorInnerProps extends SignalRConnectionMonitorProps, InjectedTranslationProps {}

export interface SignalRConnectionMonitorState {
    connectionStatus: ConnectionStatus;
}

export class SignalRConnectionMonitorComponent extends React.Component<
    SignalRConnectionMonitorInnerProps,
    SignalRConnectionMonitorState
> {
    private disposableHandlers: Disposable[] = [];

    private restoreSignalRConnection = () => {
        this.props.signalRConnection.restore().catch(reportError);
    };

    constructor(props: SignalRConnectionMonitorInnerProps) {
        super(props);

        this.state = {
            connectionStatus: props.signalRConnection.getConnectionStatus(),
        };
    }

    private getSnackbarAction(): JSX.Element | undefined {
        if (this.state.connectionStatus === ConnectionStatus.CONNECTIONLOST) {
            return (
                <Button color="secondary" onClick={this.restoreSignalRConnection} variant="text">
                    {this.props.t('reconnect')}
                </Button>
            );
        }

        return undefined;
    }

    private getSnackbarMessage(): string | undefined {
        switch (this.state.connectionStatus) {
            case ConnectionStatus.TRYINGTORECONNECT:
                return this.props.t('signal-r-trying-to-reconnect');
            case ConnectionStatus.CONNECTIONLOST:
                return this.props.t('signal-r-connection-lost');
            default:
                return undefined;
        }
    }

    private snackbarIsOpen() {
        return [ConnectionStatus.CONNECTIONLOST, ConnectionStatus.TRYINGTORECONNECT].includes(
            this.state.connectionStatus
        );
    }

    public componentDidMount(): void {
        const { signalRConnection } = this.props;

        this.disposableHandlers.push(
            signalRConnection.onConnectionStatusChanged((connectionStatus: ConnectionStatus) => {
                this.setState({ connectionStatus });
            })
        );

        window.addEventListener('online', this.restoreSignalRConnection);
    }

    public componentDidUpdate(prevProps: SignalRConnectionMonitorInnerProps): void {
        if (prevProps.signalRConnection !== this.props.signalRConnection) {
            throw new Error('Property signalRConnection is not supposed to be changed.');
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
        window.removeEventListener('online', this.restoreSignalRConnection);
    }

    public render(): React.ReactNode {
        return (
            <Snackbar
                action={this.getSnackbarAction()}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                message={this.getSnackbarMessage()}
                open={this.snackbarIsOpen()}
            />
        );
    }
}
