import type { Dispatch } from 'redux';

import { changeLanguageAction, retrieveCurrentLanguageAction, retrieveLanguagesAction } from '~/data/language';
import type { Language } from '~/data/language/actionTypes';

import type { LanguageStoreState } from '../../reducers';
import { selectLanguages } from '../../reducers';

import type { DispatchProps, StateProps } from './models';

const mapStateToProps = (state: LanguageStoreState): StateProps => ({
    availableLanguages: selectLanguages(state),
    currentLanguage: state.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeLanguage: (language: Language) => dispatch(changeLanguageAction(language)),
    retrieveLanguages: () => {
        dispatch(retrieveLanguagesAction());
        dispatch(retrieveCurrentLanguageAction());
    },
});

export { mapDispatchToProps, mapStateToProps };
