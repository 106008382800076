import { CRUD_ACTIONS } from '@fv/components/Dialogs';
import type { CrudActions } from '@fv/components/Dialogs';
import { Button, CircularProgress, List, ListItem, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC, ReactNode, RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ProfileSubpageShell, getValueByPath, mapDepotToClassification } from '~/common';
import { BannerType, ConfirmationDialog, LeftPane, TopBanner, WidgetDialog } from '~/components/Dialogs';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { CloseIcon, TruckIcon } from '~/components/Icons';
import { BooleanControlType, TextInput, noneValue } from '~/components/InputFields';
import { AutocompleteDropdownInput } from '~/components/InputFields/AutocompleteDropdownInput';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneLoader } from '~/components/SceneLoader';
import { TitledIconButton } from '~/components/TitledIconButton';
import { getVehicleSettingsAction, updateAdminVehicleAction } from '~/data/vehicleadministration';
import { isEqual, isNil, isUndefined } from '~/libs/utility';
import {
    BooleanSettingsField,
    ClassificationSettingsField,
} from '~/modules/Administration/components/AssetSettingsFields';
import type { StoreState } from '~/reducers';
import { reportError } from '~/reporting';
import { staticDataStoreStateSelector } from '~/selectors';
import { logEvent } from '~/services/Analytics';
import type {
    ClassificationAbstraction,
    CompanyCard,
    ResolvedAdminVehicleRead,
    ResolvedVehicle,
    ServicesSecurables,
} from '~/services/ApiClient';
import { ClassificationType, ServerResultStatus, SettingsValueSource } from '~/services/ApiClient';
import { formatVehicleCategory, formatVehicleName } from '~/services/Formatters';

import { vehicleAdministrationStateSelector } from '../../selectors';
import { Dialog } from '../Dialog';

import { NumericInputSettingsField } from './components';
import { noneServiceValue } from './constants';
import {
    defaultSettingsMapper,
    mapClassificationToClassificationAbstraction,
    mapCompanyCardToClassificationAbstraction,
    mapDevicesToMulticolumnClassificationAbstraction,
    mapToAdminVehicleEdit,
    mapVehicleCategoryToClassificationAbstraction,
    mapVehicleToForm,
    mapVehicleTypeToClassificationAbstraction,
} from './dataMappers';
import type { FieldNames, FieldValues } from './models';
import { SourceFiledNameSuffix, dropdownServiceFields, toggleServiceFields } from './models';
import type { VehicleEditorDialogReduxProps } from './redux';
import type { VehicleEditorDialogFormClassKey } from './styles';
import { getEditorTitle } from './utils';

export interface VehicleEditorDialogProps {
    canActivateServices: boolean;
    canUnassignDevice: boolean;
    editorState: CrudActions;
    onClose: (isCanceled: boolean) => void;
    servicesSecurables: ServicesSecurables;
    vehicle?: ResolvedAdminVehicleRead;
}

export interface VehicleEditorDialogInnerProps
    extends VehicleEditorDialogReduxProps,
        VehicleEditorDialogProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        WithStyles<VehicleEditorDialogFormClassKey> {}

export const VehicleEditorDialogComponent: FC<VehicleEditorDialogInnerProps> = (props) => {
    const {
        canActivateServices,
        canUnassignDevice,
        categories,
        classes,
        companyCards,
        createAdminVehicle,
        devicesTypes,
        displayPreferences,
        editorState,
        existingVehicles,
        fuelTankTypes,
        imageCapturings,
        navigations,
        onClose,
        servicesSecurables,
        startInhibitors,
        t,
        tachoGenerations,
        tachographs,
        tachoProtocols,
        tellTalesServices,
        updateAdminVehicle,
        updateAdminVehicleReset,
        updateVehicleServerResultStatus,
        vehicle,
        vehicleTypes,
        workflows,
    } = props;

    const { defaultVehicleSettings, staticDataState, vehicleCategories, vehicleSettings } = useSelector(
        (store: StoreState) => ({
            defaultVehicleSettings: vehicleAdministrationStateSelector(store).adminVehicleDefaultSettings,
            staticDataState: staticDataStoreStateSelector(store),
            vehicleCategories: settingDataSelector(SettingsKey.VEHICLECATEGORIES)(store),
            vehicleSettings: vehicleAdministrationStateSelector(store).adminVehicleSettings,
        }),
        isEqual
    );
    const dispatch = useDispatch();
    const generalRef = useRef<HTMLDivElement>(null);
    const tachographRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const fuelRef = useRef<HTMLDivElement>(null);
    const [topBanner, setTopBanner] = useState<ReactNode>();
    const [openDiscardChangesDialog, setOpenDiscardChangesDialog] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const vehicleDefaultFieldValues = useMemo(() => {
        if (vehicleSettings.pending || defaultVehicleSettings.pending || !defaultVehicleSettings.data) {
            return undefined;
        }

        return mapVehicleToForm({
            defaultVehicleCategory: Object.values(vehicleCategories)[0],
            defaultVehicleSettings: defaultVehicleSettings.data,
            editorState,
            vehicle,
            vehicleSettings: vehicleSettings.data,
        });
    }, [vehicle, editorState, vehicleCategories, defaultVehicleSettings, vehicleSettings]);

    const vehicleDefaultSettingsValues = useMemo(
        () => (defaultVehicleSettings.data ? defaultSettingsMapper(defaultVehicleSettings.data) : undefined),
        [defaultVehicleSettings]
    );

    const form = useForm<FieldValues>({ defaultValues: vehicleDefaultFieldValues, mode: 'all' });

    useEffect(() => {
        const id = vehicle?.id;
        if (!isUndefined(id)) {
            dispatch(getVehicleSettingsAction(id));
        }
    }, [dispatch, vehicle?.id]);

    useEffect(() => {
        form.reset(vehicleDefaultFieldValues || {});
    }, [form, vehicleDefaultFieldValues]);
    const vehicleId = vehicleDefaultFieldValues?.id ?? 0;

    const primaryTitle =
        editorState === CRUD_ACTIONS.EDIT && vehicle
            ? formatVehicleName(vehicle as ResolvedVehicle, displayPreferences.vehicleDisplayFormat)
            : '';
    const secondaryTitle =
        editorState === CRUD_ACTIONS.EDIT && vehicle
            ? [vehicle.category && formatVehicleCategory(t, vehicle.category), vehicle.vehicleType?.name]
                  .filter(Boolean)
                  .join(' - ') || ''
            : '';

    const formIsValid = form.formState.isValid;

    const dirtyFields = Object.keys(form.formState.dirtyFields) as FieldNames[];
    const dirtyFieldsCount = dirtyFields.length;
    const changedFieldsCount = dirtyFields.filter((it) => !it.endsWith(SourceFiledNameSuffix)).length;

    const handleCloseActivateServices = () => {
        setIsOpenDialog(false);
    };

    const handleConfirm = () => {
        const item = mapToAdminVehicleEdit(form.getValues());
        dispatch(updateAdminVehicleAction(vehicleId, item)).catch(reportError);
        handleCloseActivateServices();
    };

    const buttonName = useMemo(() => {
        switch (editorState) {
            case CRUD_ACTIONS.EDIT: {
                return t('save');
            }

            case CRUD_ACTIONS.CLONE: {
                return t('clone');
            }

            case CRUD_ACTIONS.CREATE: {
                return t('create');
            }

            default:
                throw Error(`Provided editor state ${editorState} is not supported.`);
        }
    }, [editorState, t]);

    const mandatoryFieldsArePopulated = useCallback(() => {
        const vinValue = form.getValues('vin');
        const unitIdValue = form.getValues('unitId');
        const aliasValue = form.getValues('alias');
        if (vinValue === '' && unitIdValue === '' && aliasValue === '') {
            return false;
        } else {
            return true;
        }
    }, [form]);

    const isUniqueVIN = useCallback(
        (existingVehicleId: number | undefined, vinValue: string) => {
            if (!vinValue) {
                return true;
            }
            return !existingVehicles.some(
                (it) =>
                    (isUndefined(existingVehicleId) || it.id !== existingVehicleId) &&
                    it.vin &&
                    it.vin.toUpperCase() === vinValue.toUpperCase()
            );
        },
        [existingVehicles]
    );

    const isCompanyCardValid = useCallback(
        (companyCardNumber?: string) => {
            if (isNil(companyCardNumber) || parseInt(companyCardNumber, 10) === noneValue) {
                return true;
            }
            const result = Object.values(companyCards).some(
                (it: CompanyCard) => it.number.toLowerCase() === companyCardNumber.toString().toLowerCase()
            );

            return result;
        },
        [companyCards]
    );
    const scrollTo = useCallback((position: RefObject<HTMLDivElement>) => {
        if (position.current !== null) {
            position.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, []);
    const resetFormState = useCallback(() => {
        updateAdminVehicleReset();
    }, [updateAdminVehicleReset]);

    const closeBanner = useCallback(() => {
        resetFormState();
        setTopBanner(undefined);
    }, [resetFormState]);

    const openBanner = useCallback(
        (bannerTitle: string, dataId: string, type: BannerType) => {
            setTopBanner(<TopBanner dataId={dataId} onDismiss={() => closeBanner()} title={bannerTitle} type={type} />);
        },
        [closeBanner]
    );

    const isAdditionallyActivatedService = useCallback(
        (field: FieldNames): boolean => {
            if (field.endsWith(SourceFiledNameSuffix)) {
                return false;
            }

            if (!dropdownServiceFields.includes(field) && !toggleServiceFields.includes(field)) {
                return false;
            }

            let dropdownServiceActivated = false;
            let toggleServiceActivated = false;

            const fieldValue = form.getValues(field);

            if (dropdownServiceFields.includes(field)) {
                dropdownServiceActivated = fieldValue !== noneServiceValue;
            } else if (toggleServiceFields.includes(field)) {
                toggleServiceActivated = fieldValue !== false;
            }

            return toggleServiceActivated || dropdownServiceActivated;
        },
        [form]
    );

    const additionallyActivatedServicesCount = useCallback((): number => {
        return dirtyFields.filter((field) => isAdditionallyActivatedService(field)).length;
    }, [dirtyFields, isAdditionallyActivatedService]);

    const submitForm = useCallback(
        (data: FieldValues) => {
            if (editorState === CRUD_ACTIONS.EDIT && additionallyActivatedServicesCount() > 0) {
                setIsOpenDialog(true);
            } else {
                const vehicleToSave = mapToAdminVehicleEdit(data);

                switch (editorState) {
                    case CRUD_ACTIONS.EDIT: {
                        updateAdminVehicle(vehicleId, vehicleToSave);
                        break;
                    }

                    case CRUD_ACTIONS.CLONE:
                    case CRUD_ACTIONS.CREATE: {
                        createAdminVehicle(vehicleToSave);
                        break;
                    }

                    default:
                        throw Error(`Provided editor state ${editorState} is not supported.`);
                }
            }
        },
        [additionallyActivatedServicesCount, createAdminVehicle, editorState, updateAdminVehicle, vehicleId]
    );

    const closeForm = useCallback(
        (isCanceled: boolean) => {
            if (!isCanceled) {
                switch (editorState) {
                    case CRUD_ACTIONS.EDIT: {
                        logEvent('vehicle admin', 'modify-vehicle', 'Modify vehicle');
                        break;
                    }

                    case CRUD_ACTIONS.CLONE: {
                        logEvent('vehicle admin', 'clone-vehicle', 'Clone vehicle');
                        break;
                    }

                    case CRUD_ACTIONS.CREATE: {
                        logEvent('vehicle admin', 'create-vehicle', 'Create vehicle');
                        break;
                    }

                    default:
                        throw Error(`Provided editor state ${editorState} is not supported.`);
                }
            }
            onClose(isCanceled);
            resetFormState();
        },
        [editorState, onClose, resetFormState]
    );

    const cancelHandler = useCallback(() => {
        if (dirtyFieldsCount) {
            setOpenDiscardChangesDialog(true);
        } else {
            closeForm(true);
        }
    }, [closeForm, dirtyFieldsCount]);

    useEffect(() => {
        form.trigger(); // run field validations on form editor start/opening
        if (!updateVehicleServerResultStatus) {
            if (!formIsValid) {
                openBanner(
                    t('topbanner-message-validation-errors'),
                    'vehicle-update-dialog-banner-form-validation-errors',
                    BannerType.Error
                );
            } else if (changedFieldsCount > 0) {
                openBanner(
                    t('topbanner-message-fields-changed', {
                        fieldCount: changedFieldsCount,
                    }),
                    'vehicle-update-dialog-banner-fields-changed',
                    BannerType.Warning
                );
            } else {
                closeBanner();
            }
        } else {
            switch (updateVehicleServerResultStatus) {
                case ServerResultStatus.BADREQUEST:
                    openBanner(
                        t('server-message-bad-request'),
                        'vehicle-update-dialog-banner-server-warning',
                        BannerType.Warning
                    );
                    break;
                case ServerResultStatus.OK:
                    closeForm(false);
                    break;
                case ServerResultStatus.PENDING:
                    setTopBanner(undefined);
                    break;
                case ServerResultStatus.SERVERERROR:
                    openBanner(
                        t('server-message-internal-error'),
                        'vehicle-update-dialog-banner-server-error',
                        BannerType.Error
                    );
                    break;
                default:
                    closeBanner();
            }
        }
    }, [changedFieldsCount, closeBanner, closeForm, form, formIsValid, openBanner, t, updateVehicleServerResultStatus]);

    const headerIcon = (
        <TitledIconButton
            color="inherit"
            data-id="dialog-header-icon"
            disabled={updateVehicleServerResultStatus === ServerResultStatus.PENDING}
            onClick={() => closeForm(true)}
            placement="bottom-end"
            title={t('close')}
        >
            <CloseIcon />
        </TitledIconButton>
    );
    const actionButtons = (
        <>
            <Button
                className={classes.actionButtons}
                color="secondary"
                data-id="cancel"
                disabled={updateVehicleServerResultStatus === ServerResultStatus.PENDING}
                onClick={cancelHandler}
            >
                {t('cancel')}
            </Button>
            <Button
                className={classes.actionButtons}
                color="secondary"
                data-id="save"
                disabled={
                    !formIsValid ||
                    dirtyFieldsCount === 0 ||
                    updateVehicleServerResultStatus === ServerResultStatus.PENDING
                }
                form="vehicle-editor-form"
                type="submit"
                variant="contained"
            >
                {updateVehicleServerResultStatus === ServerResultStatus.PENDING ? (
                    <CircularProgress className={classes.backDrop} size={30} />
                ) : (
                    buttonName
                )}
            </Button>
        </>
    );

    const leftPaneHeader = useMemo(
        () => (
            <ProfileSubpageShell
                hasChildren={false}
                icon={<TruckIcon className={classes.vehicleIcon} />}
                primaryTitle={primaryTitle}
                secondaryTitle={secondaryTitle}
            />
        ),
        [classes.vehicleIcon, primaryTitle, secondaryTitle]
    );
    const leftPaneElement = useMemo(() => {
        return (
            <LeftPane classes={{ root: classes.leftPane }} header={leftPaneHeader}>
                <List>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            data-id="vehicle-editor-general-section-link"
                            onClick={() => scrollTo(generalRef)}
                            variant="button"
                        >
                            {t('vehicle-editor-section-general')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            data-id="vehicle-editor-tachograph-section-link"
                            onClick={() => scrollTo(tachographRef)}
                            variant="button"
                        >
                            {t('vehicle-editor-section-tachograph')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            data-id="vehicle-editor-services-section-link"
                            onClick={() => scrollTo(servicesRef)}
                            variant="button"
                        >
                            {t('vehicle-editor-section-services')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            data-id="vehicle-editor-fuel-section-link"
                            onClick={() => scrollTo(fuelRef)}
                            variant="button"
                        >
                            {t('vehicle-editor-section-fuel')}
                        </Typography>
                    </ListItem>
                </List>
            </LeftPane>
        );
    }, [classes.leftPane, classes.sectionsListItem, leftPaneHeader, scrollTo, t]);

    const discardChangesDialogOnCancelHandler = useCallback(() => {
        setOpenDiscardChangesDialog(false);
    }, [setOpenDiscardChangesDialog]);

    const discardChangesDialog = useMemo(() => {
        return (
            <ConfirmationDialog
                confirmationActionText={t('discard')}
                dataId="confirm-close-edit-vehicle-form"
                onCancel={discardChangesDialogOnCancelHandler}
                onConfirm={() => onClose(true)}
                open={openDiscardChangesDialog}
                title={t('asset-editor-close-confirmation-title')}
            >
                <Typography>{t('asset-editor-close-confirmation-prompt')}</Typography>
            </ConfirmationDialog>
        );
    }, [discardChangesDialogOnCancelHandler, onClose, openDiscardChangesDialog, t]);

    const textField = useCallback(
        (
            fieldName: string,
            fieldDataId: string,
            fieldOptions?: {
                disabled?: boolean;
                readonly?: boolean;
                required?: boolean;
            },
            fieldValidation?: {
                alphanumeric?: boolean;
                errorText?: string;
                hasError?: boolean;
                minLength?: number;
                validationRules?: RegisterOptions;
            }
        ) => {
            return (
                <TextInput
                    alphanumeric={fieldValidation?.alphanumeric}
                    className={classes.textInput}
                    dataId={`vehicle-editor-field-${fieldDataId}`}
                    disabled={fieldOptions?.disabled ?? false}
                    errorText={fieldValidation?.errorText}
                    fieldName={fieldName}
                    hasError={fieldValidation?.hasError}
                    label={t(`vehicle-editor-field-${fieldDataId}`)}
                    markValueChange
                    maxLength={20}
                    minLength={fieldValidation?.minLength ?? 0}
                    readonly={fieldOptions?.readonly ?? false}
                    required={fieldOptions?.required ?? false}
                    validationRules={fieldValidation?.validationRules}
                />
            );
        },
        [t, classes.textInput]
    );

    const classificationField = useCallback(
        (
            fieldName: string,
            fieldDataId: string,
            values: ClassificationAbstraction[],
            fieldOptions?: {
                required?: boolean;
            },
            fieldValidation?: {
                validationRules?: RegisterOptions;
            }
        ) => (
            <div style={{ width: 400 }}>
                <AutocompleteDropdownInput
                    className={classes.enumerationInput}
                    dataId={`vehicle-editor-field-${fieldDataId}`}
                    fieldName={fieldName}
                    label={t(`vehicle-editor-field-${fieldDataId}`)}
                    markValueChange
                    required={fieldOptions?.required}
                    validationRules={fieldValidation?.validationRules}
                    values={values}
                />
            </div>
        ),
        [classes.enumerationInput, t]
    );

    const classificationServiceField = useCallback(
        (
            fieldName: string,
            fieldDataId: string,
            values: ClassificationAbstraction[],
            classificationType?: ClassificationType
        ) => {
            const disabled =
                !canActivateServices && (editorState === CRUD_ACTIONS.EDIT || editorState === CRUD_ACTIONS.CREATE);
            const disabledReason = disabled ? t('activation-service-false-tooltip') : undefined;
            const settingsSourceRules =
                canActivateServices || editorState === CRUD_ACTIONS.EDIT || editorState === CRUD_ACTIONS.CREATE
                    ? undefined
                    : {
                          validate: {
                              req: (v: SettingsValueSource) =>
                                  v === SettingsValueSource.Default
                                      ? undefined
                                      : t('cannot-override-service-validation-error'),
                          },
                      };

            return (
                <ClassificationSettingsField
                    classificationType={classificationType}
                    className={classes.enumerationInput}
                    dataId={fieldDataId}
                    defaultSettingValue={vehicleDefaultSettingsValues?.[fieldName]}
                    disabled={disabled}
                    disabledReason={disabledReason}
                    fieldName={fieldName}
                    label={t(`vehicle-editor-field-${fieldDataId}`)}
                    markValueChange
                    settingsSourceFieldName={`${fieldName}${SourceFiledNameSuffix}`}
                    settingsSourceRules={settingsSourceRules}
                    values={values}
                />
            );
        },
        [canActivateServices, t, classes.enumerationInput, vehicleDefaultSettingsValues, editorState]
    );

    const booleanServiceField = useCallback(
        (fieldName: string, fieldDataId: string) => {
            const disabled =
                !canActivateServices && (editorState === CRUD_ACTIONS.EDIT || editorState === CRUD_ACTIONS.CREATE);
            const disabledReason = disabled ? t('activation-service-false-tooltip') : undefined;
            const settingsSourceRules =
                canActivateServices || editorState === CRUD_ACTIONS.EDIT || editorState === CRUD_ACTIONS.CREATE
                    ? undefined
                    : {
                          validate: {
                              req: (v: SettingsValueSource) =>
                                  v === SettingsValueSource.Default
                                      ? undefined
                                      : t('cannot-override-service-validation-error'),
                          },
                      };

            return (
                <BooleanSettingsField
                    className={classes.toggleInput}
                    controlType={BooleanControlType.ToggleButton}
                    dataId={fieldDataId}
                    defaultSettingValue={vehicleDefaultSettingsValues?.[fieldName]}
                    disabled={disabled}
                    disabledReason={disabledReason}
                    fieldName={fieldName}
                    markValueChange
                    settingsSourceFieldName={`${fieldName}${SourceFiledNameSuffix}`}
                    settingsSourceRules={settingsSourceRules}
                />
            );
        },
        [canActivateServices, classes.toggleInput, vehicleDefaultSettingsValues, t, editorState]
    );

    if (!vehicleDefaultFieldValues) {
        return <SceneLoader />;
    }

    const vinField = textField('vin', 'vin', undefined, {
        validationRules: {
            validate: {
                'mandatory-field': () => {
                    return !mandatoryFieldsArePopulated() ? t('vehicle-mandatory-field-error') : undefined;
                },
                'vin-not-unique': (value: string) =>
                    !isUniqueVIN(vehicleId, value) ? t('vin-not-unique-field-error') : undefined,
            },
        },
    });

    const aliasField = textField('alias', 'alias', undefined, {
        minLength: 3,
        validationRules: {
            validate: {
                'mandatory-field': () => {
                    return !mandatoryFieldsArePopulated() ? t('vehicle-mandatory-field-error') : undefined;
                },
            },
        },
    });

    const unitIdField = textField('unitId', 'unit-id', undefined, {
        validationRules: {
            validate: {
                'mandatory-field': () => {
                    return !mandatoryFieldsArePopulated() ? t('vehicle-mandatory-field-error') : undefined;
                },
            },
        },
    });

    const vrnField = textField('vrn', 'vrn');

    const isDeviceUsed = (option: ClassificationAbstraction) => getValueByPath(option, 'rowValues[2]', false);

    const deviceField = (
        <AutocompleteDropdownInput
            {...(!canUnassignDevice && {
                required:
                    !isNil(form.formState.defaultValues?.device) &&
                    !([CRUD_ACTIONS.CREATE, CRUD_ACTIONS.CLONE] as CrudActions[]).includes(editorState),
            })}
            className={classes.textInput}
            dataId="vehicle-editor-field-device"
            defaultValue={vehicleDefaultFieldValues.device ?? undefined}
            fieldName="device"
            headers={[t('msisdn'), t('serial-number'), t('vehicle-display-name')]}
            isBranded={isDeviceUsed}
            isMulticolumn
            label={t(`vehicle-editor-field-device`)}
            markValueChange
            maxLength={20}
            minLength={0}
            values={mapDevicesToMulticolumnClassificationAbstraction({
                devices: staticDataState.devices.data.array,
                devicesTypes,
                vehicleDisplayPreferencesFormat: displayPreferences.vehicleDisplayFormat,
                vehicles: staticDataState.vehicles.data.array,
            })}
        />
    );

    const tagField = textField('tag', 'tag');

    const companyCardIdField = classificationField(
        'companyCardId',
        'company-card-id',
        mapCompanyCardToClassificationAbstraction(companyCards),
        undefined,
        {
            validationRules: {
                validate: {
                    'company-card-not-valid': (value: string) =>
                        !isCompanyCardValid(value) ? t('company-card-not-valid-field-error') : undefined,
                },
            },
        }
    );

    const vehicleCategoryField = classificationField(
        'vehicleCategory',
        'vehicle-category',
        mapVehicleCategoryToClassificationAbstraction(t, categories),
        { required: true },
        { validationRules: { validate: undefined } }
    );

    const depotField = classificationField(
        'depot',
        'depot',
        mapDepotToClassification(staticDataState.depots.data.array),
        undefined,
        { validationRules: { validate: undefined } }
    );

    const vehicleTypeField = classificationField(
        'vehicleType',
        'vehicle-type',
        mapVehicleTypeToClassificationAbstraction(vehicleTypes),
        undefined,
        { validationRules: { validate: undefined } }
    );

    const alertsAndPanicField = servicesSecurables.alertsAndPanic
        ? booleanServiceField('alertsAndPanicEnabled', 'alerts-and-panic')
        : null;

    const driverCardsField = servicesSecurables.driverCards
        ? booleanServiceField('driverCardsEnabled', 'driver-cards')
        : null;

    const driverCoachField = servicesSecurables.driverCoach
        ? booleanServiceField('driverCoachEnabled', 'driver-coach')
        : null;

    const driverHoursField = servicesSecurables.driverHours
        ? booleanServiceField('driverHoursEnabled', 'driver-hours')
        : null;

    const dseField = servicesSecurables.drivingStyleEvaluation ? booleanServiceField('dseEnabled', 'dse') : null;

    const frequentPositioningField = servicesSecurables.frequentPositioning
        ? booleanServiceField('frequentPositioningEnabled', 'frequent-positioning')
        : null;

    const fuelSiteAdviceField = servicesSecurables.fuelSiteAdvice
        ? booleanServiceField('fuelSiteAdviceEnabled', 'fuel-site-advice')
        : null;

    const kpi2Field = servicesSecurables.kpi2 ? booleanServiceField('kpi2Enabled', 'kpi2') : null;

    const landmarksField = servicesSecurables.landmarks ? booleanServiceField('landmarksEnabled', 'landmarks') : null;

    const rtdsField = servicesSecurables.rtds ? booleanServiceField('rtdsEnabled', 'rtds') : null;

    const textMessagingField = servicesSecurables.textMessaging
        ? booleanServiceField('textMessagingEnabled', 'text-messaging')
        : null;

    const trailersField = servicesSecurables.trailers ? booleanServiceField('trailersEnabled', 'trailers') : null;

    const tireControlField = servicesSecurables.tireControl
        ? booleanServiceField('tireControlEnabled', 'tire-control')
        : null;

    const appStoreField = servicesSecurables.appStore ? booleanServiceField('appStoreEnabled', 'app-store') : null;

    const truckIdentificationField = servicesSecurables.truckIdentification
        ? booleanServiceField('truckIdentificationEnabled', 'truck-identification')
        : null;

    const trailerControlServiceField = servicesSecurables.trailerControlService
        ? booleanServiceField('trailerControlServiceEnabled', 'trailer-control-service')
        : null;

    const guidedNavigationField = servicesSecurables.guidedNavigation
        ? booleanServiceField('guidedNavigationEnabled', 'guided-navigation')
        : null;

    const j1939DataServiceField = servicesSecurables.j1939DataService
        ? booleanServiceField('j1939DataServiceEnabled', 'j1939-data-service')
        : null;

    const navigationEtaField = servicesSecurables.navigationEta
        ? booleanServiceField('navigationEtaEnabled', 'navigation-eta')
        : null;

    const workflowField = servicesSecurables.workflow
        ? classificationServiceField(
              'workflowType',
              'workflow-type',
              mapClassificationToClassificationAbstraction(workflows),
              ClassificationType.WorkflowType
          )
        : null;

    const navigationField = servicesSecurables.navigation
        ? classificationServiceField(
              'navigationType',
              'navigation-type',
              mapClassificationToClassificationAbstraction(navigations),
              ClassificationType.NavigationType
          )
        : null;

    const imageCapturingField = servicesSecurables.imageCapturing
        ? classificationServiceField(
              'imageCapturingType',
              'image-capturing-type',
              mapClassificationToClassificationAbstraction(imageCapturings),
              ClassificationType.ImageCapturingType
          )
        : null;

    const startInhibitorField = servicesSecurables.inhibitorService
        ? classificationServiceField(
              'inhibitorServiceType',
              'inhibitor-service-type',
              mapClassificationToClassificationAbstraction(startInhibitors),
              ClassificationType.InhibitorServiceType
          )
        : null;

    const tellTalesServiceField = servicesSecurables.tellTaleService
        ? classificationServiceField(
              'tellTaleServiceType',
              'telltales-service-type',
              mapClassificationToClassificationAbstraction(tellTalesServices),
              ClassificationType.TellTaleServiceType
          )
        : null;

    return (
        <>
            {isOpenDialog && (
                <Dialog
                    confirmationText={t('save-and-activate')}
                    dataId="activate-services-confirmation-dialog"
                    isOpen
                    onClose={handleCloseActivateServices}
                    onConfirm={handleConfirm}
                    title={t('activate-services')}
                >
                    <span>{t('confirm-activate-services')}</span>
                </Dialog>
            )}

            {discardChangesDialog}
            <div className={classes.root} data-id="editor-root">
                <WidgetDialog
                    dialogActions={actionButtons}
                    headerActions={[headerIcon]}
                    open
                    testId="vehicle-editor-dialog"
                    title={getEditorTitle(editorState)}
                >
                    <>
                        {topBanner}
                        <div className={classes.body}>
                            {leftPaneElement}
                            <FormProvider {...form}>
                                <form
                                    className={classNames(classes.form)}
                                    data-id="vehicle-editor-form"
                                    id="vehicle-editor-form"
                                    onSubmit={form.handleSubmit(submitForm)}
                                >
                                    <div className={classes.formContent} data-id="form-content">
                                        <Typography
                                            className={classes.sectionTitle}
                                            color="textSecondary"
                                            data-id="vehicle-editor-section-general"
                                            ref={generalRef}
                                            variant="subtitle1"
                                        >
                                            {t('vehicle-editor-section-general')}
                                        </Typography>
                                        {vinField}
                                        {vrnField}
                                        {unitIdField}
                                        {aliasField}
                                        {deviceField}
                                        {vehicleCategoryField}
                                        {tagField}
                                        {depotField}
                                        {vehicleTypeField}
                                        {companyCardIdField}
                                        <Typography
                                            className={classes.sectionTitle}
                                            color="textSecondary"
                                            data-id="vehicle-editor-section-tachograph"
                                            ref={tachographRef}
                                            variant="subtitle1"
                                        >
                                            {t('vehicle-editor-section-tachograph')}
                                        </Typography>
                                        <ClassificationSettingsField
                                            classificationType={ClassificationType.Tachograph}
                                            className={classes.enumerationInput}
                                            dataId="tachograph"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.tachograph ??
                                                Object.values(tachographs)[0].id
                                            }
                                            fieldName="tachograph"
                                            label={t(`vehicle-editor-field-tachograph`)}
                                            markValueChange
                                            settingsSourceFieldName={`tachograph${SourceFiledNameSuffix}`}
                                            values={mapClassificationToClassificationAbstraction(tachographs)}
                                        />
                                        <ClassificationSettingsField
                                            classificationType={ClassificationType.TachoProtocol}
                                            className={classes.enumerationInput}
                                            dataId="tacho-protocol"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.tachoProtocol ??
                                                Object.values(tachoProtocols)[0].id
                                            }
                                            fieldName="tachoProtocol"
                                            label={t(`vehicle-editor-field-tacho-protocol`)}
                                            markValueChange
                                            settingsSourceFieldName={`tachoProtocol${SourceFiledNameSuffix}`}
                                            values={mapClassificationToClassificationAbstraction(tachoProtocols)}
                                        />
                                        <BooleanSettingsField
                                            className={classes.toggleInput}
                                            controlType={BooleanControlType.ToggleButton}
                                            dataId="one-minute-rule-enabled"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.oneMinuteRuleEnabled ?? false
                                            }
                                            fieldName="oneMinuteRuleEnabled"
                                            markValueChange
                                            settingsSourceFieldName={`oneMinuteRuleEnabled${SourceFiledNameSuffix}`}
                                        />
                                        <ClassificationSettingsField
                                            classificationType={ClassificationType.TachoGeneration}
                                            className={classes.enumerationInput}
                                            dataId="tacho-generation"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.tachoGeneration ??
                                                Object.values(tachoGenerations)[0].id
                                            }
                                            fieldName="tachoGeneration"
                                            label={t(`vehicle-editor-field-tacho-generation`)}
                                            markValueChange
                                            settingsSourceFieldName={`tachoGeneration${SourceFiledNameSuffix}`}
                                            values={mapClassificationToClassificationAbstraction(tachoGenerations)}
                                        />
                                        <Typography
                                            className={classes.sectionTitle}
                                            color="textSecondary"
                                            data-id="vehicle-editor-section-services"
                                            ref={servicesRef}
                                            variant="subtitle1"
                                        >
                                            {t('vehicle-editor-section-services')}
                                        </Typography>
                                        {alertsAndPanicField}
                                        {driverCardsField}
                                        {driverCoachField}
                                        {driverHoursField}
                                        {dseField}
                                        {frequentPositioningField}
                                        {fuelSiteAdviceField}
                                        {kpi2Field}
                                        {landmarksField}
                                        {rtdsField}
                                        {textMessagingField}
                                        {trailersField}
                                        {workflowField}
                                        {guidedNavigationField}
                                        {navigationField}
                                        {imageCapturingField}
                                        {tireControlField}
                                        {appStoreField}
                                        {truckIdentificationField}
                                        {trailerControlServiceField}
                                        {startInhibitorField}
                                        {tellTalesServiceField}
                                        {j1939DataServiceField}
                                        {navigationEtaField}
                                        <Typography
                                            className={classes.sectionTitle}
                                            color="textSecondary"
                                            data-id="vehicle-editor-section-fuel"
                                            ref={fuelRef}
                                            variant="subtitle1"
                                        >
                                            {t('vehicle-editor-section-fuel')}
                                        </Typography>
                                        <NumericInputSettingsField
                                            defaultSettingValue={vehicleDefaultSettingsValues?.primaryFuelTankSize}
                                            fieldDataId="primary-fuel-tank-size"
                                            fieldName="primaryFuelTankSize"
                                            maxValue={1600}
                                            minValue={10}
                                            settingsSourceFieldName="primaryFuelTankSizeValueSource"
                                        />
                                        <ClassificationSettingsField
                                            classificationType={ClassificationType.FuelTankType}
                                            className={classes.enumerationInput}
                                            dataId="primary-fuel-tank-type"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.primaryFuelTankType ??
                                                Object.values(fuelTankTypes)[0].id
                                            }
                                            fieldName="primaryFuelTankType"
                                            label={t('vehicle-editor-field-primary-fuel-tank-type')}
                                            markValueChange
                                            settingsSourceFieldName="primaryFuelTankTypeValueSource"
                                            values={mapClassificationToClassificationAbstraction(fuelTankTypes)}
                                        />
                                        <NumericInputSettingsField
                                            defaultSettingValue={vehicleDefaultSettingsValues?.secondaryFuelTankSize}
                                            fieldDataId="secondary-fuel-tank-size"
                                            fieldName="secondaryFuelTankSize"
                                            maxValue={1600}
                                            minValue={0}
                                            settingsSourceFieldName="secondaryFuelTankSizeValueSource"
                                        />
                                        <ClassificationSettingsField
                                            classificationType={ClassificationType.FuelTankType}
                                            className={classes.enumerationInput}
                                            dataId="secondary-fuel-tank-type"
                                            defaultSettingValue={
                                                vehicleDefaultSettingsValues?.secondaryFuelTankType ??
                                                Object.values(fuelTankTypes)[0].id
                                            }
                                            fieldName="secondaryFuelTankType"
                                            label={t('vehicle-editor-field-secondary-fuel-tank-type')}
                                            markValueChange
                                            settingsSourceFieldName="secondaryFuelTankTypeValueSource"
                                            values={mapClassificationToClassificationAbstraction(fuelTankTypes)}
                                        />
                                        <NumericInputSettingsField
                                            defaultSettingValue={vehicleDefaultSettingsValues?.adBlueTankSize}
                                            fieldDataId="adblue-tank-size"
                                            fieldName="adBlueTankSize"
                                            maxValue={250}
                                            minValue={0}
                                            settingsSourceFieldName="adBlueTankSizeValueSource"
                                        />
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </>
                </WidgetDialog>
            </div>
        </>
    );
};
