import type { FC } from 'react';
import { memo } from 'react';

import { PositionMiniMapSection } from '~/common';
import { entryEquals } from '~/common/utils';

import type { MiniMapSectionProps } from '../../../models';

const MiniMapComponent: FC<MiniMapSectionProps> = (props) => {
    const {
        changeMapMode,
        changeZoomLevel,
        dragHandleElement,
        driverStatus,
        isCollapsed,
        lastLocation,
        mapMode,
        toggleCollapsed,
        zoomLevel,
    } = props;

    return (
        <PositionMiniMapSection
            changeMapMode={changeMapMode}
            changeZoomLevel={changeZoomLevel}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            lastLocation={lastLocation}
            mapMode={mapMode}
            position={driverStatus.location?.position}
            toggleCollapsed={toggleCollapsed}
            zoomLevel={zoomLevel}
        />
    );
};

MiniMapComponent.displayName = 'MiniMap';
export default memo(MiniMapComponent, (a, b) =>
    entryEquals<MiniMapSectionProps>({ a, b, paths: ['driverStatus', 'zoomLevel', 'isCollapsed', 'mapMode'] })
);
