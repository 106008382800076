import type { StaticDataStoreState } from '~/common';

import { ACTION_TYPE_KEYS } from './actionTypes';
import type { ActionTypes, TripReportState } from './models';
import { resolveTrips } from './utils';

const initialState: TripReportState = {
    error: false,
    items: [],
    pending: false,
};

const tripReportReducer = (state = initialState, action: ActionTypes, staticData: StaticDataStoreState) => {
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED:
            return {
                ...state,
                error: false,
                items: resolveTrips({ items: action.payload, staticData }),
                pending: false,
            };
        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case ACTION_TYPE_KEYS.RESET:
            return initialState;

        default:
            return state;
    }
};

export { tripReportReducer };
