import { CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';

import { LatestMessage } from '../../../LatestMessages/components/LatestMessage';
import { RetryLoadLatestMessages } from '../../../LatestMessages/components/RetryLoadLatestMessages';
import type { LatestMessagesSectionContentInnerProps } from '../../component';

const DecideContent: FC<LatestMessagesSectionContentInnerProps> = (props) => {
    const {
        classes,
        deletedMessageIds,
        failedToLoadMessages,
        getLatestMessages,
        latestMessagesSortedDesc,
        loadingMessages,
        t,
    } = props;

    if (loadingMessages) {
        return (
            <div className={classNames(classes.paperBackground, classes.centerContents)} data-id="loading-messages">
                <CircularProgress size={32} />
            </div>
        );
    }

    if (failedToLoadMessages) {
        return (
            <div className={classNames(classes.centerContents, classes.failedToLoad)}>
                <RetryLoadLatestMessages onClick={getLatestMessages} />
            </div>
        );
    }

    if (!latestMessagesSortedDesc.length) {
        return (
            <div className={classes.centerContents} data-id="no-messages-sent-yet">
                <Typography className={classes.italicText} variant="caption">
                    {t('no-messages-sent-yet')}
                </Typography>
            </div>
        );
    }

    const sortedMessages = latestMessagesSortedDesc.slice().reverse();

    return (
        <>
            {sortedMessages.map((message) => (
                <LatestMessage
                    isDeleted={!!(deletedMessageIds && deletedMessageIds.includes(message.value.id))}
                    key={message.value.id}
                    value={message}
                />
            ))}
        </>
    );
};

DecideContent.displayName = 'DecideContent';
export { DecideContent };
