import { Grid, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { LabeledIndicatorGroupClassKey } from './styles';

export interface LabeledIndicatorGroupProps {
    badge?: JSX.Element;
    childrenColumnSpan?: number[];
    dataId?: string;
    options?: JSX.Element | string[];
    title?: string;
}

export interface LabeledIndicatorGroupInnerProps
    extends LabeledIndicatorGroupProps,
        WithStyles<LabeledIndicatorGroupClassKey> {}

export const LabeledIndicatorGroupComponent: React.FC<LabeledIndicatorGroupInnerProps> = ({
    badge,
    children,
    childrenColumnSpan,
    classes,
    dataId,
    options,
    title,
}) => {
    const formattedBadge = badge && <span className={classes.badge}>{badge}</span>;
    const formattedTitle = (
        <span className={classes.title} data-id="group-title">
            {title && title.toUpperCase()}
        </span>
    );

    const optionsMapped = React.useMemo(() => {
        return (
            options &&
            options instanceof Array &&
            options.map((option: string) => {
                return (
                    <span className={classes.option} key={option}>
                        {option.toUpperCase()}
                    </span>
                );
            })
        );
    }, [classes.option, options]);

    const optionsFormatted = (
        <Typography color="textSecondary" variant="subtitle2">
            {options && <div className={classes.options}>{optionsMapped}</div>}
        </Typography>
    );

    const groupTitleWithBadge = (
        <Typography className={classes.flex} color="textSecondary" variant="subtitle2">
            {formattedBadge}
            {formattedTitle}
        </Typography>
    );
    const header = (
        <div className={classes.splitRow}>
            {groupTitleWithBadge}
            {optionsFormatted}
        </div>
    );

    const gridItems = React.useMemo(() => {
        return (
            Array.isArray(children) &&
            children.map((child: React.ReactNode, index: React.ReactText) => {
                return (
                    <Grid
                        item
                        key={child?.toString()}
                        xs={childrenColumnSpan?.length ? childrenColumnSpan[index] : 12 / children.length}
                    >
                        {child}
                    </Grid>
                );
            })
        );
    }, [children, childrenColumnSpan]);

    const grid = React.useMemo(() => {
        return (
            gridItems && (
                <Grid container spacing={2}>
                    {gridItems}
                </Grid>
            )
        );
    }, [gridItems]);

    return (
        <div className={classes.root} data-id={dataId}>
            {header}
            {grid}
        </div>
    );
};
