import type { FC } from 'react';

import { DriverNameFormatter } from '~/components/Formatters';

import type { DriverMarkerNameFormatterProps } from './models';

const DriverMarkerNameFormatter: FC<DriverMarkerNameFormatterProps> = (props) => {
    const { displayNameFormat, entry } = props;
    return (
        <DriverNameFormatter
            dataTestId="driver-name-formatter"
            displayNameFormat={displayNameFormat}
            value={entry.driver?.driver}
        />
    );
};

DriverMarkerNameFormatter.displayName = 'DriverMarkerNameFormatter';

export { DriverMarkerNameFormatter };
