import { withStyles } from '@mui/styles';
import { setDisplayName } from 'react-recompose';
import compose from 'react-recompose/compose';

import { DriverMarker as DriverMarkerComponent } from './component';
import type { DriverMarkerInnerProps, DriverMarkerProps } from './models';
import { DriverMarkerStyleRules } from './styles';

export const DriverMarker = compose<DriverMarkerInnerProps, DriverMarkerProps>(
    setDisplayName('DriverMarker'),
    withStyles(DriverMarkerStyleRules)
)(DriverMarkerComponent);
