import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import {
    AddressFormatter,
    DistanceFormatter,
    DriverEventHasCorrectionFormatter,
    DriverHoursLoginStatusFormatter,
    HistoryDriverEventFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import { createDateTimeColumn, createDurationColumn, createFormattedColumn } from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type {
    Address,
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
    DriverActivityType,
    NearestCity,
} from '~/services/ApiClient';
import { LoginStatus } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    exportFormatterFactory,
    exportFormatterIncludingUndefinedFactory,
    formatAddress,
    formatBoolean,
    formatDriverHoursLoginStatus,
    formatHistoryDriverEvent,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    getDistanceUnitSystemUtils,
    valueTextFormatterFactory,
    valueTextFormatterIncludingUndefinedFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory, groupingCriteriaIncludingUndefinedFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import type { HistoryDriverEntry } from '../../../../models';

import { ColumnName } from './constants';
import { getDriverIdValue, getDriverNameValue } from './getCellValue.driver';
import {
    getAddressValue,
    getCityValue,
    getCoDriverIdValue,
    getCoDriverNameValue,
    getCountryCodeValue,
    getDistanceValue,
    getDriverActivityValue,
    getDriverSubActivityValue,
    getDurationValue,
    getEndOdometerValue,
    getIsCorrectionValue,
    getLatitudeValue,
    getLoginStatusValue,
    getLongitudeValue,
    getMsisdnValue,
    getNearestCityValue,
    getPostalCodeValue,
    getStartDateValue,
    getStartOdometerValue,
    getVehicleDisplayNameFactory,
} from './getCellValue.event';

export const getRowId = (row: HistoryDriverEntry): number => row.driverEvent.eventId;

export const getColumns = (
    t: SingleTFunction,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    unitSystem: DisplayUserPreferencesUnitSystem
): Array<GridColumnDefinition<HistoryDriverEntry>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const numberToValueTextFormatter = valueTextFormatterFactory((v: number) => v.toString());

    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);

    const driverColumns: Array<GridColumnDefinition<HistoryDriverEntry>> = [
        {
            compare: compareFactory(
                (v: DriverActivityType | undefined) => formatHistoryDriverEvent(t, v),
                stringComparer
            ),
            dataType: 'string',
            exportValueFormatter: exportFormatterIncludingUndefinedFactory((v: DriverActivityType | undefined) =>
                formatHistoryDriverEvent(t, v)
            ),
            formatUndefinedValue: true,
            getCellValue: getDriverActivityValue,
            groupingCriteria: groupingCriteriaIncludingUndefinedFactory((v: DriverActivityType | undefined) =>
                formatHistoryDriverEvent(t, v)
            ),
            groupTitle: t('driver'),
            name: ColumnName.DRIVERACTIVITY,
            title: t('driver-activity'),
            valueFormatterComponent: withTranslation()(HistoryDriverEventFormatter),
            valueTextFormatter: valueTextFormatterIncludingUndefinedFactory((v: DriverActivityType | undefined) =>
                formatHistoryDriverEvent(t, v)
            ),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getDriverSubActivityValue,
            groupTitle: t('driver'),
            name: ColumnName.SUBACTIVITY,
            title: t('driver-sub-activity'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getDriverNameValue(driverDisplayNameFormat),
            groupTitle: t('driver'),
            name: ColumnName.DRIVER,
            title: t('driver-name'),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'string',
            getCellValue: getDriverIdValue,
            groupTitle: t('driver'),
            name: ColumnName.DRIVERID,
            title: t('driver-id'),
            valueTextFormatter: numberToValueTextFormatter,
        },
        createFormattedColumn(
            ColumnName.CARDSTATUS,
            t('card-status'),
            t('driver'),
            (entry) => getLoginStatusValue(entry),
            (status) => formatDriverHoursLoginStatus(t, status),
            {
                align: 'center',
                cellFiltering: {
                    options: Object.values(LoginStatus),
                },
                valueFormatterComponent: withTranslation()(DriverHoursLoginStatusFormatter),
            }
        ),
        createDateTimeColumn(ColumnName.DATETIME, t('date-time'), t('general'), getStartDateValue),
        createDurationColumn(t, ColumnName.DURATION, t('duration'), t('general'), getDurationValue),
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCoDriverNameValue(driverDisplayNameFormat),
            groupTitle: t('co-driver'),
            name: ColumnName.CODRIVER,
            title: t('co-driver-name'),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'string',
            getCellValue: getCoDriverIdValue,
            groupTitle: t('co-driver'),
            name: ColumnName.CODRIVERID,
            title: t('co-driver-id'),
            valueTextFormatter: numberToValueTextFormatter,
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getVehicleDisplayNameFactory(vehicleDisplayNameFormat),
            groupTitle: t('vehicle'),
            name: ColumnName.VEHICLE,
            title: t('vehicle'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getMsisdnValue,
            groupTitle: t('vehicle'),
            name: ColumnName.MSISDN,
            title: t('msisdn'),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            getCellValue: getStartOdometerValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            groupTitle: t('vehicle'),
            name: ColumnName.STARTODOMETER,
            title: t('start-odometer'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            getCellValue: getEndOdometerValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            groupTitle: t('vehicle'),
            name: ColumnName.ENDODOMETER,
            title: t('end-odometer'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            getCellValue: getDistanceValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            groupTitle: t('vehicle'),
            name: ColumnName.DISTANCE,
            title: t('distance'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
        },
        {
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            getCellValue: getNearestCityValue,
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            groupTitle: t('location'),
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(NearestCityFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCityValue,
            groupTitle: t('location'),
            name: ColumnName.CITY,
            title: t('city'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getPostalCodeValue,
            groupTitle: t('location'),
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
        },
        {
            compare: compareFactory(formatAddress, stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory(formatAddress),
            getCellValue: getAddressValue,
            groupingCriteria: groupingCriteriaFactory(formatAddress),
            groupTitle: t('location'),
            name: ColumnName.ADDRESS,
            title: t('address'),
            valueFormatterComponent: AddressFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: Address) => formatAddress(v)),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCountryCodeValue,
            groupTitle: t('location'),
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
        },
        {
            compare: compareNumbers,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            getCellValue: getLatitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            compare: compareNumbers,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            getCellValue: getLongitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: getIsCorrectionValue,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('general'),
            name: ColumnName.ISCORRECTION,
            title: t('is-correction'),
            valueFormatterComponent: withTranslation()(DriverEventHasCorrectionFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
    ];

    return driverColumns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
