import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SelectEntryDialogClassKey =
    | 'body'
    | 'closeIcon'
    | 'content'
    | 'disabled'
    | 'disabledItem'
    | 'filter'
    | 'item'
    | 'itemInput'
    | 'items'
    | 'search'
    | 'searchIcon'
    | 'searchInput'
    | 'selectedItem';

export const SelectEntryDialogStyleRules: StyleRulesCallback<Theme, {}, SelectEntryDialogClassKey> = (theme: Theme) =>
    createStyles({
        body: {
            display: 'flex',
            height: '100%',
        },
        closeIcon: {
            '&:hover': {
                color: alpha(theme.palette.text.primary, 1),
                cursor: 'pointer',
            },
            color: alpha(theme.palette.text.primary, 0.54),
            margin: theme.spacing(0.5),
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        disabled: {
            opacity: 0.26,
        },
        disabledItem: {
            '&:hover': {
                background: 'none',
            },

            cursor: 'default',
        },
        filter: {
            borderLeft: `1px solid ${theme.palette.divider}`,
            width: 300,
        },
        item: {
            '&:hover': {
                background: theme.palette.action.hover,
            },
            alignItems: 'start',
            cursor: 'pointer',
            display: 'flex',

            padding: theme.spacing(1, 2),
        },
        itemInput: {
            marginRight: theme.spacing(2),
            padding: theme.spacing(),
        },
        items: {
            overflow: 'auto',
        },
        search: {
            '&::before': {
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                content: "''",
                height: '100%',
                margin: theme.spacing(-0.5),
                pointerEvents: 'none',
                position: 'absolute',
                visibility: 'hidden',
                width: '100%',
            },
            inputPlaceHolder: {
                fontSize: 14,
                fontWeight: theme.typography.fontWeightMedium,
                height: theme.spacing(4),
                opacity: 0.87,
            },
            padding: theme.spacing(1, 2.5),
            root: {
                backgroundColor: 'transparent',
                cursor: 'text',
                height: 40,
                padding: theme.spacing(0.5),
                position: 'relative',
            },
        },
        searchIcon: {
            color: alpha(theme.palette.text.primary, 0.54),
            margin: theme.spacing(0.5),
        },
        searchInput: {
            '&:focus-within $searchIcon': {
                color: alpha(theme.palette.text.primary, 1),
            },
            color: theme.palette.text.primary,
            margin: theme.spacing(0, 1.5),
        },
        selectedItem: {
            background: theme.palette.action.selected,
        },
    });
