import { escapeRegExp, isEqual } from '../../utility';
import type { Option, ValueOption } from '../models';

const flattenOptions = <T>(
    options: Option<T>[],
    predicate: (option: Option<T>) => boolean = () => true
): ValueOption<T>[] =>
    options
        .filter(predicate)
        .flatMap((option) => ('id' in option ? [option] : flattenOptions(option.options, predicate)));

interface FilterOptionsArgs<T, O extends Option<T>> {
    options: O[];
    searchText: string;
}

const filterOptions = <T, O extends Option<T>>({ options, searchText }: FilterOptionsArgs<T, O>): O[] => {
    if (!searchText) {
        return options;
    }

    const regex = new RegExp(escapeRegExp(searchText), 'i');
    return options
        .map((option): O | undefined => {
            if ('id' in option) {
                return regex.test(option.displayName) ? option : undefined;
            }

            return {
                ...option,
                options: filterOptions({ options: option.options, searchText }),
            };
        })
        .filter(Boolean) as O[];
};

const getAllChildrenItems = <T>(id: T, baseList: ValueOption<T>[]): ValueOption<T>[] => {
    const children = baseList.filter((x) => isEqual(x.parentId, id));
    const result: ValueOption<T>[] = [...children];
    children.forEach((x) => {
        const subChildren = getAllChildrenItems(x.id, baseList);
        result.push(...subChildren);
    });
    return result;
};

export { filterOptions, flattenOptions, getAllChildrenItems };
