import { getI18n } from 'react-i18next';

import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

const getDistanceUnit = (unit: UnitSystem) => {
    const { t } = getI18n();
    switch (unit) {
        case UNIT_SYSTEMS.IMPERIAL:
            return t('unit-mi');
        case UNIT_SYSTEMS.METRIC:
            return t('unit-km');
        default:
            throw new Error('Unknown unit system.');
    }
};

export { getDistanceUnit };
