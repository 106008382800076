import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const IgnitionOnIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <rect
                height="2.6"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9354 12.0849)"
                width="8.7"
                x="7.8"
                y="10.7"
            />
            <path
                d="M12.1 1C6 1 1.1 5.9 1.1 12s4.9 11 11 11s11-4.9 11-11S18.2 1 12.1 1z M13.2 5c-0.6 0-1-0.4-1-1s0.4-1 1-1s1 0.4 1 1
        S13.8 5 13.2 5z M6.2 7c-0.6 0-1-0.4-1-1s0.4-1 1-1s1 0.4 1 1S6.8 7 6.2 7z M12.1 18.5c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5
        s6.5 2.9 6.5 6.5S15.7 18.5 12.1 18.5z M19.2 8c-0.6 0-1-0.4-1-1s0.4-1 1-1s1 0.4 1 1S19.8 8 19.2 8z M3.2 13.5
        c-0.3 0-0.5-0.2-0.5-0.5c0-0.3 0.2-0.5 0.5-0.5s0.5 0.2 0.5 0.5S3.5 13.5 3.2 13.5z"
            />
        </SvgIcon>
    );
};
IgnitionOnIcon.displayName = 'IgnitionOnIcon';

export { IgnitionOnIcon };
