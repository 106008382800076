import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import type { ComponentType } from 'react';
import { ToastContainer as _ToastContainer } from 'react-toastify';
import type { ToastContainerProps } from 'react-toastify';

import { themeSpacingToNumber } from '../utility';

interface _ToastContainerProps {
    muiTheme: Theme;
}

const ToastContainer = styled(_ToastContainer, {
    shouldForwardProp: (propName) => propName !== 'muiTheme',
})<_ToastContainerProps>(
    ({ muiTheme }) => ({
        '&.Toastify__toast-container--bottom-left': {
            left: `${muiTheme.custom.menu.width + themeSpacingToNumber(muiTheme.spacing())}px`,
        },
    })
    // Override props from styled due conflicts with prop naming "theme"
) as ComponentType<_ToastContainerProps & ToastContainerProps>;

export { ToastContainer };
