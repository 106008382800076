import { mToKm, mToMiles, milesToYards } from '@fv/converters';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { UnitSystemConverterOptions, UnitSystemFormatterOptions, UnitSystemUtils } from './unitSystemUtils';

const getImperialUtils = (t: SingleTFunction) => {
    const milesUnit = t('unit-mi');
    const yardsUnit = t('unit-yd');
    const defaultPrecision = 0;

    return {
        converter: (distance?: number, options?: UnitSystemConverterOptions) => {
            return !isUndefined(distance)
                ? Number(mToMiles(distance).toFixed(options?.precision ?? defaultPrecision))
                : undefined;
        },
        formatter: (distance?: number, options?: UnitSystemFormatterOptions) => {
            if (isUndefined(distance)) {
                return '';
            }

            const miles = mToMiles(distance);
            if (options?.autoFormat) {
                if (miles < 0.1) {
                    return `${milesToYards(miles).toFixed(0)} ${yardsUnit}`;
                }

                if (miles < 10) {
                    return `${miles.toFixed(1)} ${milesUnit}`;
                }

                return `${miles.toFixed(0)} ${milesUnit}`;
            }

            return `${miles.toFixed(options?.precision ?? defaultPrecision)} ${milesUnit}`;
        },
        unit: milesUnit,
    };
};

const getMetricUtils = (t: SingleTFunction) => {
    const kilometersUnit = t('unit-km');
    const metersUnit = t('unit-m');
    const defaultPrecision = 0;

    return {
        converter: (distance?: number, options?: UnitSystemConverterOptions) => {
            return !isUndefined(distance)
                ? Number(mToKm(distance).toFixed(options?.precision ?? defaultPrecision))
                : undefined;
        },
        formatter: (distance?: number, options?: UnitSystemFormatterOptions) => {
            if (isUndefined(distance)) {
                return '';
            }

            if (options?.autoFormat) {
                if (distance < 100) {
                    return `${distance.toFixed(0)} ${metersUnit}`;
                }

                const km = mToKm(distance);
                if (km < 10) {
                    return `${km.toFixed(1)} ${kilometersUnit}`;
                }

                return `${km.toFixed(0)} ${kilometersUnit}`;
            }

            return `${mToKm(distance).toFixed(options?.precision ?? defaultPrecision)} ${kilometersUnit}`;
        },
        unit: kilometersUnit,
    };
};

export const getDistanceUnitSystemUtils = memoizeOne(
    (t: SingleTFunction, unitSystem: DisplayUserPreferencesUnitSystem): UnitSystemUtils => {
        switch (unitSystem) {
            case DisplayUserPreferencesUnitSystem.Imperial:
                return getImperialUtils(t);
            case DisplayUserPreferencesUnitSystem.Metric:
                return getMetricUtils(t);
            default:
                throw new Error('Unknown unit system.');
        }
    }
);
