import type { Dispatch } from 'redux';

import { reportError } from '~/reporting';

import type { DispatchProps, StateProps } from './component';
import { retrieveCustomerUsersAction } from './data';
import type { UserAutocompleteStoreState } from './reducers';

export const mapStateToProps = (userAutocompleteState: UserAutocompleteStoreState): StateProps => ({
    loadingUsers: userAutocompleteState.users.pending,
    retrieveUsersFulfilled: userAutocompleteState.users.fulfilled,
    users: userAutocompleteState.users.data,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getUsersForCustomer: (cid: number) => {
        dispatch(retrieveCustomerUsersAction(cid)).catch(reportError);
    },
});
