import type { MapViewport } from '~/common';
import type { Action, ActionMeta, BaseAction, RequiredAndNonNullable } from '~/types';

export enum ActionTypeKeys {
    SCENEMAP_CHANGESEARCHQUERY = 'SCENEMAP_CHANGESEARCHQUERY',
    SCENEMAP_CHANGEVIEWPORT = 'SCENEMAP_CHANGEVIEWPORT',
    SCENEMAP_HISTORY = 'SCENEMAP_HISTORY',
    SCENEMAP_PANTOASSET = 'SCENEMAP_PANTOASSET',
    SCENEMAP_SHOWALLSELECTEDASSETS = 'SCENEMAP_SHOWALLSELECTEDASSETS',
}

export type ActionTypes =
    | Action<ActionTypeKeys.SCENEMAP_CHANGEVIEWPORT, RequiredAndNonNullable<MapViewport>>
    | Action<ActionTypeKeys.SCENEMAP_HISTORY, boolean>
    | ActionMeta<ActionTypeKeys.SCENEMAP_CHANGESEARCHQUERY, string | undefined, string>
    | BaseAction<ActionTypeKeys.SCENEMAP_PANTOASSET>
    | BaseAction<ActionTypeKeys.SCENEMAP_SHOWALLSELECTEDASSETS>;
