import moment from 'moment';

import { orderBy } from '~/libs/utility';
import type { PositionGroup } from '~/services/ApiClient';

export interface PreparedItem {
    date: Date;
    firstOfDay: boolean;
    positionGroup: PositionGroup;
}

export const buildItems = (positionGroups: PositionGroup[], fallbackDateTime: Date): PreparedItem[] => {
    let lastDaySeparator: moment.Moment | undefined;
    let previousDate = fallbackDateTime;

    const orderedGroups = orderBy(positionGroups, (item) => item.start?.dateTime ?? new Date(Number.MIN_VALUE), 'desc');

    const items: PreparedItem[] = [];
    for (let i = 0; i < orderedGroups.length; i++) {
        const currentDate = orderedGroups[i].start?.dateTime ?? previousDate;
        const currentMoment = moment(currentDate).startOf('day');
        const firstOfDay = !lastDaySeparator || !lastDaySeparator.isSame(currentMoment, 'day');

        items.push({ date: currentMoment.toDate(), firstOfDay, positionGroup: orderedGroups[i] });

        lastDaySeparator = currentMoment;
        previousDate = currentDate;
    }

    return items;
};
