import type { FC } from 'react';

import { useDisplayPreferences } from '~/common/hooks';
import { SceneMapView } from '~/components/SceneMap';
import type { MonitoringDriverEntry } from '~/data/monitoring';
import { monitoringDriverEntryEquals } from '~/scenes/Monitoring/utils';

import { MONITORING_MAP_DRIVER_STATE_KEY } from './consts';
import { createFilter } from './createFilter';
import type { DriverMapViewProps } from './models';

const getRowId = (entry: MonitoringDriverEntry): number => entry.status.driver;
const getLocation = (entry: MonitoringDriverEntry) => entry.status.location?.position;

const DriverMapView: FC<DriverMapViewProps> = (props) => {
    const displayPreferences = useDisplayPreferences();

    return (
        <SceneMapView
            dataId="monitoring-map-driver"
            entryEquals={monitoringDriverEntryEquals}
            filterDataSource={createFilter(displayPreferences)}
            getAssetIdentifier={getRowId}
            getAssetPosition={getLocation}
            mapKey={MONITORING_MAP_DRIVER_STATE_KEY}
            showTooltip
            {...props}
        />
    );
};
DriverMapView.displayName = 'DriverMapView';

export { DriverMapView };
