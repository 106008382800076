import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification } from '~/services/ApiClient';
import { AssetConnectionStatus, ClassificationType, TrailerType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import type { TrailerConnectionFilteringClassKey } from './styles';

export interface TrailerConnectionFilter {
    connectionStatus?: AssetConnectionStatus;
    trailerManufacturer?: Classification;
    trailerType?: TrailerType;
}

export interface TrailerConnectionFilteringProps {
    filter?: TrailerConnectionFilter;
    onFilterStateChange: (filter: TrailerConnectionFilter) => void;
    trailerManufacturers: Classification[];
}

export interface TrailerConnectionFilteringInnerProps
    extends TrailerConnectionFilteringProps,
        InjectedTranslationProps,
        WithStyles<TrailerConnectionFilteringClassKey> {}

export const ALL_OPTION = 'all';

export const TrailerConnectionFilteringComponent: React.FC<TrailerConnectionFilteringInnerProps> = ({
    classes,
    filter,
    onFilterStateChange,
    t,
    trailerManufacturers,
}) => {
    const trailerManufacturerElements = trailerManufacturers.map((manufacturer: Classification) => (
        <FormControlLabel
            control={<Radio />}
            data-id={`trailer-manufacturer-option:${manufacturer.id}`}
            key={`manufacturer:${manufacturer.id}`}
            label={formatClassification(t, ClassificationType.TrailerManufacturer, manufacturer)}
            value={manufacturer.id}
        />
    ));

    const handleTrailerTypeChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTrailerType =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as TrailerType);

            onFilterStateChange({
                connectionStatus: filter?.connectionStatus,
                trailerManufacturer: filter?.trailerManufacturer,
                trailerType: selectedTrailerType,
            });
        },
        [onFilterStateChange, filter]
    );

    const handleTrailerManufacturerChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTrailerManufacturer = trailerManufacturers.find(
                (manufacturer: Classification) => manufacturer.id === +event.target.value
            );

            onFilterStateChange({
                connectionStatus: filter?.connectionStatus,
                trailerManufacturer: selectedTrailerManufacturer,
                trailerType: filter?.trailerType,
            });
        },
        [onFilterStateChange, filter, trailerManufacturers]
    );

    const handleConnectionStatusChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedConnectionStatus =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as AssetConnectionStatus);

            onFilterStateChange({
                connectionStatus: selectedConnectionStatus,
                trailerManufacturer: filter?.trailerManufacturer,
                trailerType: filter?.trailerType,
            });
        },
        [onFilterStateChange, filter]
    );

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl} component="fieldset" variant="standard">
                <FormLabel className={classes.label} component="legend" data-id="trailer-type-radio-group-title">
                    {t('trailer-type').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    data-id="trailer-type-radio-group"
                    name="trailer-type"
                    onChange={handleTrailerTypeChange}
                    value={filter?.trailerType || ALL_OPTION}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`trailer-type-option:${ALL_OPTION}`}
                        key={ALL_OPTION}
                        label={t(`${ALL_OPTION}`)}
                        value={ALL_OPTION}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`trailer-type-option:${TrailerType.TRAILER}`}
                        key={TrailerType.TRAILER}
                        label={t(`${TrailerType.TRAILER}`)}
                        value={TrailerType.TRAILER}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`trailer-type-option:${TrailerType.REEFER}`}
                        key={TrailerType.REEFER}
                        label={t(`${TrailerType.REEFER}`)}
                        value={TrailerType.REEFER}
                    />
                </RadioGroup>
            </FormControl>

            <FormControl className={classes.formControl} component="fieldset" variant="standard">
                <FormLabel
                    className={classes.label}
                    component="legend"
                    data-id="trailer-manufacturer-radio-group-title"
                >
                    {t('trailer-manufacturer').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    data-id="trailer-manufacturer-radio-group"
                    name="trailer-manufacturer"
                    onChange={handleTrailerManufacturerChange}
                    value={isUndefined(filter?.trailerManufacturer?.id) ? -1 : filter?.trailerManufacturer?.id}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`trailer-manufacturer-option:${ALL_OPTION}`}
                        key={ALL_OPTION}
                        label={t(`${ALL_OPTION}`)}
                        value={-1}
                    />
                    {trailerManufacturerElements}
                </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" variant="standard">
                <FormLabel className={classes.label} component="legend" data-id="connection-status-radio-group-title">
                    {t('connection-status').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    data-id="connection-status-radio-group"
                    name="connection-status"
                    onChange={handleConnectionStatusChange}
                    value={filter?.connectionStatus || ALL_OPTION}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${ALL_OPTION}`}
                        key={ALL_OPTION}
                        label={t(`${ALL_OPTION}`)}
                        value={ALL_OPTION}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${AssetConnectionStatus.CONNECTED}`}
                        key={AssetConnectionStatus.CONNECTED}
                        label={t(`${AssetConnectionStatus.CONNECTED}`)}
                        value={AssetConnectionStatus.CONNECTED}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${AssetConnectionStatus.NOT_CONNECTED}`}
                        key={AssetConnectionStatus.NOT_CONNECTED}
                        label={t(`${AssetConnectionStatus.NOT_CONNECTED}`)}
                        value={AssetConnectionStatus.NOT_CONNECTED}
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
};
