import { subtract } from 'duration-fns';

import { LIMIT } from './consts';
import { ViolationType } from './enums';
import type { IsViolationProps } from './models';

const isViolation = (props: IsViolationProps): boolean => {
    const { threshold, value, violationType } = props;

    const difference = subtract(value, threshold).milliseconds;

    switch (violationType) {
        case ViolationType.ABOVE:
            return difference < LIMIT;
        case ViolationType.BELOW:
            return difference > LIMIT;
        default:
            return false;
    }
};

export { isViolation };
