import type { Dispatch } from 'redux';

import type { HistoryStoreState } from '~/data/monitoring';
import {
    flyToPositionGroupAction,
    panToPositionAction,
    panToPositionGroupAction,
} from '~/scenes/Monitoring/components/Map/components/History/data';
import type { Position, PositionGroup } from '~/services/ApiClient';

import {
    highlightPositionAction,
    highlightPositionGroupAction,
    resetScrollToPositionGroupAction,
} from '../../../../data';

export interface PositionGroupsContainerStateProps {
    highlightPositionGroupId?: number;
    scrollToPositionGroup: boolean;
}

export interface PositionGroupsContainerDispatchProps {
    flyToPositionGroup: () => void;
    highlightPosition: (position?: Position) => void;
    highlightPositionGroup: (positionGroup?: PositionGroup) => void;
    panToPosition: () => void;
    panToPositionGroup: () => void;
    resetScrollToPositionGroup: () => void;
}

export interface PositionGroupsContainerReduxProps
    extends PositionGroupsContainerStateProps,
        PositionGroupsContainerDispatchProps {}

export const mapStateToProps = (state: HistoryStoreState): PositionGroupsContainerStateProps => ({
    highlightPositionGroupId: state.highlightPositionGroupId,
    scrollToPositionGroup: state.scrollToPositionGroup,
});

export const mapDispatchProps = (dispatch: Dispatch): PositionGroupsContainerDispatchProps => ({
    flyToPositionGroup: () => {
        dispatch(flyToPositionGroupAction());
    },
    highlightPosition: (position?: Position) => {
        dispatch(highlightPositionAction(position));
    },
    highlightPositionGroup: (positionGroup?: PositionGroup) => {
        dispatch(highlightPositionGroupAction(positionGroup?.id));
    },
    panToPosition: () => {
        dispatch(panToPositionAction());
    },
    panToPositionGroup: () => {
        dispatch(panToPositionGroupAction());
    },
    resetScrollToPositionGroup: () => {
        dispatch(resetScrollToPositionGroupAction());
    },
});
