import * as React from 'react';

import { EBS } from '../../../../../EBS';

import type { EbsSectionContentInnerProps } from './model';

export const EbsSectionContentComponent: React.FC<EbsSectionContentInnerProps> = ({ displayPreferences, ebs }) => {
    const { axleLoadSum: axleWeight, dateTime, milage: distance, trailerWeight } = ebs;

    return (
        <EBS
            axleWeight={axleWeight}
            mileage={distance}
            timeStamp={dateTime}
            trailerWeight={trailerWeight}
            unitSystem={displayPreferences.unitSystem}
        />
    );
};
