import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ActivitySurfaceClassKey =
    | 'active'
    | 'activityAvailable'
    | 'activityDrive'
    | 'activityRest'
    | 'activityWork'
    | 'root';

export const ActivitySurfaceStyleRules: StyleRulesCallback<Theme, {}, ActivitySurfaceClassKey> = (theme: Theme) =>
    createStyles({
        active: {
            '&::after': {
                background:
                    'repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(255, 255, 255, 0.5) 2px, rgba(255, 255, 255, 0.5) 8px)',
                bottom: 0,
                content: '""',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
            },
            position: 'relative',
        },
        activityAvailable: {
            backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
            height: '50%',
            zIndex: 3,
        },
        activityDrive: {
            backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
            height: '100%',
            zIndex: 1,
        },
        activityRest: {
            backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
            height: '25%',
            zIndex: 4,
        },
        activityWork: {
            backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
            height: '75%',
            zIndex: 2,
        },
        root: {
            display: 'inline-block',
        },
    });
