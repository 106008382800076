import { colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type TotalCounterFooterClassKey = 'totalCounterFooter';

export const useStyles = makeStyles({
    totalCounterFooter: {
        bottom: 0,
        color: colors.common.black,
        fontSize: 13,
        fontWeight: 700,
        left: 0,
        maxWidth: 200,
        minWidth: 200,
        padding: 16,
        position: 'sticky',
    },
});
