import type { UnitSystem } from '@fv/converters';
import { autoConvertDistance, convertDistance, getDistanceUnit } from '@fv/converters';

import { isUndefined } from '../../utility';

interface FormatDistanceArgs {
    autoFormat?: boolean;
    precision?: number;
    unitSystem: UnitSystem;
    value?: number;
}

const formatDistance = (args: FormatDistanceArgs) => {
    const { autoFormat, precision, unitSystem, value } = args;
    if (isUndefined(value)) {
        return undefined;
    }

    if (autoFormat) {
        const { unit, value: converted } = autoConvertDistance({ unitSystem, value });
        return `${converted} ${unit}`;
    }

    const converted = convertDistance({ precision, unitSystem, value });
    const unit = getDistanceUnit(unitSystem);

    return `${converted} ${unit}`;
};

export { formatDistance };
export type { FormatDistanceArgs };
