import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { AddressFormatter, CoordinatesFormatter, DateTimeFormatter, TimeFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { PositionStatus } from '~/services/ApiClient';
import { AddressFormat } from '~/services/Formatters';

import type { PositionStatusContentClassKey } from './styles';

export interface PositionStatusContentProps {
    positionStatus: PositionStatus;
}

export interface PositionStatusContentInnerProps
    extends PositionStatusContentProps,
        InjectedTranslationProps,
        WithStyles<PositionStatusContentClassKey> {}

export const PositionStatusContentComponent: React.FC<PositionStatusContentInnerProps> = ({
    classes,
    positionStatus,
    t,
}) => {
    const getAddressLine = () => {
        if (isUndefined(positionStatus.address)) {
            return (
                <CoordinatesFormatter
                    t={t}
                    undefinedValueMessage={t('not-available')}
                    value={positionStatus.position}
                />
            );
        }

        return (
            <span data-id="address-line">
                <AddressFormatter options={{ format: AddressFormat.FirstLine }} value={positionStatus.address} />
            </span>
        );
    };
    const timeWithTooltip = (
        <Tooltip title={<DateTimeFormatter value={positionStatus.dateTime} />}>
            <span data-id="current-date">
                <TimeFormatter value={positionStatus.dateTime} />
            </span>
        </Tooltip>
    );
    const addressSecondLine = (
        <span data-id="address-secondary-line">
            <AddressFormatter options={{ format: AddressFormat.SecondLine }} value={positionStatus.address} />
        </span>
    );

    const positionStatusContent = (
        <span data-id="position-status-content">
            <Typography className={classes.splitRow} variant="body2">
                {getAddressLine()}
                <Typography variant="caption">{timeWithTooltip}</Typography>
            </Typography>
            <Typography className={classes.splitRow} color="textSecondary" variant="caption">
                {addressSecondLine}
            </Typography>
        </span>
    );

    return positionStatusContent;
};
