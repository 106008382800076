import type { CSSProperties, CreateCSSProperties } from '@mui/styles';

import type { FlattenStyleRulesArgs } from './models';

const flattenCssProps = <Props extends object>(
    cssPropsOrFactory: CreateCSSProperties<Props> | CSSProperties,
    props: Props
): React.CSSProperties => {
    const values = Object.values(cssPropsOrFactory);
    if (values.find((v) => v instanceof Function)) {
        const result = {};
        Object.keys(cssPropsOrFactory).forEach((key) => {
            const innerCssPropsOrFactory: CreateCSSProperties<Props> | CSSProperties =
                cssPropsOrFactory[key] instanceof Function
                    ? (cssPropsOrFactory[key] as (props: Props) => CreateCSSProperties<Props> | CSSProperties)(props)
                    : (cssPropsOrFactory[key] as CSSProperties);

            result[key] = flattenCssProps(innerCssPropsOrFactory, props);
        });
        return result as React.CSSProperties;
    }

    return cssPropsOrFactory as React.CSSProperties;
};

export const flattenStyleRules = <Theme, Props extends object, ClassKey extends string = string>({
    props,
    styleRulesCallback,
    theme,
}: FlattenStyleRulesArgs<Theme, Props, ClassKey>): Record<ClassKey, React.CSSProperties> => {
    const styleRules = styleRulesCallback(theme);

    const result = {};
    Object.keys(styleRules).forEach((key) => {
        const cssPropsOrFactory: CreateCSSProperties<Props> | CSSProperties =
            styleRules[key] instanceof Function ? styleRules[key](props) : styleRules[key];

        result[key] = flattenCssProps(cssPropsOrFactory, props);
    });

    return result as Record<ClassKey, React.CSSProperties>;
};
