import { Switch, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { SettingItemHeaderProps } from '../SettingItemHeader';
import { SettingItemHeader } from '../SettingItemHeader';

import type { ToggleSettingItemClassKeys } from './styles';

export interface ToggleSettingItemProps extends Omit<SettingItemHeaderProps, 'action'> {
    checked: boolean;
    disabled?: boolean;
    disableGutters?: boolean;
    onChange: (_: unknown, checked: boolean) => void;
    switchDataId: string;
    switchTooltipWhenDisabled?: string;
}

export interface ToggleSettingItemInnerProps
    extends Omit<ToggleSettingItemProps, 'classes'>,
        WithStyles<ToggleSettingItemClassKeys> {}

export const ToggleSettingItemComponent: React.FC<ToggleSettingItemInnerProps> = ({
    checked,
    classes,
    disabled,
    onChange,
    switchDataId,
    switchTooltipWhenDisabled,
    ...restProps
}) => {
    const action = (
        <Switch
            checked={checked}
            className={classes.action}
            data-id={switchDataId}
            disabled={disabled}
            onChange={onChange}
        />
    );

    const disabledActionWithTitle = disabled && switchTooltipWhenDisabled && (
        <Tooltip title={switchTooltipWhenDisabled}>
            <span>{action}</span>
        </Tooltip>
    );

    return <SettingItemHeader {...restProps} action={disabledActionWithTitle || action} />;
};
