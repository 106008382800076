import { useDebounce } from '@fv/hooks';
import InputAdornment from '@mui/material/InputAdornment';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';

import { ESCAPE_KEY } from './consts';
import type { SearchHeaderProps } from './models';
import { ClearIcon, Container, Input, SearchIcon } from './styles';

const SearchHeader: FC<SearchHeaderProps> = (props) => {
    const { changeSearchQuery, debouncePeriod = 500, toggleSearch } = props;

    const debouncedChangeSearchQuery = useDebounce({ delay: debouncePeriod, func: changeSearchQuery });

    const handleKeyPress = (event: ChangeEvent<HTMLTextAreaElement> & KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ESCAPE_KEY) {
            toggleSearch(false);
        } else {
            debouncedChangeSearchQuery(event.target.value);
        }
    };

    return (
        <Container data-testid="search-open">
            <Input
                aria-label="Search"
                autoFocus
                disableUnderline
                endAdornment={
                    <InputAdornment position="end">
                        <ClearIcon aria-label="close search" onClick={() => toggleSearch(false)} />
                    </InputAdornment>
                }
                fullWidth
                onKeyUp={handleKeyPress}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon aria-label="search icon" />
                    </InputAdornment>
                }
            />
        </Container>
    );
};

SearchHeader.displayName = 'SearchHeader';
export { SearchHeader };
