import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { SettingItemHeaderOuterProps } from './component';

export type SettingItemHeaderClassKeys = 'action' | 'body' | 'description' | 'disabled' | 'icon' | 'root' | 'title';

export const SettingItemHeaderStyleRules: StyleRulesCallback<
    Theme,
    SettingItemHeaderOuterProps,
    SettingItemHeaderClassKeys
> = (theme) =>
    createStyles({
        action: {
            color: theme.palette.text.secondary,
            display: 'flex',
        },
        body: {
            flexGrow: 1,
            marginRight: theme.spacing(2),
        },
        description: {},
        disabled: {
            opacity: theme.palette.action.disabledOpacity,
        },
        icon: {
            color: theme.palette.text.secondary,
            height: 24,
            marginRight: theme.spacing(2),
            width: 24,
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            padding: ({ disableGutters }) => theme.spacing(2, disableGutters ? 0 : 2),
        },
        title: {},
    });
