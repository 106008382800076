import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withSceneAnalytics } from '~/components/Analytics';
import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { TrailerDetailsPaneInnerProps, TrailerDetailsPaneProps } from './component';
import { TrailerDetailsPaneComponent } from './component';
import type { HistoryTrailerDetailsPaneUserPreferences } from './preferences';
import {
    HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY,
    defaultHistoryTrailerDetailsPaneUserPreferences,
    requiredHistoryTrailerDetailsPaneUserPreferences,
} from './preferences';
import { mapDispatchToProps } from './redux';

export {
    defaultHistoryTrailerDetailsPaneUserPreferences,
    HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY,
    requiredHistoryTrailerDetailsPaneUserPreferences,
};

export type DefaultDetailsPaneUserPreferences = HistoryTrailerDetailsPaneUserPreferences;

export const TrailerDetailsPane = compose<TrailerDetailsPaneInnerProps, TrailerDetailsPaneProps>(
    setDisplayName('TrailerDetailsPane'),
    withSceneAnalytics('historytrailerdetailspane'),
    withTranslation(),
    withDisplayPreferences(),
    connect(undefined, mapDispatchToProps)
)(TrailerDetailsPaneComponent);
