import { isEmpty, isUndefined } from '@fv/components/utility';
import Collapse from '@mui/material/Collapse';
import { useEffect, useState } from 'react';

import type { ItemContentProps } from './models';
import { ActionContainer, Container, ExpandLess, ExpandMore, Switch, Typography } from './styles';

const ItemContent = <T extends ItemContentProps<T>>(props: T) => {
    const {
        checkedKeys,
        children,
        expandedKeys,
        id,
        name,
        onCheck,
        onCollapse,
        onContextMenu,
        paddingLeft,
        readOnly = false,
        status,
    } = props;

    const hasChildren = !isEmpty(children);
    const [switchState, setSwitchState] = useState(status === 'on');
    const [disabledState, setDisabledState] = useState(status === 'disabled');

    useEffect(() => {
        setSwitchState(status === 'on');
        setDisabledState(status === 'disabled');
    }, [status]);

    if (!id) {
        return null;
    }

    return (
        <Container
            data-testid={`container_${id}`}
            isLeaf={!hasChildren}
            key={`item-content_${id}`}
            onContextMenu={(event) => onContextMenu?.({ children, id, name }, event)}
            paddingLeft={paddingLeft}
        >
            <ActionContainer>
                {hasChildren &&
                    (!!expandedKeys[id] && expandedKeys[id].value ? (
                        <ExpandLess
                            data-testid={`expand_less_${id}`}
                            fillOpacity={0.6}
                            onClick={(event) => onCollapse({ event, id, value: false })}
                        />
                    ) : (
                        <ExpandMore
                            data-testid={`expand_more_${id}`}
                            fillOpacity={0.6}
                            onClick={(event) => onCollapse({ event, id, value: true })}
                        />
                    ))}

                <Typography>{name}</Typography>
                {!readOnly && (
                    <Switch
                        checked={switchState}
                        disabled={disabledState}
                        onChange={(event, checked) =>
                            onCheck({ event, id, isLeaf: !hasChildren, source: children ?? [], value: checked })
                        }
                        value={id}
                    />
                )}
            </ActionContainer>

            {children?.map(({ id: innerId, ...rest }) => (
                <Collapse
                    data-testid={`item-content_collapsible_${innerId}`}
                    in={!!expandedKeys[id] && expandedKeys[id].value}
                    key={`item-content_collapsible_${innerId}`}
                >
                    <ItemContent
                        {...rest}
                        checkedKeys={checkedKeys}
                        expandedKeys={expandedKeys}
                        id={innerId}
                        onCheck={onCheck}
                        onCollapse={onCollapse}
                        onContextMenu={onContextMenu}
                        paddingLeft={isUndefined(rest.paddingLeft) ? 16 : 24}
                        readOnly={readOnly}
                    />
                </Collapse>
            ))}
        </Container>
    );
};

ItemContent.displayName = 'ItemContent';
export { ItemContent };
