import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import { useState } from 'react';

import { MultiSelectList } from '../MultiSelectList';

import type { TransferListProps } from './models';
import { useStyles } from './styles';
import { classifyOptions } from './utils';

const TransferList = <TId,>(props: TransferListProps<TId>): ReturnType<FC<TransferListProps<TId>>> => {
    const { availableTitle, equalityComparer, onChange, options, selectedTitle, testId, value } = props;

    const classes = useStyles();
    const [availableChecked, setAvailableChecked] = useState<TId[]>([]);
    const [selectedChecked, setSelectedChecked] = useState<TId[]>([]);
    const classifiedOptions = classifyOptions({ equalityComparer, options, value });

    const handleAvailableChecked = () => {
        onChange([...value, ...availableChecked]);
        setAvailableChecked([]);
    };
    const handleSelectedChecked = () => {
        onChange(value.filter((v) => !selectedChecked.find((c) => equalityComparer(c, v))));
        setSelectedChecked([]);
    };

    return (
        <Box
            className={classes.root}
            data-testid={`transfer-list:${testId}`}
            display="grid"
            flexDirection="row"
            height="100%"
        >
            <Box>
                <MultiSelectList
                    equalityComparer={equalityComparer}
                    onChange={setAvailableChecked}
                    options={classifiedOptions.available}
                    testId="available"
                    title={availableTitle}
                    value={availableChecked}
                />
            </Box>

            <Box
                className={classes.buttonContainer}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding={2}
            >
                <Button
                    data-testid="move-available"
                    disabled={availableChecked.length === 0}
                    onClick={handleAvailableChecked}
                    size="small"
                    variant="outlined"
                >
                    &gt;
                </Button>
                <Button
                    data-testid="move-selected"
                    disabled={selectedChecked.length === 0}
                    onClick={handleSelectedChecked}
                    size="small"
                    variant="outlined"
                >
                    &lt;
                </Button>
            </Box>

            <Box>
                <MultiSelectList
                    equalityComparer={equalityComparer}
                    onChange={setSelectedChecked}
                    options={classifiedOptions.selected}
                    testId="selected"
                    title={selectedTitle}
                    value={selectedChecked}
                />
            </Box>
        </Box>
    );
};

TransferList.displayName = 'TransferList';
export { TransferList };
