import type { Dispatch } from 'redux';

import { retrieveDriverEventFieldValuesAction } from '~/data/driverevents';
import { reportError } from '~/reporting';
import type { DynamicHistoryStoreState } from '~/scenes/History/reducers';

import type { DispatchProps, StateProps } from './models';
import { DriverEventSource } from './models';

export interface OwnProps {
    driverEventId: number;
    driverEventSource?: DriverEventSource;
    driverEventTypeId?: number;
}

export const mapStateToProps = (dynamicHistoryStoreState: DynamicHistoryStoreState): StateProps => {
    return {
        failedToLoadFields: dynamicHistoryStoreState.resolvedDriverEventFieldValues.rejected,
        fields: dynamicHistoryStoreState.resolvedDriverEventFieldValues.data,
        loadingFields: dynamicHistoryStoreState.resolvedDriverEventFieldValues.pending,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => {
    const { driverEventId, driverEventSource, driverEventTypeId } = ownProps;

    return {
        loadEventFields: () => {
            dispatch(
                retrieveDriverEventFieldValuesAction(
                    driverEventId,
                    driverEventSource === DriverEventSource.DriverEvent ? driverEventTypeId : undefined,
                    driverEventSource === DriverEventSource.DriverSubActivity ? driverEventTypeId : undefined
                )
            ).catch(reportError);
        },
    };
};
