import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import { getI18n } from '~/i18n';
import type { IntroCarousel } from '~/services/IntroCarousel';

import { closeIntroCarouselAction, retrieveLatestIntroCarouselAction } from './data';
import type {
    IntroCarouselDispatchProps,
    IntroCarouselReduxOwnProps,
    IntroCarouselReduxProps,
    IntroCarouselStateProps,
} from './models';
import type { IntroCarouselUserPreferences } from './preferences';
import { INTROCAROUSEL_USERPREFERENCES_KEY } from './preferences';
import type { IntroCarouselStoreState } from './reducers';

export const mapStateToProps = (
    introCarousel: IntroCarouselStoreState,
    preferences: IntroCarouselUserPreferences
): IntroCarouselStateProps => ({
    carousel: introCarousel.latestIntroCarousel,
    manuallyOpened: introCarousel.manuallyOpened,
    openUnseenCarousel: introCarousel.openUnseenCarousel,
    seenLatestCarousel: introCarousel.seenLatestCarousel,
    seenRevision: preferences.seenRevision,
});

export const mapDispatchToProps = (dispatch: Dispatch): IntroCarouselDispatchProps => ({
    closeIntroCarousel: () => dispatch(closeIntroCarouselAction()),
    markAsSeen: (carousel: IntroCarousel) => {
        dispatch(updateUserPreferencesAction(INTROCAROUSEL_USERPREFERENCES_KEY, { seenRevision: carousel.revision }));
    },
    retrieveLatestIntroCarousel: () => {
        const { language } = getI18n();
        dispatch(retrieveLatestIntroCarouselAction(language));
    },
});

export const mergeProps = (
    stateProps: IntroCarouselStateProps,
    dispatchProps: IntroCarouselDispatchProps,
    ownProps: IntroCarouselReduxOwnProps
): IntroCarouselReduxProps => ({
    ...ownProps,
    carousel: stateProps.carousel,
    close: () => {
        if (
            !ownProps.userIsImpersonated &&
            stateProps.carousel &&
            stateProps.carousel.revision > (stateProps.seenRevision || Number.MIN_VALUE)
        ) {
            dispatchProps.markAsSeen(stateProps.carousel);
        }

        dispatchProps.closeIntroCarousel();
    },
    open:
        stateProps.manuallyOpened ||
        (stateProps.openUnseenCarousel &&
            !stateProps.seenLatestCarousel &&
            (stateProps.carousel && stateProps.carousel.revision) !== stateProps.seenRevision),
    retrieveLatestIntroCarousel: () => {
        dispatchProps.retrieveLatestIntroCarousel();
    },
});
