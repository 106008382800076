import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { SendTextMessageFormProps } from './component';

export type SendTextMessageFormClassKey =
    | 'characterCount'
    | 'characterCountContainer'
    | 'circularProgress'
    | 'input'
    | 'root'
    | 'sendFab'
    | 'sendIcon'
    | 'sendMessageContainer';

export const useStyles = makeStyles<Theme, SendTextMessageFormProps, SendTextMessageFormClassKey>((theme) => ({
    characterCount: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(11),
    },
    characterCountContainer: {
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing(0.5),
    },
    circularProgress: {
        position: 'absolute',
    },
    input: {
        '&::placeholder': {
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
        },
        border: 'none',
        color: theme.palette.text.primary,
        flexGrow: 1,
        fontFamily: theme.typography.fontFamily,
        marginRight: theme.spacing(),
        minHeight: '100%',
        outline: 'none',
        paddingBottom: theme.spacing(),
        paddingLeft: (props) => (!props.disabledTyping ? undefined : theme.spacing(1)),
        // minHeight: 32,
        paddingTop: theme.spacing(),
        resize: 'none',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    },
    sendFab: {
        fontSize: theme.typography.pxToRem(14),
        height: 32,
        minHeight: 32,
        width: 32,
    },
    sendIcon: {
        fontSize: theme.typography.pxToRem(14),
    },
    sendMessageContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexGrow: 1,
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing(),
    },
}));
