import type { DevicesResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    DEVICES = 'DEVICES',
}

export enum ActionTypeKeys {
    DEVICES_FULFILLED = 'DEVICES_FULFILLED',
    DEVICES_PENDING = 'DEVICES_PENDING',
    DEVICES_REJECTED = 'DEVICES_REJECTED',
}

export type DevicesPayload = Readonly<DevicesResponse>;

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.DEVICES_FULFILLED, DevicesPayload>
    | Pending<ActionTypeKeys.DEVICES_PENDING>
    | Rejected<ActionTypeKeys.DEVICES_REJECTED>;
