import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationWidgetClassKey =
    | 'actionButton'
    | 'content'
    | 'listContainer'
    | 'none'
    | 'scrollToBottomFab'
    | 'scrollToBottomFabVisible';

export const widgetWidth = 336;
export const widgetHeight = 450;

export const ConversationWidgetStyleRules: StyleRulesCallback<Theme, {}, ConversationWidgetClassKey> = (theme: Theme) =>
    createStyles({
        actionButton: {
            fontSize: 14,
            height: 24,
            marginLeft: theme.spacing(0.5),
            padding: 0,
            width: 24,
        },
        content: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            position: 'relative',
        },
        listContainer: {
            flexGrow: 1,
            position: 'relative',
        },
        none: {
            display: 'none',
        },
        scrollToBottomFab: {
            backgroundColor: theme.palette.background.paper,
            bottom: -50,
            color: theme.palette.text.secondary,
            height: 32,
            minHeight: 32,
            position: 'absolute',
            right: theme.spacing(2.5),
            transition: '250ms',
            width: 32,
        },
        scrollToBottomFabVisible: {
            bottom: theme.spacing(1.5),
        },
    });
