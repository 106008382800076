import type { StaticDataStoreState } from '~/common';
import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as VehicleAdministrationsActionTypes } from '~/data/vehicleadministration';
import { ActionTypeKeys as VehicleAdministrationsActionTypeKeys } from '~/data/vehicleadministration';
import type { RetrievableData } from '~/reducers';
import type { ResolvedVehicleChangelogChanges, VehicleChangelog } from '~/services/ApiClient';
import type { BaseAction } from '~/types';

import { resolveVehicleChangelogsEntries } from './reducers.resolveVehicleChangelogsEntries';

export interface VehicleAdministrationChangelogsStoreState {
    pendingChangelogId?: number;
    previousChangelogId?: number;
    vehicleChangelogs: RetrievableData<VehicleChangelog[]>;
    vehicleChangelogsEntries: ChangelogEntry<ResolvedVehicleChangelogChanges>[];
}

const defaultVehicleChangelogsState: VehicleAdministrationChangelogsStoreState = {
    pendingChangelogId: undefined,
    vehicleChangelogs: {
        data: [],
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    vehicleChangelogsEntries: [],
};

export const vehicleChangelogsReducer = (
    state: VehicleAdministrationChangelogsStoreState = defaultVehicleChangelogsState,
    action: BaseAction<''> | VehicleAdministrationsActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): VehicleAdministrationChangelogsStoreState => {
    switch (action.type) {
        case VehicleAdministrationsActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_PENDING:
            return {
                ...state,
                pendingChangelogId: action.meta,
                previousChangelogId: state.pendingChangelogId ?? action.meta,
                vehicleChangelogs: {
                    ...state.vehicleChangelogs,
                    pending: true,
                },
            };

        case VehicleAdministrationsActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_REJECTED: {
            if (state.pendingChangelogId !== action.meta) {
                return state;
            }

            return {
                ...defaultVehicleChangelogsState,
                vehicleChangelogs: {
                    ...defaultVehicleChangelogsState.vehicleChangelogs,
                    rejected: true,
                },
            };
        }
        case VehicleAdministrationsActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_FULFILLED: {
            if (state.pendingChangelogId !== action.meta) {
                return state;
            }
            return {
                ...state,
                pendingChangelogId: undefined,
                previousChangelogId: action.meta,
                vehicleChangelogs: {
                    data: action.payload.items,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
                vehicleChangelogsEntries: resolveVehicleChangelogsEntries(
                    action.payload.items,
                    staticDataStoreState,
                    settingsStoreState
                ),
            };
        }

        default:
            return state;
    }
};
