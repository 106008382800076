import type { ClassNameMap, Theme } from '@mui/material';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { classes?: ClassNameMap<string> }>((theme) => ({
    closeIcon: {
        '&:hover': {
            color: alpha(theme.palette.primary.contrastText, 1),
            cursor: 'pointer',
        },
        color: alpha(theme.palette.primary.contrastText, 0.87),
        margin: theme.spacing(0.5),
    },
    input: {
        '&:focus-within $searchIcon': {
            color: alpha(theme.palette.primary.contrastText, 1),
        },
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        height: theme.spacing(4),
        opacity: 0.87,
    },
    inputPlaceholder: {
        fontStyle: 'italic',
    },
    root: {
        '&::before': {
            backgroundColor: alpha(theme.palette.common.black, 0.26),
            borderRadius: theme.shape.borderRadius,
            content: "''",
            height: '100%',
            margin: theme.spacing(-0.5),
            pointerEvents: 'none',
            position: 'absolute',
            width: '100%',
        },
        backgroundColor: 'transparent',
        cursor: 'text',
        height: 40,
        padding: theme.spacing(0.5),
        position: 'relative',
    },
    searchIcon: {
        color: alpha(theme.palette.primary.contrastText, 0.54),
        margin: theme.spacing(0.5),
    },
    typography: {
        display: 'flex',
    },
}));
