import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useBulkCheckbox } from '../../hooks';

import type { GroupProps } from './models';
import { useStyles } from './styles';

const Group = <TId,>(props: GroupProps<TId>) => {
    const { collapsed, equalityComparer, group, selected, testId, toggleCollapsed, toggleSelected } = props;

    const classes = useStyles();

    const visibleGroupValues = group.options.map((o) => o.id);

    const { bulkChecked, bulkIndeterminate, toggleBulk } = useBulkCheckbox({
        equalityComparer,
        items: visibleGroupValues,
        selected,
        toggleSelected,
    });

    return (
        <>
            <ListItemIcon className={classes.iconContainer} data-testid={`group:${testId}:collapse`}>
                <IconButton className={classes.icon} disableRipple onClick={toggleCollapsed}>
                    {collapsed ? <ChevronRight /> : <ExpandMore />}
                </IconButton>
            </ListItemIcon>

            <ListItemIcon className={classes.iconContainer} data-testid={`group:${testId}:checkbox`}>
                <Checkbox
                    checked={bulkChecked}
                    className={classes.checkbox}
                    disableRipple
                    indeterminate={bulkIndeterminate}
                    onClick={toggleBulk}
                />
            </ListItemIcon>

            <ListItemText
                data-testid={`group:${testId}:title`}
                primary={`${group.groupName} (${group.selectedItemCount} / ${group.totalItemCount})`}
            />
        </>
    );
};

Group.displayName = 'Group';
export { Group };
