import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';
import { AddressFormat } from '~/services/Formatters';

import { AddressFormatter, CoordinatesFormatter } from '../Formatters';

import type { AddressCardProps } from './models';
import { useStyles } from './styles';

const AddressCard: FC<AddressCardProps> = (props) => {
    const { address, children, icon, noDataMessage, position } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <ListItem className={classes.addressLines}>
            <Avatar className={classes.avatar}>{icon}</Avatar>

            {position ? (
                <>
                    <ListItemText
                        data-testid="address-card"
                        primary={
                            <span data-testid="address-first-line">
                                {isUndefined(address) ? (
                                    <CoordinatesFormatter
                                        data-testid="address-card:first-line"
                                        t={t}
                                        value={position}
                                    />
                                ) : (
                                    <AddressFormatter
                                        data-testid="address-card:first-line"
                                        options={{ format: AddressFormat.FirstLine }}
                                        value={address}
                                    />
                                )}
                            </span>
                        }
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondary={
                            <span data-testid="address-second-line">
                                <AddressFormatter
                                    data-testid="address-card:second-line"
                                    options={{ format: AddressFormat.SecondLine }}
                                    undefinedValueMessage={t('not-available')}
                                    value={address}
                                />
                            </span>
                        }
                        secondaryTypographyProps={{ variant: 'body2' }}
                    />
                    {children}
                </>
            ) : (
                <ListItemText
                    className={classes.undefinedMessage}
                    data-testid="no-data-message"
                    primary={noDataMessage || t('not-available')}
                    primaryTypographyProps={{ variant: 'body2' }}
                />
            )}
        </ListItem>
    );
};

AddressCard.displayName = 'AddressCard';
export { AddressCard };
