import type { StyledComponentProps, WithStyles } from '@mui/styles';

import type { MiniSettingsPanelClassKeys } from './styles';

export interface SettingItem<T extends SettingItemType> {
    key: string;
    settingType: T;
    title: string;
}

export enum SettingItemType {
    Boolean = 'boolean',
    DateTime = 'datetime',
    NumericMagnitude = 'numeric-magnitude',
}

export enum BooleanFieldType {
    Checkbox = 'checkbox',
    ToggleSwitch = 'toggle-switch',
}

export interface BooleanSettingItem extends SettingItem<SettingItemType.Boolean> {
    key: string;
    onValueChange: () => void;
    title: string;
    type: BooleanFieldType;
    value: boolean;
}

export interface NumericMagnitudeSettingItem extends SettingItem<SettingItemType.NumericMagnitude> {
    errorText?: string;
    isRequired?: boolean;
    maxValue?: number;
    minValue?: number;
    onValueChange: (value?: number) => void;
    unit: string;
    value?: number;
}

export interface DateTimeSettingItem extends SettingItem<SettingItemType.DateTime> {
    disableFutureDatesInPicker?: boolean;
    disablePastDatesInPicker?: boolean;
    errorText?: string;
    isRequired?: boolean;
    maxDate?: Date;
    minDate?: Date;
    onValueChange: (value?: Date) => void;
    value?: Date;
}

export type CustomSettingItemTypes = BooleanSettingItem | DateTimeSettingItem | NumericMagnitudeSettingItem;

export interface MiniSettingsPanelProps {
    collapsable?: boolean;
    settings: CustomSettingItemTypes[];
}

export interface MiniSettingsPanelOuterProps
    extends MiniSettingsPanelProps,
        StyledComponentProps<MiniSettingsPanelClassKeys> {}

export interface MiniSettingsPanelInnerProps extends MiniSettingsPanelProps, WithStyles<MiniSettingsPanelClassKeys> {}
