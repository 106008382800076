import type { UsersResponse } from '~/services/ApiClient';
import type { BaseAction } from '~/types';

export enum ActionTypeKeys {
    OVERLAYMENU_CLOSE = 'OVERLAYMENU_CLOSE',
    OVERLAYMENU_OPEN = 'OVERLAYMENU_OPEN',
}

export type UsersPayload = Readonly<UsersResponse>;

export type ActionTypes = BaseAction<ActionTypeKeys.OVERLAYMENU_CLOSE> | BaseAction<ActionTypeKeys.OVERLAYMENU_OPEN>;
