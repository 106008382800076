import { Button } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_RELOAD_MESSAGE } from './consts';
import type { AskForPageReloadProps } from './models';
import { ButtonsContainer, Container, RefreshIcon } from './styles';

const AskForPageReload: FC<AskForPageReloadProps> = (props) => {
    const { message, reload } = props;

    const { t } = useTranslation();
    const reloadMessage = message ?? DEFAULT_RELOAD_MESSAGE;
    const reloadFunction = reload ?? window.location.reload;

    return (
        <Container data-testid="ask-for-page-reload">
            <div data-testid="message">{t(reloadMessage)}</div>

            <ButtonsContainer>
                <Button color="primary" data-testid="reload" onClick={reloadFunction} variant="contained">
                    <RefreshIcon />
                    {t('reload')}
                </Button>
            </ButtonsContainer>
        </Container>
    );
};

AskForPageReload.displayName = 'AskForPageReload';
export { AskForPageReload };
