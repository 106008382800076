import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { themeSpacingToNumber } from '~/common/utils';

import { graphWidth } from '../../constants';

export type TicksClassKey = 'midnight' | 'noon' | 'number' | 'root' | 'tick' | 'tickLabel';

export const TicksStyleRules: StyleRulesCallback<Theme, {}, TicksClassKey> = (theme: Theme) =>
    createStyles({
        midnight: {
            color: theme.palette.text.primary,
        },
        noon: {
            color: theme.palette.text.primary,
        },
        number: {
            color: theme.palette.text.secondary,
        },
        root: {
            display: 'flex',
            height: theme.spacing(1.5),
            pointerEvents: 'none',
            position: 'relative',
            width: graphWidth,
        },
        tick: {
            background: theme.palette.action.selectedBackground,
            color: theme.palette.text.disabled,
            height: theme.spacing(0.5),
            position: 'absolute',
            width: 1,
        },
        tickLabel: {
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '4px',
            position: 'absolute',
            top: themeSpacingToNumber(theme.spacing(0.5)) + 7,
            width: 1,
        },
    });
