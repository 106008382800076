const MODULE_PREFIX = 'NOTIFICATIONS';

const actions = {
    CLEAR: `[${MODULE_PREFIX}] CLEAR`,
    PENDING: `[${MODULE_PREFIX}] PENDING`,
    REJECTED: `[${MODULE_PREFIX}] REJECTED`,
    UPDATE: `[${MODULE_PREFIX}] UPDATE`,
} as const;

export { actions };
