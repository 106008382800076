import type { TachoLincStatus, TachoLincStatusResponse } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export interface MessageActionTypeMeta {
    messageId: number;
}

export const TACHOLINC_STATUS_ACTION_TYPE = {
    DELETE: 'DELETE_TACHOLINC_STATUS',
    GET: 'GET_TACHOLINC_STATUS',
    RESET: 'RESET_TACHOLINC_STATUS',
} as const;

export enum ActionTypeKeys {
    TACHOLINC_STATUS_FULFILLED = 'TACHOLINC_STATUS_FULFILLED',
    TACHOLINC_STATUS_PENDING = 'TACHOLINC_STATUS_PENDING',
    TACHOLINC_STATUS_REJECTED = 'TACHOLINC_STATUS_REJECTED',
}

export const ACTION_TYPE_KEYS = {
    LIST_FULFILLED: `${TACHOLINC_STATUS_ACTION_TYPE.GET}_FULFILLED`,
    LIST_PENDING: `${TACHOLINC_STATUS_ACTION_TYPE.GET}_PENDING`,
    LIST_REJECTED: `${TACHOLINC_STATUS_ACTION_TYPE.GET}_REJECTED`,
    RESET: TACHOLINC_STATUS_ACTION_TYPE.DELETE,
} as const;

export type ActionTypes =
    | FulfilledMeta<ActionTypeKeys.TACHOLINC_STATUS_FULFILLED, Readonly<TachoLincStatusResponse>, TachoLincStatus>
    | PendingMeta<ActionTypeKeys.TACHOLINC_STATUS_PENDING, TachoLincStatus>
    | RejectedMeta<ActionTypeKeys.TACHOLINC_STATUS_REJECTED, undefined, TachoLincStatus>;
