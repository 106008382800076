import { Marker } from '@fv/components';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { MonitoringContainerEntry } from '~/data/monitoring';

import { ContainerMarkerIcon, ContainerMarkerNameFormatter } from './components';
import type { ContainerMarkerInnerProps } from './models';

export const ContainerMarkerComponent: FC<ContainerMarkerInnerProps> = (props) => {
    const { classes, displayContainerName, entry, ...rest } = props;

    const NameFormatterComponent = useMemo(() => {
        if (!displayContainerName) {
            return undefined;
        }

        return ContainerMarkerNameFormatter;
    }, [displayContainerName]);
    const overriddenMarkerClasses = useMemo(() => {
        return {
            assetName: classes.assetName,
            highlight: classes.containerBorderColor,
            iconBox: classes.containerBackgroundColor,
            selected: classes.containerBorderColor,
        };
    }, [classes]);

    return (
        <Marker<MonitoringContainerEntry>
            {...rest}
            classes={overriddenMarkerClasses}
            dataId={`container-marker:${entry.container.id}`}
            entry={entry}
            Icon={ContainerMarkerIcon}
            NameFormatter={NameFormatterComponent}
        />
    );
};
