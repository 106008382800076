import * as React from 'react';

import type { DocumentViewerFile, DocumentViewerFileWithContent } from '../models';

export interface DocumentViewerFileCache {
    getMultiple: (files: DocumentViewerFile[]) => Promise<DocumentViewerFileWithContent[]>;
    getSingle: (file: DocumentViewerFile) => Promise<DocumentViewerFileWithContent | undefined>;
}

export interface FileCacheEntry {
    key: DocumentViewerFile;
    value: Promise<DocumentViewerFileWithContent | undefined>;
}

export const useFileCache = (
    downloadFiles: (files: DocumentViewerFile[]) => Promise<DocumentViewerFileWithContent[]>
): DocumentViewerFileCache => {
    const cacheRef = React.useRef<FileCacheEntry[]>([]);

    return React.useMemo(
        () => ({
            getMultiple: async (files) => {
                const missingFiles = files.filter((f) => !cacheRef.current.find((e) => e.key === f));
                if (missingFiles.length) {
                    const missingFilesPromise = downloadFiles(missingFiles);
                    missingFiles.forEach((missingFile) =>
                        cacheRef.current.push({
                            key: missingFile,
                            value: missingFilesPromise.then((r) => r.find((c) => c.file === missingFile)),
                        })
                    );
                }

                const requestedEntries = cacheRef.current.filter((e) => files.includes(e.key));
                const allValues = await Promise.all(requestedEntries.map((e) => e.value));
                return allValues.filter(Boolean) as DocumentViewerFileWithContent[];
            },
            getSingle: (file) => {
                let cachedContents = cacheRef.current.find((e) => e.key === file)?.value;
                if (!cachedContents) {
                    cachedContents = downloadFiles([file]).then((r) => r.find((c) => c.file === file));
                    cacheRef.current.push({ key: file, value: cachedContents });
                }
                return cachedContents;
            },
        }),
        [downloadFiles]
    );
};
