import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { InfoBoxStyleRules } from '../../styles';

import { TrailerInfoBox as _TrailerInfoBox } from './component';
import type { TrailerInfoBoxInnerProps, TrailerInfoBoxProps } from './models';

export const TrailerInfoBox = compose<TrailerInfoBoxInnerProps, TrailerInfoBoxProps>(
    setDisplayName('InfoBox'),
    withStyles(InfoBoxStyleRules)
)(_TrailerInfoBox);
