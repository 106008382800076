import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { FC, MouseEvent } from 'react';
import { useCallback, useState } from 'react';

import type { DropDownMenuProps } from './models';

const DropDownMenu: FC<DropDownMenuProps> = (props) => {
    const { chartRef, menuButton, menuOptions } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <div>
            <div data-id="menu-button" onClick={handleClick} ref={chartRef}>
                {menuButton}
            </div>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                id="drop-down-menu"
                keepMounted
                onClose={handleClose}
                open={open}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {menuOptions.map((option) => (
                    <MenuItem data-id={`menu-item-${option.id}`} key={option.id} onClick={handleClose}>
                        {option.element}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

DropDownMenu.displayName = 'DropDownMenu';
export default DropDownMenu;
