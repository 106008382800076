import { Typography } from '@mui/material';
import * as React from 'react';

import { DateFormatter } from '~/components/Formatters';

import { useStyles } from './styles';

export interface ChangelogDateEntityProps {
    groupedPosition: number;
    value: Date;
}

export interface ChangelogDateEntityInnerProps extends ChangelogDateEntityProps {}

const ChangelogDateEntity: React.FC<ChangelogDateEntityInnerProps> = ({ groupedPosition, value }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.date} data-id={`date:${groupedPosition}`} variant="subtitle2">
            <DateFormatter format="PPPP" value={value} />
        </Typography>
    );
};
ChangelogDateEntity.displayName = 'ChangelogDateEntity';

export { ChangelogDateEntity };
