import type { Duration } from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViolationFieldFormatterFactory } from '~/components/Formatters';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { DurationViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';
import { parseOptionalDurationViolationField } from '~/services/Parsers';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';
import { commonDurationFormatter } from '../utils';

const DriveSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const {
        availableBiWeeklyDriving,
        availableDailyDriving,
        availableWeeklyDriving,
        continuousDriving,
        dailyDriving,
        driveBreak,
        extendedDailyDrivingCount,
        weeklyDriving,
    } = props.driverStatus;
    const { t } = useTranslation();

    const ViolationNumberFormatter = useMemo(() => ViolationFieldFormatterFactory((v: number) => v.toString()), []);
    const ViolationDurationFormatter = useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    return (
        <ReorderableSection
            dataId={SectionName.DRIVE}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.DRIVE}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList
                list={[
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-continuous-driving'),
                        value: continuousDriving && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'drive-continuous-driving')}
                                t={t}
                                value={parseOptionalDurationViolationField(continuousDriving as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'continuous-driving',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-drive-break'),
                        value: commonDurationFormatter(driveBreak),
                        valueDataId: 'drive-break',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-daily-driving'),
                        value: dailyDriving && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'drive-daily-driving')}
                                t={t}
                                value={parseOptionalDurationViolationField(dailyDriving as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'daily-driving',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-weekly-driving'),
                        value: weeklyDriving && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'drive-weekly-driving')}
                                t={t}
                                value={parseOptionalDurationViolationField(weeklyDriving as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'weekly-driving',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-extended-driving-count'),
                        value: !isUndefined(extendedDailyDrivingCount) ? (
                            <ViolationNumberFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'drive-extended-driving-count')}
                                t={t}
                                value={extendedDailyDrivingCount}
                            />
                        ) : undefined,
                        valueDataId: 'extended-driving-count',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-available-daily-driving'),
                        value: commonDurationFormatter(availableDailyDriving),
                        valueDataId: 'available-daily-driving-time',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-available-weekly-driving'),
                        value: commonDurationFormatter(availableWeeklyDriving),
                        valueDataId: 'available-weekly-driving-time',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'drive-available-bi-weekly-driving'),
                        value: commonDurationFormatter(availableBiWeeklyDriving),
                        valueDataId: 'available-biweekly-driving-time',
                    },
                ]}
                listDataId="property-list"
            />
        </ReorderableSection>
    );
};

DriveSection.displayName = 'DriveSection';
export default DriveSection;
