import type { UsersResponse } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    CUSTOMERUSERS = 'CUSTOMERUSERS',
}

export enum ActionTypeKeys {
    CUSTOMERUSERS_FULFILLED = 'CUSTOMERUSERS_FULFILLED',
    CUSTOMERUSERS_PENDING = 'CUSTOMERUSERS_PENDING',
    CUSTOMERUSERS_REJECTED = 'CUSTOMERUSERS_REJECTED',
}

export type UsersPayload = Readonly<UsersResponse>;

export type ActionTypes =
    | FulfilledMeta<ActionTypeKeys.CUSTOMERUSERS_FULFILLED, UsersPayload, number>
    | PendingMeta<ActionTypeKeys.CUSTOMERUSERS_PENDING, number>
    | RejectedMeta<ActionTypeKeys.CUSTOMERUSERS_REJECTED, undefined, number>;
