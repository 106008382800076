import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TemperatureStatusIndicatorClassKey = 'okIcon' | 'root' | 'warningIcon';

export const TemperatureStatusIndicatorStyleRules: StyleRulesCallback<Theme, {}, TemperatureStatusIndicatorClassKey> = (
    theme: Theme
) =>
    createStyles({
        okIcon: {
            background: theme.palette.success.main,
        },
        root: {},
        warningIcon: {
            background: theme.palette.error.main,
        },
    });
