import { CircularProgress, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangelogEntityType, ChangelogSubpage } from '~/common';
import { SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import { useUserPreferences } from '~/components/UserPreferences';
import { getVehicleChangelogAttributeDefinitions } from '~/scenes/VehicleAdministration/getVehicleChangelogAttributeDefinitions';
import type { DisplayUserPreferences, ResolvedVehicle, ResolvedVehicleChangelogChanges } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import type { VehicleAdministrationChangelogsSubpageInnerProps } from './models';
import { useStyles } from './styles';

const ChangelogSubpageComponent = ChangelogSubpage<ResolvedVehicleChangelogChanges>();

const VehicleAdministrationChangelogsSubpageComponent: FC<VehicleAdministrationChangelogsSubpageInnerProps> = (
    props
) => {
    const { changelogsEntries, getVehicleChangelogs, loading, previousVehicleId, rejected, vehicle } = props;
    const { t } = useTranslation();
    const [displayPreferences] = useUserPreferences<DisplayUserPreferences>(PredefinedUserPreferencesKeys.DISPLAY);

    const vehicleName = formatVehicleName(
        vehicle.adminVehicle as ResolvedVehicle,
        displayPreferences.vehicleDisplayFormat
    );

    const classes = useStyles();

    useEffect(() => {
        getVehicleChangelogs(vehicle?.adminVehicle?.id);
    }, [getVehicleChangelogs, vehicle?.adminVehicle?.id]);

    const header = (
        <SceneDetailsSubpageHeader
            title={t('changelog-vehicleadmin-subpage-title', {
                vehicleName,
            })}
        />
    );

    if (rejected) {
        return (
            <>
                {header}
                <Typography className={classes.messageText} data-id="loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </>
        );
    }

    if (loading || previousVehicleId !== vehicle?.adminVehicle?.id) {
        return (
            <>
                {header}
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            </>
        );
    }

    return (
        <ChangelogSubpageComponent
            attributeDefinitions={getVehicleChangelogAttributeDefinitions(t)}
            changelogs={changelogsEntries}
            changelogSubpageTitle={t('changelog-vehicleadmin-subpage-title', {
                vehicleName,
            })}
            entityType={ChangelogEntityType.VEHICLE_ADMIN}
        />
    );
};

export default VehicleAdministrationChangelogsSubpageComponent;
