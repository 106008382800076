import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationsSummaryWidgetClassKey =
    | 'actionButton'
    | 'content'
    | 'listContainer'
    | 'scrollToTopFab'
    | 'scrollToTopFabVisible';

export const widgetWidth = 264;
export const widgetHeight = 465;

export const ConversationsSummaryWidgetStyleRules: StyleRulesCallback<Theme, {}, ConversationsSummaryWidgetClassKey> = (
    theme: Theme
) =>
    createStyles({
        actionButton: {
            fontSize: 14,
            height: 24,
            marginLeft: theme.spacing(0.5),
            padding: 0,
            width: 24,
        },
        content: {
            '@global .ReactVirtualized__Grid__innerScrollContainer': {
                marginBottom: '48px !important',
            },
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        listContainer: {
            flexGrow: 1,
            overflow: 'hidden',
            position: 'relative',
        },
        scrollToTopFab: {
            bottom: -50,
            height: 24,
            left: 'calc(50% - 12px)',
            minHeight: 24,
            position: 'absolute',
            transition: '250ms',
            width: 24,
        },
        scrollToTopFabVisible: {
            bottom: theme.spacing(1.5),
        },
    });
