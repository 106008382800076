import type { Disposable } from '~/listeners';
import type { Notification, ResourceFilter } from '~/services/ApiClient';

import { ConnectionStatus } from './contracts';
import { NotificationsClient } from './instances';

export const subscribeToNotificationsAndRetrieveTheData = async <T extends Notification>(
    resourceType: string,
    parse: (data: unknown) => T,
    handler: (notification: T) => void,
    retrieveData: () => void,
    resourceFilter: ResourceFilter | undefined = undefined
): Promise<Disposable> => {
    const disposableHandlers: Disposable[] = [];

    await NotificationsClient.subscribe({ handler, parse, resourceFilter, resourceType }).then(
        (disposeHandler: Disposable) => {
            disposableHandlers.push(disposeHandler);
            retrieveData();
        }
    );

    disposableHandlers.push(
        NotificationsClient.onConnectionStatusChanged(
            (connectionStatus: ConnectionStatus, prevConnectionStatus: ConnectionStatus) => {
                if (
                    connectionStatus === ConnectionStatus.CONNECTED &&
                    prevConnectionStatus === ConnectionStatus.TRYINGTORECONNECT
                ) {
                    retrieveData();
                }
            }
        )
    );

    return {
        dispose: () => {
            disposableHandlers.forEach(({ dispose }) => {
                dispose();
            });
        },
    };
};
