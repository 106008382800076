import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionGroupMarkerClassKey =
    | 'assetName'
    | 'box'
    | 'currentAvatar'
    | 'currentAvatarSelected'
    | 'markerIconDiv'
    | 'parkAvatar'
    | 'parkAvatarSelected'
    | 'popupCurrentAvatar'
    | 'popupParkAvatar'
    | 'smallAvatarSelected'
    | 'smallMarkerIconBox';

export const PositionGroupMarkerStyleRules: StyleRulesCallback<Theme, {}, PositionGroupMarkerClassKey> = (
    theme: Theme
) =>
    createStyles({
        assetName: {
            backgroundColor: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },

        box: {
            '& > :first-child': {
                marginRight: '15px',
                marginTop: '5px',
            },
            '& > :last-child': {
                minWidth: '260px',
            },
            display: 'flex',
            padding: '15px',
            width: '350px',
        },
        currentAvatar: {
            backgroundColor: '#797979',
            color: theme.palette.common.white,
            fontSize: 16,
        },
        currentAvatarSelected: {
            borderColor: '#797979',
        },
        markerIconDiv: {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
        },
        parkAvatar: {
            backgroundColor: '#0652dd',
            border: '1px solid',
            borderColor: theme.palette.common.white,
            color: theme.palette.common.white,
            filter: 'drop-shadow( 2px 0px 2px rgba(0, 0, 0, .4))',
            fontSize: 'inherit',
            height: 30,
            padding: 0,
            width: 30,
        },
        parkAvatarSelected: {
            borderColor: '#0652dd',
            height: 45,
            left: -7,
            top: -7,
            width: 45,
        },

        popupCurrentAvatar: {
            backgroundColor: '#797979',
            fontSize: 18,
            height: 30,
            width: 30,
        },
        popupParkAvatar: {
            '& > :first-child': {
                border: 'none',
            },
            backgroundColor: '#0652dd',
            fontSize: 18,
            height: 30,
            width: 30,
        },
        smallAvatarSelected: {
            height: 40,
            left: -10,
            top: -10,
            width: 40,
        },
        smallMarkerIconBox: {
            height: 20,
            width: 20,
        },
    });
