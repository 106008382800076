import { translateClassification } from '@fv/translations';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import type { Classification, ClassificationType, PojoModel } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

const formatClassificationMemoized = memoizeOne((t: SingleTFunction) =>
    memoize(
        (classificationKey: ClassificationType, classification: Classification | PojoModel<Classification>) =>
            translateClassification({ classification, classificationKey, t }),
        (classificationKey: ClassificationType, classification: Classification | PojoModel<Classification>) =>
            `${classificationKey}/${classification.key}/${classification.displayName || ''}`
    )
);

export const formatClassification = (
    t: SingleTFunction,
    classificationKey: ClassificationType,
    classification: Classification | PojoModel<Classification>
): string => {
    if (!classification) {
        return '';
    }

    return formatClassificationMemoized(t)(classificationKey, classification);
};
