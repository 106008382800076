import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { LabeledIndicatorGroupContainer } from '~/components/Indicators';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedDriverHoursStatus } from '~/services/ApiClient';

import { DriverActivityGraph } from '../../../DriverActivityGraph';
import { SectionName } from '../../constants';

import { ContinuousDriving } from './components/ContinuousDriving';
import type { DailyOverviewSectionClassKey } from './styles';

export interface DailyOverviewSectionProps {
    dragHandleElement: JSX.Element;
    driverHours?: ResolvedDriverHoursStatus;
    driverId?: number;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    vehicleId: number;
}

export interface DailyOverviewSectionInnerProps
    extends DailyOverviewSectionProps,
        InjectedTranslationProps,
        WithStyles<DailyOverviewSectionClassKey> {}

export const DailyOverviewSectionComponent: React.FC<DailyOverviewSectionInnerProps> = (props) => {
    const { dragHandleElement, driverHours, driverId, isCollapsed, t, toggleCollapsed, vehicleId } = props;

    const indicatorGroups = driverHours ? <ContinuousDriving driverHours={driverHours} /> : undefined;
    return (
        <ReorderableSection
            dataId={SectionName.DAILY_OVERVIEW}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.DAILY_OVERVIEW}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <DriverActivityGraph
                componentId="daily-overview-section-driver-activity-graph"
                driverId={driverId}
                vehicleId={vehicleId}
            />
            <LabeledIndicatorGroupContainer dataId="daily-overview-indicator-group-container">
                {indicatorGroups}
            </LabeledIndicatorGroupContainer>
        </ReorderableSection>
    );
};
