import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { OverlayMenuItemProps } from './component';

export type OverlayMenuItemClassKey = 'externalListIcon' | 'icon' | 'listIcon' | 'text';
export const useStyles = makeStyles<Theme, OverlayMenuItemProps, OverlayMenuItemClassKey>((theme) => ({
    externalListIcon: {
        flexDirection: 'row-reverse',
    },
    icon: {
        overflow: 'visible',
    },
    listIcon: {
        paddingLeft: (props) => (props.parentMenuItem ? theme.spacing(4) : theme.spacing()),
    },
    text: {
        marginLeft: 20,
    },
}));
