import type { Dispatch } from 'redux';

import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage';
import { getVehicleChangelogsAction } from '~/data/vehicleadministration';
import { reportError } from '~/reporting';
import type { ResolvedVehicleChangelogChanges } from '~/services/ApiClient';

import type { VehicleAdministrationChangelogsStoreState } from './reducers';

export interface VehicleChangelogsStateProps {
    changelogsEntries: ChangelogEntry<ResolvedVehicleChangelogChanges>[];
    loading: boolean;
    previousVehicleId?: number;
    rejected: boolean;
}

export interface VehicleChangelogsDispatchProps {
    getVehicleChangelogs: (vehicleId: number) => void;
}

export interface VehicleChangelogsReduxProps extends VehicleChangelogsStateProps, VehicleChangelogsDispatchProps {}

export const mapStateToProps = (state: VehicleAdministrationChangelogsStoreState): VehicleChangelogsStateProps => ({
    changelogsEntries: state.vehicleChangelogsEntries,
    loading: state.vehicleChangelogs.pending,
    previousVehicleId: state.previousChangelogId,
    rejected: state.vehicleChangelogs.rejected,
});

export const mapDispatchProps = (dispatch: Dispatch): VehicleChangelogsDispatchProps => ({
    getVehicleChangelogs: (vehicleId: number) => {
        dispatch(getVehicleChangelogsAction(vehicleId)).catch(reportError);
    },
});
