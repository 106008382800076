import { styled } from '@mui/material';

import { DriverIcon as _DriverIcon } from '~/components/Icons';

const DriverIcon = styled(_DriverIcon)({
    height: '100%',
    width: '100%',
});

export { DriverIcon };
