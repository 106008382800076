export enum Status {
    FAILEDTOSAVE = 'FAILEDTOSAVE',
    SAVED = 'SAVED',
    SAVING = 'SAVING',
}

export enum KeyStatus {
    FAILEDTOSAVE = 'FAILEDTOSAVE',
    SAVED = 'SAVED',
    SAVING = 'SAVING',
    TOBESAVED = 'TOBESAVED',
}

export interface LocalKeyPreferences {
    data: object;
    status: KeyStatus;
}

export interface SharedKeyPreferences {
    data: object;
    toBeSaved: boolean;
}

export interface PreferencesDictionary<T> {
    [key: string]: T;
}

export type StatusChangedHandler = (status: Status, prevStatus: Status) => void;
