import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type {
    Classification,
    ResolvedVehicleTypeChangelogChanges,
    VehicleTypeChangelogChanges,
} from '~/services/ApiClient';

export const getResolvedVehicleTypeChangelogChanges = (
    vehicleCategories: NumericDictionary<Classification>,
    axlesClassification: NumericDictionary<Classification>,
    emissionClasses: NumericDictionary<Classification>,
    changes?: VehicleTypeChangelogChanges
): ResolvedVehicleTypeChangelogChanges => {
    const typeId = changes?.type;
    const axlesId = changes?.axles;
    const emissionClassId = changes?.emissionClass;
    return {
        axles: !isUndefined(axlesId) ? axlesClassification[axlesId] : undefined,
        description: changes?.description,
        dimensions: changes?.dimensions,
        emissionClass: !isUndefined(emissionClassId) ? emissionClasses[emissionClassId] : undefined,
        hazardousGoods: changes?.hazardousGoods,
        name: changes?.name,
        speedLimit: changes?.speedLimit,
        type: !isUndefined(typeId) ? vehicleCategories[typeId] : undefined,
        weight: changes?.weight,
    };
};
