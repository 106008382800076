import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryFormatter } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { formatDate } from '~/services/Formatters';

import { SectionName } from '../../constants';

import type { IssuedSectionProps } from './models';

const IssuedSection: FC<IssuedSectionProps> = (props) => {
    const { adminCompanyCard, dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();

    const keyValueItems: KeyValueItem[] = [
        {
            label: t('company-card-issued-card-number'),
            value: adminCompanyCard.issuedCardNumber ? <span> {adminCompanyCard.issuedCardNumber}</span> : undefined,
            valueDataId: 'company-card-issued-card-number',
        },
        {
            label: t('company-card-date'),
            value: adminCompanyCard.date ? <span> {formatDate(adminCompanyCard.date)}</span> : undefined,
            valueDataId: 'company-card-date',
        },
        {
            label: t('company-card-member-state'),
            value:
                adminCompanyCard.memberState && adminCompanyCard.countryCode ? (
                    <CountryFormatter t={t} value={String(adminCompanyCard.countryCode)} />
                ) : undefined,
            valueDataId: 'company-card-member-state',
        },
        {
            label: t('company-card-authority-name'),
            value: adminCompanyCard.authorityName ? <span> {adminCompanyCard.authorityName}</span> : undefined,
            valueDataId: 'company-card-authority-name',
        },
    ];
    return (
        <ReorderableSection
            dataId="company-card-administration-issued-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.ISSUED)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="issued-list" />
        </ReorderableSection>
    );
};
IssuedSection.displayName = 'IssuedSection';
export default IssuedSection;
