import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type IconDividerClassKey = 'root';

export const IconDividerStyleRules: StyleRulesCallback<Theme, {}, IconDividerClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            alignSelf: 'center',
            backgroundColor: 'currentColor',
            height: 24,
            margin: theme.spacing(),
            verticalAlign: 'middle',
            width: 1,
        },
    });
