export enum ColumnName {
    ADDRESS = 'street',
    CARDSTATUS = 'card-status',
    CITY = 'city',
    CODRIVER = 'co-driver',
    CODRIVERID = 'co-driver-id',
    COUNTRYCODE = 'country-code',
    DATETIME = 'date-time',
    DISTANCE = 'distance',
    DRIVER = 'driver',
    DRIVERACTIVITY = 'driver-activity',
    DRIVERID = 'driver-id',
    DURATION = 'duration',
    ENDODOMETER = 'end-odometer',
    ISCORRECTION = 'is-correction',
    LATITUDE = 'latitude',
    LONGITUDE = 'longitude',
    MSISDN = 'msisdn',
    NEARESTCITY = 'nearest-city',
    POSTALCODE = 'postal-code',
    STARTODOMETER = 'start-odometer',
    SUBACTIVITY = 'sub-activity',
    VEHICLE = 'vehicle',
}
