import type { Theme } from '@mui/material';

import { addDates, stringToDuration } from '~/libs/dates';
import { sortBy } from '~/libs/utility';
import type { ResolvedDriverEvent } from '~/services/ApiClient/models';

import type { GraphSeries } from './models';

interface MapDriverEventsArgs {
    resolvedDriverEvents: ResolvedDriverEvent[];
    theme: Theme;
}

export const mapDriverEvents = (args: MapDriverEventsArgs) => {
    const { resolvedDriverEvents, theme } = args;

    const sortedDriverEvents = sortBy(resolvedDriverEvents, 'startDatetime');

    return sortedDriverEvents
        .map((driverEvent): GraphSeries | undefined => {
            if (!driverEvent.completedActivity) {
                return undefined;
            }

            const startDateTime = driverEvent.startDatetime ?? new Date();
            const stopDateTime = addDates(startDateTime, stringToDuration(driverEvent.completedActivity.duration));

            return {
                fillColor:
                    theme.functionalItemsColors.driverActivity[
                        driverEvent.completedActivity.activityType.key.toLowerCase()
                    ].value,
                meta: {
                    activityType: driverEvent.completedActivity.activityType,
                    address: driverEvent.location?.address,
                    distance: driverEvent.completedActivity.distance,
                    driver: driverEvent.driver,
                    driverEventId: driverEvent.eventId,
                    duration: driverEvent.completedActivity.duration,
                    startDateTime,
                    stopDateTime,
                    subActivity: driverEvent.completedActivity.subActivityType,
                    vehicle: driverEvent.vehicle,
                },
                x: driverEvent.completedActivity.activityType.key.toLowerCase(),
                y: [startDateTime.getTime(), stopDateTime.getTime()],
            };
        })
        .filter(Boolean) as GraphSeries[];
};
