import { createSelector } from 'reselect';

import { sceneMapRootStateSelector } from '~/selectors';
import { memoizeOne } from '~/services/Memoize';

import { createSceneMapStateSelector } from '../../selectors';

const mapStateSelector = memoizeOne((mapKey: string) =>
    createSelector(sceneMapRootStateSelector, createSceneMapStateSelector(mapKey), (state, mapState) => ({
        panToAsset: state.panToAsset,
        searchQuery: mapState?.searchQuery,
        showAllSelectedAssets: state.showAllSelectedAssets,
        viewport: state.viewport,
    }))
);

export { mapStateSelector };
