import type { FC } from 'react';

import { useDisplayPreferences } from '~/common/hooks';
import { SceneMapView } from '~/components/SceneMap';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { monitoringTrailerEntryEquals } from '~/scenes/Monitoring/utils';

import { MONITORING_MAP_TRAILER_STATE_KEY } from './consts';
import { createFilter } from './createFilter';
import type { TrailerMapViewProps } from './models';

const getRowId = (entry: MonitoringTrailerEntry): number => entry.trailer.id;
const getLocation = (entry: MonitoringTrailerEntry) => entry.status?.location?.position;

const TrailerMapView: FC<TrailerMapViewProps> = (props) => {
    const displayPreferences = useDisplayPreferences();

    return (
        <SceneMapView
            dataId="monitoring-map-trailer"
            entryEquals={monitoringTrailerEntryEquals}
            filterDataSource={createFilter(displayPreferences)}
            getAssetIdentifier={getRowId}
            getAssetPosition={getLocation}
            mapKey={MONITORING_MAP_TRAILER_STATE_KEY}
            showTooltip
            {...props}
        />
    );
};
TrailerMapView.displayName = 'TrailerMapView';

export { TrailerMapView };
