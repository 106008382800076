export const TACHOFILES_USERPREFERENCES_KEY = 'tachofiles-state';

export const TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY = 'tachofiles-assetselector-state';

export interface TachoFilesUserPreferences {
    leftPaneIsOpen: boolean;
}

export const defaultTachoFilesUserPreferences: TachoFilesUserPreferences = {
    leftPaneIsOpen: true,
};

export const requiredTachoFilesUserPreferences = {
    defaultState: defaultTachoFilesUserPreferences,
    key: TACHOFILES_USERPREFERENCES_KEY,
};
