import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TableHeaderRowTitleClassKey = 'title';

export const TableHeaderRowTitleStyleRules: StyleRulesCallback<Theme, {}, TableHeaderRowTitleClassKey> = () =>
    createStyles({
        title: {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });
