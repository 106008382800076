import type { ResolvedMessageBody, WorkflowFormDefinition } from '~/services/ApiClient';
import {
    BooleanFieldWorkflowFormElement,
    BooleanWorkflowFieldValue,
    DateTimeFieldWorkflowFormElement,
    DateTimeWorkflowFieldValue,
    DurationFieldWorkflowFormElement,
    DurationWorkflowFieldValue,
    EnumFieldWorkflowFormElement,
    EnumWorkflowFieldValue,
    FieldWorkflowFormElement,
    MessageBodyType,
    NumericFieldWorkflowFormElement,
    NumericWorkflowFieldValue,
    PositionFieldWorkflowFormElement,
    PositionWorkflowFieldValue,
    RawFieldWorkflowFormElement,
    RawWorkflowFieldValue,
    TextFieldWorkflowFormElement,
    TextWorkflowFieldValue,
    WorkflowMessageBody,
    createApiModel,
} from '~/services/ApiClient';

export const buildWorkflowConversationMessageBody = (
    workflowFormDefinition?: WorkflowFormDefinition
): ResolvedMessageBody | undefined => {
    if (!workflowFormDefinition) {
        return undefined;
    }

    const workflowMessageBody = createApiModel(WorkflowMessageBody, {
        fieldValues: workflowFormDefinition.elements
            .filter((element) => element instanceof FieldWorkflowFormElement)
            .map((element) => {
                const field = element as FieldWorkflowFormElement;

                if (field instanceof RawFieldWorkflowFormElement) {
                    return createApiModel(RawWorkflowFieldValue, {
                        fieldId: field.id,
                        value: undefined,
                    });
                } else if (field instanceof TextFieldWorkflowFormElement) {
                    return createApiModel(TextWorkflowFieldValue, {
                        fieldId: field.id,
                        value: field.defaultValue,
                    });
                } else if (field instanceof NumericFieldWorkflowFormElement) {
                    return createApiModel(NumericWorkflowFieldValue, {
                        fieldId: field.id,
                        value: field.defaultValue,
                    });
                } else if (field instanceof BooleanFieldWorkflowFormElement) {
                    return createApiModel(BooleanWorkflowFieldValue, {
                        fieldId: field.id,
                        value: field.defaultValue,
                    });
                }
                if (field instanceof DateTimeFieldWorkflowFormElement) {
                    return createApiModel(DateTimeWorkflowFieldValue, {
                        fieldId: field.id,
                        value: undefined,
                    });
                } else if (field instanceof DurationFieldWorkflowFormElement) {
                    return createApiModel(DurationWorkflowFieldValue, {
                        fieldId: field.id,
                        value: field.defaultValue,
                    });
                } else if (field instanceof EnumFieldWorkflowFormElement) {
                    return createApiModel(EnumWorkflowFieldValue, {
                        fieldId: field.id,
                        optionId: field.defaultValue,
                    });
                } else if (field instanceof PositionFieldWorkflowFormElement) {
                    return createApiModel(PositionWorkflowFieldValue, {
                        fieldId: field.id,
                        value: field.defaultValue,
                    });
                }

                throw new Error(`Unhandled form element of type '${field.constructor.name}'`);
            }),
        formDefinitionId: workflowFormDefinition.id,
    });
    return {
        type: MessageBodyType.Workflow,
        value: workflowMessageBody,
        workflowFormDefinition,
    };
};
