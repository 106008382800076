import type { Language as L } from '~/services/Language';
import type { Fulfilled, Pending, Rejected } from '~/types';

export type Language = L;

export enum ActionTypePrefixes {
    CHANGELANGUAGE = 'CHANGELANGUAGE',
    CURRENTLANGUAGE = 'CURRENTLANGUAGE',
    LANGUAGES = 'LANGUAGES',
}

export enum ActionTypeKeys {
    CHANGELANGUAGE_FULFILLED = 'CHANGELANGUAGE_FULFILLED',
    CHANGELANGUAGE_PENDING = 'CHANGELANGUAGE_PENDING',
    CHANGELANGUAGE_REJECTED = 'CHANGELANGUAGE_REJECTED',
    CURRENTLANGUAGE_FULFILLED = 'CURRENTLANGUAGE_FULFILLED',
    CURRENTLANGUAGE_PENDING = 'CURRENTLANGUAGE_PENDING',
    CURRENTLANGUAGE_REJECTED = 'CURRENTLANGUAGE_REJECTED',
    LANGUAGES_FULFILLED = 'LANGUAGES_FULFILLED',
    LANGUAGES_PENDING = 'LANGUAGES_PENDING',
    LANGUAGES_REJECTED = 'LANGUAGES_REJECTED',
}

export interface LanguagesPayload {
    languages: Language[];
}

export interface CurrentLanguagePayload {
    language: Language;
}

export interface ChangeLanguagePayload {
    language: Language;
}

export type ActionTypes =
    // Languages
    | Fulfilled<ActionTypeKeys.CHANGELANGUAGE_FULFILLED, ChangeLanguagePayload>
    | Fulfilled<ActionTypeKeys.CURRENTLANGUAGE_FULFILLED, CurrentLanguagePayload>
    | Fulfilled<ActionTypeKeys.LANGUAGES_FULFILLED, LanguagesPayload>

    // Current language
    | Pending<ActionTypeKeys.CHANGELANGUAGE_PENDING>
    | Pending<ActionTypeKeys.CURRENTLANGUAGE_PENDING>
    | Pending<ActionTypeKeys.LANGUAGES_PENDING>

    // Change language
    | Rejected<ActionTypeKeys.CHANGELANGUAGE_REJECTED>
    | Rejected<ActionTypeKeys.CURRENTLANGUAGE_REJECTED>
    | Rejected<ActionTypeKeys.LANGUAGES_REJECTED>;
