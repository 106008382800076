import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const MONITORING_LIST_VEHICLE_USERPREFERENCES_KEY = 'monitoring-grid-vehicle-state';

export const defaultVehicleListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.VEHICLE,
        ColumnName.MSISDN,
        ColumnName.DEVICETYPE,
        ColumnName.LASTUPDATE,
        ColumnName.SPEED,
        ColumnName.HEADING,
        ColumnName.ADDRESS,
        ColumnName.CITY,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRY,
        ColumnName.COUNTRYCODE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.INHIBITORSTATUS,
        ColumnName.ODOMETER,
        ColumnName.FUELLEVEL,
        ColumnName.NEARESTCITY,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.ROUTESTATUS,
        ColumnName.ROUTEDESTINATION,
        ColumnName.ROUTEETA,
        ColumnName.ROUTEDRIVERHOURSETA,
        ColumnName.TRAILER,
        ColumnName.TRAILEREVENT,
        ColumnName.TRAILERLASTEVENT,
        ColumnName.DRIVERID,
        ColumnName.DRIVERNAME,
        ColumnName.CODRIVERID,
        ColumnName.CODRIVERNAME,
        ColumnName.DRIVERACTIVITY,
        ColumnName.DRIVERSUBACTIVITY,
        ColumnName.AVAILABLEDAILYDRIVINGTIME,
        ColumnName.AVAILABLEWEEKLYDRIVINGTIME,
        ColumnName.AVAILABLEBIWEEKLYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEDAILYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEWEEKLYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEBIWEEKLYDRIVINGTIME,
        ColumnName.SHIFTDURATION,
        ColumnName.DAILYDUTY,
        ColumnName.DURATION,
        ColumnName.CONTINUOUSWORKAVAILABLE,
        ColumnName.OPERATIONALWEEKDURATION,
        ColumnName.LOGINMANUALSTATUS,
        ColumnName.CONTINUOUSDRIVING,
        ColumnName.DRIVEBREAK,
        ColumnName.DAILYDRIVING,
        ColumnName.WEEKLYDRIVING,
        ColumnName.EXTENDEDDRIVINGCOUNT,
        ColumnName.CONTINUOUSLABORSHORT,
        ColumnName.SHORTLABORBREAK,
        ColumnName.CONTINUOUSLABORLONG,
        ColumnName.LONGLABORBREAK,
        ColumnName.NIGHTLABOR,
        ColumnName.WEEKLYLABOR,
        ColumnName.AVERAGEWEEKLYLABOR,
        ColumnName.WEEKLYDUTY,
        ColumnName.MONTHLYDUTY,
        ColumnName.MONTHLYDRIVERDUTY,
        ColumnName.FIRSTSPLITDAILYREST,
        ColumnName.REDUCEDDAILYRESTCOUNT,
        ColumnName.DAILYRESTING,
        ColumnName.WEEKLYRESTCOMPENSATION,
        ColumnName.TOTALWEEKLYRESTCOMPENSATION,
        ColumnName.WEEKLYRESTDUE,
        ColumnName.CODRIVERACTIVITY,
        ColumnName.CODRIVERSUBACTIVITY,
        ColumnName.CODRIVERAVAILABLEDAILYDRIVINGTIME,
        ColumnName.CODRIVERAVAILABLEWEEKLYDRIVINGTIME,
        ColumnName.CODRIVERAVAILABLEBIWEEKLYDRIVINGTIME,
        ColumnName.CODRIVERSHIFTDURATION,
        ColumnName.CODRIVERDAILYDUTY,
        ColumnName.CODRIVERDURATION,
        ColumnName.CODRIVERCONTINUOUSWORKAVAILABLE,
        ColumnName.CODRIVEROPERATIONALWEEKDURATION,
        ColumnName.CODRIVERLOGINMANUALSTATUS,
        ColumnName.CODRIVERCONTINUOUSDRIVING,
        ColumnName.CODRIVERDRIVEBREAK,
        ColumnName.CODRIVERDAILYDRIVING,
        ColumnName.CODRIVERWEEKLYDRIVING,
        ColumnName.CODRIVEREXTENDEDDRIVINGCOUNT,
        ColumnName.CODRIVERCONTINUOUSLABORSHORT,
        ColumnName.CODRIVERSHORTLABORBREAK,
        ColumnName.CODRIVERCONTINUOUSLABORLONG,
        ColumnName.CODRIVERLONGLABORBREAK,
        ColumnName.CODRIVERNIGHTLABOR,
        ColumnName.CODRIVERWEEKLYLABOR,
        ColumnName.CODRIVERAVERAGEWEEKLYLABOR,
        ColumnName.CODRIVERWEEKLYDUTY,
        ColumnName.CODRIVERMONTHLYDUTY,
        ColumnName.CODRIVERMONTHLYDRIVERDUTY,
        ColumnName.CODRIVERFIRSTSPLITDAILYREST,
        ColumnName.CODRIVERREDUCEDDAILYRESTCOUNT,
        ColumnName.CODRIVERDAILYRESTING,
        ColumnName.CODRIVERWEEKLYRESTCOMPENSATION,
        ColumnName.CODRIVERTOTALWEEKLYRESTCOMPENSATION,
        ColumnName.CODRIVERWEEKLYRESTDUE,
        ColumnName.TRAILERID,
        ColumnName.TRAILERWEIGHT,
        ColumnName.AXLELOAD,
        ColumnName.EBSMILEAGE,
        ColumnName.EBSTIMESTAMP,
        ColumnName.TRAILERUNITID,
        ColumnName.TRAILERDOOR1SENSOR,
        ColumnName.TRAILERDOOR2SENSOR,
        ColumnName.TRAILERREEFERSTATUS,
        ColumnName.TRAILERCOMPARTMENT1STATUS,
        ColumnName.TRAILERCOMPARTMENT1STATUSMANUFACTURER,
        ColumnName.TRAILERCOMPARTMENT1LASTEVENT,
        ColumnName.TRAILERCOMPARTMENT1SUPPLYTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT1RETURNTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT1SETPOINTTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR1,
        ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR2,
        ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR3,
        ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR4,
        ColumnName.TRAILERCOMPARTMENT2STATUS,
        ColumnName.TRAILERCOMPARTMENT2STATUSMANUFACTURER,
        ColumnName.TRAILERCOMPARTMENT2LASTEVENT,
        ColumnName.TRAILERCOMPARTMENT2SUPPLYTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT2RETURNTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT2SETPOINTTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR1,
        ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR2,
        ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR3,
        ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR4,
        ColumnName.TRAILERCOMPARTMENT3STATUS,
        ColumnName.TRAILERCOMPARTMENT3STATUSMANUFACTURER,
        ColumnName.TRAILERCOMPARTMENT3LASTEVENT,
        ColumnName.TRAILERCOMPARTMENT3SUPPLYTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT3RETURNTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT3SETPOINTTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR1,
        ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR2,
        ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR3,
        ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR4,
        ColumnName.TRAILERCOMPARTMENT4STATUS,
        ColumnName.TRAILERCOMPARTMENT4STATUSMANUFACTURER,
        ColumnName.TRAILERCOMPARTMENT4LASTEVENT,
        ColumnName.TRAILERCOMPARTMENT4SUPPLYTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT4RETURNTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT4SETPOINTTEMPERATURE,
        ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR1,
        ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR2,
        ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR3,
        ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR4,
    ],
    columnWidths: [
        { columnName: ColumnName.VEHICLE, width: 200 },
        { columnName: ColumnName.MSISDN, width: 150 },
        { columnName: ColumnName.DEVICETYPE, width: 125 },
        { columnName: ColumnName.LASTUPDATE, width: 150 },
        { columnName: ColumnName.SPEED, width: 90 },
        { columnName: ColumnName.HEADING, width: 90 },
        { columnName: ColumnName.ADDRESS, width: 200 },
        { columnName: ColumnName.CITY, width: 175 },
        { columnName: ColumnName.POSTALCODE, width: 100 },
        { columnName: ColumnName.COUNTRY, width: 150 },
        { columnName: ColumnName.COUNTRYCODE, width: 90 },
        { columnName: ColumnName.IGNITIONSTATUS, width: 90 },
        { columnName: ColumnName.INHIBITORSTATUS, width: 90 },
        { columnName: ColumnName.ODOMETER, width: 100 },
        { columnName: ColumnName.FUELLEVEL, width: 90 },
        { columnName: ColumnName.NEARESTCITY, width: 250 },
        { columnName: ColumnName.ALTITUDE, width: 90 },
        { columnName: ColumnName.LATITUDE, width: 100 },
        { columnName: ColumnName.LONGITUDE, width: 100 },
        { columnName: ColumnName.ROUTESTATUS, width: 175 },
        { columnName: ColumnName.ROUTEDESTINATION, width: 400 },
        { columnName: ColumnName.ROUTEETA, width: 150 },
        { columnName: ColumnName.ROUTEDRIVERHOURSETA, width: 150 },
        { columnName: ColumnName.DRIVERID, width: 90 },
        { columnName: ColumnName.DRIVERNAME, width: 175 },
        { columnName: ColumnName.CODRIVERID, width: 90 },
        { columnName: ColumnName.CODRIVERNAME, width: 175 },
        { columnName: ColumnName.DRIVERACTIVITY, width: 75 },
        { columnName: ColumnName.DRIVERSUBACTIVITY, width: 125 },
        { columnName: ColumnName.AVAILABLEDAILYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.AVAILABLEWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.AVAILABLEBIWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.DOUBLEMANNEDAVAILABLEDAILYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.DOUBLEMANNEDAVAILABLEWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.DOUBLEMANNEDAVAILABLEBIWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.SHIFTDURATION, width: 100 },
        { columnName: ColumnName.DAILYDUTY, width: 100 },
        { columnName: ColumnName.DURATION, width: 100 },
        { columnName: ColumnName.CONTINUOUSWORKAVAILABLE, width: 100 },
        { columnName: ColumnName.OPERATIONALWEEKDURATION, width: 100 },
        { columnName: ColumnName.LOGINMANUALSTATUS, width: 100 },
        { columnName: ColumnName.CONTINUOUSDRIVING, width: 100 },
        { columnName: ColumnName.DRIVEBREAK, width: 100 },
        { columnName: ColumnName.DAILYDRIVING, width: 100 },
        { columnName: ColumnName.WEEKLYDRIVING, width: 100 },
        { columnName: ColumnName.EXTENDEDDRIVINGCOUNT, width: 100 },
        { columnName: ColumnName.CONTINUOUSLABORSHORT, width: 100 },
        { columnName: ColumnName.SHORTLABORBREAK, width: 100 },
        { columnName: ColumnName.CONTINUOUSLABORLONG, width: 100 },
        { columnName: ColumnName.LONGLABORBREAK, width: 100 },
        { columnName: ColumnName.NIGHTLABOR, width: 100 },
        { columnName: ColumnName.WEEKLYLABOR, width: 100 },
        { columnName: ColumnName.AVERAGEWEEKLYLABOR, width: 100 },
        { columnName: ColumnName.WEEKLYDUTY, width: 100 },
        { columnName: ColumnName.MONTHLYDUTY, width: 100 },
        { columnName: ColumnName.MONTHLYDRIVERDUTY, width: 100 },
        { columnName: ColumnName.FIRSTSPLITDAILYREST, width: 100 },
        { columnName: ColumnName.REDUCEDDAILYRESTCOUNT, width: 100 },
        { columnName: ColumnName.DAILYRESTING, width: 100 },
        { columnName: ColumnName.WEEKLYRESTCOMPENSATION, width: 100 },
        { columnName: ColumnName.TOTALWEEKLYRESTCOMPENSATION, width: 100 },
        { columnName: ColumnName.WEEKLYRESTDUE, width: 150 },
        { columnName: ColumnName.CODRIVERACTIVITY, width: 75 },
        { columnName: ColumnName.CODRIVERSUBACTIVITY, width: 125 },
        { columnName: ColumnName.CODRIVERAVAILABLEDAILYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.CODRIVERAVAILABLEWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.CODRIVERAVAILABLEBIWEEKLYDRIVINGTIME, width: 100 },
        { columnName: ColumnName.CODRIVERSHIFTDURATION, width: 100 },
        { columnName: ColumnName.CODRIVERDAILYDUTY, width: 100 },
        { columnName: ColumnName.CODRIVERDURATION, width: 100 },
        { columnName: ColumnName.CODRIVERCONTINUOUSWORKAVAILABLE, width: 100 },
        { columnName: ColumnName.CODRIVEROPERATIONALWEEKDURATION, width: 100 },
        { columnName: ColumnName.CODRIVERLOGINMANUALSTATUS, width: 100 },
        { columnName: ColumnName.CODRIVERCONTINUOUSDRIVING, width: 100 },
        { columnName: ColumnName.CODRIVERDRIVEBREAK, width: 100 },
        { columnName: ColumnName.CODRIVERDAILYDRIVING, width: 100 },
        { columnName: ColumnName.CODRIVERWEEKLYDRIVING, width: 100 },
        { columnName: ColumnName.CODRIVEREXTENDEDDRIVINGCOUNT, width: 100 },
        { columnName: ColumnName.CODRIVERCONTINUOUSLABORSHORT, width: 100 },
        { columnName: ColumnName.CODRIVERSHORTLABORBREAK, width: 100 },
        { columnName: ColumnName.CODRIVERCONTINUOUSLABORLONG, width: 100 },
        { columnName: ColumnName.CODRIVERLONGLABORBREAK, width: 100 },
        { columnName: ColumnName.CODRIVERNIGHTLABOR, width: 100 },
        { columnName: ColumnName.CODRIVERWEEKLYLABOR, width: 100 },
        { columnName: ColumnName.CODRIVERAVERAGEWEEKLYLABOR, width: 100 },
        { columnName: ColumnName.CODRIVERWEEKLYDUTY, width: 100 },
        { columnName: ColumnName.CODRIVERMONTHLYDUTY, width: 100 },
        { columnName: ColumnName.CODRIVERMONTHLYDRIVERDUTY, width: 100 },
        { columnName: ColumnName.CODRIVERFIRSTSPLITDAILYREST, width: 100 },
        { columnName: ColumnName.CODRIVERREDUCEDDAILYRESTCOUNT, width: 100 },
        { columnName: ColumnName.CODRIVERDAILYRESTING, width: 100 },
        { columnName: ColumnName.CODRIVERWEEKLYRESTCOMPENSATION, width: 100 },
        { columnName: ColumnName.CODRIVERTOTALWEEKLYRESTCOMPENSATION, width: 100 },
        { columnName: ColumnName.CODRIVERWEEKLYRESTDUE, width: 150 },
        { columnName: ColumnName.TRAILER, width: 150 },
        { columnName: ColumnName.TRAILERID, width: 100 },
        { columnName: ColumnName.TRAILERUNITID, width: 100 },
        { columnName: ColumnName.TRAILEREVENT, width: 150 },
        { columnName: ColumnName.TRAILERLASTEVENT, width: 100 },
        { columnName: ColumnName.TRAILERDOOR1SENSOR, width: 75 },
        { columnName: ColumnName.TRAILERDOOR2SENSOR, width: 75 },
        { columnName: ColumnName.TRAILERREEFERSTATUS, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1STATUS, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1LASTEVENT, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT1TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2STATUS, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2LASTEVENT, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT2TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3STATUS, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3LASTEVENT, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT3TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4STATUS, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4LASTEVENT, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.TRAILERCOMPARTMENT4TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.TRAILERWEIGHT, width: 75 },
        { columnName: ColumnName.AXLELOAD, width: 75 },
        { columnName: ColumnName.EBSMILEAGE, width: 75 },
        { columnName: ColumnName.EBSTIMESTAMP, width: 75 },
    ],
    filteringEnabled: false,
    filters: [],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    sorting: [{ columnName: ColumnName.LASTUPDATE, direction: GridSortingDirection.DESC }],
    visibleColumns: [
        ColumnName.VEHICLE,
        ColumnName.MSISDN,
        ColumnName.DEVICETYPE,
        ColumnName.LASTUPDATE,
        ColumnName.SPEED,
        ColumnName.HEADING,
        ColumnName.ADDRESS,
        ColumnName.CITY,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRY,
        ColumnName.COUNTRYCODE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.INHIBITORSTATUS,
        ColumnName.ODOMETER,
        ColumnName.FUELLEVEL,
        ColumnName.NEARESTCITY,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.ROUTESTATUS,
        ColumnName.ROUTEDESTINATION,
        ColumnName.ROUTEETA,
        ColumnName.ROUTEDRIVERHOURSETA,
        ColumnName.DRIVERID,
        ColumnName.DRIVERNAME,
        ColumnName.CODRIVERID,
        ColumnName.CODRIVERNAME,
        ColumnName.DRIVERACTIVITY,
        ColumnName.DRIVERSUBACTIVITY,
        ColumnName.AVAILABLEDAILYDRIVINGTIME,
        ColumnName.AVAILABLEWEEKLYDRIVINGTIME,
        ColumnName.AVAILABLEBIWEEKLYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEDAILYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEWEEKLYDRIVINGTIME,
        ColumnName.DOUBLEMANNEDAVAILABLEBIWEEKLYDRIVINGTIME,
        ColumnName.SHIFTDURATION,
        ColumnName.DAILYDUTY,
        ColumnName.TRAILER,
        ColumnName.TRAILEREVENT,
        ColumnName.TRAILERLASTEVENT,
        ColumnName.TRAILERWEIGHT,
        ColumnName.AXLELOAD,
        ColumnName.EBSMILEAGE,
        ColumnName.EBSTIMESTAMP,
    ],
};
