import { isSameDay } from 'date-fns';
import { duration } from 'moment';

import type { Driver, StaticDataStoreState, Vehicle } from '~/common';
import { getValueFromHash } from '~/common';

import type { QueryTripsByAssetRead, ResolvedQueryTripsByAssetRead } from './models';

interface ResolveTripsProps {
    items: QueryTripsByAssetRead[];
    staticData: StaticDataStoreState;
}

const resolveTrips = ({ items = [], staticData }: ResolveTripsProps) => {
    const type = items[0] && items[0].asset?.type && (`${items[0].asset?.type}s` as 'drivers' | 'vehicles');

    if (!type) {
        return [];
    }
    let stopCounter = 1;
    let prevDate = items[0].startDate;
    let prevId = items[0].asset?.id;

    return items.map((trip): ResolvedQueryTripsByAssetRead => {
        let { totalStandingTime } = trip;
        if (!isSameDay(prevDate, trip.startDate) || prevId !== trip.asset?.id) {
            stopCounter = 1;
            totalStandingTime = undefined;
        }
        const resolvedTrip = {
            ...trip,
            asset: getValueFromHash<Driver | Vehicle>(staticData[type].data?.hash, trip.asset?.id),
            stop: stopCounter,
            totalDrivingTime: duration(trip.totalDrivingTime),
            totalStandingTime: duration(totalStandingTime),
            totalTime: duration(trip.totalDrivingTime).add(duration(totalStandingTime)),
        };
        stopCounter += 1;
        prevDate = trip.startDate;
        prevId = trip.asset?.id;
        return resolvedTrip;
    });
};

export { resolveTrips };
