import type { StyleSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';

import { useMapContext } from '../../context';
import type { MapGatewayLayerProps } from '../../models';
import { MaplibreGLLayer } from '../MaplibreGLLayer';

const MapGatewayLayer: FC<MapGatewayLayerProps> = (props) => {
    const [onMap, setOnMap] = useState(false);

    const [style, setStyle] = useState<StyleSpecification | undefined>();
    const [layerInstance, setLayerInstance] = useState(0);
    const { includeTrafficIncidents, includeTruckAttributes, mapMode } = props;

    const { getMaplibreStyle } = useMapContext();

    useEffect(() => {
        if (!onMap) {
            return;
        }

        getMaplibreStyle({ includeTrafficIncidents, includeTruckAttributes, mapMode }).then((res) => setStyle(res));
    }, [includeTrafficIncidents, includeTruckAttributes, mapMode, onMap, getMaplibreStyle]);

    return (
        <FeatureGroup
            eventHandlers={{
                add: () => setOnMap(true),
                remove: () => setOnMap(false),
            }}
        >
            {style && (
                <MaplibreGLLayer
                    eventHandlers={{
                        remove: () => {
                            // Increasing the layer instance, as the maplibreGL layer cannot be re-added to the map again.
                            // Preparing a new instance
                            setLayerInstance(layerInstance + 1);
                        },
                    }}
                    key={layerInstance}
                    style={style}
                />
            )}
        </FeatureGroup>
    );
};

export { MapGatewayLayer };
