import ArrowBack from '@mui/icons-material/ArrowBack';
import Warning from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { useCallback } from 'react';

import { VehicleNameFormatter } from '~/components/Formatters';
import { TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { DisplayUserPreferencesVehicleDisplayFormat, Vehicle } from '~/services/ApiClient';

import { UnreadMessageCountBadge } from '../../../../../UnreadMessageCountBadge';

import type { ConversationWidgetHeaderClassKey } from './styles';

export interface ConversationWidgetHeaderProps {
    failedToSendMessage: boolean;
    messageDetailsSubpageIsOpened: boolean;
    onGoBack?: () => void;
    title?: string;
    unreadTextMessageCount: number;
    unreadWorkflowMessageCount: number;
    vehicle: Vehicle;
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface ConversationWidgetHeaderInnerProps
    extends ConversationWidgetHeaderProps,
        InjectedTranslationProps,
        WithStyles<ConversationWidgetHeaderClassKey> {}

export const ConversationWidgetHeaderComponent: FC<ConversationWidgetHeaderInnerProps> = ({
    classes,
    failedToSendMessage,
    messageDetailsSubpageIsOpened,
    onGoBack,
    t,
    title,
    unreadTextMessageCount,
    unreadWorkflowMessageCount,
    vehicle,
    vehicleDisplayNameFormat,
}) => {
    const handleBackButtonClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation();
            if (onGoBack) {
                onGoBack();
            }
        },
        [onGoBack]
    );

    const backButton = onGoBack && (
        <TitledIconButton
            className={classNames(classes.icon, classes.actionButton)}
            data-id="back-icon"
            onClick={handleBackButtonClick}
            title={t('back')}
        >
            <ArrowBack className={classes.backButton} />
        </TitledIconButton>
    );

    const iconElement = messageDetailsSubpageIsOpened ? (
        backButton
    ) : failedToSendMessage ? (
        <Warning className={classNames(classes.icon, classes.warningIcon)} data-id="warning-icon" />
    ) : (
        <TruckIcon className={classes.icon} data-id="truck-icon" />
    );

    const titleElement = (
        <>
            <Typography className={classes.vehicleDisplayName} data-id="conversation-title" variant="subtitle2">
                <TitledTruncatedText className={classes.vehicleName}>
                    <VehicleNameFormatter value={vehicle} vehicleDisplayNameFormat={vehicleDisplayNameFormat} />
                </TitledTruncatedText>

                {messageDetailsSubpageIsOpened && (
                    <span className={classes.messageInfo}>
                        {` - `}
                        {title ?? t('message-info')}
                    </span>
                )}
            </Typography>

            {!messageDetailsSubpageIsOpened && (
                <UnreadMessageCountBadge
                    unreadTextMessageCount={unreadTextMessageCount}
                    unreadWorkflowMessageCount={unreadWorkflowMessageCount}
                />
            )}
        </>
    );

    return (
        <div className={classes.root} data-id="conversation-title-container">
            {iconElement}
            {titleElement}
        </div>
    );
};
