import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    label: {
        flex: 1,
    },
    labelPlacementStart: {
        marginLeft: 0,
    },
}));

export { useStyles };
