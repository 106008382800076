import type { Classification } from '@fv/translations';

import type { ClassificationAbstraction } from '~/services/ApiClient';

interface Values {
    id: number;
    key: string;
    name: string;
}

interface MapEntryToClassification<T extends Values> {
    displayName?: ((key: Classification) => string) | string;
    values: T[];
}

const mapEntryToClassification = <T extends Values>(args: MapEntryToClassification<T>): ClassificationAbstraction[] => {
    const { displayName = 'name', values } = args;

    return values.map((value) => ({
        displayName: typeof displayName === 'function' ? displayName(value) : value[displayName],
        id: value.id,
        key: value.key,
    }));
};

export { mapEntryToClassification };
