import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import type { FC } from 'react';
import React from 'react';

import type { ContextMenuItem } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import { ChangelogIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';

import { CONTEXT_MENU_ACTIONS } from './constants';
import type { VehicleTypeAdministrationContextMenuProps } from './models';

const VehicleTypeAdministrationContextMenu: FC<VehicleTypeAdministrationContextMenuProps> = (props) => {
    const { onClick, onClose, position } = props;
    const { t } = useTranslation();

    const getMenuItems: () => ContextMenuItem[] = () => [
        {
            icon: <EditIcon />,
            key: CONTEXT_MENU_ACTIONS.MODIFY,
            onClick: () => onClick(CONTEXT_MENU_ACTIONS.MODIFY),
            title: t('modify-action'),
        },
        {
            icon: <ChangelogIcon />,
            key: CONTEXT_MENU_ACTIONS.CHANGELOG,
            onClick: () => onClick(CONTEXT_MENU_ACTIONS.CHANGELOG),
            title: t('open-changelogs-subpage-action'),
        },
        {
            icon: <CancelIcon />,
            key: CONTEXT_MENU_ACTIONS.DELETE,
            onClick: () => onClick(CONTEXT_MENU_ACTIONS.DELETE),
            title: t('delete'),
        },
    ];

    return (
        <ContextMenu
            dataId="vehicle-types-context-menu"
            menuItems={getMenuItems()}
            onClose={onClose}
            position={position}
        />
    );
};

VehicleTypeAdministrationContextMenu.displayName = 'VehicleTypeAdministrationContextMenu';
export default VehicleTypeAdministrationContextMenu;
