import { CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmationDialog } from '~/components/Dialogs';
import { deleteAdminVehicle } from '~/data/vehicleadministration';
import { reportError } from '~/reporting';
import { logEvent } from '~/services/Analytics';

import type { DeleteVehicleDialogProps } from './models';
import { useStyles } from './styles';

export const DeleteVehicleDialogComponent: React.FC<DeleteVehicleDialogProps> = (props) => {
    const { onCancel, onRefreshData, vehicle } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [status, setStatus] = useState({ error: false, isLoading: false });

    const deleteVehicle = () => {
        if (!vehicle) {
            return;
        }

        setStatus({ error: false, isLoading: true });

        dispatch(deleteAdminVehicle(vehicle.id))
            .then(() => {
                setStatus({ error: false, isLoading: false });
                logEvent('Vehicle admin', 'delete-vehicle', 'Delete vehicle');
                onCancel();
                onRefreshData();
            })
            .catch((error) => {
                setStatus({ error: true, isLoading: false });
                reportError(error);
            });
    };

    return (
        <ConfirmationDialog
            confirmationActionText={t('delete')}
            dataId="delete-admin-vehicle-dialog"
            isDisabled={status?.isLoading}
            onCancel={onCancel}
            onConfirm={deleteVehicle}
            open
            title={t('delete')}
        >
            <>
                <Typography>{t('delete-admin-vehicle-content-text', { alias: vehicle?.settings?.alias })}</Typography>
                {status.error && (
                    <Typography color="error" data-id="delete-error-message" variant="caption">
                        {t('generic-error-message')}
                    </Typography>
                )}
                {status.isLoading && (
                    <div className={classes.messageContainer}>
                        <CircularProgress className={classes.spinner} size={30} />
                        <Typography className={classes.message} data-id="delete-progress-message" variant="subtitle2">
                            {t('delete-vehicle-progress-message')}
                        </Typography>
                    </div>
                )}
            </>
        </ConfirmationDialog>
    );
};

DeleteVehicleDialogComponent.displayName = 'DeleteVehicleDialog';
export default DeleteVehicleDialogComponent;
