import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type HeaderInfoClassKey = 'reeferPercentage' | 'reeferStatuses';

export const HeaderInfoStyleRules: StyleRulesCallback<Theme, {}, HeaderInfoClassKey> = (theme: Theme) =>
    createStyles({
        reeferPercentage: {
            marginTop: theme.spacing(0.125),
        },
        reeferStatuses: {
            '&>*': {
                marginLeft: theme.spacing(1),
            },
            display: 'flex',
            height: '20px',
        },
    });
