import type { LayerProps } from '@react-leaflet/core';
import { createElementObject, createLayerComponent } from '@react-leaflet/core';
import type { LeafletMaplibreGLOptions, MaplibreGL } from 'leaflet';
import { maplibreGL } from 'leaflet';
import '@maplibre/maplibre-gl-leaflet';

interface MaplibreGLLayerProps extends LayerProps {
    style: LeafletMaplibreGLOptions['style'];
}

const MaplibreGLLayer = createLayerComponent<MaplibreGL, MaplibreGLLayerProps>(
    (props, context) => {
        // create copy of the style as maplibre changes it internally
        const styleCopy = typeof props.style === 'string' ? props.style : { ...props.style };
        return createElementObject(maplibreGL({ ...props, style: styleCopy }), context);
    },
    (instance, props, prevProps) => {
        if (props.style !== prevProps.style) {
            instance.getMaplibreMap()?.setStyle(props.style);
        }
    }
);

export type { MaplibreGLLayerProps };
export { MaplibreGLLayer };
