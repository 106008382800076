import { useEffect } from 'react';

import { logEvent } from '~/services/Analytics';

export interface UseSceneAnalytics {
    options?: {
        reportDuration?: boolean;
        reportLoaded?: boolean;
    };
    scene: string;
}

const defaultOptions = {
    reportDuration: false,
    reportLoaded: true,
};

export const useSceneAnalytics = (props: UseSceneAnalytics) => {
    const { options, scene } = props;
    const theOptions = { ...defaultOptions, ...options };

    useEffect(() => {
        const start = new Date();

        if (theOptions.reportLoaded) {
            logEvent('Scenes', `loaded-${scene}`, `Loaded scene ${scene}`);
        }

        return () => {
            if (!theOptions.reportDuration) {
                return;
            }

            const duration = Math.round((start.getTime() - start.getTime()) / 1000);
            logEvent('Scenes', `showed-${scene}`, `Showed scene ${scene}`, {
                value: duration,
            });
        };
    }, [scene, theOptions.reportDuration, theOptions.reportLoaded]);
};
