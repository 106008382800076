import { formatDuration } from '@fv/components/formatters';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { mapDriverEvents } from '~/components/DriverActivitiesReportChart/mapDriverEvents';
import { ACTIVITY_TYPES_CATEGORIES_ORDER } from '~/data/driverActivitiesReport';
import ApexCharts from '~/libs/react-apexcharts';
import { isEmpty } from '~/libs/utility';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';
import { renderToStaticThemedMarkup } from '~/services/React';

import type { DisplayUserPreferences } from '../DisplayPreferences';
import { useUserPreferences } from '../UserPreferences';

import { ChartTooltip } from './components/ChartTooltip';
import type { DriverActivitiesReportChartProps } from './models';
import { useStyles } from './styles';
import { beforeResetZoom, beforeZoom } from './utils';

const DriverActivitiesReportChart: FC<DriverActivitiesReportChartProps> = (props) => {
    const { perspective, resolvedDriverEvents, selectHighlightedRow, selectRow } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [displayPreferences] = useUserPreferences<DisplayUserPreferences>(PredefinedUserPreferencesKeys.DISPLAY);
    const graphData = useMemo(() => mapDriverEvents({ resolvedDriverEvents, theme }), [theme, resolvedDriverEvents]);

    if (isEmpty(graphData)) {
        return (
            <Typography className={classes.noData} data-id="no-driver-activities">
                {t('no-driver-activities-graph-period')}
            </Typography>
        );
    }

    return (
        <ApexCharts
            height={350}
            options={{
                chart: {
                    events: {
                        beforeResetZoom,
                        beforeZoom,
                        dataPointMouseEnter: (_, __, options) => {
                            const { dataPointIndex, seriesIndex, w } = options;
                            selectHighlightedRow?.(
                                w.config.series[seriesIndex].data[dataPointIndex].meta.driverEventId
                            );
                        },
                        dataPointMouseLeave: () => {
                            selectHighlightedRow?.(undefined);
                        },
                        dataPointSelection: (_, __, options) => {
                            const { dataPointIndex, seriesIndex, w } = options;
                            selectRow?.(w.config.series[seriesIndex].data[dataPointIndex].meta.driverEventId);
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: (_, options) => {
                        const { config, dataPointIndex, seriesIndex } = options;
                        const currentSeries = config.series[seriesIndex].data[dataPointIndex];

                        return `${t(`driver-activity-${currentSeries.x}`)}: ${formatDuration({
                            value: currentSeries.meta.duration,
                        })}`;
                    },
                },
                plotOptions: { bar: { horizontal: true } },
                tooltip: {
                    cssClass: classes.tooltip,
                    custom: (options) => {
                        const { dataPointIndex, seriesIndex, w } = options;

                        return renderToStaticThemedMarkup(
                            theme,
                            <ChartTooltip
                                displayPreferences={displayPreferences}
                                payload={w.config.series[seriesIndex].data[dataPointIndex]}
                                perspective={perspective}
                            />
                        );
                    },
                },
                xaxis: {
                    categories: ACTIVITY_TYPES_CATEGORIES_ORDER,
                    labels: { datetimeUTC: false },
                    max: graphData[graphData.length - 1].y[1],
                    min: graphData[0].y[0],
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        formatter: (value: number | string) => {
                            return typeof value === 'string' ? t(`driver-activity-${value}`) : '';
                        },
                    },
                },
            }}
            series={[{ data: graphData }]}
            type="rangeBar"
        />
    );
};

DriverActivitiesReportChart.displayName = 'DriverActivitiesReportChart';
export { DriverActivitiesReportChart };
