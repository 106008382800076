import { Typography } from '@mui/material';
import * as React from 'react';

export interface HeaderInfoProps {
    distance: React.ReactNode;
    fuel: React.ReactNode;
}
export interface HeaderInfoInnerProps extends HeaderInfoProps {}

const HeaderInfo: React.FC<HeaderInfoInnerProps> = ({ distance, fuel }) => {
    return (
        <Typography color="textPrimary" variant="body2">
            <span data-id="distance">{distance}</span>
            {', '}
            <span data-id="fuel">{fuel}</span>
        </Typography>
    );
};
HeaderInfo.displayName = 'HeaderInfo';

export { HeaderInfo };
