import EditIcon from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router';

import { ProfileSubpageShell } from '~/common';
import { ChangelogIcon, VehicleTypesIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';
import { logEvent } from '~/services/Analytics';
import type { ResolvedVehicleType } from '~/services/ApiClient';
import { ClassificationType, EditorState } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { constructVehicleTypesAdministrationUrl } from '../../../../constructVehicleTypesAdministrationUrl';
import { Subpage } from '../../../../models';

import { useStyles } from './styles';

export interface HeaderSectionProps {
    adminVehicleType: ResolvedVehicleType;
    openEditorDialog: (operationMode: EditorState, entry: ResolvedVehicleType) => void;
}

export interface HeaderSectionInnerProps extends HeaderSectionProps {}

const HeaderSection: React.FunctionComponent<HeaderSectionInnerProps> = ({ adminVehicleType, openEditorDialog }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const openChangelogsSubpage = React.useCallback(() => {
        const changelogsSubpageUrl = constructVehicleTypesAdministrationUrl(adminVehicleType.id, Subpage.CHANGELOGS);
        logEvent('vehicle type admin', 'open-vehicle-type-change-log', 'Open vehicle type changelog');

        history.push(changelogsSubpageUrl);
    }, [adminVehicleType.id, history]);

    const primaryTitle = React.useMemo(() => {
        return adminVehicleType.name;
    }, [adminVehicleType.name]);

    const secondaryTitle = React.useMemo(() => {
        return adminVehicleType.type
            ? formatClassification(t, ClassificationType.VehicleTypeCategory, adminVehicleType.type)
            : '';
    }, [adminVehicleType.type, t]);

    const openEditorDialogForModification = () => {
        openEditorDialog(EditorState.MODIFY, adminVehicleType);
    };

    const buttons = (
        <div className={classes.buttons} data-id="vehicletype-admin-detailspane-header-buttons">
            <Tooltip title={t('modify-admin-vehicletype-button-tooltip')}>
                <Button
                    color="secondary"
                    data-id="modify-admin-vehicletype-button"
                    onClick={openEditorDialogForModification}
                >
                    <EditIcon className={classes.buttonIcon} />
                    {t('modify-action')}
                </Button>
            </Tooltip>
            <Tooltip title={t('modify-admin-vehicletype-button-tooltip')}>
                <Button
                    color="secondary"
                    data-id="open-changelogs-subpage"
                    onClick={() => {
                        openChangelogsSubpage();
                    }}
                >
                    <ChangelogIcon className={classes.buttonIcon} />
                    {t('open-changelogs-subpage-action')}
                </Button>
            </Tooltip>
        </div>
    );

    return (
        <>
            <ProfileSubpageShell
                icon={<VehicleTypesIcon className={classes.vehicleTypesIcon} />}
                primaryTitle={primaryTitle}
                secondaryTitle={secondaryTitle}
            />
            {buttons}
        </>
    );
};
HeaderSection.displayName = 'HeaderSection';

export { HeaderSection };
