import { useMemo } from 'react';

import { useUserPreferences } from '~/components/UserPreferences';
import { difference, groupBy, intersection, keyBy, mapValues } from '~/libs/utility';
import type { AssetReference } from '~/services/ApiClient';

import type {
    AssetReferenceHash,
    AssetReferenceWithDisplayName,
    SceneAssetSelectorDerivedProps,
    SceneAssetSelectorProps,
} from './models';
import type { SceneAssetSelectorUserPreferences, SceneAssetSelectorViewMode } from './preferences';
import {
    areAllEntitiesExpanded,
    assetReferenceDifference,
    ensureSelectedParentGroupId,
    ensureUnassignedDepot,
    ensureUnassignedGroup,
    generateAssetTree,
    getAssetGroupChildAssetIds,
    getDepotChildAssetIds,
    removeAssetsSelection,
    selectedAssetIdsBasedOnMode,
    selectedAssetSelector,
} from './services';

export interface EtherealState {
    searchEnabled: boolean;
    searchQuery?: string;
}

export const useDerivedAssetSelectorProps = (ownProps: SceneAssetSelectorProps): SceneAssetSelectorDerivedProps => {
    const [userPreferences, setUserPreferences] = useUserPreferences<SceneAssetSelectorUserPreferences>(
        ownProps.preferencesKey
    );

    const { singleSelectionMode } = ownProps;
    const {
        collapsedAssetTypes,
        expandedDepotIds,
        expandedGroupIds: expandedAssetGroupIds,
        viewMode,
    } = userPreferences;

    const { assetGroupIds, assetGroups, assetIds, assetIdsHash, assetsHash, depotIds, depots } = useMemo(() => {
        const localAssetIdsHash = ownProps.assetDataSets.reduce((acc, dataSet) => {
            acc[dataSet.type] = keyBy(
                dataSet.assets.map((a) => a.reference),
                'id'
            );
            return acc;
        }, {}) as AssetReferenceHash<AssetReference>;
        return {
            assetIds: ownProps.assetDataSets.reduce<AssetReference[]>((acc, dataSet) => {
                acc.push(...dataSet.assets.map((a) => a.reference));
                return acc;
            }, []),
            assetIdsHash: localAssetIdsHash,
            assetsHash: ownProps.assetDataSets.reduce((acc, dataSet) => {
                acc[dataSet.type] = keyBy(dataSet.assets, 'reference.id');
                return acc;
            }, {}) as AssetReferenceHash<AssetReferenceWithDisplayName>,
            ...ensureUnassignedDepot(ownProps.depots, localAssetIdsHash),
            ...ensureUnassignedGroup(ownProps.assetGroups, localAssetIdsHash),
        };
    }, [ownProps.assetDataSets, ownProps.depots, ownProps.assetGroups]);

    const selectedAssetIds = selectedAssetIdsBasedOnMode(
        selectedAssetSelector(ownProps.selectedAssetIds, userPreferences.selectedAssetIds, assetIdsHash),
        assetIds,
        singleSelectionMode
    );
    const selectedParentGroupId = ensureSelectedParentGroupId(
        assetGroups,
        assetIdsHash,
        selectedAssetIds,
        userPreferences.selectedParentGroupId,
        singleSelectionMode
    );

    const expandedAllGroups = areAllEntitiesExpanded(assetGroupIds, expandedAssetGroupIds);
    const expandedAllDepots = areAllEntitiesExpanded(depotIds, expandedDepotIds);

    const selectedAssetsHash = useMemo(
        () =>
            mapValues(groupBy(selectedAssetIds, 'type'), (v) =>
                keyBy(v, 'id')
            ) as unknown as AssetReferenceHash<AssetReference>,
        [selectedAssetIds]
    );
    const assetTree = generateAssetTree(
        viewMode,
        ownProps.assetDataSets,
        assetGroups,
        depots,
        assetIdsHash,
        assetsHash,
        expandedDepotIds,
        expandedAssetGroupIds,
        collapsedAssetTypes,
        selectedAssetsHash,
        selectedParentGroupId,
        expandedAllGroups,
        expandedAllDepots
    );

    const setSelectedAssets = (selectedAssets: AssetReference[], groupId?: number) => {
        setUserPreferences({
            selectedAssetIds: selectedAssets,
            selectedParentGroupId: singleSelectionMode ? groupId : undefined,
        });
    };

    const setSelectedGroups = (selectedGroups: number[]) => {
        setUserPreferences({
            selectedAssetIds: selectedGroups
                .map((groupId) => getAssetGroupChildAssetIds(assetGroups, groupId, assetIdsHash))
                .flat(),
        });
    };

    const setSelectedDepots = (selectedDepots: number[]) => {
        setUserPreferences({
            selectedAssetIds: selectedDepots
                .map((depotId) => getDepotChildAssetIds(depots, depotId, assetIdsHash))
                .flat(),
        });
    };
    const deselectAssets = (assetIdsToDeselect: AssetReference[]) => {
        const newSelectedAssetIds = removeAssetsSelection(selectedAssetIds, assetIdsToDeselect);
        setUserPreferences({
            selectedAssetIds: newSelectedAssetIds,
            selectedParentGroupId: undefined,
        });
        if (ownProps.onSelectedAssetIdsChange) {
            ownProps.onSelectedAssetIdsChange(newSelectedAssetIds);
        }
    };

    return {
        assetTree,
        changeViewMode: (value: SceneAssetSelectorViewMode) => setUserPreferences({ viewMode: value }),
        removeUnauthorizedExpandedDepots: () => {
            if (difference(expandedDepotIds, depotIds).length) {
                setUserPreferences({ expandedDepotIds: intersection(expandedDepotIds, depotIds) });
            }
        },
        removeUnauthorizedExpandedGroups: () => {
            if (difference(expandedAssetGroupIds, assetGroupIds).length) {
                setUserPreferences({ expandedGroupIds: intersection(expandedAssetGroupIds, assetGroupIds) });
            }
        },
        removeUnauthorizedSelectedAssets: () => {
            if (userPreferences.selectedAssetIds) {
                const unauthorizedAssets = assetReferenceDifference(userPreferences.selectedAssetIds, assetIdsHash);
                if (unauthorizedAssets.length) {
                    deselectAssets(unauthorizedAssets);
                }
            }
        },
        setCollapsedAssetTypes: (assetTypeIds) => setUserPreferences({ collapsedAssetTypes: assetTypeIds }),
        setExpandedDepots: (depotIdsList) => setUserPreferences({ expandedDepotIds: depotIdsList }),
        setExpandedGroups: (groupIds) => setUserPreferences({ expandedGroupIds: groupIds }),
        setSelectedAssets,
        setSelectedDepots,
        setSelectedGroups,
        viewMode,
    };
};
