import type { Moment } from 'moment';
import moment from 'moment';

import type { FilterDateTimeValue } from '../../../../models';
import { GridFilterOperation } from '../../../../models';

const createFilterValueForUnitOfTime = (value: Moment, unitOfTime: moment.unitOfTime.Base): FilterDateTimeValue => {
    const min = value.clone().startOf(unitOfTime);
    const max = min.clone().add(1, unitOfTime);
    return { max: max.toISOString(), min: min.toISOString() };
};

export const createFilterValue = (
    operation: GridFilterOperation,
    value: Moment | null
): FilterDateTimeValue | undefined => {
    if (!value) {
        return undefined;
    }

    switch (operation) {
        case GridFilterOperation.MonthEqual:
            return createFilterValueForUnitOfTime(value, 'month');
        case GridFilterOperation.YearEqual:
            return createFilterValueForUnitOfTime(value, 'year');
        default:
            return createFilterValueForUnitOfTime(value, 'minute');
    }
};

export const getInputValue = (
    value: FilterDateTimeValue | undefined,
    currentInputValue: Moment | null
): Moment | null => {
    if (!value) {
        return null;
    }

    const min = moment(value.min);
    const max = moment(value.max);

    if (currentInputValue && min <= currentInputValue && max > currentInputValue) {
        return currentInputValue;
    }

    return min;
};
