interface FormatToInputArgs<T> {
    options?: Partial<{ displayName: string; id: string; key: string }>;
    source: T;
}

const formatToSelect = <T>({ options, source }: FormatToInputArgs<T>) => {
    const { displayName, id, key } = { displayName: 'displayName', id: 'id', key: 'key', ...options };

    if (!Array.isArray(source)) {
        const toArray = Object.values(source as { [s: string]: T });

        return toArray.map((values) => ({
            displayName: values[displayName],
            id: values[id],
            key: String(values[key] || values[id]),
        }));
    }

    return source.map((values) => ({
        displayName: values[displayName],
        id: values[id],
        key: String(values[key] || values[id]),
    }));
};

export { formatToSelect };
