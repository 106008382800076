import { getI18n } from 'react-i18next';

import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

import { milesToYards } from './milesToYards';
import { mToKm } from './mToKm';
import { mToMiles } from './mToMiles';

interface AutoConvertDistanceArgs {
    unitSystem: UnitSystem;
    value: number;
}

const autoConvertDistance = (args: AutoConvertDistanceArgs) => {
    const { unitSystem, value } = args;
    const { t } = getI18n();
    switch (unitSystem) {
        case UNIT_SYSTEMS.METRIC: {
            if (value < 100) {
                return { unit: t('unit-m'), value: value.toFixed(0) };
            }

            const km = mToKm(value);
            const precision = km < 10 ? 1 : 0;
            return { unit: t('unit-km'), value: km.toFixed(precision) };
        }
        case UNIT_SYSTEMS.IMPERIAL: {
            const miles = mToMiles(value);

            if (miles < 0.1) {
                return { unit: t('unit-yd'), value: milesToYards(miles).toFixed(0) };
            }

            const precision = miles < 10 ? 1 : 0;
            return { unit: t('unit-mi'), value: miles.toFixed(precision) };
        }
        default:
            throw new Error('Unknown unit system.');
    }
};

export { autoConvertDistance };
export type { AutoConvertDistanceArgs };
