import { ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DistanceFormatter } from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';

import { Container } from '../styles';

import type { DistanceProps } from './model';

const Distance: FC<DistanceProps> = (props) => {
    const { avatar, distance, notAvailable, title, unitSystem } = props;
    const { t } = useTranslation();

    return (
        <Container data-testid="distance-container">
            <ListItemAvatar data-testid="avatar">{avatar}</ListItemAvatar>
            <ListItemText data-testid="title" primary={title} />
            <Typography color="textPrimary" data-testid="distance-content" variant="subtitle2">
                {isUndefined(distance) ? (
                    <span data-testid="not-available">{notAvailable}</span>
                ) : (
                    <DistanceFormatter t={t} unitSystem={unitSystem} value={distance} />
                )}
            </Typography>
        </Container>
    );
};

Distance.displayName = 'Distance';

export { Distance };
