import type { VehicleStatus } from '~/services/ApiClient';

export enum TooltipSize {
    Large = 'large',
    Small = 'small',
}

export interface FuelLevelGraphProps {
    /** Data for the graph */
    data: VehicleStatus[];
    /** Tooltip size */
    tooltipSize?: TooltipSize;
}
