import type { MapGatewayLayerProps, MapModes } from '@fv/components';
import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { MapLayersSecurables, Securables } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { getMapLayersProps } from './layers';
import type { MapUserPreferences } from './preferences';
import { MAP_USERPREFERENCES_KEY } from './preferences';

export interface MapStateProps {
    followSelectedAsset: boolean;
    mapLayersProps: MapGatewayLayerProps;
    mapLayersSecurables: MapLayersSecurables;
    mapMode: MapModes;
    markerClusterRadius: number;
    trafficIncidentsOverlayEnabled: boolean;
    truckAttributesOverlayEnabled: boolean;
}

export interface MapDispatchProps {
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    changeMapMode: (mapMode: MapModes) => void;
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void;
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void;
}

export interface MapReduxProps extends MapStateProps {
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    changeMapMode: (mapMode: MapModes) => void;
    toggleTrafficIncidentsOverlayEnabled: () => void;
    toggleTruckAttributesOverlayEnabled: () => void;
}

export const mapStateToProps = (
    {
        followSelectedAsset,
        mapMode,
        markerClusterRadius,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
    }: MapUserPreferences,
    securables: Securables
): MapStateProps => {
    return {
        followSelectedAsset,
        mapLayersProps: getMapLayersProps({
            mapLayersSecurables: securables.mapLayers,
            mapMode,
            trafficIncidentsOverlayEnabled,
            truckAttributesOverlayEnabled,
        }),
        mapLayersSecurables: securables.mapLayers,
        mapMode,
        markerClusterRadius,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): MapDispatchProps => ({
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { followSelectedAsset }));
    },
    changeMapMode: (mapMode: MapModes) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { mapMode }));
    },
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { trafficIncidentsOverlayEnabled }));
    },
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { truckAttributesOverlayEnabled }));
    },
});

const toggleTruckAttributesOverlayEnabledMemoized = memoizeOne(
    (
        truckAttributesOverlayEnabled: boolean | undefined,
        changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTruckAttributesOverlayEnabled(!truckAttributesOverlayEnabled);
        }
);
const toggleTrafficIncidentsOverlayEnabledMemoized = memoizeOne(
    (
        trafficIncidentsOverlayEnabled: boolean | undefined,
        changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTrafficIncidentsOverlayEnabled(!trafficIncidentsOverlayEnabled);
        }
);

export const mergeProps = (
    stateProps: MapStateProps,
    {
        changeTrafficIncidentsOverlayEnabled,
        changeTruckAttributesOverlayEnabled,
        ...restDispatchProps
    }: MapDispatchProps,
    ownProps: object
): MapReduxProps => ({
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    toggleTrafficIncidentsOverlayEnabled: toggleTrafficIncidentsOverlayEnabledMemoized(
        stateProps.trafficIncidentsOverlayEnabled,
        changeTrafficIncidentsOverlayEnabled
    ),
    toggleTruckAttributesOverlayEnabled: toggleTruckAttributesOverlayEnabledMemoized(
        stateProps.truckAttributesOverlayEnabled,
        changeTruckAttributesOverlayEnabled
    ),
});
