import type { Marker as LeafletMarker } from 'leaflet';
import { divIcon } from 'leaflet';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Marker } from 'react-leaflet';

import type { JSXMarkerProps } from './models';

const JSXMarker: FC<JSXMarkerProps> = (props) => {
    const { children, iconOptions, popup, position, ...rest } = props;

    const ref = useRef<LeafletMarker | null>();
    const [element, setElement] = useState<HTMLElement | undefined>();

    useEffect(() => {
        ref.current?.setLatLng(position);
    }, [position]);

    return (
        <>
            {useMemo(
                () => (
                    <Marker
                        {...rest}
                        icon={divIcon(iconOptions)}
                        position={position}
                        ref={(r) => {
                            ref.current = r;
                            r?.on('add', () => {
                                setElement(r.getElement());
                            });

                            r?.on('remove', () => {
                                setElement(undefined);
                            });
                        }}
                    >
                        {popup?.content}
                    </Marker>
                ),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [popup?.key]
            )}

            {element && createPortal(children, element)}
        </>
    );
};

JSXMarker.displayName = 'JSXMarker';
export { JSXMarker };
