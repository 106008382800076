import type { Address } from '~/common';
import { isUndefined } from '~/libs/utility';
import type { AddressFormatOptions } from '~/services/Formatters';
import { formatAddress } from '~/services/Formatters';

export interface AddressFormatterProps {
    options?: AddressFormatOptions;
    undefinedValueMessage?: string;
    value?: Address;
}

export interface AddressFormatterInnerProps extends AddressFormatterProps {}

const AddressFormatter: React.FunctionComponent<AddressFormatterInnerProps> = ({
    options,
    undefinedValueMessage,
    value,
    // eslint-disable-next-line react/jsx-no-useless-fragment
}) => <>{isUndefined(value) ? undefinedValueMessage : formatAddress(value, options)}</>;
AddressFormatter.displayName = 'AddressFormatter';

export { AddressFormatter };
