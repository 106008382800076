import { isUndefined } from '@fv/components/utility';
import classNames from 'classnames';
import type { FC } from 'react';

import type { DurationFormatterProps } from './models';
import { useStyles } from './styles';
import { formatDuration, isViolation } from './utils';

const DurationFormatter: FC<DurationFormatterProps> = (props) => {
    const { options, threshold, value, violationType } = props;
    const classes = useStyles();

    if (isUndefined(value)) {
        return null;
    }

    return (
        <span
            className={classNames(classes.root, {
                [classes.violation]:
                    !isUndefined(threshold) && violationType && isViolation({ threshold, value, violationType }),
            })}
        >
            {formatDuration({ options, value })}
        </span>
    );
};

DurationFormatter.displayName = 'DurationFormatter';
export { DurationFormatter };
