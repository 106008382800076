import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type HeaderSectionClassKey = 'buttonIcon' | 'buttons' | 'vehicleTypesIcon';

export const useStyles = makeStyles<Theme, {}, HeaderSectionClassKey>((theme) => ({
    buttonIcon: {
        marginRight: theme.spacing(),
    },
    buttons: {
        display: 'block',
        margin: `auto ${theme.spacing()}`,
        paddingBottom: theme.spacing(1.75),
        paddingTop: theme.spacing(1.75),
    },
    vehicleTypesIcon: {
        color: theme.functionalItemsColors.assetType.vehicle.value,
    },
}));
