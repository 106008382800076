import classNames from 'classnames';
import * as React from 'react';

import { Severity } from './models';
import { useStyles } from './styles';

export interface ValidationBoxProps {
    message: string;
    severity: Severity;
}

export interface ValidationBoxInnerProps extends ValidationBoxProps {}

const ValidationBox: React.FunctionComponent<ValidationBoxInnerProps> = ({ message, severity }) => {
    const classes = useStyles();

    const className = classNames(classes.root, {
        [classes.error]: severity === Severity.Error,
        [classes.warning]: severity === Severity.Warning,
    });

    return (
        <div className={className} data-id="validation-box">
            {message}
        </div>
    );
};
ValidationBox.displayName = 'ValidationBox';

export { ValidationBox };
