import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DateTimeEditorClassKey = 'clear' | 'input' | 'toolbarIcon';

export const useStyles = makeStyles<Theme, {}, DateTimeEditorClassKey>((theme) => ({
    clear: { color: theme.palette.text.secondary, cursor: 'pointer' },
    input: { fontSize: 14 },
    toolbarIcon: { color: theme.palette.primary.contrastText },
}));
