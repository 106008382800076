import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import type { ChangelogAttributeDefinition } from '~/common/components/ChangelogsSubpage';
import {
    AltitudeFormatter,
    BooleanFormatter,
    ClassificationFormatter,
    SpeedFormatter,
    WeightFormatter,
} from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { DisplayUserPreferencesUnitSystem, ResolvedVehicleTypeChangelogChanges } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

export const getVehicleTypeChangelogAttributeDefinitions = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem
): ChangelogAttributeDefinition<ResolvedVehicleTypeChangelogChanges>[] => {
    const withUnitSystem = defaultProps({ unitSystem });

    return [
        {
            getValue: (changelogChanges) => changelogChanges.name,
            title: t('name'),
        },
        {
            getValue: (changelogChanges) => changelogChanges.description,
            title: t('description'),
        },
        {
            getValue: (changelogChanges) => changelogChanges.type,
            title: t('type'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.VehicleTypeCategory, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges.dimensions?.width,
            title: t('width'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.dimensions?.height,
            title: t('height'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.dimensions?.length,
            title: t('length'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.weight,
            title: t('weight'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.hazardousGoods,
            title: t('hazardous-goods'),
            valueFormatterComponent: compose(withTranslation)(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.axles,
            title: t('axles'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.Axles, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges.emissionClass,
            title: t('emission-class'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.EmissionClass, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges.speedLimit?.motorway,
            title: t('speed-limit-motorway'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.speedLimit?.road,
            title: t('speed-limit-road'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges.speedLimit?.city,
            title: t('speed-limit-city'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
        },
    ];
};
