import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { vehiclesHashSelector } from '~/common';
import { defaultDisplayUserPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import { CONVERSATION_USERPREFERENCES_KEY } from '../../preferences';
import { conversationsSummaryWidgetStoreStateSelector } from '../../selectors';
import { ensureUserMessagingProfiles } from '../EnsureUserMessagingProfiles';

import type { ConversationsSummaryWidgetInnerProps, ConversationsSummaryWidgetProps } from './component';
import { ConversationsSummaryWidgetComponent } from './component';
import {
    CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY,
    requiredConversationsSummaryWidgetUserPreferences,
} from './preferences';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';
import { ConversationsSummaryWidgetStyleRules } from './styles';

export {
    CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY,
    requiredConversationsSummaryWidgetUserPreferences,
} from './preferences';

export type { ConversationsSummaryWidgetUserPreferences } from './preferences';

export * from './reducers';

export const ConversationsSummaryWidgetFactory = (
    assetSelectorPreferencesKey: string
): React.ComponentType<ConversationsSummaryWidgetProps> => {
    return compose<ConversationsSummaryWidgetInnerProps, ConversationsSummaryWidgetProps>(
        setDisplayName('ConversationsSummaryWidget'),
        ensureSettings([SettingsKey.SECURABLES]),
        ensureUserPreferences(
            [
                requiredConversationsSummaryWidgetUserPreferences,
                {
                    defaultState: defaultDisplayUserPreferences,
                    key: PredefinedUserPreferencesKeys.DISPLAY,
                },
                requiredSceneAssetSelectorUserPreferencesFactory(assetSelectorPreferencesKey),
            ],
            false
        ),
        ensureUserMessagingProfiles(),
        connect(
            createSelector(
                vehiclesHashSelector,
                conversationsSummaryWidgetStoreStateSelector,
                keyDataStateSelectorFactory(CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY),
                keyDataStateSelectorFactory(PredefinedUserPreferencesKeys.DISPLAY),
                keyDataStateSelectorFactory(assetSelectorPreferencesKey),
                keyDataStateSelectorFactory(CONVERSATION_USERPREFERENCES_KEY),
                settingDataSelector(SettingsKey.SECURABLES),
                settingDataSelector(SettingsKey.VEHICLE_MESSAGING_CAPABILITIES),
                mapStateToProps
            ),
            mapDispatchToProps,
            mergeProps
        ),
        withTranslation(),
        withStyles(ConversationsSummaryWidgetStyleRules)
    )(ConversationsSummaryWidgetComponent);
};
