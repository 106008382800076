import type { Dispatch } from 'redux';

import type { Securables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './component';
import { closeOverlayMenuAction, openOverlayMenuAction } from './data';
import type { MenuRootStoreState } from './reducers';

export const mapStateToProps = (menuRootStateProps: MenuRootStoreState, securables: Securables): StateProps => ({
    isOverlayMenuOpen: menuRootStateProps.isOverlayMenuOpen,
    securables,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    closeMenuAction: () => dispatch(closeOverlayMenuAction()),
    dispatch,
    openMenuAction: () => dispatch(openOverlayMenuAction()),
});
