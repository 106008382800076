/* eslint-disable react/no-unstable-nested-components */
import Refresh from '@mui/icons-material/Refresh';
import { Button, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';

import type { DriverEventFieldsSectionInnerProps } from './models';
import { useStyles } from './styles';

export const DriverEventFieldsSectionComponent: FC<DriverEventFieldsSectionInnerProps> = (props) => {
    const {
        dragHandleElement,
        driverEventSource,
        failedToLoadFields,
        fields,
        isCollapsed,
        loadEventFields,
        loadingFields,
        t,
        toggleCollapsed,
    } = props;

    const classes = useStyles();

    useEffect(() => {
        loadEventFields();
    }, [loadEventFields]);

    const getContent = useMemo(
        () => (): JSX.Element => {
            if (loadingFields) {
                return (
                    <div
                        className={classNames(classes.paperBackground, classes.centerContents)}
                        data-id="loading-fields"
                    >
                        <CircularProgress size={32} />
                    </div>
                );
            }

            if (failedToLoadFields) {
                return (
                    <div className={classNames(classes.centerContents, classes.failedToLoad)}>
                        <div className={classes.root}>
                            <Typography
                                className={classes.retryMessage}
                                data-id="event-fields-could-not-be-retrieved"
                                variant="caption"
                            >
                                {t('event-fields-could-not-be-retrieved')}
                            </Typography>

                            <Button
                                color="secondary"
                                data-id="retry-load-event-fields"
                                onClick={loadEventFields}
                                variant="contained"
                            >
                                <Refresh />
                                {t('retry')}
                            </Button>
                        </div>
                    </div>
                );
            }

            if (!fields.length) {
                return (
                    <div className={classes.centerContents} data-id="no-event-fields">
                        <Typography className={classes.italicText} variant="caption">
                            {t('no-event-fields')}
                        </Typography>
                    </div>
                );
            }

            const keyValueItems: KeyValueItem[] = !isUndefined(fields)
                ? fields.map((it) => {
                      return {
                          label: it.field.name,
                          value: <span>{it.value}</span>,
                          valueDataId: `${driverEventSource}-field:${it.field.id}`,
                      };
                  })
                : [];

            return <KeyValueList list={keyValueItems} listDataId="field-values-list" />;
        },
        [
            classes.centerContents,
            classes.failedToLoad,
            classes.italicText,
            classes.paperBackground,
            classes.retryMessage,
            classes.root,
            driverEventSource,
            failedToLoadFields,
            fields,
            loadEventFields,
            loadingFields,
            t,
        ]
    );

    return (
        <ReorderableSection
            dataId="activity-field"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('activity-fields')}
            toggleCollapsed={toggleCollapsed}
        >
            {getContent()}
        </ReorderableSection>
    );
};
