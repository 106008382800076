import { Table } from '@devexpress/dx-react-grid-material-ui';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';

import type { CustomTableRowProps } from './models';
import { useStyles } from './styles';

const TableRow: FC<CustomTableRowProps> = (props) => {
    const { highlightedRowId, onRowContextMenu, selectedRowRef, selectRow, ...rowProps } = props;

    const classes = useStyles();

    return (
        <Table.Row
            {...rowProps}
            className={`${
                selectedRowRef.current === rowProps.tableRow.rowId
                    ? classes.selected
                    : highlightedRowId === rowProps.tableRow.rowId
                      ? classes.highlighted
                      : classes.initial
            }`}
            onClick={() => {
                selectedRowRef.current = rowProps.tableRow.rowId;
                if (selectRow && !isUndefined(rowProps.tableRow.rowId)) {
                    selectRow(rowProps.tableRow.rowId);
                }
            }}
            onContextMenu={(event: MouseEvent) => {
                if (!isUndefined(rowProps.tableRow.rowId) && onRowContextMenu) {
                    onRowContextMenu(event, rowProps.tableRow.rowId);
                }
            }}
            {...(highlightedRowId === rowProps.tableRow.rowId && { 'data-id': 'highlighted' })}
        />
    );
};

TableRow.displayName = 'TableRow';
export default TableRow;
