import { forEach } from '~/libs/utility';
import type { AssetReference } from '~/services/ApiClient';
import { AssetGroup, AssetType, Depot, createApiModel } from '~/services/ApiClient';

import { unassignedDepotId, unassignedGroupId } from '../constants';
import type { AssetReferenceHash } from '../models';

export const assetTypeIdsMap: { [key in AssetType]: Depot | keyof AssetGroup } = {
    [AssetType.Container]: 'containerIds',
    [AssetType.Driver]: 'driverIds',
    [AssetType.Trailer]: 'trailerIds',
    [AssetType.Vehicle]: 'vehicleIds',
};

export const createUnassignedGroup = (assetIds: AssetReferenceHash<AssetReference>): AssetGroup => {
    const data = {
        allowDrivers: true,
        allowTrailers: true,
        allowVehicles: true,
        id: unassignedGroupId,
        name: 'unassigned',
    };

    forEach(assetIds, (assetTypeDict, assetType) => {
        if (assetTypeDict) {
            data[assetTypeIdsMap[assetType]] = Object.values(assetTypeDict).map((x) => x.id);
        }
    });

    return createApiModel(AssetGroup, data);
};

export const createUnassignedDepot = (assetIds: AssetReferenceHash<AssetReference>): Depot => {
    const data = {
        id: unassignedDepotId,
        name: 'unassigned',
    };

    forEach(assetIds, (assetTypeDict, assetType) => {
        if (assetTypeDict) {
            data[assetTypeIdsMap[assetType]] = Object.values(assetTypeDict).map((x) => x.id);
        }
    });

    return createApiModel(Depot, data);
};
