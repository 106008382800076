import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import type { EnsureAuthorizationProps } from './models';
import { ensureAuthorizationSelector } from './selectors';

const EnsureAuthorization: FC<EnsureAuthorizationProps> = (props) => {
    const { children, requiredFeature, securablesPredicate } = props;

    const { enabledFeatures, securables } = useSelector(ensureAuthorizationSelector);

    if (
        (requiredFeature && !enabledFeatures.includes(requiredFeature)) ||
        (securablesPredicate && !securablesPredicate(securables))
    ) {
        return <Redirect to="/unauthorized" />;
    }

    return children;
};

EnsureAuthorization.displayName = 'EnsureAuthorization';
export { EnsureAuthorization };
