import type { MapGatewayLayerProps } from '@fv/components';
import { MAP_MODES, MapContext } from '@fv/components';
import type { StyleSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { FC, PropsWithChildren } from 'react';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { useLanguageCode } from '~/components/LanguageSelector';
import type { Dictionary } from '~/libs/utility';
import { MapMode, getMapGatewayClient } from '~/services/MapGatewayClient';

const MapContextProviderWrapper: FC<PropsWithChildren<{}>> = (props) => {
    const securables = useSelector(settingDataSelector(SettingsKey.SECURABLES));
    const languageCode = useLanguageCode();
    const cachedItems = useRef<Dictionary<Promise<StyleSpecification>>>({});

    const value = useMemo(() => {
        const availableModes = [
            MAP_MODES.ROAD,
            ...(securables.mapLayers.satelliteAllowed ? [MAP_MODES.HYBRID, MAP_MODES.SATELLITE] : []),
        ];

        const getMaplibreStyle = (args: MapGatewayLayerProps) => {
            const client = getMapGatewayClient();
            const key = `${client.getJwt()}-${languageCode}-${args.mapMode}-${args.includeTruckAttributes}-${
                args.includeTrafficIncidents
            }`;
            let style = cachedItems.current[key];
            if (!style) {
                style = client
                    .getMaplibreStyle(
                        args.mapMode === MAP_MODES.SATELLITE
                            ? MapMode.Satellite
                            : args.mapMode === MAP_MODES.HYBRID
                              ? MapMode.Hybrid
                              : MapMode.Road,
                        args.includeTruckAttributes,
                        args.includeTrafficIncidents,
                        languageCode
                    )
                    .then(
                        (res) =>
                            new Promise<StyleSpecification>((resolve, reject) => {
                                const fileReader = new FileReader();

                                fileReader.addEventListener(
                                    'load',
                                    () => {
                                        if (typeof fileReader.result === 'string') {
                                            // Guarantee the cached item is deep frozen, so nobody can change it
                                            const serializedStyleResponse = JSON.parse(fileReader.result, (_, v) =>
                                                Object.freeze(v)
                                            );
                                            resolve(serializedStyleResponse);
                                        } else {
                                            reject(
                                                new Error(
                                                    `Expected fileReader to return string, but was ${typeof fileReader.result}`
                                                )
                                            );
                                        }
                                    },
                                    false
                                );

                                fileReader.readAsText(res.data);
                            })
                    );

                cachedItems.current[key] = style;
            }

            return style;
        };

        return { availableModes, getMaplibreStyle };
    }, [securables, languageCode]);

    return <MapContext.Provider value={value} {...props} />;
};

MapContextProviderWrapper.displayName = 'MapContextProviderWrapper';
export { MapContextProviderWrapper };
