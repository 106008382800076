import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import { useTranslation } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { EditorState, ResolvedVehicleType } from '~/services/ApiClient';

import { SectionName } from './constants';
import type { VehicleTypesAdministrationDetailsPaneUserPreferences } from './preferences';
import { VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface VehicleTypeAdministrationDetailsPaneProps {
    entry: ResolvedVehicleType;
    onClose: () => void;
    openEditorDialog: (operationMode: EditorState, entry: ResolvedVehicleType) => void;
    // openChangelog: (vehicleTypeId?: number) => void;
}

export interface VehicleTypeAdministrationDetailsPaneInnerProps extends VehicleTypeAdministrationDetailsPaneProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    ResolvedVehicleType,
    VehicleTypesAdministrationDetailsPaneUserPreferences,
    SectionName
>(VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY);

export const VehicleTypeAdministrationDetailsPaneComponent: React.FC<
    VehicleTypeAdministrationDetailsPaneInnerProps
> = ({ entry, onClose, openEditorDialog }) => {
    const { t } = useTranslation();

    const header = React.useMemo(
        () => <DetailsPaneHeader onClose={onClose} title={t('vehicle-types-administration-details-pane-header')} />,
        [onClose, t]
    );

    const getSections = React.useCallback(
        (
            userPreferences: VehicleTypesAdministrationDetailsPaneUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized({
                collapsedSections: userPreferences.collapsedSections,
                entry,
                openEditorDialog,
                toggleCollapsedState,
            }),
        [entry, openEditorDialog]
    );

    return (
        <DetailsPaneComponent
            alwaysFirstSection={SectionName.VEHICLETYPESADMINISTRATION_HEADER}
            dataId="vehicle-types-administration-details-pane"
            entry={entry}
            getSections={getSections}
            HeaderReactNode={header}
        />
    );
};
