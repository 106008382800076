import type { AssetType } from '~/services/ApiClient';

import type { AssetNode, AssetTypeNodeState } from '../models';

export const mapAssetTypeNodeState = (
    assetType: AssetType,
    collapsedAssetTypes: AssetType[],
    children: AssetNode[]
): AssetTypeNodeState => {
    const expanded = !collapsedAssetTypes.includes(assetType);
    const checkedChildren = children.filter((c) => c.state.checked);
    const hasAssetChildren = !!children.length;

    const indeterminate = hasAssetChildren && children.length !== checkedChildren.length && checkedChildren.length > 0;
    const checked = hasAssetChildren && checkedChildren.length > 0 && !indeterminate;

    return {
        assetsCount: children.length,
        checked,
        expanded,
        hasAssetChildren,
        indeterminate,
        selectedAssetsCount: checkedChildren.length,
    };
};
