import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import type { RequiredUserPreferences } from '~/components/UserPreferences';

import { SectionName } from './constants';

export interface TextMessageDetailsUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const defaultTextMessageDetailsUserPreferences: TextMessageDetailsUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MESSAGEINFO, SectionName.MINIMAP, SectionName.LOCATION, SectionName.METADATA],
};

export const requiredMessageDetailsUserPreferencesFactory = (key: string): RequiredUserPreferences => ({
    defaultState: defaultTextMessageDetailsUserPreferences,
    key,
});
