import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SpeedFormatter } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { useTranslation } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedVehicleType } from '~/services/ApiClient';

import { SectionName } from '../../constants';

export interface SpeedLimitsSectionProps {
    adminVehicleType: ResolvedVehicleType;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface SpeedLimitsSectionInnerProps extends SpeedLimitsSectionProps, InjectedDisplayPreferencesProps {}

export const SpeedLimitsSectionComponent: React.FunctionComponent<SpeedLimitsSectionInnerProps> = ({
    adminVehicleType,
    displayPreferences,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const { t } = useTranslation();
    const { unitSystem } = displayPreferences;

    const keyValueItems: KeyValueItem[] = [
        {
            label: t('speed-limit-motorway'),
            value: adminVehicleType.speedLimit?.motorway ? (
                <SpeedFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.speedLimit?.motorway} />
            ) : undefined,
            valueDataId: 'speed-limit-motorway',
        },
        {
            label: t('speed-limit-road'),
            value: adminVehicleType.speedLimit?.road ? (
                <SpeedFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.speedLimit?.road} />
            ) : undefined,
            valueDataId: 'speed-limit-road',
        },
        {
            label: t('speed-limit-city'),
            value: adminVehicleType.speedLimit?.city ? (
                <SpeedFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.speedLimit?.city} />
            ) : undefined,
            valueDataId: 'speed-limit-city',
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-administration-speed-limits-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.VEHICLETYPESADMINISTRATION_SPEEDLIMITS)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="speedlimits-list" />
        </ReorderableSection>
    );
};
