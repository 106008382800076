import chunk from 'lodash/chunk';
import clone from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import escapeRegExp from 'lodash/escapeRegExp';
import every from 'lodash/every';
import fill from 'lodash/fill';
import findLastIndex from 'lodash/findLastIndex';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import pull from 'lodash/pull';
import random from 'lodash/random';
import range from 'lodash/range';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import without from 'lodash/without';
import { v4 as uuidv4 } from 'uuid';

const genericIsEqual: <T>(value: T, other: T) => boolean = isEqual;

export type { DebouncedFunc, Dictionary, NumericDictionary } from 'lodash';
export {
    chunk,
    clone,
    debounce,
    difference,
    differenceWith,
    escapeRegExp,
    every,
    fill,
    findLastIndex,
    flatten,
    flattenDeep,
    forEach,
    genericIsEqual as isEqual,
    get,
    groupBy,
    intersection,
    isEmpty,
    isNaN,
    isNil,
    isNull,
    isNumber,
    isObject,
    isUndefined,
    keyBy,
    map,
    mapValues,
    memoize,
    merge,
    omit,
    orderBy,
    pick,
    pull,
    random,
    range,
    remove,
    sortBy,
    throttle,
    uniq,
    uniqBy,
    uuidv4,
    values,
    without,
};
