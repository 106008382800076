import type { Classification } from '~/services/ApiClient';
import { RouteStatusKeys, ViolationType } from '~/services/ApiClient';

import { ColorVariants } from './colorVariants';

export function mapViolationTypeToColorVariant(violationType: undefined | ViolationType): ColorVariants {
    switch (violationType) {
        case ViolationType.None:
            return ColorVariants.Normal;
        case ViolationType.Violation:
            return ColorVariants.Error;
        case ViolationType.Warning:
            return ColorVariants.Warning;
        default:
            return ColorVariants.Normal;
    }
}

export function mapRouteStatusToColorVariant(routeStatus: Classification | undefined): ColorVariants {
    switch (routeStatus?.key) {
        case RouteStatusKeys.OUTOFCORRIDOR:
            return ColorVariants.Error;
        case RouteStatusKeys.BACKINCORRIDOR:
            return ColorVariants.Warning;
        case RouteStatusKeys.ARRIVEDATDESTINATION:
            return ColorVariants.Success;
        case RouteStatusKeys.STARTNAVIGATING:
            return ColorVariants.Normal;
        case RouteStatusKeys.NOTNAVIGATING:
            return ColorVariants.Disabled;
        default:
            return ColorVariants.Normal;
    }
}
