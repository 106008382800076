import type { LatestMessagesSectionStoreState } from '~/data/monitoring';
import { omit } from '~/libs/utility';
import { resolveConversationMessage, resolveConversationUpdate } from '~/modules/Communication';
import type { RetrievableData } from '~/reducers';
import type { ResolvedMessage } from '~/services/ApiClient';
import { mergeConversationUpdates } from '~/services/Conversations';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';
import { applyConversationUpdate } from './reducers.applyConversationUpdate';

// Use undefined form definitions for now, the last messages section doesn't support workflow messages
const defaultWorkflowFormDefinitions = undefined;

export const defaultLatestMessagesState: RetrievableData<ResolvedMessage[] | undefined> = {
    data: undefined,
    fulfilled: false,
    pending: false,
    rejected: false,
};

export const defaultLatestMessageSectionStoreState: LatestMessagesSectionStoreState = {
    deletedMessageIds: {},
    latestMessages: {},
    pendingConversationsUpdates: {},
};

export const latestMessagesSectionReducer = (
    state: LatestMessagesSectionStoreState = defaultLatestMessageSectionStoreState,
    action: ActionTypes
): LatestMessagesSectionStoreState => {
    switch (action.type) {
        case ActionTypeKeys.LATESTMESSAGES_PENDING: {
            const actualLatestMessagesState = state.latestMessages[action.meta];

            return {
                ...state,
                latestMessages: {
                    ...state.latestMessages,
                    [action.meta]: {
                        ...defaultLatestMessagesState,
                        ...actualLatestMessagesState,
                        pending: true,
                    },
                },
            };
        }

        case ActionTypeKeys.LATESTMESSAGES_FULFILLED: {
            const actualLatestMessagesState = state.latestMessages[action.meta];

            if (!actualLatestMessagesState) {
                return state;
            }

            const resolvedMessages = (action.payload?.items || []).map((msg) =>
                resolveConversationMessage(msg, defaultWorkflowFormDefinitions)
            );

            return {
                ...state,
                deletedMessageIds: omit(state.deletedMessageIds, [action.meta]),
                latestMessages: {
                    ...state.latestMessages,
                    [action.meta]: {
                        data: state.pendingConversationsUpdates[action.meta]
                            ? applyConversationUpdate(
                                  resolvedMessages,
                                  resolveConversationUpdate(
                                      state.pendingConversationsUpdates[action.meta],
                                      defaultWorkflowFormDefinitions
                                  )
                              )
                            : resolvedMessages,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
                pendingConversationsUpdates: omit(state.pendingConversationsUpdates, [action.meta]),
            };
        }

        case ActionTypeKeys.LATESTMESSAGES_REJECTED: {
            const actualLatestMessagesState = state.latestMessages[action.meta];

            if (!actualLatestMessagesState) {
                return state;
            }

            return {
                ...state,
                latestMessages: {
                    ...state.latestMessages,
                    [action.meta]: {
                        data: undefined,
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        }

        case ActionTypeKeys.LATESTMESSAGES_UPDATE: {
            const actualPendingConversationUpdateState = state.pendingConversationsUpdates[action.meta];

            return {
                ...state,
                pendingConversationsUpdates: {
                    ...state.pendingConversationsUpdates,
                    [action.meta]: actualPendingConversationUpdateState
                        ? mergeConversationUpdates(actualPendingConversationUpdateState, action.payload.data)
                        : action.payload.data,
                },
            };
        }

        case ActionTypeKeys.LATESTMESSAGES_APPLY_PENDING: {
            const actualLatestMessagesState = state.latestMessages[action.meta];
            const actualPendingConversationUpdateState = state.pendingConversationsUpdates[action.meta];

            if (
                actualPendingConversationUpdateState &&
                actualLatestMessagesState &&
                actualLatestMessagesState.data &&
                actualLatestMessagesState.fulfilled &&
                !actualLatestMessagesState.pending
            ) {
                return {
                    ...state,
                    deletedMessageIds: {
                        ...state.deletedMessageIds,
                        [action.meta]: [
                            ...(state.deletedMessageIds[action.meta] || []),
                            ...actualPendingConversationUpdateState.deletedMessages,
                        ],
                    },
                    latestMessages: {
                        ...state.latestMessages,
                        [action.meta]: {
                            ...actualLatestMessagesState,
                            data: applyConversationUpdate(
                                actualLatestMessagesState.data,
                                resolveConversationUpdate(
                                    actualPendingConversationUpdateState,
                                    defaultWorkflowFormDefinitions
                                )
                            ),
                        },
                    },
                    pendingConversationsUpdates: omit(state.pendingConversationsUpdates, [action.meta]),
                };
            }

            return state;
        }

        case ActionTypeKeys.LATESTMESSAGES_CLEAR:
            return {
                ...state,
                deletedMessageIds: omit(state.deletedMessageIds, [action.meta]),
                latestMessages: omit(state.latestMessages, [action.meta]),
                pendingConversationsUpdates: omit(state.pendingConversationsUpdates, [action.meta]),
            };

        default:
            return state;
    }
};
