import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
    activityAvailable: {
        backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
        color: theme.functionalItemsColors.driverActivity.available.contrast,
    },
    activityAvatar: {
        alignSelf: 'flex-end',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 14,
        height: 22,
        marginLeft: theme.spacing(-1),
        width: 22,
    },
    activityDrive: {
        backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
        color: theme.functionalItemsColors.driverActivity.drive.contrast,
    },
    activityRest: {
        backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
        color: theme.functionalItemsColors.driverActivity.rest.contrast,
    },
    activityWork: {
        backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
        color: theme.functionalItemsColors.driverActivity.work.contrast,
    },
    coDriver: {
        display: 'flex',
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    disabled: {
        opacity: 0.38,
    },
    driver: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    driverAvatar: {
        backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
        border: `1px solid ${theme.palette.text.secondary}`,
        color: theme.functionalItemsColors.assetType.driver.value,
        fontSize: 18,
        height: 24,
        width: 24,
    },
    driverIcon: {
        fontSize: 14,
    },
    flex: {
        display: 'flex',
    },
}));
