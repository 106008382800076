import Room from '@mui/icons-material/Room';
import { Avatar, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { useReverseGeocodedAddress } from '~/common';
import { CloseIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { Position } from '~/services/ApiClient';

import { GeoPositionAddress } from '../GeoPositionAddress';

import type { AddressPopupClassKey } from './styles';

export interface AddressPopupProps {
    onClose: () => void;
    position: Position;
}

export interface AddressPopupInnerProps extends AddressPopupProps, WithStyles<AddressPopupClassKey> {}

export const AddressPopupComponent: FC<AddressPopupInnerProps> = (props) => {
    const { classes, onClose, position } = props;

    const { address } = useReverseGeocodedAddress(position);

    return (
        <Paper className={classes.paper} data-id="address-popup" data-testid="address-popup" elevation={3}>
            <Avatar className={classes.avatar} data-id="address-avatar">
                <Room color="primary" />
            </Avatar>

            <GeoPositionAddress address={address} position={position} />

            <TitledIconButton className={classes.closeButton} data-id="address-popup-close" onClick={onClose}>
                <CloseIcon fontSize="inherit" />
            </TitledIconButton>
        </Paper>
    );
};
