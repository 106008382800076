import type { Size } from '../models';

const degToRadian = (deg: number) => deg * (Math.PI / 180);

// This method calculates the outer bounds of an image after rotating it
export const rotateRectangle = (size: Size, rotation: number): Size => {
    const { height, width } = size;
    const rotationRadian = degToRadian(rotation);
    const translate = (x: number, y: number) => ({
        x: x * Math.cos(rotationRadian) - y * Math.sin(rotationRadian),
        y: x * Math.sin(rotationRadian) + y * Math.cos(rotationRadian),
    });

    const topLeft = translate(0, height);
    const topRight = translate(width, height);
    const bottomRight = translate(width, 0);
    const bottomLeft = translate(0, 0);

    const minX = Math.min(topLeft.x, topRight.x, bottomRight.x, bottomLeft.x);
    const maxX = Math.max(topLeft.x, topRight.x, bottomRight.x, bottomLeft.x);
    const minY = Math.min(topLeft.y, topRight.y, bottomRight.y, bottomLeft.y);
    const maxY = Math.max(topLeft.y, topRight.y, bottomRight.y, bottomLeft.y);
    const w = Math.abs(minX) + maxX;
    const h = Math.abs(minY) + maxY;

    return { height: h, width: w };
};
