import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, keyBy } from '~/libs/utility';
import type { Classification, CompartmentStatus, ResolvedCompartmentStatus } from '~/services/ApiClient';

export const compartmentStatusResolver = (
    { init, status, temperatureSensors, toJSON, ...compartmentStatusData }: CompartmentStatus,
    compartmentStatus: NumericDictionary<Classification>
): ResolvedCompartmentStatus => ({
    ...compartmentStatusData,
    status: !isUndefined(status) ? compartmentStatus[status] : undefined,
    temperatureSensors: keyBy(temperatureSensors, 'sensorNumber'),
});
