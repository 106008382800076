import { Backdrop, CircularProgress, debounce } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

import type { TriggerDataRefresh } from '~/common';
import { AssetSelector, useStaticDataProvider } from '~/common';
import { RefreshSceneBar, RefreshSceneBarState } from '~/common/components/RefreshSceneBar';
import { AskForPageReload } from '~/components/AskForPageReload';
import type { ContextMenuPosition } from '~/components/ContextMenu';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PageTemplate } from '~/components/PageTemplate';
import type { DetailsPaneContextProps } from '~/components/SceneDetailsPane';
import { DetailsPaneContext } from '~/components/SceneDetailsPane';
import { SceneLoader } from '~/components/SceneLoader';
import { isUndefined } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import type { ResolvedRtdsSchedule } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

import { RtdsScheduleDetailsPane } from './components/DetailsPane';
import { RtdsSchedulesChangelogSubpage } from './components/DetailsPane/components/RtdsScheduleChangelogsSubpage';
import { RtdsSchedulesListView, RtdsSchedulesListViewHeader } from './components/List';
import { RtdsSchedulesContextMenu } from './components/RtdsScheduleContextMenu';
import { ScheduleEditor } from './components/ScheduleEditor';
import { constructRtdsScheduleUrl } from './constructRtdsScheduleUrl';
import { Subpage } from './models';
import { RTDSSCHEDULES_ASSETSELECTOR_USERPREFERENCES_KEY } from './preferences';
import type { RtdsSchedulesReduxProps } from './redux';
import type { RtdsSchedulesClassKeys } from './styles';

export interface RtdsSchedulesProps {}
export interface RtdsSchedulesInnerProps
    extends RtdsSchedulesProps,
        InjectedTranslationProps,
        RtdsSchedulesReduxProps,
        WithStyles<RtdsSchedulesClassKeys> {}

export interface RtdsSchedulesComponentState {
    rtdsScheduleContextMenu?: {
        position: ContextMenuPosition;
        scheduleId: number;
    };
    triggerDataRefresh: TriggerDataRefresh;
}

export const RtdsSchedulesComponent: React.FC<RtdsSchedulesInnerProps> = ({
    authorized,
    classes,
    clearData,
    contentLoading,
    getRtdsSchedules,
    keepSubpageOpen,
    leftPaneIsOpen,
    loading,
    rejected,
    rtdsSchedulesEntries,
    selectedDriverIds,
    selectedScheduleId,
    selectedVehicleIds,
    subpage,
    t,
    toggleLeftPaneVisibility,
}) => {
    const history = useHistory();

    const staticDataProvider = useStaticDataProvider();
    const [dialog, setDialog] = React.useState<React.ReactNode>();
    const [state, setState] = React.useState<RtdsSchedulesComponentState>({
        triggerDataRefresh: {
            initialLoad: true,
            showBanner: false,
            triggered: false,
        },
    });

    const onCloseRefreshSceneBar = React.useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                initialLoad: false,
                showBanner: false,
                triggered: prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const onRefreshData = React.useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                initialLoad: false,
                showBanner: true,
                triggered: !prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const closeDetailsPane = React.useCallback(() => {
        history.push(constructRtdsScheduleUrl());
    }, [history]);

    const selectedEntry = React.useMemo(() => {
        if (isUndefined(selectedScheduleId)) {
            return undefined;
        }

        const resolvedRtdsSchedule = rtdsSchedulesEntries.find((entry) => entry.schedule.id?.id === selectedScheduleId);

        return resolvedRtdsSchedule;
    }, [selectedScheduleId, rtdsSchedulesEntries]);

    const openScheduleEditor = React.useCallback(
        (assetType: AssetType, isClone: boolean, schedule?: ResolvedRtdsSchedule) => {
            setDialog(
                <ScheduleEditor
                    assetType={assetType}
                    isClone={isClone}
                    onClose={() => setDialog(undefined)}
                    onSave={onRefreshData}
                    schedule={schedule}
                />
            );
        },
        [onRefreshData]
    );

    const getSubpageContext = React.useMemo(() => {
        const subpageContext: DetailsPaneContextProps = {
            close: () => {
                history.push(constructRtdsScheduleUrl(undefined, undefined));
            },
            goBack: () => {
                history.push(constructRtdsScheduleUrl(selectedScheduleId, undefined));
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            openSubpage: () => {},
        };
        return subpageContext;
    }, [history, selectedScheduleId]);

    const rightPane = React.useMemo((): JSX.Element | undefined => {
        if (!selectedScheduleId) {
            return undefined;
        }

        const subpageContext = getSubpageContext;

        if (subpage === Subpage.CHANGELOGS) {
            return (
                <DetailsPaneContext.Provider value={subpageContext}>
                    <RtdsSchedulesChangelogSubpage scheduleId={selectedScheduleId} />
                </DetailsPaneContext.Provider>
            );
        } else {
            return (
                selectedEntry && (
                    <RtdsScheduleDetailsPane
                        entry={selectedEntry}
                        handleClose={closeDetailsPane}
                        openScheduleEditor={openScheduleEditor}
                    />
                )
            );
        }
    }, [selectedScheduleId, getSubpageContext, subpage, selectedEntry, closeDetailsPane, openScheduleEditor]);

    const selectRtdsScheduleCallback = React.useCallback(
        (rowSelectedScheduleId: number) => {
            history.push(constructRtdsScheduleUrl(rowSelectedScheduleId, keepSubpageOpen ? subpage : undefined));
        },
        [history, keepSubpageOpen, subpage]
    );

    const dispatchGetRtdsSchedulesDebounced = React.useMemo(
        () =>
            debounce((vehicleIds: number[] | undefined, driverIds: number[] | undefined) => {
                getRtdsSchedules(vehicleIds, driverIds);
            }, 500),
        [getRtdsSchedules]
    );

    const closeContextMenu = React.useCallback(() => {
        setState((prevState) => ({ ...prevState, rtdsScheduleContextMenu: undefined }));
    }, []);

    React.useEffect(() => {
        dispatchGetRtdsSchedulesDebounced(selectedVehicleIds, selectedDriverIds);
    }, [dispatchGetRtdsSchedulesDebounced, selectedDriverIds, selectedVehicleIds, state.triggerDataRefresh.triggered]);

    React.useEffect(() => {
        staticDataProvider.retrieveAssetGroups();
        staticDataProvider.retrieveDepots();
        staticDataProvider.retrieveVehicles();
        staticDataProvider.retrieveDrivers();
        return () => clearData();
    }, [clearData, staticDataProvider]);

    if (!authorized) {
        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
    }

    if (rejected && state.triggerDataRefresh.initialLoad) {
        return <AskForPageReload />;
    }

    if (loading) {
        return <SceneLoader />;
    }

    const getLeftPane = (): JSX.Element | undefined => {
        if (!leftPaneIsOpen) {
            return undefined;
        }

        const assetTypes: AssetType[] = [AssetType.Driver, AssetType.Vehicle];

        return (
            <AssetSelector assetTypes={assetTypes} preferencesKey={RTDSSCHEDULES_ASSETSELECTOR_USERPREFERENCES_KEY} />
        );
    };

    const header = (
        <RtdsSchedulesListViewHeader
            dataSource={rtdsSchedulesEntries}
            gridActionsDisabled={selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0}
            onRefreshData={onRefreshData}
            openScheduleEditor={openScheduleEditor}
            title={t('rtds-schedules')}
        />
    );

    const onRowContextMenu = (evt: MouseEvent, rowId: string) => {
        setState({
            ...state,
            rtdsScheduleContextMenu: {
                position: { clientX: evt.clientX, clientY: evt.clientY },
                scheduleId: parseInt(rowId, 10),
            },
        });
        evt.preventDefault();
        evt.stopPropagation();
    };

    const getViewContent = (): JSX.Element | string => {
        if (selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0) {
            return (
                <div className={classes.messageText} data-id="no-selection">
                    {t('no-assets-selected')}
                </div>
            );
        }

        return (
            <>
                <Backdrop
                    className={classes.backDrop}
                    data-id="back-drop"
                    open={contentLoading && !!state.triggerDataRefresh.initialLoad}
                >
                    <CircularProgress className={classes.loader} data-id="backdrop-loader" size={120} />
                </Backdrop>
                <RtdsSchedulesListView
                    dataSource={rtdsSchedulesEntries}
                    highlightedAssetId={state.rtdsScheduleContextMenu?.scheduleId}
                    onRowContextMenu={onRowContextMenu}
                    selectedAssetId={selectedScheduleId}
                    selectRow={selectRtdsScheduleCallback}
                />
            </>
        );
    };

    const getTopBanner = () => {
        if (
            (contentLoading || rejected) &&
            !state.triggerDataRefresh.initialLoad &&
            state.triggerDataRefresh.showBanner
        ) {
            return (
                <RefreshSceneBar
                    disableClose={contentLoading}
                    onClose={onCloseRefreshSceneBar}
                    state={rejected ? RefreshSceneBarState.ERROR : RefreshSceneBarState.REFRESHING}
                    stateAction={rejected ? onRefreshData : undefined}
                />
            );
        }
        return undefined;
    };

    const leftPaneTitle = t(leftPaneIsOpen ? 'collapse-asset-selector' : 'expand-asset-selector');

    return (
        <div className={classes.root} data-id="rtds-schedules">
            <PageTemplate
                header={header}
                leftPaneElement={getLeftPane()}
                leftPaneToggleTitle={leftPaneTitle}
                rightPaneElement={rightPane}
                toggleLeftPaneVisibility={toggleLeftPaneVisibility}
                topPaneElement={getTopBanner()}
            >
                {getViewContent()}
            </PageTemplate>
            <RtdsSchedulesContextMenu
                {...state.rtdsScheduleContextMenu}
                onClose={closeContextMenu}
                refreshData={onRefreshData}
            />
            {dialog}
        </div>
    );
};
