import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';

import type { RecipientListReduxProps } from './component';

export const mapStateToProps = (
    staticDataStore: StaticDataStoreState,
    settingsState: SettingsStoreState
): RecipientListReduxProps => {
    const { assetGroups, depots, drivers, vehicles } = staticDataStore;
    const { vehicleCategories, vehicleTypes } = settingsState;

    return {
        assetGroups: assetGroups.data,
        depots: depots.data.array,
        drivers: drivers.data.array,
        loading: !depots.fulfilled || !assetGroups.fulfilled || !vehicles.fulfilled || !drivers.fulfilled,
        rejected: depots.rejected || assetGroups.rejected || vehicles.rejected || drivers.rejected,
        vehicleCategories: Object.values(vehicleCategories.data),
        vehicles: vehicles.data.array,
        vehicleTypes: Object.values(vehicleTypes.data),
    };
};
