const CRUD_ACTIONS = {
    CLONE: 'clone',
    CREATE: 'create',
    DELETE: 'delete',
    EDIT: 'edit',
} as const;

const SERVER_STATUS = {
    ACCEPTED: 'Accepted',
    BADREQUEST: 'BadRequest',
    OK: 'Ok',
    PENDING: 'Pending',
    SERVERERROR: 'ServerError',
};

export { CRUD_ACTIONS, SERVER_STATUS };
