import type { FC } from 'react';

import { MarkerIcon } from '~/components/Map/components/MarkerIcon';

import type { DriverMarkerIconProps } from './models';
import { DriverIcon } from './styles';

const DriverMarkerIcon: FC<DriverMarkerIconProps> = (props) => {
    const { entry } = props;
    return (
        <MarkerIcon
            AssetIcon={DriverIcon}
            courseOverGround={entry.driver?.vehicleStatus?.courseOverGround}
            dataId="driver-icon-marker"
            driverActivity={entry.driver?.activity}
            speedOverGround={entry.driver?.vehicleStatus?.speedOverGround}
        />
    );
};

DriverMarkerIcon.displayName = 'DriverMarkerIcon';

export { DriverMarkerIcon };
