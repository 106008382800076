import _RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material';

// export const useStyles = makeStyles((theme) => ({
//     root: { height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' },
//     container: { textAlign: 'center' },
//     buttons: { marginTop: theme.spacing() },
//     refreshIcon: { marginRight: theme.spacing() },
// }));

const Container = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    textAlign: 'center',
});

const ButtonsContainer = styled('div')(({ theme }) => ({ marginTop: theme.spacing() }));
const RefreshIcon = styled(_RefreshIcon)(({ theme }) => ({ marginRight: theme.spacing() }));

export { ButtonsContainer, Container, RefreshIcon };
