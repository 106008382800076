import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { AddressPopupProps } from './component';

export type AddressPopupClassKey = 'avatar' | 'closeButton' | 'paper';

export const AddressPopupStyleRules: StyleRulesCallback<Theme, AddressPopupProps, AddressPopupClassKey> = (
    theme: Theme
) =>
    createStyles({
        avatar: {
            alignSelf: 'flex-start',
            backgroundColor: theme.palette.background.default,
            height: theme.spacing(7.5),
            marginRight: theme.spacing(1),
            width: theme.spacing(7.5),
        },
        closeButton: {
            fontSize: 14,
            height: theme.spacing(3),
            marginLeft: 'auto',
            padding: 0,
            width: theme.spacing(3),
        },
        paper: {
            alignSelf: 'flex-end',
            bottom: theme.spacing(11),
            display: 'flex',
            margin: '0 auto',
            padding: theme.spacing(0.75),
            position: 'relative',
            width: theme.spacing(48),
            zIndex: 1000,
        },
    });
