/* eslint-disable global-require */
import slide92 from './slide-92.png';
import slide93 from './slide-93.png';
import slide94 from './slide-94.png';
import slide95 from './slide-95.png';
import slide96 from './slide-96.jpg';

export const carousel = {
    assets: {
        'slide-92': slide92,
        'slide-93': slide93,
        'slide-94': slide94,
        'slide-95': slide95,
        'slide-96': slide96,
    },
    fallback: require('./carousel.en-GB.json'),
    languages: {
        'de-DE': require('./carousel.de-DE.json'),
        'es-ES': require('./carousel.es-ES.json'),
        'fr-FR': require('./carousel.fr-FR.json'),
        'it-IT': require('./carousel.it-IT.json'),
        'nl-NL': require('./carousel.nl-NL.json'),
        'pl-PL': require('./carousel.pl-PL.json'),
    },
    revision: 32,
};
