import type { Dispatch } from 'redux';

import type { MapViewport } from '~/common';
import type { HistoryMapStoreState, HistoryStoreState } from '~/data/monitoring';
import type { PositionGroup } from '~/services/ApiClient';
import type { RequiredAndNonNullable } from '~/types';

import {
    highlightPositionGroupAction,
    scrollToPositionGroupAction,
} from '../../../DetailsPane/components/HistorySubpage/data';

import { changeViewportAction } from './data';
import type { HistoryMapDispatchProps, HistoryMapStateProps } from './models';

export const mapStateToProps = (state: HistoryStoreState, mapState: HistoryMapStoreState): HistoryMapStateProps => ({
    flyToPositionGroup: mapState.flyToPositionGroup,
    highlightPosition: state.highlightPosition,
    highlightPositionGroupId: state.highlightPositionGroupId,
    panToPosition: mapState.panToPosition,
    panToPositionGroup: mapState.panToPositionGroup,
    pendingPositionGroups: state.pendingPositionGroups,
    positionGroups: state.positionGroups,
    viewport: mapState.viewport,
});

export const mapDispatchProps = (dispatch: Dispatch): HistoryMapDispatchProps => ({
    changeViewport: (viewport: RequiredAndNonNullable<MapViewport>) => {
        dispatch(changeViewportAction(viewport));
    },
    highlightPositionGroup: (positionGroup?: PositionGroup) => {
        dispatch(highlightPositionGroupAction(positionGroup?.id));
    },
    scrollToPositionGroup: () => {
        dispatch(scrollToPositionGroupAction());
    },
});
