import { createSelector } from 'reselect';

import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import type { Position } from '~/services/ApiClient';

import { HistoryEventType } from '../../models';
import type { DynamicHistoryStoreState } from '../../reducers';
import { dynamicHistoryStateSelector } from '../../selectors';

export interface HistoryEntryContextMenuReduxProps {
    eventId?: number;
    eventType?: HistoryEventType;
}

export interface HistoryEntryContextMenuReduxState {
    eventPosition?: Position;
}

export const mapStateToProps = (
    dynamicHistory: DynamicHistoryStoreState,
    ownProps: HistoryEntryContextMenuReduxProps
): HistoryEntryContextMenuReduxState => {
    const { eventId, eventType } = ownProps;

    if (isUndefined(eventId) || !eventType) {
        return {};
    }

    const { driverEvents, trailerEvents, vehicleEvents } = dynamicHistory;

    switch (eventType) {
        case HistoryEventType.DRIVER:
            return {
                eventPosition: driverEvents.data.find((v) => v.eventId === eventId)?.location?.position,
            };
        case HistoryEventType.TRAILER:
            return {
                eventPosition: trailerEvents.data.find((v) => v.trailerEventId === eventId)?.location?.position,
            };
        case HistoryEventType.VEHICLE:
            return {
                eventPosition: vehicleEvents.data.find((v) => v.eventPosId === eventId)?.location?.position,
            };
        default:
            throw new Error(`Not supported event type ${eventType}`);
    }
};

export const reduxPropsSelector: (
    storeState: StoreState,
    ownProps: HistoryEntryContextMenuReduxProps
) => HistoryEntryContextMenuReduxState = createSelector(
    dynamicHistoryStateSelector,
    (_: never, ownProps: HistoryEntryContextMenuReduxProps) => ownProps,
    mapStateToProps
);
