import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    paneHandler: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        display: 'flex',
        height: theme.spacing(5),
        width: theme.spacing(3),
    },
}));
