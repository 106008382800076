import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { DriverEventFieldValueMeta, ActionTypes as DriverEventsActionTypes } from '~/data/driverevents';
import { ActionTypeKeys as DriverEventsActionTypeKeys } from '~/data/driverevents';
import type { ActionTypes as TrailerEventsActionTypes } from '~/data/trailerevents';
import { ActionTypeKeys as TrailerEventsActionTypeKeys } from '~/data/trailerevents';
import type { ActionTypes as VehicleEventsActionTypes } from '~/data/vehicleevents';
import { ActionTypeKeys as VehicleEventsActionTypeKeys } from '~/data/vehicleevents';
import { isUndefined } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type {
    DriverEvent,
    DriverEventFieldValue,
    ResolvedDriverEventFieldValue,
    TrailerEvent,
    VehicleEvent,
} from '~/services/ApiClient';

import type { ActionTypes as HistoryActionTypes } from './data';
import { ActionTypeKeys as HistoryActionTypeKeys } from './data';
import { eventFieldValueResolver } from './reducers.eventFieldValueResolver';

export interface DynamicHistoryStoreState {
    driverEventFieldValues: RetrievableData<DriverEventFieldValue[]>;
    driverEvents: RetrievableData<DriverEvent[]>;
    resolvedDriverEventFieldValues: RetrievableData<ResolvedDriverEventFieldValue[]>;
    trailerEvents: RetrievableData<TrailerEvent[]>;
    vehicleEvents: RetrievableData<VehicleEvent[]>;
}

export interface HistoryStoreState {
    dateTimeRange?: DateTimeRange;
    driverEventFieldValueMeta?: DriverEventFieldValueMeta;
    dynamicHistory: DynamicHistoryStoreState;
}

export const defaultStoreState: HistoryStoreState = {
    dynamicHistory: {
        driverEventFieldValues: {
            data: [],
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        driverEvents: {
            data: [],
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        resolvedDriverEventFieldValues: {
            data: [],
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        trailerEvents: {
            data: [],
            fulfilled: false,
            pending: false,
            rejected: false,
        },
        vehicleEvents: {
            data: [],
            fulfilled: false,
            pending: false,
            rejected: false,
        },
    },
};

type ActionTypes = DriverEventsActionTypes | HistoryActionTypes | TrailerEventsActionTypes | VehicleEventsActionTypes;

export const historyReducer = (
    state: HistoryStoreState = defaultStoreState,
    action: ActionTypes,
    settings: SettingsStoreState
): HistoryStoreState => {
    switch (action.type) {
        case HistoryActionTypeKeys.HISTORY_CLEAR_DATA:
            return {
                ...defaultStoreState,
                dateTimeRange: state.dateTimeRange,
            };

        case HistoryActionTypeKeys.HISTORY_CHANGE_DATETIMERANGE:
            return {
                ...state,
                dateTimeRange: action.payload,
            };

        case VehicleEventsActionTypeKeys.VEHICLEEVENT_FULFILLED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    vehicleEvents: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        case VehicleEventsActionTypeKeys.VEHICLEEVENT_PENDING:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    vehicleEvents: {
                        ...state.dynamicHistory.vehicleEvents,
                        pending: true,
                    },
                },
            };
        case VehicleEventsActionTypeKeys.VEHICLEEVENT_REJECTED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    vehicleEvents: {
                        data: [],
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        case TrailerEventsActionTypeKeys.TRAILEREVENT_FULFILLED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    trailerEvents: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        case TrailerEventsActionTypeKeys.TRAILEREVENT_PENDING:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    trailerEvents: {
                        ...state.dynamicHistory.trailerEvents,
                        pending: true,
                    },
                },
            };
        case TrailerEventsActionTypeKeys.TRAILEREVENT_REJECTED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    trailerEvents: {
                        data: [],
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENT_FULFILLED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEvents: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENT_PENDING:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEvents: {
                        ...state.dynamicHistory.driverEvents,
                        pending: true,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENT_REJECTED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEvents: {
                        data: [],
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENTFIELDVALUES_FULFILLED:
            return {
                ...state,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEventFieldValues: {
                        data:
                            action.meta === state.driverEventFieldValueMeta
                                ? action.payload.items
                                : state.dynamicHistory.driverEventFieldValues.data,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                    resolvedDriverEventFieldValues: {
                        data:
                            action.meta === state.driverEventFieldValueMeta
                                ? eventFieldValueResolver(
                                      action.payload.items,
                                      !isUndefined(state.driverEventFieldValueMeta.eventTypeId)
                                          ? settings.driverEventTypes.data[state.driverEventFieldValueMeta.eventTypeId]
                                                .fields
                                          : !isUndefined(state.driverEventFieldValueMeta.subActivityTypeId)
                                            ? settings.driverSubActivityTypes.data[
                                                  state.driverEventFieldValueMeta.subActivityTypeId
                                              ].fields
                                            : undefined
                                  )
                                : state.dynamicHistory.resolvedDriverEventFieldValues.data,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENTFIELDVALUES_PENDING:
            return {
                ...state,
                driverEventFieldValueMeta: action.meta,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEventFieldValues: {
                        data: [],
                        fulfilled: false,
                        pending: true,
                        rejected: false,
                    },
                    resolvedDriverEventFieldValues: {
                        data: [],
                        fulfilled: false,
                        pending: true,
                        rejected: false,
                    },
                },
            };
        case DriverEventsActionTypeKeys.DRIVEREVENTFIELDVALUES_REJECTED:
            return {
                ...state,
                driverEventFieldValueMeta: action.meta,
                dynamicHistory: {
                    ...state.dynamicHistory,
                    driverEventFieldValues: {
                        data: [],
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                    resolvedDriverEventFieldValues: {
                        data: [],
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                    },
                },
            };
        default:
            return state;
    }
};
