import { Marker } from '@fv/components';
import { useMemo } from 'react';
import type { FC } from 'react';
import { withProps } from 'react-recompose';

import type { MonitoringDriverEntry } from '~/data/monitoring';

import { DriverMarkerIcon } from './components/DriverMarkerIcon';
import { DriverMarkerNameFormatter } from './components/DriverMarkerNameFormatter';
import type { DriverMarkerInnerProps } from './models';

const DriverMarker: FC<DriverMarkerInnerProps> = (props) => {
    const { classes, driverDisplayNameFormat, entry, ...rest } = props;

    const NameFormatterComponent = useMemo(
        () =>
            driverDisplayNameFormat &&
            withProps({ displayNameFormat: driverDisplayNameFormat })(DriverMarkerNameFormatter),
        [driverDisplayNameFormat]
    );

    const overriddenMarkerClasses = useMemo(() => {
        return {
            assetName: classes.assetName,
            highlight: classes.driverBorderColor,
            iconBox: classes.driverBackgroundColor,
            selected: classes.driverBorderColor,
        };
    }, [classes]);

    return (
        <Marker<MonitoringDriverEntry>
            {...rest}
            classes={overriddenMarkerClasses}
            dataId={`driver-marker:${entry.status.driver}`}
            entry={entry}
            Icon={DriverMarkerIcon}
            NameFormatter={NameFormatterComponent}
        />
    );
};

export { DriverMarker };
