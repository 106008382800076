import { styled } from '@mui/material';
import _Backdrop from '@mui/material/Backdrop';
import _CircularProgress from '@mui/material/CircularProgress';

const MessageContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
}));

const Backdrop = styled(_Backdrop)(({ theme }) => ({
    color: '#fff',
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
}));

const CircularProgress = styled(_CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export { Backdrop, CircularProgress, MessageContainer };
