export const RTDSSESSIONS_USERPREFERENCES_KEY = 'rtdssessions-state';

export const RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY = 'rtdssessions-assetselector-state';

export interface RtdsSessionsUserPreferences {
    leftPaneIsOpen: boolean;
}

export const defaultRtdsSessionsUserPreferences: RtdsSessionsUserPreferences = {
    leftPaneIsOpen: true,
};

export const requiredRtdsSessionsUserPreferences = {
    defaultState: defaultRtdsSessionsUserPreferences,
    key: RTDSSESSIONS_USERPREFERENCES_KEY,
};
