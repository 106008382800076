import type { RouteComponentProps } from 'react-router';
import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import { retrieveDriverEventsAction } from '~/data/driverevents';
import { retrieveTrailerEventsAction } from '~/data/trailerevents';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { retrieveVehicleEventsAction } from '~/data/vehicleevents';
import { endOfDay, startOfDay, subDates } from '~/libs/dates';
import { isUndefined } from '~/libs/utility';
import { reportError } from '~/reporting';
import type { AssetType, HistorySecurables, ResolvedAsset } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { HistoryReduxProps, HistoryUrlParams } from './component';
import { changeDateTimeRangeAction, clearDataAction } from './data';
import type { HistoryAssetEntry } from './models';
import { HistoryEventType } from './models';
import type { HistoryUserPreferences } from './preferences';
import { HISTORY_USERPREFERENCES_KEY } from './preferences';
import type { DynamicHistoryStoreState } from './reducers';
import { buildHistoryAssetEntries } from './redux.buildHistoryAssetEntries';
import { buildSelectedAsset as buildSelectedAssetFn } from './redux.buildSelectedAsset';
import { buildSelectedHistoryEntry as buildSelectedHistoryEntryFn } from './redux.buildSelectedHistoryEntry';
import { getEventTypeValue } from './services';

const buildHistoryAssetEntriesMemoized = memoizeOne(buildHistoryAssetEntries);

const buildSelectedHistoryEntryMemoized = memoizeOne(buildSelectedHistoryEntryFn);

const buildSelectedAssetMemoized = memoizeOne(buildSelectedAssetFn);

export interface HistoryStateProps {
    buildHistoryAssets: (eventType: HistoryEventType) => HistoryAssetEntry[];
    buildSelectedAsset: (selectedAssetId?: number, selectedAssetType?: AssetType) => ResolvedAsset | undefined;
    buildSelectedHistoryEntry: (
        eventType: HistoryEventType,
        selectedAssetId?: number,
        selectedEntryId?: number
    ) => HistoryAssetEntry | undefined;
    dateTimeRange: DateTimeRange;
    eventLoading: (eventType: HistoryEventType) => boolean;
    eventRejected: (eventType: HistoryEventType) => boolean;
    eventTypePreferences: (eventType: HistoryEventType) => SceneAssetSelectorUserPreferences;
    leftPaneIsOpen: boolean;
    permittedEventTypes: HistoryEventType[];
}

export interface HistoryDispatchProps {
    changeDateTimeRange: (value: DateTimeRange) => void;
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void;
    clearData: () => void;
    getEvents: (
        eventType: HistoryEventType,
        assseType: AssetType,
        assetId: number,
        startDate: Date,
        endDate: Date
    ) => void;
}

const getDefaultDateTimeRange = memoizeOne(() => {
    const endDate = endOfDay(new Date());
    const startDate = startOfDay(subDates(new Date(), { days: 1 }));

    return { endDate, startDate };
});

const getPermittedEventTypes = memoizeOne((securables: HistorySecurables) => {
    const permittedEventTypes: HistoryEventType[] = [];

    if (securables.vehicles) {
        permittedEventTypes.push(HistoryEventType.VEHICLE);
    }

    if (securables.trailers) {
        permittedEventTypes.push(HistoryEventType.TRAILER);
    }

    if (securables.drivers) {
        permittedEventTypes.push(HistoryEventType.DRIVER);
    }

    return permittedEventTypes;
});

export const mapStateToProps = (
    staticDataStoreState: StaticDataStoreState,
    dynamicHistoryStoreState: DynamicHistoryStoreState,
    vehicleAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    trailerAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    driverAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    historyUserPreferences: HistoryUserPreferences,
    settingsState: SettingsStoreState,
    dateTimeRange: DateTimeRange | undefined
): HistoryStateProps => {
    const { drivers, trailers, vehicles } = staticDataStoreState;

    const { driverEvents, trailerEvents, vehicleEvents } = dynamicHistoryStoreState;

    const {
        compartmentStatus,
        deviceTypes,
        doorStatus,
        driverActivityTypes,
        driverEventTypes,
        driverSubActivityTypes,
        hookedStatus,
        reeferAlarm,
        reeferManufacturers,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        securables: { data: securables },
        trailerBatteryStatus,
        trailerEventType,
        trailerManufacturers,
        vehicleCategories,
        vehicleTypes,
    } = settingsState;

    const canViewVehicles = securables.history.vehicles;
    const canViewTrailers = securables.history.trailers;
    const canViewDrivers = securables.history.drivers;

    const permittedEventTypes = getPermittedEventTypes(securables.history);

    const { leftPaneIsOpen } = historyUserPreferences;

    const buildSelectedAsset = (selectedAssetId?: number, selectedAssetType?: AssetType) => {
        return buildSelectedAssetMemoized(
            selectedAssetId,
            selectedAssetType,
            vehicles.data.hash,
            trailers.data.hash,
            drivers.data.hash,
            deviceTypes.data,
            vehicleCategories.data,
            vehicleTypes.data,
            trailerManufacturers.data,
            reeferManufacturers.data
        );
    };

    const buildHistoryAssets = (eventType: HistoryEventType) => {
        return buildHistoryAssetEntriesMemoized(
            eventType,
            vehicleEvents.data,
            trailerEvents.data,
            driverEvents.data,
            vehicles.data.hash,
            trailers.data.hash,
            drivers.data.hash,
            deviceTypes.data,
            vehicleCategories.data,
            vehicleTypes.data,
            reeferStatus.data,
            reeferAlarm.data,
            reeferOperationMode.data,
            reeferPowerMode.data,
            reeferSpeed.data,
            hookedStatus.data,
            compartmentStatus.data,
            doorStatus.data,
            trailerBatteryStatus.data,
            trailerEventType.data,
            driverActivityTypes.data,
            driverSubActivityTypes.data,
            driverEventTypes.data
        );
    };

    const buildSelectedHistoryEntry = (eventType: HistoryEventType, selectedEntryId?: number) => {
        return buildSelectedHistoryEntryMemoized(
            eventType,
            selectedEntryId,
            vehicles.data.hash,
            trailers.data.hash,
            drivers.data.hash,
            vehicleEvents.data,
            trailerEvents.data,
            driverEvents.data,
            deviceTypes.data,
            vehicleCategories.data,
            vehicleTypes.data,
            reeferStatus.data,
            reeferAlarm.data,
            reeferOperationMode.data,
            reeferPowerMode.data,
            reeferSpeed.data,
            hookedStatus.data,
            compartmentStatus.data,
            doorStatus.data,
            trailerBatteryStatus.data,
            trailerEventType.data,
            driverActivityTypes.data,
            driverSubActivityTypes.data,
            driverEventTypes.data
        );
    };

    const eventtypePreferences = (eventType: HistoryEventType): SceneAssetSelectorUserPreferences => {
        return getEventTypeValue<SceneAssetSelectorUserPreferences>(eventType, {
            [HistoryEventType.DRIVER]: driverAssetSelectorUserPreferences,
            [HistoryEventType.TRAILER]: trailerAssetSelectorUserPreferences,
            [HistoryEventType.VEHICLE]: vehicleAssetSelectorUserPreferences,
        });
    };

    const eventLoading = (eventType: HistoryEventType): boolean => {
        return getEventTypeValue(eventType, {
            [HistoryEventType.DRIVER]: canViewDrivers && driverEvents.pending,
            [HistoryEventType.TRAILER]: canViewTrailers && trailerEvents.pending,
            [HistoryEventType.VEHICLE]: canViewVehicles && vehicleEvents.pending,
        });
    };

    const eventRejected = (eventType: HistoryEventType): boolean => {
        return getEventTypeValue(eventType, {
            [HistoryEventType.DRIVER]: driverEvents.rejected,
            [HistoryEventType.TRAILER]: trailerEvents.rejected,
            [HistoryEventType.VEHICLE]: vehicleEvents.rejected,
        });
    };

    return {
        buildHistoryAssets,
        buildSelectedAsset,
        buildSelectedHistoryEntry,
        dateTimeRange: dateTimeRange ?? getDefaultDateTimeRange(),
        eventLoading,
        eventRejected,
        eventTypePreferences: eventtypePreferences,
        leftPaneIsOpen,
        permittedEventTypes,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): HistoryDispatchProps => {
    return {
        changeDateTimeRange: (dateTimeRange: DateTimeRange) => {
            dispatch(changeDateTimeRangeAction(dateTimeRange));
        },
        changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => {
            dispatch(updateUserPreferencesAction(HISTORY_USERPREFERENCES_KEY, { leftPaneIsOpen }));
        },
        clearData: () => {
            dispatch(clearDataAction());
        },
        getEvents: (
            eventType: HistoryEventType,
            assetType: AssetType,
            assetId: number,
            startDate: Date,
            stopDate: Date
        ) => {
            switch (eventType) {
                case HistoryEventType.DRIVER:
                    dispatch(
                        retrieveDriverEventsAction({
                            assetId,
                            assetType,
                            startDate,
                            stopDate,
                        })
                    ).catch(reportError);
                    break;
                case HistoryEventType.TRAILER:
                    dispatch(
                        retrieveTrailerEventsAction({
                            assetId,
                            assetType,
                            startDate,
                            stopDate,
                        })
                    ).catch(reportError);
                    break;
                case HistoryEventType.VEHICLE:
                    dispatch(retrieveVehicleEventsAction({ startDate, stopDate, vehicleId: assetId })).catch(
                        reportError
                    );
                    break;
                default:
                    throw Error(`Unknown eventType: ${eventType}`);
            }
        },
    };
};

const toggleLeftPaneVisibilityMemoized = memoizeOne(
    (changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void, leftPaneIsOpen: boolean) => () => {
        changeLeftPaneVisibility(!leftPaneIsOpen);
    }
);

export const mergeProps = (
    {
        buildHistoryAssets,
        buildSelectedAsset,
        buildSelectedHistoryEntry,
        eventLoading,
        eventRejected,
        eventTypePreferences,
        leftPaneIsOpen,
        ...restStateProps
    }: HistoryStateProps,
    { changeLeftPaneVisibility, ...restDispatchProps }: HistoryDispatchProps,
    { match: { params }, ...restOwnProps }: RouteComponentProps<HistoryUrlParams>
): HistoryReduxProps => {
    const firstEventType = restStateProps.permittedEventTypes
        ? restStateProps.permittedEventTypes.length > 0
            ? restStateProps.permittedEventTypes[0]
            : HistoryEventType.VEHICLE
        : HistoryEventType.VEHICLE;

    const eventType = params.eventType || firstEventType;
    const selectedAssetIdParam = params.selectedAssetId ? Number(params.selectedAssetId) : undefined;
    const selectedAssetTypeParam = params.selectedAssetType;

    const rejected = eventRejected(eventType);
    const loading = !isUndefined(selectedAssetIdParam) && !rejected && eventLoading(eventType);
    const leftPaneForceOpen = isUndefined(params.selectedAssetId) ? false : leftPaneIsOpen;

    return {
        ...restStateProps,
        ...restOwnProps,
        ...restDispatchProps,
        contentLoading: loading,
        contentRejected: rejected,
        dataSource: buildHistoryAssets(eventType),
        eventTypeFromUrl: eventType,
        getSelectedHistoryEntry: (selectedHistoryEntryId: number) =>
            buildSelectedHistoryEntry(eventType, selectedHistoryEntryId),
        leftPaneIsOpen: isUndefined(params.selectedAssetId) ? true : leftPaneIsOpen,
        selectedAsset: buildSelectedAsset(selectedAssetIdParam, selectedAssetTypeParam),
        selectedAssetIdFromUrl: selectedAssetIdParam,
        selectedAssetTypeFromUrl: selectedAssetTypeParam,
        toggleLeftPaneVisibility: toggleLeftPaneVisibilityMemoized(changeLeftPaneVisibility, leftPaneForceOpen),
    };
};
