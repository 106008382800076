import type { MapModes } from '@fv/components';
import * as React from 'react';
import type { Dispatch } from 'react';
import type { RouteComponentProps } from 'react-router';
import { generatePath } from 'react-router';

import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { MonitoringContainerEntry, MonitoringViewMode } from '~/data/monitoring';
import { MonitoringPerspective } from '~/data/monitoring';

import { MONITORING_PATH_STRUCTURE } from '../../../../consts';

import { ContainerHeader } from './components/ContainerHeader';
import type { SectionName } from './constants';
import type { ContainerDetailsPaneUserPreferences } from './preferences';
import { DETAILSPANE_CONTAINER_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized, unauthorizedSectionsNamesMemoized } from './sections';

export interface ContainerDetailsPaneProps {
    entry: MonitoringContainerEntry;
    viewMode: MonitoringViewMode;
}

export interface StateProps {
    canViewMap: boolean;
}

export interface DispatchProps {
    changeMiniMapMode: Dispatch<MapModes>;
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
    panToContainer: () => void;
}

export interface ContainerDetailsPaneInnerProps
    extends ContainerDetailsPaneProps,
        DispatchProps,
        RouteComponentProps,
        StateProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    MonitoringContainerEntry,
    ContainerDetailsPaneUserPreferences,
    SectionName
>(DETAILSPANE_CONTAINER_USERPREFERENCES_KEY);

export const ContainerDetailsPaneComponent: React.FC<ContainerDetailsPaneInnerProps> = ({
    canViewMap,
    changeMiniMapMode,
    changeMiniMapZoomLevel,
    entry,
    history,
    panToContainer,
    viewMode,
}) => {
    const handleClose = React.useCallback(() => {
        history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                perspective: MonitoringPerspective.CONTAINER,
                viewMode,
            })
        );
    }, [history, viewMode]);

    const getSections = (
        userPreferences: ContainerDetailsPaneUserPreferences,
        toggleCollapsedState: (sectionName: SectionName) => () => void,
        monitoringViewMode: MonitoringViewMode
    ) =>
        getSectionsMemoized(
            monitoringViewMode,
            entry,
            userPreferences.collapsedSections,
            toggleCollapsedState,
            userPreferences.miniMapZoomLevel,
            changeMiniMapZoomLevel,
            panToContainer,
            userPreferences.miniMapMode,
            changeMiniMapMode
        );

    return (
        <DetailsPaneComponent
            dataId="container-details-pane"
            entry={entry}
            getSections={(userPreferences, toggleCollapsedState) =>
                getSections(userPreferences, toggleCollapsedState, viewMode)
            }
            Header={ContainerHeader}
            onClose={handleClose}
            unauthorizedSections={unauthorizedSectionsNamesMemoized(canViewMap)}
        />
    );
};
