import { Box } from '@mui/material';
import * as React from 'react';

import type { Size } from '../../../../models';
import { rotateRectangle, scaleRectangle } from '../../../../services';

import { useStyles } from './styles';

export interface ImageProps {
    onLoad?: (imageSize: Size) => void;
    rotation: number;
    scale: number;
    src?: string;
}

export interface ImageInnerProps extends ImageProps {}

const Image: React.FC<ImageInnerProps> = (props) => {
    const classes = useStyles(props);
    const imageRef = React.useRef<HTMLImageElement>(null);

    const [originalImageSize, setOriginalImageSize] = React.useState<Size | undefined>();
    const transformImageSize = React.useMemo(
        () => originalImageSize && scaleRectangle(rotateRectangle(originalImageSize, props.rotation), props.scale),
        [originalImageSize, props.rotation, props.scale]
    );

    const imageLoaded = () => {
        if (imageRef.current) {
            const imageSize = { height: imageRef.current.height, width: imageRef.current.width };
            setOriginalImageSize(imageSize);
            if (props.onLoad) {
                props.onLoad(imageSize);
            }
        }
    };

    return (
        <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            visibility={transformImageSize ? undefined : 'hidden'}
            {...transformImageSize}
        >
            <img className={classes.image} onLoad={imageLoaded} ref={imageRef} src={props.src} />
        </Box>
    );
};
Image.displayName = 'Image';

export { Image };
