import type { DriverActivitiesNotification, DriverActivitiesResponse } from '~/services/ApiClient';
import type { ActionMeta, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    DRIVERACTIVITIES = 'DRIVERACTIVITIES',
}

export enum ActionTypeKeys {
    DRIVERACTIVITIES_APPLY_PENDING = 'DRIVERACTIVITIES_APPLY_PENDING',
    DRIVERACTIVITIES_CLEAR = 'DRIVERACTIVITIES_CLEAR',
    DRIVERACTIVITIES_FULFILLED = 'DRIVERACTIVITIES_FULFILLED',
    DRIVERACTIVITIES_PENDING = 'DRIVERACTIVITIES_PENDING',
    DRIVERACTIVITIES_REJECTED = 'DRIVERACTIVITIES_REJECTED',
    DRIVERACTIVITIES_UPDATE = 'DRIVERACTIVITIES_UPDATE',
}

export type DriverActivitiesPayload = Readonly<DriverActivitiesResponse>;

export type ActionTypes =
    | ActionMeta<ActionTypeKeys.DRIVERACTIVITIES_APPLY_PENDING, undefined, number>
    | ActionMeta<ActionTypeKeys.DRIVERACTIVITIES_CLEAR, undefined, number>
    | ActionMeta<ActionTypeKeys.DRIVERACTIVITIES_UPDATE, DriverActivitiesNotification, number>
    | FulfilledMeta<ActionTypeKeys.DRIVERACTIVITIES_FULFILLED, DriverActivitiesPayload, number>
    | PendingMeta<ActionTypeKeys.DRIVERACTIVITIES_PENDING, number>
    | RejectedMeta<ActionTypeKeys.DRIVERACTIVITIES_REJECTED, undefined, number>;
