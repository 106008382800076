import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import { createStyles } from '@mui/styles';

type DriverMarkerClassKey = 'assetName' | 'driverBackgroundColor' | 'driverBorderColor';

const DriverMarkerStyleRules: StyleRulesCallback<Theme, {}, DriverMarkerClassKey> = (theme: Theme) =>
    createStyles({
        assetName: {
            backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
            borderColor: theme.functionalItemsColors.assetType.driver.value,
            color: theme.functionalItemsColors.assetType.driver.value,
        },
        driverBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.assetType.driver.value,
            color: theme.functionalItemsColors.assetType.driver.contrast,
        },
        driverBorderColor: {
            borderColor: theme.functionalItemsColors.assetType.driver.value,
        },
    });

export { type DriverMarkerClassKey, DriverMarkerStyleRules };
