import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

export interface SelectionDetailsProps {
    entryType: string;
    numberOfEntries: number;
    numberOfMatchingEntries?: number;
    numberOfSelectedEntries: number;
}

export interface SelectionDetailsInnerProps extends SelectionDetailsProps, InjectedTranslationProps {}

export const SelectionDetailsComponent: React.FC<SelectionDetailsInnerProps> = ({
    entryType,
    numberOfEntries,
    numberOfMatchingEntries,
    numberOfSelectedEntries,
    t,
}) => {
    const content = isUndefined(numberOfMatchingEntries)
        ? numberOfSelectedEntries
            ? t('nof-selected-entries-out-of-a-total-of', { entryType, numberOfEntries, numberOfSelectedEntries })
            : t('nof-entries', { entryType, numberOfEntries })
        : numberOfSelectedEntries
          ? t('nof-selected-entries-out-of-a-total-of-matching-items', {
                entryType,
                numberOfEntries,
                numberOfMatchingEntries,
                numberOfSelectedEntries,
            })
          : t('nof-matching-entries', { entryType, numberOfEntries, numberOfMatchingEntries });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
};
