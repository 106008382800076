import { MenuItem } from '@mui/material';
import * as React from 'react';
import type { OptionProps as ReactSelectOptionProps } from 'react-select';

import type { Option } from '../../models';

export interface OptionProps<T> extends ReactSelectOptionProps<Option<T>, false> {}

export interface OptionInnerProps<T> extends OptionProps<T> {}

export const OptionComponentFactory: <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) => React.FC<OptionInnerProps<T>> =
    (CustomChildComponent) =>
    ({ children, data, innerProps, innerRef, isDisabled, isFocused, isSelected }) => {
        const childElement = CustomChildComponent ? (
            <CustomChildComponent option={data}>{children}</CustomChildComponent>
        ) : (
            <div data-id="value">{children}</div>
        );

        return (
            <MenuItem
                component="div"
                disabled={isDisabled}
                ref={innerRef}
                selected={isFocused}
                {...innerProps}
                data-id={`option:${data.value}`}
                style={isSelected ? { fontWeight: 500 } : undefined}
            >
                {childElement}
            </MenuItem>
        );
    };
