import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    arrow: {
        height: theme.spacing(6),
        position: 'absolute',
        top: `calc((100% - ${theme.spacing(12)}) / 2 + ${theme.spacing(3)})`,
        width: theme.spacing(6),
    },
    arrowIcon: { color: theme.palette.grey[700] },
    arrowLeft: { left: theme.spacing(-12) },
    arrowRight: { right: theme.spacing(-12) },
    backdrop: { zIndex: 1 },
    container: {
        '& .slick-arrow': {
            alignItems: 'center',
            backgroundColor: theme.palette.grey[300],
            borderRadius: '100%',
            display: 'flex !important',
            height: theme.spacing(6),
            justifyContent: 'center',
            width: theme.spacing(6),
        },
        '& .slick-dots button': {
            '&:before': { color: 'white !important', fontSize: theme.typography.fontSize / 1.75 },
        },
        '& .slick-dots li': { margin: 0 },
        '& .slick-list': {
            backgroundColor: theme.palette.grey[500],
            borderRadius: theme.shape.borderRadius * 3.5,
            display: 'block',
            margin: 0,
            overflow: 'hidden',
            padding: 0,
            position: 'relative',
        },
        '& .slick-next': { '&:before': { display: 'none' }, right: theme.spacing(-12) },
        '& .slick-prev': { '&:before': { display: 'none' }, left: theme.spacing(-12) },
        left: '50%',
        maxWidth: '700px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        zIndex: 1,
    },
    dismiss: { bottom: theme.spacing(2), left: '50%', position: 'absolute', transform: 'translateX(-50%)' },
    imgContainer: { height: `calc(100% - ${theme.spacing(27)})` },
    newsletter: { marginTop: theme.spacing() },
    slideImage: {
        objectFit: 'cover',
    },
    subtitle: {
        boxOrient: 'vertical',
        display: '-webkit-box',
        lineClamp: 3,
        margin: 0,
        overflow: 'hidden',
    },
    textContainer: {
        color: 'white',
        height: theme.spacing(33),
        margin: theme.spacing(0, 'auto'),
        maxWidth: '80%',
        paddingTop: theme.spacing(2),
        textAlign: 'center',
    },
    title: {
        boxOrient: 'vertical',
        display: '-webkit-box',
        lineClamp: 2,
        marginBottom: theme.spacing(1.5),
        overflow: 'hidden',
    },
}));

export { useStyles };
