import * as React from 'react';

import { ConfirmationDialog } from '~/components/Dialogs';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

export interface AbortRtdsSessionDialogProps {
    onAbort: () => void;
    onClose: () => void;
    sessionId: number;
}

export interface AbortRtdsSessionDialogInnerProps extends AbortRtdsSessionDialogProps, InjectedTranslationProps {}

export const AbortRtdsSessionDialogComponent: React.FC<AbortRtdsSessionDialogInnerProps> = ({
    onAbort,
    onClose,
    sessionId,
    t,
}) => {
    const abortSession = React.useCallback(() => {
        retryableRequest(() => ApiClient.abortRtdsSession(sessionId));
        onClose();
        onAbort();
    }, [onClose, sessionId, onAbort]);

    return (
        <ConfirmationDialog
            confirmationActionText={t('abort-rtds-session')}
            dataId="abort-rtds-session-dialog"
            onCancel={onClose}
            onConfirm={abortSession}
            open
            title={t('abort-rtds-session')}
        >
            <span>{t('abort-rtds-session-dialog-text')}</span>
        </ConfirmationDialog>
    );
};
