import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type LocationSectionActionButtonsClassKeys = 'copyCoordinatesButton' | 'iconMargin';

export const useStyles = makeStyles<Theme, {}, LocationSectionActionButtonsClassKeys>((theme) => ({
    copyCoordinatesButton: {
        marginLeft: theme.spacing(-1),
    },
    iconMargin: {
        marginRight: theme.spacing(),
    },
}));
