import { AssetSelector } from '@fv/components';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import type { AssetType } from '~/services/ApiClient';

import { DepotIcon } from '../Icons';

import { AssetSelectorTabs } from './components/AssetSelectorTabs';
import { checkableNodeTypes, expandableNodeTypes } from './constants';
import type { AssetNode, SceneAssetSelectorInnerProps, SupportedNode } from './models';
import { NodeTypes } from './models';
import { SceneAssetSelectorViewMode } from './preferences';
import { useDerivedAssetSelectorProps } from './useDerivedAssetSelectorProps';

export const SceneAssetSelectorComponent: FC<SceneAssetSelectorInnerProps> = (props) => {
    const {
        assetDataSets = [],
        classes,
        className,
        onAssetContextMenu,
        onSelectedAssetIdsChange,
        singleSelectionMode,
        t,
    } = props;

    const derivedProps = useDerivedAssetSelectorProps(props);

    const { removeUnauthorizedExpandedDepots, removeUnauthorizedExpandedGroups, removeUnauthorizedSelectedAssets } =
        derivedProps;

    useEffect(() => {
        removeUnauthorizedSelectedAssets();
        removeUnauthorizedExpandedDepots();
        removeUnauthorizedExpandedGroups();
    }, [removeUnauthorizedSelectedAssets, removeUnauthorizedExpandedDepots, removeUnauthorizedExpandedGroups]);

    const {
        assetTree,
        changeViewMode,
        setCollapsedAssetTypes,
        setExpandedDepots,
        setExpandedGroups,
        setSelectedAssets,
        viewMode,
    } = derivedProps;

    const assetName = assetDataSets.length === 1 ? assetDataSets[0].displayName : t('assets');
    const dataSetsHash = useMemo(
        () =>
            assetDataSets.reduce((acc, dataSet) => {
                acc[dataSet.type] = dataSet.icon;
                return acc;
            }, {}),
        [assetDataSets]
    );

    const getNodeIcon = useCallback(
        (node: SupportedNode) => {
            switch (node.type) {
                case NodeTypes.ASSET:
                    return dataSetsHash[node?.assetReference?.type];

                case NodeTypes.DEPOT:
                    return <DepotIcon />;

                case NodeTypes.GROUP:
                    break;

                case NodeTypes.ASSET_TYPE:
                    return <span />;

                default:
                    throw new Error('Unknown nodeType');
            }
        },
        [dataSetsHash]
    );

    const convertTreeNode = useCallback(
        (assetNode: SupportedNode): SupportedNode => {
            const icon = getNodeIcon(assetNode) as JSX.Element;
            return {
                ...assetNode,
                children: assetNode?.children?.map(convertTreeNode),
                hideCheckbox: !!singleSelectionMode,
                icon,
                isExpandable: true,
            };
        },
        [getNodeIcon, singleSelectionMode]
    );

    const newAssetTree = useMemo(() => assetTree.map(convertTreeNode), [assetTree, convertTreeNode]);

    const assetNodeDictionary = useMemo(() => {
        const dictionary: { [key: number | string]: SupportedNode } = {};
        const addNodeDataToDictionary = (node: SupportedNode) => {
            if (node.children) {
                node.children.map(addNodeDataToDictionary);
            }
            dictionary[node.id] = node;
        };

        newAssetTree.forEach(addNodeDataToDictionary);

        return dictionary;
    }, [newAssetTree]);

    const getAssetReferenceById = useCallback(
        (selectedAssets: (number | string)[]) => {
            return selectedAssets
                ?.filter((selectedAsset) => assetNodeDictionary[selectedAsset].type === NodeTypes.ASSET)
                .map((item: number | string) => (assetNodeDictionary[item] as AssetNode)?.assetReference);
        },
        [assetNodeDictionary]
    );

    const checkablePredicate = useCallback((item) => {
        return checkableNodeTypes.includes(item.type);
    }, []);

    const checkedKeys = useMemo(() => {
        return Object.values(assetNodeDictionary)
            .filter((assetTreeNode: SupportedNode) => checkablePredicate(assetTreeNode) && assetTreeNode.state.checked)
            .map((assetTreeNode: SupportedNode) => assetTreeNode.id);
    }, [assetNodeDictionary, checkablePredicate]);

    const expandedKeys = useMemo(() => {
        return Object.values(assetNodeDictionary)
            .filter((assetTreeNode: SupportedNode) =>
                expandableNodeTypes.indexOf(assetTreeNode.type) !== -1 && 'expanded' in assetTreeNode.state
                    ? assetTreeNode.state?.expanded
                    : false
            )
            .map((assetTreeNode: SupportedNode) => assetTreeNode.id);
    }, [assetNodeDictionary]);

    const checkHandler = useCallback(
        (value: (number | string)[]) => {
            const nextSelectedAsset = getAssetReferenceById(value);

            if (onSelectedAssetIdsChange) {
                onSelectedAssetIdsChange(nextSelectedAsset);
            }

            setSelectedAssets(nextSelectedAsset);
        },
        [setSelectedAssets, getAssetReferenceById, onSelectedAssetIdsChange]
    );

    const onContextMenu = useCallback(
        (event, id) => {
            const assetReference = (assetNodeDictionary[id] as AssetNode)?.assetReference;

            if (assetReference && onAssetContextMenu) {
                onAssetContextMenu(event, assetReference);
            }
        },
        [assetNodeDictionary, onAssetContextMenu]
    );

    const collapseHandler = useCallback(
        (expandedNodesIdList) => {
            if (SceneAssetSelectorViewMode.GROUPS === viewMode) {
                const expandedGroupsIds = expandedNodesIdList
                    .filter(
                        (groupId: number | string) => assetNodeDictionary[Number(groupId)]?.type === NodeTypes.GROUP
                    )
                    .map((groupId: number | string) => Number(groupId));
                setExpandedGroups(expandedGroupsIds);
                return;
            }
            if (SceneAssetSelectorViewMode.DEPOTS === viewMode) {
                const expandedDepotsIds = expandedNodesIdList
                    .filter(
                        (depotId: number | string) => assetNodeDictionary[Number(depotId)]?.type === NodeTypes.DEPOT
                    )
                    .map((depotId: number | string) => Number(depotId));
                setExpandedDepots(expandedDepotsIds);
                return;
            }
            if (SceneAssetSelectorViewMode.ASSETS === viewMode) {
                const collapsedAssetTypesIds = Object.values(assetNodeDictionary)
                    .filter(
                        (assetTreeNode) =>
                            assetTreeNode?.type === NodeTypes.ASSET_TYPE &&
                            expandedNodesIdList.indexOf(assetTreeNode.id) === -1
                    )
                    .map((assetTypeNode) => assetTypeNode.id as AssetType);
                setCollapsedAssetTypes(collapsedAssetTypesIds);
            }
        },
        [assetNodeDictionary, setCollapsedAssetTypes, setExpandedDepots, setExpandedGroups, viewMode]
    );

    const assetSelectorKey = `${viewMode}_${Object.keys(assetNodeDictionary).join()}`;

    return (
        <div className={classNames(classes.assetSelector, className)} data-id="asset-selector">
            <AssetSelectorTabs assetName={assetName} changeViewMode={changeViewMode} viewMode={viewMode} />

            <AssetSelector
                checkablePredicate={checkablePredicate}
                checkedKeys={checkedKeys}
                expandedKeys={expandedKeys}
                items={newAssetTree}
                key={assetSelectorKey}
                onCheck={checkHandler}
                onCollapse={collapseHandler}
                onContextMenu={onContextMenu}
                searchingItemsName={assetName}
                singleSelectionMode={singleSelectionMode}
            />
        </div>
    );
};
