import { isEmpty } from '~/libs/utility';
import type { AssetGroup, AssetReference, Depot } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

import { getAssetGroupAssetIds, getAssetGroupIds } from './assetgroups';
import { createUnassignedDepot, createUnassignedGroup } from './createUnassignedCategories';
import { getDepotAssetIds, getDepotIds } from './depots';
import { assetReferenceHashDifference } from './hashutils';

export const ensureUnassignedGroup = (
    assetGroups: AssetGroup[],
    assetIds: AssetReferenceHash<AssetReference>
): { assetGroupIds: number[]; assetGroups: AssetGroup[] } => {
    const assignedAssetIds = getAssetGroupAssetIds(assetGroups, assetIds);
    const unassignedAssetIds = assetReferenceHashDifference(assetIds, assignedAssetIds);

    const ensuredAssetGroups = Object.values(unassignedAssetIds).reduce((acc, v) => acc || !isEmpty(v), false)
        ? [...assetGroups, createUnassignedGroup(unassignedAssetIds)]
        : assetGroups;

    return {
        assetGroupIds: getAssetGroupIds(ensuredAssetGroups),
        assetGroups: ensuredAssetGroups,
    };
};

export const ensureUnassignedDepot = (
    depots: Depot[],
    assetIds: AssetReferenceHash<AssetReference>
): { depotIds: number[]; depots: Depot[] } => {
    const assignedAssetIds = getDepotAssetIds(depots, assetIds);
    const unassignedAssetIds = assetReferenceHashDifference(assetIds, assignedAssetIds);

    const ensuredDepots = Object.values(unassignedAssetIds).reduce((acc, v) => acc || !isEmpty(v), false)
        ? [...depots, createUnassignedDepot(unassignedAssetIds)]
        : depots;

    return {
        depotIds: getDepotIds(ensuredDepots),
        depots: ensuredDepots,
    };
};
