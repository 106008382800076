import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { BubbleProps } from './component';

export type BubbleClassKey =
    | 'body'
    | 'content'
    | 'footer'
    | 'leftTailed'
    | 'nonTailed'
    | 'rightTailed'
    | 'root'
    | 'surface'
    | 'tail'
    | 'tailContainer'
    | 'title';

export const BubbleStyleRules: StyleRulesCallback<Theme, BubbleProps, BubbleClassKey> = (theme: Theme) =>
    createStyles({
        body: {},
        content: {
            display: 'inline',
        },
        footer: {
            bottom: theme.spacing(-0.5),
            display: 'inline',
            float: 'right',
            marginLeft: theme.spacing(),
            position: 'relative',
            whiteSpace: 'nowrap',
            width: ({ fullWidthFooter }) => (fullWidthFooter ? '100%' : 'auto'),
        },
        leftTailed: {
            '& $tail': {
                boxShadow: '-1px 0px 1px 0px rgba(0,0,0,0.12)',
                left: -5,
            },
            '& $tailContainer': {
                boxShadow: '-2px 0px 2px -2px rgba(0,0,0,0.2)',
                left: -8,
                transform: 'rotate(-45deg)',
            },
            borderTopLeftRadius: 0,
        },
        nonTailed: {},
        rightTailed: {
            '& $tail': {
                boxShadow: '0px -1px 1px 0px rgba(0,0,0,0.12)',
                right: -5,
            },
            '& $tailContainer': {
                boxShadow: '2px 0px 2px -2px rgba(0,0,0,0.2)',
                right: -8,
                transform: 'rotate(45deg)',
            },
            borderTopRightRadius: 0,
        },
        root: {
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'inline-block',
            margin: theme.spacing(0, 1),
            maxWidth: `calc(100% - ${theme.spacing(2)})`,
            padding: theme.spacing(1, 1.5),
            position: 'relative',
        },
        surface: {
            backgroundColor: theme.palette.background.paper,
        },
        tail: {
            height: 10,
            pointerEvents: 'auto',
            position: 'absolute',
            top: 5,
            transform: 'rotate(45deg)',
            width: 10,
        },
        tailContainer: {
            height: 16,
            overflow: 'hidden',
            pointerEvents: 'none',
            position: 'absolute',
            top: -10,
            width: 16,
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            textAlign: 'left',
        },
    });
