import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import type { ServicesSecurables } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { SectionName } from '../../constants';
import type { CommonSectionProps } from '../../models';

export interface ServicesSectionProps extends CommonSectionProps {
    vehicleAdministrationServices: ServicesSecurables;
}

export interface ServicesSectionInnerProps extends ServicesSectionProps, InjectedTranslationProps {}

const addItemIfServiceEnabled = (items: KeyValueItem[], item: KeyValueItem, serviceEnabled: boolean): void => {
    if (serviceEnabled) {
        items.push(item);
    }
};

export const ServicesSectionComponent: React.FunctionComponent<ServicesSectionInnerProps> = ({
    adminVehicle,
    dragHandleElement,
    isCollapsed,
    t,
    toggleCollapsed,
    vehicleAdministrationServices,
}) => {
    const keyValueItems: KeyValueItem[] = [];
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('alerts-and-panic'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.alertsAndPanicEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'alerts-and-panic',
        },
        vehicleAdministrationServices.alertsAndPanic
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('driver-cards'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.driverCardsEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'driver-cards',
        },
        vehicleAdministrationServices.driverCards
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('driver-coach'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.driverCoachEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'driver-coach',
        },
        vehicleAdministrationServices.driverCoach
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('driver-hours'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.driverHoursEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'driver-hours',
        },
        vehicleAdministrationServices.driverHours
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('dse'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.dseEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'dse',
        },
        vehicleAdministrationServices.drivingStyleEvaluation
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('frequent-positioning'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.frequentPositioningEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'frequent-positioning',
        },
        vehicleAdministrationServices.frequentPositioning
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('fuel-site-advice'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.fuelSiteAdviceEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'fuel-site-advice',
        },
        vehicleAdministrationServices.fuelSiteAdvice
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('kpi2'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.kpi2Enabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'kpi2',
        },
        vehicleAdministrationServices.kpi2
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('landmarks'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.landmarksEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'landmarks',
        },
        vehicleAdministrationServices.landmarks
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('rtds'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.rtdsEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'rtds',
        },
        vehicleAdministrationServices.rtds
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('text-messaging'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.textMessagingEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'text-messaging',
        },
        vehicleAdministrationServices.textMessaging
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('trailers'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.trailersEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'trailers',
        },
        vehicleAdministrationServices.trailers
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            label: t('workflow'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(t, ClassificationType.WorkflowType, adminVehicle.settings.workflowType)}
                </span>
            ),
            valueDataId: 'workflow',
        },
        vehicleAdministrationServices.workflow
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('guided-navigation'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.guidedNavigationEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'guided-navigation',
        },
        vehicleAdministrationServices.guidedNavigation
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            label: t('navigation'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(t, ClassificationType.NavigationType, adminVehicle.settings.navigationType)}
                </span>
            ),
            valueDataId: 'navigation',
        },
        vehicleAdministrationServices.navigation
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            label: t('image-capturing'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(
                        t,
                        ClassificationType.ImageCapturingType,
                        adminVehicle.settings.imageCapturingType
                    )}
                </span>
            ),
            valueDataId: 'image-capturing',
        },
        vehicleAdministrationServices.imageCapturing
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('tire-control'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.tireControlEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'tire-control',
        },
        vehicleAdministrationServices.tireControl
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('app-store'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.appStoreEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'app-store',
        },
        vehicleAdministrationServices.appStore
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('truck-identification'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.truckIdentificationEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'truck-identification',
        },
        vehicleAdministrationServices.truckIdentification
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('trailer-control-service'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.trailerControlServiceEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'trailer-control-service',
        },
        vehicleAdministrationServices.trailerControlService
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            label: t('start-inhibitor'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(
                        t,
                        ClassificationType.InhibitorServiceType,
                        adminVehicle.settings.inhibitorServiceType
                    )}
                </span>
            ),
            valueDataId: 'start-inhibitor',
        },
        vehicleAdministrationServices.inhibitorService
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            label: t('tell-tale-service'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(
                        t,
                        ClassificationType.TellTaleServiceType,
                        adminVehicle.settings.tellTaleServiceType
                    )}
                </span>
            ),
            valueDataId: 'tell-tale-service',
        },
        vehicleAdministrationServices.tellTaleService
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('j1939-data-service'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.j1939DataServiceEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'j1939-data-service',
        },
        vehicleAdministrationServices.j1939DataService
    );
    addItemIfServiceEnabled(
        keyValueItems,
        {
            isIconValue: true,
            label: t('navigation-eta'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.navigationEtaEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            valueDataId: 'navigation-eta',
        },
        vehicleAdministrationServices.navigationEta
    );

    const unauthorizedToSeeMessagesElement = (
        <SectionContentMessage dataId="not-authorized-to-see-services">
            {t('see-services-for-vehicle-not-authorized')}
        </SectionContentMessage>
    );

    const sectionContent =
        keyValueItems.length === 0 ? (
            unauthorizedToSeeMessagesElement
        ) : (
            <KeyValueList list={keyValueItems} listDataId="services-list" />
        );

    return (
        <ReorderableSection
            dataId="vehicleadministration-services-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.VEHICLEADMINISTRATIONSERVICES)}
            toggleCollapsed={toggleCollapsed}
        >
            {sectionContent}
        </ReorderableSection>
    );
};
