import type { WithStyles } from '@mui/styles';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { WidgetDialog } from '~/components/Dialogs/WidgetDialog';
import { CloseIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';
import { subDates } from '~/libs/dates';
import type { ChartPeriod } from '~/services/ApiClient';
import { formatDate } from '~/services/Formatters';

import { TimeSeriesDialogContentFactory } from './components/TimeSeriesDialogContent';
import type { TimeSeriesChartDefinition } from './models';
import type { TimeSeriesDialogReduxProps } from './redux';
import type { TimeSeriesDialogClassKey } from './styles';

export interface TimeSeriesDialogProps<T> {
    charts: TimeSeriesChartDefinition<T>[];
    defaultActiveChartName?: string;
    getData: (startDate: Date, stopDate: Date) => Promise<T[]>;
    onClose: () => void;
    title: string;
}

export interface TimeSeriesDialogInnerProps<T>
    extends TimeSeriesDialogProps<T>,
        TimeSeriesDialogReduxProps<T>,
        InjectedTranslationProps,
        WithStyles<TimeSeriesDialogClassKey> {}

const now = new Date();

export const TimeSeriesDialogComponent = <T,>(props: TimeSeriesDialogInnerProps<T>): JSX.Element => {
    const {
        charts,
        classes,
        clearDialogData,
        defaultActiveChartName,
        getData,
        getDialogData,
        onClose,
        t,
        timeSeriesData,
        title,
    } = props;

    const chartRef = useRef<HTMLDivElement>(null);
    const closeRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const TimeSeriesDialogContent = useMemo(() => TimeSeriesDialogContentFactory<T>(), []);

    const [activeChart, setActiveChart] = useState<TimeSeriesChartDefinition<T>>(() => {
        const chart = charts.find((c) => c.name === defaultActiveChartName);
        return chart ?? charts[0];
    });

    const chartPeriod = useMemo(() => ({ startDate: subDates(now, { days: 2 }), stopDate: now }), []);
    const [viewPeriod, setViewPeriod] = useState<ChartPeriod>(chartPeriod);

    const getExportFilename = useCallback(() => {
        return t('time-series-dialog-export-filename', {
            dataSetName: activeChart.title,
            startDateTime: formatDate(viewPeriod.startDate, 'yyyyMMdd HHmm'),
            stopDateTime: formatDate(viewPeriod.stopDate, 'yyyyMMdd HHmm'),
            title,
        });
    }, [t, title, activeChart, viewPeriod]);

    const excludeElements = (node: Node) => node !== closeRef.current && node !== dropdownRef.current;
    const downloadTemperaturesGraph = useCallback(() => {
        if (chartRef.current) {
            domtoimage
                .toBlob(chartRef.current, {
                    bgcolor: 'white',
                    filter: excludeElements,
                    style: { margin: 0, marginInlineStart: 0, maxHeight: 'inherit' },
                })
                .then((blob) => {
                    fileDownload(blob, `${getExportFilename()}.png`);
                });
        }
    }, [chartRef, getExportFilename]);

    useEffect(() => {
        getDialogData(chartPeriod.startDate, chartPeriod.stopDate, getData);
    }, [getDialogData, getData, chartPeriod]);

    useEffect(() => {
        return clearDialogData;
    }, [clearDialogData]);

    const actions = useMemo(() => {
        const closeButton = (
            <div key="close" ref={closeRef}>
                <TitledIconButton color="inherit" data-id="close" onClick={onClose} title={t('close')}>
                    <CloseIcon />
                </TitledIconButton>
            </div>
        );
        return [closeButton];
    }, [onClose, t]);

    return (
        <WidgetDialog
            chartRef={chartRef}
            headerActions={actions}
            onClose={onClose}
            open
            testId="temperatures-graph-dialog"
            title={<span data-id="dialog-title">{title}</span>}
        >
            <div className={classes.main}>
                <TimeSeriesDialogContent
                    activeChart={activeChart}
                    chartPeriod={chartPeriod}
                    chartRef={dropdownRef}
                    charts={charts}
                    data={timeSeriesData}
                    downloadImage={downloadTemperaturesGraph}
                    getExportFilename={getExportFilename}
                    onActiveChartChanged={setActiveChart}
                    onViewPeriodChanged={setViewPeriod}
                    viewPeriod={viewPeriod}
                />
            </div>
        </WidgetDialog>
    );
};
