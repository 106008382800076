import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ReverseInfiniteScrollClassKey = 'content' | 'itemsContainer' | 'pusher' | 'root' | 'statusIndicator';

export const ReverseInfiniteScrollStyleRules: StyleRulesCallback<Theme, {}, ReverseInfiniteScrollClassKey> = () =>
    createStyles({
        content: {},
        itemsContainer: {},
        pusher: {
            flexGrow: 1,
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            position: 'relative',
        },
        statusIndicator: {},
    });
