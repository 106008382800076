import type { FC } from 'react';

import type { ClusterGroupProps } from './model';

const ClusterGroup: FC<ClusterGroupProps> = (props) => {
    const { count, dataId } = props;

    return (
        <div
            data-testid={dataId}
            style={{
                alignItems: 'center',
                display: 'flex',
                height: 24,
                justifyContent: 'center',
                width: 24,
            }}
        >
            <div
                style={{
                    backgroundColor: '#000000',
                    borderColor: '#FFFFFF',
                    borderRadius: 12,
                    borderStyle: 'solid',
                    borderWidth: 2,
                    boxShadow: `
                        0px 1px 5px 0px #bdbdbd,
                        0px 2px 2px 0px #9e9e9e,
                        0px 3px 1px -2px #757575
                    `,
                    color: '#FFFFFF',
                    height: 24,
                    lineHeight: '20px',
                    minWidth: 24,
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    position: 'absolute',
                    textAlign: 'center',
                }}
            >
                {count}
            </div>
        </div>
    );
};

ClusterGroup.displayName = 'ClusterGroup';
export { ClusterGroup };
