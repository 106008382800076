import type { Dispatch } from 'redux';

import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage/models';
import { getRtdsScheduleChangelogsAction } from '~/data/rtdsschedules';
import { reportError } from '~/reporting';
import type { ResolvedRtdsScheduleChangelogChanges } from '~/services/ApiClient';

import type { RtdsScheduleChangelogsStoreState } from './reducers';

export interface RtdsScheduleChangelogsStateProps {
    changelogsEntries: ChangelogEntry<ResolvedRtdsScheduleChangelogChanges>[];
    loading: boolean;
    previousScheduleId?: number;
    rejected: boolean;
}

export interface RtdsScheduleChangelogsDispatchProps {
    getRtdsScheduleChangelogs: (scheduleId: number) => void;
}

export interface RtdsScheduleChangelogsRedux
    extends RtdsScheduleChangelogsStateProps,
        RtdsScheduleChangelogsDispatchProps {}

export const mapStateToProps = (state: RtdsScheduleChangelogsStoreState): RtdsScheduleChangelogsStateProps => ({
    changelogsEntries: state.rtdsSchedulesChangelogsEntries,
    loading: state.rtdsSchedulesChangelogs.pending,
    previousScheduleId: state.previousChangelog,
    rejected: state.rtdsSchedulesChangelogs.rejected,
});

export const mapDispatchProps = (dispatch: Dispatch): RtdsScheduleChangelogsDispatchProps => ({
    getRtdsScheduleChangelogs: (scheduleId: number) => {
        dispatch(getRtdsScheduleChangelogsAction(scheduleId)).catch(reportError);
    },
});
