import { OutlinedInput as MuiOutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

interface OutlinedInputProps {
    warning?: boolean;
}

const OutlinedInput = styled(MuiOutlinedInput, {
    shouldForwardProp: (propName) => propName !== 'warning',
})<OutlinedInputProps>(({ theme, warning }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: warning ? theme.palette.warning.main : '',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: warning ? theme.palette.warning.light : '',
    },
    '&.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: warning ? theme.palette.warning.dark : '',
    },
}));

export { OutlinedInput };
