import { Marker } from '@fv/components';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';
import { withProps } from 'react-recompose';

import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';

import { VehicleMarkerIcon } from './components/VehicleMarkerIcon';
import { VehicleMarkerIconBadge } from './components/VehicleMarkerIconBadge';
import { VehicleMarkerNameFormatter } from './components/VehicleMarkerNameFormatter';
import type { VehicleMarkerClassKey } from './styles';

export interface VehicleMarkerProps {
    animationDuration: number;
    entry: MonitoringVehicleEntry;
    selected: boolean;
    updateAnimationVisible: boolean;
    vehicleDisplayNameFormat?: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface VehicleMarkerInnerProps extends VehicleMarkerProps, WithStyles<VehicleMarkerClassKey> {}

export const VehicleMarkerComponent: FC<VehicleMarkerInnerProps> = (props) => {
    const { classes, entry, vehicleDisplayNameFormat, ...rest } = props;

    const NameFormatterComponent = useMemo(
        () => vehicleDisplayNameFormat && withProps({ vehicleDisplayNameFormat })(VehicleMarkerNameFormatter),
        [vehicleDisplayNameFormat]
    );

    const overriddenMarkerClasses = useMemo(() => {
        const driverActivity = entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity?.key;

        return {
            assetName: classes.assetName,
            highlight: classNames({
                [classes.vehicleBorderColor]: isUndefined(driverActivity),
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
            }),
            iconBox: classNames({
                [classes.vehicleBackgroundColor]: isUndefined(driverActivity),
                [classes[`driverActivity${driverActivity}BackgroundColor`]]: !isUndefined(driverActivity),
            }),
            selected: classNames({
                [classes.vehicleBorderColor]: isUndefined(driverActivity),
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
            }),
        };
    }, [classes, entry.vehicleDriverHoursStatus]);

    return (
        <Marker<MonitoringVehicleEntry>
            {...rest}
            classes={overriddenMarkerClasses}
            dataId={`vehicle-marker:${entry.vehicle.id}`}
            entry={entry}
            Icon={VehicleMarkerIcon}
            IconBadge={VehicleMarkerIconBadge}
            NameFormatter={NameFormatterComponent}
        />
    );
};
