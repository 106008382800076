import type { HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import type { RetryableFn } from 'promise-retry';
import promiseRetry from 'promise-retry';
import { v1 as uuidv1 } from 'uuid';

import type { Dictionary } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import { reportError } from '~/reporting';
import type { Notification } from '~/services/ApiClient';
import { NotificationSubscription } from '~/services/ApiClient';
import { getConfig } from '~/services/Config';

import { connectionTimeoutDurationMillis } from './constants';
import type { ConnectionStatusChangedHandler, Subscription } from './contracts';
import { ConnectionStatus } from './contracts';
import type { LockSubscriptionArgs, SubscribeArgs, WaitForSubscriptionToGetUnlockedArgs } from './models';

export class Client {
    private connection: HubConnection;
    private connectionStatus = ConnectionStatus.DISCONNECTED;
    private connectionStatusChangedHandlers: ConnectionStatusChangedHandler[] = [];
    private connectionTimeout?: number = undefined;
    private handleNotification = (subscriptionId: string, notification: unknown) => {
        const subscription = this.handlerBySubscriptionId[subscriptionId];
        if (!subscription) {
            return;
        }

        const parsed = subscription.parse(notification);
        subscription.handler(parsed);
    };

    private handlerBySubscriptionId: Dictionary<Subscription> = {};
    private isOpening?: Promise<void>;
    private jwt?: string;

    private subscriptions: Dictionary<Subscription> = {};

    constructor(url: string) {
        this.connection = new HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => this.jwt || '' }).build();

        this.connection.onclose(async () => {
            if (!this.isDisconnecting() && !this.isDisconnected()) {
                /**
                 * Set current connection status without notifying listeners
                 * and try to restore connection & subscriptions.
                 */
                this.connectionStatus = ConnectionStatus.CONNECTIONLOST;
                await this.restore();
            }
        });
    }

    private async clearAllSubscriptions(): Promise<void> {
        await Promise.all(Object.keys(this.subscriptions).map((key) => this.clearSubscription(key)));
    }

    private async clearSubscription(key: string): Promise<void> {
        if (!this.subscriptions[key]) {
            return;
        }

        await this.lockSubscription({
            fn: async () => {
                window.clearTimeout(this.subscriptions[key].renewSubscriptionTimeout);

                const { notificationSubscription } = this.subscriptions[key];
                if ((this.isConnected() || this.isDisconnecting()) && notificationSubscription) {
                    await this.connection.send('Unsubscribe', notificationSubscription.id);
                }
            },
            key,
        });

        const { notificationSubscription } = this.subscriptions[key];
        if (notificationSubscription) {
            delete this.handlerBySubscriptionId[notificationSubscription.id];
        }
        delete this.subscriptions[key];
    }

    private hasLostConnection() {
        return this.connectionStatus === ConnectionStatus.CONNECTIONLOST;
    }

    private isConnected() {
        return this.connectionStatus === ConnectionStatus.CONNECTED;
    }

    private isDisconnected() {
        return this.connectionStatus === ConnectionStatus.DISCONNECTED;
    }

    private isDisconnecting() {
        return this.connectionStatus === ConnectionStatus.DISCONNECTING;
    }

    private isTryingToReconnect() {
        return this.connectionStatus === ConnectionStatus.TRYINGTORECONNECT;
    }

    /**
     * Lock subscription before using it and release at the end.
     * If subscription is locked, by default wait max 5s, if it
     * does not get released in time an exception is thrown.
     */
    private async lockSubscription({ fn, key, maxWaitingTime = 5000 }: LockSubscriptionArgs): Promise<void> {
        await this.waitForSubscriptionToGetUnlocked({ key, maxWaitingTime });

        try {
            this.subscriptions[key].locked = true;
            await fn();
        } finally {
            this.subscriptions[key].locked = false;
        }
    }

    /**
     * After a half of subscription lifetime renew it to avoid
     * some possible delays that could cause to miss some messages.
     */
    private renewSubscription(key: string, expirationDateTime: Date): number {
        return window.setTimeout(
            async () => {
                await this.lockSubscription({
                    fn: async () => {
                        window.clearTimeout(this.subscriptions[key].renewSubscriptionTimeout);

                        const { notificationSubscription } = this.subscriptions[key];
                        if (this.isConnected() && notificationSubscription) {
                            const response = await this.connection.invoke<NotificationSubscription>(
                                'RenewSubscription',
                                notificationSubscription.id
                            );
                            const updatedNotificationSubscription = NotificationSubscription.fromJS(response);

                            this.subscriptions[key] = {
                                ...this.subscriptions[key],
                                notificationSubscription: updatedNotificationSubscription,
                                renewSubscriptionTimeout: this.renewSubscription(
                                    key,
                                    updatedNotificationSubscription.expirationDateTime
                                ),
                            };
                        }
                    },
                    key,
                });
            },
            (expirationDateTime.getTime() - new Date().getTime()) / 2
        );
    }

    private async restoreAllSubscriptions(): Promise<void> {
        await Promise.all(Object.keys(this.subscriptions).map((key) => this.restoreSubscription(key)));
    }

    private async restoreConnection(): Promise<void> {
        await promiseRetry<void>(
            (retry: RetryableFn<void>) => {
                if (this.isDisconnecting() || this.isDisconnected()) {
                    throw new Error('Connection has been disconnected.');
                }

                return this.connection.start().catch(retry);
            },
            {
                retries: getConfig().maxRetriesPerFailedRequest,
            }
        );
    }

    private async restoreSubscription(key: string): Promise<void> {
        await this.lockSubscription({
            fn: async () => {
                window.clearTimeout(this.subscriptions[key].renewSubscriptionTimeout);
                const { notificationSubscription, resourceFilter, resourceType } = this.subscriptions[key];

                if (notificationSubscription) {
                    delete this.handlerBySubscriptionId[notificationSubscription.id];
                }

                const response = await this.connection.invoke<NotificationSubscription>(
                    'Subscribe',
                    resourceType,
                    resourceFilter?.toJSON()
                );
                const updatedNotificationSubscription = NotificationSubscription.fromJS(response);

                this.subscriptions[key] = {
                    ...this.subscriptions[key],
                    notificationSubscription: updatedNotificationSubscription,
                    renewSubscriptionTimeout: this.renewSubscription(
                        key,
                        updatedNotificationSubscription.expirationDateTime
                    ),
                };

                this.handlerBySubscriptionId[updatedNotificationSubscription.id] = this.subscriptions[key];
            },
            key,
        });
    }

    /**
     * Changes the current connection status and notifies
     * listeners the changed.
     */
    private setConnectionStatus(connectionStatus: ConnectionStatus) {
        const prevConnectionStatus = this.connectionStatus;
        this.connectionStatus = connectionStatus;
        this.connectionStatusChangedHandlers.forEach((handler) => {
            handler(connectionStatus, prevConnectionStatus);
        });
    }

    private async waitForSubscriptionToGetUnlocked({
        key,
        maxWaitingTime,
        waitedTime = 0,
    }: WaitForSubscriptionToGetUnlockedArgs): Promise<void> {
        if (!this.subscriptions[key]) {
            throw new Error(`Subscription ${key} has been disposed.`);
        }

        if (this.subscriptions[key].locked) {
            if (waitedTime < maxWaitingTime) {
                const tickTimeout = 100;
                await new Promise<void>((resolve, reject) => {
                    window.setTimeout(() => {
                        this.waitForSubscriptionToGetUnlocked({
                            key,
                            maxWaitingTime,
                            waitedTime: waitedTime + tickTimeout,
                        }).then(resolve, reject);
                    }, tickTimeout);
                });
            } else {
                throw new Error(`Subscription ${key} is in use.`);
            }
        }
    }

    public async close(): Promise<void> {
        if (this.isDisconnected() || this.isDisconnecting()) {
            return;
        }

        this.setConnectionStatus(ConnectionStatus.DISCONNECTING);
        this.connection.off('Notification');
        await this.clearAllSubscriptions();
        await this.connection.stop();
        this.setConnectionStatus(ConnectionStatus.DISCONNECTED);
    }

    public getConnectionStatus(): ConnectionStatus {
        return this.connectionStatus;
    }

    public onConnectionStatusChanged(fn: ConnectionStatusChangedHandler): Disposable {
        this.connectionStatusChangedHandlers.push(fn);

        return {
            dispose: () => {
                this.connectionStatusChangedHandlers = this.connectionStatusChangedHandlers.filter(
                    (handler) => fn !== handler
                );
            },
        };
    }

    public async open(): Promise<void> {
        if (this.isConnected() || this.isTryingToReconnect()) {
            return;
        }

        if (this.hasLostConnection()) {
            await this.restore();
            return;
        }

        if (this.isOpening) {
            await this.isOpening;
            return;
        }

        this.isOpening = this.connection
            .start()
            .then(() => {
                this.setConnectionStatus(ConnectionStatus.CONNECTED);
                this.connection.on('Notification', this.handleNotification);
            })
            .finally(() => {
                this.isOpening = undefined;
            });

        await this.isOpening;
    }

    /**
     * Try to restore connection and subscriptions only
     * when connection has been lost.
     */
    public async restore(): Promise<void> {
        if (!this.hasLostConnection()) {
            return;
        }

        this.setConnectionStatus(ConnectionStatus.TRYINGTORECONNECT);

        try {
            await this.restoreConnection();
            await this.restoreAllSubscriptions();

            this.setConnectionStatus(ConnectionStatus.CONNECTED);
        } catch {
            if (!this.isDisconnecting() && !this.isDisconnected()) {
                this.setConnectionStatus(ConnectionStatus.CONNECTIONLOST);
            }
        }
    }

    public setJwt(jwt: string | undefined): void {
        if (this.jwt === jwt) {
            return;
        }

        this.jwt = jwt;
    }

    public async subscribe<T extends Notification>(args: SubscribeArgs<T>): Promise<Disposable> {
        const { handler, parse, resourceFilter, resourceType } = args;

        await this.open();

        /**
         * Clear connection timeout when registering the first subscription
         */
        if (!Object.keys(this.subscriptions).length && this.connectionTimeout) {
            window.clearTimeout(this.connectionTimeout);
            this.connectionTimeout = undefined;
        }

        const key = uuidv1();
        this.subscriptions[key] = {
            handler,
            parse,
            resourceFilter,
            resourceType,
        };

        await this.lockSubscription({
            fn: async () => {
                const response = await this.connection.invoke<NotificationSubscription>(
                    'Subscribe',
                    resourceType,
                    resourceFilter?.toJSON()
                );
                const notificationSubscription = NotificationSubscription.fromJS(response);

                this.subscriptions[key] = {
                    ...this.subscriptions[key],
                    notificationSubscription,
                    renewSubscriptionTimeout: this.renewSubscription(key, notificationSubscription.expirationDateTime),
                };
                this.handlerBySubscriptionId[notificationSubscription.id] = this.subscriptions[key];
            },
            key,
        }).catch((error) => {
            reportError({ error, resourceType });
            delete this.subscriptions[key];
        });

        return {
            /**
             * Also starts a timer to automatically close the connection after 15 min of inactivity of new subscriptions
             */
            dispose: async () => {
                await this.clearSubscription(key);

                if (!Object.keys(this.subscriptions).length && !this.connectionTimeout) {
                    this.connectionTimeout = window.setTimeout(async () => {
                        await this.close();
                    }, connectionTimeoutDurationMillis);
                }
            },
        };
    }
}
