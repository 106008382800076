import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import type { MonitoringDriverEntry } from '~/data/monitoring';
import { filterBySearchQuery } from '~/services/Filter';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

export const createFilter =
    (displayPreferences: DisplayUserPreferences) =>
    (driverEntries: MonitoringDriverEntry[], searchQuery: string): MonitoringDriverEntry[] => {
        const textAccessors = (entry: MonitoringDriverEntry): Array<string | undefined> => {
            const { driver } = entry;
            const coDriver = driver?.coDriver;

            return [
                driver && driver?.driver ? formatDriverName(driver.driver, displayPreferences.driverDisplayFormat) : '',
                coDriver && formatDriverName(coDriver, displayPreferences.driverDisplayFormat),
                driver && driver?.vehicle
                    ? formatVehicleName(driver.vehicle, displayPreferences.vehicleDisplayFormat)
                    : '',
            ];
        };

        return filterBySearchQuery(driverEntries, textAccessors, searchQuery);
    };
