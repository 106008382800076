import { formatDate, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareDateObjects } from '~/services/Sorting';

import { DateFormatter } from '../Formatters';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export const createDateColumn = <T>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Date | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => ({
    ...options,
    compare: compareDateObjects,
    dataType: 'date',
    excelCellFormat: 'm/d/yy',
    getCellValue,
    groupingCriteria: groupingCriteriaFactory(formatDate),
    groupTitle,
    groupValueFormatterComponent: DateFormatter,
    name,
    title,
    valueFormatterComponent: DateFormatter,
    valueTextFormatter: valueTextFormatterFactory(formatDate),
});
