import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { HistoryVehicleEquals } from '~/scenes/History/historyVehicleEntryEquals';
import type { DeviceType, Securables } from '~/services/ApiClient';

import type { HistoryVehicleEntry } from '../../../../models';

import { filterHistoryDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { HISTORY_LIST_VEHICLE_USERPREFERENCES_KEY, defaultVehicleListUserPreferences } from './preferences';

export type VehicleListViewProps = Omit<SceneListViewProps<HistoryVehicleEntry>, 'columns' | 'gridDataId'>;

export type VehicleListViewHeaderProps = Omit<
    SceneListViewHeaderProps<HistoryVehicleEntry>,
    'columns' | 'excelSheetTitle'
>;

const VehicleListViewComponent = SceneListViewFactory<HistoryVehicleEntry>(
    HISTORY_LIST_VEHICLE_USERPREFERENCES_KEY,
    defaultVehicleListUserPreferences,
    getRowId,
    HistoryVehicleEquals,
    filterHistoryDataSourceMemoized
);

const VehicleListViewHeaderComponents = SceneListViewHeaderFactory<HistoryVehicleEntry>(
    HISTORY_LIST_VEHICLE_USERPREFERENCES_KEY,
    defaultVehicleListUserPreferences,
    filterHistoryDataSourceMemoized,
    'history'
);

interface ReduxProps {
    deviceTypes: NumericDictionary<DeviceType>;
    securables: Securables;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    deviceTypes: settingDataSelector(SettingsKey.DEVICETYPES),
    securables: settingDataSelector(SettingsKey.SECURABLES),
});

export const VehicleListView = compose<SceneListViewProps<HistoryVehicleEntry>, VehicleListViewProps>(
    setDisplayName('VehicleListView'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences?.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem,
            ownProps.deviceTypes
        ),
        gridDataId: 'history-grid-vehicle',
    }))
)(VehicleListViewComponent);

export const VehicleListViewHeader = compose<SceneListViewHeaderProps<HistoryVehicleEntry>, VehicleListViewHeaderProps>(
    setDisplayName('VehicleListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem,
            ownProps.deviceTypes
        ),
        excelSheetTitle: ownProps.t('history'),
    }))
)(VehicleListViewHeaderComponents);
