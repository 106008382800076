export const CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY = 'conversations-summary-widget';

export interface ConversationsSummaryWidgetUserPreferences {
    isMinimized: boolean;
    showOnlySelectedVehicles: boolean;
    showOnlyUnreadConversations: boolean;
}

export const defaultConversationsSummaryWidgetUserPreferences: ConversationsSummaryWidgetUserPreferences = {
    isMinimized: false,
    showOnlySelectedVehicles: true,
    showOnlyUnreadConversations: false,
};

export const requiredConversationsSummaryWidgetUserPreferences = {
    defaultState: defaultConversationsSummaryWidgetUserPreferences,
    key: CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY,
};
