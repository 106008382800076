import { Grid, ListItem } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import type { OptionProps } from './models';

const Option: React.FC<OptionProps> = (props) => {
    const { classes, isMulticolumn, option } = props;
    const options = (Array.isArray(option?.values) && option.values) || [];
    const brandedClass = classes?.brandedRow ?? '';

    return isMulticolumn ? (
        <Grid
            className={classNames(classes?.optionCellRow, { [brandedClass]: option.branded })}
            container
            key={`autocomplete-dropdown-input-list-${option.key}`}
        >
            {options.map((e: string) => (
                <Grid className={classes?.optionItemText} item key={`autocomplete-dropdown-input-list-${e}`} xs={4}>
                    {e}
                </Grid>
            ))}
        </Grid>
    ) : (
        <ListItem
            className={classNames(classes?.optionCellRow, { [brandedClass]: option.branded })}
            key={`autocomplete-dropdown-input-list-${option.key}`}
        >
            <span>{option.displayName}</span>
        </ListItem>
    );
};

export default Option;
