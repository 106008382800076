import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY = 'rtds-schedule-detailspane-state';

export interface RtdsScheduleDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultRtdsScheduleDetailsPaneUserPreferences: RtdsScheduleDetailsPaneUserPreferences = {
    collapsedSections: [],
    sectionOrder: [SectionName.RTDSSCHEDULEDETAILS],
};

export const processResponse = (
    preferences: Partial<RtdsScheduleDetailsPaneUserPreferences>
): RtdsScheduleDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultRtdsScheduleDetailsPaneUserPreferences);
};

export const requiredRtdsScheduleDetailsPaneUserPreferences = {
    defaultState: defaultRtdsScheduleDetailsPaneUserPreferences,
    key: RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY,
};
