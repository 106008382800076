import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleClassKey =
    | 'activityAvailable'
    | 'activityAvatar'
    | 'activityDrive'
    | 'activityRest'
    | 'activityWork'
    | 'avatar'
    | 'badge'
    | 'delimiter'
    | 'disabled'
    | 'disabledTitle'
    | 'line'
    | 'vehicleCategory';

export const VehicleStyleRules: StyleRulesCallback<Theme, {}, VehicleClassKey> = (theme: Theme) =>
    createStyles({
        activityAvailable: {
            backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
            color: theme.functionalItemsColors.driverActivity.available.contrast,
        },
        activityAvatar: {
            alignSelf: 'flex-end',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontSize: 14,
            height: 18,
            marginLeft: theme.spacing(-1.5),
            width: 18,
        },
        activityDrive: {
            backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
            color: theme.functionalItemsColors.driverActivity.drive.contrast,
        },
        activityRest: {
            backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
            color: theme.functionalItemsColors.driverActivity.rest.contrast,
        },
        activityWork: {
            backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
            color: theme.functionalItemsColors.driverActivity.work.contrast,
        },
        avatar: {
            alignSelf: 'center',
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.vehicle.value,
            height: 40,
            overflow: 'visible',
            width: 40,
        },
        badge: {
            right: 10,
        },
        delimiter: {
            margin: theme.spacing(0, 0.5),
        },
        disabled: {
            opacity: 0.38,
        },
        disabledTitle: {
            fontStyle: 'italic',
        },
        line: {
            display: 'flex',
        },
        vehicleCategory: {
            fontWeight: theme.typography.fontWeightMedium,
        },
    });
