import * as React from 'react';

import { ConfirmationDialog } from '~/components/Dialogs';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { reportError } from '~/reporting';
import type { Vehicle } from '~/services/ApiClient';
import { ApiClient } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

export enum InhibitorAction {
    DISABLE = 'disable',
    ENABLE = 'enable',
    GETPIN = 'get-pin',
}

export enum DriverRole {
    CODRIVER = 'co-driver',
    DRIVER = 'driver',
}

export interface InhibitorActionDialogProps {
    action: InhibitorAction;
    onClose: () => void;
    vehicle: Vehicle;
}

export interface InhibitorActionDialogInnerProps
    extends InhibitorActionDialogProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const InhibitorActionDialogComponent: React.FC<InhibitorActionDialogInnerProps> = ({
    action,
    displayPreferences,
    onClose,
    t,
    vehicle,
}) => {
    const [inhibitorPin, setInhibitorPin] = React.useState<string | undefined>();

    const [renderDialog, setRenderDialog] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (action === InhibitorAction.GETPIN) {
            ApiClient.getInhibitorPin(vehicle.id)
                .then((response) => {
                    setInhibitorPin(() => response.pin);
                    setRenderDialog(() => true);
                })
                .catch((error) => reportError(error));
        } else {
            setRenderDialog(() => true);
        }
    }, [action, vehicle]);

    const handleConfirm = React.useCallback(async () => {
        switch (action) {
            case InhibitorAction.DISABLE:
                await ApiClient.disableInhibitor(vehicle.id);
                break;
            case InhibitorAction.ENABLE:
                await ApiClient.enableInhibitor(vehicle.id);
                break;
            case InhibitorAction.GETPIN:
                // do nothing special
                break;
            default:
                throw new Error(`InhibitorAction ${action} is not supported.`);
        }
        onClose();
    }, [action, vehicle, onClose]);

    const vehicleDisplayName = React.useMemo(
        () => formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat),
        [displayPreferences.vehicleDisplayFormat, vehicle]
    );

    const getTitle = React.useMemo(() => {
        switch (action) {
            case InhibitorAction.DISABLE:
                return t('disable-start-inhibitor-dialog-title');
            case InhibitorAction.ENABLE:
                return t('enable-start-inhibitor-dialog-title');
            case InhibitorAction.GETPIN:
                return t('pincode-for-vehicle-dialog-title', { vehicleDisplayName });
            default:
                throw new Error(`InhibitorAction ${action} is not supported.`);
        }
    }, [t, action, vehicleDisplayName]);

    const getSupportingText = React.useMemo(() => {
        switch (action) {
            case InhibitorAction.DISABLE:
                return t('disable-start-inhibitor-supporting-text', { vehicleDisplayName });
            case InhibitorAction.ENABLE:
                return t('enable-start-inhibitor-supporting-text', { vehicleDisplayName });
            case InhibitorAction.GETPIN:
                return t('pincode-for-vehicle-supporting-text', { inhibitorPin, vehicleDisplayName });
            default:
                throw new Error(`InhibitorAction ${action} is not supported.`);
        }
    }, [action, t, inhibitorPin, vehicleDisplayName]);

    const getConfirmationActionText = React.useMemo(() => {
        switch (action) {
            case InhibitorAction.DISABLE:
                return t('disable');
            case InhibitorAction.ENABLE:
                return t('enable');
            case InhibitorAction.GETPIN:
                return undefined;
            default:
                throw new Error(`InhibitorAction ${action} is not supported.`);
        }
    }, [t, action]);

    const getDataId = React.useMemo(() => {
        switch (action) {
            case InhibitorAction.DISABLE:
                return 'disable-start-inhibitor-dialog';
            case InhibitorAction.ENABLE:
                return 'enable-start-inhibitor-dialog';
            case InhibitorAction.GETPIN:
                return 'pincode-for-vehicle-dialog';
            default:
                throw new Error(`InhibitorAction ${action} is not supported.`);
        }
    }, [action]);

    return renderDialog ? (
        <ConfirmationDialog
            confirmationActionText={getConfirmationActionText}
            dataId={getDataId}
            onCancel={onClose}
            onConfirm={handleConfirm}
            open
            title={getTitle}
        >
            <span>{getSupportingText}</span>
        </ConfirmationDialog>
    ) : null;
};
