import { compareStrings } from '~/services/Sorting';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export const createStringColumn = <T>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => string | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => {
    return {
        compare: compareStrings,
        dataType: 'string',
        getCellValue,
        groupTitle,
        name,
        title,
        ...options,
    };
};
