import { defaultProps } from 'react-recompose';

import type { ChangelogAttributeDefinition } from '~/common/components/ChangelogsSubpage';
import { ClassificationFormatter, ClassificationsFormatter, DateTimeFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { ResolvedRtdsScheduleChangelogChanges } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

export const getRtdsScheduleChangelogAttributeDefinitions = (
    t: SingleTFunction
): ChangelogAttributeDefinition<ResolvedRtdsScheduleChangelogChanges>[] => {
    return [
        {
            getValue: (changelogChanges) => changelogChanges?.startDate,
            title: t('rtdsschedules-changelogs-definitions-startDate'),
            valueFormatterComponent: DateTimeFormatter,
        },
        {
            getValue: (changelogChanges) => changelogChanges?.frequency,
            title: t('schedule-frequency'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.RtdsScheduleFrequency, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.tachoBlocks,
            title: t('schedule-requested-tacho-data'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.TachographDataBlocks, t })(
                ClassificationsFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.priority,
            title: t('rtds-schedule-priority'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.RtdsPriority, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.recurrence,
            title: t('rtdsschedules-changelogs-definitions-recurrence'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.day,
            title: t('rtds-frequency-day-custom'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.weekDay,
            title: t('rtdsschedules-changelogs-definitions-weekday'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.RtdsWeekDayType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.requestedDays,
            title: t('rtds-schedule-requested-days'),
        },
    ];
};
