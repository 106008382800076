import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    changedNotchedOutline: { borderColor: `${theme.palette.warning.main} !important`, borderWidth: '1px' },
    focusChangedNotchedOutline: { borderColor: `${theme.palette.primary.main} !important` },
    leftAlignment: { textAlign: 'left' },
    outlinedInput: {
        '&:hover:not($disabled):not($focused):not($error) $changedNotchedOutline': {
            borderColor: `${theme.palette.warning.dark} !important`,
        },
        '&$focusChangedNotchedOutline $changedNotchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },
}));

export { useStyles };
