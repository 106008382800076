import * as React from 'react';

import { useStaticDataProvider } from '~/common/hooks/useStaticDataProvider';
import type { StaticDataProvider } from '~/common/models';

export interface InjectedStaticDataProviderProps {
    staticDataProvider: StaticDataProvider;
}

export const withStaticDataProvider =
    () =>
    <WrappedProps extends object>(
        WrappedComponent: React.ComponentType<WrappedProps>
    ): React.ComponentType<InjectedStaticDataProviderProps & WrappedProps> => {
        const HOC = (props: WrappedProps) => {
            const staticDataProvider = useStaticDataProvider();

            return <WrappedComponent {...(props as WrappedProps)} staticDataProvider={staticDataProvider} />;
        };

        HOC.displayName = 'withStaticDataProvider';
        return HOC;
    };
