import type { Dispatch } from 'redux';

import type { DriverActivityStoreState } from '~/data/monitoring';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, memoize, throttle } from '~/libs/utility';
import { reportError } from '~/reporting';
import type { DriverActivitiesNotification, DriverActivityType, DriverStatusNotification } from '~/services/ApiClient';

import type { DispatchProps, DriverActivityGraphProps, StateProps } from './component';
import {
    applyDriverActivitiesUpdatesAction,
    clearDriverActivitiesAction,
    retrieveDriverActivitiesAction,
    retrieveDriverActivitiesByIdAction,
    updateDriverActivitiesAction,
} from './data';

export const autoUpdateThrottleTimeout = 2000;

export const mapStateToProps = (
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverActivityStoreState: DriverActivityStoreState
): StateProps => {
    const state = {
        driverActivities: driverActivityStoreState.driversActivities,
        driverActivityTypes,
    };

    return state;
};

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: DriverActivityGraphProps): DispatchProps => {
    const refreshThrottledPerDriver = memoize((driverId: number) =>
        throttle(() => dispatch(applyDriverActivitiesUpdatesAction(driverId)), autoUpdateThrottleTimeout)
    );

    return {
        clearDriverActivities: (driverId: number) => {
            dispatch(clearDriverActivitiesAction(driverId));
        },
        getDriverActivities: (driverId: number) => {
            if (!isUndefined(ownProps.vehicleId)) {
                dispatch(retrieveDriverActivitiesAction(ownProps.vehicleId, driverId)).catch(reportError);
            } else {
                dispatch(retrieveDriverActivitiesByIdAction(driverId)).catch(reportError);
            }
        },
        updateDriverActivities: (
            driverId: number,
            notification: DriverActivitiesNotification | DriverStatusNotification
        ) => {
            dispatch(updateDriverActivitiesAction(driverId, notification));
            refreshThrottledPerDriver(driverId)();
        },
    };
};
