import { Paper, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { ColorVariants } from '~/services/Styling';

import type { LabeledIndicatorClassKey } from './styles';

export interface LabeledIndicatorProps {
    badge?: React.ReactNode;
    dataId?: string;
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode | string;
    title: string;
    titleKeepCapitalization?: boolean;
    variant?: ColorVariants;
}

export interface LabeledIndicatorInnerProps extends LabeledIndicatorProps, WithStyles<LabeledIndicatorClassKey> {}

export const LabeledIndicatorComponent: React.FC<LabeledIndicatorInnerProps> = ({
    badge,
    classes,
    dataId,
    primaryText,
    secondaryText,
    title,
    titleKeepCapitalization,
    variant,
}) => {
    const paperClassName = classNames(classes.root, {
        [classes.disabled]: variant === ColorVariants.Disabled,
        [classes.error]: variant === ColorVariants.Error,
        [classes.success]: variant === ColorVariants.Success,
        [classes.warning]: variant === ColorVariants.Warning,
    });

    const formattedBadge = badge && (
        <span className={classes.badge} data-id="badge">
            {badge}
        </span>
    );
    const formattedTitle = (
        <span className={classes.title} data-id="title">
            {titleKeepCapitalization ? title : title.toUpperCase()}
        </span>
    );

    const firstLine = (
        <Typography className={classes.flex} color="textSecondary" variant="subtitle2">
            {formattedBadge}
            {formattedTitle}
        </Typography>
    );
    const secondLine = (
        <Typography className={classes.primaryText} color="textPrimary" data-id="primary-value" variant="subtitle2">
            {primaryText}
        </Typography>
    );
    const thirdLine = (
        <Typography className={classes.secondaryText} color="textSecondary" data-id="secondary-value" variant="caption">
            {secondaryText}
        </Typography>
    );

    return (
        <Paper className={paperClassName} data-id={dataId} elevation={3}>
            {firstLine}
            {secondLine}
            {thirdLine}
        </Paper>
    );
};
