import type { Dispatch } from 'redux';

import type { CommunicationUserPreferences } from '~/data/communication';
import { updateUserPreferencesAction } from '~/data/userpreferences';

import { retrieveConversationProfilesAction } from '../../data';
import type { ConversationUserPreferences } from '../../preferences';
import { CONVERSATION_USERPREFERENCES_KEY } from '../../preferences';
import type { ConversationsRootStoreState } from '../../reducers';

import type { DispatchProps, StateProps } from './component';

export const mapStateToProps = (
    conversationsRootStoreState: ConversationsRootStoreState,
    conversationUserPreferences: ConversationUserPreferences,
    communicationUserPreferences: CommunicationUserPreferences
): StateProps => {
    return {
        communicationUserPreferences,
        conversationUserPreferences,
        profiles: conversationsRootStoreState.profiles,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        retrieveConversationProfiles: () => {
            dispatch(retrieveConversationProfilesAction());
        },
        updateUserConversationProfile: (conversationProfileId: number) => {
            dispatch(
                updateUserPreferencesAction(CONVERSATION_USERPREFERENCES_KEY, {
                    conversationProfileId,
                })
            );
        },
    };
};
