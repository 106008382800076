import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { protectedComponentHoc } from '~/components/AuthenticationData';
import { requiredDisplayUserPreferences, withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { DETAILSPANE_USERPREFERENCES_KEY, requiredDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';

import { VehicleTypesAdministrationComponent } from './component';
import { requiredVehicleTypesAdministrationDetailsPaneUserPreferences } from './components/DetailsPane';
import type { VehicleTypesAdministrationInnerProps, VehicleTypesAdministrationProps } from './models';
import {
    ADMINVEHICLETYPES_ASSETSELECTOR_USERPREFERENCES_KEY,
    requiredAdminVehicleTypesUserPreferences,
} from './preferences';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';
import { vehicleTypeAdministrationStateSelector } from './selectors';

export * from './models';
export type { AdminVehicleTypeSceneStoreState } from './storestate';
export { adminVehicleTypeSceneReducer } from './storestate';

export const VehicleTypesAdministration = compose<
    VehicleTypesAdministrationInnerProps,
    VehicleTypesAdministrationProps
>(
    setDisplayName('VehicleTypesAdministration'),
    protectedComponentHoc,
    withMenu,
    withDisplayPreferences(),
    ensureSettings([
        SettingsKey.VEHICLE_TYPE_CATEGORIES,
        SettingsKey.AXLES,
        SettingsKey.EMISSION_CLASS,
        SettingsKey.SECURABLES,
    ]),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(ADMINVEHICLETYPES_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredDisplayUserPreferences,
        requiredAdminVehicleTypesUserPreferences,
        requiredVehicleTypesAdministrationDetailsPaneUserPreferences,
        requiredDetailsPaneUserPreferences,
    ]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.SECURABLES),
            vehicleTypeAdministrationStateSelector,
            keyDataStateSelectorFactory(DETAILSPANE_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchToProps,
        mergeProps
    )
)(VehicleTypesAdministrationComponent);
