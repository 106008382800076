import type { SvgIconProps } from '@mui/material';
import { List } from '@mui/material';
import type { ClassNameMap, WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { PositionGroup, PositionStatus } from '~/services/ApiClient';
import { PositionGroupType } from '~/services/ApiClient';

import { buildItems } from './buildItems';
import { DaySeparator } from './components/DaySeparator';
import { PositionGroup as PositionGroupComponent } from './components/PositionGroup';
import type { TimelineItemClassKey } from './components/TimelineItem';
import type { PositionGroupsContainerReduxProps } from './redux';
import type { PositionGroupsContainerClassKey } from './styles';

export interface PositionGroupsContainerProps {
    AssetIcon: React.ComponentType<SvgIconProps>;
    positionGroups: PositionGroup[];
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>;
    startDate: Date;
    stopDate: Date;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
}

export interface PositionGroupsContainerInnerProps
    extends PositionGroupsContainerProps,
        PositionGroupsContainerReduxProps,
        InjectedTranslationProps,
        WithStyles<PositionGroupsContainerClassKey> {}

export const PositionGroupsContainerComponent: React.FC<PositionGroupsContainerInnerProps> = ({
    AssetIcon,
    classes,
    flyToPositionGroup,
    highlightPosition,
    highlightPositionGroup,
    highlightPositionGroupId,
    panToPosition,
    panToPositionGroup,
    positionGroups,
    resetScrollToPositionGroup,
    retrievePositions,
    scrollToPositionGroup,
    startDate,
    stopDate,
    timelineClasses,
}) => {
    const preparedItems = React.useMemo(() => buildItems(positionGroups, stopDate), [positionGroups, stopDate]);
    const getItemSegmentType = React.useCallback((isFirst: boolean, isLast: boolean, hasStart: boolean) => {
        return isFirst && isLast && hasStart ? 'point' : isFirst ? 'begin' : isLast && hasStart ? 'end' : 'continuous';
    }, []);
    const clearHighlightHandler = React.useCallback(() => {
        highlightPositionGroup(undefined);
    }, [highlightPositionGroup]);

    let stopNumber = preparedItems.filter((x) => x.positionGroup.type === PositionGroupType.Stop).length;

    const items = preparedItems.map(({ date, firstOfDay, positionGroup }, idx) => {
        const daySeparatorSegmentType = idx === 0 ? 'hidden' : 'continuous';
        const itemSegmentType = getItemSegmentType(idx === 0, idx === preparedItems.length - 1, !!positionGroup.start);
        const positionGroupStartDate = positionGroup.start?.dateTime ?? startDate;
        const positionGroupStopDate = positionGroup.stop?.dateTime ?? stopDate;
        const highlighted = highlightPositionGroupId === positionGroup.id;
        let currentStop;

        if (positionGroup.type === PositionGroupType.Stop) {
            currentStop = stopNumber;
            stopNumber -= 1;
        }

        return (
            <React.Fragment key={positionGroup.id}>
                {firstOfDay && (
                    <DaySeparator
                        date={date}
                        ListItemProps={{ onMouseEnter: clearHighlightHandler }}
                        segmentType={daySeparatorSegmentType}
                        timelineClasses={timelineClasses}
                    />
                )}
                <PositionGroupComponent
                    AssetIcon={AssetIcon}
                    flyToPositionGroup={flyToPositionGroup}
                    highlighted={highlighted}
                    highlightPosition={highlightPosition}
                    highlightPositionGroup={highlightPositionGroup}
                    panToPosition={panToPosition}
                    panToPositionGroup={panToPositionGroup}
                    positionGroup={positionGroup}
                    resetScrollIntoView={resetScrollToPositionGroup}
                    retrievePositions={retrievePositions}
                    scrollIntoView={highlighted && scrollToPositionGroup}
                    segmentType={itemSegmentType}
                    startDate={positionGroupStartDate}
                    stopDate={positionGroupStopDate}
                    stopNumber={currentStop}
                    timelineClasses={timelineClasses}
                />
            </React.Fragment>
        );
    });

    return (
        <List className={classes.root} data-id="timeline" onMouseLeave={clearHighlightHandler}>
            {items}
        </List>
    );
};
