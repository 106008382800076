import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SceneAssetSelectorClassKey = 'assetSelector' | 'assetTreeMessage' | 'treeView';

export const SceneAssetSelectorStyleRules: StyleRulesCallback<Theme, {}, SceneAssetSelectorClassKey> = (theme: Theme) =>
    createStyles({
        assetSelector: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: theme.typography.fontFamily,
            fontSize: '.75em',
            height: '100%',
            overflow: 'hidden',
        },
        assetTreeMessage: {
            flexGrow: 1,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(3),
        },
        treeView: {
            flexGrow: 1,
        },
    });
