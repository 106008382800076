import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    popup: {
        '@global .leaflet-popup-close-button ': {
            display: 'none',
        },
        '@global .leaflet-popup-content-wrapper': {
            borderRadius: theme.shape.borderRadius,
        },
        '@global .leaflet-popup-content-wrapper .leaflet-popup-content ': {
            margin: 0,
            width: 'auto',
        },
        '@global .leaflet-popup-tip-container ': {
            left: 60,
            width: 20,
            zoom: 0.7,
        },
        bottom: `${4}px !important`,
        left: `${-35}px !important`,
    },
}));
