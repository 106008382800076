import type { ActionTypes as AuthenticationActionTypes, Identity } from '~/data/authentication';
import { ActionTypeKeys as AuthenticationActionTypeKeys } from '~/data/authentication';
import { AuthenticationFailedResponse, AuthenticationFailedResponseStatus } from '~/services/ApiClient';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface LoginStoreState {
    authenticationFailure: boolean;
    identity: Identity | null;
    loading: boolean;
    passwordExpired: boolean;
    requestFailed: boolean;
}

const defaultStoreState: LoginStoreState = {
    authenticationFailure: false,
    identity: null,
    loading: false,
    passwordExpired: false,
    requestFailed: false,
};

export const loginReducer = (
    state: LoginStoreState = defaultStoreState,
    action: ActionTypes | AuthenticationActionTypes
): LoginStoreState => {
    switch (action.type) {
        case AuthenticationActionTypeKeys.AUTHENTICATION_PENDING:
            return {
                ...state,
                authenticationFailure: false,
                loading: true,
                passwordExpired: false,
                requestFailed: false,
            };
        case AuthenticationActionTypeKeys.AUTHENTICATION_FULFILLED:
            return {
                ...state,
                authenticationFailure: action.payload instanceof AuthenticationFailedResponse,
                loading: false,
                passwordExpired:
                    action.payload instanceof AuthenticationFailedResponse &&
                    action.payload.status === AuthenticationFailedResponseStatus.PasswordExpired,
            };
        case AuthenticationActionTypeKeys.AUTHENTICATION_REJECTED:
            return { ...state, loading: false, requestFailed: action.error };
        case ActionTypeKeys.SAVEIDENTITY:
            return { ...state, identity: action.payload.identity || null };
        default:
            return state;
    }
};
