import Attachment from '@mui/icons-material/Attachment';
import { CircularProgress, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Bubble, BubbleType } from '~/components/Bubble';
import { ExpandableMessagePreview } from '~/components/ExpandableMessagePreview';
import { MessageStatusFormatter, TimeFormatter } from '~/components/Formatters';
import { ExpandMoreIcon } from '~/components/Icons';
import { LoadingIndicator } from '~/components/LoadingIndicator';
import { autotranslateHistoryTreshold } from '~/modules/Communication/components/ConversationWidgets/components/ConversationWidget/components/ConversationWidgetList/constants';
import type { TranslatedMessageState } from '~/modules/Communication/components/ConversationWidgets/reducers';
import { logEvent } from '~/services/Analytics';
import type {
    ResolvedAttachment,
    ResolvedMessage,
    ResolvedMessageBody,
    WorkflowFieldValue,
} from '~/services/ApiClient';
import {
    Classification,
    FieldWorkflowFormElement,
    FileTypeKeys,
    IncomingMessage,
    IncomingMessageStatus,
    MessageDirection,
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    OutgoingMessage,
    OutgoingMessageStatus,
    TextMessageBody,
    UnresolvedWorkflowMessageBody,
    WorkflowFormDefinitionStatus,
    WorkflowMessageBody,
} from '~/services/ApiClient';
import { FormatNewLineToBr, formatMessagePrimaryAttribute, formatWorkflowFieldValue } from '~/services/Formatters';

import { AttachmentViewer } from '../AttachmentViewer';

import { Menu } from './components/Menu';
import { WorkflowMessageContent } from './components/WorkflowMessageContent';
import type { ConversationMessageClassKey } from './styles';

export interface ConversationMessageProps {
    canDeleteMessages: boolean;
    canUpdateReadStatus: boolean;
    copyMessageToDraft?: (messageBody: TextMessageBody) => void;
    deleteMessage?: (messageId: number) => void;
    discardMessage?: (message: OptimisticOutgoingConversationMessage) => void;
    firstMessageOfGroup: boolean;
    hideDropdownMenu?: boolean;
    markIncomingMessageAsRead?: (messageId: number) => void;
    markIncomingMessageAsUnread?: (messageId: number) => void;
    message: ResolvedMessage;
    openMessageDetails?: (message: ResolvedMessage, scrollToAttachment: boolean) => void;
    requestTranslation?: (message: ResolvedMessage) => void;
    retrieveWorkflowFormDefinition?: (formId: number) => void;
    retrySendMessage?: (message: OptimisticOutgoingConversationMessage) => void;
    translatedMessageValue?: TranslatedMessageState;
    undeleteMessage?: (messageId: number) => void;
    updatingDeleteStatus: boolean;
    updatingReadStatus: boolean;
}

export interface ConversationMessageInnerProps
    extends ConversationMessageProps,
        WithStyles<ConversationMessageClassKey> {}

const ConversationMessageComponent = forwardRef<HTMLDivElement, ConversationMessageInnerProps>(
    (
        {
            canDeleteMessages,
            canUpdateReadStatus,
            classes,
            copyMessageToDraft,
            deleteMessage,
            discardMessage,
            firstMessageOfGroup,
            hideDropdownMenu,
            markIncomingMessageAsRead,
            markIncomingMessageAsUnread,
            message,
            openMessageDetails,
            requestTranslation,
            retrieveWorkflowFormDefinition,
            retrySendMessage,
            translatedMessageValue = {},
            undeleteMessage,
            updatingDeleteStatus,
            updatingReadStatus,
        },
        ref
    ) => {
        const [anchorElement, setAnchorElement] = useState<HTMLDivElement>();
        const [open, setOpen] = useState(false);
        const [openedAttachment, setOpenedAttachment] = useState<boolean>(false);

        const handleOnCloseMenu = useCallback((): void => {
            setOpen(false);
        }, [setOpen]);

        const { t } = useTranslation();

        useEffect(() => {
            if (
                message.body.workflowFormDefinitionStatus === WorkflowFormDefinitionStatus.REQUIRED &&
                message.body.value instanceof WorkflowMessageBody &&
                retrieveWorkflowFormDefinition
            ) {
                retrieveWorkflowFormDefinition(message.body.value.formDefinitionId);
            }
        }, [message.body.value, message.body.workflowFormDefinitionStatus, retrieveWorkflowFormDefinition]);

        useEffect(() => {
            if (message.direction === MessageDirection.Incoming && message.body.value instanceof TextMessageBody) {
                logEvent(
                    'messaging',
                    `incoming-text-message-displayed`,
                    `Client has displayed an incoming text message`,
                    {
                        value: message.body.value.message.length,
                    }
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleClick = useCallback(
            (e: MouseEvent<HTMLDivElement>) => {
                setOpen(true);
                setAnchorElement(e.currentTarget);
            },
            [setOpen, setAnchorElement]
        );

        const handleOnClickMarkAsRead = useCallback(
            (e: MouseEvent<HTMLLIElement>) => {
                e.preventDefault();

                if (
                    message.value instanceof IncomingMessage &&
                    message.value.status === IncomingMessageStatus.Read &&
                    markIncomingMessageAsUnread
                ) {
                    markIncomingMessageAsUnread(message.value.id);
                } else if (markIncomingMessageAsRead) {
                    markIncomingMessageAsRead(message.value.id);
                }

                setOpen(false);
            },
            [markIncomingMessageAsUnread, markIncomingMessageAsRead, setOpen, message]
        );

        const handleDeleteMessage = useCallback(() => {
            if (deleteMessage) {
                deleteMessage(message.value.id);
            }

            setOpen(false);
        }, [setOpen, deleteMessage, message.value.id]);

        const handleUndeleteMessage = useCallback(() => {
            if (undeleteMessage) {
                undeleteMessage(message.value.id);
            }

            setOpen(false);
        }, [setOpen, undeleteMessage, message.value.id]);

        const handleRetrySendMessage = useCallback((): void => {
            if (retrySendMessage) {
                retrySendMessage(message.value as OptimisticOutgoingConversationMessage);
            }

            setOpen(false);
        }, [setOpen, retrySendMessage, message]);

        const handleDiscardMessage = useCallback((): void => {
            if (discardMessage) {
                discardMessage(message.value as OptimisticOutgoingConversationMessage);
            }

            setOpen(false);
        }, [setOpen, discardMessage, message]);

        const canCopyMessageToDraft = message.body.value instanceof TextMessageBody;
        const handleCopyMessageToDraft = useCallback((): void => {
            if (message.body.value instanceof TextMessageBody && copyMessageToDraft) {
                copyMessageToDraft(message.body.value);
            }

            setOpen(false);
        }, [setOpen, copyMessageToDraft, message.body]);

        const handleMessageDetailsClick = useCallback(() => {
            if (openMessageDetails) {
                openMessageDetails(message, false);
            }
        }, [openMessageDetails, message]);

        const handleAttachmentsClick = useCallback(() => {
            if (openMessageDetails) {
                openMessageDetails(message, true);
            }
        }, [openMessageDetails, message]);

        const documentViewer = useMemo(() => {
            const attachment = message.value instanceof IncomingMessage ? message.value.attachments[0] : undefined;

            const resolvedAttachment: ResolvedAttachment | undefined =
                attachment &&
                (() => {
                    const newType: Classification = new Classification();
                    newType.init({
                        id: attachment.id,
                        key: Object.values(FileTypeKeys)[attachment.type],
                    });
                    return {
                        ...attachment,
                        type: newType,
                    };
                })();

            return (
                resolvedAttachment &&
                openedAttachment && (
                    <AttachmentViewer
                        attachments={[resolvedAttachment]}
                        message={message}
                        onClose={() => {
                            setOpenedAttachment(false);
                        }}
                    />
                )
            );
        }, [message, openedAttachment, setOpenedAttachment]);

        const isPreviewMode = !openMessageDetails;

        let isFailed = false;
        if (
            message.value instanceof OutgoingMessage ||
            message.value instanceof OptimisticOutgoingConversationMessage
        ) {
            isFailed =
                message.value.status === OutgoingMessageStatus.Failed ||
                message.value.status === OptimisticOutgoingConversationMessageStatus.GeneralFailure ||
                message.value.status === OptimisticOutgoingConversationMessageStatus.ValidationFailure;
        }

        const isOutgoing =
            message.value instanceof OutgoingMessage || message.value instanceof OptimisticOutgoingConversationMessage;

        const isOutgoingReplyTo =
            message.replyToMessage?.value instanceof OutgoingMessage ||
            message.replyToMessage?.value instanceof OptimisticOutgoingConversationMessage;

        const dropdownMenuArrow = useMemo(() => {
            const contentElementStyles = {
                [classes.incomingMessageMenuButton]: message.value instanceof IncomingMessage,
                [classes.messageMenuOpen]: open,
                [classes.outgoingMessageMenuButton]:
                    message.value instanceof OutgoingMessage ||
                    message.value instanceof OptimisticOutgoingConversationMessage,
            };

            return (
                <div
                    aria-haspopup="true"
                    aria-owns={open ? 'message-menu' : undefined}
                    className={classNames(classes.messageMenuButton, contentElementStyles)}
                    data-id="menu-selector"
                    onClick={handleClick}
                >
                    <ExpandMoreIcon className={classes.messageMenuButtonIcon} />
                </div>
            );
        }, [classes, message.value, handleClick, open]);

        const contentElement = useCallback(() => {
            const getSecondaryWorkflowField = (body: ResolvedMessageBody) => {
                let secondaryFieldValue: undefined | WorkflowFieldValue;
                let secondaryWorkflowField;

                if (body.value instanceof UnresolvedWorkflowMessageBody) {
                    return t('missing-form-definition');
                }

                if (body.value instanceof WorkflowMessageBody) {
                    secondaryFieldValue = body.value.fieldValues.find((field) => {
                        return field.fieldId === body.workflowFormDefinition?.secondaryFieldId;
                    });
                }
                const secondaryField = body.workflowFormDefinition?.elements.find((el: FieldWorkflowFormElement) => {
                    return el.id === body.workflowFormDefinition?.secondaryFieldId;
                });

                if (secondaryField instanceof FieldWorkflowFormElement) {
                    secondaryWorkflowField =
                        formatWorkflowFieldValue(secondaryField, t, secondaryFieldValue) || t('not-available');
                }
                return secondaryWorkflowField;
            };

            if (
                message.body.workflowFormDefinitionStatus === WorkflowFormDefinitionStatus.PENDING ||
                message.body.workflowFormDefinitionStatus === WorkflowFormDefinitionStatus.REQUIRED
            ) {
                return <LoadingIndicator />;
            }

            let messageBoxToRender;
            if (message.value.body instanceof TextMessageBody) {
                const { detectedSourceLanguage, message: translatedMessage, pending } = translatedMessageValue;

                const isMessagePastAutotranslateHistoryTreshold =
                    Date.now() - message.value.dateTime.getTime() > autotranslateHistoryTreshold;
                const isIncomingMessage = message.value instanceof IncomingMessage;
                const isTranslateButtonVisible =
                    isMessagePastAutotranslateHistoryTreshold &&
                    isIncomingMessage &&
                    requestTranslation &&
                    !pending &&
                    !translatedMessage;

                messageBoxToRender = (
                    <>
                        <span className={classes.textMessageContent} data-id="text-message-content">
                            {isTranslateButtonVisible && (
                                <Button
                                    className={classes.translateButton}
                                    fullWidth
                                    onClick={() => requestTranslation && requestTranslation(message)}
                                    size="small"
                                >
                                    {t('translate')}
                                </Button>
                            )}
                            {pending && !translatedMessage && <LinearProgress className={classes.translationLoader} />}
                            {translatedMessage ? (
                                <>
                                    <ExpandableMessagePreview>
                                        <FormatNewLineToBr text={message.value.body.message} />
                                    </ExpandableMessagePreview>
                                    {detectedSourceLanguage && (
                                        <div className={classes.translationDetectedLanguage}>
                                            {t('translated-from')} {detectedSourceLanguage.toUpperCase()}
                                        </div>
                                    )}
                                    <FormatNewLineToBr text={translatedMessage} />
                                </>
                            ) : (
                                <FormatNewLineToBr text={message.value.body.message} />
                            )}
                        </span>
                        {!hideDropdownMenu && dropdownMenuArrow}
                    </>
                );
            } else {
                let originalMessage;
                let primaryWorkflowField;
                let secondaryWorkflowField;
                let isMissingFormDefinition;
                if (message.replyToMessage) {
                    primaryWorkflowField = formatMessagePrimaryAttribute(t, message.replyToMessage.body);
                    secondaryWorkflowField = getSecondaryWorkflowField(message.replyToMessage.body);
                    isMissingFormDefinition =
                        message.replyToMessage.body.value instanceof UnresolvedWorkflowMessageBody;
                    originalMessage = (
                        <WorkflowMessageContent
                            isFailed={false}
                            isMissingFormDefinition={isMissingFormDefinition}
                            isOutgoing={isOutgoingReplyTo}
                            isQuoted
                            messageTitle={primaryWorkflowField}
                            messageTitleSecondary={secondaryWorkflowField}
                        />
                    );
                }

                primaryWorkflowField = formatMessagePrimaryAttribute(t, message.body);
                secondaryWorkflowField = getSecondaryWorkflowField(message.body);
                isMissingFormDefinition = message.body.value instanceof UnresolvedWorkflowMessageBody;
                const wfMessage = (
                    <>
                        <WorkflowMessageContent
                            isDeleted={message.value.isDeleted}
                            isFailed={isFailed}
                            isMissingFormDefinition={isMissingFormDefinition}
                            isOutgoing={isOutgoing}
                            messageTitle={primaryWorkflowField}
                            messageTitleSecondary={secondaryWorkflowField}
                            onClick={!isPreviewMode ? handleMessageDetailsClick : undefined}
                        />
                        {!hideDropdownMenu && dropdownMenuArrow}
                    </>
                );
                messageBoxToRender = (
                    <div data-id="wf-message-content">
                        {originalMessage}
                        {wfMessage}
                    </div>
                );
            }
            return <span data-id="message-content">{messageBoxToRender}</span>;
        }, [
            message,
            t,
            translatedMessageValue,
            classes.textMessageContent,
            classes.translateButton,
            classes.translationLoader,
            classes.translationDetectedLanguage,
            requestTranslation,
            hideDropdownMenu,
            dropdownMenuArrow,
            isOutgoing,
            isFailed,
            isPreviewMode,
            handleMessageDetailsClick,
            isOutgoingReplyTo,
        ]);

        const timestampClasses = classNames({
            [classes.optimisticMessageTimestamp]: message.value instanceof OptimisticOutgoingConversationMessage,
        });

        const footerStatusIndicator =
            updatingReadStatus || updatingDeleteStatus ? (
                <CircularProgress data-id="marking-message-as-read-spinner" size={14} />
            ) : (
                <MessageStatusFormatter iconProps={{ fontSize: 'inherit' }} t={t} value={message.status} />
            );

        const attachments = message.value instanceof IncomingMessage ? message.value.attachments : undefined;

        const hasAttachment = !!attachments?.length;

        const attachmentsElement = attachments?.length ? (
            <>
                <div
                    className={classes.attachment}
                    onClick={
                        attachments.length === 1
                            ? () => {
                                  setOpenedAttachment(true);
                              }
                            : !isPreviewMode
                              ? handleAttachmentsClick
                              : undefined
                    }
                >
                    <Attachment className={classes.attachmentIcon} fontSize="inherit" />
                    <span className={classes.attachmentInfo} data-id="attachments">
                        {t('attachments-count', { count: attachments.length })}
                    </span>
                </div>
                {documentViewer}
            </>
        ) : null;

        const footerElement = (
            <span className={classes.footer}>
                {attachmentsElement}
                <div className={classes.timeAndStatus}>
                    <span className={timestampClasses} data-id="message-timestamp">
                        <TimeFormatter value={message.value.dateTime} />
                    </span>
                    <span className={classes.statusIndicator}>{footerStatusIndicator}</span>
                </div>
            </span>
        );

        const overriddenBubbleClasses = {
            content: classNames({
                [classes.incomingMessageBubbleContentUnread]:
                    message.value instanceof IncomingMessage && message.value.status === IncomingMessageStatus.Received,
                [classes.outgoingMessageBubbleContentFailed]:
                    (message.value instanceof OutgoingMessage &&
                        message.value.status === OutgoingMessageStatus.Failed) ||
                    (message.value instanceof OptimisticOutgoingConversationMessage &&
                        (message.value.status === OptimisticOutgoingConversationMessageStatus.GeneralFailure ||
                            message.value.status === OptimisticOutgoingConversationMessageStatus.ValidationFailure)),
            }),
            surface: classNames({
                [classes.incomingMessageBubbleSurface]: message.value instanceof IncomingMessage,
                [classes.outgoingMessageBubbleSurface]:
                    message.value instanceof OutgoingMessage ||
                    message.value instanceof OptimisticOutgoingConversationMessage,
            }),
            title: classNames({
                [classes.outgoingMessageBubbleTitle]:
                    message.value instanceof OutgoingMessage ||
                    message.value instanceof OptimisticOutgoingConversationMessage,
            }),
        };

        let dataId = `incoming-conversation-message:${message.value.id}`;
        if (
            message.value instanceof OutgoingMessage ||
            message.value instanceof OptimisticOutgoingConversationMessage
        ) {
            dataId =
                message.value instanceof OptimisticOutgoingConversationMessage
                    ? `optimistic-outgoing-conversation-message:${message.value.correlationId}`
                    : `outgoing-conversation-message:${message.value.id}`;
        }
        const menu = (
            <Menu
                anchorElement={anchorElement}
                canDeleteMessage={canDeleteMessages}
                canUpdateReadStatus={canUpdateReadStatus}
                conversationMessage={message.value}
                onClickMarkAsRead={handleOnClickMarkAsRead}
                onClose={handleOnCloseMenu}
                onCopyMessageToDraft={canCopyMessageToDraft ? handleCopyMessageToDraft : undefined}
                onDeleteMessage={handleDeleteMessage}
                onDiscardMessage={handleDiscardMessage}
                onInfo={handleMessageDetailsClick}
                onRetrySendMessage={handleRetrySendMessage}
                onUndeleteMessage={handleUndeleteMessage}
                open={open}
                updatingReadStatus={updatingReadStatus}
            />
        );

        if (
            message.value instanceof OutgoingMessage ||
            message.value instanceof OptimisticOutgoingConversationMessage
        ) {
            return (
                <div className={classNames(classes.root, classes.outgoingMessage)} data-id={dataId}>
                    <Bubble
                        classes={overriddenBubbleClasses}
                        content={contentElement()}
                        footer={footerElement}
                        title={
                            firstMessageOfGroup ? <div data-id="message-title">{message.value.author}</div> : undefined
                        }
                        type={firstMessageOfGroup ? BubbleType.RightTailed : BubbleType.NonTailed}
                    />
                    {menu}
                </div>
            );
        }

        return (
            <div className={classNames(classes.root, classes.incomingMessage)} data-id={dataId}>
                <Bubble
                    classes={overriddenBubbleClasses}
                    content={contentElement()}
                    footer={footerElement}
                    fullWidthFooter={hasAttachment}
                    ref={ref}
                    type={firstMessageOfGroup ? BubbleType.LeftTailed : BubbleType.NonTailed}
                />
                {menu}
            </div>
        );
    }
);

ConversationMessageComponent.displayName = 'Message';
export { ConversationMessageComponent };
