import Box from '@mui/material/Box';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { FlagIcon } from '~/components/Icons';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatCountry } from '~/services/Formatters';

import type { CountryFormatterClassKey } from './styles';

export interface CountryFormatterProps {
    t: SingleTFunction;
    value?: string;
}

export interface CountryFormatterInnerProps extends CountryFormatterProps, WithStyles<CountryFormatterClassKey> {}

export const CountryFormatterComponent: FC<CountryFormatterInnerProps> = ({ classes, t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return (
        <Box alignItems="center" display="flex">
            <FlagIcon className={classes.flag} code={value} />
            {formatCountry(t, value)}
        </Box>
    );
};
