import * as React from 'react';
import type { ControlProps, StylesConfig } from 'react-select';
import Select, { components as reactSelectComponents } from 'react-select';
import type { IndicatorComponentType, SelectComponentsConfig } from 'react-select/src/components';
import type { MenuListComponentProps, NoticeProps } from 'react-select/src/components/Menu';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import { Control } from './components/Control';
import { DropdownIndicator } from './components/DropdownIndicator';
import { NoOptionsMessage } from './components/NoOptionsMessage';
import { OptionFactory } from './components/Option';
import { SingleValueFactory } from './components/SingleValue';
import type { Option } from './models';

export interface RemoteAutocompleteProps<T> {
    dataId: string;
    filterOption?:
        | ((optionInfo: { data: Option<T>; label: string; value: string }, inputValue: string) => boolean)
        | null;
    isDisabled?: boolean;
    isLoading: boolean;
    loadSucceeded: boolean;
    onChange: (value: Option<T>) => void;
    options: Array<Option<T>>;
    retrieveOptions: () => void;
    selectedOption: null | Option<T> | undefined;
}

export interface RemoteAutocompleteInnerProps<T> extends RemoteAutocompleteProps<T>, InjectedTranslationProps {}

export const RemoteAutocompleteComponentFactory = <T,>(
    OptionChildComponent?: React.ComponentType<{ option: Option<T> }>
): React.FC<RemoteAutocompleteInnerProps<T>> => {
    const MenuList = (props: MenuListComponentProps<Option<T>, false>) => {
        return (
            <reactSelectComponents.MenuList {...props}>
                <div data-id="menu-list">{props.children}</div>
            </reactSelectComponents.MenuList>
        );
    };

    const overriddenComponents: SelectComponentsConfig<Option<T>, false> = {
        Control: Control as React.ComponentType<ControlProps<Option<T>, false>>,
        DropdownIndicator: DropdownIndicator as IndicatorComponentType<Option<T>, false>,
        IndicatorSeparator: null,
        MenuList,
        NoOptionsMessage: NoOptionsMessage as React.ComponentType<NoticeProps<Option<T>, false>>,
        Option: OptionFactory(OptionChildComponent),
        SingleValue: SingleValueFactory(OptionChildComponent),
    };

    // Take the input element out of the flexbox flow so it can be displayed at the start of the select field
    const overriddenStyles: StylesConfig<Option<T>, false> = {
        input: () => ({
            paddingLeft: '8px',
            position: 'absolute',
        }),
    };

    return ({
        dataId,
        filterOption,
        isDisabled,
        isLoading,
        loadSucceeded,
        onChange,
        options,
        retrieveOptions,
        selectedOption,
        t,
    }) => {
        const noOptionsMessageRenderer = () => t('no-match');

        return (
            <div data-id={dataId}>
                <Select
                    components={overriddenComponents}
                    filterOption={filterOption}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    loadingMessage={() => t('loading-placeholder')}
                    noOptionsMessage={noOptionsMessageRenderer}
                    onChange={onChange}
                    onMenuOpen={!isLoading && !loadSucceeded ? retrieveOptions : undefined}
                    options={options}
                    placeholder={t('select-placeholder')}
                    styles={overriddenStyles}
                    value={selectedOption}
                />
            </div>
        );
    };
};
