import type { ChangelogEntry, Driver, Trailer, Vehicle } from '~/common';
import { AssetType } from '~/services/ApiClient';

import type {
    AssetGroup,
    AssetGroupChangelogRead,
    AssetSelectorGroup,
    ResolveAssetGroupsAdminArgs,
    ResolvedAssetGroupChangelogChanges,
    ResolvedQueryAssetGroupsAdmin,
} from './models';

const resolveAssetGroupsAdmin = ({ drivers, items, trailers, vehicles }: ResolveAssetGroupsAdminArgs) => {
    return items.map((assetGroup): ResolvedQueryAssetGroupsAdmin => {
        const { assetsIds, ...restGroup } = assetGroup;

        const assignedAssets = {
            assignedDrivers: [] as Driver[],
            assignedTrailers: [] as Trailer[],
            assignedVehicles: [] as Vehicle[],
        };

        const accumulateAssetActions = {
            [AssetType.Driver]: (id: number) => assignedAssets.assignedDrivers.push(drivers[id]),
            [AssetType.Trailer]: (id: number) => assignedAssets.assignedTrailers.push(trailers[id]),
            [AssetType.Vehicle]: (id: number) => assignedAssets.assignedVehicles.push(vehicles[id]),
        };

        assetGroup.assetsIds?.forEach((assetId) => {
            accumulateAssetActions[assetId.type]?.(assetId.id);
        });

        const { allowDrivers, allowTrailers, allowVehicles, ...restSettings } = assetGroup.settings;
        const allowAssetTypes = [
            allowDrivers && AssetType.Driver,
            allowVehicles && AssetType.Vehicle,
            allowTrailers && AssetType.Trailer,
        ].filter(Boolean) as AssetType[];

        return {
            ...restGroup,
            ...assignedAssets,
            settings: { ...restSettings, allowAssetTypes },
        };
    });
};

const resolveAssetGroupsAdminChangelog = (items: AssetGroupChangelogRead[]) => {
    return items.map(
        (changelog): ChangelogEntry<ResolvedAssetGroupChangelogChanges> => ({
            ...changelog,
            after: changelog.after ?? {},
            before: changelog.before ?? {},
        })
    );
};

const formatAssetGroups = (source: AssetGroup[]): AssetSelectorGroup[] => {
    return source.map(({ assetGroups, ...rest }) => {
        if (assetGroups) {
            return { ...rest, children: formatAssetGroups(assetGroups) };
        }

        return { ...rest, children: [] };
    });
};

export { formatAssetGroups, resolveAssetGroupsAdmin, resolveAssetGroupsAdminChangelog };
