import { useEffect, useState } from 'react';
import type { ChangeEvent, FC, FocusEvent } from 'react';

import { isUndefined } from '../../utility';
import { TextInput } from '../TextInput';

import type { NumberInputProps } from './models';

const NumberInput: FC<NumberInputProps> = (props) => {
    const { onBlur, onChange, onFocus, precision, value, ...restProps } = props;

    // Don't immediately apply the newly formatted value, but only when the field is not focussed
    // This is to prevent applying the format when the user is inputting a value
    const [inputValue, setInputValue] = useState<string>(isUndefined(value) ? '' : value.toFixed(precision));
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        if (!hasFocus) {
            setInputValue(isUndefined(value) ? '' : value.toFixed(precision));
        }
    }, [value, hasFocus, setInputValue, precision]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.currentTarget.value);

        if (event.currentTarget.value !== '') {
            const parsedNumber = Number(event.currentTarget.value);
            const rounded = Number(parsedNumber.toFixed(precision));
            onChange?.(rounded);
        } else {
            onChange?.(undefined);
        }
    };

    const handleOnFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHasFocus(true);
        onFocus?.(event);
    };

    const handleOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHasFocus(false);
        onBlur?.(event);
    };

    return (
        <TextInput
            {...restProps}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            type="number"
            value={inputValue}
        />
    );
};

NumberInput.displayName = 'NumberInput';
export { NumberInput };
