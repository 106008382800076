import { DateRange, Schedule } from '@mui/icons-material';
import { TextField, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import type { Moment } from 'moment';
import moment from 'moment';
import type { ComponentProps, FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil, isUndefined } from '~/libs/utility';

import type { BaseProps } from '../model';
import { DateTimeControlType } from '../model';

import type { DateTimeInputClassKey } from './styles';

export interface DateTimeInputProps extends BaseProps<Date | undefined> {
    controlType: DateTimeControlType;
    daysEnabled?: number[];
    requiredText?: string;
}

export interface DateTimeInputInnerProps
    extends DateTimeInputProps,
        WithStyles<DateTimeInputClassKey>,
        InjectedTranslationProps {}

export const DateTimeInputComponent: FC<DateTimeInputInnerProps> = (props) => {
    const {
        classes,
        className,
        controlType,
        dataId,
        daysEnabled,
        defaultValue,
        disabled,
        disabledReason,
        errorText,
        fieldName,
        hasError,
        label,
        markValueChange,
        onBlurChanged,
        onValueChanged,
        required,
        requiredText,
        t,
        validationRules,
    } = props;

    const { control, formState } = useFormContext();
    const fieldError = formState.errors[fieldName];
    const error = !!fieldError || hasError;
    const isDirtyField = formState.dirtyFields[fieldName];
    const requiredMessage = requiredText ?? t('wf-field-error-required');
    const helperText =
        hasError && !isUndefined(errorText)
            ? errorText
            : fieldError
              ? fieldError.type === 'invalid-date'
                  ? t(`date-time-field-${fieldError.type as string}`)
                  : fieldError.message
              : undefined;

    const timeFormat = useMemo(() => {
        switch (controlType) {
            case DateTimeControlType.Date: {
                return '';
            }
            case DateTimeControlType.DateTime: {
                return moment.localeData().longDateFormat('LT');
            }

            default:
                throw Error(`Provided editor state ${controlType} is not supported.`);
        }
    }, [controlType]);

    const helpers = useMemo(() => {
        return {
            ampm: timeFormat.toLowerCase().includes('a'),
            dateFormat: moment.localeData().longDateFormat('L'),
            prepareValueForChange: (v: Moment | null | undefined) => {
                return v?.clone().toDate();
            },
            prepareValueForEdit: (v: Date | undefined) => {
                return v ? moment.utc(v).local(false) : null;
            },
            timeFormat,
        };
    }, [timeFormat]);

    const rules = useMemo(() => {
        return {
            required: { message: requiredMessage, value: required ?? false },
            validate: {
                'invalid-date': (value: unknown) =>
                    value instanceof Date ? !Number.isNaN(value.valueOf()) : isNil(value),
            },
            ...validationRules,
        };
    }, [required, requiredMessage, validationRules]);

    const disableDays = (event: Moment): boolean => {
        const date = helpers.prepareValueForChange(event);
        return !isUndefined(date) && !isUndefined(daysEnabled) ? !daysEnabled.includes(date?.getDay()) : false;
    };

    const notifyChange = useCallback(
        (value: Moment | null | undefined, callbackFn?: (v: Date | undefined) => void) => {
            if (!callbackFn) {
                return;
            }

            if (!value) {
                callbackFn(undefined);
            } else {
                const convertedValue = helpers.prepareValueForChange(value);
                callbackFn(convertedValue);
            }
        },
        [helpers]
    );

    const onBlurHandler = useCallback(
        (field: ControllerRenderProps) => {
            notifyChange(undefined, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );

    const onChangeHandler = useCallback(
        (event: Moment | null, field: ControllerRenderProps) => {
            notifyChange(event, (value) => {
                field.onChange(value);

                if (onValueChanged) {
                    onValueChanged(value);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const datepickerProps: Partial<ComponentProps<typeof DesktopDatePicker>> = {
        className,
        disabled,
        inputFormat: `${helpers.dateFormat} ${helpers.timeFormat}`,
        InputProps: {
            classes: {
                focused: markValueChange && !error && isDirtyField && classes.focusChangedNotchedOutline,
                notchedOutline: markValueChange && !error && isDirtyField && classes.changedNotchedOutline,
                root: isUndefined(markValueChange) || !markValueChange ? undefined : classes.outlinedInput,
            },
            error,
            fullWidth: true,
            readOnly: false,
            required,
            size: 'small',
        },
        label,
    };

    const baseComponent = () => {
        switch (controlType) {
            case DateTimeControlType.Date:
                return (
                    <Controller
                        control={control}
                        defaultValue={defaultValue}
                        name={fieldName}
                        render={({ field }) => (
                            <DesktopDatePicker
                                {...datepickerProps}
                                InputProps={{ ...datepickerProps.InputProps, onBlur: () => onBlurHandler(field) }}
                                onChange={(value: Moment | null) => onChangeHandler(value, field)}
                                renderInput={(inputProps) => (
                                    <TextField {...inputProps} data-id={dataId} helperText={helperText} />
                                )}
                                shouldDisableDate={disableDays}
                                value={helpers.prepareValueForEdit(field.value)}
                            />
                        )}
                        rules={rules}
                    />
                );

            case DateTimeControlType.DateTime:
                return (
                    <Controller
                        control={control}
                        defaultValue={defaultValue}
                        name={fieldName}
                        render={({ field }) => (
                            <DesktopDateTimePicker
                                {...datepickerProps}
                                ampm={helpers.ampm}
                                dateRangeIcon={<DateRange className={classes.toolbarIcon} />}
                                InputProps={{ ...datepickerProps.InputProps, onBlur: () => onBlurHandler(field) }}
                                onChange={(value: Moment | null) => onChangeHandler(value, field)}
                                renderInput={(inputProps) => (
                                    <TextField {...inputProps} data-id={dataId} helperText={helperText} />
                                )}
                                timeIcon={<Schedule className={classes.toolbarIcon} />}
                                value={helpers.prepareValueForEdit(field.value)}
                            />
                        )}
                        rules={rules}
                    />
                );

            default:
                throw Error(`ControlType '${controlType}' is not supported.`);
        }
    };

    return disabledReason && disabled ? (
        <Tooltip data-id={`input-tooltip-${dataId}`} placement="right" title={disabledReason}>
            <span>{baseComponent()}</span>
        </Tooltip>
    ) : (
        baseComponent()
    );
};
