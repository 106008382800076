import { InputAdornment, TextField } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { isNaN, isUndefined } from '~/libs/utility';

import type { NumericMagnitudeSettingItem } from '../../models';

export interface NumericMagnitudeSettingProps {
    className?: string;
    margin?: 'dense' | 'none' | 'normal';
    setting: NumericMagnitudeSettingItem;
    size?: 'medium' | 'small';
}

export interface NumericMagnitudeSettingInnerProps extends NumericMagnitudeSettingProps {}

const NumericMagnitudeSetting: FC<NumericMagnitudeSettingProps> = (props) => {
    const { className, margin, setting, size } = props;
    const { errorText, isRequired, key, maxValue, minValue, onValueChange, unit, value } = setting;

    const [inputValue, setInputValue] = useState<number | undefined>(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const validateInput = useCallback(
        (input: number): boolean =>
            // When no input check the setting is required
            (!isRequired && isNaN(input)) ||
            // When input perform validity check
            ((isUndefined(minValue) || input >= minValue) && (isUndefined(maxValue) || input <= maxValue)),
        [isRequired, minValue, maxValue]
    );

    const hasError = !validateInput(inputValue ?? NaN);

    const onBlur = useCallback(() => {
        if (hasError) {
            setInputValue(value ?? NaN);
        }
    }, [hasError, value]);

    const onChangeCallback = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const inputNumericValue = parseInt(e.currentTarget.value, 10);

            setInputValue(inputNumericValue);

            if (validateInput(inputNumericValue)) {
                onValueChange(!isNaN(inputNumericValue) ? inputNumericValue : undefined);
            }
        },
        [onValueChange, validateInput]
    );

    return (
        <TextField
            className={className}
            data-id={key}
            error={hasError}
            helperText={hasError ? errorText : undefined}
            InputProps={{
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
            margin={margin}
            onBlur={onBlur}
            onChange={onChangeCallback}
            required={isRequired}
            size={size}
            type="number"
            value={inputValue}
            variant="outlined"
        />
    );
};
NumericMagnitudeSetting.displayName = 'NumericMagnitudeSetting';

export { NumericMagnitudeSetting };
