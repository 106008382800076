import { createAction } from 'redux-actions';

import type { AdminTrailerEdit } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    SaveAdminTrailerRequest,
    ServerResultStatus,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ACTION_TYPE_PREFIXES } from './actionTypes';

const getTrailersAdministration = createAction(ACTION_TYPE_PREFIXES.LIST, () =>
    retryableRequest(() => ApiClient.getTrailersAdministration())
);

const getTrailerChangelogsAction = createAction(
    ACTION_TYPE_PREFIXES.CHANGELOG,
    (trailerId: number) => retryableRequest(() => ApiClient.getTrailerChangelogs(trailerId)),
    (trailerId: number) => trailerId
);

const deleteTrailersAdministration = createAction(ACTION_TYPE_PREFIXES.DELETE, (trailerId: number) =>
    retryableRequest(() => ApiClient.deleteAdminTrailer(trailerId))
);

const updateTrailerAdministration = createAction(
    ACTION_TYPE_PREFIXES.UPDATE,
    async (trailerId: number, trailer: AdminTrailerEdit) => {
        try {
            await ApiClient.updateTrailerAdmin(trailerId, createApiModel(SaveAdminTrailerRequest, { item: trailer }));
            return ServerResultStatus.OK;
        } catch (error) {
            if (ApiException.isApiException(error) && error.status === 400) {
                throw ServerResultStatus.BADREQUEST;
            }

            throw ServerResultStatus.SERVERERROR;
        }
    }
);

const createTrailerAdministration = createAction(ACTION_TYPE_PREFIXES.CREATE, async (trailer: AdminTrailerEdit) => {
    try {
        await ApiClient.createAdminTrailer(createApiModel(SaveAdminTrailerRequest, { item: trailer }));
        return ServerResultStatus.OK;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});

const getTrailerDefaultSettings = createAction(ACTION_TYPE_PREFIXES.DEFAULT_SETTINGS, async () => {
    try {
        const { item } = await ApiClient.getTrailerDefaultSettings();
        return item;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});

const getTrailerSettings = createAction(ACTION_TYPE_PREFIXES.SETTINGS, async (id: number) => {
    try {
        const { item } = await ApiClient.getTrailerSettings(id);
        return item;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});

const resetTrailerAdministration = () => ({ type: ACTION_TYPE_PREFIXES.RESET });

export {
    createTrailerAdministration,
    deleteTrailersAdministration,
    getTrailerChangelogsAction,
    getTrailerDefaultSettings,
    getTrailersAdministration,
    getTrailerSettings,
    resetTrailerAdministration,
    updateTrailerAdministration,
};
