import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DateTimeRangePicker } from '~/components/DateTimeRangePicker';
import { WidgetDialog } from '~/components/Dialogs';
import { CloseIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';
import { dynamicMonitoringStateSelector } from '~/data/monitoring';
import { getVehicleStatusesForPeriod } from '~/data/vehiclestatus';
import { MONITORING_DATETIMERANGE } from '~/scenes/Monitoring/consts';

import { vehicleStatusesForPeriodSelector } from '../../../../selectors';
import { useFuelLevelGraphData } from '../../hooks';
import { FuelLevelGraph } from '../FuelLevelGraph';

import { DEFAULT_DATES } from './consts';
import type { FuelLevelDialogProps } from './models';
import { GraphWrapper, MessageContent, StyledHeader, TitleWrapper } from './styles';

const FuelLevelDialog = (props: FuelLevelDialogProps) => {
    const { onCloseModal, vehicleId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { error, items, pending } = useSelector(vehicleStatusesForPeriodSelector);
    const { vehicleStatus } = useSelector(dynamicMonitoringStateSelector);

    const [dateTimeRange, setDateTimeRange] = useState(DEFAULT_DATES);

    const handleChangeDateTimeRange = useCallback(
        (value) => {
            setDateTimeRange(value);
            dispatch(getVehicleStatusesForPeriod(vehicleId, value.startDate, value.endDate));
        },
        [dispatch, vehicleId]
    );

    const shouldBeUpdated = useMemo(
        () => dateTimeRange.endDate.getTime() === DEFAULT_DATES.endDate.getTime(),
        [dateTimeRange.endDate]
    );

    const graphData = useFuelLevelGraphData({
        initialItems: items,
        shouldBeUpdated,
        vehicleId,
        vehicleStatus: vehicleStatus.data,
    });

    useEffect(() => {
        dispatch(getVehicleStatusesForPeriod(vehicleId, DEFAULT_DATES.startDate, DEFAULT_DATES.endDate));
    }, [dispatch, vehicleId]);

    const content = useMemo(() => {
        if (pending) {
            return (
                <MessageContent data-testid="loading-data">
                    <CircularProgress size={32} />
                </MessageContent>
            );
        }

        if (error) {
            return (
                <MessageContent data-testid="loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </MessageContent>
            );
        }

        if (!graphData.length) {
            return <MessageContent data-testid="no-graph-data">{t('violation-group-no-data')}</MessageContent>;
        }

        return (
            <GraphWrapper data-testid="graph">
                <FuelLevelGraph data={graphData} />
            </GraphWrapper>
        );
    }, [error, graphData, pending, t]);

    return (
        <WidgetDialog
            headerActions={[
                <TitledIconButton color="inherit" data-testid="close" onClick={onCloseModal} title={t('close')}>
                    <CloseIcon />
                </TitledIconButton>,
            ]}
            open
            testId="fuel-level-graph-dialog"
            title={
                <TitleWrapper>
                    <StyledHeader variant="body1">{t('fuel-level')}</StyledHeader>
                    <DateTimeRangePicker
                        maxDate={MONITORING_DATETIMERANGE.endDate}
                        maxDays={MONITORING_DATETIMERANGE.maxDays}
                        minDate={MONITORING_DATETIMERANGE.minDate}
                        onChange={handleChangeDateTimeRange}
                        value={dateTimeRange}
                    />
                </TitleWrapper>
            }
        >
            {content}
        </WidgetDialog>
    );
};

export { FuelLevelDialog };
