import { isNil, isUndefined } from '~/libs/utility';

interface ConstructUrlProps {
    id?: number | string;
    keepSubPageOpen?: boolean;
    route: string;
    subpage?: string;
}

export const constructUrl = ({ id, keepSubPageOpen, route, subpage }: ConstructUrlProps) => {
    const isSubpageOpened = window.location.pathname.includes(subpage || '');
    if (isUndefined(id) && subpage) {
        throw new Error(`Cannot keep open subpage if there's no selected asset`);
    }

    return [id, subpage].reduce<string>((accumulator: string, urlParam) => {
        if (subpage === urlParam && isSubpageOpened && keepSubPageOpen === false) {
            return accumulator;
        }
        return isNil(urlParam) ? accumulator : `${accumulator}/${urlParam}`;
    }, route);
};
