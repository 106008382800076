import { getValueByPath } from '~/common';
import type { MonitoringDriverEntry, MonitoringTrailerEntry, MonitoringVehicleEntry } from '~/data/monitoring';

const getPopupKey = <T>(entry: T) => {
    const isVehicle = getValueByPath(entry, 'vehicle.id');
    const isDriver = getValueByPath(entry, 'driver.driver.id');

    if (isVehicle) {
        const vehicleEntry = entry as MonitoringVehicleEntry;

        return `vehicle:${vehicleEntry.vehicle.id}-driver:${
            vehicleEntry.vehicleDriverHoursStatus?.driverHoursStatus?.driver.id ?? 'none'
        }-codriver:${vehicleEntry.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver.id ?? 'none'}-trailer:${
            vehicleEntry.trailerConnection?.trailerReference.id ?? 'none'
        }`;
    }
    if (isDriver) {
        const driverEntry = entry as MonitoringDriverEntry;
        return `driver:${driverEntry.driver?.driver?.id}`;
    }

    const trailerEntry = entry as MonitoringTrailerEntry;
    return `trailer:${trailerEntry.trailer.id}-vehicle:${
        trailerEntry.vehicleConnection?.vehicle?.id ?? 'none'
    }-driver:${
        trailerEntry.vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.driver.id ?? 'none'
    }-codriver:${trailerEntry.vehicleConnection?.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver.id ?? 'none'}`;
};

export { getPopupKey };
