import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import type { DeepPojoModel, DisplayUserPreferencesDriverDisplayFormat, DriverName } from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';

export interface DriverNameFormatterProps {
    dataTestId?: string;
    displayNameFormat: DisplayUserPreferencesDriverDisplayFormat;
    value?: DeepPojoModel<DriverName> | DriverName;
}

export interface DriverNameFormatterInnerProps extends DriverNameFormatterProps {}

const DriverNameFormatter: FC<DriverNameFormatterInnerProps> = ({ dataTestId, displayNameFormat, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <div data-testid={dataTestId}>{formatDriverName(value, displayNameFormat)}</div>;
};
DriverNameFormatter.displayName = 'DriverNameFormatter';

export { DriverNameFormatter };
