import { defaultProps } from 'react-recompose';

import { TemperatureFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import { getTemperatureUnitSystemUtils, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareNumbers } from '~/services/Sorting';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export const createTemperatureColumn = <T>(
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => number | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => {
    const temperatureUtils = getTemperatureUnitSystemUtils(t, unitSystem);

    return {
        ...options,
        align: 'right',
        compare: compareNumbers,
        dataType: 'number',
        excelCellFormat: `0.0 "${temperatureUtils.unit}"`,
        exportValueFormatter: exportConvertorFactory(temperatureUtils.converter),
        getCellValue,
        getFilterValue: temperatureUtils.converter,
        groupingCriteria: groupingCriteriaFactory(temperatureUtils.formatter),
        groupTitle,
        name,
        title,
        valueFormatterComponent: defaultProps({ t, unitSystem })(TemperatureFormatter),
        valueTextFormatter: valueTextFormatterFactory(temperatureUtils.formatter),
    };
};
