import { Avatar, ListItem, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { ElapsedTime } from '~/components/ElapsedTime';
import { DriverActivityIconFormatter, DriverNameFormatter } from '~/components/Formatters';
import { ClockIcon } from '~/components/Icons';
import type { InjectedTranslationProps, SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DriverActivityType,
    DriverName,
    DriverRole,
    DriverSubActivityType,
} from '~/services/ApiClient';
import { formatDriverActivity } from '~/services/Formatters';

import { DriverActivityGraph } from '../../../DriverActivityGraph';

import type { DriverSummaryClassKey } from './styles';

export interface DriverSummaryProps {
    activity?: DriverActivityType;
    activityStartDateTime?: Date;
    dataId?: string;
    driver?: DriverName;
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat;
    icon: JSX.Element;
    onOpenDriverSubpage: (driverRole: DriverRole) => void;
    role: DriverRole;
    subActivity?: DriverSubActivityType;
    vehicleId: number;
}

export interface DriverSummaryInnerProps
    extends DriverSummaryProps,
        InjectedTranslationProps,
        WithStyles<DriverSummaryClassKey> {}

const formatDriverRoleLine = (
    classes: Record<DriverSummaryClassKey, string>,
    t: SingleTFunction,
    role: string,
    driver?: DriverName,
    activity?: DriverActivityType,
    subActivity?: DriverSubActivityType
) => {
    const subActivityElement = driver && subActivity && <span data-id="driver-sub-activity">{subActivity.name}</span>;

    const activityElement =
        driver && activity ? (
            <Typography color="textSecondary" variant="body2">
                <span data-id="driver-activity">{formatDriverActivity(t, activity)}</span>
                {subActivityElement && <>,&nbsp;</>}
                {subActivityElement}
            </Typography>
        ) : (
            driver && (
                <Typography color="textSecondary" data-id="driver-activity" variant="body2">
                    -
                </Typography>
            )
        );

    const activityDelimiter = activityElement && (
        <Typography className={classes.delimiter} color="textSecondary" variant="body2">
            •
        </Typography>
    );

    return (
        <div className={classes.driverInfo}>
            <Typography className={classes.role} color="textSecondary" variant="body2">
                {t(`${role}`)}
            </Typography>
            {activityDelimiter}
            {activityElement}
        </div>
    );
};

const formatDriverActivityDetailsLine = (
    classes: Record<DriverSummaryClassKey, string>,
    t: SingleTFunction,
    driver?: DriverName,
    activity?: DriverActivityType,
    activityStartDateTime?: Date
) => {
    return driver && activity && !isUndefined(activityStartDateTime) ? (
        <div className={classes.activityDetailsTimestamp}>
            <ElapsedTime icon={ClockIcon} since={activityStartDateTime} />
        </div>
    ) : (
        <div data-id="no-data">{t('not-available')}</div>
    );
};

export const DriverSummaryComponent: React.SFC<DriverSummaryInnerProps> = ({
    activity,
    activityStartDateTime,
    classes,
    dataId,
    driver,
    driverDisplayFormat,
    icon,
    onOpenDriverSubpage,
    role,
    subActivity,
    t,
    vehicleId,
}) => {
    const openDriverSubpage = React.useCallback(() => {
        onOpenDriverSubpage(role);
    }, [onOpenDriverSubpage, role]);

    const formattedDriverName = driver ? (
        <DriverNameFormatter displayNameFormat={driverDisplayFormat} value={driver} />
    ) : (
        t('not-logged-in')
    );

    const driverName = (
        <Typography
            className={classNames(classes.driverName, { [classes.disabledTitle]: !driver })}
            data-id="driver-name"
            onClick={openDriverSubpage}
        >
            {formattedDriverName}
        </Typography>
    );

    const activityIcon = activity && (
        <Avatar className={classNames(classes.driverActivityIcon, classes[`activity${activity.key}`])}>
            <DriverActivityIconFormatter iconProps={{ fontSize: 'inherit' }} value={activity.key} />
        </Avatar>
    );

    const driverActivityGraph = driver && (
        <DriverActivityGraph componentId={`${role}-activity-graph`} driverId={driver.id} vehicleId={vehicleId} />
    );

    const driverInfo = formatDriverRoleLine(classes, t, t(`${role}`), driver, activity, subActivity);
    const activityDetails = formatDriverActivityDetailsLine(classes, t, driver, activity, activityStartDateTime);

    return (
        <ListItem className={classNames(classes.driver, { [classes.disabled]: !driver })} data-id={dataId}>
            <div className={classes.driverInfoRow}>
                <div className={classes.avatarContainer}>
                    <Avatar className={classes.driverIcon} data-id="driver-avatar" onClick={openDriverSubpage}>
                        {icon}
                    </Avatar>
                    {activityIcon}
                </div>

                <div>
                    {driverName}
                    {driverInfo}
                </div>
            </div>

            {activityDetails}
            {driverActivityGraph}
        </ListItem>
    );
};
