import type { Dispatch } from 'redux';

import { openUnseenCarouselAction } from '~/components/IntroCarousel';
import type { Credentials } from '~/data/authentication';
import { loginAction } from '~/data/authentication';
import { reportError } from '~/reporting';

import { saveIdentityAction } from '../../data/index';
import type { LoginStoreState } from '../../reducers';

import type { UserLoginFormDispatchProps, UserLoginFormStateProps } from './component';

export const mapStateToProps = (state: LoginStoreState): UserLoginFormStateProps => {
    return {
        authenticating: state.loading,
        authenticationError: state.authenticationFailure,
        customer: (state.identity && state.identity.customer) || undefined,
        passwordExpired: state.passwordExpired,
        requestFailed: state.requestFailed,
        username: (state.identity && state.identity.username) || undefined,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): UserLoginFormDispatchProps => {
    return {
        authenticate: (credentials: Credentials, rememberMe: boolean) => {
            dispatch(
                saveIdentityAction(
                    rememberMe ? { customer: credentials.customer, username: credentials.username } : undefined
                )
            );

            dispatch(loginAction(credentials)).catch(reportError);

            dispatch(openUnseenCarouselAction());
        },
    };
};
