import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';

import { SectionName } from '../../constants';

import type { GeneralSectionProps } from './models';

const GeneralSection: FC<GeneralSectionProps> = (props) => {
    const { adminCompanyCard, dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();

    const keyValueItems: KeyValueItem[] = [
        {
            label: t('serial-number'),
            value: <span>{adminCompanyCard.serialNumber}</span>,
            valueDataId: 'company-card-serial-number',
        },
        {
            label: t('company-card-card-number'),
            value: adminCompanyCard.cardNumber ? <span> {adminCompanyCard.cardNumber}</span> : undefined,
            valueDataId: 'company-card-card-number',
        },
        {
            label: t('company-card-card-description'),
            value: adminCompanyCard.cardDescription ? <span> {adminCompanyCard.cardDescription}</span> : undefined,
            valueDataId: 'company-card-card-description',
        },
    ];
    return (
        <ReorderableSection
            dataId="company-card-administration-general-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.GENERAL)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="general-list" />
        </ReorderableSection>
    );
};
GeneralSection.displayName = 'GeneralSection';
export default GeneralSection;
