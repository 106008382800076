import { Map } from '@fv/components';
import type { Map as LMap, LatLngBounds } from 'leaflet';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FeatureGroup, ScaleControl } from 'react-leaflet';

import { isUndefined } from '~/libs/utility';
import type { PositionGroup } from '~/services/ApiClient';
import { PositionGroupType } from '~/services/ApiClient';

import { MapEventControls } from './components/MapEventsControl';
import { PositionGroupMarker } from './components/PositionGroupMarker';
import { PositionGroupRoute } from './components/PositionGroupRoute';
import { PositionMarker } from './components/PositionMarker';
import type { HistoryMapInnerProps } from './models';
import * as utility from './utility';

export const selectedMarkerZIndexOffset = 500;
export const markerRiseOffset = 1000;

export const HistoryMapComponent: FC<HistoryMapInnerProps> = (props) => {
    const {
        AssetIcon,
        assetName,
        changeViewport,
        classes,
        flyToPositionGroup,
        highlightPosition,
        highlightPositionGroup,
        highlightPositionGroupId,
        mapLayersProps,
        markerClasses,
        panToPosition,
        panToPositionGroup,
        pendingPositionGroups,
        positionGroups,
        routeColor,
        scrollToPositionGroup,
        size,
        viewport,
    } = props;

    const mapRef = useRef<LMap | null>();
    const [zoomToFit, setZoomToFit] = useState(true);
    const mapBounds = useMemo(() => {
        if (mapRef.current || viewport) {
            return undefined;
        }

        let bounds: LatLngBounds | undefined;
        if (utility.positionGroupsAreLoaded(positionGroups, pendingPositionGroups)) {
            bounds = utility.getRouteBounds(utility.getAggregatedCoordinates(positionGroups.data));
        } else {
            bounds = utility.getRouteBounds(undefined);
        }

        return bounds;
    }, [viewport, positionGroups, pendingPositionGroups]);

    useEffect(() => {
        if (!mapRef.current || !utility.positionGroupsAreLoaded(positionGroups, pendingPositionGroups)) {
            return;
        }

        let bounds: LatLngBounds | undefined;
        let fly = false;

        if (zoomToFit) {
            bounds = utility.getRouteBounds(utility.getAggregatedCoordinates(positionGroups.data));
            fly = true;
            setZoomToFit(false);
        }

        if (panToPositionGroup || flyToPositionGroup) {
            const positionGroup = positionGroups.data.find((p) => p.id === highlightPositionGroupId);
            if (positionGroup) {
                bounds = utility.getRouteBounds(utility.getAggregatedCoordinates([positionGroup]));
                fly = flyToPositionGroup;
            }
        }

        if (panToPosition && highlightPosition) {
            bounds = utility.getRouteBounds([[highlightPosition.latitude, highlightPosition.longitude]]);
        }

        if (bounds) {
            if (fly) {
                mapRef.current.flyToBounds(bounds, { duration: 0.5, maxZoom: 17 });
            } else {
                mapRef.current.flyTo(bounds.getCenter(), viewport?.zoom, { animate: false });
            }
        }
    }, [
        positionGroups,
        pendingPositionGroups,
        flyToPositionGroup,
        panToPositionGroup,
        panToPosition,
        highlightPositionGroupId,
        highlightPosition,
        zoomToFit,
        setZoomToFit,
        mapRef,
        viewport?.zoom,
    ]);

    const renderMovements = useCallback(
        (positionsGroup: PositionGroup[]) => {
            return positionsGroup.map((positionGroup) => (
                <PositionGroupRoute
                    AssetIcon={AssetIcon}
                    disabled={!isUndefined(highlightPositionGroupId) && highlightPositionGroupId !== positionGroup.id}
                    highlightPositionGroup={highlightPositionGroup}
                    key={positionGroup.id}
                    lineColor={routeColor}
                    positionGroup={positionGroup}
                    scrollToPositionGroup={scrollToPositionGroup}
                />
            ));
        },
        [AssetIcon, highlightPositionGroupId, highlightPositionGroup, scrollToPositionGroup, routeColor]
    );

    const renderStops = useCallback(
        (positionsGroup: PositionGroup[]) => {
            const stops = positionsGroup.filter((it) => it.type === PositionGroupType.Stop || !isUndefined(it.current));
            let stopNumber = 1;

            return stops.map((positionGroup) => {
                const currentStop = stopNumber;
                stopNumber += 1;
                return (
                    <PositionGroupMarker
                        assetName={assetName}
                        classes={markerClasses}
                        dataId={utility.getPositionGroupMarkerDataId(
                            positionGroup,
                            positionGroup.id === highlightPositionGroupId
                                ? utility.PositionGroupState.Highlighted
                                : utility.PositionGroupState.Normal
                        )}
                        highlightPositionGroup={highlightPositionGroup}
                        key={positionGroup.id}
                        positionGroup={positionGroup}
                        scrollToPositionGroup={scrollToPositionGroup}
                        selected={positionGroup.id === highlightPositionGroupId}
                        stopNumber={currentStop}
                    />
                );
            });
        },
        [assetName, highlightPositionGroupId, highlightPositionGroup, scrollToPositionGroup, markerClasses]
    );

    const clearHighlight = useCallback(() => highlightPositionGroup(undefined), [highlightPositionGroup]);

    const renderMovementsAndStops = useMemo(() => {
        const positionGroupsAreLoaded = utility.positionGroupsAreLoaded(positionGroups, pendingPositionGroups);

        return positionGroupsAreLoaded ? (
            <FeatureGroup eventHandlers={{ mouseout: clearHighlight }}>
                {renderMovements(positionGroups.data)}
                {renderStops(positionGroups.data)}
                {highlightPosition && <PositionMarker position={highlightPosition} />}
            </FeatureGroup>
        ) : null;
    }, [positionGroups, pendingPositionGroups, highlightPosition, renderMovements, renderStops, clearHighlight]);

    useEffect(() => {
        if (mapRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                mapRef.current?.invalidateSize();
            });

            resizeObserver.observe(mapRef.current.getContainer());

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [size]);

    return (
        <div data-id="history-map" style={size}>
            <Map
                {...viewport}
                baseLayer={mapLayersProps}
                bounds={mapBounds}
                className={classes.map}
                disableBaseLayerControls
                dragging
                ref={(instance) => {
                    mapRef.current = instance;
                }}
                touchZoom
                worldCopyJump
            >
                <ScaleControl imperial metric position="bottomleft" />

                {renderMovementsAndStops}

                <MapEventControls onChangeViewport={changeViewport} />
            </Map>
        </div>
    );
};
