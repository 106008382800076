import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TimeSeriesDialogContentClassKey =
    | 'chart'
    | 'content'
    | 'contentHeader'
    | 'contentTitle'
    | 'loaderContainer'
    | 'messageText'
    | 'tabsPaper';

export const TimeSeriesDialogContentStyleRules: StyleRulesCallback<Theme, {}, TimeSeriesDialogContentClassKey> = (
    theme: Theme
) =>
    createStyles({
        chart: {
            flexGrow: 1,
            minHeight: theme.custom.chart.minHeight,
        },
        content: {
            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            margin: theme.spacing(2),
        },
        contentHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between ',
        },
        contentTitle: {
            lineHeight: 3,
        },
        loaderContainer: {
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            minHeight: theme.custom.loader.minHeight,
        },
        messageText: {
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            fontStyle: 'italic',
            justifyContent: 'center',
            minHeight: theme.custom.dialogContent.minHeight,
        },
        tabsPaper: {
            backgroundColor: theme.palette.background.default,
            width: 144,
        },
    });
