import type { CustomersResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    CUSTOMERS = 'CUSTOMERS',
}

export enum ActionTypeKeys {
    CUSTOMERS_FULFILLED = 'CUSTOMERS_FULFILLED',
    CUSTOMERS_PENDING = 'CUSTOMERS_PENDING',
    CUSTOMERS_REJECTED = 'CUSTOMERS_REJECTED',
}

export type CustomersPayload = Readonly<CustomersResponse>;

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.CUSTOMERS_FULFILLED, CustomersPayload>
    | Pending<ActionTypeKeys.CUSTOMERS_PENDING>
    | Rejected<ActionTypeKeys.CUSTOMERS_REJECTED>;
