import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type NavigationSectionClassKeys = 'elapsedTimeContainer' | 'indicatorsContainer';

export const useStyles = makeStyles<Theme, {}, NavigationSectionClassKeys>((theme: Theme) => ({
    elapsedTimeContainer: {
        marginLeft: theme.spacing(2),
    },
    indicatorsContainer: {
        marginTop: theme.spacing(-3),
    },
}));
