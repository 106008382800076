export enum ColumnName {
    ADDRESS = 'address',
    ALTITUDE = 'altitude',
    CITY = 'city',
    COUNTRYCODE = 'country-code',
    DATETIME = 'date-time',
    DEVICETYPE = 'device-type',
    FUELLEVEL = 'fuel-level',
    HEADING = 'heading',
    IGNITIONSTATUS = 'ignition-status',
    LATITUDE = 'latitude',
    LONGITUDE = 'longitude',
    MSISDN = 'msisdn',
    NEARESTCITY = 'nearest-city',
    ODOMETER = 'odometer',
    POSTALCODE = 'postal-code',
    SPEED = 'speed',
    TOTALFUELUSAGE = 'total-fuel-usage',
    VEHICLE = 'vehicle',
}
