import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LabeledIndicatorGroupClassKey =
    | 'badge'
    | 'flex'
    | 'option'
    | 'options'
    | 'primaryText'
    | 'root'
    | 'secondaryText'
    | 'splitRow'
    | 'title';

export const LabeledIndicatorGroupStyleRules: StyleRulesCallback<Theme, {}, LabeledIndicatorGroupClassKey> = (
    theme: Theme
) =>
    createStyles({
        badge: {
            '& > *': {
                height: 20,
                width: 20,
            },
            '& > * >*': {
                height: 20,
                width: 20,
            },
            '& > * >* >*': {
                height: 14,
                width: 14,
            },
            display: 'inline-block',
            marginRight: theme.spacing(0.5),
        },
        flex: {
            display: 'flex',
        },
        option: {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            padding: theme.spacing(0.3),
        },
        options: {
            '& > *:first-child': {
                backgroundColor: theme.palette.primary.main,
                borderBottomLeftRadius: theme.shape.borderRadius,
                borderTopLeftRadius: theme.shape.borderRadius,
                color: theme.palette.primary.contrastText,
            },
            '& > *:last-child': {
                borderBottomRightRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
            },
            '& > *:not(:last-child)': {
                borderRight: 'none',
            },
            fontSize: 10,
            fontWeight: theme.typography.fontWeightMedium,
            marginBottom: theme.spacing(0.3),
            marginRight: theme.spacing(),
        },
        primaryText: {
            fontSize: 12,
            textAlign: 'right',
            width: '100%',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0.5),
            width: '100%',
        },
        secondaryText: {
            fontSize: 10,
            textAlign: 'right',
            width: '100%',
        },
        splitRow: {
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(),
            width: '100%',
        },
        title: {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium,
            margin: 'auto',
        },
    });
