import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Backdrop, Button, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import type { IntroCarouselInnerProps } from './models';
import { useStyles } from './styles';

const IntroCarousel: FC<IntroCarouselInnerProps> = (props) => {
    const { carousel, close, open, retrieveLatestIntroCarousel } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (!carousel) {
            retrieveLatestIntroCarousel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!open) {
        return null;
    }

    return (
        <>
            <Backdrop className={classes.backdrop} onClick={close} open />

            <div className={classes.container} data-id="intro-carousel">
                <Slider
                    autoplay
                    autoplaySpeed={carousel?.autoPlaySpeed ?? 7000}
                    dots
                    infinite
                    nextArrow={
                        <div>
                            <ArrowForwardIcon className={classes.arrowIcon} />
                        </div>
                    }
                    prevArrow={
                        <div>
                            <ArrowBackIcon className={classes.arrowIcon} />
                        </div>
                    }
                    slidesToScroll={1}
                    slidesToShow={1}
                    speed={300}
                >
                    {carousel?.slides.map(({ customUrl, imageSource, subtitle, title }) => (
                        <div key={imageSource}>
                            <div className={classes.imgContainer}>
                                <img
                                    alt={title}
                                    className={classes.slideImage}
                                    height={390}
                                    src={imageSource}
                                    width={700}
                                />
                            </div>

                            <div className={classes.textContainer}>
                                <Tooltip title={title}>
                                    <Typography className={classes.title} variant="h6">
                                        {title}
                                    </Typography>
                                </Tooltip>

                                <Tooltip title={subtitle}>
                                    <Typography className={classes.subtitle} variant="body1">
                                        {subtitle}
                                    </Typography>
                                </Tooltip>

                                {customUrl && (
                                    <Button
                                        className={classes.newsletter}
                                        data-testid="custom-url"
                                        href={customUrl}
                                        target="_blank"
                                        variant="contained"
                                    >
                                        {t('newsletter')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                </Slider>

                <Button className={classes.dismiss} data-id="get-started" onClick={close} variant="contained">
                    {t('get-started')}
                </Button>
            </div>
        </>
    );
};

IntroCarousel.displayName = 'IntroCarousel';
export default IntroCarousel;
