import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    group: {
        backgroundColor: theme.palette.background.paper,
        gap: theme.spacing(),
        height: theme.spacing(5),
        margin: 0,
    },
    item: {
        height: theme.spacing(5),
        margin: 0,
        paddingLeft: (props: { indentLevel: number }) => theme.spacing(1.5 + (props.indentLevel + 1) * 4),
    },
    list: { margin: 0, padding: 0 },
}));

export { useStyles };
