import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import type { WidgetDialogInnerProps } from './models';
import { useStyles } from './styles';

const WidgetDialog: FC<WidgetDialogInnerProps> = (props) => {
    const {
        chartRef,
        children,
        dialogActions,
        disableEscapeKeyDown = false,
        headerActions,
        leftFooterAdornment,
        onClose,
        open,
        testId,
        title,
    } = props;

    const classes = useStyles();

    const actions = headerActions?.map((headerAction: ReactNode, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.headerAction} key={`header_action_${i}`}>
            {headerAction}
        </div>
    ));

    const footer = !!(dialogActions || leftFooterAdornment) && (
        <div className={classes.footer} data-id="footer-content">
            {leftFooterAdornment && <div className={classes.leftFooterAdornment}>{leftFooterAdornment}</div>}
            <DialogActions classes={{ root: classes.dialogActions }}>{dialogActions}</DialogActions>
        </div>
    );

    return (
        <Dialog
            classes={{ paper: classes.customContentStyle }}
            disableEscapeKeyDown={disableEscapeKeyDown}
            maxWidth="lg"
            onClose={onClose}
            open={open}
            PaperProps={{ ref: chartRef }}
        >
            <div className={classes.dialog} data-testid={testId}>
                <DialogTitle className={classes.header} data-id="header-content">
                    {typeof title === 'string' ? (
                        <Typography className={classes.title} variant="body1">
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                    {actions && <div className={classes.headerActions}>{actions}</div>}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>{children}</DialogContent>
                {footer && <Divider />}
                {footer}
            </div>
        </Dialog>
    );
};

WidgetDialog.displayName = 'WidgetDialog';
export default memo(WidgetDialog);
