import type { FC } from 'react';

import type { GridColumnDefinition } from '~/components/Grid';
import { HeaderTemplate } from '~/components/HeaderTemplate';
import { IconDivider } from '~/components/IconDivider';
import { Search } from '~/components/Search';

import type { SceneListUserPreferences } from '../../preferences';

import { ViewBarActionsFactory } from './components/ViewBarActions';
import type { SceneListViewHeaderInnerProps } from './models';

export const SceneListViewHeaderComponentFactory = <T,>(
    preferencesKey: string,
    defaultListUserPreferencesState: SceneListUserPreferences,
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[],
    excelFileName: string
): FC<SceneListViewHeaderInnerProps<T>> => {
    const ViewBarActions = ViewBarActionsFactory<T>(
        preferencesKey,
        defaultListUserPreferencesState,
        filterDataSourceMemoized,
        excelFileName
    );

    const Component = ({
        changeSearchQuery,
        clearSearchQuery,
        columns,
        dataSource,
        disableActions,
        excelSheetTitle,
        filters,
        gridActionsDisabled,
        moduleBarActions,
        onFilteredDataSourceChange,
        onShowColumns,
        perspectiveButtons,
        searchQuery,
        title,
    }: SceneListViewHeaderInnerProps<T>) => (
        <HeaderTemplate
            actions={
                <>
                    {moduleBarActions}
                    {moduleBarActions && <IconDivider />}
                    <ViewBarActions
                        columns={columns}
                        dataSource={dataSource}
                        disableActions={disableActions}
                        excelSheetTitle={excelSheetTitle}
                        gridActionsDisabled={gridActionsDisabled}
                        onFilteredDataSourceChange={onFilteredDataSourceChange}
                        onShowColumns={onShowColumns}
                    />
                    {perspectiveButtons && <IconDivider />}
                    {perspectiveButtons}
                </>
            }
            centerEl={
                <Search
                    clearSearchQuery={clearSearchQuery}
                    defaultSearchQuery={searchQuery}
                    onSearchQueryChange={changeSearchQuery}
                />
            }
            filters={filters}
            title={title}
        />
    );

    return Component;
};
