import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TrailerMarkerClassKey =
    | 'assetName'
    | 'driverActivityAvailableBackgroundColor'
    | 'driverActivityAvailableBorderColor'
    | 'driverActivityDriveBackgroundColor'
    | 'driverActivityDriveBorderColor'
    | 'driverActivityRestBackgroundColor'
    | 'driverActivityRestBorderColor'
    | 'driverActivityWorkBackgroundColor'
    | 'driverActivityWorkBorderColor'
    | 'trailerBackgroundColor'
    | 'trailerBorderColor';

export const TrailerMarkerStyleRules: StyleRulesCallback<Theme, {}, TrailerMarkerClassKey> = (theme: Theme) =>
    createStyles({
        assetName: {
            backgroundColor: theme.functionalItemsColors.assetType.trailer.contrast,
            borderColor: theme.functionalItemsColors.assetType.trailer.value,
            color: theme.functionalItemsColors.assetType.trailer.value,
        },
        driverActivityAvailableBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
            color: theme.functionalItemsColors.driverActivity.available.contrast,
        },
        driverActivityAvailableBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.available.value,
        },
        driverActivityDriveBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
            color: theme.functionalItemsColors.driverActivity.drive.contrast,
        },
        driverActivityDriveBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.drive.value,
        },
        driverActivityRestBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
            color: theme.functionalItemsColors.driverActivity.rest.contrast,
        },
        driverActivityRestBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.rest.value,
        },
        driverActivityWorkBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
            color: theme.functionalItemsColors.driverActivity.work.contrast,
        },
        driverActivityWorkBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.work.value,
        },
        trailerBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.assetType.trailer.value,
            color: theme.functionalItemsColors.assetType.trailer.contrast,
        },
        trailerBorderColor: {
            borderColor: theme.functionalItemsColors.assetType.trailer.value,
        },
    });
