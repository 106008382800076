import { TextField } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { TextFieldWorkflowFormElement } from '~/services/ApiClient';

import type { TextWorkflowFieldClassKey } from './styles';

export interface TextWorkflowFieldProps {
    dataId: string;
    disabled?: boolean;
    field: TextFieldWorkflowFormElement;
    label?: string;
}

export interface TextWorkflowFieldInnerProps
    extends TextWorkflowFieldProps,
        InjectedTranslationProps,
        WithStyles<TextWorkflowFieldClassKey> {}

export const TextWorkflowFieldComponent: React.FC<TextWorkflowFieldInnerProps> = ({
    classes,
    dataId,
    disabled,
    field,
    label,
    t,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { id, isReadOnly, isRequired, maxLength, minLength } = field;
    const error = errors[id];
    const className = classes[`${field.horizontalAlignment}Alignment`];
    const requiredMessage = t('wf-field-error-required');

    const rules = React.useMemo(() => {
        return {
            maxLength,
            minLength: minLength && {
                message: t('wf-field-error-min-length', {
                    minLength,
                }),
                value: minLength,
            },
            required: { message: requiredMessage, value: isRequired },
        };
    }, [isRequired, maxLength, minLength, requiredMessage, t]);

    const onChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: unknown[]) => void) => {
            const { value } = e.currentTarget;

            const maxLengthReached = maxLength && value.length > maxLength;
            if (maxLengthReached) {
                const textCut = value.substring(0, maxLength);
                return onChange(textCut);
            }
            return onChange(value);
        },
        [maxLength]
    );

    return (
        <Controller
            control={control}
            defaultValue=""
            name={`${id}`}
            render={({ field: fieldFormProps }) => (
                <TextField
                    {...fieldFormProps}
                    data-id={dataId}
                    disabled={disabled}
                    error={!!error}
                    fullWidth
                    helperText={error ? error.message : isRequired ? requiredMessage : undefined}
                    InputProps={{
                        classes: {
                            input: className,
                        },
                        readOnly: isReadOnly,
                    }}
                    label={label}
                    onChange={(e) => onChangeHandler(e, fieldFormProps.onChange)}
                    required={isRequired}
                    size="small"
                    variant="outlined"
                />
            )}
            rules={rules}
        />
    );
};
