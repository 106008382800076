import 'leaflet-geosearch/dist/geosearch.css';
import { GeoSearchControl } from 'leaflet-geosearch';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import type { GeoSearchControlArgs, SearchBarProps } from './models';
import { useClassNames } from './styles';

const SearchBar = <T,>(props: SearchBarProps<T>) => {
    const { classNames, onSearch, ...restProps } = props;

    const className = useClassNames();
    const map = useMap();

    const searchControl = GeoSearchControl({
        autoClose: true,
        classNames: { ...className, ...classNames },
        provider: { search: ({ query }) => onSearch(query) },
        showMarker: false,
        zoomLevel: 17,
        ...restProps,
    } as GeoSearchControlArgs);

    useEffect(() => {
        map.addControl(searchControl);

        return () => {
            map.removeControl(searchControl);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

SearchBar.displayName = 'SearchBar';
export { SearchBar };
