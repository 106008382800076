import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { IgnitionOffIcon, IgnitionOnIcon } from '~/components/Icons';

import type { IgnitionIconFormatterClassKey } from './styles';

export interface IgnitionIconFormatterProps {
    value: boolean;
}

export interface IgnitionIconFormatterInnerProps
    extends IgnitionIconFormatterProps,
        WithStyles<IgnitionIconFormatterClassKey> {}

export const IgnitionIconFormatterComponent: React.FC<IgnitionIconFormatterInnerProps> = ({ classes, value }) => {
    const ignitionIcon = value ? <IgnitionOnIcon /> : <IgnitionOffIcon />;
    const avatarClassName = classNames(classes.iconSize, {
        [classes.off]: !value,
        [classes.on]: value,
    });
    return <Avatar className={avatarClassName}>{ignitionIcon}</Avatar>;
};
