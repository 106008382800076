import type { PopoverProps } from '@mui/material';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import type { FC, MouseEvent, ReactNode } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Message } from '~/services/ApiClient';
import {
    IncomingMessage,
    IncomingMessageStatus,
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    OutgoingMessage,
    OutgoingMessageStatus,
} from '~/services/ApiClient';

export interface MenuProps {
    anchorElement: PopoverProps['anchorEl'];
    canDeleteMessage: boolean;
    canUpdateReadStatus: boolean;
    conversationMessage: Message;
    onClickMarkAsRead: (e: MouseEvent<HTMLLIElement>) => void;
    onClose: () => void;
    onCopyMessageToDraft?: () => void;
    onDeleteMessage: () => void;
    onDiscardMessage: () => void;
    onInfo: () => void;
    onRetrySendMessage: () => void;
    onUndeleteMessage: () => void;
    open: boolean;
    updatingReadStatus: boolean;
}

export interface MenuInnerProps extends MenuProps, InjectedTranslationProps {}

export const MenuComponent: FC<MenuInnerProps> = (props) => {
    const {
        anchorElement,
        canDeleteMessage,
        canUpdateReadStatus,
        conversationMessage,
        onClickMarkAsRead,
        onClose,
        onCopyMessageToDraft,
        onDeleteMessage,
        onDiscardMessage,
        onInfo,
        onRetrySendMessage,
        onUndeleteMessage,
        open,
        t,
        updatingReadStatus,
    } = props;

    const getMenuItems = () => {
        const items: Array<ReactNode> = [
            <MenuItem data-id="message-menu:info" key="info" onClick={onInfo}>
                {t('message-info')}
            </MenuItem>,
        ];

        if (
            conversationMessage instanceof OutgoingMessage ||
            conversationMessage instanceof OptimisticOutgoingConversationMessage
        ) {
            items.push(
                onCopyMessageToDraft && (
                    <MenuItem data-id="message-menu:copy-to-draft" key="copy-to-draft" onClick={onCopyMessageToDraft}>
                        {t('copy-to-draft')}
                    </MenuItem>
                )
            );
        }

        if (
            conversationMessage instanceof OptimisticOutgoingConversationMessage &&
            (conversationMessage.status === OptimisticOutgoingConversationMessageStatus.GeneralFailure ||
                conversationMessage.status === OptimisticOutgoingConversationMessageStatus.ValidationFailure)
        ) {
            items.push(
                <MenuItem
                    data-id="message-menu:retry-send-message"
                    key="retry-send-message"
                    onClick={onRetrySendMessage}
                >
                    {t('retry')}
                </MenuItem>
            );
            items.push(
                <MenuItem data-id="message-menu:discard-message" key="discard-message" onClick={onDiscardMessage}>
                    {t('discard')}
                </MenuItem>
            );
        }

        if (conversationMessage instanceof IncomingMessage) {
            const markAsReadTranslationKey =
                conversationMessage.status === IncomingMessageStatus.Read ? 'mark-as-unread' : 'mark-as-read';

            const markAsReadTooltipTranslationKey = !canUpdateReadStatus
                ? 'change-read-status-not-authorized'
                : updatingReadStatus
                  ? 'changing-read-status'
                  : '';

            const markAsReadDisabled = !canUpdateReadStatus || updatingReadStatus;

            items.push(
                <Tooltip key={markAsReadTranslationKey} title={t(markAsReadTooltipTranslationKey)}>
                    <div>
                        {/* needs wrapping in a div, otherwise won't show tooltip when disabled */}
                        <MenuItem
                            /**
                             * temp adding aria-disabled to handle disabled status in ui tests,
                             * it is a default prop material-ui v4 and should be removed once we upgrade to v4
                             */
                            aria-disabled={markAsReadDisabled}
                            data-id={`message-menu:${markAsReadTranslationKey}`}
                            disabled={markAsReadDisabled}
                            onClick={onClickMarkAsRead}
                        >
                            {t(markAsReadTranslationKey)}
                        </MenuItem>
                    </div>
                </Tooltip>
            );
        }

        if (
            canDeleteMessage &&
            (conversationMessage instanceof IncomingMessage || conversationMessage instanceof OutgoingMessage)
        ) {
            if (conversationMessage.isDeleted) {
                items.push(
                    <MenuItem
                        data-id="message-menu:undelete-message"
                        key="undelete-message"
                        onClick={onUndeleteMessage}
                    >
                        {t('undelete')}
                    </MenuItem>
                );
            } else if (
                !conversationMessage.isDeleted &&
                conversationMessage.status !== OutgoingMessageStatus.Scheduled
            ) {
                items.push(
                    <MenuItem data-id="message-menu:delete-message" key="delete-message" onClick={onDeleteMessage}>
                        {t('delete')}
                    </MenuItem>
                );
            } else if (
                !conversationMessage.isDeleted &&
                conversationMessage.status === OutgoingMessageStatus.Scheduled
            ) {
                items.push(
                    <MenuItem
                        data-id="message-menu:cancel-scheduled-message"
                        key="cancel-message"
                        onClick={onDeleteMessage}
                    >
                        {t('cancel')}
                    </MenuItem>
                );
            }
        }

        return items;
    };

    return (
        <Menu
            anchorEl={anchorElement}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            disableAutoFocusItem
            id="message-menu"
            onClose={onClose}
            open={open}
        >
            {getMenuItems()}
        </Menu>
    );
};
