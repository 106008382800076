import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type NumericInputClassKey =
    | 'centerAlignment'
    | 'changedNotchedOutline'
    | 'focusChangedNotchedOutline'
    | 'leftAlignment'
    | 'outlinedInput';

export const NumericInputStyleRules: StyleRulesCallback<{}, {}, NumericInputClassKey> = (theme: Theme) =>
    createStyles({
        centerAlignment: {
            textAlign: 'center',
        },
        changedNotchedOutline: {
            borderColor: `${theme.palette.warning.main} !important`,
            borderWidth: '1px',
        },
        focusChangedNotchedOutline: {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        leftAlignment: {
            textAlign: 'left',
        },
        outlinedInput: {
            '&:hover:not($disabled):not($focused):not($error) $changedNotchedOutline': {
                borderColor: `${theme.palette.warning.dark} !important`,
            },
            '&$focusChangedNotchedOutline $changedNotchedOutline': {
                borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    });
