import type { ContainerStatus } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    CONTAINERSTATUS = 'CONTAINERSTATUS',
}

export enum ActionTypeKeys {
    CONTAINERSTATUS_FULFILLED = 'CONTAINERSTATUS_FULFILLED',
    CONTAINERSTATUS_PENDING = 'CONTAINERSTATUS_PENDING',
    CONTAINERSTATUS_REJECTED = 'CONTAINERSTATUS_REJECTED',
    CONTAINERSTATUS_UPDATE = 'CONTAINERSTATUS_UPDATE',
}

export interface ContainerStatusPayload {
    readonly items: ContainerStatus[];
}

export type ActionTypes =
    | Action<ActionTypeKeys.CONTAINERSTATUS_UPDATE, ContainerStatus>
    | Fulfilled<ActionTypeKeys.CONTAINERSTATUS_FULFILLED, ContainerStatusPayload>
    | Pending<ActionTypeKeys.CONTAINERSTATUS_PENDING>
    | Rejected<ActionTypeKeys.CONTAINERSTATUS_REJECTED>;
