import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from '~/components/LanguageSelector';

import { useStyles } from './styles';

export interface ResetPasswordNoticeProps {}

export interface ResetPasswordNoticeInnerProps extends ResetPasswordNoticeProps {}

const ResetPasswordNotice: React.FC<ResetPasswordNoticeInnerProps> = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography gutterBottom variant="h5">
                {t('reset-password')}
            </Typography>
            <br />
            <Typography variant="body2">{t('reset-password-notice-first-paragraph')}</Typography>
            <br />
            <Typography variant="body2">{t('reset-password-notice-second-paragraph')}</Typography>
            <Link className={classes.link} data-id="login-link" to="/">
                {t('reset-password-link-back-to-login')}
            </Link>
        </div>
    );
};
ResetPasswordNotice.displayName = 'ResetPasswordNotice';

export { ResetPasswordNotice };
