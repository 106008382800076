import * as React from 'react';

import { isUndefined } from '~/libs/utility';

import type { DocumentViewerScale, DocumentViewerScaling, Size } from '../models';
import { DocumentViewerScaleMode } from '../models';

import { getFitToScreenScale, getFitToScreenWidthScale, getNextScales } from './useScaling.utilities';

export const useScaling = (defaultScreenSize?: Size, defaultContentSize?: Size): DocumentViewerScaling => {
    const [screenSize, setScreenSize] = React.useState(defaultScreenSize);
    const [contentSize, setContentSize] = React.useState(defaultContentSize);
    const [scale, setScale] = React.useState<DocumentViewerScale>(DocumentViewerScaleMode.FitToScreen);

    return React.useMemo(() => {
        let currentScale: number | undefined;
        switch (scale) {
            case DocumentViewerScaleMode.FitToScreen:
                currentScale = screenSize && contentSize && getFitToScreenScale(screenSize, contentSize);
                break;
            case DocumentViewerScaleMode.FitToScreenWidth:
                currentScale = screenSize && contentSize && getFitToScreenWidthScale(screenSize, contentSize);
                break;
            default:
                currentScale = scale;
                break;
        }

        const { in: zoomInScale, out: zoomOutScale } = getNextScales(currentScale ?? 1);

        return {
            fitToScreen: () => setScale(DocumentViewerScaleMode.FitToScreen),
            fitToScreenWidth: () => setScale(DocumentViewerScaleMode.FitToScreenWidth),
            scale,
            setContentSize,
            setScreenSize,
            value: currentScale ?? 1,
            zoomIn: isUndefined(zoomInScale) ? undefined : () => setScale(zoomInScale),
            zoomOut: isUndefined(zoomOutScale) ? undefined : () => setScale(zoomOutScale),
        };
    }, [scale, setScreenSize, setContentSize, screenSize, contentSize, setScale]);
};
