import { withTranslation } from 'react-i18next';
import { defaultProps } from 'react-recompose';

import { ViolationFieldFormatterFactory } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumberViolationField, ViolationField } from '~/services/ApiClient';
import { exportFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, compareNumbers } from '~/services/Sorting';

import { ViolationFieldGroupFormatter } from '../Formatters/ViolationFieldGroup';

import type { CreateColumnOptions, GridColumnDefinition } from './models';
import { violationGroupingCriteriaForViolationField } from './violationGroupCriteria';

export const createNumberWithViolationColumn = <T>(
    t: SingleTFunction,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => NumberViolationField | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => {
    const NumberViolationFieldFormatterComponent = ViolationFieldFormatterFactory((v: number) => v.toString());

    return {
        ...options,
        align: 'right',
        compare: compareFactory((v: ViolationField<number>) => v.value, compareNumbers),
        dataType: 'number',
        exportValueFormatter: exportFormatterFactory(
            (numberViolationField: ViolationField<number>) => numberViolationField.value
        ),
        formatUndefinedValue: true,
        getCellValue,
        getFilterValue: exportFormatterFactory(
            (numberViolationField: ViolationField<number>) => numberViolationField.value
        ),
        groupingCriteria: groupingCriteriaFactory(violationGroupingCriteriaForViolationField<number>()),
        groupTitle,
        groupValueFormatterComponent: withTranslation()(ViolationFieldGroupFormatter<number>()),
        name,
        title,
        valueFormatterComponent: defaultProps({ fieldName: title, t })(NumberViolationFieldFormatterComponent),
    };
};
