import type { Dispatch } from 'redux';

import { uuidv4 } from '~/libs/utility';
import { ServerResultStatus } from '~/services/ApiClient';

import { actions } from './actionTypes';
import type {
    ClearRequest,
    PendingRequestPayload,
    RejectRequestArgs,
    RejectRequestPayload,
    StartRequestArgs,
} from './models';

const clearRequest = (args: ClearRequest) => (dispatch: Dispatch) => {
    const { id } = args;

    dispatch({ payload: { id }, type: actions.CLEAR });
};

const rejectedRequest = (args: RejectRequestArgs) => (dispatch: Dispatch) => {
    const { id } = args;
    const payload: RejectRequestPayload = {
        id,
        shouldOpen: true,
        status: ServerResultStatus.SERVERERROR,
    };

    dispatch({ payload, type: actions.REJECTED });
    dispatch(clearRequest({ id }));
};

const startRequest = <T extends { requestId: string }>(args: StartRequestArgs<T>) => {
    return async (dispatch: Dispatch) => {
        const { apiCall, form } = args;

        const payload: PendingRequestPayload = {
            form,
            id: uuidv4(),
            shouldOpen: true,
            status: ServerResultStatus.PENDING,
        };

        dispatch({ payload, type: actions.PENDING });

        try {
            const { requestId } = await apiCall();

            dispatch({
                payload: { ...payload, requestId, shouldOpen: false },
                type: actions.PENDING,
            });

            return ServerResultStatus.OK;
        } catch (error) {
            dispatch(rejectedRequest({ id: payload.id }));

            return ServerResultStatus.SERVERERROR;
        }
    };
};

export { clearRequest, rejectedRequest, startRequest };
