import type { ReactNode } from 'react';
import { Component } from 'react';
import { generatePath } from 'react-router';

import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { MonitoringVehicleEntry, SectionName, VehicleDetailsPaneUserPreferences } from '~/data/monitoring';
import { MonitoringPerspective } from '~/data/monitoring';

import { MONITORING_PATH_STRUCTURE } from '../../../../consts';

import { VehicleHeader } from './components/VehicleHeader';
import type { GetSectionsArgs, VehicleDetailsPaneInnerProps } from './models';
import { DETAILSPANE_VEHICLE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized, getUnauthorizedSectionsMemoized } from './sections';

const DetailsPaneComponent = SceneDetailsPaneFactory<
    MonitoringVehicleEntry,
    VehicleDetailsPaneUserPreferences,
    SectionName
>(DETAILSPANE_VEHICLE_USERPREFERENCES_KEY);

export class VehicleDetailsPaneComponent extends Component<VehicleDetailsPaneInnerProps> {
    private getSections = ({ monitoringViewMode, toggleCollapsedState, userPreferences }: GetSectionsArgs) =>
        getSectionsMemoized({
            assetGroups: this.props.assetGroups,
            changeCollapsedTemperatureCompartments: this.props.changeCollapsedTemperatureCompartments,
            changeMiniMapMode: this.props.changeMiniMapMode,
            changeMiniMapZoomLevel: this.props.changeMiniMapZoomLevel,
            collapsedSections: userPreferences.collapsedSections,
            collapsedTemperatureCompartments: userPreferences.collapsedTemperatureCompartments,
            depots: this.props.depots,
            entry: this.props.entry,
            handleLocate: this.props.panToVehicle,
            miniMapMode: userPreferences.miniMapMode,
            miniMapZoomLevel: userPreferences.miniMapZoomLevel,
            toggleCollapsedState,
            viewMode: monitoringViewMode,
        });

    private handleClose = () => {
        this.props.history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                perspective: MonitoringPerspective.VEHICLE,
                viewMode: this.props.viewMode,
            })
        );
    };

    public render(): ReactNode {
        const { entry, securables } = this.props;
        return (
            <DetailsPaneComponent
                dataId="vehicle-details-pane"
                entry={entry}
                getSections={(userPreferences, toggleCollapsedState) =>
                    this.getSections({ monitoringViewMode: this.props.viewMode, toggleCollapsedState, userPreferences })
                }
                Header={VehicleHeader}
                onClose={this.handleClose}
                unauthorizedSections={getUnauthorizedSectionsMemoized(securables)}
            />
        );
    }
}
