import type { NumericDictionary } from '~/libs/utility';
import { get, isUndefined, mapValues, omit } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import {
    IncomingMessageStatus,
    OptimisticOutgoingConversationMessageStatus,
    ValidationFailedResponse,
} from '~/services/ApiClient';
import type {
    Conversation,
    ConversationMessageCorrelationRef,
    ConversationUpdate,
    ResolvedConversation,
    ResolvedTranslateMessageResponse,
} from '~/services/ApiClient';
import { mergeConversationUpdates } from '~/services/Conversations';

import type { ActionTypes } from '../../data';
import { ActionTypeKeys } from '../../data';
import type { ConversationsRootStoreState } from '../../reducers';
import { resolveConversationMessage } from '../../reducers.resolveConversationMessage';
import { resolveConversationUpdate } from '../../reducers.resolveConversationUpdate';

import {
    expectedNumberOfConversationMessagesWhenRetrievingConversation,
    numberOfConversationMessagesToRequestWhenRetrievingOlderMessages,
} from './constants';
import { applyConversationUpdate } from './reducers.applyConversationUpdate';
import { filterPendingAllMessagesStatusUpdates } from './reducers.filterPendingAllMessagesStatusUpdates';
import { filterPendingMessageStatusUpdate } from './reducers.filterPendingMessageStatusUpdate';
import { mergeOptimisticMessagesIntoConversation } from './reducers.mergeOptimisticMessagesIntoConversation';
import { pushOptimisticMessageToConversation } from './reducers.pushOptimisticMessageToConversation';
import { removeOptimisticConversationMessage } from './reducers.removeOptimisticConversationMessage';
import { resolveConversation } from './reducers.resolveConversation';
import { updateMissingWorkflowFormDefinitions } from './reducers.updateMissingWorkflowFormDefinitions';
import { updateOptimisticConversationMessageStatus } from './reducers.updateOptimisticConversationMessageStatus';

export interface PendingMessageStatusUpdate {
    expectedStatus?: IncomingMessageStatus;
    expectedStatusDeleted?: boolean;
}

export interface ConversationWidgetStoreState {
    allUnreadMessagesThatAreBeingMarkedAsRead: number[];
    conversation: RetrievableData<Conversation | undefined>;
    failedToLoadOlderMessages: boolean;
    loadingOlderMessages: boolean;
    markingAllMessagesAsRead: boolean;
    noOlderMessagesToDisplay: boolean;
    pagingAfterCursor?: string;
    pendingMessagesStatusUpdates: NumericDictionary<PendingMessageStatusUpdate>;
    resolvedConversation?: ResolvedConversation;
    shouldFocusInput: boolean;
    unacknowledgedFailedMessagesCorrelationIds: string[];
    unresolvedWorkflowFormIds: number[];
}

export interface TranslatedMessageState extends ResolvedTranslateMessageResponse {
    pending: boolean;
}

export interface TranslatedMessagesState extends NumericDictionary<NumericDictionary<TranslatedMessageState>> {}

export interface ConversationWidgetsStoreState {
    conversations: NumericDictionary<ConversationWidgetStoreState>;
    correlationRefs: NumericDictionary<ConversationMessageCorrelationRef[]>;
    pendingConversationsUpdates: NumericDictionary<ConversationUpdate>;
    shouldOpenConversation: boolean;
    shouldOpenConversationForVehicleId?: number;
    translatedMessages: TranslatedMessagesState;
}

export const defaultConversationState: RetrievableData<Conversation | undefined> = {
    data: undefined,
    fulfilled: false,
    pending: false,
    rejected: false,
};

export const defaultConversationWidgetStoreState: ConversationWidgetStoreState = {
    allUnreadMessagesThatAreBeingMarkedAsRead: [],
    conversation: defaultConversationState,
    failedToLoadOlderMessages: false,
    loadingOlderMessages: false,
    markingAllMessagesAsRead: false,
    noOlderMessagesToDisplay: false,
    pagingAfterCursor: undefined,
    pendingMessagesStatusUpdates: {},
    shouldFocusInput: false,
    unacknowledgedFailedMessagesCorrelationIds: [],
    unresolvedWorkflowFormIds: [],
};

const defaultStoreState: ConversationWidgetsStoreState = {
    conversations: {},
    correlationRefs: {},
    pendingConversationsUpdates: {},
    shouldOpenConversation: false,
    translatedMessages: {},
};

const updateStateIfNeeded = (
    vehicleId: number,
    state: ConversationWidgetsStoreState,
    updateCurrentState: (conversationState?: ConversationWidgetStoreState) => ConversationWidgetStoreState | undefined
): ConversationWidgetsStoreState => {
    const actualConversationState = state.conversations[vehicleId];

    const updatedConversationState = updateCurrentState(actualConversationState);

    if (updatedConversationState === actualConversationState) {
        return state;
    }

    if (isUndefined(updatedConversationState)) {
        return {
            ...state,
            conversations: omit(state.conversations, vehicleId),
        };
    }

    return {
        ...state,
        conversations: {
            ...state.conversations,
            [vehicleId]: updatedConversationState,
        },
    };
};

export const conversationWidgetsReducer = (
    state: ConversationWidgetsStoreState = defaultStoreState,
    action: ActionTypes,
    conversationsRoot: ConversationsRootStoreState
): ConversationWidgetsStoreState => {
    switch (action.type) {
        case ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_PENDING: {
            const actualTranslatedMessages = state.translatedMessages;

            return {
                ...state,
                translatedMessages: {
                    ...actualTranslatedMessages,
                    [action.meta.vehicleId]: {
                        ...actualTranslatedMessages[action.meta.vehicleId],
                        [action.meta.messageId]: {
                            ...(get(
                                actualTranslatedMessages,
                                `${action.meta.vehicleId}.${action.meta.messageId}`,
                                {}
                            ) as TranslatedMessageState),
                            pending: true,
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_FULFILLED: {
            const actualTranslatedMessages = state.translatedMessages;

            return {
                ...state,
                translatedMessages: {
                    ...actualTranslatedMessages,
                    [action.meta.vehicleId]: {
                        ...actualTranslatedMessages[action.meta.vehicleId],
                        [action.meta.messageId]: {
                            ...action.payload,
                            pending: false,
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_REJECTED: {
            const actualTranslatedMessages = state.translatedMessages;

            return {
                ...state,
                translatedMessages: {
                    ...actualTranslatedMessages,
                    [action.meta.vehicleId]: {
                        ...actualTranslatedMessages[action.meta.vehicleId],
                        [action.meta.messageId]: {
                            ...(get(
                                actualTranslatedMessages,
                                `${action.meta.vehicleId}.${action.meta.messageId}`,
                                {}
                            ) as TranslatedMessageState),
                            pending: false,
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_PENDING: {
            const actualConversationState = state.conversations[action.meta];

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta]: {
                        ...defaultConversationWidgetStoreState,
                        ...actualConversationState,
                        conversation: {
                            ...defaultConversationState,
                            ...(actualConversationState && actualConversationState.conversation),
                            pending: true,
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_FULFILLED: {
            const actualConversationState = state.conversations[action.meta];
            if (!actualConversationState) {
                return state;
            }

            const resolvedConversation = resolveConversation(
                action.payload.item,
                conversationsRoot.workflowFormDefinitions.data
            );
            const resolvedConversationWithPrevOptimisticMessages = mergeOptimisticMessagesIntoConversation(
                actualConversationState.resolvedConversation,
                resolvedConversation
            );

            const updatedResolvedConversation = state.pendingConversationsUpdates[action.meta]
                ? applyConversationUpdate(
                      resolvedConversationWithPrevOptimisticMessages,
                      resolveConversationUpdate(
                          state.pendingConversationsUpdates[action.meta],
                          conversationsRoot.workflowFormDefinitions.data
                      ),
                      state.correlationRefs[action.meta]
                  )
                : resolvedConversationWithPrevOptimisticMessages;

            const allUnreadMessagesThatAreBeingMarkedAsRead = filterPendingAllMessagesStatusUpdates(
                updatedResolvedConversation,
                actualConversationState.allUnreadMessagesThatAreBeingMarkedAsRead
            );

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta]: {
                        ...actualConversationState,
                        allUnreadMessagesThatAreBeingMarkedAsRead,
                        conversation: {
                            data: action.payload.item,
                            fulfilled: true,
                            pending: false,
                            rejected: false,
                        },
                        markingAllMessagesAsRead: !!allUnreadMessagesThatAreBeingMarkedAsRead.length,
                        noOlderMessagesToDisplay:
                            action.payload.item.messages.length <
                            expectedNumberOfConversationMessagesWhenRetrievingConversation,
                        pagingAfterCursor: action.payload.metadata.messagesPaging.after,
                        pendingMessagesStatusUpdates: filterPendingMessageStatusUpdate(
                            updatedResolvedConversation,
                            actualConversationState.pendingMessagesStatusUpdates
                        ),
                        resolvedConversation: updatedResolvedConversation,
                    },
                },
                correlationRefs: omit(state.correlationRefs, [action.meta]),
                pendingConversationsUpdates: omit(state.pendingConversationsUpdates, [action.meta]),
            };
        }

        case ActionTypeKeys.CONVERSATION_REJECTED: {
            const actualConversationState = state.conversations[action.meta];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta]: {
                        ...actualConversationState,
                        conversation: {
                            data: undefined,
                            fulfilled: false,
                            pending: false,
                            rejected: true,
                        },
                        noOlderMessagesToDisplay: false,
                        pagingAfterCursor: undefined,
                        resolvedConversation: undefined,
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_UPDATE: {
            const actualPendingConversationUpdateState = state.pendingConversationsUpdates[action.meta];
            const actualCorrelationRefsState = state.correlationRefs[action.meta];
            let newPendingConversationUpdateState = {};
            let newCorrelationRefsState = {};

            if (!actualPendingConversationUpdateState) {
                newPendingConversationUpdateState = {
                    ...state.pendingConversationsUpdates,
                    [action.meta]: action.payload.data,
                };
            }

            if (actualPendingConversationUpdateState) {
                newPendingConversationUpdateState = {
                    ...state.pendingConversationsUpdates,
                    [action.meta]: mergeConversationUpdates(actualPendingConversationUpdateState, action.payload.data),
                };
            }

            if (!action.payload.metadata) {
                newCorrelationRefsState = state.correlationRefs;
            }

            if (action.payload.metadata && !actualCorrelationRefsState) {
                newCorrelationRefsState = {
                    ...state.correlationRefs,
                    [action.meta]: action.payload.metadata.correlationRefs,
                };
            }

            if (action.payload.metadata && actualCorrelationRefsState) {
                newCorrelationRefsState = {
                    ...state.correlationRefs,
                    [action.meta]: [...actualCorrelationRefsState, ...action.payload.metadata.correlationRefs],
                };
            }

            return {
                ...state,
                correlationRefs: newCorrelationRefsState,
                pendingConversationsUpdates: newPendingConversationUpdateState,
            };
        }

        case ActionTypeKeys.CONVERSATION_APPLY_PENDING: {
            const actualConversationState = state.conversations[action.meta];
            const actualPendingConversationUpdateState = state.pendingConversationsUpdates[action.meta];
            const actualCorrelationRefsState = state.correlationRefs[action.meta];

            if (
                actualConversationState &&
                actualPendingConversationUpdateState &&
                !actualConversationState.conversation.pending &&
                actualConversationState.resolvedConversation
            ) {
                const resolvedConversationUpdate = resolveConversationUpdate(
                    actualPendingConversationUpdateState,
                    conversationsRoot.workflowFormDefinitions.data
                );
                const updatedConversation = applyConversationUpdate(
                    actualConversationState.resolvedConversation,
                    resolvedConversationUpdate,
                    actualCorrelationRefsState
                );

                const allUnreadMessagesThatAreBeingMarkedAsRead = filterPendingAllMessagesStatusUpdates(
                    updatedConversation,
                    actualConversationState.allUnreadMessagesThatAreBeingMarkedAsRead
                );

                return {
                    ...state,
                    conversations: {
                        ...state.conversations,
                        [action.meta]: {
                            ...actualConversationState,
                            allUnreadMessagesThatAreBeingMarkedAsRead,
                            markingAllMessagesAsRead: !!allUnreadMessagesThatAreBeingMarkedAsRead.length,
                            pendingMessagesStatusUpdates: filterPendingMessageStatusUpdate(
                                updatedConversation,
                                actualConversationState.pendingMessagesStatusUpdates
                            ),
                            resolvedConversation: updatedConversation,
                        },
                    },
                    correlationRefs: omit(state.correlationRefs, [action.meta]),
                    pendingConversationsUpdates: omit(state.pendingConversationsUpdates, [action.meta]),
                };
            }

            return state;
        }

        case ActionTypeKeys.CONVERSATION_OPEN:
            return {
                ...state,
                shouldOpenConversation: true,
                shouldOpenConversationForVehicleId: action.meta,
            };

        case ActionTypeKeys.CONVERSATION_OPEN_SUCCEEDED:
        case ActionTypeKeys.CONVERSATION_OPEN_REJECTED:
            return {
                ...state,
                shouldOpenConversation: false,
                shouldOpenConversationForVehicleId: undefined,
            };

        case ActionTypeKeys.CONVERSATION_CLEAR:
            return {
                ...state,
                ...updateStateIfNeeded(action.meta, state, () => {
                    return undefined;
                }),
                pendingConversationsUpdates: action.meta
                    ? omit(state.pendingConversationsUpdates, [action.meta])
                    : defaultConversationState,
            };

        case ActionTypeKeys.CONVERSATIONMESSAGETEXT_CLEARSENDERROR: {
            return updateStateIfNeeded(action.meta, state, (current) => {
                if (!current) {
                    return current;
                }
                return {
                    ...current,
                    unacknowledgedFailedMessagesCorrelationIds: [],
                };
            });
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_SEND_PENDING: {
            return updateStateIfNeeded(action.meta.vehicleId, state, (actualConversationState) => {
                if (!actualConversationState?.resolvedConversation) {
                    return actualConversationState;
                }

                return {
                    ...actualConversationState,
                    resolvedConversation: pushOptimisticMessageToConversation(
                        actualConversationState.resolvedConversation,
                        resolveConversationMessage(
                            action.meta.optimisticMessage,
                            conversationsRoot.workflowFormDefinitions.data
                        )
                    ),
                };
            });
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_SEND_FULFILLED: {
            return updateStateIfNeeded(action.meta.vehicleId, state, (actualConversationState) => {
                if (!actualConversationState?.resolvedConversation) {
                    return actualConversationState;
                }

                return {
                    ...actualConversationState,
                    resolvedConversation: updateOptimisticConversationMessageStatus(
                        actualConversationState.resolvedConversation,
                        action.meta.optimisticMessage.correlationId,
                        OptimisticOutgoingConversationMessageStatus.Pending
                    ),
                };
            });
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_DISCARD: {
            return updateStateIfNeeded(action.meta.vehicleId, state, (actualConversationState) => {
                if (!actualConversationState?.resolvedConversation) {
                    return actualConversationState;
                }

                const { correlationId } = action.meta.message;

                return {
                    ...actualConversationState,
                    resolvedConversation: removeOptimisticConversationMessage(
                        actualConversationState.resolvedConversation,
                        action.meta.message.correlationId
                    ),
                    unacknowledgedFailedMessagesCorrelationIds:
                        actualConversationState.unacknowledgedFailedMessagesCorrelationIds.filter(
                            (failedMessageCorrelationId) => failedMessageCorrelationId !== correlationId
                        ),
                };
            });
        }

        case ActionTypeKeys.CONVERSATIONMESSAGE_SEND_REJECTED: {
            return updateStateIfNeeded(action.meta.vehicleId, state, (actualConversationState) => {
                if (!actualConversationState?.resolvedConversation) {
                    return actualConversationState;
                }
                const status =
                    action.payload instanceof ValidationFailedResponse
                        ? OptimisticOutgoingConversationMessageStatus.ValidationFailure
                        : OptimisticOutgoingConversationMessageStatus.GeneralFailure;

                return {
                    ...actualConversationState,
                    resolvedConversation: updateOptimisticConversationMessageStatus(
                        actualConversationState.resolvedConversation,
                        action.meta.optimisticMessage.correlationId,
                        status
                    ),
                    unacknowledgedFailedMessagesCorrelationIds: [
                        ...actualConversationState.unacknowledgedFailedMessagesCorrelationIds,
                        action.meta.optimisticMessage.correlationId,
                    ],
                };
            });
        }

        case ActionTypeKeys.CONVERSATIONMESSAGETEXT_CHANGEFOCUS:
            return updateStateIfNeeded(action.meta, state, (current) => ({
                ...defaultConversationWidgetStoreState,
                ...current,
                shouldFocusInput: action.payload,
            }));

        case ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_PENDING: {
            return updateStateIfNeeded(action.meta, state, (current) => {
                if (!current) {
                    return current;
                }
                return {
                    ...current,
                    markingAllMessagesAsRead: true,
                };
            });
        }

        case ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_REJECTED: {
            return updateStateIfNeeded(action.meta, state, (current) => {
                if (!current) {
                    return current;
                }
                return {
                    ...current,
                    allUnreadMessagesThatAreBeingMarkedAsRead: [],
                    markingAllMessagesAsRead: false,
                };
            });
        }

        case ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_FULFILLED: {
            const messagesToBeMarkedAsRead: NumericDictionary<PendingMessageStatusUpdate> = {};
            action.payload.messageIdsToBeMarkedAsRead.forEach((messageId) => {
                messagesToBeMarkedAsRead[messageId] = {
                    expectedStatus: IncomingMessageStatus.Read,
                };
            });

            return updateStateIfNeeded(action.meta, state, (current) => {
                if (!current) {
                    return current;
                }
                return {
                    ...current,
                    allUnreadMessagesThatAreBeingMarkedAsRead: action.payload.messageIdsToBeMarkedAsRead,
                    pendingMessagesStatusUpdates: {
                        ...current.pendingMessagesStatusUpdates,
                        ...messagesToBeMarkedAsRead,
                    },
                };
            });
        }

        case ActionTypeKeys.CONVERSATION_MARKMESSAGEASREAD_PENDING: {
            const actualConversationState = state.conversations[action.meta.vehicleId];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta.vehicleId]: {
                        ...actualConversationState,
                        pendingMessagesStatusUpdates: {
                            ...actualConversationState.pendingMessagesStatusUpdates,
                            [action.meta.messageId]: {
                                expectedStatus: IncomingMessageStatus.Read,
                            },
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_DELETEMESSAGE_PENDING: {
            const actualConversationState = state.conversations[action.meta.vehicleId];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta.vehicleId]: {
                        ...actualConversationState,
                        pendingMessagesStatusUpdates: {
                            ...actualConversationState.pendingMessagesStatusUpdates,
                            [action.meta.messageId]: {
                                expectedStatusDeleted: true,
                            },
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_MARKMESSAGEASREAD_REJECTED:
        case ActionTypeKeys.CONVERSATION_DELETEMESSAGE_REJECTED:
        case ActionTypeKeys.CONVERSATION_MARKMESSAGEASUNREAD_REJECTED:
        case ActionTypeKeys.CONVERSATION_UNDELETEMESSAGE_REJECTED: {
            const actualConversationState = state.conversations[action.meta.vehicleId];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta.vehicleId]: {
                        ...actualConversationState,
                        pendingMessagesStatusUpdates: omit(actualConversationState.pendingMessagesStatusUpdates, [
                            action.meta.messageId,
                        ]),
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATION_MARKMESSAGEASUNREAD_PENDING: {
            const actualConversationState = state.conversations[action.meta.vehicleId];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta.vehicleId]: {
                        ...actualConversationState,
                        pendingMessagesStatusUpdates: {
                            ...actualConversationState.pendingMessagesStatusUpdates,
                            [action.meta.messageId]: {
                                expectedStatus: IncomingMessageStatus.Received,
                            },
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONMESSAGES_PENDING: {
            const actualConversationState = state.conversations[action.meta];

            if (!actualConversationState?.resolvedConversation) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta]: {
                        ...actualConversationState,
                        loadingOlderMessages: true,
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONMESSAGES_FULFILLED: {
            const actualConversationState = state.conversations[action.meta];

            if (!actualConversationState?.resolvedConversation) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta]: {
                        ...actualConversationState,
                        failedToLoadOlderMessages: false,
                        loadingOlderMessages: false,
                        noOlderMessagesToDisplay:
                            action.payload.items.length <
                            numberOfConversationMessagesToRequestWhenRetrievingOlderMessages,
                        pagingAfterCursor: action.payload.metadata.paging.after,
                        resolvedConversation: {
                            ...actualConversationState.resolvedConversation,
                            messages: [
                                ...actualConversationState.resolvedConversation.messages,
                                ...action.payload.items.map((message) =>
                                    resolveConversationMessage(message, conversationsRoot.workflowFormDefinitions.data)
                                ),
                            ],
                        },
                    },
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONMESSAGES_REJECTED: {
            return updateStateIfNeeded(action.meta, state, (current) => {
                if (!current?.resolvedConversation) {
                    return current;
                }
                return {
                    ...current,
                    failedToLoadOlderMessages: true,
                    loadingOlderMessages: false,
                };
            });
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_FULFILLED:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_PENDING:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_REJECTED: {
            return {
                ...state,
                conversations: mapValues(state.conversations, (s) =>
                    updateMissingWorkflowFormDefinitions(s, conversationsRoot.workflowFormDefinitions.data)
                ),
            };
        }

        case ActionTypeKeys.CONVERSATION_UNDELETEMESSAGE_PENDING: {
            const actualConversationState = state.conversations[action.meta.vehicleId];

            if (!actualConversationState) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.meta.vehicleId]: {
                        ...actualConversationState,
                        pendingMessagesStatusUpdates: {
                            ...actualConversationState.pendingMessagesStatusUpdates,
                            [action.meta.messageId]: {
                                expectedStatusDeleted: false,
                            },
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
};
