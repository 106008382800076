import type { VehicleStatus } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    VEHICLESTATUS = 'VEHICLESTATUS',
    VEHICLESTATUS_FOR_PERIOD = 'VEHICLESTATUS_FOR_PERIOD',
}

export enum ActionTypeKeys {
    VEHICLESTATUS_FOR_PERIOD_FULFILLED = 'VEHICLESTATUS_FOR_PERIOD_FULFILLED',
    VEHICLESTATUS_FOR_PERIOD_PENDING = 'VEHICLESTATUS_FOR_PERIOD_PENDING',
    VEHICLESTATUS_FOR_PERIOD_REJECTED = 'VEHICLESTATUS_FOR_PERIOD_REJECTED',
    VEHICLESTATUS_FULFILLED = 'VEHICLESTATUS_FULFILLED',
    VEHICLESTATUS_PENDING = 'VEHICLESTATUS_PENDING',
    VEHICLESTATUS_REJECTED = 'VEHICLESTATUS_REJECTED',
    VEHICLESTATUS_UPDATE = 'VEHICLESTATUS_UPDATE',
}

export interface VehicleStatusPayload {
    readonly items: VehicleStatus[];
}

export type ActionTypes =
    | Action<ActionTypeKeys.VEHICLESTATUS_UPDATE, VehicleStatus>
    | Fulfilled<ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_FULFILLED, VehicleStatusPayload>
    | Fulfilled<ActionTypeKeys.VEHICLESTATUS_FULFILLED, VehicleStatusPayload>
    | Pending<ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_PENDING>
    | Pending<ActionTypeKeys.VEHICLESTATUS_PENDING>
    | Rejected<ActionTypeKeys.VEHICLESTATUS_FOR_PERIOD_REJECTED>
    | Rejected<ActionTypeKeys.VEHICLESTATUS_REJECTED>;
