import { createAction } from 'redux-actions';

import type { PositionGroupsMeta } from '~/data/monitoring';
import type { Position, PositionGroup } from '~/services/ApiClient';
import { retryableRequest } from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrievePositionGroups = (
    retrieveFunc: (startDate: Date, stopDate: Date, includePositions: boolean) => Promise<PositionGroup[]>,
    startDate: Date,
    stopDate: Date,
    includePositions: boolean
) => {
    return retryableRequest(() => retrieveFunc(startDate, stopDate, includePositions));
};

export const retrievePositionGroupsAction = createAction<
    Promise<PositionGroup[]>,
    PositionGroupsMeta,
    (startDate: Date, stopDate: Date, includePositions: boolean) => Promise<PositionGroup[]>,
    Date,
    Date,
    boolean
>(ActionTypePrefixes.POSITIONGROUPS, retrievePositionGroups, (_, startDate, stopDate, includePositions) => ({
    includePositions,
    startDate,
    stopDate,
}));

export const highlightPositionGroupAction = createAction(
    ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITIONGROUP,
    (positionGroupId: number | undefined) => positionGroupId
);

export const highlightPositionAction = createAction(
    ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITION,
    (position: Position | undefined) => position
);

export const scrollToPositionGroupAction = createAction(ActionTypeKeys.HISTORYMAP_SCROLLTOPOSITIONGROUP);

export const resetScrollToPositionGroupAction = createAction(ActionTypeKeys.HISTORYMAP_RESETSCROLLTOPOSITIONGROUP);
