export const ADMINVEHICLE_USERPREFERENCES_KEY = 'vehicleadmin-state';

export const ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY = 'vehicleadmin-assetselector-state';

export interface AdminVehicleUserPreferences {
    leftPaneIsOpen: boolean;
}

export const defaultAdminVehicleUserPreferences: AdminVehicleUserPreferences = {
    leftPaneIsOpen: true,
};

export const requiredAdminVehicleUserPreferences = {
    defaultState: defaultAdminVehicleUserPreferences,
    key: ADMINVEHICLE_USERPREFERENCES_KEY,
};
