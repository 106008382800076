import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TableGroupRowContentClassKey = 'columnTitle';

export const TableGroupRowContentStyleRules: StyleRulesCallback<Theme, {}, TableGroupRowContentClassKey> = (
    theme: Theme
) =>
    createStyles({
        columnTitle: {
            '& strong': {
                marginRight: theme.spacing(0.5),
            },
            alignItems: 'center',

            display: 'inline-flex',
        },
    });
