import type { Address, DriverActivityType, DriverName, DriverSubActivityType, NamedVehicleReference } from '~/common';
import type { ResolvedDriverEvent } from '~/services/ApiClient/models';

export enum DriverActivitiesReportPerspective {
    Driver = 'driver',
    Vehicle = 'vehicle',
}

export interface DriverActivitiesReportChartProps {
    perspective: DriverActivitiesReportPerspective;
    resolvedDriverEvents: ResolvedDriverEvent[];
    selectHighlightedRow?: (segmentId?: number) => void;
    selectRow?: (segmentId: number) => void;
}

export interface GraphSeries {
    fillColor?: string;
    meta: {
        activityType: DriverActivityType;
        address: Address | undefined;
        distance: number | undefined;
        driver: DriverName | undefined;
        driverEventId: number;
        duration: string;
        startDateTime: Date;
        stopDateTime: Date;
        subActivity: DriverSubActivityType | undefined;
        vehicle: NamedVehicleReference | undefined;
    };
    x: string;
    y: number[];
}
