import { ListItem } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ElapsedTime } from '~/components/ElapsedTime';
import { ClockIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import type { ResolvedReeferStatus } from '~/services/ApiClient';

import { FuelInformation } from './components/FuelInformation';
import { HeaderInfo } from './components/HeaderInfo';
import type { ReeferSectionClassKey } from './styles';

export interface ReeferSectionProps {
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    reefer?: ResolvedReeferStatus;
    toggleCollapsed: () => void;
}

export interface ReeferSectionInnerProps
    extends InjectedTranslationProps,
        ReeferSectionProps,
        WithStyles<ReeferSectionClassKey> {}

export const ReeferSectionComponent: React.FC<ReeferSectionInnerProps> = ({
    classes,
    dragHandleElement,
    isCollapsed,
    reefer,
    t,
    toggleCollapsed,
}) => {
    const renderContent = React.useCallback(() => {
        if (!reefer) {
            return <SectionContentMessage dataId="no-reefer-messages">{t('no-reefer')}</SectionContentMessage>;
        }

        return (
            <>
                <FuelInformation reefer={reefer} />
                <ListItem className={classes.reeferTimeElement}>
                    <ElapsedTime icon={ClockIcon} since={reefer.dateTime} />
                </ListItem>
            </>
        );
    }, [t, reefer, classes]);

    const headerInfo = React.useMemo(() => {
        return reefer && <HeaderInfo reefer={reefer} />;
    }, [reefer]);

    return (
        <ReorderableSection
            dataId="reefer"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={headerInfo}
            title={t('reefer')}
            toggleCollapsed={toggleCollapsed}
        >
            {renderContent()}
        </ReorderableSection>
    );
};
