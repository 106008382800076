import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DoorClassKeys =
    | 'captionIcon'
    | 'delimiter'
    | 'doorElement'
    | 'doorNumber'
    | 'doorStatus'
    | 'doorStatusTimeDetails'
    | 'doorTimeElement'
    | 'secondaryDoorStatusText';

export const DoorStyleRules: StyleRulesCallback<Theme, {}, DoorClassKeys> = (theme: Theme) =>
    createStyles({
        captionIcon: {
            fontSize: theme.typography.pxToRem(14),
            marginRight: theme.spacing(0.5),
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        doorElement: {
            paddingBottom: 0,
        },
        doorNumber: {},
        doorStatus: {
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'right',
        },
        doorStatusTimeDetails: {
            alignItems: 'center',
            display: 'flex',
            marginBottom: theme.spacing(),
        },
        doorTimeElement: {
            paddingTop: 0,
        },
        secondaryDoorStatusText: {
            fontWeight: theme.typography.fontWeightBold,
        },
    });
