import type { Preferences, PreferencesScope, QueryPreferencesResponse } from '~/services/ApiClient';
import type { ActionMeta, Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    USERPREFERENCES_DELETE = 'USERPREFERENCES_DELETE',
    USERPREFERENCES_DELETE_ALL = 'USERPREFERENCES_DELETE_ALL',
    USERPREFERENCES_LOAD = 'USERPREFERENCES_LOAD',
    USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT = 'USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT',
}

export enum ActionTypeKeys {
    USERPREFERENCES_DELETE_ALL_FULFILLED = 'USERPREFERENCES_DELETE_ALL_FULFILLED',
    USERPREFERENCES_DELETE_ALL_PENDING = 'USERPREFERENCES_DELETE_ALL_PENDING',
    USERPREFERENCES_DELETE_ALL_REJECTED = 'USERPREFERENCES_DELETE_ALL_REJECTED',
    USERPREFERENCES_DELETE_FULFILLED = 'USERPREFERENCES_DELETE_FULFILLED',
    USERPREFERENCES_DELETE_PENDING = 'USERPREFERENCES_DELETE_PENDING',
    USERPREFERENCES_DELETE_REJECTED = 'USERPREFERENCES_DELETE_REJECTED',
    USERPREFERENCES_INITIALIZESTATE = 'USERPREFERENCES_INITIALIZESTATE',
    USERPREFERENCES_LOAD_FULFILLED = 'USERPREFERENCES_LOAD_FULFILLED',
    USERPREFERENCES_LOAD_PENDING = 'USERPREFERENCES_LOAD_PENDING',
    USERPREFERENCES_LOAD_REJECTED = 'USERPREFERENCES_LOAD_REJECTED',
    USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_FULFILLED = 'USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_FULFILLED',
    USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_PENDING = 'USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_PENDING',
    USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_REJECTED = 'USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_REJECTED',
    USERPREFERENCES_UPDATE = 'USERPREFERENCES_UPDATE',
}

export type UserPreferencesPayload = Readonly<QueryPreferencesResponse> | undefined;

export type InitializeUserPreferencesStatePayload = Readonly<{
    defaultUserPreferences: Preferences;
    processResponse?: (preferences: object) => object;
}>;

export type ActionTypes =
    | ActionMeta<ActionTypeKeys.USERPREFERENCES_INITIALIZESTATE, InitializeUserPreferencesStatePayload, string>
    | ActionMeta<ActionTypeKeys.USERPREFERENCES_UPDATE, Preferences, string>
    | Fulfilled<ActionTypeKeys.USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_FULFILLED, undefined>
    | FulfilledMeta<ActionTypeKeys.USERPREFERENCES_DELETE_ALL_FULFILLED, undefined, PreferencesScope>
    | FulfilledMeta<ActionTypeKeys.USERPREFERENCES_DELETE_FULFILLED, undefined, string>
    | FulfilledMeta<ActionTypeKeys.USERPREFERENCES_LOAD_FULFILLED, UserPreferencesPayload, string[]>
    | Pending<ActionTypeKeys.USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_PENDING>
    | PendingMeta<ActionTypeKeys.USERPREFERENCES_DELETE_ALL_PENDING, PreferencesScope>
    | PendingMeta<ActionTypeKeys.USERPREFERENCES_DELETE_PENDING, string>
    | PendingMeta<ActionTypeKeys.USERPREFERENCES_LOAD_PENDING, string[]>
    | Rejected<ActionTypeKeys.USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT_REJECTED, undefined>
    | RejectedMeta<ActionTypeKeys.USERPREFERENCES_DELETE_ALL_REJECTED, undefined, PreferencesScope>
    | RejectedMeta<ActionTypeKeys.USERPREFERENCES_DELETE_REJECTED, undefined, string>
    | RejectedMeta<ActionTypeKeys.USERPREFERENCES_LOAD_REJECTED, undefined, string[]>;
