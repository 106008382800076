import {
    ApiClient,
    Preferences,
    PreferencesScope,
    QueryPreferencesResponse,
    createApiModel,
} from '~/services/ApiClient';

export interface PredefinedUserPreferences {
    [key: string]: {
        get: () => Promise<QueryPreferencesResponse>;
    };
}

export enum PredefinedUserPreferencesKeys {
    DISPLAY = 'display',
}

export const predefinedUserPreferences: PredefinedUserPreferences = {
    [PredefinedUserPreferencesKeys.DISPLAY]: {
        get: async (): Promise<QueryPreferencesResponse> => {
            const preferences = await ApiClient.getDisplayUserPreferences();
            return createApiModel(QueryPreferencesResponse, {
                items: [
                    createApiModel(Preferences, {
                        key: PredefinedUserPreferencesKeys.DISPLAY,
                        scope: PreferencesScope.User,
                        value: preferences.item,
                    }),
                ],
            });
        },
    },
};
