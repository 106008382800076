import { CircularProgress, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangelogEntityType, ChangelogSubpage } from '~/common';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import { getVehicleTypeChangelogAttributeDefinitions } from '~/scenes/VehicleTypesAdministration/getVehicleTypeChangelogAttributeDefinitions';
import type { ResolvedVehicleTypeChangelogChanges } from '~/services/ApiClient';

import type { VehicleTypeChangelogsRedux } from './redux';
import { useStyles } from './styles';

export interface VehicleTypesAdministrationChangelogsSubpageProps {
    vehicleTypeId: number;
}

export interface VehicleTypesAdministrationChangelogsSubpageInnerProps
    extends VehicleTypesAdministrationChangelogsSubpageProps,
        VehicleTypeChangelogsRedux,
        InjectedDisplayPreferencesProps {}

const ChangelogSubpageComponent = ChangelogSubpage<ResolvedVehicleTypeChangelogChanges>();

export const VehicleTypesAdministrationChangelogsSubpageComponent: FC<
    VehicleTypesAdministrationChangelogsSubpageInnerProps
> = (props) => {
    const {
        changelogsEntries,
        displayPreferences,
        getVehicleTypeChangelogs,
        loading,
        previousVehicleTypeId,
        rejected,
        vehicleTypeId,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        getVehicleTypeChangelogs(vehicleTypeId);
    }, [getVehicleTypeChangelogs, vehicleTypeId]);

    const header = <SceneDetailsSubpageHeader title={t('changelog-vehicle-type-subpage-title', { vehicleTypeId })} />;

    if (rejected) {
        return (
            <>
                {header}
                <Typography className={classes.messageText} data-id="loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </>
        );
    }

    if (loading || previousVehicleTypeId !== vehicleTypeId) {
        return (
            <>
                {header}
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            </>
        );
    }

    return (
        <ChangelogSubpageComponent
            attributeDefinitions={getVehicleTypeChangelogAttributeDefinitions(t, displayPreferences.unitSystem)}
            changelogs={changelogsEntries}
            changelogSubpageTitle={t('changelog-vehicle-type-subpage-title', {
                vehicleTypeId,
            })}
            entityType={ChangelogEntityType.VEHICLE_TYPE_ADMIN}
        />
    );
};
