import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { FilterDefaultCellProps } from './component';

export type FilterDefaultCellClassKey = 'input' | 'root';

export const useStyles = makeStyles<Theme, FilterDefaultCellProps, FilterDefaultCellClassKey>(() => ({
    input: (props) => ({
        textAlign: props.align,
    }),
    root: {},
}));
