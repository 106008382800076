import type { NumericDictionary } from '~/libs/utility';
import type {
    Classification,
    DeviceType,
    Driver,
    ResolvedAsset,
    Trailer,
    Vehicle,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { driverResolver, trailerResolver, vehicleResolver } from '~/services/ModelResolvers';

export const buildSelectedAsset = (
    selectedAssetId: number | undefined,
    selectedAssetType: AssetType | undefined,
    vehiclesHash: NumericDictionary<Vehicle>,
    trailersHash: NumericDictionary<Trailer>,
    driversHash: NumericDictionary<Driver>,
    deviceTypes: NumericDictionary<DeviceType>,
    categories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>,
    trailerManufacturers: NumericDictionary<Classification>,
    reeferManufacturers: NumericDictionary<Classification>
): ResolvedAsset | undefined => {
    if (!selectedAssetId || !selectedAssetType) {
        return undefined;
    }

    switch (selectedAssetType) {
        case AssetType.Vehicle: {
            const vehicle = vehiclesHash[selectedAssetId];
            if (!vehicle) {
                return undefined;
            }
            return vehicleResolver({ categories, deviceTypes, vehicle, vehicleTypes });
        }
        case AssetType.Trailer: {
            const trailer = trailersHash[selectedAssetId];
            if (!trailer) {
                return undefined;
            }
            return trailerResolver(trailer, trailerManufacturers, reeferManufacturers);
        }
        case AssetType.Driver: {
            const driver = driversHash[selectedAssetId];
            if (!driver) {
                return undefined;
            }
            return driverResolver(driver);
        }
        default:
            throw Error(`Unknown asset type: ${selectedAssetType}`);
    }
};
