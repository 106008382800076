import type { SingleTFunction } from '~/components/LanguageSelector';

type FormatVoltageArgs = {
    precision?: number;
    t: SingleTFunction;
    value: number;
};

export const formatVoltage = ({ precision = 2, t, value }: FormatVoltageArgs): string => {
    const formattedValue = `${value.toFixed(precision)} ${t('unit-voltage')}`;
    return formattedValue;
};
