import type { Dispatch } from 'redux';

import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import { showHistoryMapAction } from '~/components/SceneMap/data';
import type { HistoryStoreState } from '~/data/monitoring';
import { reportError } from '~/reporting';
import type { PositionGroup } from '~/services/ApiClient';

import { retrievePositionGroupsAction } from './data';

export interface HistoryStateProps {
    endDate: Date;
    items: PositionGroup[];
    loading: boolean;
    rejected: boolean;
    startDate: Date;
}

export interface HistoryDispatchProps {
    getPositionGroups: (
        retrieveFunc: (startDate: Date, stopDate: Date, includePositions: boolean) => Promise<PositionGroup[]>,
        startDate: Date,
        stopDate: Date,
        includePositions: boolean
    ) => void;
    showHistoryMap: (showHistoryMap: boolean) => void;
}

export interface HistoryRedux extends HistoryStateProps, HistoryDispatchProps {}

export const mapStateToProps = (state: HistoryStoreState, dateRangePicker: DateTimeRange): HistoryStateProps => ({
    endDate: dateRangePicker.endDate,
    items: state.positionGroups.data,
    loading: state.positionGroups.pending,
    rejected: state.positionGroups.rejected,
    startDate: dateRangePicker.startDate,
});

export const mapDispatchProps = (dispatch: Dispatch): HistoryDispatchProps => ({
    getPositionGroups: (
        retrieveFunc: (startDate: Date, stopDate: Date, includePositions: boolean) => Promise<PositionGroup[]>,
        startDate: Date,
        stopDate: Date,
        includePositions: boolean
    ) => {
        dispatch(retrievePositionGroupsAction(retrieveFunc, startDate, stopDate, includePositions)).catch(reportError);
    },
    showHistoryMap: (showHistoryMap: boolean) => {
        dispatch(showHistoryMapAction(showHistoryMap));
    },
});
