import type { Dispatch } from 'redux';

import {
    createAdminVehicleTypeAction,
    createAdminVehicleTypeResetAction,
    updateAdminVehicleTypeAction,
    updateAdminVehicleTypeResetAction,
} from '~/data/vehicletypeadministration';
import type { NumericDictionary } from '~/libs/utility';
import { reportError } from '~/reporting';
import type { Classification } from '~/services/ApiClient';

import type { AdminVehicleTypesStoreState } from '../../reducers';

import type {
    VehicleTypeEditorProps,
    VehicleTypeEditorReduxDispatchProps,
    VehicleTypeEditorReduxStateProps,
} from './component';

export const mapStateToProps = (
    vehicleTypeCategories: NumericDictionary<Classification>,
    axles: NumericDictionary<Classification>,
    emissionClasses: NumericDictionary<Classification>,
    {
        adminVehicleTypes: resolvedVehicleTypes,
        createVehicleTypeServerResultStatus,
        updateVehicleTypeServerResultStatus,
    }: AdminVehicleTypesStoreState,
    { vehicleType }: VehicleTypeEditorProps
): VehicleTypeEditorReduxStateProps => ({
    axles,
    createVehicleTypeServerResultStatus,
    emissionClasses,
    uniqueVehicleTypeNames: resolvedVehicleTypes.data.array
        .map((resolvedVehicleType) => resolvedVehicleType.name)
        .filter((name) => vehicleType?.name !== name),
    updateVehicleTypeServerResultStatus,
    vehicleTypeCategories,
});

export const mapDispatchToProps = (dispatch: Dispatch): VehicleTypeEditorReduxDispatchProps => ({
    createAdminVehicleType: (vehicleType) => {
        dispatch(createAdminVehicleTypeAction(vehicleType)).catch(reportError);
    },
    resetAdminVehicleTypeCreate: () => {
        dispatch(createAdminVehicleTypeResetAction());
    },
    resetAdminVehicleTypeUpdate: () => {
        dispatch(updateAdminVehicleTypeResetAction());
    },
    updateAdminVehicleType: (vehicleType) => {
        dispatch(updateAdminVehicleTypeAction(vehicleType)).catch(reportError);
    },
});
