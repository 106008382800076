import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SectionListItemClassKey = 'icon' | 'label' | 'root' | 'typography' | 'value';

export const SectionListItemClassStyleRules: StyleRulesCallback<Theme, {}, SectionListItemClassKey> = (theme) =>
    createStyles({
        icon: {
            display: 'inline-flex',
        },
        label: {
            flexGrow: 1,
            marginRight: theme.spacing(),
            paddingBottom: theme.spacing(0.75),
            paddingTop: theme.spacing(0.75),
        },
        /**
         * Remove padding top/bottom from list item and
         * move it to label & value to be able to include
         * a value element that has more than 14px height
         * without increasing the list item height.
         */
        root: {
            overflow: 'hidden',
            padding: `0 ${theme.spacing()}`,
        },
        typography: {
            lineHeight: '14px',
        },
        value: {
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            textAlign: 'right',
        },
    });
