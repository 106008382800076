import type { FC } from 'react';

import { ConfirmationDialog } from '~/components/Dialogs';

import type { DialogProps } from './models';

const Dialog: FC<DialogProps> = (props) => {
    const { children, confirmationText, dataId, isOpen, onCancel, onClose, onConfirm, title } = props;
    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }

        onClose();
    };

    return (
        <ConfirmationDialog
            confirmationActionText={confirmationText}
            dataId={dataId}
            onCancel={handleOnCancel}
            onConfirm={onConfirm}
            open={isOpen}
            title={title}
        >
            {children}
        </ConfirmationDialog>
    );
};

Dialog.displayName = 'AdminVehicleDialog';
export default Dialog;
