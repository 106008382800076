import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

import { kilogramsToPounds } from './kilogramsToPounds';

interface ConvertWeightArgs {
    precision?: number;
    unitSystem: UnitSystem;
    value: number;
}

const convertWeight = (args: ConvertWeightArgs) => {
    const { precision = 0, unitSystem, value } = args;
    switch (unitSystem) {
        case UNIT_SYSTEMS.IMPERIAL:
            return Number(kilogramsToPounds(value).toFixed(precision));
        case UNIT_SYSTEMS.METRIC:
            return Number(value.toFixed(precision));
        default:
            throw new Error('Unknown unit system.');
    }
};

export { convertWeight };
export type { ConvertWeightArgs };
