import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ActivitiesBarClassKey =
    | 'activitySurface'
    | 'activitySurfaceLeftCornersRounded'
    | 'activitySurfaceRightCornersRounded'
    | 'loadingView'
    | 'root';

export const ActivitiesBarStyleRules: StyleRulesCallback<Theme, {}, ActivitiesBarClassKey> = (theme: Theme) =>
    createStyles({
        activitySurface: {
            bottom: 0,
            position: 'absolute',
        },
        activitySurfaceLeftCornersRounded: {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
        },
        activitySurfaceRightCornersRounded: {
            borderBottomRightRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
        loadingView: {
            borderRadius: theme.shape.borderRadius,
            height: 12,
            marginBottom: theme.spacing(0.25),
        },
        root: {
            background: theme.palette.divider,
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            height: 12,
            marginBottom: theme.spacing(0.25),
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
        },
    });
