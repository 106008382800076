import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InlineBadgeClassKey } from './styles';

export interface InlineBadgeProps {
    dataId?: string;
    value: number;
}

export interface InlineBadgeInnerProps extends InlineBadgeProps, WithStyles<InlineBadgeClassKey> {}

export const InlineBadgeComponent: React.FC<InlineBadgeInnerProps> = ({ classes, dataId, value }) => {
    return (
        <div className={classes.root} data-id={dataId}>
            {value}
        </div>
    );
};
