import { Avatar, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CoDriverIcon, DriverIcon, TruckIcon } from '~/components/Icons';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { DriverName } from '~/services/ApiClient';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

import type { DisplayVehicleInfoArgs, TrailerInfoBoxInnerProps } from './models';

const TrailerInfoBox: FC<TrailerInfoBoxInnerProps> = (props) => {
    const { classes, driverDisplayNameFormat, entry, vehicleDisplayFormat } = props;

    const { t } = useTranslation();
    const { vehicleConnection } = entry;

    const driverIcon = <DriverIcon className={classes.icon} />;
    const coDriverIcon = <CoDriverIcon className={classes.icon} />;
    const truckIcon = <TruckIcon className={classes.icon} />;
    const driver = vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.driver;
    const coDriver = vehicleConnection?.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver;
    const vehicle = vehicleConnection?.vehicleReference;
    const vehicleAuthorized = !!vehicleConnection?.vehicle;

    const displayDriverInfo = (d: DriverName, dataId: string, icon: JSX.Element) => {
        const formattedName = formatDriverName(d, driverDisplayNameFormat);

        return (
            <div className={classes.infoBoxWrapper}>
                <Avatar className={classes.driverAvatar}>{icon}</Avatar>

                <div className={classes.infoText}>
                    <Typography data-id={dataId} variant="body2">
                        <TitledTruncatedText>{formattedName}</TitledTruncatedText>
                    </Typography>
                </div>
            </div>
        );
    };

    const displayVehicleInfo = (args: DisplayVehicleInfoArgs) => {
        const { authorized, dataId, icon, vehicleLabel } = args;
        const formattedVehicle = formatVehicleName(vehicleLabel, vehicleDisplayFormat);

        const vehicleInfoClasses = classNames(classes.infoBoxWrapper, {
            [classes.notAuthorized]: !authorized,
        });
        return (
            <div className={vehicleInfoClasses}>
                <Avatar className={classes.vehicleAvatar}>{icon}</Avatar>
                <div className={classes.infoText}>
                    <Typography data-id={dataId} variant="body2">
                        <TitledTruncatedText>{formattedVehicle}</TitledTruncatedText>
                    </Typography>
                </div>
            </div>
        );
    };

    const displayNoDriver = () => {
        const translatedTitle = t('not-logged-in');
        return (
            <div>
                <div className={classNames(classes.notLoggedIn, classes.infoBoxWrapper)}>
                    <Avatar className={classes.driverAvatar}>{driverIcon}</Avatar>

                    <div className={classes.infoText}>
                        <Typography data-id="driver" variant="body2">
                            <TitledTruncatedText>{translatedTitle}</TitledTruncatedText>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    const displayNoVehicle = () => {
        const notConnected = t('not-connected');
        return (
            <div>
                <div className={classNames(classes.notLoggedIn, classes.infoBoxWrapper)}>
                    <Avatar className={classes.vehicleAvatar}>{truckIcon}</Avatar>

                    <div className={classes.infoText}>
                        <Typography data-id="vehicle" variant="body2">
                            <TitledTruncatedText>{notConnected}</TitledTruncatedText>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div data-id={`info-box:${entry.trailer.id}`}>
            {driver ? displayDriverInfo(driver, 'driver', driverIcon) : displayNoDriver()}
            {coDriver ? displayDriverInfo(coDriver, 'co-driver', coDriverIcon) : null}
            {vehicle
                ? displayVehicleInfo({
                      authorized: vehicleAuthorized,
                      dataId: 'vehicle',
                      icon: truckIcon,
                      vehicleLabel: vehicle,
                  })
                : displayNoVehicle()}
        </div>
    );
};

TrailerInfoBox.displayName = 'TrailerInfoBox';
export { TrailerInfoBox };
