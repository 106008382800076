import Refresh from '@mui/icons-material/Refresh';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { AddPlusToIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps } from '~/components/SceneList';
import { SceneListViewHeaderFactory } from '~/components/SceneList';
import { TitledIconButton } from '~/components/TitledIconButton';
import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type { ResolvedAdminVehicleRead } from '~/services/ApiClient';
import { EditorState } from '~/services/ApiClient';

import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import {
    VEHICLE_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleAdministrationListUserPreferences,
} from '../../preferences';

import type { ReduxProps } from '.';

const HeaderComponent = SceneListViewHeaderFactory<ResolvedAdminVehicleRead>(
    VEHICLE_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleAdministrationListUserPreferences,
    filterDataSourceMemoized,
    'vehicle-administration'
);

export interface VehicleAdministrationListViewHeaderProps
    extends Omit<
        SceneListViewHeaderProps<ResolvedAdminVehicleRead>,
        'columns' | 'excelSheetTitle' | 'moduleBarActions'
    > {
    canCreate: boolean;
    contentLoading: boolean;
    onRefreshData: () => void;
    openCreateVehicleEditorDialog: (editorState: EditorState) => void;
}
export interface VehicleAdministrationListViewHeaderInnerProps
    extends VehicleAdministrationListViewHeaderProps,
        ReduxProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const VehicleAdministrationListViewHeaderComponent: FC<VehicleAdministrationListViewHeaderInnerProps> = (
    props
) => {
    const {
        billingGroups,
        billingStatuses,
        canCreate,
        contentLoading,
        dataSource,
        deviceTypes,
        displayPreferences,
        fuelTankTypes,
        i18n,
        imageCapturingTypes,
        inhibitorServiceTypes,
        navigationTypes,
        networks,
        onRefreshData,
        openCreateVehicleEditorDialog,
        securables,
        t,
        tachoGenerations,
        tachographs,
        tachoProtocols,
        tellTaleServiceTypes,
        vehicleCategories,
        vehicleTypes,
        workflowTypes,
        ...restProps
    } = props;

    const staticDataState = useSelector((store: StoreState) => staticDataStoreStateSelector(store));
    const depots = isUndefined(staticDataState) ? [] : staticDataState.depots.data;
    const columns = getColumnsMemoized({
        billingGroups,
        billingStatuses,
        depots,
        deviceTypes,
        fuelTankTypes,
        imageCapturingTypes,
        inhibitorServiceTypes,
        navigationTypes,
        networks,
        servicesSecurables: securables.services,
        t,
        tachoGenerations,
        tachographs,
        tachoProtocols,
        tellTaleServiceTypes,
        unitSystem: displayPreferences.unitSystem,
        vehicleCategories,
        vehicleTypes,
        workflowTypes,
    });

    const addNewVehicleButton = useMemo(() => {
        return (
            canCreate && (
                <TitledIconButton
                    color="inherit"
                    data-id="add-new-vehicle"
                    disabled={restProps.gridActionsDisabled}
                    onClick={() => openCreateVehicleEditorDialog(EditorState.NEW)}
                    placement="bottom-end"
                    title={t('add-new-vehicle')}
                >
                    <AddPlusToIcon>
                        <TruckIcon />
                    </AddPlusToIcon>
                </TitledIconButton>
            )
        );
    }, [canCreate, openCreateVehicleEditorDialog, restProps.gridActionsDisabled, t]);

    const refreshDataButton = useMemo(() => {
        return (
            <TitledIconButton
                color="inherit"
                data-id="refresh-data-button"
                disabled={restProps.gridActionsDisabled || contentLoading}
                onClick={onRefreshData}
                placement="bottom-end"
                title={t('refresh')}
            >
                <Refresh />
            </TitledIconButton>
        );
    }, [contentLoading, onRefreshData, restProps.gridActionsDisabled, t]);

    const moduleBarActions = useMemo(
        () => (
            <>
                {addNewVehicleButton}
                {refreshDataButton}
            </>
        ),
        [addNewVehicleButton, refreshDataButton]
    );

    return (
        <HeaderComponent
            {...restProps}
            columns={columns}
            dataSource={dataSource}
            excelSheetTitle={t('vehicle-admin-module')}
            moduleBarActions={moduleBarActions}
        />
    );
};
