import type { DriverActivityStoreState } from '~/data/monitoring';
import type { NumericDictionary } from '~/libs/utility';
import { keyBy, omit } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { DriverActivity } from '~/services/ApiClient';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';
import { applyDriverActivitiesUpdate } from './reducers.applyDriverActivitiesUpdate';
import { mergeDriverActivitiesUpdates } from './reducers.mergeDriverActivitiesUpdates';

const defaultStoreState: DriverActivityStoreState = {
    driversActivities: {},
    pendingDriverActivitiesUpdates: {},
};

export const defaultDriverActivitiesState: RetrievableData<NumericDictionary<DriverActivity> | undefined> = {
    data: undefined,
    fulfilled: false,
    pending: false,
    rejected: false,
};

export const driverActivityStoreReducer = (
    state: DriverActivityStoreState = defaultStoreState,
    action: ActionTypes
): DriverActivityStoreState => {
    switch (action.type) {
        case ActionTypeKeys.DRIVERACTIVITIES_PENDING: {
            const actualDriverActivitiesState = state.driversActivities[action.meta];

            return {
                ...state,
                driversActivities: {
                    ...state.driversActivities,
                    [action.meta]: {
                        ...defaultDriverActivitiesState,
                        ...actualDriverActivitiesState,
                        pending: true,
                    },
                },
            };
        }

        case ActionTypeKeys.DRIVERACTIVITIES_FULFILLED: {
            const actualDriverActivitiesState = state.driversActivities[action.meta];
            const actualPendingDriverActivitiesUpdates = state.pendingDriverActivitiesUpdates[action.meta];

            if (!actualDriverActivitiesState) {
                return state;
            }

            const payloadItemsHash = keyBy(action.payload.items, 'id');
            const updatedDriverActivities = actualPendingDriverActivitiesUpdates
                ? applyDriverActivitiesUpdate(payloadItemsHash, actualPendingDriverActivitiesUpdates)
                : payloadItemsHash;

            return {
                ...state,
                driversActivities: {
                    ...state.driversActivities,
                    [action.meta]: {
                        ...defaultDriverActivitiesState,
                        data: updatedDriverActivities,
                        fulfilled: true,
                    },
                },
                pendingDriverActivitiesUpdates: omit(state.pendingDriverActivitiesUpdates, [action.meta]),
            };
        }

        case ActionTypeKeys.DRIVERACTIVITIES_REJECTED: {
            const actualDriverActivitiesState = state.driversActivities[action.meta];

            if (!actualDriverActivitiesState) {
                return state;
            }

            return {
                ...state,
                driversActivities: {
                    ...state.driversActivities,
                    [action.meta]: {
                        ...defaultDriverActivitiesState,
                        rejected: true,
                    },
                },
            };
        }

        case ActionTypeKeys.DRIVERACTIVITIES_CLEAR:
            return {
                ...state,
                driversActivities: omit(state.driversActivities, [action.meta]),
                pendingDriverActivitiesUpdates: omit(state.pendingDriverActivitiesUpdates, [action.meta]),
            };

        case ActionTypeKeys.DRIVERACTIVITIES_UPDATE: {
            const actualPendingDriverActivitiesUpdates = state.pendingDriverActivitiesUpdates[action.meta];
            const updatedActualPendingDriverActivitiesUpdates = actualPendingDriverActivitiesUpdates
                ? mergeDriverActivitiesUpdates(actualPendingDriverActivitiesUpdates, action.payload.data)
                : action.payload.data;

            return {
                ...state,
                pendingDriverActivitiesUpdates: {
                    ...state.pendingDriverActivitiesUpdates,
                    [action.meta]: updatedActualPendingDriverActivitiesUpdates,
                },
            };
        }

        case ActionTypeKeys.DRIVERACTIVITIES_APPLY_PENDING: {
            const actualDriverActivitiesState = state.driversActivities[action.meta];
            const actualPendingDriverActivitiesUpdates = state.pendingDriverActivitiesUpdates[action.meta];

            if (
                actualPendingDriverActivitiesUpdates &&
                actualDriverActivitiesState &&
                actualDriverActivitiesState.data &&
                actualDriverActivitiesState.fulfilled &&
                !actualDriverActivitiesState.pending
            ) {
                return {
                    ...state,
                    driversActivities: {
                        ...state.driversActivities,
                        [action.meta]: {
                            ...actualDriverActivitiesState,
                            data: applyDriverActivitiesUpdate(
                                actualDriverActivitiesState.data,
                                actualPendingDriverActivitiesUpdates
                            ),
                        },
                    },
                    pendingDriverActivitiesUpdates: omit(state.pendingDriverActivitiesUpdates, [action.meta]),
                };
            }
            return state;
        }

        default:
            return state;
    }
};
