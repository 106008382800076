import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withSceneAnalytics } from '~/components/Analytics';
import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { DriverDetailsPaneInnerProps, DriverDetailsPaneProps } from './component';
import { DriverDetailsPaneComponent } from './component';
import type { HistoryDriverDetailsPaneUserPreferences } from './preferences';
import {
    HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY,
    defaultHistoryDriverDetailsPaneUserPreferences,
    requiredHistoryDriverDetailsPaneUserPreferences,
} from './preferences';
import { mapDispatchToProps } from './redux';

export {
    defaultHistoryDriverDetailsPaneUserPreferences,
    HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY,
    requiredHistoryDriverDetailsPaneUserPreferences,
};

export type DefaultDetailsPaneUserPreferences = HistoryDriverDetailsPaneUserPreferences;

export const DriverDetailsPane = compose<DriverDetailsPaneInnerProps, DriverDetailsPaneProps>(
    setDisplayName('DriverDetailsPane'),
    withSceneAnalytics('historydriverdetailspane'),
    withTranslation(),
    withDisplayPreferences(),
    connect(undefined, mapDispatchToProps)
)(DriverDetailsPaneComponent);
