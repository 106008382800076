import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ActivityDetailsClassKey = 'listItemWithMargin' | 'root';

export const ActivityDetailsStyleRules: StyleRulesCallback<Theme, {}, ActivityDetailsClassKey> = (theme: Theme) =>
    createStyles({
        listItemWithMargin: {
            marginTop: theme.spacing(2),
        },
        root: {
            padding: 0,
        },
    });
