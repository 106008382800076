import { Avatar, Tooltip } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { useDisplayPreferences } from '~/common/hooks';
import { DateTimeFormatter, DriverActivityIconFormatter, DriverNameFormatter } from '~/components/Formatters';
import { CoDriverIcon, DriverIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';
import { DriverRole } from '~/services/ApiClient';
import { formatDriverActivity } from '~/services/Formatters';

import type { DriverHeaderProps } from './models';
import { useStyles } from './styles';

const DriverHeader: FC<DriverHeaderProps> = (props) => {
    const { activity, driver, lastEvent, role, subactivity } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const displayPreferences = useDisplayPreferences();

    const lastEventDate = lastEvent as Date;

    const avatar =
        role === DriverRole.DRIVER ? (
            <Avatar
                className={classNames(classes.driverAvatar, { [classes.disabled]: !driver })}
                data-id="driver-avatar"
            >
                <DriverIcon className={classes.driverIcon} />
            </Avatar>
        ) : (
            <Avatar className={classes.driverAvatar} data-id="co-driver-avatar">
                <CoDriverIcon className={classes.driverIcon} />
            </Avatar>
        );

    const activityBadge = (
        <Avatar
            className={
                !isUndefined(activity) ? classNames(classes.activityAvatar, classes[`activity${activity.key}`]) : ''
            }
            data-id="driver-activity-badge"
        >
            {activity && <DriverActivityIconFormatter iconProps={{ fontSize: 'inherit' }} value={activity.key} />}
        </Avatar>
    );

    const tooltipTitle = (
        <div>
            <div>
                <DriverNameFormatter displayNameFormat={displayPreferences.driverDisplayFormat} value={driver} />
            </div>
            <div>
                {activity && <span>{formatDriverActivity(t, activity)}</span>}
                {activity && subactivity && `, `}
                {subactivity && <span>{subactivity.name}</span>}
            </div>
            <div>
                {lastEvent && (
                    <span>
                        <Moment fromNow>{lastEventDate.toUTCString()}</Moment>
                    </span>
                )}
            </div>
            <div>{lastEventDate && <DateTimeFormatter value={lastEventDate} />}</div>
        </div>
    );

    return (
        <div className={classes.driver}>
            {driver ? (
                <Tooltip title={tooltipTitle ?? ''}>
                    <div className={classes.flex}>
                        {avatar}
                        {activityBadge}
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title={t('not-logged-in')}>{avatar}</Tooltip>
            )}
        </div>
    );
};

DriverHeader.displayName = 'DriverHeader';
export default DriverHeader;
