import _Clear from '@mui/icons-material/Clear';
import _Search from '@mui/icons-material/Search';
import _Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
}));

const SearchIcon = styled(_Search)(({ theme }) => ({ color: theme.palette.text.secondary }));
const ClearIcon = styled(_Clear)(({ theme }) => ({ color: theme.palette.text.secondary, cursor: 'pointer' }));

const Input = styled(_Input)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1),
}));

export { ClearIcon, Container, Input, SearchIcon };
