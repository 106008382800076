import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, sortBy } from '~/libs/utility';
import type {
    AssetRecipient,
    DisplayUserPreferencesVehicleDisplayFormat,
    Vehicle,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';
import { AssetRecipientType } from '~/services/ApiClient';
import { formatVehicleCategory, formatVehicleName } from '~/services/Formatters';

export const mapVehicleOptions = (
    vehicles: Vehicle[],
    vehicleTypes: NumericDictionary<VehicleType>,
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    t: SingleTFunction,
    vehiclePredicate?: (vehicleId: number) => boolean
): AssetRecipient[] =>
    sortBy(
        vehicles
            .filter((x) => (vehiclePredicate ? vehiclePredicate(x.id) : x))
            .map((x) => {
                const category = vehicleCategories[x.category];
                const vehicleType = !isUndefined(x.vehicleTypeId) ? vehicleTypes[x.vehicleTypeId] : undefined;
                const vehicleTypeFormated = !isUndefined(vehicleType)
                    ? vehicleType.name || vehicleType.description
                    : undefined;

                const secondaryFirst = !isUndefined(category) ? formatVehicleCategory(t, category) : undefined;
                const secondarySecond = !isUndefined(vehicleTypeFormated) ? vehicleTypeFormated : undefined;
                return {
                    ids: [x.id],
                    name: formatVehicleName(x, vehicleDisplayFormat),
                    secondaryTextFirst: secondaryFirst,
                    secondaryTextSecond: secondarySecond,
                    type: AssetRecipientType.VEHICLE,
                };
            }),
        (vehicle) => vehicle.name.toLocaleLowerCase()
    );
