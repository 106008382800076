import type { ActionTypes as AuthenticationActionTypes } from '~/data/authentication';
import { ActionTypeKeys as AuthenticationActionTypeKeys } from '~/data/authentication';

import type { ActionTypes as MenuActionTypes } from '../../data';
import { ActionTypeKeys as MenuActionTypeKeys } from '../../data';

export interface OverlayMenuStoreState {
    impersonateUserFailed: boolean;
    impersonateUserFulfilled: boolean;
    impersonateUserPending: boolean;
}

export const defaultStoreState: OverlayMenuStoreState = {
    impersonateUserFailed: false,
    impersonateUserFulfilled: false,
    impersonateUserPending: false,
};

export const overlayMenuReducer = (
    state: OverlayMenuStoreState = defaultStoreState,
    action: AuthenticationActionTypes | MenuActionTypes
): OverlayMenuStoreState => {
    switch (action.type) {
        case MenuActionTypeKeys.OVERLAYMENU_CLOSE:
            return defaultStoreState;

        case AuthenticationActionTypeKeys.IMPERSONATEUSER_PENDING:
            return {
                ...state,
                impersonateUserFailed: false,
                impersonateUserFulfilled: false,
                impersonateUserPending: true,
            };
        case AuthenticationActionTypeKeys.IMPERSONATEUSER_FULFILLED:
            return {
                ...state,
                impersonateUserFailed: false,
                impersonateUserFulfilled: true,
                impersonateUserPending: false,
            };
        case AuthenticationActionTypeKeys.IMPERSONATEUSER_REJECTED:
            return {
                ...state,
                impersonateUserFailed: true,
                impersonateUserFulfilled: false,
                impersonateUserPending: false,
            };

        default:
            return state;
    }
};
