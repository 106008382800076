import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TemperaturesSectionClassKey =
    | 'compartmentContainer'
    | 'compartmentHeader'
    | 'compartmentHeaderText'
    | 'indicators'
    | 'temperatureIndicator';

export const TemperaturesSectionStyleRules: StyleRulesCallback<Theme, {}, TemperaturesSectionClassKey> = (
    theme: Theme
) =>
    createStyles({
        compartmentContainer: {
            margin: theme.spacing(0, 2, 1),
        },
        compartmentHeader: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        compartmentHeaderText: {
            flexGrow: 1,
        },
        indicators: {
            '&>*': {
                marginLeft: theme.spacing(1),
            },
            display: 'flex',
        },
        temperatureIndicator: {
            fontSize: 18,
            height: 24,
            width: 24,
        },
    });
