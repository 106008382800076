import type { FC } from 'react';

import 'flag-icons/css/flag-icons.min.css';

import type { FlagIconProps } from './models';

const FlagIcon: FC<FlagIconProps> = ({ className, code }) => {
    return <span className={`fi fi-${code.toLowerCase()} ${className}`} />;
};

FlagIcon.displayName = 'FlagIcon';
export { FlagIcon };
