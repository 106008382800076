import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import {
    DateTimeFormatter,
    DurationFormatter,
    ViolationFieldFormatterFactory,
    ViolationType,
} from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { ViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel } from '~/services/Formatters';

import { SectionName } from '../../constants';

import { useStyles } from './styles';

export interface RestSectionProps {
    dailyRest?: Duration;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    reducedDayRestCount?: ViolationField<number>;
    splitDailyRest?: Duration;
    toggleCollapsed: () => void;
    totalWeeklyRestCompensation?: Duration;
    weeklyRestCompensation?: Duration;
    weeklyRestDueDateTime?: Date;
}

export interface RestSectionInnerProps extends RestSectionProps, InjectedTranslationProps {}

export const RestSectionComponent: React.FC<RestSectionInnerProps> = ({
    dailyRest,
    dragHandleElement,
    isCollapsed,
    reducedDayRestCount,
    splitDailyRest,
    t,
    toggleCollapsed,
    totalWeeklyRestCompensation,
    weeklyRestCompensation,
    weeklyRestDueDateTime,
}) => {
    const classes = useStyles();

    const ViolationNumberFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: number) => v.toString()),
        []
    );

    const keyValueItems: KeyValueItem[] = [
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-first-split-daily-rest'),
            value: splitDailyRest ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    value={splitDailyRest}
                    violationType={ViolationType.ABOVE}
                />
            ) : undefined,
            valueDataId: 'first-split-daily-rest',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-reduced-daily-rest-count'),
            value: !isUndefined(reducedDayRestCount) ? (
                <ViolationNumberFormatter
                    fieldName={formatDriverSubpageListItemLabel(t, 'rest-reduced-daily-rest-count')}
                    t={t}
                    value={reducedDayRestCount}
                />
            ) : undefined,
            valueDataId: 'reduced-daily-rest-count',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-daily-resting'),
            value: dailyRest ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    value={dailyRest}
                    violationType={ViolationType.ABOVE}
                />
            ) : undefined,
            valueDataId: 'daily-resting',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-weekly-rest-compensation'),
            value: weeklyRestCompensation ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    value={weeklyRestCompensation}
                    violationType={ViolationType.ABOVE}
                />
            ) : undefined,
            valueDataId: 'weekly-rest-compensation',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-total-weekly-rest-compensation'),
            value: totalWeeklyRestCompensation ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    value={totalWeeklyRestCompensation}
                    violationType={ViolationType.ABOVE}
                />
            ) : undefined,
            valueDataId: 'total-weekly-rest-compensation',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'rest-weekly-rest-due'),
            value: weeklyRestDueDateTime ? (
                <span className={classes.simpleValue} data-id="weekly-rest-due">
                    <DateTimeFormatter value={weeklyRestDueDateTime} />
                </span>
            ) : undefined,
            valueDataId: 'weekly-rest-due',
        },
    ];

    return (
        <ReorderableSection
            dataId={SectionName.REST}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.REST}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="property-list" />
        </ReorderableSection>
    );
};
