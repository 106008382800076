import { styled } from '@mui/material';

const Frame = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    maxHeight: 175,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(1.5),
}));

export { Frame };
