import { StaticDataType } from '../models';
import type { StaticDataStoreState } from '../reducers';

export const isStaticDataLoaded = (type: StaticDataType, staticDataState: StaticDataStoreState): boolean => {
    switch (type) {
        case StaticDataType.ASSETGROUPS:
            return staticDataState.assetGroups && staticDataState.assetGroups.fulfilled;

        case StaticDataType.CONTAINERS:
            return staticDataState.containers && staticDataState.containers.fulfilled;

        case StaticDataType.DEPOTS:
            return staticDataState.depots && staticDataState.depots.fulfilled;

        case StaticDataType.DEVICES:
            return staticDataState.devices && staticDataState.devices.fulfilled;

        case StaticDataType.DRIVERS:
            return staticDataState.drivers && staticDataState.drivers.fulfilled;
        case StaticDataType.DRIVERSTATUS:
            return staticDataState.driverStatus && staticDataState.driverStatus.fulfilled;
        case StaticDataType.TRAILERS:
            return staticDataState.trailers && staticDataState.trailers.fulfilled;
        case StaticDataType.VEHICLES:
            return staticDataState.vehicles && staticDataState.vehicles.fulfilled;
        case StaticDataType.VEHICLESTATUS:
            return staticDataState.vehicleStatus && staticDataState.vehicleStatus.fulfilled;
        default:
            throw Error(`Provided static data type ${type} is not supported.`);
    }
};
