import { MAP_MODES } from '@fv/components';
import type { MapGatewayLayerProps } from '@fv/components';

import type { GetMapLayersPropsArgs } from './models';

export const getMapLayersProps = (args: GetMapLayersPropsArgs): MapGatewayLayerProps => {
    const { mapLayersSecurables, mapMode, trafficIncidentsOverlayEnabled, truckAttributesOverlayEnabled } = args;

    const includeTruckAttributes = Boolean(truckAttributesOverlayEnabled && mapLayersSecurables.truckAttributesAllowed);
    const includeTrafficIncidents = Boolean(
        trafficIncidentsOverlayEnabled && mapLayersSecurables.trafficIncidentsAllowed
    );

    if (!mapLayersSecurables?.satelliteAllowed) {
        return {
            includeTrafficIncidents,
            includeTruckAttributes,
            mapMode: MAP_MODES.ROAD,
        };
    }

    return {
        includeTrafficIncidents,
        includeTruckAttributes,
        mapMode,
    };
};
