import { LocationSection } from '~/common';
import type { Address, Position } from '~/services/ApiClient';

import type { SectionProps } from '../../../models';

import { CopyCoordinates } from './components/CopyCoordinates';

const LocationComponent: React.FC<SectionProps> = (props) => {
    const { dragHandleElement, driverStatus, isCollapsed, toggleCollapsed } = props;

    const position = driverStatus.location?.position as Position;

    return (
        <LocationSection
            actionButtons={<CopyCoordinates position={position} />}
            address={driverStatus.location?.address as Address}
            datetime={driverStatus.location?.dateTime as Date}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            position={position}
            toggleCollapsed={toggleCollapsed}
        />
    );
};

LocationComponent.displayName = 'Location';
export default LocationComponent;
