import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import image from './assets/empty-road.png';
import type { EmptyStateMessageClassKey } from './styles';

export interface EmptyStateMessageProps {
    dataId?: string;
    description: React.ReactNode;
    title: React.ReactNode;
}

export interface EmptyStateMessageInnerProps extends EmptyStateMessageProps, WithStyles<EmptyStateMessageClassKey> {}

export const EmptyStateMessageComponent: React.FunctionComponent<EmptyStateMessageInnerProps> = ({
    classes,
    dataId,
    description,
    title,
}) => {
    return (
        <div className={classes.root} data-id={dataId}>
            <img alt="Empty road" className={classes.image} src={image} />
            <Typography align="center" className={classes.title} variant="subtitle1">
                {title}
            </Typography>
            <Typography align="center" color="textSecondary" variant="caption">
                {description}
            </Typography>
        </div>
    );
};
