import * as React from 'react';
import { useSelector } from 'react-redux';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory } from '~/components/SceneList';
import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type { ResolvedAdminVehicleRead } from '~/services/ApiClient';

import type { ReduxProps } from '../..';
import { getRowId, vehicleAdministrationEquals } from '../../../../entryUtils';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import {
    VEHICLE_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleAdministrationListUserPreferences,
} from '../../preferences';

export type VehicleAdministrationListViewProps = Omit<
    SceneListViewProps<ResolvedAdminVehicleRead>,
    'columns' | 'getCellClassName' | 'gridDataId'
>;

export interface VehicleAdministrationListViewInnerProps
    extends VehicleAdministrationListViewProps,
        ReduxProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const ListViewComponent = SceneListViewFactory<ResolvedAdminVehicleRead>(
    VEHICLE_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleAdministrationListUserPreferences,
    getRowId,
    vehicleAdministrationEquals,
    filterDataSourceMemoized
);

export const VehicleAdministrationListViewComponent: React.FC<VehicleAdministrationListViewInnerProps> = ({
    billingGroups,
    billingStatuses,
    deviceTypes,
    displayPreferences,
    fuelTankTypes,
    i18n,
    imageCapturingTypes,
    inhibitorServiceTypes,
    navigationTypes,
    networks,
    securables,
    t,
    tachoGenerations,
    tachographs,
    tachoProtocols,
    tellTaleServiceTypes,
    vehicleCategories,
    vehicleTypes,
    workflowTypes,
    ...restProps
}) => {
    const staticDataState = useSelector((store: StoreState) => staticDataStoreStateSelector(store));

    const depots = isUndefined(staticDataState) ? [] : staticDataState.depots.data;
    const columns = getColumnsMemoized({
        billingGroups,
        billingStatuses,
        depots,
        deviceTypes,
        fuelTankTypes,
        imageCapturingTypes,
        inhibitorServiceTypes,
        navigationTypes,
        networks,
        servicesSecurables: securables.services,
        t,
        tachoGenerations,
        tachographs,
        tachoProtocols,
        tellTaleServiceTypes,
        unitSystem: displayPreferences.unitSystem,
        vehicleCategories,
        vehicleTypes,
        workflowTypes,
    });
    return <ListViewComponent columns={columns} gridDataId="vehicle-administration-grid" {...restProps} />;
};
