import type { ActionItem } from '@fv/components/Actions';
import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ServicesSecurables } from '~/services/ApiClient';

import type { VehicleAdministrationEntry } from '../../models';

import { SectionName } from './constants';
import type { VehicleAdministrationDetailsPaneUserPreferences } from './preferences';
import { VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface VehicleAdministrationDetailsPaneProps {
    entry: VehicleAdministrationEntry;
    headerActions: ActionItem[];
    onClose: () => void;
    vehicleAdministrationServices: ServicesSecurables;
}

export interface VehicleAdministrationDetailsPaneInnerProps
    extends VehicleAdministrationDetailsPaneProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    VehicleAdministrationEntry,
    VehicleAdministrationDetailsPaneUserPreferences,
    SectionName
>(VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY);

export const VehicleAdministrationDetailsPaneComponent: React.FC<VehicleAdministrationDetailsPaneInnerProps> = ({
    entry,
    headerActions,
    onClose,
    t,
    vehicleAdministrationServices,
}) => {
    const getSections = React.useCallback(
        (
            userPreferences: VehicleAdministrationDetailsPaneUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized({
                collapsedSections: userPreferences.collapsedSections,
                entry,
                headerActions,
                toggleCollapsedState,
                vehicleAdministrationServices,
            }),
        [entry, headerActions, vehicleAdministrationServices]
    );

    return (
        <DetailsPaneComponent
            alwaysFirstSection={SectionName.VEHICLEADMINISTRATIONHEADER}
            dataId="vehicleadministration-details-pane"
            entry={entry}
            getSections={getSections}
            HeaderReactNode={
                <DetailsPaneHeader onClose={onClose} title={t('vehicle-administration-details-pane-header')} />
            }
        />
    );
};
