import type { StaticDataStoreState } from '~/common';
import type { UserPreferencesState } from '~/components/UserPreferences';
import type { ActivityReportSceneUserPreferences } from '~/scenes/DriverActivitiesReport/models';

import { ACTION_TYPE_KEYS } from './actionsTypes';
import type { ActionTypes, DriverActivitiesReportSceneState, DriverActivitiesResolverArgs } from './models';
import { ViewMode } from './models';
import { driverActivitiesGraphResolver, driverActivitiesListResolver } from './utils';

const INITIAL_STATE: DriverActivitiesReportSceneState = {
    error: false,
    items: [],
    pending: false,
};

const driverActivitiesReportReducer = (
    state = INITIAL_STATE,
    action: ActionTypes,
    staticData: StaticDataStoreState,
    userPreferences: UserPreferencesState
): DriverActivitiesReportSceneState => {
    const { drivers, vehicles } = staticData;
    const actualData = userPreferences['driver_activities_report-scene']
        ?.actualData as ActivityReportSceneUserPreferences;
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED: {
            const commonData: DriverActivitiesResolverArgs = {
                drivers: drivers.data.hash,
                groupingOptions: actualData.groupingWithTotalsOptions,
                source: action.payload,
                vehicles: vehicles.data.hash,
            };
            return {
                ...state,
                items:
                    action.meta.viewMode === ViewMode.LIST
                        ? driverActivitiesListResolver(commonData)
                        : driverActivitiesGraphResolver(commonData, action.meta.assetType),
                pending: false,
            };
        }

        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case ACTION_TYPE_KEYS.RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export { driverActivitiesReportReducer };
