import type { BaseItem, FlattenItemsArgs } from '@fv/components';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssetSelector } from '../../context';

import type { ItemContentProps } from './components';
import { ItemContent } from './components';
import { EmptyPlaceholderContainer, Virtuoso } from './styles';

const List = <T,>() => {
    const [
        { checkableItemsCount, checkedKeys, expandedKeys, hideExpandAllButton, items, query },
        { checkablePredicate, onCheck, onClick, onCollapse, onContextMenu },
    ] = useAssetSelector<T>();
    const { t } = useTranslation();

    const flatItemsList = useMemo(() => {
        const flattenItems = (args: FlattenItemsArgs<T>) => {
            const { indentLevel = 0, isRoot = true, parentId = 'root', sourceItems } = args;
            let flattenedItems: BaseItem<T>[] = [];
            sourceItems?.forEach((item: BaseItem<T>) => {
                if (isRoot || parentId in expandedKeys || query.length > 0) {
                    flattenedItems.push({ ...item, indentLevel });

                    if (item?.children?.length) {
                        flattenedItems = flattenedItems.concat(
                            flattenItems({
                                indentLevel: indentLevel + 1,
                                isRoot: false,
                                parentId: item.id,
                                sourceItems: item?.children,
                            })
                        );
                    }
                }
            });

            return flattenedItems;
        };

        return flattenItems({ sourceItems: items });
    }, [expandedKeys, items, query.length]);

    return (
        <Virtuoso
            components={{
                // eslint-disable-next-line react/no-unstable-nested-components
                EmptyPlaceholder: () => {
                    return (
                        <EmptyPlaceholderContainer>
                            <Typography align="center" data-id="column-selector-message">
                                {query ? t('no-results', { term: query }) : t('no-assets')}
                            </Typography>
                        </EmptyPlaceholderContainer>
                    );
                },
            }}
            data={checkableItemsCount > 0 ? flatItemsList : []}
            data-testid="asset-selector-list"
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(_: number, props: ItemContentProps<T>) => (
                <ItemContent
                    {...props}
                    checkablePredicate={checkablePredicate}
                    checkedKeys={checkedKeys}
                    disableExpandButton={Boolean(query.length && props.children?.length)}
                    expandedKeys={expandedKeys}
                    hideExpandAllButton={hideExpandAllButton}
                    onCheck={onCheck}
                    onClick={onClick}
                    onCollapse={onCollapse}
                    onContextMenu={onContextMenu}
                />
            )}
        />
    );
};

List.displayName = 'List';
export { List };
