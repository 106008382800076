import { withTranslation } from 'react-i18next';
import { defaultProps } from 'react-recompose';

import type { ChangelogAttributeDefinition } from '~/common/components/ChangelogsSubpage';
import { BooleanFormatter, ClassificationFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { ResolvedVehicleChangelogChanges } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

export const getVehicleChangelogAttributeDefinitions = (
    t: SingleTFunction
): ChangelogAttributeDefinition<ResolvedVehicleChangelogChanges>[] => {
    return [
        {
            getValue: (changelogChanges) => changelogChanges?.uaid,
            title: t('vehicleadmin-changelogs-definitions-uaid'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.category?.key,
            title: t('vehicleadmin-changelogs-definitions-category'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.vehicleType?.name,
            title: t('vehicleadmin-changelogs-definitions-vehicleType'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.depot?.name,
            title: t('vehicleadmin-changelogs-definitions-depot'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.vin,
            title: t('vehicleadmin-changelogs-definitions-vin'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.vrn,
            title: t('vehicleadmin-changelogs-definitions-vrn'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.unitId,
            title: t('vehicleadmin-changelogs-definitions-unitId'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.companyCardId,
            title: t('vehicleadmin-changelogs-definitions-companyCardId'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.tag,
            title: t('vehicleadmin-changelogs-definitions-tag'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.msisdn,
            title: t('vehicleadmin-changelogs-definitions-msisdn'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.alias,
            title: t('vehicleadmin-changelogs-definitions-alias'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.oneMinuteRuleEnabled,
            title: t('vehicleadmin-changelogs-definitions-oneMinuteRuleEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.alertsAndPanicEnabled,
            title: t('vehicleadmin-changelogs-definitions-alertsAndPanicEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.driverCardsEnabled,
            title: t('vehicleadmin-changelogs-definitions-driverCardsEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.driverCoachEnabled,
            title: t('vehicleadmin-changelogs-definitions-driverCoachEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.driverHoursEnabled,
            title: t('vehicleadmin-changelogs-definitions-driverHoursEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.dseEnabled,
            title: t('vehicleadmin-changelogs-definitions-dseEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.frequentPositioningEnabled,
            title: t('vehicleadmin-changelogs-definitions-frequentPositioningEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.fuelSiteAdviceEnabled,
            title: t('vehicleadmin-changelogs-definitions-fuelSiteAdviceEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.kpi2Enabled,
            title: t('vehicleadmin-changelogs-definitions-kpi2Enabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.landmarksEnabled,
            title: t('vehicleadmin-changelogs-definitions-landmarksEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.rtdsEnabled,
            title: t('vehicleadmin-changelogs-definitions-rtdsEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.textMessagingEnabled,
            title: t('vehicleadmin-changelogs-definitions-textMessagingEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.trailersEnabled,
            title: t('vehicleadmin-changelogs-definitions-trailersEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.tireControlEnabled,
            title: t('vehicleadmin-changelogs-definitions-tireControlEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.appStoreEnabled,
            title: t('vehicleadmin-changelogs-definitions-appStoreEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.truckIdentificationEnabled,
            title: t('vehicleadmin-changelogs-definitions-truckIdentificationEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.trailerControlServiceEnabled,
            title: t('vehicleadmin-changelogs-definitions-trailerControlServiceEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.j1939DataServiceEnabled,
            title: t('vehicleadmin-changelogs-definitions-j1939DataServiceEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.navigationEtaEnabled,
            title: t('vehicleadmin-changelogs-definitions-navigationEtaEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.guidedNavigationEnabled,
            title: t('vehicleadmin-changelogs-definitions-guidedNavigationEnabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.tachograph,
            title: t('vehicleadmin-changelogs-definitions-tachograph'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.Tachograph, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.tachoProtocol,
            title: t('vehicleadmin-changelogs-definitions-tachoprotocol'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.TachoProtocol, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.tachoGeneration,
            title: t('vehicleadmin-changelogs-definitions-tachogeneration'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.TachoGeneration, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.workflowType,
            title: t('vehicleadmin-changelogs-definitions-workflowType'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.WorkflowType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.navigationType,
            title: t('vehicleadmin-changelogs-definitions-navigationType'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.NavigationType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.imageCapturingType,
            title: t('vehicleadmin-changelogs-definitions-imageCapturingType'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.ImageCapturingType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.inhibitorServiceType,
            title: t('vehicleadmin-changelogs-definitions-inhibitorServiceType'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.InhibitorServiceType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.tellTaleServiceType,
            title: t('vehicleadmin-changelogs-definitions-tellTaleServiceType'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.TellTaleServiceType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.primaryFuelTankSize,
            title: t('vehicleadmin-changelogs-definitions-primary-fuel-tank-size'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.primaryFuelTankType,
            title: t('vehicleadmin-changelogs-definitions-primary-fuel-tank-type'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.FuelTankType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.secondaryFuelTankSize,
            title: t('vehicleadmin-changelogs-definitions-secondary-fuel-tank-size'),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.secondaryFuelTankType,
            title: t('vehicleadmin-changelogs-definitions-secondary-fuel-tank-type'),
            valueFormatterComponent: defaultProps({ classificationKey: ClassificationType.FuelTankType, t })(
                ClassificationFormatter
            ),
        },
        {
            getValue: (changelogChanges) => changelogChanges?.settings?.adBlueTankSize,
            title: t('vehicleadmin-changelogs-definitions-ad-blue-tank-size'),
        },
    ];
};
