import { TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableBodyCell } from '../TableBodyCell';

import type { CollectionChangelogChangesTableRowProps } from './models';

const CollectionChangelogChangesTableRow = <T, V>(props: CollectionChangelogChangesTableRowProps<T, V>) => {
    const { attributeDefinition, changelog } = props;
    const { t } = useTranslation();

    const beforeItem = attributeDefinition.getValue(changelog.before);
    const afterItem = attributeDefinition.getValue(changelog.after);

    const removed = Number(beforeItem) - Number(afterItem);
    const added = Number(afterItem) - Number(beforeItem);

    const textToShow =
        added === 0 && removed === 0
            ? t('changelog-collection-changes-summary-equal-item-count')
            : t('changelog-collection-changes-summary', {
                  added: added > 0 ? added : 0,
                  removed: removed > 0 ? removed : 0,
              });

    return (
        <TableRow data-id={attributeDefinition.title} key={attributeDefinition.title}>
            <TableBodyCell text={attributeDefinition.title} valueDataId="attribute" />
            <TableBodyCell colSpan={2} text={textToShow} valueDataId="changes" />
        </TableRow>
    );
};

CollectionChangelogChangesTableRow.displayName = 'CollectionChangelogChangesTableRow';
export { CollectionChangelogChangesTableRow };
