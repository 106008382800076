import type { Dispatch } from 'react';
import { useState } from 'react';

import { differenceWith, intersectionWith, isEqual, isUndefined, xor, xorWith } from '../../../utility';
import type { GroupOption } from '../../models';
import type { FilteredGroupOption } from '../models';
import { filterOptions, flattenOptions, getAllChildrenItems } from '../utils';

interface UseMultiSelectListArgs<TId> {
    equalityComparer: (a: TId, b: TId) => boolean;
    onChange: Dispatch<TId[]>;
    options: GroupOption<TId>[];
    searchText: string;
    value: TId[];
}

const useMultiSelectList = <TId>(args: UseMultiSelectListArgs<TId>) => {
    const { equalityComparer, onChange, options, searchText, value } = args;
    const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
    const [collapsedChildren, setCollapsedChildren] = useState<TId[]>([]);

    const visibleOptions = filterOptions({ options, searchText });
    const groups = visibleOptions.map((option, groupIndex): FilteredGroupOption<TId> => {
        const allGroupValues = flattenOptions(options[groupIndex].options).map((o) => o.id);
        const selectedAllGroupValues = intersectionWith(allGroupValues, value, equalityComparer);

        return {
            groupName: option.groupName,
            options: option.options,
            selectedItemCount: selectedAllGroupValues.length,
            totalItemCount: allGroupValues.length,
        };
    });
    const groupCounts = visibleOptions.map((option) =>
        collapsedGroups.includes(option.groupName) ? 0 : option.options.length
    );
    const flattenedVisibleOptions = flattenOptions(
        visibleOptions,
        (o) => 'id' in o || !collapsedGroups.includes(o.groupName)
    );

    const isCollapsed = (option: FilteredGroupOption<TId>) => collapsedGroups.includes(option.groupName);
    const toggleCollapsed = (option: FilteredGroupOption<TId>) =>
        setCollapsedGroups(xor(collapsedGroups, [option.groupName]));

    const toggleCollapsedSubGroup = (id: TId) => {
        const childrenIds = getAllChildrenItems(id, flattenedVisibleOptions).map((x) => x.id);
        setCollapsedChildren((prevIds) => xor(prevIds, [id, ...childrenIds]));
    };
    const isCollapsedSubGroup = (id: TId) => collapsedChildren.some((x) => isEqual(x, id));
    const isSelected = (optionValue: TId) => !isUndefined(value.find((v) => equalityComparer(v, optionValue)));
    const toggleSelected = (optionValues: TId[], includeChildren?: boolean) => {
        let updatedValue: TId[];

        if (includeChildren !== undefined) {
            updatedValue = includeChildren
                ? value.concat(optionValues)
                : differenceWith(value, optionValues, equalityComparer);
        } else {
            updatedValue = xorWith(value, optionValues, equalityComparer);
        }

        onChange(updatedValue);
    };

    return {
        allItems: flattenOptions(options),
        filteredItems: flattenOptions(visibleOptions),
        groupCounts,
        groups,
        isCollapsed,
        isCollapsedSubGroup,
        isSelected,
        items: flattenedVisibleOptions.filter((option) =>
            option.parentId ? !collapsedChildren.some((x) => isEqual(x, option.parentId)) : true
        ),
        toggleCollapsed,
        toggleCollapsedSubGroup,
        toggleSelected,
    };
};

export { useMultiSelectList };
export type { UseMultiSelectListArgs };
