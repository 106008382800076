import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import { IconFormatter } from './components/Icon';

export interface TrailerManualConnectionStatusFormatterProps extends InjectedTranslationProps {
    value?: boolean;
}

export interface TrailerManualConnectionStatusFormatterInnerProps extends TrailerManualConnectionStatusFormatterProps {}

export const TrailerManualConnectionStatusFormatterComponent: React.SFC<
    TrailerManualConnectionStatusFormatterInnerProps
> = ({ t, value }) => {
    if (value) {
        return (
            <Tooltip title={t('is-manually-connected')}>
                <span data-id={`trailert-manually-connect-status:${value}`}>
                    <IconFormatter />
                </span>
            </Tooltip>
        );
    } else {
        return null;
    }
};
