import type { ApexOptions as ApexOptionsLib } from 'apexcharts';

type ApexOptions = Required<ApexOptionsLib>;
type ChartOptions = Required<ApexOptions['chart']>;
type Events = ChartOptions['events'];

const beforeResetZoom: Events['beforeResetZoom'] = ({ ctx }) => {
    const { opts } = ctx;
    return { xaxis: { max: opts.xaxis.max, min: opts.xaxis.min } };
};

const beforeZoom: Events['beforeZoom'] = ({ ctx, w }, { xaxis }) => {
    const FIFTEEN_MIN = 900000;

    const { xaxis: configXAxis } = ctx.opts;
    const { config } = w;

    const mainDiff = configXAxis.max - configXAxis.min;
    const zoom = xaxis.max - xaxis.min;

    if (zoom > mainDiff) {
        return { xaxis: { max: configXAxis.max, min: configXAxis.min } };
    }

    if (zoom < FIFTEEN_MIN) {
        return { xaxis: { max: config.xaxis.max, min: config.xaxis.min } };
    }

    return { xaxis };
};

export { beforeResetZoom, beforeZoom };
