import classNames from 'classnames';
import * as React from 'react';

import { ReorderableSections } from '~/components/Sections';

import type { DetailsPaneContextProps } from './context';
import { DetailsPaneContext } from './context';
import type { SceneDetailsPaneInnerProps } from './models';
import type { SceneDetailsPaneUserPreferences } from './preferences.sceneDetailsPane';

export const SceneDetailsPaneFactoryComponent = <
    E,
    P extends SceneDetailsPaneUserPreferences<S>,
    S extends string,
>(): React.FC<SceneDetailsPaneInnerProps<E, P, S>> => {
    const Component: React.FC<SceneDetailsPaneInnerProps<E, P, S>> = ({
        alwaysFirstSection,
        changeSectionOrder,
        classes,
        className,
        dataId,
        entry,
        getSections,
        Header,
        HeaderReactNode,
        onClose,
        toggleCollapsedState,
        unauthorizedSections,
        userPreferences,
    }) => {
        const sectionDefinitions = getSections(userPreferences, toggleCollapsedState);
        const outerContext = React.useContext(DetailsPaneContext);
        const [subpage, setSubpage] = React.useState<React.ReactNode | undefined>(undefined);

        const subpageContext = React.useMemo<DetailsPaneContextProps>(() => {
            return {
                ...outerContext,
                close: onClose ?? outerContext.close,
                goBack: () => setSubpage(undefined),
            };
        }, [outerContext, setSubpage, onClose]);

        const pageContext = React.useMemo<DetailsPaneContextProps>(() => {
            return {
                ...outerContext,
                close: onClose ?? outerContext.close,
                openSubpage: setSubpage,
            };
        }, [outerContext, setSubpage, onClose]);

        const pageClassname = classNames(classes.root, className, { [classes.hidden]: subpage });
        const headerElement = Header ? <Header entry={entry} /> : HeaderReactNode;

        return (
            <>
                <DetailsPaneContext.Provider value={subpageContext}>{subpage}</DetailsPaneContext.Provider>
                <DetailsPaneContext.Provider value={pageContext}>
                    <div className={pageClassname} data-id={dataId}>
                        <div className={classes.header} data-id="header">
                            {headerElement}
                        </div>

                        <div className={classes.sections}>
                            <ReorderableSections
                                alwaysFirstSection={alwaysFirstSection}
                                changeSectionOrder={changeSectionOrder}
                                droppableId="details-pane-section"
                                hiddenSections={unauthorizedSections}
                                sectionDefinitions={sectionDefinitions}
                                sectionOrder={userPreferences.sectionOrder}
                            />
                        </div>
                    </div>
                </DetailsPaneContext.Provider>
            </>
        );
    };

    return Component;
};
