import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY } from '~/data/monitoring';

import { SectionName } from './consts';

export const defaultDriverStatusDetailsPaneUserPreferences: DriverStatusDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 12,
    sectionOrder: [
        SectionName.DRIVERSTATUS_HEADER,
        SectionName.DRIVERSTATUS_MINIMAP,
        SectionName.DRIVERSTATUS_LOCATION,
        SectionName.DRIVERSTATUS_STAFF,
        SectionName.DRIVERSTATUS_VEHICLE,
    ],
};

export interface DriverStatusDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const requiredDriverStatusDetailsPaneUserPreferences = {
    defaultState: defaultDriverStatusDetailsPaneUserPreferences,
    key: DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY,
};
