import { Checkbox, FormControl, FormHelperText, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { useController } from 'react-hook-form';

import { BooleanInput } from '~/components/InputFields';
import type { BooleanInputProps } from '~/components/InputFields';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SettingsValueSource } from '~/services/ApiClient';

import type { BaseSettingsProps } from '../model';

import type { BooleanFieldClassKey } from './styles';

export interface BooleanSettingsFieldProps
    extends Omit<BooleanInputProps, 'disabled' | 'disabledReason'>,
        BaseSettingsProps<boolean> {}

export interface BooleanSettingsFieldInnerProps
    extends BooleanSettingsFieldProps,
        InjectedTranslationProps,
        WithStyles<BooleanFieldClassKey> {}

export const BooleanSettingsFieldComponent: React.FunctionComponent<BooleanSettingsFieldInnerProps> = ({
    classes,
    defaultSettingValue,
    disabled,
    disabledReason,
    settingsSourceFieldName,
    settingsSourceRules,
    t,
    ...restInputProps
}) => {
    const {
        field: { onChange: onSettingsValueSourceChange, ref, value: settingsValueSource, ...fieldProps },
        fieldState: { error },
    } = useController({
        name: settingsSourceFieldName,
        rules: settingsSourceRules,
    });
    const { field } = useController({ name: restInputProps.fieldName });

    const onSettingsSourceCheckboxChanged = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onSettingsValueSourceChange(checked ? SettingsValueSource.Default : SettingsValueSource.Asset);
        if (checked) {
            field.onChange(defaultSettingValue);
        }
    };

    return (
        <FormControl className={classes.root} error={!!error}>
            <div className={classes.fieldContainer} data-id={`${restInputProps.fieldName}-container`}>
                <Typography data-id={`vehicle-editor-field-${restInputProps.dataId}`}>
                    {t(`vehicle-editor-field-${restInputProps.dataId}`)}
                </Typography>
                <BooleanInput
                    {...restInputProps}
                    disabled={disabled || settingsValueSource === SettingsValueSource.Default}
                    disabledReason={disabled ? disabledReason : t('default-service-value')}
                />
                <Tooltip
                    data-id={`disabled-tooltip-${restInputProps.dataId}`}
                    placement="right"
                    title={
                        disabled
                            ? disabledReason
                            : settingsValueSource === SettingsValueSource.Default
                              ? t('switch-to-no-default')
                              : t('switch-to-default')
                    }
                >
                    <span>
                        <Checkbox
                            {...fieldProps}
                            checked={settingsValueSource === SettingsValueSource.Default}
                            className={classes.defaultCheckbox}
                            data-id={`${restInputProps.dataId}-default`}
                            disabled={disabled}
                            inputRef={ref}
                            onChange={onSettingsSourceCheckboxChanged}
                        />
                    </span>
                </Tooltip>
            </div>
            {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
    );
};
