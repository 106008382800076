import { useDisplayPreferences } from '~/common/hooks';
import type { MonitoringDriverEntry, MonitoringTrailerEntry, MonitoringVehicleEntry } from '~/data/monitoring';

import { MAP_KEYS } from '../consts';

import { TrailerInfoBox, VehicleInfoBox } from './components';
import { DriverInfoBox } from './components/DriverInfoBox';
import type { AssetMarkerPopupProps } from './models';

const AssetMarkerPopup = <T,>(props: AssetMarkerPopupProps<T>) => {
    const { entry, mapKey } = props;

    const { driverDisplayFormat, trailerDisplayFormat, vehicleDisplayFormat } = useDisplayPreferences();

    switch (mapKey) {
        case MAP_KEYS.driver:
            return (
                <DriverInfoBox
                    driverDisplayNameFormat={driverDisplayFormat}
                    entry={entry as MonitoringDriverEntry}
                    vehicleDisplayFormat={vehicleDisplayFormat}
                />
            );
        case MAP_KEYS.trailer:
            return (
                <TrailerInfoBox
                    driverDisplayNameFormat={driverDisplayFormat}
                    entry={entry as MonitoringTrailerEntry}
                    vehicleDisplayFormat={vehicleDisplayFormat}
                />
            );

        case MAP_KEYS.vehicle:
            return (
                <VehicleInfoBox
                    driverDisplayNameFormat={driverDisplayFormat}
                    entry={entry as MonitoringVehicleEntry}
                    trailerDisplayFormat={trailerDisplayFormat}
                />
            );

        default:
            return null;
    }
};

AssetMarkerPopup.displayName = 'AssetMarkerPopup';
export { AssetMarkerPopup };
