import Lens from '@mui/icons-material/Lens';
import type { WithStyles } from '@mui/styles';
import { divIcon } from 'leaflet';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Marker } from 'react-leaflet';

import type { Position } from '~/services/ApiClient';
import { renderToStaticThemedMarkup } from '~/services/React';

import type { PositionMarkerStyleRules } from './styles';

export interface PositionMarkerProps {
    position: Position;
}

export interface PositionMarkerInnerProps
    extends PositionMarkerProps,
        WithStyles<typeof PositionMarkerStyleRules, true> {}

export const PositionMarkerComponent: FC<PositionMarkerInnerProps> = (props) => {
    const { classes, position, theme } = props;

    const icon = useMemo(
        () =>
            divIcon({
                className: classes.positionIcon,
                html: renderToStaticThemedMarkup(theme, <Lens fontSize="inherit" />),
                iconAnchor: [3, 3],
                iconSize: [6, 6],
            }),
        [classes, theme]
    );

    return <Marker icon={icon} position={[position.latitude, position.longitude]} />;
};
