import Refresh from '@mui/icons-material/Refresh';
import { Button, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { RetryLoadLatestMessagesClassKey } from './styles';

export interface RetryLoadLatestMessagesProps {
    onClick: () => void;
}

export interface RetryLoadLatestMessagesInnerProps
    extends RetryLoadLatestMessagesProps,
        WithStyles<RetryLoadLatestMessagesClassKey>,
        InjectedTranslationProps {}

export const RetryLoadLatestMessagesComponent: FC<RetryLoadLatestMessagesInnerProps> = (props) => {
    const { classes, onClick, t } = props;

    return (
        <div className={classes.root}>
            <Typography className={classes.message} data-id="messages-could-not-be-retrieved" variant="caption">
                {t('messages-could-not-be-retrieved')}
            </Typography>

            <Button color="secondary" data-id="retry-load-conversation" onClick={onClick} variant="contained">
                <Refresh />
                {t('retry')}
            </Button>
        </div>
    );
};
