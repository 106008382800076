import * as React from 'react';

import type { InjectedUserMessagingProfilesProps } from '../EnsureUserMessagingProfiles';

import { ConversationWidget } from './components/ConversationWidget';
import type { OpenedConversation } from './preferences';

export interface ConversationWidgetsProps {
    canLocate: (vehicleId: number) => boolean;
    onLocate: (vehicleId: number) => void;
}

export interface ConversationWidgetStateProps {
    openedConversations: OpenedConversation[];
    shouldOpenConversation: boolean;
    shouldOpenConversationForVehicleId?: number;
}

export interface ConversationWidgetsDispatchProps {
    closeConversation: () => void;
    openConversation: (vehicleId?: number) => void;
    openConversationRejected: () => void;
    openConversationSucceeded: () => void;
}

export interface ConversationWidgetsInnerProps
    extends ConversationWidgetsProps,
        ConversationWidgetsDispatchProps,
        ConversationWidgetStateProps,
        InjectedUserMessagingProfilesProps {}

export class ConversationWidgetsComponent extends React.Component<ConversationWidgetsInnerProps> {
    private handleOnClosed = () => {
        if (!this.props.shouldOpenConversation) {
            this.props.closeConversation();
        } else {
            this.props.openConversationSucceeded();
            this.props.openConversation(this.props.shouldOpenConversationForVehicleId);
        }
    };

    public componentDidUpdate(prevProps: ConversationWidgetsInnerProps): void {
        const { openedConversations, shouldOpenConversation, shouldOpenConversationForVehicleId } = this.props;

        if (
            !prevProps.shouldOpenConversation &&
            shouldOpenConversation &&
            (!openedConversations.length || openedConversations[0].vehicleId === shouldOpenConversationForVehicleId)
        ) {
            this.props.openConversationSucceeded();
            this.props.openConversation(shouldOpenConversationForVehicleId);
        }
    }

    public render(): React.ReactNode {
        const { openedConversations, shouldOpenConversation, shouldOpenConversationForVehicleId } = this.props;
        if (!openedConversations.length) {
            return null;
        }

        const firstOpenedConversation = openedConversations[0];

        const shouldClose =
            shouldOpenConversation && shouldOpenConversationForVehicleId !== firstOpenedConversation.vehicleId;

        return (
            <ConversationWidget
                canLocate={this.props.canLocate}
                isMinimized={firstOpenedConversation.isMinimized}
                key={firstOpenedConversation.vehicleId}
                onClosed={this.handleOnClosed}
                onCloseRejected={this.props.openConversationRejected}
                onLocate={this.props.onLocate}
                shouldClose={shouldClose}
                userConversationProfileId={this.props.userConversationProfileId}
                vehicleId={firstOpenedConversation.vehicleId}
            />
        );
    }
}
