import { List } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { DriverActivityEntry } from '../../../../../../models';

import { ActivityDetailsListItem } from './components/ActivityDetailsListItem';
import type { ActivityDetailsClassKey } from './styles';

export interface ActivityDetailsProps {
    activityEntries: DriverActivityEntry[];
    className?: string;
}

export interface ActivityDetailsInnerProps extends ActivityDetailsProps, WithStyles<ActivityDetailsClassKey> {}

export const ActivityDetailsComponent: React.FC<ActivityDetailsInnerProps> = ({
    activityEntries,
    classes,
    className,
}) => {
    const listItems = activityEntries.map((entry, index) => {
        const listItemClassName = classNames({ [classes.listItemWithMargin]: index > 0 });
        return <ActivityDetailsListItem activityEntry={entry} className={listItemClassName} key={entry.id} />;
    });

    return (
        <List className={classNames(classes.root, className)} data-id="activities-list">
            {listItems}
        </List>
    );
};
