import { Box, Tooltip } from '@mui/material';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import { formatMessageStatus } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';
import type { MessageStatusFormatterProps } from './models';

const MessageStatusFormatter: FC<MessageStatusFormatterProps> = ({ iconProps, t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    const title = formatMessageStatus(t, value);
    return (
        <Tooltip title={title}>
            <Box data-id={`message-status:${value}`} display="inline-flex">
                <IconFormatter iconProps={iconProps} value={value} />
            </Box>
        </Tooltip>
    );
};

MessageStatusFormatter.displayName = 'MessageStatusFormatter';
export default MessageStatusFormatter;
