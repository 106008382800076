import type { NumericDictionary } from '~/libs/utility';
import type {
    Message,
    ResolvedMessage,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
} from '~/services/ApiClient';
import {
    IncomingMessage,
    IncomingMessageStatus,
    MessageDirection,
    MessageStatus,
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    OutgoingMessage,
    OutgoingMessageStatus,
} from '~/services/ApiClient';

import { resolveConversationMessageBody } from './reducers.resolveConversationMessageBody';

export const mapIncomingMessageStatus = (status: IncomingMessageStatus): MessageStatus => {
    switch (status) {
        case IncomingMessageStatus.Read:
            return MessageStatus.IncomingRead;
        case IncomingMessageStatus.Received:
            return MessageStatus.Received;
        default:
            throw new Error('Unknow status');
    }
};

export const mapOutgoingMessageStatus = (status: OutgoingMessageStatus): MessageStatus => {
    switch (status) {
        case OutgoingMessageStatus.Delivered:
            return MessageStatus.Delivered;
        case OutgoingMessageStatus.Failed:
            return MessageStatus.Failed;
        case OutgoingMessageStatus.Pending:
            return MessageStatus.Pending;
        case OutgoingMessageStatus.Read:
            return MessageStatus.OutgoingRead;
        case OutgoingMessageStatus.Scheduled:
            return MessageStatus.Scheduled;
        default:
            throw new Error('Unknow status');
    }
};

export const mapOptimisticOutgoingMessageStatus = (
    status: OptimisticOutgoingConversationMessageStatus
): MessageStatus => {
    switch (status) {
        case OptimisticOutgoingConversationMessageStatus.GeneralFailure:
            return MessageStatus.GeneralFailure;
        case OptimisticOutgoingConversationMessageStatus.Pending:
            return MessageStatus.Pending;
        case OptimisticOutgoingConversationMessageStatus.Sending:
            return MessageStatus.Sending;
        case OptimisticOutgoingConversationMessageStatus.ValidationFailure:
            return MessageStatus.ValidationFailure;
        default:
            throw new Error('Unknow status');
    }
};

export const resolveConversationMessage = (
    message: Message,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): ResolvedMessage => {
    let direction: MessageDirection;
    let status: MessageStatus;
    if (message instanceof IncomingMessage) {
        status = message.isDeleted ? MessageStatus.Deleted : mapIncomingMessageStatus(message.status);
        direction = MessageDirection.Incoming;
    } else if (message instanceof OutgoingMessage) {
        status = message.isDeleted ? MessageStatus.Deleted : mapOutgoingMessageStatus(message.status);
        direction = MessageDirection.Outgoing;
    } else if (message instanceof OptimisticOutgoingConversationMessage) {
        status = message.isDeleted ? MessageStatus.Deleted : mapOptimisticOutgoingMessageStatus(message.status);
        direction = MessageDirection.Outgoing;
    } else {
        throw new Error('Unsupported message type');
    }

    return {
        body: resolveConversationMessageBody(message.body, workflowFormDefinitions),
        direction,
        replyToMessage: message.replyToMessage
            ? resolveConversationMessage(message.replyToMessage, workflowFormDefinitions)
            : undefined,
        status,
        value: message,
    };
};
