import type { CrudActions } from '@fv/components/Dialogs';

import type { TriggerDataRefresh } from '~/common';
import type { ResolvedAdminVehicleRead } from '~/services/ApiClient';

import type { VehicleAdministrationReduxProps } from './redux';

export interface VehicleAdministrationEntry {
    adminVehicle: ResolvedAdminVehicleRead;
}

export enum Subpage {
    CHANGELOGS = 'changelogs',
}

export interface VehicleUrlParams {
    subpage?: Subpage;
    vehicleId?: string;
}

export interface DialogArgs {
    action: CrudActions;
    vehicle?: ResolvedAdminVehicleRead;
}

export interface VehicleAdministrationProps {}
export interface VehicleAdministrationInnerProps extends VehicleAdministrationProps, VehicleAdministrationReduxProps {}

export interface VehicleAdministrationComponentState {
    subpage?: Subpage;
    triggerDataRefresh: TriggerDataRefresh;
}
