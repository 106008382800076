import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { formatIgnitionStatus } from '~/services/Formatters';

import { SectionName } from '../../constants';

export interface MetadataSectionProps {
    dragHandleElement: JSX.Element;
    ignitionStatus?: boolean | undefined;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface MetadataSectionInnerProps extends MetadataSectionProps, InjectedTranslationProps {}

export const MetadataSectionComponent: React.FC<MetadataSectionInnerProps> = ({
    dragHandleElement,
    ignitionStatus,
    isCollapsed,
    t,
    toggleCollapsed,
}) => {
    const keyValueItems: KeyValueItem[] = [
        {
            label: t('ignition'),
            value: <span>{formatIgnitionStatus(t, ignitionStatus)}</span>,
            valueDataId: 'ignition-status',
        },
    ];
    return (
        <ReorderableSection
            dataId={SectionName.METADATA}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('metadata')}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="metadata-list" />
        </ReorderableSection>
    );
};
