import { actions } from './actionTypes';
import type { ActionTypes, ReportSettingsState } from './models';

const initialState: ReportSettingsState = {
    error: false,
    items: [],
    pending: false,
    template: {
        error: false,
        item: undefined,
        pending: false,
    },
};

const reportSettingsReducer = (state = initialState, action: ActionTypes): ReportSettingsState => {
    switch (action.type) {
        case actions.LIST_PENDING:
            return { ...state, pending: true };
        case actions.LIST_FULFILLED:
            return { ...state, items: action.payload.items };
        case actions.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case actions.TEMPLATE_PENDING:
            return { ...state, template: { ...state.template, pending: true } };
        case actions.TEMPLATE_FULFILLED:
            return { ...state, template: { ...state.template, item: action.payload.item, pending: false } };
        case actions.TEMPLATE_REJECTED:
            return { ...state, template: { ...state.template, error: true, pending: false } };

        default:
            return state;
    }
};

export { reportSettingsReducer };
