import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandableContainer, ExpandableContent, FooterButton, FooterContainer } from './styles';

const ExpandableMessagePreview: FC = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    const onExpandClick = () => {
        setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    };

    return (
        <>
            <ExpandableContainer collapsedSize={60} data-testid="expandable-message-preview-container" in={isExpanded}>
                <ExpandableContent expanded={isExpanded}>{children}</ExpandableContent>
            </ExpandableContainer>
            <FooterContainer>
                <FooterButton
                    data-testid="expandable-message-preview-expand-button"
                    disableRipple
                    onClick={onExpandClick}
                    size="small"
                >
                    {isExpanded ? t('see-less') : t('see-more')}
                </FooterButton>
            </FooterContainer>
        </>
    );
};

ExpandableMessagePreview.displayName = 'ExpandableMessagePreview';
export { ExpandableMessagePreview };
