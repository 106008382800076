import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type NoOlderMessagesToDisplayClassKey = 'noOlderMessagesToDisplay';

export const NoOlderMessagesToDisplayStyleRules: StyleRulesCallback<Theme, {}, NoOlderMessagesToDisplayClassKey> = (
    theme: Theme
) =>
    createStyles({
        noOlderMessagesToDisplay: {
            background: theme.palette.warning.light,
            display: 'inline-block',
            fontStyle: 'italic',
            padding: theme.spacing(0.5, 1.5),
        },
    });
