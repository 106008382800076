import _ExpandMore from '@mui/icons-material/ChevronRight';
import _ExpandLess from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

interface ContainerProps {
    isRoot: boolean;
}
const Container = styled('div')<ContainerProps>(({ isRoot, theme }) => ({
    paddingLeft: isRoot ? 0 : theme.spacing(3),
}));
const TreeRowContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(),
}));

interface TreeRowItemProps {
    flexGrow?: number;
    order: number;
}
const TreeRowItem = styled('div')<TreeRowItemProps>(({ flexGrow, order }) => ({ flexGrow, order }));

interface ToggleExpandActionProps {
    isDisable: boolean;
}
const ExpandMore = styled(_ExpandMore, {
    shouldForwardProp: (propName) => propName !== 'isDisable',
})<ToggleExpandActionProps>(({ isDisable, theme }) => ({
    color: isDisable ? theme.palette.text.disabled : 'unset',
}));

const ExpandLess = styled(_ExpandLess, {
    shouldForwardProp: (propName) => propName !== 'isDisable',
})<ToggleExpandActionProps>(({ isDisable, theme }) => ({
    color: isDisable ? theme.palette.text.disabled : 'unset',
}));

export { Container, ExpandLess, ExpandMore, TreeRowContainer, TreeRowItem };
