import type { FuelValue, UnitSystem } from '@fv/converters';
import { convertFuel, getFuelUnit } from '@fv/converters';

interface FormatFuelArgs {
    precision?: number;
    unitSystem: UnitSystem;
    value: FuelValue;
}

const formatFuel = (args: FormatFuelArgs) => {
    return `${convertFuel(args).toFixed(args.precision)} ${getFuelUnit(args.unitSystem, args.value.type)}`;
};

export { formatFuel };
export type { FormatFuelArgs };
