import type { TFunction } from 'react-i18next';

import { DisplayUserPreferencesUnitSystem } from '../ApiClient';

const ADORNAMENT_KEYS = {
    litre: 'litre',
    temp: 'temp',
} as const;

type AdornamentKeys = (typeof ADORNAMENT_KEYS)[keyof typeof ADORNAMENT_KEYS];

interface AdornamentFormatProps {
    key: AdornamentKeys;
    t: TFunction<'translation', undefined>;
    unitSystem: DisplayUserPreferencesUnitSystem;
}

const getLiterAdornament = (unitSystem: DisplayUserPreferencesUnitSystem, t: TFunction<'translation', undefined>) =>
    unitSystem === DisplayUserPreferencesUnitSystem.Imperial ? t('unit-gal') : t('unit-l');

const getTempAdornament = (unitSystem: DisplayUserPreferencesUnitSystem, t: TFunction<'translation', undefined>) =>
    unitSystem === DisplayUserPreferencesUnitSystem.Imperial ? t('unit-fahrenheit') : t('unit-celsius');

const getAdornamentFormat = ({ key, t, unitSystem }: AdornamentFormatProps) => {
    switch (key) {
        case ADORNAMENT_KEYS.litre:
            return getLiterAdornament(unitSystem, t);

        case ADORNAMENT_KEYS.temp:
            return getTempAdornament(unitSystem, t);

        default:
            throw Error(`${key} is not implemented`);
    }
};

export { ADORNAMENT_KEYS, getAdornamentFormat };
