import type { NumericDictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { Customer } from '~/services/ApiClient';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface CustomerAutocompleteStoreState {
    customers: RetrievableData<NumericDictionary<Customer>>;
}

export const defaultStoreState: CustomerAutocompleteStoreState = {
    customers: {
        data: {},
        fulfilled: false,
        pending: false,
        rejected: false,
    },
};

export const customerAutocompleteReducer = (
    state: CustomerAutocompleteStoreState = defaultStoreState,
    action: ActionTypes
): CustomerAutocompleteStoreState => {
    switch (action.type) {
        case ActionTypeKeys.CUSTOMERS_PENDING:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    pending: true,
                },
            };
        case ActionTypeKeys.CUSTOMERS_FULFILLED:
            return {
                ...state,
                customers: {
                    data: keyBy(action.payload.items, 'id'),
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
            };
        case ActionTypeKeys.CUSTOMERS_REJECTED:
            return {
                ...state,
                customers: {
                    ...defaultStoreState.customers,
                    rejected: true,
                },
            };

        default:
            return state;
    }
};
