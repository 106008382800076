import type { StaticDataStoreState } from '~/common';
import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ResolvedVehicleChangelogChanges, VehicleChangelog } from '~/services/ApiClient';

import { getResolvedVehicleChangelogChanges } from './getResolvedVehicleChangelogChanges';

export const resolveVehicleChangelogsEntries = (
    vehicleChangelogs: VehicleChangelog[],
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): ChangelogEntry<ResolvedVehicleChangelogChanges>[] => {
    return vehicleChangelogs.map((vehicleAdministrationChangelog) => {
        return {
            action: vehicleAdministrationChangelog.action,
            after: getResolvedVehicleChangelogChanges(
                settingsStoreState,
                staticDataStoreState,
                vehicleAdministrationChangelog.after
            ),
            before: getResolvedVehicleChangelogChanges(
                settingsStoreState,
                staticDataStoreState,
                vehicleAdministrationChangelog.before
            ),
            dateTime: vehicleAdministrationChangelog.dateTime,
            id: vehicleAdministrationChangelog.id,
            impersonator: vehicleAdministrationChangelog.impersonator,
            username: vehicleAdministrationChangelog.username,
        };
    });
};
