import { TextField, Typography } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { UnresolvedWorkflowMessageBody } from '~/services/ApiClient';

import { useStyles } from './styles';

export interface UnresolvedConversationWorkflowBodyProps {
    messageBody: UnresolvedWorkflowMessageBody;

    showEmptyFields?: boolean;
}

export interface UnresolvedConversationWorkflowBodyInnerProps
    extends UnresolvedConversationWorkflowBodyProps,
        InjectedTranslationProps {}

export const UnresolvedConversationWorkflowBodyComponent: React.FC<UnresolvedConversationWorkflowBodyInnerProps> = ({
    messageBody,
    showEmptyFields,
    t,
}) => {
    const classes = useStyles();

    const renderFields = messageBody.fieldValues.map((field, index) => {
        if (showEmptyFields || !isNil(field.value)) {
            return (
                <div className={classes.element} data-id={`wf-field:${index}`} key={field.name}>
                    <TextField
                        data-id={`wf-input:${index}`}
                        fullWidth
                        InputProps={{
                            classes: {
                                disabled: classes.readOnlyUnderlineDisabled,
                                root: classes.readOnlyRoot,
                                underline: classes.readOnlyUnderline,
                            },
                            readOnly: true,
                        }}
                        label={field.name}
                        size="small"
                        value={field.value}
                        variant="filled"
                    />
                </div>
            );
        } else {
            return null;
        }
    });

    const isBodyEmpty = renderFields.every((x) => isNil(x));

    const renderBody = isBodyEmpty ? (
        <Typography
            align="center"
            color="textSecondary"
            data-id="turn-off-emty-fields-label"
            display="block"
            variant="caption"
        >
            {t('workflow-body-no-fields-to-show')}
        </Typography>
    ) : (
        renderFields
    );

    return (
        <div className={classes.body} data-id="unresolved-workflow-body">
            {renderBody}
        </div>
    );
};
