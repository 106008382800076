import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DriverSubpageHeaderClassKey =
    | 'actionButtons'
    | 'avatarIcons'
    | 'driverName'
    | 'placeHolderElement'
    | 'root'
    | 'vehicleInfo';

export const DriverSubpageHeaderStyleRules: StyleRulesCallback<Theme, {}, DriverSubpageHeaderClassKey> = (
    theme: Theme
) =>
    createStyles({
        actionButtons: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        avatarIcons: {
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(),
            marginTop: theme.spacing(),
        },
        driverName: {
            alignItems: 'center',
            display: 'flex',
            flexFlow: 'column',
            fontWeight: theme.typography.fontWeightMedium,
        },
        placeHolderElement: {
            display: 'flex',
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 50,
        },
        root: {
            background: theme.palette.background.default,
            padding: theme.spacing(),
        },
        vehicleInfo: {
            alignItems: 'center',
            display: 'flex',
            flexFlow: 'column',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(0.5),
        },
    });
