import type { Duration } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DateTimeFormatter,
    DurationFormatter,
    ViolationFieldFormatterFactory,
    ViolationType,
} from '~/components/Formatters';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { DriverActivityType, DurationViolationField } from '~/services/ApiClient';
import {
    formatDriverActivity,
    formatDriverHoursLoginStatus,
    formatDriverSubpageListItemLabel,
    formatDuration,
} from '~/services/Formatters';
import { parseOptionalDurationViolationField } from '~/services/Parsers';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';

import { useStyles } from './styles';

const GeneralSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const {
        activity,
        activityStartDateTime,
        cardStatus,
        continuousWorkWait,
        duration,
        operationalWeekDuration,
        shiftDuration,
        subactivity,
    } = props.driverStatus;
    const classes = useStyles();
    const { t } = useTranslation();

    const ViolationDurationFormatter = useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    return (
        <ReorderableSection
            dataId={SectionName.GENERAL}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.GENERAL}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList
                list={[
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-last-activity'),
                        value: activity && (
                            <span className={classes.simpleValue} data-id="last-activity">
                                {formatDriverActivity(t, activity as DriverActivityType)}
                            </span>
                        ),
                        valueDataId: 'last-activity',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-last-sub-activity'),
                        value: subactivity && (
                            <span className={classes.simpleValue} data-id="last-subactivity">
                                {subactivity.name}
                            </span>
                        ),
                        valueDataId: 'last-subactivity',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-date-time'),
                        value: activityStartDateTime ? (
                            <span className={classes.simpleValue} data-id="start-time">
                                <DateTimeFormatter value={activityStartDateTime as Date} />
                            </span>
                        ) : undefined,
                        valueDataId: 'start-time',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-duration'),
                        value: duration ? (
                            <DurationFormatter
                                threshold={moment.duration()}
                                value={moment.duration(duration)}
                                violationType={ViolationType.ABOVE}
                            />
                        ) : undefined,
                        valueDataId: 'duration',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-current-shift'),
                        value: shiftDuration && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'general-current-shift')}
                                t={t}
                                value={parseOptionalDurationViolationField(shiftDuration as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'current-shift-duration',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-continuous-work-available'),
                        value: continuousWorkWait && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'general-continuous-work-available')}
                                t={t}
                                value={parseOptionalDurationViolationField(
                                    continuousWorkWait as DurationViolationField
                                )}
                            />
                        ),
                        valueDataId: 'continuous-work-available',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-operational-week-duration'),
                        value: operationalWeekDuration && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'general-operational-week-duration')}
                                t={t}
                                value={parseOptionalDurationViolationField(
                                    operationalWeekDuration as DurationViolationField
                                )}
                            />
                        ),
                        valueDataId: 'operational-week-duration',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'general-login-manual-status'),
                        value: !isUndefined(cardStatus) ? (
                            <span className={classes.simpleValue} data-id="login-manual-status">
                                {formatDriverHoursLoginStatus(t, cardStatus)}
                            </span>
                        ) : undefined,
                        valueDataId: 'login-manual-status',
                    },
                ]}
                listDataId="property-list"
            />
        </ReorderableSection>
    );
};

GeneralSection.displayName = 'GeneralSection';
export default GeneralSection;
