import CalendarToday from '@mui/icons-material/CalendarToday';
import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC, ReactNode } from 'react';

import { DateTimeSpanFormatter } from '~/components/Formatters';
import { LabeledIndicator } from '~/components/Indicators';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { SummarySectionContentClassKey } from './styles';

export interface SummarySectionContentProps {
    dateSpan: { start: Date | undefined; stop: Date | undefined };
    primaryValueDistance: ReactNode;
    primaryValueFuel: ReactNode;
    secondaryValueDistance: string | undefined;
    secondaryValueFuel: string | undefined;
}

export interface SummarySectionContentInnerProps
    extends SummarySectionContentProps,
        InjectedTranslationProps,
        WithStyles<SummarySectionContentClassKey> {}

export const SummarySectionContentComponent: FC<SummarySectionContentInnerProps> = (props) => {
    const { classes, dateSpan, primaryValueDistance, primaryValueFuel, secondaryValueDistance, secondaryValueFuel, t } =
        props;

    const distanceIndicator = (
        <LabeledIndicator
            dataId="distance-indicator"
            primaryText={primaryValueDistance}
            secondaryText={secondaryValueDistance}
            title={t('distance')}
        />
    );
    const fuelIndicator = (
        <LabeledIndicator
            dataId="fuel-indicator"
            primaryText={primaryValueFuel}
            secondaryText={secondaryValueFuel}
            title={t('fuel')}
        />
    );

    const timestamp = (
        <Typography
            className={classes.summaryTimeDetails}
            color="textSecondary"
            component="div"
            data-id="timestamp"
            variant="caption"
        >
            <CalendarToday className={classes.calendarIcon} />
            <DateTimeSpanFormatter value={dateSpan} />
        </Typography>
    );
    const content = (
        <div className={classes.flex}>
            {distanceIndicator}
            {fuelIndicator}
        </div>
    );
    return (
        <div className={classes.summaryContentContainer}>
            {content}
            {timestamp}
        </div>
    );
};
