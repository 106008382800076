import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    cell: {
        fontSize: '12px',
        padding: theme.spacing(0, 1),
    },
    cellText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
