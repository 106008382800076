import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DurationClassKey = 'root' | 'violation';

export const DurationStyleRules: StyleRulesCallback<Theme, {}, DurationClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            marginRight: theme.spacing(0.5),
        },
        violation: {
            backgroundColor: theme.palette.error.main,
            borderRadius: 3,
            color: theme.palette.error.contrastText,
            marginRight: theme.spacing(0),
            paddingBottom: theme.spacing(0.25),
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            paddingTop: theme.spacing(0.25),
        },
    });
