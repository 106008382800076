import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics, withScreenAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import type { HistoryInnerProps, HistoryProps, HistoryReduxProps } from './component';
import { HistoryComponent } from './component';
import {
    requiredHistoryDriverDetailsPaneUserPreferences,
    requiredHistoryTrailerDetailsPaneUserPreferences,
    requiredHistoryVehicleDetailsPaneUserPreferences,
} from './components/DetailsPane';
import {
    HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY,
    HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY,
    HISTORY_USERPREFERENCES_KEY,
    HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY,
    requiredHistoryUserPreferences,
} from './preferences';
import type { HistoryStateProps } from './redux';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';
import { dynamicHistoryStateSelector, historyDateTimeRangeStateSelector, historyStateSelector } from './selectors';
import { HistoryStyleRules } from './styles';

export type { HistoryProps, HistoryUrlParams } from './component';
export * from './models';
export { driverEventResolver } from './redux.driverEventResolver';

export * from './storestate';

export const History = compose<HistoryInnerProps, HistoryProps>(
    setDisplayName('History'),
    protectedComponentHoc,
    withMenu,
    ensureSettings([
        SettingsKey.SECURABLES,
        SettingsKey.DEVICETYPES,
        SettingsKey.VEHICLECATEGORIES,
        SettingsKey.VEHICLETYPES,
        SettingsKey.DRIVERACTIVITYTYPES,
        SettingsKey.DRIVERSUBACTIVITYTYPES,
        SettingsKey.DRIVEREVENTTYPES,
        SettingsKey.REEFER_STATUS,
        SettingsKey.REEFER_ALARM,
        SettingsKey.REEFER_OPERATION_MODE,
        SettingsKey.REEFER_POWER_MODE,
        SettingsKey.REEFER_SPEED,
        SettingsKey.COMPARTMENT_STATUS,
        SettingsKey.DOOR_STATUS,
        SettingsKey.TRAILER_BATTERY_STATUS,
        SettingsKey.TRAILER_EVENT_TYPE,
        SettingsKey.TRAILER_BATTERY_TYPE,
        SettingsKey.REEFER_BATTERY_TYPE,
        SettingsKey.TRAILER_MANUFACTURERS,
        SettingsKey.REEFER_MANUFACTURERS,
    ]),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredSceneAssetSelectorUserPreferencesFactory(HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredSceneAssetSelectorUserPreferencesFactory(HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredHistoryUserPreferences,
        requiredHistoryVehicleDetailsPaneUserPreferences,
        requiredHistoryTrailerDetailsPaneUserPreferences,
        requiredHistoryDriverDetailsPaneUserPreferences,
    ]),
    withTranslation(),
    withStyles(HistoryStyleRules),
    withDisplayPreferences(),
    connect<HistoryStateProps>(
        createSelector(
            staticDataStoreStateSelector,
            dynamicHistoryStateSelector,
            keyDataStateSelectorFactory(HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(HISTORY_USERPREFERENCES_KEY),
            settingsStoreStateSelector,
            historyDateTimeRangeStateSelector,
            historyStateSelector,
            mapStateToProps
        ),
        mapDispatchToProps,
        mergeProps
    ),
    withSceneAnalytics('history'),
    withScreenAnalytics<HistoryReduxProps>((p) => `history/${p.eventTypeFromUrl}`)
)(HistoryComponent);
