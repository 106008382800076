import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { PositionFieldWorkflowFormElement } from '~/services/ApiClient';
import { Position } from '~/services/ApiClient';
import { isValidPosition } from '~/services/Parsers';

import { PositionInput } from './components/PositionInput';

export interface PositionWorkflowFieldProps {
    dataId: string;
    disabled?: boolean;
    field: PositionFieldWorkflowFormElement;
    label?: string;
}

export interface PositionWorkflowFieldInnerProps extends PositionWorkflowFieldProps, InjectedTranslationProps {}

export const PositionWorkflowFieldComponent: React.FC<PositionWorkflowFieldInnerProps> = ({
    dataId,
    disabled,
    field,
    label,
    t,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const controllerProps = {
        control,
        defaultValue: '',
        name: `${field.id}`,
        rules: {
            required: field.isRequired,
            validate: {
                'invalid-position': (value: unknown) =>
                    value instanceof Position ? isValidPosition(value) : isNil(value),
            },
        },
    };

    const error = errors[controllerProps.name];
    const errorMessage = error ? error.message || t(`wf-field-error-${error.type}`) : undefined;
    const helperText = (errorMessage as string) ?? (field.isRequired ? t('wf-field-error-required') : '');

    return (
        <Controller
            {...controllerProps}
            render={({ field: fieldFormProps }) => (
                <PositionInput
                    {...fieldFormProps}
                    dataId={dataId}
                    disabled={disabled}
                    error={!!error}
                    helperText={helperText}
                    label={label}
                    position={fieldFormProps.value}
                    readOnly={field.isReadOnly}
                    required={field.isRequired}
                />
            )}
        />
    );
};
