import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';

export type TitledTruncatedTextClassKey = 'text';

export const TitledTruncatedTextStyleRules: StyleRulesCallback<Theme, {}, TitledTruncatedTextClassKey> = () => {
    return {
        text: {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
};
