import type { ActionItem } from '@fv/components';
import { ContextMenu } from '@fv/components';
import { CRUD_ACTIONS, isDeleteDialog, isEditDialog } from '@fv/components/Dialogs';
import { useNestedRouteParams } from '@fv/routing';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetSelector, StaticDataType } from '~/common';
import { useContextMenu, useEnsureData } from '~/common/hooks';
import { AskForPageReload } from '~/components/AskForPageReload';
import { SettingsKey } from '~/components/EnsureSettings';
import { ChangelogIcon } from '~/components/Icons';
import { PageLoader } from '~/components/PageLoader';
import { PageTemplate } from '~/components/PageTemplate';
import { RefreshDataBanner } from '~/components/RefreshBanner';
import type { DetailsPaneContextProps, DetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { DETAILSPANE_USERPREFERENCES_KEY, DetailsPaneContext } from '~/components/SceneDetailsPane';
import { SceneLoader } from '~/components/SceneLoader';
import { useUserPreferences } from '~/components/UserPreferences';
import { getVehicleDefaultSettingsAction, retrieveVehicleAdministrationAction } from '~/data/vehicleadministration';
import { isEmpty, isNil, isUndefined } from '~/libs/utility';
import { logEvent } from '~/services/Analytics';
import { AssetType } from '~/services/ApiClient';

import { DeleteVehicleDialog } from './components/DeleteVehicleDialog';
import { VehicleAdministrationDetailsPane } from './components/DetailsPane';
import { VehicleAdministrationChangelogsSubpage } from './components/DetailsPane/components/VehicleAdministrationChangelogsSubpage';
import { VehicleAdministrationListView, VehicleAdministrationListViewHeader } from './components/List';
import { VehicleEditorDialog } from './components/VehicleEditorDialog';
import { NESTED_ROUTE } from './consts';
import { clearDataAction } from './data';
import type { DialogArgs, VehicleAdministrationInnerProps, VehicleUrlParams } from './models';
import { Subpage } from './models';
import type { AdminVehicleUserPreferences } from './preferences';
import { ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY, ADMINVEHICLE_USERPREFERENCES_KEY } from './preferences';
import { Container } from './styles';

const VehicleAdministrationComponent: FC<VehicleAdministrationInnerProps> = (props) => {
    const { dataSource, servicesSecurables, vehicleAdministrationAuthorizations } = props;

    const { t } = useTranslation();
    const { error, isFetching, isInitialLoad, reloadData, reloadStaticData } = useEnsureData({
        clearActions: [clearDataAction],
        lastActions: [retrieveVehicleAdministrationAction, getVehicleDefaultSettingsAction],
        settings: [
            SettingsKey.SECURABLES,
            SettingsKey.DEVICETYPES,
            SettingsKey.VEHICLETYPES,
            SettingsKey.VEHICLECATEGORIES,
            SettingsKey.NETWORK,
            SettingsKey.BILLING_GROUP,
            SettingsKey.BILLING_STATUS,
            SettingsKey.TACHOGRAPH,
            SettingsKey.TACHO_PROTOCOL,
            SettingsKey.TACHO_GENERATION,
            SettingsKey.WORKFLOW_TYPE,
            SettingsKey.IMAGE_CAPTURING_TYPE,
            SettingsKey.NAVIGATION_TYPE,
            SettingsKey.INHIBITOR_SERVICE_TYPE,
            SettingsKey.TELL_TALE_SERVICE_TYPE,
            SettingsKey.FUEL_TANK_TYPE,
        ],
        staticData: [
            StaticDataType.ASSETGROUPS,
            StaticDataType.VEHICLES,
            StaticDataType.DEPOTS,
            StaticDataType.DEVICES,
        ],
    });
    const [{ keepSubPageOpen }] = useUserPreferences<DetailsPaneUserPreferences>(DETAILSPANE_USERPREFERENCES_KEY);
    const [userPreferences, setUserPreferences] = useUserPreferences<AdminVehicleUserPreferences>(
        ADMINVEHICLE_USERPREFERENCES_KEY
    );
    const { currentClicked, handleClose, handleRowClick, isOpen, position, prevClicked } = useContextMenu<number>();
    const [dialogs, setDialogs] = useState<DialogArgs>();

    const [{ subpage, vehicleId: vehicleIdParam }, setRouteParams] = useNestedRouteParams<VehicleUrlParams>({
        defaultParams: {},
        path: NESTED_ROUTE,
    });

    const selectedVehicleId = isUndefined(vehicleIdParam) ? undefined : Number(vehicleIdParam);

    const forceLeftPaneIsOpen = isEmpty(dataSource);
    const leftPaneIsOpen = forceLeftPaneIsOpen || userPreferences.leftPaneIsOpen;
    const handleToggleLeftPane = forceLeftPaneIsOpen
        ? undefined
        : () => setUserPreferences({ leftPaneIsOpen: !leftPaneIsOpen });

    const refreshData = () => {
        reloadStaticData(true);
        reloadData();
    };

    const onCloseHandle = (isCanceled: boolean) => {
        if (!isCanceled) {
            refreshData();
        }
        setDialogs(undefined);
    };

    const getActions = (id: number): ActionItem[] => {
        const vehicle = dataSource.find((entry) => entry.id === id);

        if (!vehicle) {
            return [];
        }

        return [
            vehicleAdministrationAuthorizations.vehicleModification && {
                icon: <EditIcon />,
                key: 'modify-admin-vehicle',
                onClick: () => setDialogs({ action: CRUD_ACTIONS.EDIT, vehicle }),
                title: t('modify-action'),
            },
            vehicleAdministrationAuthorizations.vehicleCreation && {
                icon: <FileCopyIcon />,
                key: 'clone-admin-vehicle',
                onClick: () => setDialogs({ action: CRUD_ACTIONS.CLONE, vehicle }),
                title: t('clone'),
            },
            {
                icon: <ChangelogIcon />,
                key: 'changelog-admin-vehicle',
                onClick: () => {
                    logEvent('vehicle admin', 'open-vehicle-change-log', 'Open vehicle change log');
                    setRouteParams({ subpage: Subpage.CHANGELOGS, vehicleId: id.toString() });
                },
                title: t('open-changelogs-subpage-action'),
            },
            vehicleAdministrationAuthorizations.vehicleDeletion && {
                disabled: !isNil(vehicle?.device) || !!vehicle?.device,
                icon: <CancelIcon />,
                key: 'delete-admin-vehicle',
                onClick: () => setDialogs({ action: CRUD_ACTIONS.DELETE, vehicle }),
                title: t('delete-action'),
                tooltip:
                    !isNil(vehicle?.device) || !!vehicle?.device ? t('delete-admin-vehicle-disable-reason') : undefined,
            },
        ].filter(Boolean) as ActionItem[];
    };

    const selectVehicleAdministrationCallback = (selectedAdminVehicleId: number) => {
        setRouteParams({
            subpage: keepSubPageOpen ? subpage : undefined,
            vehicleId: selectedAdminVehicleId.toString(),
        });
    };

    const closeDetailsPane = () => {
        setRouteParams({});
    };

    const selectedEntry = useMemo(() => {
        if (isUndefined(selectedVehicleId)) {
            return undefined;
        }
        const resolved = dataSource.find((entry) => entry.id === selectedVehicleId);

        return resolved;
    }, [selectedVehicleId, dataSource]);

    const getSubpageContext = useMemo(() => {
        const subpageContext: DetailsPaneContextProps = {
            close: () => {
                setRouteParams({});
            },
            goBack: () => {
                setRouteParams({ vehicleId: selectedVehicleId?.toString() });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            openSubpage: () => {},
        };
        return subpageContext;
    }, [setRouteParams, selectedVehicleId]);

    const detailsPane = (() => {
        if (selectedEntry && subpage === Subpage.CHANGELOGS) {
            return (
                <DetailsPaneContext.Provider value={getSubpageContext}>
                    <VehicleAdministrationChangelogsSubpage vehicle={{ adminVehicle: selectedEntry }} />
                </DetailsPaneContext.Provider>
            );
        }

        return (
            selectedEntry && (
                <VehicleAdministrationDetailsPane
                    entry={{ adminVehicle: selectedEntry }}
                    headerActions={getActions(selectedEntry.id)}
                    onClose={closeDetailsPane}
                    vehicleAdministrationServices={servicesSecurables}
                />
            )
        );
    })();

    if (isInitialLoad) {
        if (error) {
            return <AskForPageReload />;
        }

        if (isFetching) {
            return <SceneLoader />;
        }
    }

    return (
        <Container data-id="vehicle-administration">
            <PageTemplate
                header={
                    <VehicleAdministrationListViewHeader
                        canCreate={vehicleAdministrationAuthorizations.vehicleCreation}
                        contentLoading={isFetching}
                        dataSource={dataSource}
                        onRefreshData={refreshData}
                        openCreateVehicleEditorDialog={() => setDialogs({ action: CRUD_ACTIONS.CREATE })}
                        title={t('vehicle-admin-module')}
                    />
                }
                leftPaneElement={
                    leftPaneIsOpen ? (
                        <AssetSelector
                            assetTypes={[AssetType.Vehicle]}
                            preferencesKey={ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY}
                        />
                    ) : undefined
                }
                leftPaneToggleTitle={leftPaneIsOpen ? t('collapse-asset-selector') : t('expand-asset-selector')}
                rightPaneElement={detailsPane}
                toggleLeftPaneVisibility={handleToggleLeftPane}
                topPaneElement={
                    isFetching || error ? (
                        <RefreshDataBanner isLoading={isFetching} isRejected={error} onRefreshData={refreshData} />
                    ) : undefined
                }
            >
                <PageLoader
                    emptyMessage={t('no-assets-selected-in-asset-selector', { assetType: t('vehicles') })}
                    isEmpty={isEmpty(dataSource)}
                    isInitialLoad={isInitialLoad}
                    isLoading={isFetching}
                >
                    <VehicleAdministrationListView
                        dataSource={dataSource}
                        highlightedAssetId={currentClicked}
                        onRowContextMenu={handleRowClick}
                        selectedAssetId={selectedVehicleId}
                        selectRow={selectVehicleAdministrationCallback}
                    />
                </PageLoader>
            </PageTemplate>

            {isOpen && !isUndefined(prevClicked) && (
                <ContextMenu
                    dataId="admin-vehicles-context-menu"
                    items={getActions(prevClicked)}
                    onClose={handleClose}
                    position={position}
                />
            )}

            {isDeleteDialog(dialogs?.action) && dialogs?.vehicle && (
                <DeleteVehicleDialog
                    onCancel={() => setDialogs(undefined)}
                    onRefreshData={refreshData}
                    vehicle={dialogs.vehicle}
                />
            )}

            {dialogs?.action && isEditDialog(dialogs?.action) && (
                <VehicleEditorDialog
                    canActivateServices={vehicleAdministrationAuthorizations.serviceActivation}
                    canUnassignDevice={vehicleAdministrationAuthorizations.canUnassignDevice}
                    editorState={dialogs.action}
                    onClose={onCloseHandle}
                    servicesSecurables={servicesSecurables}
                    vehicle={dialogs?.vehicle}
                />
            )}
        </Container>
    );
};

export default VehicleAdministrationComponent;
