import type { StaticDataStoreState } from '~/common';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as TachofilesActionTypes } from '~/data/tacho';
import { ActionTypeKeys as TachofilesActionTypeKeys } from '~/data/tacho';
import type { RetrievableData } from '~/reducers';
import type { QueryTachoFilesRequest, TachoFile } from '~/services/ApiClient';

import type { ActionTypes as TachofilesItemsActionTypes } from './data';
import { ActionTypeKeys as TachofilesItemsActionTypeKeys } from './data';
import type { TachoFileEntry } from './models';
import { resolveTachoFilesEntries } from './reducers.resolveTachoFilesEntries';

export interface TachoFilesStoreState {
    dateTimeRange?: DateTimeRange;
    latestRequestParams?: QueryTachoFilesRequest;
    tachoFiles: RetrievableData<TachoFile[]>;
    tachoFilesEntries: TachoFileEntry[];
}

export const defaultStoreState: TachoFilesStoreState = {
    tachoFiles: {
        data: [],
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    tachoFilesEntries: [],
};

type ActionTypes = TachofilesActionTypes | TachofilesItemsActionTypes;

export const tachoFilesReducer = (
    state: TachoFilesStoreState = defaultStoreState,
    action: ActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): TachoFilesStoreState => {
    switch (action.type) {
        case TachofilesItemsActionTypeKeys.TACHOFILES_CLEAR_DATA:
            return {
                ...defaultStoreState,
                dateTimeRange: state.dateTimeRange,
            };
        case TachofilesItemsActionTypeKeys.TACHOFILES_CHANGE_DATETIMERANGE:
            return {
                ...state,
                dateTimeRange: action.payload,
            };
        case TachofilesActionTypeKeys.TACHOFILES_QUERY_PENDING:
            return {
                ...state,
                latestRequestParams: action.meta,
                tachoFiles: {
                    ...state.tachoFiles,
                    fulfilled: false,
                    pending: true,
                    rejected: false,
                },
            };
        case TachofilesActionTypeKeys.TACHOFILES_QUERY_REJECTED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }
            return {
                ...state,
                tachoFiles: {
                    data: [],
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
            };
        }
        case TachofilesActionTypeKeys.TACHOFILES_QUERY_FULFILLED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }

            const resolvedTachoFilesEntries = resolveTachoFilesEntries({
                settingsStoreState,
                staticDataStoreState,
                tachoFiles: action.payload.items,
            });

            return {
                ...state,
                tachoFiles: {
                    data: action.payload.items,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
                tachoFilesEntries: resolvedTachoFilesEntries,
            };
        }
        default:
            return state;
    }
};
