import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { ReeferStatusFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedReeferStatus } from '~/services/ApiClient';

import { ReeferValue } from '../ReeferValue';

import type { HeaderInfoClassKey } from './styles';

export interface HeaderInfoProps {
    reefer: ResolvedReeferStatus;
}

export interface HeaderInfoInnerProps
    extends InjectedTranslationProps,
        HeaderInfoProps,
        WithStyles<HeaderInfoClassKey> {}

export const HeaderInfoComponent: FC<HeaderInfoInnerProps> = (props) => {
    const { classes, reefer } = props;

    return (
        <div className={classes.reeferStatuses} data-id="reefer-statuses">
            <span className={classes.reeferPercentage} data-id="reefer-percentage">
                <ReeferValue reefer={reefer} />
            </span>
            <span data-id="reefer-status" data-status={reefer.status?.key}>
                <ReeferStatusFormatter headerAvatar value={reefer.status} withAvatar />
            </span>
        </div>
    );
};
