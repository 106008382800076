import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import type { FC } from 'react';

import { isUndefined } from '../../utility';

import type { LabeledSwitchProps } from './models';
import { useStyles } from './styles';

const LabeledSwitch: FC<LabeledSwitchProps> = (props) => {
    const {
        error,
        FormControlLabelProps,
        FormControlProps,
        helperText,
        label,
        onChange,
        tooltip,
        TooltipProps,
        value,
        ...restProps
    } = props;

    const classes = useStyles();

    const control = (
        <FormControl {...FormControlProps}>
            <FormControlLabel
                classes={{
                    ...FormControlLabelProps?.classes,
                    label: classNames(classes.label, FormControlLabelProps?.classes?.label),
                    labelPlacementStart: classNames(
                        classes.labelPlacementStart,
                        FormControlLabelProps?.classes?.labelPlacementStart
                    ),
                }}
                control={<Switch {...restProps} checked={value} onChange={(_, checked) => onChange(checked)} />}
                label={label}
                labelPlacement="start"
                {...FormControlLabelProps}
            />
            {!isUndefined(helperText) && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
    );

    return tooltip ? (
        <Tooltip title={tooltip} {...TooltipProps}>
            {control}
        </Tooltip>
    ) : (
        control
    );
};

LabeledSwitch.displayName = 'LabeledSwitch';
export { LabeledSwitch };
