import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { Input, InputAdornment, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { DebouncedFunc } from '~/libs/utility';
import { debounce } from '~/libs/utility';

import type { ConversationSummarySearchBarClassKey } from './styles';

export interface ConversationSummarySearchBarProps {
    changeSearchQuery: (searchQuery: string) => void;
    clearSearchQuery: () => void;
    defaultSearchQuery?: string;
}

export interface ConversationSummarySearchBarInnerProps
    extends ConversationSummarySearchBarProps,
        WithStyles<ConversationSummarySearchBarClassKey>,
        InjectedTranslationProps {}

export const ConversationSummarySearchBarComponent: FC<ConversationSummarySearchBarInnerProps> = (props) => {
    const { changeSearchQuery, classes, clearSearchQuery, defaultSearchQuery, t } = props;

    const debounceRef = useRef<DebouncedFunc<(searchQuery?: string) => void>>();
    const inputRef = useRef<HTMLInputElement>();
    const [currentSearchQuery, setCurrentSearchQuery] = useState(defaultSearchQuery || '');

    const clearClick = useCallback(() => {
        if (debounceRef.current) {
            debounceRef.current.cancel();
        }

        clearSearchQuery();
        setCurrentSearchQuery('');
    }, [clearSearchQuery, setCurrentSearchQuery]);

    const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            setCurrentSearchQuery(event.target.value);
            if (debounceRef.current) {
                debounceRef.current(event.target.value);
            }
        },
        [setCurrentSearchQuery]
    );

    useEffect(() => {
        if (debounceRef.current) {
            debounceRef.current.cancel();
        }

        debounceRef.current = debounce((searchQuery?: string) => {
            if (searchQuery) {
                changeSearchQuery(searchQuery);
            } else {
                clearSearchQuery();
            }
        }, 500);

        return () => debounceRef.current && debounceRef.current.flush();
    }, [changeSearchQuery, clearSearchQuery]);

    const handleSearchBarClick = useCallback(() => inputRef.current && inputRef.current.focus(), []);

    const startAdornment = (
        <InputAdornment position="start">
            <Search className={classes.icon} />
        </InputAdornment>
    );

    const endAdornment = currentSearchQuery && (
        <InputAdornment position="end">
            <Tooltip title={t('clear-search')}>
                <Clear className={classes.icon} data-id="clear-search" onClick={clearClick} />
            </Tooltip>
        </InputAdornment>
    );

    return (
        <div className={classes.root} onClick={handleSearchBarClick}>
            <Typography component="div" variant="caption">
                <Input
                    className={classes.input}
                    data-id="search-input"
                    disableUnderline
                    endAdornment={endAdornment}
                    fullWidth
                    inputRef={inputRef}
                    onChange={handleInputChange}
                    startAdornment={startAdornment}
                    value={currentSearchQuery}
                />
            </Typography>
        </div>
    );
};
