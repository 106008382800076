import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';

import { ValueChangelogChangesTableRow } from './components/ValueChangelogChangesTableRow';
import type { ChangelogChangesTableProps } from './models';
import { useStyles } from './styles';

const defaultEqualityComparer = (a: unknown, b: unknown) => a === b;

const ChangelogChangesTable = <T,>({
    attributeDefinitions,
    changelog,
}: ChangelogChangesTableProps<T>): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table} data-id={`changelog-table:${changelog.id}`}>
                <TableHead>
                    <TableRow className={classes.header}>
                        <TableCell className={classes.cell}>{t('attribute')}</TableCell>
                        <TableCell className={classes.cell}>{t('old-value')}</TableCell>
                        <TableCell className={classes.cell}>{t('new-value')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {attributeDefinitions.map((attrDef) => {
                        const oldValue = attrDef.getValue(changelog.before);
                        const newValue = attrDef.getValue(changelog.after);
                        const equalityComparer = attrDef.equalityComparer ?? defaultEqualityComparer;
                        if ((isUndefined(oldValue) || isUndefined(newValue)) && equalityComparer(oldValue, newValue)) {
                            return undefined;
                        }

                        const RowComponent = attrDef.RowComponent ?? ValueChangelogChangesTableRow;

                        return <RowComponent attributeDefinition={attrDef} changelog={changelog} key={attrDef.title} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ChangelogChangesTable.displayName = 'ChangelogChangesTable';
export { ChangelogChangesTable };
