import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { exportFormatterFactory, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, stringComparer } from '~/services/Sorting';

import type { CellFilteringOptions, CreateColumnOptions, GridColumnDefinition } from './models';

export interface CreateFormattedColumnOptions<TData> extends CreateColumnOptions {
    align?: 'center' | 'left' | 'right';
    cellFiltering?: CellFilteringOptions;
    compare?: (a: TData | undefined, b: TData | undefined) => number;
    valueFormatterComponent?: React.ComponentType<{ value?: TData }>;
}

export const createFormattedColumn = <T, TData>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => TData | undefined,
    formatString: (data: TData) => string,
    options?: CreateFormattedColumnOptions<TData>
): GridColumnDefinition<T> => {
    const FormatterComponent: React.ComponentType<{ value?: TData }> = ({ value }) =>
        React.createElement(React.Fragment, undefined, isUndefined(value) ? undefined : formatString(value));
    return {
        compare: compareFactory(formatString, stringComparer),
        dataType: 'string',
        exportValueFormatter: exportFormatterFactory(formatString),
        getCellValue,
        groupingCriteria: groupingCriteriaFactory(formatString),

        groupTitle,
        name,
        title,
        valueFormatterComponent: FormatterComponent,
        valueTextFormatter: valueTextFormatterFactory(formatString),
        ...options,
    };
};
