import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SceneDetailsPaneHeaderClassKey = 'action' | 'avatar' | 'root' | 'secondaryIdentifier';

export const SceneDetailsPaneHeaderStyleRules: StyleRulesCallback<Theme, {}, SceneDetailsPaneHeaderClassKey> = (
    theme: Theme
) =>
    createStyles({
        action: {
            marginRight: theme.spacing(),
        },
        avatar: {
            alignSelf: 'center',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: 40,
            overflow: 'visible',
            width: 40,
        },
        root: {
            flexDirection: 'row',
            height: 80,
            paddingRight: theme.spacing(),
            position: 'relative',
        },
        secondaryIdentifier: {
            opacity: 0.54,
        },
    });
