export enum FrequencyTypes {
    Once,
    Daily,
    Weekly,
    Monthly,
}

export enum PriorityTypes {
    High = 9,
    Normal = 4,
}

export enum WeekDayTypes {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

export enum OrdinalTypes {
    First = 1,
    Second,
    Third,
    Fourth,
    Last,
}

export enum MonthlyRadioButtonsTypes {
    Month = 1,
    Day,
}
