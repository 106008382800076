import { List } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReorderableSection } from '~/components/Sections';
import { DriverRole } from '~/services/ApiClient';

import type { SectionProps } from '../../../models';

import { StaffHeader } from './components/Header';
import { Summary } from './components/Summary';

const StaffSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, driverStatus, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();

    return (
        <ReorderableSection
            dataId="staff"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={<StaffHeader driverStatus={driverStatus} />}
            title={t('staff')}
            toggleCollapsed={toggleCollapsed}
        >
            <List disablePadding>
                <Summary driverStatus={driverStatus} role={DriverRole.DRIVER} />
            </List>
        </ReorderableSection>
    );
};

StaffSection.displayName = 'Staff';
export default StaffSection;
