import type { TriggerDataRefresh } from '~/common';
import type { ContextMenuPosition } from '~/components/ContextMenu';
import type { ResolvedVehicleType } from '~/services/ApiClient';

import type { VehicleTypeAdministrationReduxProps } from './redux';

export interface VehicleTypesAdministrationProps {}

export interface VehicleTypesAdministrationInnerProps
    extends VehicleTypesAdministrationProps,
        VehicleTypeAdministrationReduxProps {}

export interface VehicleTypesAdministrationComponentState {
    contextMenu?: ContextMenuPosition;
    currentVehicleType?: ResolvedVehicleType;
    prevVehicleType?: ResolvedVehicleType;
    triggerDataRefresh: TriggerDataRefresh;
}

export enum Subpage {
    CHANGELOGS = 'changelogs',
}
