import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    SETTINGS = 'SETTINGS',
    SETTINGS_UPDATE = 'SETTINGS_UPDATE',
}

export enum ActionTypeKeys {
    SETTINGS_FULFILLED = 'SETTINGS_FULFILLED',
    SETTINGS_PENDING = 'SETTINGS_PENDING',
    SETTINGS_REJECTED = 'SETTINGS_REJECTED',
    SETTINGS_UPDATE_FULFILLED = 'SETTINGS_UPDATE_FULFILLED',
    SETTINGS_UPDATE_PENDING = 'SETTINGS_UPDATE_PENDING',
    SETTINGS_UPDATE_REJECTED = 'SETTINGS_UPDATE_REJECTED',
}

export type ActionTypes =
    | FulfilledMeta<ActionTypeKeys.SETTINGS_FULFILLED, unknown, string>
    | FulfilledMeta<ActionTypeKeys.SETTINGS_UPDATE_FULFILLED, unknown, string>
    | PendingMeta<ActionTypeKeys.SETTINGS_PENDING, string>
    | PendingMeta<ActionTypeKeys.SETTINGS_UPDATE_PENDING, string>
    | RejectedMeta<ActionTypeKeys.SETTINGS_REJECTED, undefined, string>
    | RejectedMeta<ActionTypeKeys.SETTINGS_UPDATE_REJECTED, undefined, string>;
