import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';

import { useTranslation } from '~/components/LanguageSelector';

import type { RelativeTimeFormat } from './models';

export interface RelativeTimeProps {
    dataId?: string;
    date: Date;
    format: RelativeTimeFormat;
}

export interface RelativeTimeInnerProps extends RelativeTimeProps {}

const RelativeTime: React.FC<RelativeTimeInnerProps> = ({ dataId, date, format }) => {
    const { t } = useTranslation();

    const datePrefix = moment(date).isAfter(moment.now()) ? `[${t('in')}]` : '';
    const dateSuffix = moment(date).isBefore(moment.now()) ? `[${t('ago')}]` : '';
    const innerDateFormat =
        (format?.days ? `d [${format.days}] ` : '') +
        (format?.hours ? `h [${format.hours}] ` : '') +
        (format?.minutes ? `m [${format.minutes}] ` : '') +
        (format?.seconds ? `s [${format.seconds}] ` : '');

    const dateFormat = `${datePrefix} ${innerDateFormat} ${dateSuffix}`;

    const removeSign = (stringDate: string) => stringDate.replace(/-/, '');

    return <Moment data-id={dataId} date={date} durationFromNow filter={removeSign} format={dateFormat} trim />;
};
RelativeTime.displayName = 'RelativeTime';

export { RelativeTime };
