import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import * as React from 'react';

import { CalendarBlankIcon, CalendarMonthIcon } from '~/components/Icons';

import { GridFilterOperation } from '../../models';

export interface FilterIconProps extends TableFilterRow.IconProps {}

export interface FilterIconInnerProps extends FilterIconProps {}

const FilterIcon: React.FunctionComponent<FilterIconInnerProps> = ({ type, ...restProps }) => {
    switch (type) {
        case GridFilterOperation.After:
            return <TableFilterRow.Icon type={GridFilterOperation.GreaterThan} {...restProps} />;
        case GridFilterOperation.AfterOrEqual:
            return <TableFilterRow.Icon type={GridFilterOperation.GreaterThanOrEqual} {...restProps} />;
        case GridFilterOperation.Before:
            return <TableFilterRow.Icon type={GridFilterOperation.LessThan} {...restProps} />;
        case GridFilterOperation.BeforeOrEqual:
            return <TableFilterRow.Icon type={GridFilterOperation.LessThanOrEqual} {...restProps} />;
        case GridFilterOperation.MonthEqual:
            return <CalendarMonthIcon {...restProps} />;
        case GridFilterOperation.YearEqual:
            return <CalendarBlankIcon {...restProps} />;
        default:
            return <TableFilterRow.Icon type={type} {...restProps} />;
    }
};
FilterIcon.displayName = 'FilterIcon';

export { FilterIcon };
