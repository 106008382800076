import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import type { PercentageFormatterProps } from '~/components/Formatters';
import {
    AddressFormatter,
    PercentageFormatter as BasePercentageFormatter,
    CompartmentStatusFormatter,
    DeviceTypeFormatter,
    DistanceFormatter,
    DoorStatusFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    ReeferStatusFormatter,
    TrailerManualConnectionStatusFormatter,
    VoltageFormatter,
    WeightFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    classificationFilterEquals,
    createClassificationColumn,
    createDateTimeColumn,
    createDurationColumn,
    createTemperatureSensorColumn,
    getClassificationOptions,
} from '~/components/Grid';
import { createClassificationsColumn } from '~/components/Grid/createClassificationsColumn';
import { createTemperatureColumn } from '~/components/Grid/createTemperatureColumn';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { HistoryTrailerEntry } from '~/scenes/History';
import type {
    Address,
    Classification,
    ClassificationAbstraction,
    DeviceType,
    DisplayUserPreferencesTrailerDisplayFormat,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
    NearestCity,
} from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    DurationFormat,
    FormatLength,
    exportFormatterFactory,
    formatAddress,
    formatBoolean,
    formatDeviceType,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    formatPercentage,
    formatVoltage,
    getDistanceUnitSystemUtils,
    getWeightUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';
import {
    getAddressValue,
    getAxleLoadValue,
    getBatteryLevelTrailerValue,
    getBatteryStatusTrailerValue,
    getBatteryVoltageReeferValue,
    getBatteryVoltageTrailerValue,
    getCityValue,
    getCompartmentN,
    getCompartmentNMaxBandWithTempValue,
    getCompartmentNMinBandWithTempValue,
    getCompartmentNReturnTemperatureValue,
    getCompartmentNSetpointTemperatureValue,
    getCompartmentNStatusManufacturerValue,
    getCompartmentNStatusValue,
    getCompartmentNSupplyTemperatureValue,
    getCountryCodeValue,
    getDeviceMsisdnValue,
    getDeviceTypeValue,
    getDoorNSealCodeValue,
    getDoorNSensorValue,
    getDoorNTimestampValue,
    getEbsMileageValue,
    getEbsTimestampValue,
    getEventTypeValue,
    getFuelLevelReeferValue,
    getHookedValue,
    getIsManuallyConnectedValue,
    getLatitudeValue,
    getLongitudeValue,
    getNearestCityValue,
    getPostalCodeValue,
    getReeferAlarmsManufacturerValue,
    getReeferAlarmsValue,
    getReeferOperationModeValue,
    getReeferPowerModeValue,
    getReeferSpeedValue,
    getReeferStatusValue,
    getTotalEngineHoursReeferValue,
    getTrailerEventDateTimeValue,
    getVehicleDisplayNameFactory,
} from './getCellValue.event';
import { getTrailerDisplayNameFactory } from './getCellValue.trailer';

export const getRowId = (row: HistoryTrailerEntry): number => row.trailerEvent.trailerEventId;

const createNthDoorSensorColumn = (
    t: SingleTFunction,
    doorNumber: number,
    doorStatuses: NumericDictionary<Classification>
): GridColumnDefinition<HistoryTrailerEntry> => {
    const column = createClassificationsColumn(
        t,
        ColumnName[`DOOR${doorNumber}SENSOR`],
        t('nth-door-sensor', { doorNumber }),
        t('doors'),
        getDoorNSensorValue(doorNumber),
        ClassificationType.DoorStatus,
        {
            classifications: Object.values(doorStatuses),
            valueFormatterComponent: withTranslation()(DoorStatusFormatter),
        }
    );

    return column;
};

const createNthDoorSealCodeColumn = (
    t: SingleTFunction,
    doorNumber: number
): GridColumnDefinition<HistoryTrailerEntry> => ({
    align: 'right',
    compare: compareStrings,
    dataType: 'string',
    getCellValue: getDoorNSealCodeValue(doorNumber),
    groupTitle: t('doors'),
    name: ColumnName[`DOOR${doorNumber}SEALCODE`],
    title: t('nth-door-sealcode', { doorNumber }),
});

const createNthDoorTimestampColumn = (
    t: SingleTFunction,
    doorNumber: number
): GridColumnDefinition<HistoryTrailerEntry> =>
    createDateTimeColumn(
        ColumnName[`DOOR${doorNumber}TIMESTAMP`],
        t('nth-door-timestamp', { doorNumber }),
        t('doors'),
        getDoorNTimestampValue(doorNumber)
    );

const getDoorColumns = (t: SingleTFunction, doorNumber: number, doorStatuses: NumericDictionary<Classification>) => [
    createNthDoorSensorColumn(t, doorNumber, doorStatuses),
    createNthDoorSealCodeColumn(t, doorNumber),
    createNthDoorTimestampColumn(t, doorNumber),
];

const createNthCompartmentTemperatureSensorN = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    compartmentNumber: number,
    sensorNumber: number
): GridColumnDefinition<HistoryTrailerEntry> => {
    return createTemperatureSensorColumn(
        t,
        unitSystem,
        sensorNumber,
        ColumnName[`COMPARTMENT${compartmentNumber}TEMPERATURESENSOR${sensorNumber}`],
        t('nth-compartment-temperature-nth-sensor', { compartmentNumber, sensorNumber }),
        t('temperatures'),
        getCompartmentN(compartmentNumber)
    );
};

const getCompartmentColumns = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    compartmentNumber: number,
    compartmentStatuses: NumericDictionary<Classification>
): Array<GridColumnDefinition<HistoryTrailerEntry>> => [
    createClassificationColumn(
        t,
        ColumnName[`COMPARTMENT${compartmentNumber}STATUS`],
        t('nth-compartment-status', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNStatusValue(compartmentNumber),
        ClassificationType.CompartmentStatus,
        {
            classifications: Object.values(compartmentStatuses),
            valueFormatterComponent: withTranslation()(CompartmentStatusFormatter),
        }
    ),
    {
        compare: compareStrings,
        dataType: 'string',
        getCellValue: getCompartmentNStatusManufacturerValue(compartmentNumber),
        groupTitle: t('temperatures'),
        name: ColumnName[`COMPARTMENT${compartmentNumber}STATUSMANUFACTURER`],
        title: t('nth-compartment-status-manufacturer', { compartmentNumber }),
    },
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}SUPPLYTEMPERATURE`],
        t('nth-compartment-supply-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNSupplyTemperatureValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}RETURNTEMPERATURE`],
        t('nth-compartment-return-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNReturnTemperatureValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}SETPOINTTEMPERATURE`],
        t('nth-compartment-setpoint-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNSetpointTemperatureValue(compartmentNumber)
    ),
    createNthCompartmentTemperatureSensorN(t, unitSystem, compartmentNumber, 1),
    createNthCompartmentTemperatureSensorN(t, unitSystem, compartmentNumber, 2),
    createNthCompartmentTemperatureSensorN(t, unitSystem, compartmentNumber, 3),
    createNthCompartmentTemperatureSensorN(t, unitSystem, compartmentNumber, 4),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}MINBANDWITHTEMP`],
        t('nth-compartment-min-bandwith-temp', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNMinBandWithTempValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}MAXBANDWITHTEMP`],
        t('nth-compartment-max-bandwith-temp', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNMaxBandWithTempValue(compartmentNumber)
    ),
];

export const getColumns = (
    t: SingleTFunction,
    trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    unitSystem: DisplayUserPreferencesUnitSystem,
    deviceTypes: NumericDictionary<DeviceType>,
    trailerEventTypes: NumericDictionary<Classification>,
    hookedStatuses: NumericDictionary<Classification>,
    doorStatuses: NumericDictionary<Classification>,
    batteryStatuses: NumericDictionary<Classification>,
    reeferStatuses: NumericDictionary<Classification>,
    compartmentStatuses: NumericDictionary<Classification>
): Array<GridColumnDefinition<HistoryTrailerEntry>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const deviceTypeClassificationFactory = (value: DeviceType): ClassificationAbstraction => {
        return {
            displayName: formatDeviceType(t, value.key),
            id: value.id,
            key: value.key,
        };
    };

    const defaultPercentageFormatterProps: Partial<PercentageFormatterProps> = { precision: 1 };
    const PercentageFormatter = defaultProps(defaultPercentageFormatterProps)(BasePercentageFormatter);

    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);
    const weightUtils = getWeightUnitSystemUtils(t, unitSystem);

    return [
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getTrailerDisplayNameFactory(trailerDisplayNameFormat),
            groupTitle: t('general'),
            name: ColumnName.TRAILER,
            title: t('trailer'),
        },
        {
            cellFiltering: {
                options: getClassificationOptions(Object.values(deviceTypes), deviceTypeClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
            compare: compareFactory((v: string) => formatDeviceType(t, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: string) => formatDeviceType(t, v)),
            getCellValue: getDeviceTypeValue,
            groupTitle: t('general'),
            name: ColumnName.DEVICETYPE,
            title: t('device-type'),

            valueFormatterComponent: withTranslation()(DeviceTypeFormatter),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getDeviceMsisdnValue,
            groupTitle: t('vehicle'),
            name: ColumnName.MSISDN,
            title: t('msisdn'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getVehicleDisplayNameFactory(vehicleDisplayNameFormat),
            groupTitle: t('vehicle'),
            name: ColumnName.VEHICLE,
            title: t('vehicle'),
        },
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: getIsManuallyConnectedValue,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('vehicle'),
            name: ColumnName.ISMANUALLYCONNECTED,
            title: t('is-manually-connected'),
            valueFormatterComponent: withTranslation()(TrailerManualConnectionStatusFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        createDateTimeColumn(ColumnName.TRAILEREVENTDATE, t('date-time'), t('events'), getTrailerEventDateTimeValue),
        createClassificationColumn(
            t,
            ColumnName.EVENTTYPE,
            t('event-type'),
            t('events'),
            getEventTypeValue,
            ClassificationType.TrailerEventType,
            {
                classifications: Object.values(trailerEventTypes),
            }
        ),
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCityValue,
            groupTitle: t('location'),
            name: ColumnName.CITY,
            title: t('city'),
        },
        {
            compare: compareFactory(formatAddress, stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory(formatAddress),
            getCellValue: getAddressValue,
            groupingCriteria: groupingCriteriaFactory(formatAddress),
            groupTitle: t('location'),
            name: ColumnName.ADDRESS,
            title: t('address'),
            valueFormatterComponent: AddressFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: Address) => formatAddress(v)),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getPostalCodeValue,
            groupTitle: t('location'),
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getCountryCodeValue,
            groupTitle: t('location'),
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
        },
        {
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            getCellValue: getNearestCityValue,
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            groupTitle: t('location'),
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(NearestCityFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            compare: compareNumbers,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            getCellValue: getLatitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            compare: compareStrings,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            getCellValue: getLongitudeValue,
            groupTitle: t('location'),
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
        ...getDoorColumns(t, 1, doorStatuses),
        ...getDoorColumns(t, 2, doorStatuses),
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.00 "${t('unit-voltage')}"`,
            getCellValue: getBatteryVoltageTrailerValue,
            groupTitle: t('battery'),
            name: ColumnName.BATTERYVOLTAGETRAILER,
            title: t('battery-voltage-trailer'),
            valueFormatterComponent: withTranslation()(VoltageFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatVoltage({ t, value: v })),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: '0.0%',
            exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
            getCellValue: getBatteryLevelTrailerValue,
            groupTitle: t('battery'),
            name: ColumnName.BATTERYLEVELTRAILER,
            title: t('battery-level-trailer'),
            valueFormatterComponent: PercentageFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
        },
        createClassificationColumn(
            t,
            ColumnName.BATTERYSTATUSTRAILER,
            t('battery-status-trailer'),
            t('battery'),
            getBatteryStatusTrailerValue,
            ClassificationType.TrailerBatteryStatus,
            {
                classifications: Object.values(batteryStatuses),
            }
        ),
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.00 "${t('unit-voltage')}"`,
            getCellValue: getBatteryVoltageReeferValue,
            groupTitle: t('reefer'),
            name: ColumnName.BATTERYVOLTAGEREEFER,
            title: t('battery-voltage-reefer'),
            valueFormatterComponent: withTranslation()(VoltageFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatVoltage({ t, value: v })),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: '0.0%',
            exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
            getCellValue: getFuelLevelReeferValue,
            groupTitle: t('reefer'),
            name: ColumnName.FUELLEVELREEFER,
            title: t('fuel-level-reefer'),
            valueFormatterComponent: PercentageFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
        },
        createDurationColumn(
            t,
            ColumnName.TOTALENGINEHOURSREEFER,
            t('total-engine-hours-reefer'),
            t('reefer'),
            getTotalEngineHoursReeferValue,
            {
                excelCellFormat: `[h]"${t('unit-hour')}"`,
                formatOptions: {
                    durationFormat: DurationFormat.Hour,
                    durationFormatLength: FormatLength.Short,
                },
            }
        ),
        createClassificationsColumn(
            t,
            ColumnName.REEFERALARMS,
            t('reefer-alarms'),
            t('reefer'),
            getReeferAlarmsValue,
            ClassificationType.ReeferAlarm
        ),
        {
            compare: compareStrings,
            dataType: 'string',
            getCellValue: getReeferAlarmsManufacturerValue,
            groupTitle: t('reefer'),
            name: ColumnName.REEFERALARMSMANUFACTURER,
            title: t('reefer-alarms-manufacturer'),
        },
        createClassificationColumn(
            t,
            ColumnName.REEFEROPERATIONMODE,
            t('reefer-operation-mode'),
            t('reefer'),
            getReeferOperationModeValue,
            ClassificationType.ReeferOperationMode
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERPOWERMODE,
            t('reefer-power-mode'),
            t('reefer'),
            getReeferPowerModeValue,
            ClassificationType.ReeferPowerMode
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERSPEED,
            t('reefer-speed'),
            t('reefer'),
            getReeferSpeedValue,
            ClassificationType.ReeferSpeed
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERSTATUS,
            t('reefer-status'),
            t('reefer'),
            getReeferStatusValue,
            ClassificationType.ReeferStatus,
            {
                classifications: Object.values(reeferStatuses),
                valueFormatterComponent: withTranslation()(ReeferStatusFormatter),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.HOOKED,
            t('hooked'),
            t('events'),
            getHookedValue,
            ClassificationType.HookedStatus,
            {
                classifications: Object.values(hookedStatuses),
            }
        ),
        createDateTimeColumn(ColumnName.EBSTIMESTAMP, t('ebs-timestamp'), t('ebs'), getEbsTimestampValue),
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            getCellValue: getEbsMileageValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            groupTitle: t('ebs'),
            name: ColumnName.EBSMILEAGE,
            title: t('ebs-mileage'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
        },
        {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${weightUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            getCellValue: getAxleLoadValue,
            getFilterValue: (v: number) => weightUtils.converter(v, { precision: 0 }),
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            groupTitle: t('ebs'),
            name: ColumnName.AXLELOAD,
            title: t('axle-load'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
        },
        ...getCompartmentColumns(t, unitSystem, 1, compartmentStatuses),
        ...getCompartmentColumns(t, unitSystem, 2, compartmentStatuses),
        ...getCompartmentColumns(t, unitSystem, 3, compartmentStatuses),
        ...getCompartmentColumns(t, unitSystem, 4, compartmentStatuses),
    ];
};

export const getColumnsMemoized = memoizeOne(getColumns);
