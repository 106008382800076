import { groupBy, uniq } from '~/libs/utility';
import { MessagesUpdate, createApiModel } from '~/services/ApiClient';

export const mergeMessagesUpdate = (
    previousMessagesUpdate: MessagesUpdate,
    messagesUpdate: MessagesUpdate
): MessagesUpdate => {
    return createApiModel(MessagesUpdate, {
        deletedMessages: uniq(previousMessagesUpdate.deletedMessages.concat(messagesUpdate.deletedMessages)),
        updatedMessages: Object.values(
            groupBy(previousMessagesUpdate.updatedMessages.concat(messagesUpdate.updatedMessages), 'id')
        ).map((group) => group.sort((a, b) => b.revision - a.revision)[0]),
    });
};
