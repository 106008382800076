import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    divider: {
        border: '1px solid #808080',
        width: '100%',
    },
    dividerHitbox: {
        alignItems: 'center',
        alignSelf: 'stretch',
        cursor: 'row-resize',
        display: 'flex',
        padding: '0 1rem',
    },
    rightPane: {
        flex: 1,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    topPane: {
        paddingLeft: '25px',
    },
}));
