import * as React from 'react';

import { logEvent } from '~/services/Analytics';

import { addDocumentEventListener } from './addDocumentEventListener';
import { getCurrentDate } from './getCurrentDate';
import { getDocumentHidden } from './getDocumentHidden';

const InactivityMonitor: React.FC<{}> = () => {
    React.useEffect(() => {
        let inactivityStart: Date | undefined = getDocumentHidden() ? getCurrentDate() : undefined;

        addDocumentEventListener({
            listener: () => {
                if (getDocumentHidden()) {
                    inactivityStart = inactivityStart || getCurrentDate();
                } else if (inactivityStart) {
                    const duration = Math.round((getCurrentDate().getTime() - inactivityStart.getTime()) / 1000);
                    if (duration > 30) {
                        logEvent('inactivity', 'inactivity', 'screen inactivity', { value: duration });
                    }

                    inactivityStart = undefined;
                }
            },
            type: 'visibilitychange',
        });
    }, []);

    return null;
};
InactivityMonitor.displayName = 'InactivityMonitor';

export { InactivityMonitor };
