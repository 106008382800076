import type { HistoryStoreState } from '~/data/monitoring';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

const defaultHistoryState: HistoryStoreState = {
    pendingPositionGroups: undefined,
    positionGroups: {
        data: [],
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    scrollToPositionGroup: false,
};

export const historyReducer = (
    state: HistoryStoreState = defaultHistoryState,
    action: ActionTypes
): HistoryStoreState => {
    switch (action.type) {
        case ActionTypeKeys.POSITIONGROUPS_PENDING: {
            return {
                ...state,
                pendingPositionGroups: action.meta,
                positionGroups: {
                    ...state.positionGroups,
                    pending: true,
                },
            };
        }

        case ActionTypeKeys.POSITIONGROUPS_FULFILLED: {
            if (state.pendingPositionGroups !== action.meta) {
                return state;
            }

            return {
                ...state,
                highlightPosition: undefined,
                highlightPositionGroupId: undefined,
                pendingPositionGroups: undefined,
                positionGroups: {
                    data: action.payload,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
            };
        }

        case ActionTypeKeys.POSITIONGROUPS_REJECTED: {
            if (state.pendingPositionGroups !== action.meta) {
                return state;
            }

            return {
                ...state,
                highlightPosition: undefined,
                highlightPositionGroupId: undefined,
                pendingPositionGroups: undefined,
                positionGroups: {
                    data: [],
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
            };
        }

        case ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITIONGROUP:
            return {
                ...state,
                highlightPositionGroupId: action.payload,
            };

        case ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITION:
            return {
                ...state,
                highlightPosition: action.payload,
            };

        case ActionTypeKeys.HISTORYMAP_SCROLLTOPOSITIONGROUP:
            return {
                ...state,
                scrollToPositionGroup: true,
            };

        case ActionTypeKeys.HISTORYMAP_RESETSCROLLTOPOSITIONGROUP:
            return {
                ...state,
                scrollToPositionGroup: false,
            };

        default:
            return state;
    }
};
