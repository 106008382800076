import type { CalendarSpec } from 'moment';

import type { SingleTFunction } from '~/components/LanguageSelector';

export const getDefaultAdaptiveDateTimeCalendarSpec = (t: SingleTFunction): CalendarSpec => {
    return {
        lastDay: `[${t('yesterday')}]`,
        lastWeek: 'dddd',
        nextDay: `[${t('tomorrow')}]`,
        nextWeek: 'L',
        sameDay: 'LT',
        sameElse: 'L',
    };
};
