import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { DeviceType, Vehicle, VehicleCategory, VehicleEvent, VehicleType } from '~/services/ApiClient';
import { vehicleResolver } from '~/services/ModelResolvers';

import type { HistoryVehicleEntry } from './models';

export const buildHistoryVehicleEntry = (
    vehiclesHash: NumericDictionary<Vehicle>,
    vehicleEvent: VehicleEvent,
    deviceTypes: NumericDictionary<DeviceType>,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>
): HistoryVehicleEntry => {
    const vehicle = !isUndefined(vehicleEvent.vehicleId) ? vehiclesHash[vehicleEvent.vehicleId] : undefined;

    return {
        vehicle: vehicle
            ? vehicleResolver({ categories: vehicleCategories, deviceTypes, vehicle, vehicleTypes })
            : undefined,
        vehicleEvent,
    };
};
