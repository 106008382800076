import type { Dispatch } from 'redux';
import { v1 as uuidv1 } from 'uuid';

import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import { reportError } from '~/reporting';
import type {
    OptimisticOutgoingConversationMessage,
    SendMessageOptions,
    WorkflowMessageBody,
} from '~/services/ApiClient';

import { discardConversationMessageAction, sendConversationMessageAction } from '../../../../../../data';
import { buildOptimisticOutgoingConversationMessage } from '../SendMessageBox';

import type { WorkflowMessageSendSubpageOuterProps } from './models';

export interface WorkflowMessageSendSubpagDispatchProps {
    discardMessage: (message: OptimisticOutgoingConversationMessage) => void;
    sendWorkflowMessage: (
        workflowMessageBody: WorkflowMessageBody,
        options: SendMessageOptions
    ) => OptimisticOutgoingConversationMessage;
}

export interface WorkflowMessageSendSubpageOwnProps
    extends WorkflowMessageSendSubpageOuterProps,
        InjectedUserDataProps {}

export const mapDispatchToProps = (
    dispatch: Dispatch,
    { userName, vehicleId }: WorkflowMessageSendSubpageOwnProps
): WorkflowMessageSendSubpagDispatchProps => ({
    discardMessage: (message: OptimisticOutgoingConversationMessage) => {
        dispatch(discardConversationMessageAction(vehicleId, message));
    },
    sendWorkflowMessage: (workflowMessageBody: WorkflowMessageBody, options: SendMessageOptions) => {
        const message = buildOptimisticOutgoingConversationMessage(uuidv1(), workflowMessageBody, userName, vehicleId);
        dispatch(sendConversationMessageAction(vehicleId, message, options)).catch(reportError);
        return message;
    },
});
