import { createAction } from 'redux-actions';

import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import { logEvent } from '~/services/Analytics';
import type { GetMessageResponse, MessageBody, MessagesUpdate } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    IncomingMessage,
    MessageRequest,
    QueryMessagesRequest,
    SendMessageOptions,
    SendMessageRequest,
    TextMessageBody,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import type { MessageActionTypeMeta } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';
import type { SendMessageMeta } from './models';

const getMessageExecutor = (messageId: number): Promise<GetMessageResponse | undefined> => {
    return retryableRequest(async () => {
        try {
            return await ApiClient.getMessage(messageId);
        } catch (ex) {
            if (ApiException.isApiException(ex) && ex.status === 404) {
                return undefined;
            }
            throw ex;
        }
    });
};

const queryMessagesAction = createAction(
    ActionTypePrefixes.MESSAGES_QUERY,
    (
        startDate: Date,
        stopDate: Date,
        vehicleIds: number[] | undefined,
        includeDeleted: boolean | undefined,
        profileId: number
    ) =>
        retryableRequest(() =>
            ApiClient.queryMessages(
                createApiModel(QueryMessagesRequest, { includeDeleted, profileId, startDate, stopDate, vehicleIds })
            ).then((res) => {
                const messagesCharLength = res.items.reduce((acc, message) => {
                    if (message instanceof IncomingMessage && message.body instanceof TextMessageBody) {
                        return acc + message.body.message.length;
                    }

                    return acc;
                }, 0);
                logEvent('messaging', `incoming-text-messages-loaded`, `Client has loaded incoming text messages`, {
                    value: messagesCharLength,
                });
                return res;
            })
        ),
    (
        startDate: Date,
        stopDate: Date,
        vehicleIds: number[] | undefined,
        includeDeleted: boolean | undefined,
        profileId: number
    ) => createApiModel(QueryMessagesRequest, { includeDeleted, profileId, startDate, stopDate, vehicleIds })
);

const sendMessageExecutor = async (
    recipients: number[],
    messageBody: MessageBody,
    options: SendMessageOptions
): Promise<void> => {
    let success = false;
    try {
        await retryableRequest(() =>
            ApiClient.sendMessage(
                createApiModel(SendMessageRequest, {
                    body: messageBody,
                    options: createApiModel(SendMessageOptions, options),
                    recipients,
                })
            )
        );
        success = true;
    } finally {
        if (success) {
            logEvent('messaging', 'send-bulk-messages', 'Send bulk messages');
            logEvent('messaging', 'number-of-recipients', 'Number of recipients', {
                value: success ? recipients.length : 0,
            });
        } else {
            logEvent('messaging', 'send-bulk-messages-failed', 'Send bulk messages failed');
        }
    }
};

const sendMessageAction = createAction(
    ActionTypePrefixes.MESSAGES_SEND,
    sendMessageExecutor,
    (_, messageBody: MessageBody): SendMessageMeta => ({ messageBody })
);

const markMessageAsReadAction = createAction(
    ActionTypePrefixes.MESSAGES_MARKMESSAGEASREAD,
    (messageIds: number[]) =>
        retryableRequest(() => ApiClient.markMessageAsRead(createApiModel(MessageRequest, { messageIds }))),
    (messageIds: number[]) => createApiModel(MessageRequest, { messageIds })
);

const markMessageAsUnreadAction = createAction(
    ActionTypePrefixes.MESSAGES_MARKMESSAGEASUNREAD,
    (messageIds: number[]) =>
        retryableRequest(() => ApiClient.markMessageAsUnread(createApiModel(MessageRequest, { messageIds }))),
    (messageIds: number[]) => createApiModel(MessageRequest, { messageIds })
);

const deleteMessageAction = createAction(
    ActionTypePrefixes.MESSAGES_DELETEMESSAGE,
    (messageIds: number[]) =>
        retryableRequest(() => ApiClient.deleteMessage(createApiModel(MessageRequest, { messageIds }))),
    (messageIds: number[]) => createApiModel(MessageRequest, { messageIds })
);

const undeleteMessageAction = createAction(
    ActionTypePrefixes.MESSAGES_UNDELETEMESSAGE,
    (messageIds: number[]) =>
        retryableRequest(() => ApiClient.undeleteMessage(createApiModel(MessageRequest, { messageIds }))),
    (messageIds: number[]) => createApiModel(MessageRequest, { messageIds })
);

const updateMessagesAction = createAction<MessagesUpdate>(ActionTypeKeys.MESSAGES_UPDATE);

const getMessageAction = createAction(
    ActionTypePrefixes.MESSAGES_GET,
    (messageId: number, _includeDeleted: boolean): Promise<GetMessageResponse | undefined> =>
        getMessageExecutor(messageId),
    (messageId: number, includeDeleted: boolean): MessageActionTypeMeta => ({ includeDeleted, messageId })
);

const clearFailedMessageRequestsAction = createAction(ActionTypeKeys.MESSAGES_CLEARFAILEDREQUESTS);
const clearDataAction = createAction(ActionTypeKeys.COMMUNICATION_CLEAR_DATA);
const changeDateTimeRangeAction = createAction<DateTimeRange>(ActionTypeKeys.COMMUNICATION_CHANGE_DATETIMERANGE);
const clearPendingAction = createAction(ActionTypeKeys.COMMUNICATION_CLEAR_PENDING);
const applyPendingAction = createAction(ActionTypeKeys.COMMUNICATION_APPLY_PENDING);

export {
    applyPendingAction,
    changeDateTimeRangeAction,
    clearDataAction,
    clearFailedMessageRequestsAction,
    clearPendingAction,
    deleteMessageAction,
    getMessageAction,
    getMessageExecutor,
    markMessageAsReadAction,
    markMessageAsUnreadAction,
    queryMessagesAction,
    sendMessageAction,
    sendMessageExecutor,
    undeleteMessageAction,
    updateMessagesAction,
};
