import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type HistoryMapViewClassKey = 'map';

export const HistoryMapViewStyleRules: StyleRulesCallback<Theme, {}, HistoryMapViewClassKey> = () =>
    createStyles({
        map: {
            display: 'flex',
            flex: 1,
            zIndex: 0,
        },
    });
