import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import { createStyles } from '@mui/styles';

import type { InfoBoxClassKey } from './models';

export const InfoBoxStyleRules: StyleRulesCallback<Theme, {}, InfoBoxClassKey> = (theme: Theme) =>
    createStyles({
        driverAvatar: {
            backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.driver.value,
            height: 24,
            width: 24,
        },
        icon: {
            height: 14,
            width: 14,
        },
        infoBoxWrapper: {
            '&:first-child': {
                marginTop: theme.spacing(0.5),
            },
            alignItems: 'center',
            display: 'flex',
            height: theme.spacing(3),
            marginBottom: theme.spacing(0.5),
            padding: theme.spacing(0.5),
        },
        infoText: {
            marginLeft: theme.spacing(),
        },
        notAuthorized: {
            opacity: 0.38,
        },
        notLoggedIn: {
            fontStyle: 'italic',
            opacity: 0.38,
        },
        trailerAvatar: {
            backgroundColor: theme.functionalItemsColors.assetType.trailer.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.trailer.value,
            height: 24,
            width: 24,
        },
        vehicleAvatar: {
            backgroundColor: theme.functionalItemsColors.assetType.vehicle.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.vehicle.value,
            height: 24,
            width: 24,
        },
    });
