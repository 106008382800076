import { Table } from '@devexpress/dx-react-grid-material-ui';
import type { TableRowProps } from '@mui/material/TableRow';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { PureComponent } from 'react';

import type { AnimatedTableRowStyleRules } from './styles';
import { animationDuration } from './styles';

export interface AnimatedTableRowState {
    animationVisible: boolean;
}

export type AnimatedTableRowProps = {
    height?: number;
    highlighted?: boolean;
    rowEquals: (rowA: unknown, rowB: unknown) => boolean;
} & Omit<TableRowProps, 'classes' | 'ref'> &
    Table.DataRowProps;

export interface AnimatedTableRowInnerProps
    extends AnimatedTableRowProps,
        WithStyles<typeof AnimatedTableRowStyleRules, true> {}

export class AnimatedTableRowComponent extends PureComponent<AnimatedTableRowInnerProps, AnimatedTableRowState> {
    private timeoutHandle?: number;

    constructor(props: AnimatedTableRowInnerProps) {
        super(props);
        this.state = {
            animationVisible: false,
        };
    }

    private hideAnimation() {
        this.setState(() => {
            this.timeoutHandle = undefined;

            return { animationVisible: false };
        });
    }

    public componentDidUpdate(prevProps: AnimatedTableRowInnerProps): void {
        if (!this.props.rowEquals(this.props.row, prevProps.row)) {
            this.setState((prevState) => {
                if (prevState.animationVisible) {
                    return null;
                }

                this.timeoutHandle = window.setTimeout(() => {
                    this.hideAnimation();
                }, animationDuration);

                return { animationVisible: true };
            });
        }
    }

    public componentWillUnmount(): void {
        window.clearTimeout(this.timeoutHandle);
    }

    public render(): ReactNode {
        const { classes, height, highlighted, rowEquals, style, theme, ...restProps } = this.props;
        const { animationVisible } = this.state;

        return (
            <Table.Row
                {...restProps}
                className={classNames({
                    [classes.highlighted]: highlighted,
                    [classes.updateAnimation]: animationVisible,
                })}
                style={{
                    ...style,
                    backgroundColor: restProps.selected ? theme.palette.grid.selected : 'inherit',
                    height,
                }}
                {...(highlighted && { 'data-id': 'highlighted' })}
            />
        );
    }
}
