export enum ColumnName {
    COUNTRY = 'country',
    DETAILS = 'details',
    DOWNLOADDATE = 'download-date',
    DRIVER = 'driver',
    EXPORTDATE = 'export-date',
    EXPORTSTATUS = 'export-status',
    FIRSTNAME = 'first-name',
    LASTNAME = 'last-name',
    MSISDN = 'msisdn',
    NATIONALPHACODE = 'nation-alpha-code',
    SOURCE = 'source',
    UPLOADEVENTDATE = 'upload-event-date',
    UPLOADEVENTDETAILS = 'upload-event-details',
    UPLOADSTATUS = 'upload-status',
    VEHICLE = 'vehicle',
    VEHICLEALIAS = 'vehicle-alias',
}
