import type { MapModes } from '@fv/components';
import * as React from 'react';
import type { Dispatch } from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import { formatTrailerName } from '~/services/Formatters';

import type { HistoryTrailerEntry } from '../../../../models';

import type { SectionName } from './constants';
import type { HistoryTrailerDetailsPaneUserPreferences } from './preferences';
import { HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface TrailerDetailsPaneProps {
    entry: HistoryTrailerEntry;
    handleClose: () => void;
}

export interface DispatchProps {
    changeMiniMapMode: Dispatch<MapModes>;
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
}

export interface TrailerDetailsPaneInnerProps
    extends TrailerDetailsPaneProps,
        InjectedDisplayPreferencesProps,
        DispatchProps,
        InjectedTranslationProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    HistoryTrailerEntry,
    HistoryTrailerDetailsPaneUserPreferences,
    SectionName
>(HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY);

export const TrailerDetailsPaneComponent: React.FC<TrailerDetailsPaneInnerProps> = ({
    changeMiniMapMode,
    changeMiniMapZoomLevel,
    displayPreferences,
    entry,
    handleClose,
    t,
}) => {
    const getSections = (
        userPreferences: HistoryTrailerDetailsPaneUserPreferences,
        toggleCollapsedState: (sectionName: SectionName) => () => void
    ) =>
        getSectionsMemoized(
            entry,
            userPreferences.collapsedSections,
            toggleCollapsedState,
            userPreferences.miniMapZoomLevel,
            changeMiniMapZoomLevel,
            userPreferences.miniMapMode,
            changeMiniMapMode
        );

    const trailerName = entry.trailer
        ? formatTrailerName(entry.trailer ?? entry.trailerEvent.trailer, displayPreferences.trailerDisplayFormat)
        : undefined;
    const header = <DetailsPaneHeader onClose={handleClose} title={t('trailer-event-of', { trailerName })} />;

    return (
        <DetailsPaneComponent
            dataId="trailer-details-pane"
            entry={entry}
            getSections={getSections}
            HeaderReactNode={header}
        />
    );
};
