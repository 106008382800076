import type { ContainersResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    CONTAINERS = 'CONTAINERS',
}

export enum ActionTypeKeys {
    CONTAINERS_FULFILLED = 'CONTAINERS_FULFILLED',
    CONTAINERS_PENDING = 'CONTAINERS_PENDING',
    CONTAINERS_REJECTED = 'CONTAINERS_REJECTED',
}

export type ContainersPayload = Readonly<ContainersResponse>;

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.CONTAINERS_FULFILLED, ContainersPayload>
    | Pending<ActionTypeKeys.CONTAINERS_PENDING>
    | Rejected<ActionTypeKeys.CONTAINERS_REJECTED>;
