import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import type { UnitSystemConverterOptions, UnitSystemFormatterOptions, UnitSystemUtils } from './unitSystemUtils';

export const getDensityUnitSystemUtils = memoizeOne((t: SingleTFunction): UnitSystemUtils => {
    const unit = t('unit-kgm3');
    const defaultPrecision = 1;

    return {
        converter: (value?: number, options?: UnitSystemConverterOptions) =>
            !isUndefined(value) ? Number(value.toFixed(options?.precision ?? defaultPrecision)) : undefined,
        formatter: (value?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(value) ? `${value.toFixed(options?.precision ?? defaultPrecision)} ${unit}` : '',
        unit,
    };
});
