import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type { CommunicationEntry } from '~/data/communication';
import type { ConversationsRootStoreState } from '~/modules/Communication';
import { resolveConversationMessage } from '~/modules/Communication';
import type { Message } from '~/services/ApiClient';
import { vehicleResolver } from '~/services/ModelResolvers';

export const resolveCommunicationEntries = (
    messages: Message[],
    conversationsRoot: ConversationsRootStoreState,
    staticDataStoreReducer: StaticDataStoreState,
    settings: SettingsStoreState
): CommunicationEntry[] => {
    return messages.map((message) => {
        const communicationEntry = {
            enableMultiselection: true,
            message: resolveConversationMessage(message, conversationsRoot.workflowFormDefinitions.data),
            vehicle: vehicleResolver({
                categories: settings.vehicleCategories.data,
                deviceTypes: settings.deviceTypes.data,
                vehicle: staticDataStoreReducer.vehicles.data.hash[message.vehicleId],
                vehicleTypes: settings.vehicleTypes.data,
            }),
        };
        return communicationEntry;
    });
};
