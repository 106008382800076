import type { Disposable } from '~/listeners';

import type { ObserveStateArgs } from './models';

export const observeState = <Store, State>({
    onChange,
    stateSelector,
    store,
}: ObserveStateArgs<Store, State>): Disposable => {
    let previousValue = stateSelector(store.getState());
    const listener = () => {
        const nextValue = stateSelector(store.getState());
        if (previousValue !== nextValue) {
            onChange(nextValue, previousValue);
            previousValue = nextValue;
        }
    };

    return {
        dispose: store.subscribe(listener),
    };
};
