import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MessageContent = styled(Typography)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    fontStyle: 'italic',
    justifyContent: 'center',
    margin: theme.spacing(2),
    minHeight: theme.custom.dialogContent.minHeight,
}));

const TitleWrapper = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    justifyContent: 'space-between',
    width: '350px',
});

const GraphWrapper = styled(Box)(({ theme }) => ({
    margin: theme.spacing(2),
    minHeight: theme.custom.dialogContent.minHeight,
    overflow: 'hidden',
}));

const StyledHeader = styled(Typography)({ alignSelf: 'center', flexGrow: 1 });

export { GraphWrapper, MessageContent, StyledHeader, TitleWrapper };
