import type { LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { Component } from 'react';
import type { ComponentType } from 'react';
import { wrapDisplayName } from 'react-recompose';
import type { Subtract } from 'utility-types';

import { StyledPopup } from '../StyledPopup';

import type { InjectedMarkerPopupProps, MarkerPopupExtendedInnerProps } from './models';

export const withMarkerPopupHoc = <T extends InjectedMarkerPopupProps>(
    WrappedComponent: ComponentType<T>
): ComponentType<MarkerPopupExtendedInnerProps & Subtract<T, InjectedMarkerPopupProps>> => {
    type HocProps = Subtract<T, InjectedMarkerPopupProps>;
    type HocInnerProps = HocProps & MarkerPopupExtendedInnerProps;

    return class WithMarkerPopup extends Component<HocInnerProps> {
        public static displayName = wrapDisplayName(WrappedComponent, 'WithMarkerPopup');

        private cancelOldHidePopup = () => {
            clearTimeout(this.leafletTimeout);
        };

        private hidePopup = () => {
            if (this.leafletEvents.length) {
                this.leafletEvents.forEach((e) => {
                    e.target.closePopup(e.target);
                });
                this.leafletEvents = [];
            }
        };

        private leafletEvents: LeafletEvent[] = [];

        private leafletTimeout: number;

        private scheduleNewHidePopup = () => {
            this.cancelOldHidePopup();
            this.leafletTimeout = window.setTimeout(this.hidePopup, 1000);
        };

        private showPopup = (event: LeafletMouseEvent) => {
            this.cancelOldHidePopup();
            this.leafletEvents.push(event);
            this.leafletTimeout = window.setTimeout(() => {
                event.target.openPopup(event.latlng);
            }, 500);
        };

        public render() {
            const { classes, eventHandlers, popupContent, popupKey, ...restProps } = this.props;

            return (
                <WrappedComponent
                    {...(restProps as unknown as T)}
                    eventHandlers={{
                        ...eventHandlers,
                        mouseout: this.scheduleNewHidePopup,
                        mouseover: (event: LeafletMouseEvent) => {
                            if (eventHandlers?.mouseover) {
                                eventHandlers?.mouseover(event);
                            }

                            this.showPopup(event);
                        },
                    }}
                    {...(popupContent && {
                        popup: {
                            content: (
                                <div
                                    data-id="popup-wrapper"
                                    onMouseEnter={this.cancelOldHidePopup}
                                    onMouseLeave={this.scheduleNewHidePopup}
                                >
                                    <StyledPopup className={classes.popup}>{popupContent}</StyledPopup>
                                </div>
                            ),
                            key: popupKey,
                        },
                    })}
                />
            );
        }
    };
};
