import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useBulkCheckbox } from '../../hooks';

import type { HeaderProps } from './models';
import { useStyles } from './styles';

const Header = <TId,>(props: HeaderProps<TId>) => {
    const { equalityComparer, onSearchTextChange, options, searchText, selected, title, toggleSelected } = props;

    const classes = useStyles();
    const visibleValues = options.map((option) => option.id);
    const { bulkChecked, bulkIndeterminate, toggleBulk } = useBulkCheckbox({
        equalityComparer,
        items: visibleValues,
        selected,
        toggleSelected,
    });

    return (
        <ListItem className={classes.root} component="div" data-testid="search-bar" dense>
            <ListItemIcon className={classes.iconContainer} data-testid="select-all">
                <Checkbox
                    checked={bulkChecked}
                    className={classes.checkbox}
                    disableRipple
                    indeterminate={bulkIndeterminate}
                    onClick={toggleBulk}
                />
            </ListItemIcon>

            <ListItemText
                primary={title}
                secondary={
                    <Input
                        endAdornment={
                            searchText && (
                                <Clear
                                    className={classes.clearSearchText}
                                    data-testid="clear-icon"
                                    onClick={() => onSearchTextChange('')}
                                />
                            )
                        }
                        fullWidth
                        inputProps={{ 'data-testid': `search-input` }}
                        margin="dense"
                        onChange={(evt) => onSearchTextChange(evt.target.value)}
                        startAdornment={<Search data-testid="search-icon" />}
                        value={searchText}
                    />
                }
                secondaryTypographyProps={{ component: 'span' }}
            />
        </ListItem>
    );
};

Header.displayName = 'Header';
export { Header };
