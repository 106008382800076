import { defaultProps } from 'react-recompose';

import { ClassificationFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification, ClassificationType, PojoModel } from '~/services/ApiClient';
import { exportFormatterFactory, formatClassification, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, stringComparer } from '~/services/Sorting';

import { classificationFactory, classificationFilterEquals, getClassificationOptions } from './classifications.utility';
import type { CreateColumnOptions, GridColumnDefinition } from './models';

export interface CreateClassificationColumnOptions extends CreateColumnOptions {
    classifications?: Classification[];
    valueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
}

export const createClassificationColumn = <T>(
    t: SingleTFunction,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Classification | PojoModel<Classification> | undefined,
    classificationKey: ClassificationType,
    options?: CreateClassificationColumnOptions
): GridColumnDefinition<T> => ({
    valueFormatterComponent: defaultProps({ classificationKey, t })(ClassificationFormatter),
    ...options,
    align: 'left',
    cellFiltering: options?.classifications
        ? {
              options: getClassificationOptions<Classification>(
                  options.classifications,
                  classificationFactory(t, classificationKey)
              ),
              valueEquals: classificationFilterEquals,
          }
        : undefined,
    compare: compareFactory((v: Classification) => formatClassification(t, classificationKey, v), stringComparer),
    dataType: 'string',
    exportValueFormatter: exportFormatterFactory((v: Classification) => formatClassification(t, classificationKey, v)),
    getCellValue,
    groupingCriteria: groupingCriteriaFactory((v: Classification) => formatClassification(t, classificationKey, v)),
    groupTitle,
    name,
    title,

    valueTextFormatter: valueTextFormatterFactory((v: Classification) => formatClassification(t, classificationKey, v)),
});
