import type {
    QueryRtdsSchedulesRequest,
    QueryRtdsSchedulesResponse,
    RtdsSchedule,
    RtdsScheduleChangelogsResponse,
    SaveRtdsScheduleRequest,
} from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export interface RtdsScheduleActionTypeMeta {
    items: RtdsSchedule[];
}

interface RtdsScheduleActionEditTypeMeta {
    id: number;
    item: RtdsSchedule;
}

export enum ActionTypePrefixes {
    RTDSSCHEDULES_CREATE = 'RTDSSCHEDULES_CREATE',
    RTDSSCHEDULES_DELETE = 'RTDSSCHEDULES_DELETE',
    RTDSSCHEDULES_QUERY = 'RTDSSCHEDULES_QUERY',
    RTDSSCHEDULES_UPDATE = 'RTDSSCHEDULES_UPDATE',
    RTDSSCHEDULESCHANGELOGS_GET = 'RTDSSCHEDULESCHANGELOGS_GET',
}

export enum ActionTypeKeys {
    RTDSSCHEDULES_CREATE_FULFILLED = 'RTDSSCHEDULES_CREATE_FULFILLED',
    RTDSSCHEDULES_CREATE_PENDING = 'RTDSSCHEDULES_CREATE_PENDING',
    RTDSSCHEDULES_CREATE_REJECTED = 'RTDSSCHEDULES_CREATE_REJECTED',
    RTDSSCHEDULES_DELETE_FULFILLED = 'RTDSSCHEDULES_DELETE_FULFILLED',
    RTDSSCHEDULES_DELETE_PENDING = 'RTDSSCHEDULES_DELETE_PENDING',
    RTDSSCHEDULES_DELETE_REJECTED = 'RTDSSCHEDULES_DELETE_REJECTED',
    RTDSSCHEDULES_QUERY_FULFILLED = 'RTDSSCHEDULES_QUERY_FULFILLED',
    RTDSSCHEDULES_QUERY_PENDING = 'RTDSSCHEDULES_QUERY_PENDING',
    RTDSSCHEDULES_QUERY_REJECTED = 'RTDSSCHEDULES_QUERY_REJECTED',
    RTDSSCHEDULES_UPDATE_FULFILLED = 'RTDSSCHEDULES_UPDATE_FULFILLED',
    RTDSSCHEDULES_UPDATE_PENDING = 'RTDSSCHEDULES_UPDATE_PENDING',
    RTDSSCHEDULES_UPDATE_REJECTED = 'RTDSSCHEDULES_UPDATE_REJECTED',
    RTDSSCHEDULESCHANGELOGS_GET_FULFILLED = 'RTDSSCHEDULESCHANGELOGS_GET_FULFILLED',
    RTDSSCHEDULESCHANGELOGS_GET_PENDING = 'RTDSSCHEDULESCHANGELOGS_GET_PENDING',
    RTDSSCHEDULESCHANGELOGS_GET_REJECTED = 'RTDSSCHEDULESCHANGELOGS_GET_REJECTED',
}

export type ActionTypes =
    | FulfilledMeta<
          ActionTypeKeys.RTDSSCHEDULES_QUERY_FULFILLED,
          Readonly<QueryRtdsSchedulesResponse>,
          QueryRtdsSchedulesRequest
      >
    | FulfilledMeta<
          ActionTypeKeys.RTDSSCHEDULES_UPDATE_FULFILLED,
          Readonly<SaveRtdsScheduleRequest>,
          RtdsScheduleActionEditTypeMeta
      >
    | FulfilledMeta<
          ActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_FULFILLED,
          Readonly<RtdsScheduleChangelogsResponse>,
          number
      >
    | FulfilledMeta<ActionTypeKeys.RTDSSCHEDULES_CREATE_FULFILLED, undefined, RtdsScheduleActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.RTDSSCHEDULES_DELETE_FULFILLED, undefined, number>
    | PendingMeta<ActionTypeKeys.RTDSSCHEDULES_CREATE_PENDING, RtdsSchedule[]>
    | PendingMeta<ActionTypeKeys.RTDSSCHEDULES_DELETE_PENDING, number>
    | PendingMeta<ActionTypeKeys.RTDSSCHEDULES_QUERY_PENDING, QueryRtdsSchedulesRequest>
    | PendingMeta<ActionTypeKeys.RTDSSCHEDULES_UPDATE_PENDING, RtdsScheduleActionEditTypeMeta>
    | PendingMeta<ActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_PENDING, number>
    | RejectedMeta<
          ActionTypeKeys.RTDSSCHEDULES_UPDATE_REJECTED,
          SaveRtdsScheduleRequest,
          RtdsScheduleActionEditTypeMeta
      >
    | RejectedMeta<ActionTypeKeys.RTDSSCHEDULES_CREATE_REJECTED, undefined, RtdsScheduleActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.RTDSSCHEDULES_DELETE_REJECTED, undefined, number>
    | RejectedMeta<ActionTypeKeys.RTDSSCHEDULES_QUERY_REJECTED, undefined, QueryRtdsSchedulesRequest>
    | RejectedMeta<ActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_REJECTED, undefined, number>;
