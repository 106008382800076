import { styled } from '@mui/material/styles';
import type { CSSProperties } from 'react';

type ContainerProps = {
    width?: CSSProperties['width'];
};

const Container = styled('div')<ContainerProps>(({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: typeof width === 'number' ? `${width}px` : width ?? '100%',
}));

export { Container };
