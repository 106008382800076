import type { WithStyles } from '@mui/styles';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { AssetGroup, AssetReference, AssetType, Depot } from '~/services/ApiClient';

import type { RequiredUserPreferences } from '../UserPreferences';

import type { SceneAssetSelectorUserPreferences, SceneAssetSelectorViewMode } from './preferences';
import type { SceneAssetSelectorStyleRules } from './styles';

export interface Asset {
    id: number;
}

export interface AssetReferenceWithDisplayName {
    displayName: string;
    reference: AssetReference;
}

export type AssetReferenceHash<T> = {
    [keys in AssetType]?: NumericDictionary<T>;
};

export interface AssetDataSet {
    assets: AssetReferenceWithDisplayName[];
    displayName: string;
    icon: JSX.Element;
    type: AssetType;
}

interface BasicNode {
    id: number | string;
    name: string;
}

export interface Node extends BasicNode {
    children?: BasicNode[];
}

export type SupportedNode = {
    hideCheckbox?: boolean;
    icon?: JSX.Element;
    isExpandable?: boolean;
} & (AssetNode | AssetTypeNode | DepotNode | GroupNode);

export enum NodeTypes {
    ASSET = 'ASSET',
    ASSET_TYPE = 'ASSET_TYPE',
    DEPOT = 'DEPOT',
    GROUP = 'GROUP',
}

export interface AssetNodeState {
    checked: boolean;
}

export interface AssetNode extends Node {
    assetReference: AssetReference;
    id: string;
    name: string;
    parentGroupId?: number;
    state: AssetNodeState;
    type: NodeTypes.ASSET;
}

export interface GroupNodeState {
    checked: boolean;
    disabled: boolean;
    expanded: boolean;
    hasAssetChildren: boolean;
    indeterminate: boolean;
}

export interface GroupNode extends Node {
    id: number;
    name: string;
    parentGroupId?: number;
    state: GroupNodeState;
    type: NodeTypes.GROUP;
}

export interface DepotNodeState {
    checked: boolean;
    disabled: boolean;
    expanded: boolean;
    hasAssetChildren: boolean;
    indeterminate: boolean;
}

export interface DepotNode extends Node {
    id: number;
    name: string;
    state: DepotNodeState;
    type: NodeTypes.DEPOT;
}

export interface AssetTypeNodeState {
    assetsCount: number;
    checked: boolean;
    expanded: boolean;
    hasAssetChildren: boolean;
    indeterminate: boolean;
    selectedAssetsCount: number;
}

export interface AssetTypeNode extends Node {
    id: AssetType;
    name: string;
    state: AssetTypeNodeState;
    type: NodeTypes.ASSET_TYPE;
}

export type CustomSceneUserPreferencesType<T> = {
    defaultState: SceneAssetSelectorUserPreferences | T;
} & Omit<RequiredUserPreferences, 'defaultState'>;

export interface SceneAssetSelectorDerivedProps {
    assetTree: SupportedNode[];
    changeViewMode: (viewMode: SceneAssetSelectorViewMode) => void;

    removeUnauthorizedExpandedDepots: () => void;
    removeUnauthorizedExpandedGroups: () => void;
    removeUnauthorizedSelectedAssets: () => void;
    setCollapsedAssetTypes: (collapsedAssetTypesIds: AssetType[]) => void;
    setExpandedDepots: (depotIds: number[]) => void;
    setExpandedGroups: (groupIds: number[]) => void;
    setSelectedAssets: (assetIds: AssetReference[], parentGroupId?: number) => void;

    setSelectedDepots: (depotIds: number[]) => void;
    setSelectedGroups: (groupIds: number[]) => void;
    viewMode: SceneAssetSelectorViewMode;
}

export interface SceneAssetSelectorProps {
    assetDataSets: AssetDataSet[];
    assetGroups: AssetGroup[];
    className?: string;
    depots: Depot[];
    onAssetContextMenu?: (event: MouseEvent, assetId: AssetReference) => void;

    onSelectedAssetIdsChange?: (selectedAssetIds: AssetReference[]) => void;
    preferencesKey: string;
    // Use selectedAssetIds to use the asset selector in a controlled way
    selectedAssetIds?: AssetReference[];

    singleSelectionMode?: boolean;
}

export interface SceneAssetSelectorInnerProps
    extends SceneAssetSelectorProps,
        InjectedTranslationProps,
        WithStyles<typeof SceneAssetSelectorStyleRules, true> {}
