import { getValueFromHash } from '~/common';
import type { DurationInput } from '~/libs/dates';
import { addDates, durationToString, format, startOfDay, stringToDuration, sumDurations } from '~/libs/dates';
import { uuidv4 } from '~/libs/utility';
import type { AssetType } from '~/services/ApiClient';
import { themes } from '~/theme';

import { ACTIVITY_TYPES } from './consts';
import type {
    DriverActivitiesResolverArgs,
    QueryAssetActivitiesReportByIdsReadGraphResolved,
    QueryDriverActivitiesReportByIdsRead,
    QueryDriverActivitiesReportByIdsReadResolved,
    QueryDriverActivitiesReportByIdsReadResolvedBase,
} from './models';

const driverActivitiesResolver = (args: DriverActivitiesResolverArgs) => {
    const { drivers, groupingOptions, source, vehicles } = args;
    // This is managed through userPreferences state, whenever the checkboxes of the different grouping options in the header menu. If none of the options are selected, we don't want to calculate any of the subtotals
    // but the because the final subtotal is not managed by the API we need to check when we need to calculate it or not, similar to how we decide if we want rest.drive to be used in the calculation or not (below)

    return source.map(
        ({ driver, vehicle, ...driverActivityReportItem }): QueryDriverActivitiesReportByIdsReadResolvedBase => {
            const subtotalToBeCalculated: DurationInput[] = [];
            if (groupingOptions?.Drive.includeInSubTotal) {
                subtotalToBeCalculated.push(driverActivityReportItem.drive ?? 'P0Y');
            }
            if (groupingOptions?.Available.includeInSubTotal) {
                subtotalToBeCalculated.push(driverActivityReportItem.available ?? 'P0Y');
            }
            if (groupingOptions?.Work.includeInSubTotal) {
                subtotalToBeCalculated.push(driverActivityReportItem.work ?? 'P0Y');
            }
            if (groupingOptions?.Rest.includeInSubTotal) {
                subtotalToBeCalculated.push(driverActivityReportItem.rest ?? 'P0Y');
            }
            if (groupingOptions?.Rest.includeInSubTotal && !groupingOptions?.ignoreNightRestInTotals) {
                subtotalToBeCalculated.push(driverActivityReportItem.nightRest ?? 'P0Y');
            }

            return {
                ...driverActivityReportItem,
                ...(driver && { driver: getValueFromHash(drivers, driver.id) }),
                ...(vehicle && { vehicle: getValueFromHash(vehicles, vehicle.id) }),
                id: uuidv4(),
                subtotal: durationToString(sumDurations(...subtotalToBeCalculated) ?? 'P0Y'),
                total: durationToString(
                    sumDurations(
                        driverActivityReportItem.drive ?? 'P0Y',
                        driverActivityReportItem.available ?? 'P0Y',
                        driverActivityReportItem.work ?? 'P0Y',
                        driverActivityReportItem.rest ?? 'P0Y',
                        !groupingOptions?.ignoreNightRestInTotals ? driverActivityReportItem.nightRest ?? 'P0Y' : 'P0Y'
                    )
                ),
            };
        }
    );
};

const driverActivitiesListResolver = (args: DriverActivitiesResolverArgs) => {
    const { drivers, groupingOptions, source, vehicles } = args;

    const resolvedItems = driverActivitiesResolver({ drivers, groupingOptions, source, vehicles });

    return resolvedItems.map(
        ({ date, ...restOfProps }): QueryDriverActivitiesReportByIdsReadResolved => ({
            ...restOfProps,
            date: startOfDay(date),
            startTime: format(date, 'HH:mm:ss'),
        })
    );
};

const getAxis = (item: Partial<QueryDriverActivitiesReportByIdsRead>) => {
    const { available, drive, nightRest, rest, work } = item;

    return {
        xAxis:
            ((nightRest || rest) && ACTIVITY_TYPES.REST) ||
            (work && ACTIVITY_TYPES.WORK) ||
            (drive && ACTIVITY_TYPES.DRIVE) ||
            ((available && ACTIVITY_TYPES.AVAILABLE) as string),
        yAxis: nightRest || rest || work || drive || available || 'P0Y',
    };
};

const driverActivitiesGraphResolver = (args: DriverActivitiesResolverArgs, assetType: AssetType) => {
    const { drivers, groupingOptions, source, vehicles } = args;

    // Resolve driver activities
    const resolvedActivities = driverActivitiesResolver({ drivers, groupingOptions, source, vehicles });

    const result = {}; // Use a plain object to accumulate results

    for (const value of resolvedActivities) {
        const propertyValue = value[assetType];

        if (!propertyValue) {
            // Skip entries without the specified propertyKey
            continue;
        }

        // Precomputed frequently used values
        const dateFormatted = format(value.date, 'P');
        const key = `${dateFormatted}-${propertyValue.id}`;
        const { xAxis, yAxis } = getAxis(value);
        const duration = stringToDuration(yAxis);

        // Check if the graph entry already exists, if not, initialize it
        if (!result[key]) {
            result[key] = {
                date: value.date,
                driver: value.driver,
                id: value.id,
                series: [],
                subtotal: 'P0Y',
                total: 'P0Y',
                vehicle: value.vehicle,
            };
        }

        const graphEntry = result[key];

        // Cache result of sumDurations to avoid multiple function calls
        const updatedXAxisDuration = sumDurations(graphEntry[xAxis] || 'P0Y', duration);
        const updatedSubtotalDuration = sumDurations(graphEntry.subtotal, value.subtotal);
        const updatedTotalDuration = sumDurations(graphEntry.total, value.total);

        // Update graph entry properties
        graphEntry[xAxis] = durationToString(updatedXAxisDuration);
        graphEntry.subtotal = durationToString(updatedSubtotalDuration);
        graphEntry.total = durationToString(updatedTotalDuration);

        // Push series data
        graphEntry.series.push({
            fillColor: xAxis && themes.default.functionalItemsColors.driverActivity[xAxis]?.value,
            meta: {
                address: value.location?.address,
                date: value.date,
                distance: value.distance,
                driver: value.driver,
                duration: yAxis,
                vehicle: value.vehicle,
            },
            x: xAxis,
            y: [value.date.getTime(), addDates(value.date, duration).getTime()],
        });
    }

    // Convert accumulated results to an array
    return Object.values<QueryAssetActivitiesReportByIdsReadGraphResolved>(result);
};

export { driverActivitiesGraphResolver, driverActivitiesListResolver, getAxis };
