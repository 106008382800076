import type { DriverHoursByVehicleResponse, VehicleDriverHoursStatus } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    DRIVERHOURSBYVEHICLE = 'DRIVERHOURSBYVEHICLE',
}

export enum ActionTypeKeys {
    DRIVERHOURSBYVEHICLE_FULFILLED = 'DRIVERHOURSBYVEHICLE_FULFILLED',
    DRIVERHOURSBYVEHICLE_PENDING = 'DRIVERHOURSBYVEHICLE_PENDING',
    DRIVERHOURSBYVEHICLE_REJECTED = 'DRIVERHOURSBYVEHICLE_REJECTED',
    DRIVERHOURSBYVEHICLE_UPDATE = 'DRIVERHOURSBYVEHICLE_UPDATE',
}

export type DriverHoursByVehiclePayload = Readonly<DriverHoursByVehicleResponse>;

export type ActionTypes =
    | Action<ActionTypeKeys.DRIVERHOURSBYVEHICLE_UPDATE, VehicleDriverHoursStatus>
    | Fulfilled<ActionTypeKeys.DRIVERHOURSBYVEHICLE_FULFILLED, DriverHoursByVehiclePayload>
    | Pending<ActionTypeKeys.DRIVERHOURSBYVEHICLE_PENDING>
    | Rejected<ActionTypeKeys.DRIVERHOURSBYVEHICLE_REJECTED>;
