import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TachoFilesClassKeys = 'backDrop' | 'loader' | 'menuButton' | 'menuIcon' | 'messageText' | 'root';

export const TachoFilesStyleRules: StyleRulesCallback<Theme, {}, TachoFilesClassKeys> = (theme: Theme) =>
    createStyles({
        backDrop: {
            color: '#fff',
            position: 'absolute',
            zIndex: theme.zIndex.drawer,
        },
        loader: {
            color: theme.palette.primary.main,
        },
        menuButton: {
            textTransform: 'none',
        },
        menuIcon: {
            marginRight: theme.spacing(),
            minWidth: 'unset',
        },
        messageText: {
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(2),
            width: '100%',
        },
        root: {
            height: '100%',
        },
    });
