import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationWidgetHeaderClassKey =
    | 'actionButton'
    | 'backButton'
    | 'icon'
    | 'messageInfo'
    | 'root'
    | 'vehicleDisplayName'
    | 'vehicleName'
    | 'warningIcon';

export const ConversationWidgetHeaderStyleRules: StyleRulesCallback<Theme, {}, ConversationWidgetHeaderClassKey> = (
    theme: Theme
) =>
    createStyles({
        actionButton: {
            fontSize: 14,
            height: 24,
            padding: 0,
            width: 24,
        },
        backButton: {
            fontSize: 14,
        },
        icon: {
            color: theme.palette.text.secondary,
            fontSize: 14,
            marginRight: theme.spacing(),
        },

        messageInfo: {
            marginLeft: 3,
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden',
        },
        vehicleDisplayName: {
            display: 'flex',
            lineHeight: 'unset',
            marginRight: theme.spacing(),
            overflow: 'hidden',
        },
        vehicleName: {
            flex: '1 1 0',
            maxWidth: 150,
        },
        warningIcon: {
            color: theme.palette.warning.dark,
        },
    });
