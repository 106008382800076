import type { FC } from 'react';

import type { ControlButtonProps } from './models';
import { Button } from './styles';

const ControlButton: FC<ControlButtonProps> = (props) => {
    const { children, isDisable, onClick, title } = props;

    return (
        <Button
            disabled={isDisable}
            onClick={(event) => {
                onClick?.(event);
                event.preventDefault();
            }}
            title={title}
            variant="contained"
        >
            {children}
        </Button>
    );
};

export { ControlButton };
