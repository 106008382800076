import type { Dispatch } from 'redux';

import { maxNumberOfMessagesToDisplay } from '~/data/monitoring';
import { throttle } from '~/libs/utility';
import type { InjectedUserMessagingProfilesProps } from '~/modules/Communication';
import { openConversationAction } from '~/modules/Communication';
import type { RetrievableData } from '~/reducers';
import { reportError } from '~/reporting';
import type { ConversationNotification, ResolvedMessage, Securables } from '~/services/ApiClient';

import type { DispatchProps, LatestMessagesSectionProps, StateProps } from './component';
import {
    applyPendingUpdatesAction,
    clearLatestConversationMessagesAction,
    retrieveLatestMessagesAction,
    updateLatestMessagesAction,
} from './data';

export const autoUpdateThrottleTimeout = 2000;

export const mapStateToProps = (
    securables: Securables,
    latestMessagesState: RetrievableData<ResolvedMessage[] | undefined> | undefined,
    deletedMessagesState: number[]
): StateProps => {
    const stateProps: StateProps = {
        canViewMessages: securables.messaging.view,
        dataWasNotRetrieved: true,
        deletedMessageIds: [],
        failedToLoadMessages: false,
        latestMessages: [],
        loadingMessages: true,
    };

    if (latestMessagesState) {
        stateProps.loadingMessages = latestMessagesState.pending;
        stateProps.failedToLoadMessages = latestMessagesState.rejected;
        stateProps.dataWasNotRetrieved =
            !latestMessagesState.pending && !latestMessagesState.fulfilled && !latestMessagesState.rejected;

        if (latestMessagesState.data) {
            stateProps.latestMessages = latestMessagesState.data;
        }

        if (deletedMessagesState) {
            stateProps.deletedMessageIds = deletedMessagesState;
        }
    }

    return stateProps;
};

type LatestMessagesSectionPropsOwnProps = InjectedUserMessagingProfilesProps & LatestMessagesSectionProps;

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: LatestMessagesSectionPropsOwnProps): DispatchProps => {
    const refreshThrottled = throttle(
        () => dispatch(applyPendingUpdatesAction(ownProps.entry.vehicle.id)),
        autoUpdateThrottleTimeout
    );

    return {
        clearLatestMessages: () => {
            dispatch(clearLatestConversationMessagesAction(ownProps.entry.vehicle.id));
        },
        getLatestMessages: () => {
            dispatch(
                retrieveLatestMessagesAction(
                    ownProps.entry.vehicle.id,
                    maxNumberOfMessagesToDisplay,
                    ownProps.defaultConversationProfileId
                )
            ).catch(reportError);
        },
        openConversation: () => {
            dispatch(openConversationAction(ownProps.entry.vehicle.id, 'last-messages-section'));
        },
        updateLatestMessages: (conversation: ConversationNotification) => {
            dispatch(updateLatestMessagesAction(conversation));
            refreshThrottled();
        },
    };
};
