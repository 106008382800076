import type { MessageBody } from '~/services/ApiClient';
import {
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    createApiModel,
} from '~/services/ApiClient';

const optimisticOutgoingConversationMessageId = 0;

export const buildOptimisticOutgoingConversationMessage = (
    correlationId: string,
    body: MessageBody,
    author: string,
    vehicleId: number
): OptimisticOutgoingConversationMessage =>
    createApiModel(OptimisticOutgoingConversationMessage, {
        author,
        body,
        correlationId,
        dateTime: new Date(),
        id: optimisticOutgoingConversationMessageId,
        isDeleted: false,
        msisdn: '',
        revision: 1,
        status: OptimisticOutgoingConversationMessageStatus.Sending,
        statusChangedDateTime: new Date(),
        vehicleId,
    });
