import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionMarkerClassKey = 'positionIcon';

export const PositionMarkerStyleRules: StyleRulesCallback<Theme, {}, PositionMarkerClassKey> = (theme: Theme) =>
    createStyles({
        positionIcon: {
            alignItems: 'center',
            color: theme.palette.common.white,
            display: 'flex',
            fontSize: 6,
        },
    });
