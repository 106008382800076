import { InputAdornment, TextField } from '@mui/material';
import { KEY_ADD, KEY_DASH, KEY_DECIMAL, KEY_PERIOD, KEY_SUBTRACT } from 'keycode-js';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { SettingItemHeaderProps } from '../SettingItemHeader';
import { SettingItemHeader } from '../SettingItemHeader';

export interface NumericInputSettingItemProps extends Omit<SettingItemHeaderProps, 'action'> {
    actionDataId: string;
    adornment?: string;
    disabled?: boolean;
    errorLabel: string;
    invalidInput: (value: number) => boolean;
    onChange: (value: number) => void;
    type: string;
    value: number;
}

export interface NumericInputSettingItemInnerProps extends NumericInputSettingItemProps, InjectedTranslationProps {}

export const NumericInputSettingItemComponent: React.FC<NumericInputSettingItemInnerProps> = ({
    actionDataId,
    adornment,
    disabled,
    errorLabel,
    i18n,
    invalidInput,
    onChange,
    t,
    type,
    value,
    ...restProps
}) => {
    const [inputValue, setInputValue] = React.useState<number>(value);

    const onChangeHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const eventValue = event.target.value;
            const eventValueNumber = parseInt(eventValue, 10);
            setInputValue(eventValueNumber);

            if (!invalidInput(eventValueNumber)) {
                onChange(eventValueNumber);
            }
        },
        [invalidInput, onChange]
    );

    const onKeyDownDurationField = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            event.keyCode === KEY_DECIMAL ||
            event.keyCode === KEY_PERIOD ||
            event.keyCode === KEY_ADD ||
            event.keyCode === KEY_SUBTRACT ||
            event.keyCode === KEY_DASH
        ) {
            event.preventDefault();
        }
    }, []);

    const onBlur = React.useCallback(() => {
        if (invalidInput(inputValue)) {
            setInputValue(value);
        }
    }, [inputValue, invalidInput, value]);

    const error = invalidInput(inputValue);

    const inputProps = React.useMemo(() => {
        return {
            endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
        };
    }, [adornment]);
    const action = (
        <TextField
            data-id={actionDataId}
            disabled={disabled}
            error={error}
            helperText={error ? errorLabel : undefined}
            InputProps={inputProps}
            label={error ? t('input-error') : undefined}
            onBlur={onBlur}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownDurationField}
            type={type}
            value={inputValue}
            variant="outlined"
        />
    );

    return <SettingItemHeader {...restProps} action={action} />;
};
