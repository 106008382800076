export const HISTORY_USERPREFERENCES_KEY = 'history-state';

export interface HistoryUserPreferences {
    driverActivityGraphToggleState: boolean;
    leftPaneIsOpen: boolean;
}

export const defaultHistoryUserPreferences: HistoryUserPreferences = {
    driverActivityGraphToggleState: true,
    leftPaneIsOpen: true,
};

export const requiredHistoryUserPreferences = {
    defaultState: defaultHistoryUserPreferences,
    key: HISTORY_USERPREFERENCES_KEY,
};

export const HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY = 'history-vehicle-assetselector-state';
export const HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY = 'history-trailer-assetselector-state';
export const HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY = 'history-driver-assetselector-state';
