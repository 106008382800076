import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY =
    'vehicle-types-administration-detailspane-state';

export interface VehicleTypesAdministrationDetailsPaneUserPreferences
    extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultVehicleTypesAdministrationDetailsPaneUserPreferences: VehicleTypesAdministrationDetailsPaneUserPreferences =
    {
        collapsedSections: [],
        sectionOrder: [
            SectionName.VEHICLETYPESADMINISTRATION_HEADER,
            SectionName.VEHICLETYPESADMINISTRATION_GENERAL,
            SectionName.VEHICLETYPESADMINISTRATION_DIMENSIONS,
            SectionName.VEHICLETYPESADMINISTRATION_TYPE,
            SectionName.VEHICLETYPESADMINISTRATION_SPEEDLIMITS,
        ],
    };

export const processResponse = (
    preferences: Partial<VehicleTypesAdministrationDetailsPaneUserPreferences>
): VehicleTypesAdministrationDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(
        preferences,
        defaultVehicleTypesAdministrationDetailsPaneUserPreferences
    );
};

export const requiredVehicleTypesAdministrationDetailsPaneUserPreferences = {
    defaultState: defaultVehicleTypesAdministrationDetailsPaneUserPreferences,
    key: VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
};
