import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import type { ValueProps } from './models';
import { useStyles } from './styles';

const Value = <TId,>(props: ValueProps<TId>) => {
    const { hasChildren, isCollapsed, option, selected, testId, toggleCollapse, toggleSelected } = props;
    const { displayName, icon, id } = option;

    const classes = useStyles();

    return (
        <>
            <ListItemIcon className={classes.iconContainer} data-testid={`option:${testId}:iconContainer`}>
                {hasChildren && isCollapsed !== undefined && toggleCollapse && (
                    <IconButton
                        className={classes.expand}
                        data-testid={`option:${testId}:expand`}
                        disableRipple
                        onClick={() => toggleCollapse(id)}
                    >
                        {isCollapsed ? (
                            <ChevronRight data-testid="chevron-icon" />
                        ) : (
                            <ExpandMore data-testid="expand-icon" />
                        )}
                    </IconButton>
                )}
                <Checkbox
                    checked={selected}
                    className={classes.checkbox}
                    data-testid={`option:${testId}:checkbox`}
                    disableRipple
                    onClick={toggleSelected}
                />
            </ListItemIcon>

            <ListItemIcon className={classes.iconContainer} data-testid={`option:${testId}:icon`}>
                {icon}
            </ListItemIcon>

            <ListItemText
                className={classes.text}
                data-testid={`option:${testId}:title`}
                primaryTypographyProps={{ noWrap: true }}
            >
                {displayName}
            </ListItemText>
        </>
    );
};

Value.displayName = 'Value';
export { Value };
