import type { RetrievableData } from '~/reducers';

import type { ActionTypes, TimeSeriesDialogMeta } from './data/actionTypes';
import { ActionTypeKeys } from './data/actionTypes';

export interface TimeSeriesDialogStoreState {
    completedMeta?: TimeSeriesDialogMeta;
    pendingMeta?: TimeSeriesDialogMeta;
    timeSeriesData: RetrievableData<unknown[]>;
}

export const defaultStoreState: TimeSeriesDialogStoreState = {
    timeSeriesData: {
        data: [],
        fulfilled: false,
        pending: false,
        rejected: false,
    },
};

export const timeSeriesDialogReducer = (
    state: TimeSeriesDialogStoreState = defaultStoreState,
    action: ActionTypes
): TimeSeriesDialogStoreState => {
    switch (action.type) {
        case ActionTypeKeys.TIMESERIESDIALOG_CLEAR:
            return defaultStoreState;

        case ActionTypeKeys.TIMESERIESDIALOG_PENDING:
            return {
                ...state,
                pendingMeta: action.meta,
                timeSeriesData: {
                    ...state.timeSeriesData,
                    pending: true,
                },
            };

        case ActionTypeKeys.TIMESERIESDIALOG_FULFILLED:
            if (state.pendingMeta !== action.meta) {
                return state;
            }

            return {
                ...state,
                completedMeta: action.meta,
                pendingMeta: undefined,
                timeSeriesData: {
                    data: action.payload,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
            };

        case ActionTypeKeys.TIMESERIESDIALOG_REJECTED:
            if (state.pendingMeta !== action.meta) {
                return state;
            }

            return {
                ...state,
                completedMeta: action.meta,
                pendingMeta: undefined,
                timeSeriesData: {
                    data: [],
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
            };

        default:
            return state;
    }
};
