import { Tooltip as MuiTooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';

export interface CloseOnClickTooltipProps
    extends Pick<TooltipProps, Exclude<keyof TooltipProps, 'onClose' | 'onOpen' | 'open'>> {}

export interface CloseOnClickTooltipState {
    open: boolean;
}

const CloseOnClickTooltip: React.FC<CloseOnClickTooltipProps> = React.forwardRef(({ children, ...restProps }, ref) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <MuiTooltip {...restProps} onClose={handleClose} onOpen={handleOpen} open={open} ref={ref}>
            <span onClick={handleClose}>{children}</span>
        </MuiTooltip>
    );
});
CloseOnClickTooltip.displayName = 'CloseOnClickTooltip';

export { CloseOnClickTooltip };
