import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import type { ResolvedTrailerConnection } from '~/services/ApiClient';

import { TemperaturesSection } from '../../../TemperaturesSection';

export interface ConnectedTrailerTemperaturesSectionProps {
    changeCollapsedCompartments: (collapsedCompartments: number[]) => void;
    collapsedCompartments: number[];
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    trailerConnection?: ResolvedTrailerConnection;
}

export interface ConnectedTrailerTemperaturesSectionInnerProps
    extends ConnectedTrailerTemperaturesSectionProps,
        InjectedTranslationProps {}

export const ConnectedTrailerTemperaturesSectionComponent: React.FC<ConnectedTrailerTemperaturesSectionInnerProps> = ({
    changeCollapsedCompartments,
    collapsedCompartments,
    dragHandleElement,
    isCollapsed,
    t,
    toggleCollapsed,
    trailerConnection,
}) => {
    if (trailerConnection?.trailer) {
        return (
            <TemperaturesSection
                changeCollapsedCompartments={changeCollapsedCompartments}
                collapsedCompartments={collapsedCompartments}
                compartments={Object.values(trailerConnection.trailerStatus?.compartments ?? {})}
                dragHandleElement={dragHandleElement}
                isCollapsed={isCollapsed}
                toggleCollapsed={toggleCollapsed}
                trailer={trailerConnection.trailer}
            />
        );
    }

    const messageKey = trailerConnection?.trailerReference ? 'temperature-data-not-authorized' : 'not-connected';

    return (
        <ReorderableSection
            dataId="temperatures"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('temperatures')}
            toggleCollapsed={toggleCollapsed}
        >
            <SectionContentMessage dataId={messageKey}>{t(messageKey)}</SectionContentMessage>
        </ReorderableSection>
    );
};
