import MenuIcon from '@mui/icons-material/Menu';
import type { WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { TitledIconButton } from '~/components/TitledIconButton';

import type { MenuItem } from '../../../../models';

import { MenuBarItem } from './components/MenuBarItem';
import type { MenuBarClassKey } from './styles';

export interface MenuBarProps {
    items: MenuItem[];
    onMenuClick: () => void;
}

export interface MenuBarInnerProps extends MenuBarProps, WithStyles<MenuBarClassKey> {}

export const MenuBarComponent: React.FunctionComponent<MenuBarInnerProps> = (props) => {
    const { classes, items, onMenuClick } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.menuContainer} data-id="menu-bar">
            <TitledIconButton
                className={classes.icon}
                color="inherit"
                data-id="menu-button"
                disableRipple
                onClick={onMenuClick}
                placement="right"
                title={t('menu')}
            >
                <MenuIcon />
            </TitledIconButton>

            {items
                .filter((item) => !item.hideInMenuBar)
                .map((item) => (
                    <MenuBarItem key={`menu-item:${item.key}`} menuItem={item} />
                ))}
        </div>
    );
};
