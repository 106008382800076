import saveAs from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { TachoFileType, TachoFilesDownloadResponse } from '~/services/ApiClient';

interface ZipFilesProps {
    fileType?: TachoFileType;
    response: TachoFilesDownloadResponse;
    t: SingleTFunction;
}

export const zipFiles = async ({ fileType, response, t }: ZipFilesProps): Promise<void> => {
    if (!response?.items || response.items.length === 0) {
        return;
    }

    const zip = new JSZip();

    response.items.forEach((file) => {
        zip.file(file.filename, file.fileContent, { base64: true });
    });

    const zipContent = await zip.generateAsync({ type: 'blob' });

    const dateTime = moment().format('YYYYMMDD');

    const zipFileName = fileType
        ? `${t('rtds-zip-file-name', { dateTime, fileType: t(fileType) })}.zip`
        : `${t('rtds-zip-file-undefined-type', { dateTime })}.zip`;

    saveAs(zipContent, zipFileName);
};
