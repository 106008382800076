import moment from 'moment';

import { getValueFromHash } from '~/common';
import type { SettingsDataType, SettingsKey } from '~/components/EnsureSettings';
import { keyBy } from '~/libs/utility';
import type { TrailerSettingsChangelogChanges } from '~/services/ApiClient';

import type { ResolvedTrailerSettingsChangelogChanges } from './models';
import { resolveTrailerCompartmentChangelogChanges } from './reducers.resolveTrailerCompartmentChangelogChanges';

export const resolveTrailerSettingsChangelogChanges = (
    settings: TrailerSettingsChangelogChanges,
    trailerOfflineAlarm: SettingsDataType[SettingsKey.TRAILER_OFFLINE_ALARM],
    trailerMonitoring: SettingsDataType[SettingsKey.TRAILER_MONITORING],
    trailerBandwidthLinked: SettingsDataType[SettingsKey.TRAILER_BANDWIDTH_LINKED]
): ResolvedTrailerSettingsChangelogChanges => {
    return {
        ...settings,
        compartments: settings.compartments
            ? keyBy(
                  settings.compartments.map((c) =>
                      resolveTrailerCompartmentChangelogChanges(c, trailerBandwidthLinked)
                  ),
                  'compartmentNumber'
              )
            : undefined,
        interval: settings.interval ? moment.duration(settings.interval) : undefined,
        monitoring: getValueFromHash(trailerMonitoring, settings.monitoring),
        offlineAlarm: getValueFromHash(trailerOfflineAlarm, settings.offlineAlarm),
    };
};
