import type { CircleTile, PolygonTile } from '@fv/components';
import { SHAPE_TYPES } from '@fv/components';
import { Circle, GeoJSON, Polygon } from 'leaflet';

import { keyBy, uniq } from '~/libs/utility';
import type { AdminGeozoneAssignments, AdminGeozoneGeometryPolygon } from '~/services/ApiClient';
import { AdminGeozoneGeometryCircle } from '~/services/ApiClient';

import type {
    EntryToTileArgs,
    GeozoneAdministrationRead,
    ResolveAdministrationGeozoneReadArgs,
    ResolvedAdminGeozonesRead,
    ResolvedAssignmentReferences,
    UpdateGeozonesStateItemsArgs,
} from './models';

const entryToTile = (args: EntryToTileArgs) => {
    const { geometry, id } = args;

    if (geometry instanceof AdminGeozoneGeometryCircle) {
        const circleTile = new Circle<unknown>(GeoJSON.coordsToLatLng(geometry.coordinates as [number, number]), {
            radius: geometry.radius,
        });

        return <CircleTile>{
            center: circleTile.getLatLng(),
            getBounds: () => circleTile.getBounds(),
            getCenter: () => circleTile.getBounds().getCenter(),
            id,
            radius: circleTile.getRadius(),
            shape: SHAPE_TYPES.CIRCLE,
        };
    }

    const typedValue = geometry as AdminGeozoneGeometryPolygon;
    const polygonTile = new Polygon<unknown>(GeoJSON.coordsToLatLngs(typedValue.coordinates));

    return <PolygonTile>{
        getBounds: () => polygonTile.getBounds(),
        getCenter: () => polygonTile.getBounds().getCenter(),
        id,
        positions: polygonTile.getLatLngs(),
        shape: SHAPE_TYPES.POLYGON,
        toGeoJSON: () => polygonTile.toGeoJSON(),
    };
};

const resolveAdministrationGeozonesRead = (args: ResolveAdministrationGeozoneReadArgs) => {
    const { geozones, settingsState } = args;

    return (geozones || []).map((geozone): ResolvedAdminGeozonesRead => {
        return {
            ...geozone,
            assignmentCount: {
                assetCount: geozone.assignments.assets,
                assetGroupCount: geozone.assignments.assetGroups,
            },
            geometry: entryToTile({ geometry: geozone.geometry, id: geozone.id }),
            type: settingsState.geozoneType.data[geozone.type],
        };
    });
};

const resolveGeozonesCategories = (geozones: GeozoneAdministrationRead[]) => {
    const allCategories = geozones.reduce((acc: string[], geozone) => {
        geozone.categories?.map((category) => acc.push(category));

        return acc;
    }, []);

    return uniq(allCategories);
};
const resolvedAssignmentReferences = (assignmentRefs: AdminGeozoneAssignments): ResolvedAssignmentReferences => {
    const { assetGroups, assets } = assignmentRefs;
    return {
        assetGroupIds: assetGroups,
        assetReferences: assets,
    };
};

const updateGeozonesStateItems = (args: UpdateGeozonesStateItemsArgs) => {
    const { current, deletions, settingsState, updates } = args;

    const resolveUpdates = resolveAdministrationGeozonesRead({ geozones: updates, settingsState });
    const newStateHash = keyBy(resolveUpdates, 'id');

    const updatedItems = current.map((item) => {
        return newStateHash[item.id]?.revision > item.revision ? newStateHash[item.id] : item;
    });

    const itemsToAdd = resolveUpdates.reduce((accumulator: ResolvedAdminGeozonesRead[], item) => {
        const itemToAdd = !current.some((stateItem) => stateItem.id === item.id);

        if (itemToAdd) {
            accumulator.push(item);
        }

        return accumulator;
    }, []);

    return [...updatedItems, ...itemsToAdd].filter(({ id }) => !deletions.includes(id));
};

export {
    entryToTile,
    resolveAdministrationGeozonesRead,
    resolvedAssignmentReferences,
    resolveGeozonesCategories,
    updateGeozonesStateItems,
};
