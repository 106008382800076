import Refresh from '@mui/icons-material/Refresh';
import { Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { TitledIconButton } from '~/components/TitledIconButton';

import type { RetryLoadClassKey } from './styles';

export interface RetryLoadProps {
    dataId: string;
    onClick: () => void;
    title: string;
}

export interface RetryLoadInnerProps extends RetryLoadProps, WithStyles<RetryLoadClassKey> {}

export const RetryLoadComponent: FC<RetryLoadInnerProps> = (props) => {
    const { classes, dataId, onClick, title } = props;

    return (
        <Paper className={classes.root} data-id={dataId} onClick={onClick}>
            <TitledIconButton title={title}>
                <Refresh className={classes.retryIcon} color="secondary" />
            </TitledIconButton>
        </Paper>
    );
};
