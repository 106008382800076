import { TableRow } from '@mui/material';

import { PrimitiveValueFormatter } from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';

import type { ChangelogChangesTableRowProps } from '../../../../models';
import { TableBodyCell } from '../TableBodyCell';

const ValueChangelogChangesTableRow = <T, V>(props: ChangelogChangesTableRowProps<T, V>) => {
    const { attributeDefinition, changelog } = props;
    const oldValue = attributeDefinition.getValue(changelog.before);
    const newValue = attributeDefinition.getValue(changelog.after);
    const ValueFormatter = attributeDefinition.valueFormatterComponent ?? PrimitiveValueFormatter;

    return (
        <TableRow data-id={attributeDefinition.title} key={attributeDefinition.title}>
            <TableBodyCell text={attributeDefinition.title} valueDataId="attribute" />
            <TableBodyCell
                text={!isUndefined(oldValue) ? <ValueFormatter value={oldValue} /> : ''}
                valueDataId="old-value"
            />
            <TableBodyCell
                text={!isUndefined(newValue) ? <ValueFormatter value={newValue} /> : ''}
                valueDataId="new-value"
            />
        </TableRow>
    );
};

ValueChangelogChangesTableRow.displayName = 'ValueChangelogChangesTableRow';
export { ValueChangelogChangesTableRow };
