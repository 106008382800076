import type { MapModes } from '@fv/components';
import type * as i18next from 'i18next';
import type { Dispatch } from 'react';

import { LocationSection, PositionMiniMapSection } from '~/common';
import type { SectionDefinition } from '~/components/Sections';
import type { ResolvedMessage } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { MessageDetailsSection } from '../MessageDetailsSection';
import { MetadataSection } from '../MetadataSection';

import { SectionName } from './constants';

export const getSections = (
    message: ResolvedMessage,
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    t: i18next.TFunction,
    miniMapZoomLevel: number,
    changeMiniMapZoomLevel: (zoomLevel: number) => void,
    miniMapMode: MapModes,
    changeMiniMapMode: Dispatch<MapModes>
): SectionDefinition[] => {
    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <MessageDetailsSection
                    dataId={SectionName.MESSAGEINFO}
                    dragHandleElement={dragHandleElement}
                    headerOverContent
                    hideCollapsing
                    isCollapsed={false}
                    message={message}
                    title={t('message-info')}
                    toggleCollapsed={toggleCollapsedState(SectionName.MESSAGEINFO)}
                />
            ),
            name: SectionName.MESSAGEINFO,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <PositionMiniMapSection
                    changeMapMode={changeMiniMapMode}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    mapMode={miniMapMode}
                    position={message.value.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    address={message.value.location?.address}
                    courseOverGround={message.value.courseOverGround}
                    datetime={message.value.location?.dateTime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    odometer={message.value.odometer}
                    position={message.value.location?.position}
                    speedOverGround={message.value.speedOverGround}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <MetadataSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.METADATA)}
                    message={message}
                    toggleCollapsed={toggleCollapsedState(SectionName.METADATA)}
                />
            ),
            name: SectionName.METADATA,
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
