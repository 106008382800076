import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type AddressCardClassKeys = 'addressLines' | 'avatar' | 'undefinedMessage';

export const useStyles = makeStyles<Theme, {}, AddressCardClassKeys>((theme: Theme) => ({
    addressLines: {
        flexWrap: 'wrap',
        paddingBottom: 0,
        paddingTop: theme.spacing(),
    },
    avatar: {
        alignSelf: 'flex-start',
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    undefinedMessage: {
        fontStyle: 'italic',
    },
}));
