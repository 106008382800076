import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem, NearestCity } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { getDistanceUnitSystemUtils } from './distanceUnitSystemUtils';
import { formatDirection } from './formatDirection';
import type { UnitSystemConverterOptions } from './unitSystemUtils';

interface FormatNearestCityInternalArgs {
    converter: (distance?: number, options?: UnitSystemConverterOptions) => number | undefined;
    nearestCity: NearestCity;
    t: SingleTFunction;
    unitFormatted: string;
}

const formatNearestCityInternal = ({ converter, nearestCity, t, unitFormatted }: FormatNearestCityInternalArgs) => {
    const bearing = formatDirection(t, nearestCity.bearing);

    return t('nearest-city-value', {
        bearing,
        city: nearestCity.city,
        countryCode: nearestCity.countryCode,
        distance: converter(nearestCity.distance, { precision: 1 }),
        unit: unitFormatted,
    });
};

const formatNearestCityMemoized = memoizeOne((t: SingleTFunction, unitSystem: DisplayUserPreferencesUnitSystem) => {
    const distanceUnitSystemUtils = getDistanceUnitSystemUtils(t, unitSystem);

    return memoize((nearestCity: NearestCity) => {
        return formatNearestCityInternal({
            converter: distanceUnitSystemUtils.converter,
            nearestCity,
            t,
            unitFormatted: distanceUnitSystemUtils.unit,
        });
    });
});

export const formatNearestCity = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    nearestCity: NearestCity
): string => formatNearestCityMemoized(t, unitSystem)(nearestCity);
