import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { DimensionsFormatter } from '~/components/Formatters/Dimensions';
import { WeightFormatter } from '~/components/Formatters/Weight';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { useTranslation } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedVehicleType } from '~/services/ApiClient';

import { SectionName } from '../../constants';

export interface DimensionsSectionProps {
    adminVehicleType: ResolvedVehicleType;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface DimensionsSectionInnerProps extends DimensionsSectionProps, InjectedDisplayPreferencesProps {}

export const DimensionsSectionComponent: React.FunctionComponent<DimensionsSectionInnerProps> = ({
    adminVehicleType,
    displayPreferences,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const { t } = useTranslation();
    const { unitSystem } = displayPreferences;

    const keyValueItems: KeyValueItem[] = [
        {
            label: t('height'),
            value: adminVehicleType.dimensions?.height ? (
                <DimensionsFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.dimensions.height} />
            ) : undefined,
            valueDataId: 'height',
        },
        {
            label: t('width'),
            value: adminVehicleType.dimensions?.width ? (
                <DimensionsFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.dimensions.width} />
            ) : undefined,
            valueDataId: 'width',
        },
        {
            label: t('length'),
            value: adminVehicleType.dimensions?.length ? (
                <DimensionsFormatter t={t} unitSystem={unitSystem} value={adminVehicleType.dimensions.length} />
            ) : undefined,
            valueDataId: 'length',
        },
        {
            label: t('weight'),
            value: adminVehicleType.weight ? (
                <WeightFormatter unitSystem={unitSystem} value={adminVehicleType.weight} />
            ) : undefined,
            valueDataId: 'weight',
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-types-administration-dimensions-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.VEHICLETYPESADMINISTRATION_DIMENSIONS)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="dimensions-list" />
        </ReorderableSection>
    );
};
