import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LoginFormClassKey = 'fields' | 'form';

export const LoginFormStyleRules: StyleRulesCallback<Theme, {}, LoginFormClassKey> = (theme: Theme) =>
    createStyles({
        fields: {
            margin: theme.spacing(3, 5),
        },
        form: {
            margin: theme.spacing(3, 0),
        },
    });
