import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import { IconFormatter } from './components/Icon';

export interface DriverEventHasCorrectionFormatterProps extends InjectedTranslationProps {
    value?: boolean;
}

export interface DriverEventHasCorrectionFormatterInnerProps extends DriverEventHasCorrectionFormatterProps {}

export const DriverEventHasCorrectionFormatterComponent: React.SFC<DriverEventHasCorrectionFormatterInnerProps> = ({
    t,
    value,
}) => {
    if (value) {
        return (
            <Tooltip title={t('is-correction')}>
                <span data-id={`driver-event-has-correction:${value}`}>
                    <IconFormatter />
                </span>
            </Tooltip>
        );
    } else {
        return null;
    }
};
