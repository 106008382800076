import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';
import type { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';

import type { JwtDecodedToken, TokenRequest } from '~/data/authentication';

import type { LogoutReason } from '../../models';

export interface StateProps {
    decodedImpersonatorJwt?: JwtDecodedToken;
    decodedJwt?: JwtDecodedToken;
    impersonatorJwt?: string;
    isAuthenticated: boolean;
    jwt?: string;
    logoutReason?: LogoutReason;
    verified: boolean;
}

export interface DispatchProps {
    tokenExpired: () => void;

    verifyToken: (request: TokenRequest) => void;
}

export interface ProtectedComponentProps extends RouteComponentProps {}

export interface ProtectedComponentInnerProps extends ProtectedComponentProps, StateProps, DispatchProps {}

export const protectedComponent = <P extends ProtectedComponentInnerProps>(
    WrappedComponent: React.ComponentType<P>
): React.ComponentType<ProtectedComponentInnerProps> => {
    return class extends React.Component<ProtectedComponentInnerProps> {
        public static displayName = wrapDisplayName(WrappedComponent, 'ProtectedComponent');
        private getTimeoutUntilExpiration = (token: JwtDecodedToken) => {
            const currentTimestamp = Math.round(new Date().getTime() / 1000);

            return (token.exp - currentTimestamp) * 1000;
        };

        private impersonatorTokenExpirationTimer?: number;

        private tokenExpirationTimer?: number;

        private updateExpirationTimer = (
            timerKey: 'impersonatorTokenExpirationTimer' | 'tokenExpirationTimer',
            token?: JwtDecodedToken,
            prevToken?: JwtDecodedToken
        ) => {
            if (token === prevToken) {
                return;
            }

            if (this[timerKey]) {
                window.clearTimeout(this[timerKey]);
            }

            if (token) {
                const exp = this.getTimeoutUntilExpiration(token);
                if (exp > 0) {
                    // setTimeout interval only supports max of 2^31-1, otherwise executed immediately
                    this[timerKey] = window.setTimeout(() => {
                        this[timerKey] = undefined;
                        this.updateExpirationTimer(timerKey, token, undefined);
                    }, exp % 0x7fffffff);
                } else {
                    this.props.tokenExpired();
                }
            } else {
                this[timerKey] = undefined;
            }
        };

        public componentDidMount(): void {
            if (!this.props.verified && this.props.jwt) {
                this.props.verifyToken({
                    impersonatorToken: this.props.impersonatorJwt,
                    token: this.props.jwt,
                });
            }

            this.updateExpirationTimer('tokenExpirationTimer', this.props.decodedJwt);
            this.updateExpirationTimer('impersonatorTokenExpirationTimer', this.props.decodedImpersonatorJwt);
        }

        public componentDidUpdate(previousProps: ProtectedComponentInnerProps) {
            this.updateExpirationTimer('tokenExpirationTimer', this.props.decodedJwt, previousProps.decodedJwt);
            this.updateExpirationTimer(
                'impersonatorTokenExpirationTimer',
                this.props.decodedImpersonatorJwt,
                previousProps.decodedImpersonatorJwt
            );
        }

        public componentWillUnmount() {
            this.updateExpirationTimer('tokenExpirationTimer', undefined, this.props.decodedJwt);
            this.updateExpirationTimer(
                'impersonatorTokenExpirationTimer',
                undefined,
                this.props.decodedImpersonatorJwt
            );
        }

        public render(): JSX.Element {
            const { isAuthenticated, jwt, location, logoutReason, verified } = this.props;

            if (!verified && jwt) {
                return <div />;
            }

            if (!isAuthenticated) {
                if (!jwt) {
                    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/logout',
                                state: { from: location, reason: logoutReason },
                            }}
                        />
                    );
                }
            }

            const key = this.props.decodedJwt && this.props.decodedJwt.sub;

            return <WrappedComponent key={key} {...(this.props as P)} />;
        }
    };
};
