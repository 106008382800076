import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';

import { ACTION_TYPE_KEYS } from './actionTypes';
import type { ActionTypes, DevicesAdministrationState } from './models';
import { resolveAdministrationDeviceRead } from './reducers.resolveAdministrationDeviceRead';

const initialState: DevicesAdministrationState = {
    devices: [],
    error: false,
    pending: false,
};

const devicesAdministrationReducer = (
    state = initialState,
    action: ActionTypes,
    settingsState: SettingsStoreState,
    staticDataState: StaticDataStoreState
): DevicesAdministrationState => {
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED:
            return {
                devices: (action.payload.items || []).map((device) =>
                    resolveAdministrationDeviceRead({ device, settingsState, staticDataState })
                ),
                error: false,
                pending: false,
            };
        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        default:
            return state;
    }
};

export { devicesAdministrationReducer };
