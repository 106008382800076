import { Avatar, Typography } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CoDriverIcon, TruckIcon } from '~/components/Icons';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

import type { DriverInfoBoxInnerProps } from './models';

const DriverInfoBox: FC<DriverInfoBoxInnerProps> = (props) => {
    const { classes, driverDisplayNameFormat, entry, vehicleDisplayFormat } = props;
    const { vehicle } = entry.status;

    const truckIcon = useMemo(() => <TruckIcon className={classes.icon} />, [classes.icon]);
    const coDriverIcon = useMemo(() => <CoDriverIcon className={classes.icon} />, [classes.icon]);

    const { t } = useTranslation();

    return (
        <div data-testid={`info-box:${entry.status.driver}`}>
            {vehicle ? (
                <div className={classes.infoBoxWrapper}>
                    <Avatar className={classes.vehicleAvatar}>{truckIcon}</Avatar>
                    <div className={classes.infoText}>
                        <Typography data-testid={`vehicle-text-${vehicle.id.toString()}`} variant="body2">
                            <TitledTruncatedText>
                                {formatVehicleName(vehicle, vehicleDisplayFormat)}
                            </TitledTruncatedText>
                        </Typography>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={classNames(classes.notLoggedIn, classes.infoBoxWrapper)}>
                        <Avatar className={classes.driverAvatar}>{truckIcon}</Avatar>

                        <div className={classes.infoText}>
                            <Typography data-testid="vehicle-not-connected" variant="body2">
                                <TitledTruncatedText>{t('not-connected')}</TitledTruncatedText>
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            {entry.driver && entry.driver.coDriver && (
                <div className={classes.infoBoxWrapper}>
                    <Avatar className={classes.driverAvatar}>{coDriverIcon}</Avatar>

                    <div className={classes.infoText}>
                        <Typography
                            data-testid={`codriver-text-${entry.driver.coDriver.id.toString()}`}
                            variant="body2"
                        >
                            <TitledTruncatedText>
                                {formatDriverName(entry.driver.coDriver, driverDisplayNameFormat)}
                            </TitledTruncatedText>
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

DriverInfoBox.displayName = 'DriverInfoBox';
export { DriverInfoBox };
