import { isUndefined } from '~/libs/utility';

import type { ResolvedVehicle } from '../ApiClient';

import type { NamedVehicleReferenceResolverArgs } from './models';

export const namedVehicleReferenceResolver = (props: NamedVehicleReferenceResolverArgs): ResolvedVehicle => {
    const { categories, namedVehicleReference, vehicleTypes } = props;
    return {
        ...namedVehicleReference,
        category: categories[namedVehicleReference?.category ?? 0],
        vehicleType: !isUndefined(namedVehicleReference.type) ? vehicleTypes[namedVehicleReference.type] : undefined,
    };
};
