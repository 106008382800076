import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';

import { DeviceTypeFormatter } from '~/components/Formatters';
import { AssetTypeFormatter } from '~/components/Formatters/AssetType';
import { RtdsSchedulesBooleanStatesFormatter } from '~/components/Formatters/RtdsSchedulesBooleanStatesFormatter';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createClassificationColumn,
    createDateTimeColumn,
    createFormattedColumn,
    createStringColumn,
    filterDataSourceBySearchQuery,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedRtdsSchedule,
} from '~/services/ApiClient';
import { AssetType, ClassificationType } from '~/services/ApiClient';
import {
    exportFormatterFactory,
    formatAssetTypeTitle,
    formatBoolean,
    formatDeviceType,
    formatRtdscheduleDescription,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { formatTachoDatablocks } from '~/services/Formatters/formatTachoDataBlocks';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import type { RtdsSchedulesEntry } from '../../models';

import { ColumnName } from './constants';
import {
    getAssetTypeValue,
    getAssetValue,
    getDeviceEnabledValue,
    getDeviceTypeValue,
    getResponseEnabledValue,
    getRtdsDescription,
    getRtdsFrequencyValue,
} from './getCellValue';

export const filterDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: RtdsSchedulesEntry[],
        columnDefinitions: Array<GridColumnDefinition<RtdsSchedulesEntry>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);

export const getColumns = (
    t: SingleTFunction,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat
): Array<GridColumnDefinition<RtdsSchedulesEntry>> => {
    const columns: Array<GridColumnDefinition<RtdsSchedulesEntry>> = [
        {
            compare: compareNumbers,
            dataType: 'number',
            getCellValue: (e) => e.schedule.id?.id,
            groupTitle: t('schedule-related'),
            name: ColumnName.SCHEDULEID,
            title: t('schedule-scheduleid'),
        },
        {
            cellFiltering: {
                options: Object.values(AssetType).filter(
                    (assetType) => assetType === AssetType.Vehicle || assetType === AssetType.Driver
                ),
            },
            compare: compareStrings,
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: AssetType) => formatAssetTypeTitle(t, v)),
            getCellValue: getAssetTypeValue,
            groupTitle: t('schedule-related'),
            name: ColumnName.TYPE,
            title: t('rtds-asset-type'),
            valueFormatterComponent: withTranslation()(AssetTypeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: AssetType) => formatAssetTypeTitle(t, v)),
        },
        createDateTimeColumn(
            ColumnName.STARTDATE,
            t('schedule-start-date'),
            t('schedule-related'),
            (e) => e.schedule.startDate
        ),
        createStringColumn(ColumnName.ASSET, t('assets'), t('general'), (e: RtdsSchedulesEntry) =>
            getAssetValue(vehicleDisplayNameFormat, driverDisplayNameFormat, e)
        ),
        createClassificationColumn(
            t,
            ColumnName.FREQUENCY,
            t('schedule-frequency'),
            t('schedule-related'),
            getRtdsFrequencyValue,
            ClassificationType.RtdsScheduleFrequency
        ),
        createFormattedColumn(
            ColumnName.DESCRIPTION,
            t('schedule-description'),
            t('schedule-related'),
            getRtdsDescription,
            (e: ResolvedRtdsSchedule) => formatRtdscheduleDescription(t, e)
        ),
        createDateTimeColumn(
            ColumnName.NEXTRUN,
            t('schedule-next-run'),
            t('schedule-related'),
            (e) => e.schedule.nextRun
        ),
        {
            compare: compareNumbers,
            dataType: 'number',
            getCellValue: (e) => e.schedule.instance,
            groupTitle: t('schedule-related'),
            name: ColumnName.INSTANCE,
            title: t('schedule-instance'),
        },
        createFormattedColumn(
            ColumnName.REQUESTEDTACHODATA,
            t('schedule-requested-tacho-data'),
            t('schedule-related'),
            (entry: RtdsSchedulesEntry) => entry.schedule,
            (e: ResolvedRtdsSchedule) => formatTachoDatablocks(t, e)
        ),
        {
            compare: compareFactory((v: string) => formatDeviceType(t, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: string) => formatDeviceType(t, v)),
            getCellValue: getDeviceTypeValue,
            groupTitle: t('general'),
            name: ColumnName.DEVICETYPE,
            title: t('device-type'),
            valueFormatterComponent: withTranslation()(DeviceTypeFormatter),
        },
        {
            align: 'center',
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: getDeviceEnabledValue,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('schedule-related'),
            name: ColumnName.DEVICEENABLED,
            title: t('schedule-device-enabled'),
            valueFormatterComponent: withTranslation()(RtdsSchedulesBooleanStatesFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        {
            align: 'center',
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: getResponseEnabledValue,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('schedule-related'),
            name: ColumnName.RESPONSEENABLED,
            title: t('schedule-response-enabled'),
            valueFormatterComponent: withTranslation()(RtdsSchedulesBooleanStatesFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
    ];

    return columns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
