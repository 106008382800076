import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { VehicleCategory } from '~/services/ApiClient';
import { AssetConnectionStatus } from '~/services/ApiClient';
import { formatVehicleCategory } from '~/services/Formatters';

import type { VehicleFilteringClassKey } from './styles';

export interface VehicleFilter {
    connectionStatus?: AssetConnectionStatus;
    vehicleCategory?: VehicleCategory;
}

export interface VehicleFilteringProps {
    filter?: VehicleFilter;
    onFilterStateChange: (filter: VehicleFilter) => void;
    vehicleCategories: VehicleCategory[];
}

export interface VehicleFilteringInnerProps
    extends VehicleFilteringProps,
        InjectedTranslationProps,
        WithStyles<VehicleFilteringClassKey> {}

export const ALL_OPTION = 'all';

export const VehicleFilteringComponent: React.FC<VehicleFilteringInnerProps> = ({
    classes,
    filter,
    onFilterStateChange,
    t,
    vehicleCategories,
}) => {
    const vehicleCategoriesElements = vehicleCategories.map((vehicleCategory: VehicleCategory) => (
        <FormControlLabel
            control={<Radio />}
            data-id={`vehicle-category-option:${vehicleCategory.id}`}
            key={`vehicle-category:${vehicleCategory.id}`}
            label={formatVehicleCategory(t, vehicleCategory)}
            value={vehicleCategory.id}
        />
    ));

    const handleVehicleCategoryChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedVehicleCategory = vehicleCategories.find(
                (vehicleCategory: VehicleCategory) => vehicleCategory.id === +event.target.value
            );

            onFilterStateChange({
                ...filter,
                vehicleCategory: selectedVehicleCategory,
            });
        },
        [onFilterStateChange, filter, vehicleCategories]
    );

    const handleConnectionStatusChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedConnectionStatus =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as AssetConnectionStatus);

            onFilterStateChange({
                ...filter,
                connectionStatus: selectedConnectionStatus,
            });
        },
        [onFilterStateChange, filter]
    );

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl} component="fieldset" variant="standard">
                <FormLabel className={classes.label} component="legend" data-id="vehicle-category-radio-group-title">
                    {t('vehicle-category')}
                </FormLabel>
                <RadioGroup
                    data-id="vehicle-category-radio-group"
                    name="vehicle-category"
                    onChange={handleVehicleCategoryChange}
                    value={isUndefined(filter?.vehicleCategory?.id) ? -1 : filter?.vehicleCategory?.id}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`vehicle-category-option:${ALL_OPTION}`}
                        key={ALL_OPTION}
                        label={t(ALL_OPTION)}
                        value={-1}
                    />
                    {vehicleCategoriesElements}
                </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" variant="standard">
                <FormLabel className={classes.label} component="legend" data-id="connection-status-radio-group-title">
                    {t('connection-status')}
                </FormLabel>
                <RadioGroup
                    data-id="connection-status-radio-group"
                    name="connection-status"
                    onChange={handleConnectionStatusChange}
                    value={filter?.connectionStatus || ALL_OPTION}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${ALL_OPTION}`}
                        key={ALL_OPTION}
                        label={t(ALL_OPTION)}
                        value={ALL_OPTION}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${AssetConnectionStatus.CONNECTED}`}
                        key={AssetConnectionStatus.CONNECTED}
                        label={t(AssetConnectionStatus.CONNECTED)}
                        value={AssetConnectionStatus.CONNECTED}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-id={`connection-status-option:${AssetConnectionStatus.NOT_CONNECTED}`}
                        key={AssetConnectionStatus.NOT_CONNECTED}
                        label={t(AssetConnectionStatus.NOT_CONNECTED)}
                        value={AssetConnectionStatus.NOT_CONNECTED}
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
};
