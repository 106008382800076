import type { FC } from 'react';

import { RefreshSceneBar, RefreshSceneBarState } from '~/common';

import type { RefreshDataBannerProps } from './models';

const RefreshDataBanner: FC<RefreshDataBannerProps> = (props) => {
    const { isLoading, isRejected, onClose, onRefreshData } = props;

    return (
        <RefreshSceneBar
            disableClose={isLoading}
            onClose={onClose}
            state={isRejected ? RefreshSceneBarState.ERROR : RefreshSceneBarState.REFRESHING}
            stateAction={isRejected ? onRefreshData : undefined}
        />
    );
};

RefreshDataBanner.displayName = 'RefreshDataBanner';
export default RefreshDataBanner;
