import type { MapModes } from '@fv/components';
import * as React from 'react';
import type { Dispatch } from 'react';

import { LocationSection } from '~/common';
import { PositionMiniMapSection } from '~/common/components/PositionMiniMapSection';
import type { SectionDefinition } from '~/components/Sections';
import { memoizeOne } from '~/services/Memoize';

import type { HistoryTrailerEntry } from '../../../../models';
import { LocationSectionActionButtons } from '../LocationSectionActionButtons';

import { SectionName } from './constants';

export const getSections = (
    entry: HistoryTrailerEntry,
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    miniMapZoomLevel: number,
    changeMiniMapZoomLevel: (zoomLevel: number) => void,
    miniMapMode: MapModes,
    changeMiniMapMode: Dispatch<MapModes>
): SectionDefinition[] => {
    const locationSectionActionButtons = (
        <LocationSectionActionButtons position={entry.trailerEvent.location?.position} />
    );

    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    actionButtons={locationSectionActionButtons}
                    address={entry.trailerEvent.location?.address}
                    datetime={entry.trailerEvent.eventDateTime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    position={entry.trailerEvent.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <PositionMiniMapSection
                    changeMapMode={changeMiniMapMode}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    mapMode={miniMapMode}
                    position={entry.trailerEvent.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
