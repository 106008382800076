import { useDisplayPreferences } from '~/common/hooks';
import type {
    MonitoringContainerEntry,
    MonitoringDriverEntry,
    MonitoringTrailerEntry,
    MonitoringVehicleEntry,
} from '~/data/monitoring';

import { MAP_KEYS } from '../consts';

import { ContainerMarker, DriverMarker, TrailerMarker, VehicleMarker } from './components';
import type { AssetMarkerProps } from './models';

const AssetMarker = <T,>(props: AssetMarkerProps<T>) => {
    const { entry, mapKey, ...rest } = props;

    const { driverDisplayFormat, trailerDisplayFormat, vehicleDisplayFormat } = useDisplayPreferences();

    switch (mapKey) {
        case MAP_KEYS.container:
            return <ContainerMarker displayContainerName entry={entry as MonitoringContainerEntry} {...rest} />;

        case MAP_KEYS.driver:
            return (
                <DriverMarker
                    driverDisplayNameFormat={driverDisplayFormat}
                    entry={entry as MonitoringDriverEntry}
                    {...rest}
                />
            );

        case MAP_KEYS.trailer:
            return (
                <TrailerMarker
                    entry={entry as MonitoringTrailerEntry}
                    trailerDisplayNameFormat={trailerDisplayFormat}
                    {...rest}
                />
            );
        case MAP_KEYS.vehicle:
            return (
                <VehicleMarker
                    entry={entry as MonitoringVehicleEntry}
                    vehicleDisplayNameFormat={vehicleDisplayFormat}
                    {...rest}
                />
            );
        default:
            return null;
    }
};

AssetMarker.displayName = 'AssetMarker';
export { AssetMarker };
