import { endOfDay, startOfDay, subDays } from 'date-fns';

import type { DateTimeRange } from '~/components/DateTimeRangePicker';

const DATETIMERANGE_MAX_DAYS = 7;
const DATETIMERANGE_SEARCH_AVAILABLE_DAYS = 91;

const MONITORING_DATETIMERANGE: DateTimeRange = {
    endDate: endOfDay(new Date()),
    maxDays: DATETIMERANGE_MAX_DAYS,
    minDate: startOfDay(subDays(new Date(), DATETIMERANGE_SEARCH_AVAILABLE_DAYS)),
    startDate: startOfDay(subDays(new Date(), DATETIMERANGE_MAX_DAYS - 1)),
};

const MONITORING_PATH_STRUCTURE = '/monitoring/:viewMode/:perspective/:id?/:subpage?';

export { MONITORING_DATETIMERANGE, MONITORING_PATH_STRUCTURE };
