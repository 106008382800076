import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import { DriverActivityKeys } from '~/services/ApiClient';

import { speedThresholdMps } from './constants';
import type { MarkerIconProps } from './models';
import { AvailableIcon, DrivingIcon, NavigationIcon, RestingIcon, WorkingIcon } from './styles';

const MarkerIcon: FC<MarkerIconProps> = (props) => {
    const { AssetIcon, courseOverGround, dataId, driverActivity, speedOverGround } = props;

    switch (driverActivity && driverActivity.key) {
        case undefined:
            return speedOverGround && speedOverGround > speedThresholdMps ? (
                isUndefined(courseOverGround) ? (
                    <DrivingIcon data-testid={`${DriverActivityKeys.DRIVE}-icon`} />
                ) : (
                    <NavigationIcon courseOverGround={courseOverGround} data-testid="navigation-icon" />
                )
            ) : (
                <AssetIcon dataId={dataId || 'assetIcon'} />
            );
        case DriverActivityKeys.WORK:
            return <WorkingIcon data-testid={`${DriverActivityKeys.WORK}-icon`} />;
        case DriverActivityKeys.AVAILABLE:
            return <AvailableIcon data-testid={`${DriverActivityKeys.AVAILABLE}-icon`} />;
        case DriverActivityKeys.REST:
            return <RestingIcon data-testid={`${DriverActivityKeys.REST}-icon`} />;
        case DriverActivityKeys.DRIVE:
            return isUndefined(courseOverGround) ? (
                <DrivingIcon data-testid={`${DriverActivityKeys.DRIVE}-icon`} />
            ) : (
                <NavigationIcon courseOverGround={courseOverGround} data-testid="navigation-icon" />
            );
        default:
            return null;
    }
};

MarkerIcon.displayName = 'MarkerIcon';
export { MarkerIcon };
