import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressFormatter, CoordinatesFormatter } from '~/components/Formatters';
import { AddressFormat } from '~/services/Formatters';

import type { GeoPositionAddressProps } from './models';
import { Container, Typography } from './styles';

const GeoPositionAddress: FC<GeoPositionAddressProps> = (props) => {
    const { address, position } = props;

    const { t } = useTranslation();

    return (
        <Container data-testid="geoposition-address">
            {address && (
                <>
                    <Typography data-testid="first-line" variant="subtitle2">
                        <AddressFormatter options={{ format: AddressFormat.FirstLine }} value={address} />
                    </Typography>
                    <Typography data-testid="second-line" variant="body2">
                        <AddressFormatter options={{ format: AddressFormat.SecondLine }} value={address} />
                    </Typography>
                </>
            )}

            <Typography color="textSecondary" data-testid="coordinates" variant="body2">
                <CoordinatesFormatter t={t} value={position} />
            </Typography>
        </Container>
    );
};

GeoPositionAddress.displayName = 'GeoPositionAddress';
export { GeoPositionAddress };
