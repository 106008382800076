import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';

import type { TooltipSize } from '../../models';

const sizeMap = {
    large: { fontSize: 14, spacing: 1 },
    small: { fontSize: 12, spacing: 0.5 },
};

interface SizeProps {
    size: TooltipSize;
}

const Container = styled(Paper)<Theme, SizeProps>(({ size, theme }) => {
    const { spacing } = sizeMap[size];
    return {
        padding: theme.spacing(1 * spacing),
    };
});

const Row = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const LabelTypography = styled(Typography)<Theme, SizeProps>(({ size, theme }) => {
    const { fontSize, spacing } = sizeMap[size];
    return {
        flexGrow: 1,
        fontSize,
        margin: theme.spacing(0.5 * spacing),
        marginRight: theme.spacing(2 * spacing),
    };
});

const ValueTypography = styled(Typography)<Theme, SizeProps>(({ size, theme }) => {
    const { fontSize, spacing } = sizeMap[size];
    return {
        fontSize,
        fontWeight: theme.typography.fontWeightBold,
        margin: theme.spacing(0.5 * spacing),
    };
});

export { Container, LabelTypography, Row, ValueTypography };
