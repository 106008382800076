import type { Reducer } from 'redux';

import type { MapViewport } from '~/common';
import type { Dictionary } from '~/libs/utility';
import type { RequiredAndNonNullable } from '~/types';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface SceneMapRootStoreState {
    isHistoryMode: boolean;
    maps: Dictionary<SceneMapStoreState>;
    panToAsset: boolean;
    showAllSelectedAssets: boolean;
    viewport?: RequiredAndNonNullable<MapViewport>;
}

export interface SceneMapStoreState {
    searchQuery?: string;
}

const defaultStoreState: SceneMapRootStoreState = {
    isHistoryMode: false,
    maps: {},
    panToAsset: false,
    showAllSelectedAssets: false,
    viewport: {
        center: [23.805449612314625, 10.458984375000002],
        zoom: 3,
    },
};

export const sceneMapReducer: Reducer<SceneMapRootStoreState> = (
    state: SceneMapRootStoreState = defaultStoreState,
    action: ActionTypes
): SceneMapRootStoreState => {
    switch (action.type) {
        case ActionTypeKeys.SCENEMAP_CHANGEVIEWPORT:
            return {
                ...state,
                panToAsset: false,
                showAllSelectedAssets: false,
                viewport: action.payload,
            };
        case ActionTypeKeys.SCENEMAP_SHOWALLSELECTEDASSETS:
            return { ...state, showAllSelectedAssets: true };
        case ActionTypeKeys.SCENEMAP_PANTOASSET:
            return { ...state, panToAsset: true };
        case ActionTypeKeys.SCENEMAP_HISTORY:
            return { ...state, isHistoryMode: action.payload };
        case ActionTypeKeys.SCENEMAP_CHANGESEARCHQUERY:
            return {
                ...state,
                maps: { ...state.maps, [action.meta]: { ...state.maps[action.meta], searchQuery: action.payload } },
            };

        default:
            return state;
    }
};
