import type { GenericItem } from '@fv/components';
import ExpandMore from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';

import { isEmpty, isUndefined } from '../../../../../utility';

import type { ItemContentProps } from './models';
import { ActionContainer, Checkbox, Container, FormControlLabel, LabelContainer, Typography } from './styles';

const ItemContent = <T extends ItemContentProps<T>>(props: T) => {
    const {
        checkablePredicate = () => true,
        checkedKeys,
        checkedState,
        children,
        disableExpandButton = false,
        expandedKeys,
        hideCheckbox = false,
        hideExpandAllButton,
        icon,
        id,
        indentLevel = 0,
        name,
        onCheck,
        onClick,
        onCollapse,
        onContextMenu,
        parentId,
        type,
    } = props;

    const hasChildren = !isEmpty(children);
    const groupType = type?.toLowerCase();

    const isChecked = !isUndefined(checkedKeys[id]) || checkedState?.isChecked;
    const isCheckable = checkablePredicate(props as GenericItem<T>);
    const isDisabled = !children?.length && !isCheckable;
    const isIndeterminate = checkedState?.isIndeterminate;
    const isLeaf = !hasChildren;

    const handleCheck = useCallback(
        (event, checked) =>
            onCheck({
                event,
                id,
                isLeaf,
                source: children ?? [],
                value: checked,
            }),
        [children, id, isLeaf, onCheck]
    );

    const handleClick = useCallback(
        (event) =>
            onClick({
                event,
                id,
                value: true,
            }),
        [id, onClick]
    );

    return (
        <Container
            data-testid={`asset-tree-node:${groupType ? `${groupType}-` : ''}${id}`}
            hideExpandAllButton={hideExpandAllButton}
            indentLevel={indentLevel}
            isLeaf={isLeaf}
            key={`item-content_${id}`}
            onClick={isLeaf ? handleClick : undefined}
            onContextMenu={(event) => {
                onContextMenu?.(event.nativeEvent, id);
                event.stopPropagation();
            }}
            {...(!isUndefined(parentId) && { 'data-parent-id': parentId })}
        >
            <ActionContainer
                isDisabled={isDisabled}
                isLeaf={isLeaf}
                isSelected={hideCheckbox && !!isChecked}
                singleSelectMode={hideCheckbox}
            >
                {!isLeaf &&
                    (isUndefined(expandedKeys[id]) && !disableExpandButton ? (
                        <IconButton
                            aria-label="expand"
                            data-testid={`expand_more_${id}`}
                            disableRipple
                            onClick={(event) => onCollapse({ event, id, value: true })}
                        >
                            <ExpandMore />
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-label="expand"
                            data-testid={`expand_less_${id}`}
                            disabled={disableExpandButton}
                            disableRipple
                            onClick={(event) => onCollapse({ event, id, value: false })}
                        >
                            <ExpandLess />
                        </IconButton>
                    ))}
                {hideCheckbox ? (
                    <LabelContainer>
                        {icon || <FolderIcon />}
                        <Typography color="textPrimary" variant="body2">
                            {hasChildren ? `${name} (${children?.length})` : name}
                        </Typography>
                    </LabelContainer>
                ) : (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                data-testid="select"
                                disabled={isDisabled}
                                indeterminate={isIndeterminate}
                                onChange={handleCheck}
                            />
                        }
                        label={
                            <LabelContainer>
                                {icon || <FolderIcon />}
                                <Typography color="textPrimary" variant="body2">
                                    {name}
                                </Typography>
                            </LabelContainer>
                        }
                    />
                )}
            </ActionContainer>
        </Container>
    );
};

ItemContent.displayName = 'ItemContent';
export { ItemContent };
