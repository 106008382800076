import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';

import { BooleanFormatter, DeviceTypeFormatter } from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    classificationFilterEquals,
    createClassificationColumn,
    createColumn,
    createNumberColumn,
    createStringColumn,
    filterDataSourceBySearchQuery,
    getClassificationOptions,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type {
    Classification,
    ClassificationAbstraction,
    Depot,
    DeviceType,
    DisplayUserPreferencesUnitSystem,
    ResolvedAdminVehicleRead,
    ServicesSecurables,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import {
    exportFormatterFactory,
    formatBoolean,
    formatDeviceType,
    formatVehicleCategory,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { compareFactory, compareNumbers, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';

interface GetColumnsProps {
    billingGroups: NumericDictionary<Classification>;
    billingStatuses: NumericDictionary<Classification>;
    depots: NumericDictionary<Depot>;
    deviceTypes: NumericDictionary<DeviceType>;
    fuelTankTypes: NumericDictionary<Classification>;
    imageCapturingTypes: NumericDictionary<Classification>;
    inhibitorServiceTypes: NumericDictionary<Classification>;
    navigationTypes: NumericDictionary<Classification>;
    networks: NumericDictionary<Classification>;
    servicesSecurables: ServicesSecurables;
    t: SingleTFunction;
    tachoGenerations: NumericDictionary<Classification>;
    tachographs: NumericDictionary<Classification>;
    tachoProtocols: NumericDictionary<Classification>;
    tellTaleServiceTypes: NumericDictionary<Classification>;
    unitSystem: DisplayUserPreferencesUnitSystem;
    vehicleCategories: NumericDictionary<VehicleCategory>;
    vehicleTypes: NumericDictionary<VehicleType>;
    workflowTypes: NumericDictionary<Classification>;
}

export const filterDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: ResolvedAdminVehicleRead[],
        columnDefinitions: Array<GridColumnDefinition<ResolvedAdminVehicleRead>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);

const addColumnIfServiceEnabled = (
    columns: GridColumnDefinition<ResolvedAdminVehicleRead>[],
    column: GridColumnDefinition<ResolvedAdminVehicleRead>,
    serviceEnabled: boolean
): void => {
    if (serviceEnabled) {
        columns.push(column);
    }
};

export const getColumns = (props: GetColumnsProps): Array<GridColumnDefinition<ResolvedAdminVehicleRead>> => {
    const {
        billingGroups,
        billingStatuses,
        depots,
        deviceTypes,
        fuelTankTypes,
        imageCapturingTypes,
        inhibitorServiceTypes,
        navigationTypes,
        networks,
        servicesSecurables,
        t,
        tachoGenerations,
        tachographs,
        tachoProtocols,
        tellTaleServiceTypes,
        unitSystem,
        vehicleCategories,
        vehicleTypes,
        workflowTypes,
    } = props;
    const deviceTypeClassificationFactory = (value: DeviceType): ClassificationAbstraction => {
        return {
            displayName: formatDeviceType(t, value.key),
            id: value.id,
            key: value.key,
        };
    };

    const vehicleCategoryClassificationFactory = (value: VehicleCategory): ClassificationAbstraction => {
        return {
            displayName: formatVehicleCategory(t, value),
            id: value.id,
            key: value.key,
        };
    };

    const depotClassificationFactory = (value: Depot): ClassificationAbstraction => {
        return {
            displayName: value.name,
            id: value.id,
            key: value.name,
        };
    };

    const vehicleTypeClassificationFactory = (value: VehicleType): ClassificationAbstraction => {
        return {
            displayName: value.name,
            id: value.id,
            key: value.name,
        };
    };

    const columns: Array<GridColumnDefinition<ResolvedAdminVehicleRead>> = [
        createNumberColumn(ColumnName.UAID, t('uaid'), t('general'), (e) => e.uaid),
        createStringColumn(ColumnName.VIN, t('vin'), t('general'), (e) => e.vin),
        createStringColumn(ColumnName.VRN, t('vrn'), t('general'), (e) => e.vrn),
        createStringColumn(ColumnName.ALIAS, t('alias'), t('general'), (e) => e.settings?.alias),
        createStringColumn(ColumnName.UNITID, t('unit-id'), t('general'), (e) => e.unitId),
        createStringColumn(ColumnName.MSISDN, t('msisdn'), t('general'), (e) => e.device?.msisdn),
        {
            cellFiltering: {
                options: getClassificationOptions(Object.values(deviceTypes), deviceTypeClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
            compare: compareFactory((v: string) => formatDeviceType(t, v), stringComparer),
            dataType: 'string',
            exportValueFormatter: exportFormatterFactory((v: string) => formatDeviceType(t, v)),
            getCellValue: (e) => e.deviceType?.key,
            groupTitle: t('general'),
            name: ColumnName.DEVICETYPE,
            title: t('device-type'),
            valueFormatterComponent: withTranslation()(DeviceTypeFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: string) => formatDeviceType(t, v)),
        },
        {
            cellFiltering: {
                options: getClassificationOptions(
                    Object.values(vehicleCategories),
                    vehicleCategoryClassificationFactory
                ),
                valueEquals: classificationFilterEquals,
            },
            compare: compareFactory((v: string) => v, stringComparer),
            dataType: 'string',
            getCellValue: (e) => (!isUndefined(e.category) ? formatVehicleCategory(t, e.category) : ''),
            groupTitle: t('general'),
            name: ColumnName.VEHICLECATEGORY,
            title: t('vehicle-category'),
        },
        createClassificationColumn(
            t,
            ColumnName.NETWORKID,
            t('network-id'),
            t('general'),
            (e) => e.device?.networkId,
            ClassificationType.Network,
            {
                classifications: Object.values(networks),
            }
        ),
        createStringColumn(ColumnName.TAG, t('tag'), t('general'), (e) => e.tag),
        {
            cellFiltering: {
                options: getClassificationOptions(Object.values(depots), depotClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
            compare: compareNumbers,
            dataType: 'string',
            getCellValue: (e) => e.depot?.name,
            groupTitle: t('general'),
            name: ColumnName.DEPOT,
            title: t('depot'),
        },
        {
            cellFiltering: {
                options: getClassificationOptions(Object.values(vehicleTypes), vehicleTypeClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
            compare: compareNumbers,
            dataType: 'string',
            getCellValue: (e) => e.vehicleType?.name,
            groupTitle: t('general'),
            name: ColumnName.VEHICLETYPE,
            title: t('vehicle-type'),
        },
        createClassificationColumn(
            t,
            ColumnName.BILLINGGROUP,
            t('billing-group'),
            t('general'),
            (e) => e.device?.billingGroup,
            ClassificationType.BillingGroup,
            {
                classifications: Object.values(billingGroups),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.BILLINGSTATUS,
            t('billing-status'),
            t('general'),
            (e) => e.device?.billingStatus,
            ClassificationType.BillingStatus,
            {
                classifications: Object.values(billingStatuses),
            }
        ),
        {
            compare: compareNumbers,
            dataType: 'number',
            getCellValue: (e) => e.companyCardId,
            groupTitle: t('general'),
            name: ColumnName.COMPANYCARDID,
            title: t('company-card-id'),
        },
        createClassificationColumn(
            t,
            ColumnName.TACHOGRAPH,
            t('tachograph'),
            t('tachograph'),
            (e) => e.settings?.tachograph,
            ClassificationType.Tachograph,
            {
                classifications: Object.values(tachographs),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.TACHOPROTOCOL,
            t('tacho-protocol'),
            t('tachograph'),
            (e) => e.settings?.tachoProtocol,
            ClassificationType.TachoProtocol,
            {
                classifications: Object.values(tachoProtocols),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.TACHOGENERATION,
            t('tacho-generation'),
            t('tachograph'),
            (e) => e.settings?.tachoGeneration,
            ClassificationType.TachoGeneration,
            {
                classifications: Object.values(tachoGenerations),
            }
        ),
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.oneMinuteRuleEnabled,
            groupTitle: t('tachograph'),
            name: ColumnName.ONEMINUTERULEENABLED,
            title: t('one-minute-rule-enabled'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
    ];

    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.alertsAndPanicEnabled,
            groupTitle: t('services'),
            name: ColumnName.ALERTSANDPANIC,
            title: t('alerts-and-panic'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.alertsAndPanic
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.driverCardsEnabled,
            groupTitle: t('services'),
            name: ColumnName.DRIVERCARDS,
            title: t('driver-cards'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.driverCards
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.driverCoachEnabled,
            groupTitle: t('services'),
            name: ColumnName.DRIVERCOACH,
            title: t('driver-coach'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.driverCoach
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.driverHoursEnabled,
            groupTitle: t('services'),
            name: ColumnName.DRIVERHOURS,
            title: t('driver-hours'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.driverHours
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.dseEnabled,
            groupTitle: t('services'),
            name: ColumnName.DSE,
            title: t('dse'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.drivingStyleEvaluation
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.frequentPositioningEnabled,
            groupTitle: t('services'),
            name: ColumnName.FREQUENTPOSITIONING,
            title: t('frequent-positioning'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.frequentPositioning
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.fuelSiteAdviceEnabled,
            groupTitle: t('services'),
            name: ColumnName.FUELSITEADVICE,
            title: t('fuel-site-advice'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.fuelSiteAdvice
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.kpi2Enabled,
            groupTitle: t('services'),
            name: ColumnName.KPI2,
            title: t('kpi2'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.kpi2
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.landmarksEnabled,
            groupTitle: t('services'),
            name: ColumnName.LANDMARKS,
            title: t('landmarks'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.landmarks
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.rtdsEnabled,
            groupTitle: t('services'),
            name: ColumnName.RTDS,
            title: t('rtds'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.rtds
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.textMessagingEnabled,
            groupTitle: t('services'),
            name: ColumnName.TEXTMESSAGING,
            title: t('text-messaging'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.textMessaging
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.trailersEnabled,
            groupTitle: t('services'),
            name: ColumnName.TRAILERS,
            title: t('trailers'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.trailers
    );
    addColumnIfServiceEnabled(
        columns,
        createClassificationColumn(
            t,
            ColumnName.WORKFLOW,
            t('workflow'),
            t('services'),
            (e) => e.settings?.workflowType,
            ClassificationType.WorkflowType,
            {
                classifications: Object.values(workflowTypes),
            }
        ),
        servicesSecurables.workflow
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.guidedNavigationEnabled,
            groupTitle: t('services'),
            name: ColumnName.GUIDEDNAVIGATION,
            title: t('guided-navigation'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.guidedNavigation
    );
    addColumnIfServiceEnabled(
        columns,
        createClassificationColumn(
            t,
            ColumnName.NAVIGATION,
            t('navigation'),
            t('services'),
            (e) => e.settings?.navigationType,
            ClassificationType.NavigationType,
            {
                classifications: Object.values(navigationTypes),
            }
        ),
        servicesSecurables.navigation
    );
    addColumnIfServiceEnabled(
        columns,
        createClassificationColumn(
            t,
            ColumnName.IMAGECAPTURING,
            t('image-capturing'),
            t('services'),
            (e) => e.settings?.imageCapturingType,
            ClassificationType.ImageCapturingType,
            {
                classifications: Object.values(imageCapturingTypes),
            }
        ),
        servicesSecurables.imageCapturing
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.tireControlEnabled,
            groupTitle: t('services'),
            name: ColumnName.TIRECONTROL,
            title: t('tire-control'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.tireControl
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.appStoreEnabled,
            groupTitle: t('services'),
            name: ColumnName.APPSTORE,
            title: t('app-store'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.appStore
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.truckIdentificationEnabled,
            groupTitle: t('services'),
            name: ColumnName.TRUCKIDENTIFICATION,
            title: t('truck-identification'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.truckIdentification
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.trailerControlServiceEnabled,
            groupTitle: t('services'),
            name: ColumnName.TRAILERCONTROLSERVICE,
            title: t('trailer-control-service'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.trailerControlService
    );
    addColumnIfServiceEnabled(
        columns,
        createClassificationColumn(
            t,
            ColumnName.STARTINHIBITOR,
            t('start-inhibitor'),
            t('services'),
            (e) => e.settings?.inhibitorServiceType,
            ClassificationType.InhibitorServiceType,
            {
                classifications: Object.values(inhibitorServiceTypes),
            }
        ),
        servicesSecurables.inhibitorService
    );
    addColumnIfServiceEnabled(
        columns,
        createClassificationColumn(
            t,
            ColumnName.TELLTALESSERVICE,
            t('tell-tale-service'),
            t('services'),
            (e) => e.settings?.tellTaleServiceType,
            ClassificationType.TellTaleServiceType,
            {
                classifications: Object.values(tellTaleServiceTypes),
            }
        ),
        servicesSecurables.tellTaleService
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.j1939DataServiceEnabled,
            groupTitle: t('services'),
            name: ColumnName.J1939DATASERVICE,
            title: t('j1939-data-service'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.j1939DataService
    );
    addColumnIfServiceEnabled(
        columns,
        {
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.settings?.navigationEtaEnabled,
            groupTitle: t('services'),
            name: ColumnName.NAVIGATIONETA,
            title: t('navigation-eta'),
            valueFormatterComponent: withTranslation()(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        servicesSecurables.navigationEta
    );
    // Fuel tank columns
    columns.push(
        createColumn({
            getCellValue: (e) =>
                e.settings && { amount: e.settings?.primaryFuelTankSize, type: e.settings.primaryFuelTankType },
            groupTitle: t('vehicle-administration.fuel'),
            name: ColumnName.PRIMARY_FUEL_TANK_SIZE,
            title: t('vehicle-administration.primary-fuel-tank-size'),
            type: 'fuel',
            typeOptions: { unitSystem },
        }),
        createClassificationColumn(
            t,
            ColumnName.PRIMARY_FUEL_TANK_TYPE,
            t('vehicle-administration.primary-fuel-tank-type'),
            t('vehicle-administration.fuel'),
            (e) => e.settings?.primaryFuelTankType,
            ClassificationType.FuelTankType,
            {
                classifications: Object.values(fuelTankTypes),
            }
        ),
        createColumn({
            getCellValue: (e) =>
                e.settings && { amount: e.settings?.secondaryFuelTankSize, type: e.settings.secondaryFuelTankType },
            groupTitle: t('vehicle-administration.fuel'),
            name: ColumnName.SECONDARY_FUEL_TANK_SIZE,
            title: t('vehicle-administration.secondary-fuel-tank-size'),
            type: 'fuel',
            typeOptions: { unitSystem },
        }),
        createClassificationColumn(
            t,
            ColumnName.SECONDARY_FUEL_TANK_TYPE,
            t('vehicle-administration.secondary-fuel-tank-type'),
            t('vehicle-administration.fuel'),
            (e) => e.settings?.secondaryFuelTankType,
            ClassificationType.FuelTankType,
            {
                classifications: Object.values(fuelTankTypes),
            }
        ),
        createColumn({
            getCellValue: (e) => e.settings?.adBlueTankSize,
            groupTitle: t('vehicle-administration.fuel'),
            name: ColumnName.AD_BLUE_TANK_SIZE,
            title: t('vehicle-administration.ad-blue-tank-size'),
            type: 'volume_liquid',
            typeOptions: { unitSystem },
        })
    );

    return columns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
