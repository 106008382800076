import type { Dispatch } from 'redux';

import type { GridColumnDefinition, GridFilter, GridSorting } from '~/components/Grid';
import { exportToExcel, filterDataSourceByFilter } from '~/components/Grid';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListRootStoreState, SceneListUserPreferences } from '~/components/SceneList';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { uniq, without } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import type { ViewBarActionsProps, ViewBarActionsReduxProps } from './models';

export interface OwnProps<T> extends ViewBarActionsProps<T>, InjectedTranslationProps {}

export interface StateProps {
    columnOrder: string[];
    filteringEnabled: boolean;
    filters: GridFilter[];
    groupingEnabled: boolean;
    pinEnabled: boolean;
    searchQuery?: string;
    sorting: GridSorting[];
    visibleColumns: string[];
}

export interface DispatchProps {
    changeFilteringEnabled: (filteringEnabled: boolean) => void;
    changeGroupingEnabled: (groupingEnabled: boolean) => void;
    changePinEnabled: (pinEnabled: boolean) => void;
    changeVisibleColumns: (visibleColumns: string[]) => void;
}

export const mapStateToPropsFactory =
    (preferencesKey: string) =>
    (
        {
            columnOrder,
            filteringEnabled,
            filters,
            groupingEnabled,
            pinEnabled,
            sorting,
            visibleColumns,
        }: SceneListUserPreferences,
        sceneListStoreState: SceneListRootStoreState
    ): StateProps => ({
        columnOrder,
        filteringEnabled,
        filters,
        groupingEnabled,
        pinEnabled,
        searchQuery: sceneListStoreState[preferencesKey]?.searchQuery,
        sorting,
        visibleColumns,
    });

export const mapDispatchToPropsFactory =
    (preferencesKey: string) =>
    (dispatch: Dispatch): DispatchProps => ({
        changeFilteringEnabled: (filteringEnabled: boolean) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { filteringEnabled }));
        },
        changeGroupingEnabled: (groupingEnabled: boolean) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { groupingEnabled }));
        },
        changePinEnabled: (pinEnabled: boolean) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { pinEnabled }));
        },
        changeVisibleColumns: (visibleColumns: string[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { visibleColumns }));
        },
    });

export const mergePropsFactory = <T>(
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[],
    excelFileName: string
): ((stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps<T>) => ViewBarActionsReduxProps<T>) => {
    const exportToExcelMemoized = memoizeOne(
        (
            dataSource: T[],
            columnDefinitions: Array<GridColumnDefinition<T>>,
            sorting: GridSorting[],
            columnOrder: string[],
            visibleColumns: string[],
            fileName: string,
            worksheetTitle: string
        ) => {
            return () =>
                exportToExcel(
                    dataSource,
                    columnDefinitions,
                    sorting,
                    columnOrder,
                    visibleColumns,
                    fileName,
                    worksheetTitle
                );
        }
    );

    const showColumnsMemoized = memoizeOne(
        (visibleColumns: string[], changeVisibleColumns: (visibleColumns: string[]) => void) => (columns: string[]) => {
            changeVisibleColumns(uniq([...visibleColumns, ...columns]));
        }
    );

    const hideColumnsMemoized = memoizeOne(
        (visibleColumns: string[], changeVisibleColumns: (visibleColumns: string[]) => void) => (columns: string[]) => {
            changeVisibleColumns(without(visibleColumns, ...columns));
        }
    );

    const toggleGroupingEnabledMemoized = memoizeOne(
        (groupingEnabled: boolean, changeGroupingEnabled: (groupingEnabled: boolean) => void) => () => {
            changeGroupingEnabled(!groupingEnabled);
        }
    );

    const togglePinEnabledMemoized = memoizeOne(
        (pinEnabled: boolean, changePinEnabled: (pinEnabled: boolean) => void) => () => {
            changePinEnabled(!pinEnabled);
        }
    );

    const toggleFilteringEnabledMemoized = memoizeOne(
        (filteringEnabled: boolean, changeFilteringEnabled: (filteringEnabled: boolean) => void) => () => {
            changeFilteringEnabled(!filteringEnabled);
        }
    );

    // Unable to memoize filterDataSourceByFilter due to typing issue; The generic T breaks the memoizeOne typings
    const filterDataSourceByFilterMemoized = memoizeOne(
        (
            dataSource: T[],
            filters: GridFilter[],
            columns: Array<GridColumnDefinition<T>>,
            visibleColumns: string[],
            filteringEnabled: boolean
        ) => filterDataSourceByFilter(dataSource, filters, columns, visibleColumns, filteringEnabled)
    );

    return (
        { columnOrder, sorting, ...restStateProps }: StateProps,
        {
            changeFilteringEnabled,
            changeGroupingEnabled,
            changePinEnabled,
            changeVisibleColumns,
            ...restDispatchProps
        }: DispatchProps,
        ownProps: OwnProps<T>
    ): ViewBarActionsReduxProps<T> => {
        const filteredDataSource = filterDataSourceByFilterMemoized(
            filterDataSourceMemoized(
                restStateProps.searchQuery,
                ownProps.dataSource,
                ownProps.columns,
                restStateProps.visibleColumns
            ),
            restStateProps.filters,
            ownProps.columns,
            restStateProps.visibleColumns,
            restStateProps.filteringEnabled
        );
        const exportIsDisabled = !(restStateProps.visibleColumns.length && filteredDataSource.length);

        return {
            ...ownProps,
            ...restStateProps,
            ...restDispatchProps,
            exportIsDisabled,
            exportToExcel: exportToExcelMemoized(
                filteredDataSource,
                ownProps.columns,
                sorting,
                columnOrder,
                restStateProps.visibleColumns,
                excelFileName,
                ownProps.excelSheetTitle
            ),
            filteredDataSource,
            hideColumns: hideColumnsMemoized(restStateProps.visibleColumns, changeVisibleColumns),
            showColumns: showColumnsMemoized(restStateProps.visibleColumns, changeVisibleColumns),
            toggleFilteringEnabled: toggleFilteringEnabledMemoized(
                restStateProps.filteringEnabled,
                changeFilteringEnabled
            ),
            toggleGroupingEnabled: toggleGroupingEnabledMemoized(restStateProps.groupingEnabled, changeGroupingEnabled),
            togglePinEnabled: togglePinEnabledMemoized(restStateProps.pinEnabled, changePinEnabled),
        };
    };
};
