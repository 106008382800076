/* eslint-disable import/no-duplicates */
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import hu from 'date-fns/locale/hu';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import ptBR from 'date-fns/locale/pt-BR';

import { getI18n } from '~/i18n';
import { format } from '~/libs/dates';

const LOCALE_MAPPER = {
    'de-DE': de,
    'en-GB': enGB,
    'es-ES': es,
    'fr-FR': fr,
    'hu-HU': hu,
    'it-IT': it,
    'nl-NL': nl,
    'pl-PL': pl,
    'pt-BR': ptBR,
};

export const formatDate = (dateTime: Date, formatOption?: string) => {
    const { language } = getI18n();

    return format(dateTime, formatOption ?? 'P', { locale: LOCALE_MAPPER[language] });
};
