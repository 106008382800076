import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router';

import { Authenticate } from './components/Authenticate';
import { Landing } from './components/Landing';

export interface SingleSignOnProps extends RouteComponentProps<{}> {}

export interface SingleSignOnInnerProps extends SingleSignOnProps {}

export class SingleSignOnComponent extends React.Component<SingleSignOnInnerProps> {
    public render(): React.ReactNode {
        const { match } = this.props;
        return (
            <Switch>
                <Route component={Landing} exact path={`${match.path}`} />
                <Route component={Authenticate} exact path={`${match.path}/authenticate`} />
            </Switch>
        );
    }
}
