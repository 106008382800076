import { Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { CollapsibleContent } from '~/components/CollapsibleContent';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import { isUndefined, keyBy, range } from '~/libs/utility';
import type { ResolvedCompartmentStatus, ResolvedTrailer } from '~/services/ApiClient';

import { CompartmentSubSectionContent } from './components/CompartmentSubSectionContent';
import { TemperaturesGraphDialog } from './components/TemperaturesGraphDialog';
import { TemperatureStatusIndicator } from './components/TemperatureStatusIndicator';
import type { TemperaturesSectionClassKey } from './styles';

export interface TemperaturesSectionProps {
    changeCollapsedCompartments: (collapsedCompartments: number[]) => void;
    collapsedCompartments: number[];
    compartments: ResolvedCompartmentStatus[];
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    trailer: ResolvedTrailer;
}

export interface TemperaturesSectionInnerProps
    extends InjectedTranslationProps,
        TemperaturesSectionProps,
        WithStyles<TemperaturesSectionClassKey> {}

export const TemperaturesSectionComponent: FC<TemperaturesSectionInnerProps> = (props) => {
    const {
        changeCollapsedCompartments,
        classes,
        collapsedCompartments,
        compartments,
        dragHandleElement,
        isCollapsed,
        t,
        toggleCollapsed,
        trailer,
    } = props;

    const [dialogCompartment, setDialogCompartment] = useState<number | undefined>(undefined);

    const openDialog = useCallback(
        (compartmentNumber: number) => () => {
            setDialogCompartment(compartmentNumber);
        },
        [setDialogCompartment]
    );
    const closeDialog = useCallback(() => {
        setDialogCompartment(undefined);
    }, [setDialogCompartment]);

    const toggleCollapsedCompartment = useCallback(
        (compartmentNumber: number) => () => {
            if (collapsedCompartments.includes(compartmentNumber)) {
                changeCollapsedCompartments(collapsedCompartments.filter((c) => c !== compartmentNumber));
            } else {
                changeCollapsedCompartments([...collapsedCompartments, compartmentNumber]);
            }
        },
        [collapsedCompartments, changeCollapsedCompartments]
    );
    const visibleCompartments = useMemo(() => {
        const compartmentsByNumber = keyBy(compartments, 'compartmentNumber');
        return range(1, trailer.compartmentCount + 1).map((compartmentNumber) => ({
            compartment: compartmentsByNumber[compartmentNumber] as ResolvedCompartmentStatus | undefined,
            compartmentNumber,
        }));
    }, [compartments, trailer.compartmentCount]);

    const renderContent = useCallback(() => {
        switch (visibleCompartments.length) {
            case 0:
                return (
                    <SectionContentMessage dataId="no-compartments-messages">
                        {t('no-compartments')}
                    </SectionContentMessage>
                );
            case 1:
                return (
                    <div className={classes.compartmentContainer} data-id="single-compartment">
                        <CompartmentSubSectionContent
                            compartmentStatus={visibleCompartments[0].compartment}
                            onClick={openDialog(visibleCompartments[0].compartmentNumber)}
                        />
                    </div>
                );
            default:
                return visibleCompartments.map(({ compartment, compartmentNumber }) => {
                    const isCompartmentCollapsed = collapsedCompartments.includes(compartmentNumber);
                    const headerText = (
                        <span className={classes.compartmentHeaderText} data-id="title">
                            {t('nth-compartment', { compartmentNumber })}
                        </span>
                    );
                    const header = isCompartmentCollapsed ? (
                        <>
                            {headerText}
                            <span data-id="compartment-indicator">
                                <TemperatureStatusIndicator
                                    classes={{ root: classes.temperatureIndicator }}
                                    maxTemperature={compartment?.maxTemperature}
                                    minTemperature={compartment?.minTemperature}
                                    temperatureSensors={Object.values(compartment?.temperatureSensors || {})}
                                />
                            </span>
                        </>
                    ) : (
                        headerText
                    );

                    return (
                        <CollapsibleContent
                            classes={{ content: classes.compartmentContainer, header: classes.compartmentHeader }}
                            dataId={`compartment-${compartmentNumber}`}
                            header={header}
                            isCollapsed={isCompartmentCollapsed}
                            key={compartmentNumber}
                            toggleCollapsed={toggleCollapsedCompartment(compartmentNumber)}
                        >
                            <CompartmentSubSectionContent
                                compartmentStatus={compartment}
                                onClick={openDialog(compartmentNumber)}
                            />
                        </CollapsibleContent>
                    );
                });
        }
    }, [t, classes, visibleCompartments, collapsedCompartments, toggleCollapsedCompartment, openDialog]);

    const collapsedHeaderElement = useMemo(() => {
        const indicators = visibleCompartments.map(({ compartment, compartmentNumber }) => {
            return (
                <Tooltip key={compartmentNumber} title={t('nth-compartment', { compartmentNumber })}>
                    <span data-id={`compartment-indicator-${compartmentNumber}`}>
                        <TemperatureStatusIndicator
                            classes={{ root: classes.temperatureIndicator }}
                            maxTemperature={compartment?.maxTemperature}
                            minTemperature={compartment?.minTemperature}
                            temperatureSensors={Object.values(compartment?.temperatureSensors || {})}
                        />
                    </span>
                </Tooltip>
            );
        });

        return <span className={classes.indicators}>{indicators}</span>;
    }, [t, visibleCompartments, classes]);

    const graphDialog = !isUndefined(dialogCompartment) ? (
        <TemperaturesGraphDialog defaultCompartmentNumber={dialogCompartment} onClose={closeDialog} trailer={trailer} />
    ) : null;

    return (
        <ReorderableSection
            dataId="temperatures"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            rightHeaderElement={collapsedHeaderElement}
            title={t('temperatures')}
            toggleCollapsed={toggleCollapsed}
        >
            {renderContent()}
            {graphDialog}
        </ReorderableSection>
    );
};
