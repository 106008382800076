import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LoadingIndicatorClassKey = 'root';

export const circularProgressSize = 20;

export const LoadingIndicatorStyleRules: StyleRulesCallback<Theme, {}, LoadingIndicatorClassKey> = () =>
    createStyles({
        root: {
            alignItems: 'center',
            borderRadius: '50%',
            display: 'flex',
            height: 32,
            justifyContent: 'center',
            width: 32,
        },
    });
