import type { Dispatch } from 'redux';

import type { JwtStoreState } from '~/components/AuthenticationData';
import { openUnseenCarouselAction } from '~/components/IntroCarousel';
import { loginTokenAction, logoutAction } from '~/data/authentication';
import { reportError } from '~/reporting';

import type { AuthenticateDispatchProps, AuthenticateStateProps } from './models';
import type { AuthenticateStoreState } from './reducers';

export function mapStateToProps(
    jwtStoreState: JwtStoreState,
    singleSignOnState: AuthenticateStoreState
): AuthenticateStateProps {
    return {
        authenticating: singleSignOnState.loading,
        authenticationFailed: singleSignOnState.requestFailed,
        isAuthenticated: jwtStoreState.isAuthenticated,
    };
}

export function mapDispatchProps(dispatch: Dispatch): AuthenticateDispatchProps {
    return {
        authenticateToken: (token: string) => {
            dispatch(loginTokenAction(token)).catch(reportError);
            dispatch(openUnseenCarouselAction());
        },
        logout: () => dispatch(logoutAction()),
    };
}
