import { combineReducers } from 'redux';

import { vehicleStatusForPeriodReducer } from '~/data/vehiclestatus';

import { historySubpageReducer } from '../HistorySubpage';

import { driverActivitiesReducer } from './components/DriverActivityGraph';
import { latestMessagesSectionReducer } from './components/LatestMessagesSection';

export const vehicleDetailsPaneReducer = combineReducers({
    driverActivities: driverActivitiesReducer,
    historySubpage: historySubpageReducer,
    latestMessagesSection: latestMessagesSectionReducer,
    vehicleStatuses: vehicleStatusForPeriodReducer,
});
