import type { FC } from 'react';

import type { MonitoringVehicleEntry } from '~/data/monitoring';

import { MarkerIcon } from '../../../../../MarkerIcon';

import { TruckIcon } from './styles';

export interface VehicleMarkerIconProps {
    entry: MonitoringVehicleEntry;
}

export interface VehicleMarkerIconInnerProps extends VehicleMarkerIconProps {}

const VehicleMarkerIcon: FC<VehicleMarkerIconInnerProps> = ({ entry }) => (
    <MarkerIcon
        AssetIcon={TruckIcon}
        courseOverGround={entry.vehicleStatus?.courseOverGround}
        driverActivity={entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity}
        speedOverGround={entry.vehicleStatus?.speedOverGround}
    />
);
VehicleMarkerIcon.displayName = 'VehicleMarkerIcon';

export { VehicleMarkerIcon };
