import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { SceneDetailsPaneProps } from './models';

export type SceneDetailsPaneClassKey = 'header' | 'hidden' | 'root' | 'sections';

export const SceneDetailsPaneClassStyleRules: StyleRulesCallback<
    Theme,
    SceneDetailsPaneProps<unknown, unknown, unknown>,
    SceneDetailsPaneClassKey
> = () =>
    createStyles({
        header: {
            position: 'relative',
            zIndex: 1,
        },
        hidden: {
            display: 'none',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
        sections: {
            overflow: ({ overflowOff }) => (overflowOff ? 'visible' : 'auto'),
        },
    });
