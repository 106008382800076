import type { LatLngTuple } from 'leaflet';

const SHAPE_TYPES = {
    CIRCLE: 'circle',
    COMMON: 'common',
    MAPLIBREGL: 'maplibregl',
    POLYGON: 'polygon',
    XSERVER: 'xserver',
} as const;

const MAP_MODES = {
    HYBRID: 'hybrid',
    ROAD: 'road',
    SATELLITE: 'satellite',
} as const;

const BOUNDS = {
    DEFAULT: <LatLngTuple[]>[
        [57.136239319177434, 47.109375],
        [39.90973623453719, -17.314453125000004],
    ],
    MAX_BOUND: <LatLngTuple[]>[
        [-90, -Infinity],
        [90, Infinity],
    ],
};

const ZOOM = {
    DEFAULT: 4,
    MAX: 18,
    MIN: 3,
};

export { BOUNDS, MAP_MODES, SHAPE_TYPES, ZOOM };
