import * as React from 'react';

import type { DocumentViewerRotation, Size } from '../models';
import { rotateRectangle } from '../services';

export const useRotation = (defaultRotation: number): DocumentViewerRotation => {
    const [rotation, setRotation] = React.useState(defaultRotation);
    const [originalSize, setOriginalSize] = React.useState<Size | undefined>();

    return React.useMemo(
        () => ({
            applyRotation: (offset: number) => setRotation((r) => (r + (offset % 360) + 360) % 360),
            setOriginalSize,
            transformedSize: originalSize && rotateRectangle(originalSize, rotation),
            value: rotation,
        }),
        [rotation, setRotation, originalSize, setOriginalSize]
    );
};
