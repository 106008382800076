import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormHelperText, FormLabel, InputLabel, RadioGroup, Select, Tooltip } from '@mui/material';
import type { FC, FocusEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getValueByPath } from '~/common';
import { isNil, isUndefined } from '~/libs/utility';
import { EnumControlType } from '~/services/ApiClient';

import type { BaseProps } from '../model';

import { OutlinedInput } from './styles';

export interface OptionsInputProps extends BaseProps<unknown> {
    controlType: EnumControlType;
    idIsString?: boolean;
    selectedValueParser?: (value: unknown) => unknown;
    values: JSX.Element[];
}

const OptionsInput: FC<OptionsInputProps> = (props) => {
    const {
        className,
        controlType,
        dataId,
        defaultValue,
        disabled,
        disabledReason,
        errorText,
        fieldName,
        hasError,
        idIsString,
        label,
        markValueChange,
        onBlurChanged,
        onValueChanged,
        required,
        selectedValueParser,
        validationRules,
        values,
    } = props;

    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const fieldError = formState.errors[fieldName];
    const error = !!fieldError || hasError;
    const isDirtyField = Boolean(getValueByPath(formState.dirtyFields, fieldName));
    const requiredMessage = t('wf-field-error-required');
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;
    const rules = useMemo(
        () => ({ required: { message: requiredMessage, value: required ?? false }, ...validationRules }),
        [required, requiredMessage, validationRules]
    );

    const notifyChange = useCallback(
        (value: unknown, callbackFn?: (v: undefined | unknown) => void) => {
            if (!callbackFn) {
                return;
            }

            if (isNil(value)) {
                callbackFn(undefined);
            } else {
                const parsedValue =
                    !idIsString && selectedValueParser ? selectedValueParser(value as string) : (value as string);
                callbackFn(parsedValue);
            }
        },
        [idIsString, selectedValueParser]
    );

    const onChangeHandler = useCallback(
        (event: SelectChangeEvent<unknown>, field: ControllerRenderProps) => {
            notifyChange(event.target.value, (value) => {
                field.onChange(value);
                if (onValueChanged) {
                    onValueChanged(value);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const onBlurHandler = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: FocusEvent<any>, field: ControllerRenderProps) => {
            notifyChange(event.target.value, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );

    const getHelperText = useCallback(
        (value?: string) =>
            !disabled &&
            (error || helperText) && (
                <FormHelperText error={error}>
                    {error ? helperText : isNil(value) ? t('wf-field-error-please-select-an-option') : undefined}
                </FormHelperText>
            ),
        [disabled, error, helperText, t]
    );

    const dropDownRender: ControllerProps['render'] = ({ field }) => (
        <FormControl className={className} data-id={dataId} size="small" variant="outlined">
            {!isUndefined(label) && (
                <InputLabel error={error} id={`select-field:${dataId}`}>
                    {required ? `${label} *` : label}
                </InputLabel>
            )}

            <Select
                {...field}
                data-id={`select-field:${dataId}`}
                data-testid={dataId}
                disabled={disabled}
                error={error}
                input={
                    <OutlinedInput
                        label={required ? `${label} *` : label}
                        warning={markValueChange && !error && isDirtyField}
                    />
                }
                onBlur={(e) => onBlurHandler(e, field)}
                onChange={(e) => onChangeHandler(e, field)}
                value={field.value}
                variant="outlined"
            >
                {values}
            </Select>
            {getHelperText(field.value)}
        </FormControl>
    );

    const radioButtonsRender: ControllerProps['render'] = ({ field }) => (
        <FormControl
            className={className}
            data-id={dataId}
            disabled={disabled}
            error={error}
            size="small"
            variant="outlined"
        >
            {!isUndefined(label) && (
                <FormLabel component="legend" error={error}>
                    {label}
                </FormLabel>
            )}
            <RadioGroup
                {...field}
                data-id={`radio-button:${dataId}`}
                data-testid={dataId}
                onBlur={(e) => onBlurHandler(e, field)}
                onChange={(e) => onChangeHandler(e, field)}
                value={field.value}
            >
                {values}
            </RadioGroup>
            {getHelperText(field.value)}
        </FormControl>
    );

    const baseComponent = () => {
        if (controlType === EnumControlType.Dropdown) {
            return (
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={fieldName}
                    render={dropDownRender}
                    rules={rules}
                />
            );
        } else if (controlType === EnumControlType.RadioButtons) {
            return (
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={fieldName}
                    render={radioButtonsRender}
                    rules={rules}
                />
            );
        } else {
            throw Error(`ControlType '${controlType}' is not supported.`);
        }
    };

    return disabledReason && disabled ? (
        <Tooltip data-id={`input-tooltip-${dataId}`} placement="right" title={disabledReason}>
            <span className={className} data-id={`tooltip-span-:${dataId}`}>
                {baseComponent()}
            </span>
        </Tooltip>
    ) : (
        baseComponent()
    );
};

OptionsInput.displayName = 'OptionsInput';
export { OptionsInput };
