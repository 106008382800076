import type { OptimisticOutgoingConversationMessageStatus, ResolvedConversation } from '~/services/ApiClient';
import { OptimisticOutgoingConversationMessage, createApiModel } from '~/services/ApiClient';

import { mapOptimisticOutgoingMessageStatus } from '../../reducers.resolveConversationMessage';

export const updateOptimisticConversationMessageStatus = (
    conversation: ResolvedConversation,
    messageCorrelationId: string,
    newStatus: OptimisticOutgoingConversationMessageStatus
): ResolvedConversation => ({
    ...conversation,
    messages: conversation.messages.map((message) => {
        if (
            message.value instanceof OptimisticOutgoingConversationMessage &&
            message.value.correlationId === messageCorrelationId
        ) {
            return {
                ...message,
                status: mapOptimisticOutgoingMessageStatus(newStatus),
                value: createApiModel(OptimisticOutgoingConversationMessage, {
                    ...message.value,
                    status: newStatus,
                }),
            };
        }
        return message;
    }),
});
