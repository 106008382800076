import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { chunk } from '~/libs/utility';

import type { ChartSeries, ChartState } from '../../../../models';

import { ChartLegendItem } from './components/ChartLegendItem';
import type { ChartLegendClassKey } from './styles';

export interface ChartLegendProps {
    chartState: ChartState;
    onChange: (chartState: ChartState) => void;
    series: ChartSeries<never>[];
}

export interface ChartLegendInnerProps extends ChartLegendProps, WithStyles<ChartLegendClassKey> {}

const determineItemsPerRow = (totalItemCount: number) => {
    const size3Remaining = totalItemCount % 3;
    const size4Remaining = totalItemCount % 4;
    if (size4Remaining === 0) {
        return 4;
    }
    if (size3Remaining === 0) {
        return 3;
    }
    return size4Remaining >= size3Remaining ? 4 : 3;
};

export const ChartLegendComponent: React.FC<ChartLegendInnerProps> = ({ chartState, classes, onChange, series }) => {
    const items = series.map((serie) => {
        const selected = chartState.visibleSeries.includes(serie.name);
        const onItemChange = () => {
            if (selected) {
                onChange({
                    ...chartState,
                    visibleSeries: chartState.visibleSeries.filter((s) => s !== serie.name),
                });
            } else {
                onChange({
                    ...chartState,
                    visibleSeries: [...chartState.visibleSeries, serie.name],
                });
            }
        };

        return <ChartLegendItem key={serie.name} onChange={onItemChange} selected={selected} series={serie} />;
    });

    const lineSize = determineItemsPerRow(items.length);
    const lines = chunk(items, lineSize).map((lineItems, idx) => {
        const key = idx;
        return (
            <div className={classes.legendLine} key={key}>
                {lineItems}
            </div>
        );
    });

    return <div data-id="legend">{lines}</div>;
};
