import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type RequestResetPasswordClassKeys = 'backLink' | 'root';

export const useStyles = makeStyles<Theme, {}, RequestResetPasswordClassKeys>((theme: Theme) => ({
    backLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
        color: theme.palette.primary.main,
        display: 'block',
        margin: theme.spacing(3, 0, 0),
        textAlign: 'center',
        textDecoration: 'none',
    },
    root: {
        margin: theme.spacing(3, 5),
    },
}));
