import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { Button, Input, InputAdornment, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import { KEY_ESCAPE } from 'keycode-js';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { Component } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { debounce } from '~/libs/utility';

import type { SearchHeaderClassKey } from './styles';

export interface SearchHeaderProps {
    changeSearchQuery: (searchQuery: string) => void;
    closeSearch: () => void;
    dataIdPrefix: string;
    debouncePeriod?: number;
    defaultSearchQuery?: string;
    deselectAllMatchedItems?: () => void;
    hideSelectAllButtons?: boolean;
    selectAllMatchedItems?: () => void;
}

export interface SearchHeaderInnerProps
    extends SearchHeaderProps,
        WithStyles<SearchHeaderClassKey>,
        InjectedTranslationProps {}

export class SearchHeaderComponent extends Component<SearchHeaderInnerProps> {
    private debouncedChangeSearchQuery = debounce(this.props.changeSearchQuery, this.props.debouncePeriod ?? 500);

    private handleKeyPress = (event: ChangeEvent<HTMLTextAreaElement> & KeyboardEvent<HTMLInputElement>): void => {
        if (event.keyCode === KEY_ESCAPE) {
            this.debouncedChangeSearchQuery.cancel();
            this.props.closeSearch();
        } else {
            this.debouncedChangeSearchQuery(event.target.value);
        }
    };

    public componentWillUnmount(): void {
        this.debouncedChangeSearchQuery.flush();
    }

    public render(): JSX.Element {
        const {
            classes,
            closeSearch,
            defaultSearchQuery,
            deselectAllMatchedItems,
            hideSelectAllButtons,
            selectAllMatchedItems,
            t,
        } = this.props;

        const startAdornment = (
            <InputAdornment position="start">
                <Search className={classes.searchIcon} />
            </InputAdornment>
        );

        const endAdorment = (
            <InputAdornment position="end">
                <Clear
                    aria-label="closeSearch"
                    className={classes.closeIcon}
                    data-id="close-search"
                    onClick={closeSearch}
                />
            </InputAdornment>
        );

        const renderSelectButtons = () => (
            <div className={classes.selectButtons}>
                <Tooltip title={!!selectAllMatchedItems && t('add-results-to-selection')}>
                    <Button
                        className={classes.selectButton}
                        data-id="select-actions-all"
                        disabled={!selectAllMatchedItems}
                        onClick={selectAllMatchedItems}
                    >
                        {t('select-all')}
                    </Button>
                </Tooltip>
                <Tooltip title={!!deselectAllMatchedItems && t('remove-results-from-selection')}>
                    <Button
                        className={classes.selectButton}
                        data-id="select-actions-none"
                        disabled={!deselectAllMatchedItems}
                        onClick={deselectAllMatchedItems}
                    >
                        {t('select-none')}
                    </Button>
                </Tooltip>
            </div>
        );

        return (
            <div className={classes.root} data-id={`${this.props.dataIdPrefix}-search-header`}>
                <Input
                    autoFocus
                    className={classes.searchInput}
                    data-id="search-input"
                    defaultValue={defaultSearchQuery}
                    disableUnderline
                    endAdornment={endAdorment}
                    fullWidth
                    onKeyUp={this.handleKeyPress}
                    startAdornment={startAdornment}
                />

                {!hideSelectAllButtons && defaultSearchQuery && renderSelectButtons()}
            </div>
        );
    }
}
