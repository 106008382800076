export enum FormatLength {
    Short = 'Short',
    Standard = 'Standard',
}

export enum DurationFormat {
    DayHourMinute = 'DayHourMinute',
    Hour = 'Hour',
    HourMinute = 'HourMinute',
    Minute = 'Minute',
}

export enum AddressFormat {
    FirstLine = 'FirstLine',
    Full = 'Full',
    SecondLine = 'SecondLine',
}

export enum TrailerMonitoring {
    Active = 'Active',
    AlarmOnly = 'Alarm only',
}
