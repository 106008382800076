import type { NumericDictionary } from '~/libs/utility';
import type { DerivedClassification } from '~/services/ApiClient';

export interface FieldValues {
    description?: string;
    emissionClass?: number;
    hazardousGoods?: boolean;
    height?: number;
    length?: number;
    name?: string;
    speedLimitCity?: number;
    speedLimitMotorway?: number;
    speedLimitRoad?: number;
    type?: number;
    weight?: number;
    width?: number;
}

export type FieldNames = keyof FieldValues;

export const emissionClassValueNone = 0;

export const defaultFieldValues = (
    typeAxlesDerivedClassifications: NumericDictionary<DerivedClassification>
): FieldValues => ({
    description: '',
    emissionClass: emissionClassValueNone,
    hazardousGoods: false,
    height: 4,
    length: 18,
    name: '',
    speedLimitCity: 50,
    speedLimitMotorway: 90,
    speedLimitRoad: 80,
    type: Object.values(typeAxlesDerivedClassifications)[0].id,
    weight: 40,
    width: 2.55,
});

export const editorTitle = {
    Modify: 'vehicle-type-modify',
    New: 'new-vehicle-type',
};

export const buttonSubmitTitle = {
    Modify: 'save',
    New: 'create',
};
