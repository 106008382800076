import { CircularProgress, Link } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneBarState, SceneBarTemplate } from '~/components/SceneBarTemplate';

import { RefreshSceneBarState } from './models';
import { useStyles } from './styles';

export interface RefreshSceneBarProps {
    disableClose?: boolean;
    onClose?: () => void;
    state: RefreshSceneBarState;
    stateAction?: () => void;
    stateMessage?: string;
}

export interface RefreshSceneBarInnerProps extends RefreshSceneBarProps, InjectedTranslationProps {}

export const RefreshSceneBarComponent: React.FunctionComponent<RefreshSceneBarInnerProps> = ({
    disableClose,
    onClose,
    state,
    stateAction,
    stateMessage,
    t,
}) => {
    const classes = useStyles();
    const sceneBarState = React.useMemo(
        () => (state === RefreshSceneBarState.ERROR ? SceneBarState.ERROR : SceneBarState.NORMAL),
        [state]
    );
    const loadingSpinner = React.useMemo(
        () => (state === RefreshSceneBarState.ERROR ? undefined : <CircularProgress data-id="loader" size={14} />),
        [state]
    );
    const message = React.useMemo(() => {
        return stateMessage ?? (state === RefreshSceneBarState.ERROR ? t('refresh-data-failed') : t('refreshing-data'));
    }, [state, stateMessage, t]);

    const actionButton = React.useMemo(
        () =>
            stateAction ? (
                <Link
                    className={classes.link}
                    data-id="on-retry-link"
                    onClick={stateAction}
                    underline="hover"
                    variant="caption"
                >
                    {t('refresh-data-try-again')}
                </Link>
            ) : undefined,
        [classes.link, stateAction, t]
    );
    return (
        <SceneBarTemplate
            actionButton={actionButton}
            disableClose={disableClose}
            icon={loadingSpinner}
            message={message}
            onClose={onClose}
            state={sceneBarState}
        />
    );
};
