import type { StoreState } from '~/reducers';

import type { EditorDialogSelectorArgs } from './models';

const landmarksAdminSelector = (state: StoreState) => state.landmarksAdministrationScene;

const editorDialogSelector =
    ({ landmarkId }: EditorDialogSelectorArgs) =>
    (state: StoreState) => ({
        landmarkNames: state.landmarksAdministrationScene.items
            .filter((item) => item.id !== landmarkId)
            .map((item) => item.name),
    });

export { editorDialogSelector, landmarksAdminSelector };
