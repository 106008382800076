import type { PositionGroupsMeta, PositionGroupsPayload } from '~/data/monitoring';
import type { Position } from '~/services/ApiClient';
import type { Action, BaseAction, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    POSITIONGROUPS = 'POSITIONGROUPS',
}

export enum ActionTypeKeys {
    HISTORYMAP_HIGHLIGHTPOSITION = 'HISTORYMAP_HIGHLIGHTPOSITION',
    HISTORYMAP_HIGHLIGHTPOSITIONGROUP = 'HISTORYMAP_HIGHLIGHTPOSITIONGROUP',
    HISTORYMAP_RESETSCROLLTOPOSITIONGROUP = 'HISTORYMAP_RESETSCROLLTOPOSITIONGROUP',
    HISTORYMAP_SCROLLTOPOSITIONGROUP = 'HISTORYMAP_SCROLLTOPOSITIONGROUP',
    POSITIONGROUPS_FULFILLED = 'POSITIONGROUPS_FULFILLED',
    POSITIONGROUPS_PENDING = 'POSITIONGROUPS_PENDING',
    POSITIONGROUPS_REJECTED = 'POSITIONGROUPS_REJECTED',
}

export type ActionTypes =
    | Action<ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITION, Position | undefined>
    | Action<ActionTypeKeys.HISTORYMAP_HIGHLIGHTPOSITIONGROUP, number | undefined>
    | BaseAction<ActionTypeKeys.HISTORYMAP_RESETSCROLLTOPOSITIONGROUP>
    | BaseAction<ActionTypeKeys.HISTORYMAP_SCROLLTOPOSITIONGROUP>
    | FulfilledMeta<ActionTypeKeys.POSITIONGROUPS_FULFILLED, PositionGroupsPayload, PositionGroupsMeta>
    | PendingMeta<ActionTypeKeys.POSITIONGROUPS_PENDING, PositionGroupsMeta>
    | RejectedMeta<ActionTypeKeys.POSITIONGROUPS_REJECTED, undefined, PositionGroupsMeta>;
