import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TitledIconButton } from '~/components/TitledIconButton';

import type { DetailsPaneHeaderProps } from './models';
import { useStyles } from './styles';

const DetailsPaneHeader: FC<DetailsPaneHeaderProps> = (props) => {
    const { onBack, onClose, title } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Paper className={classes.root} square>
            {onBack && (
                <TitledIconButton data-id="back-icon" onClick={onBack} title={t('back')}>
                    <ArrowBack />
                </TitledIconButton>
            )}

            <Typography className={classes.title} data-id="title" variant="subtitle2">
                {title}
            </Typography>

            {onClose && (
                <TitledIconButton data-id="close-icon" onClick={onClose} title={t('close')}>
                    <Close />
                </TitledIconButton>
            )}
        </Paper>
    );
};

DetailsPaneHeader.displayName = 'DetailsPaneHeader';
export default DetailsPaneHeader;
