import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

import { Container, Item } from './styles';

const AddPlusToIcon: FC<CustomIconInnerProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <Container>
            <Item>{props.children}</Item>

            <SvgIcon
                {...rest}
                height="24"
                overflow="visible"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" transform="scale(0.6) translate(-8, 8)" />
            </SvgIcon>
        </Container>
    );
};

AddPlusToIcon.displayName = 'AddPlusToIcon';
export { AddPlusToIcon };
