import { ACTIONS } from './actionTypes';
import type { ActionTypes, LandmarksAdministrationState } from './models';

const initialState: LandmarksAdministrationState = {
    error: false,
    items: [],
    pending: false,
};

const landmarksAdministrationReducer = (state = initialState, action: ActionTypes): LandmarksAdministrationState => {
    switch (action.type) {
        case ACTIONS.LIST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.LIST_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.LIST_FULFILLED:
            return {
                ...state,
                error: false,
                items: action.payload.items,
                pending: false,
            };
        default:
            return state;
    }
};

export { landmarksAdministrationReducer };
