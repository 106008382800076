import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { ColumnSelectorFooterClassKey } from './styles';

export interface ColumnSelectorFooterProps {
    selectedCount: number;
    totalNumber: number;
}

export interface ColumnSelectorFooterInnerProps
    extends ColumnSelectorFooterProps,
        WithStyles<ColumnSelectorFooterClassKey>,
        InjectedTranslationProps {}

export const ColumnSelectorFooterComponent: React.FC<ColumnSelectorFooterInnerProps> = ({
    classes,
    selectedCount,
    t,
    totalNumber,
}) => {
    const text = t('selected-of-total-matching', { selected: selectedCount, totalMatch: totalNumber });

    return (
        <Typography
            align="right"
            className={classes.root}
            color="textSecondary"
            data-id="selected-columns-text"
            display="block"
            variant="caption"
        >
            {text}
        </Typography>
    );
};
