import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

// Icons made by Freepik from www.flaticon.com
const DriverActivitiesGraphIconComponent: FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                d="M2,2H4V20H22V22H2V2M7,10H17V13H7V10M11,15H21V18H11V15M6,4H22V8H20V6H8V8H6V4Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

DriverActivitiesGraphIconComponent.displayName = 'ActivitiesGraphIcon';
export default DriverActivitiesGraphIconComponent;
