import { TOAST_TYPE, toast } from '@fv/components/Notification';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearRequest, notificationSelector, rejectedRequest } from '~/data/notifications';
import type { Dictionary } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import { RequestNotification, ServerResultStatus } from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

const GeozoneNotifications: FC = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(notificationSelector);
    const [disposables, setDisposables] = useState<Dictionary<Disposable>>({});
    const { t } = useTranslation();

    useEffect(() => {
        Object.values(notifications).forEach(({ id, requestId }) => {
            if (!requestId || disposables[requestId]) {
                return;
            }

            subscribeToNotificationsAndRetrieveTheData(
                `requests/${requestId}`,
                RequestNotification.fromJS,
                (notification) => {
                    const { success } = notification;

                    if (success) {
                        toast({
                            message: t('requests.success-request-text'),
                            onClose: () => dispatch(clearRequest({ id })),
                            type: TOAST_TYPE.SUCCESS,
                        });
                    } else {
                        toast({
                            message: t('requests.error-request-text'),
                            onClose: () => dispatch(rejectedRequest({ id })),
                            type: TOAST_TYPE.ERROR,
                        });
                    }

                    setDisposables((prev) => {
                        // dispose current subscription and return the rest
                        const { [requestId]: current, ...rest } = prev;
                        current.dispose();

                        return rest;
                    });
                },
                // no need to retrieve anything
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {}
            ).then((disposable) => {
                if (requestId) {
                    setDisposables((prev) => ({ ...prev, [requestId]: disposable }));
                }
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    useEffect(() => {
        Object.values(notifications)
            .filter(({ status }) => status === ServerResultStatus.PENDING)
            .forEach(({ shouldOpen }) => {
                if (shouldOpen) {
                    toast({ message: t('requests.pending-request-text'), type: TOAST_TYPE.INFO });
                }
            });
    }, [notifications, t]);

    return null;
};

export { GeozoneNotifications };
