import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { DefaultHeaderProps } from './models';
import { Checkbox, Container, IconButton, Search, Title } from './styles';

const DefaultHeader: FC<DefaultHeaderProps> = (props) => {
    const {
        expandedAll,
        handleCollapse,
        hideSelectAllCheckbox,
        onToggleSelectAll,
        openSearch,
        partiallySelected,
        searchingItemsName,
        selectedAll,
    } = props;

    const { t } = useTranslation();

    const handleToggleSelectAll = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onToggleSelectAll({ event, value: checked });
    };

    return (
        <Container data-id="search-header-close">
            <Tooltip title={expandedAll ? t('collapse-all') : t('expand-all')}>
                <IconButton
                    data-expanded={expandedAll}
                    data-id="expand-all"
                    disableRipple
                    onClick={(event) => handleCollapse({ event, id: 'all', value: !expandedAll })}
                    size="large"
                >
                    {expandedAll ? <UnfoldLess /> : <UnfoldMore />}
                </IconButton>
            </Tooltip>

            {!hideSelectAllCheckbox && (
                <Tooltip title={selectedAll ? t('clear-selection') : t('select-all')}>
                    <Checkbox
                        checked={selectedAll}
                        data-id="select-all"
                        indeterminate={partiallySelected}
                        onChange={handleToggleSelectAll}
                    />
                </Tooltip>
            )}

            <Title>
                <Typography color="textPrimary" variant="subtitle1">
                    {searchingItemsName}
                </Typography>
            </Title>

            <Search aria-label="search" data-id="search-icon" onClick={openSearch} />
        </Container>
    );
};

DefaultHeader.displayName = 'DefaultHeader';
export { DefaultHeader };
