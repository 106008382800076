import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import { BooleanIconFormatter, DimensionsFormatter, SpeedFormatter, WeightFormatter } from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import { createClassificationColumn, createStringColumn, filterDataSourceBySearchQuery } from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { Classification, DisplayUserPreferencesUnitSystem, ResolvedVehicleType } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    formatBoolean,
    getDimensionsUnitSystemUtils,
    getSpeedUnitSystemUtils,
    getWeightUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, compareNumbers, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';

export const filterDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: ResolvedVehicleType[],
        columnDefinitions: Array<GridColumnDefinition<ResolvedVehicleType>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);

export const getColumns = (
    t: SingleTFunction,
    vehicleTypeCategories: NumericDictionary<Classification>,
    axles: NumericDictionary<Classification>,
    emissionClasses: NumericDictionary<Classification>,
    unitSystem: DisplayUserPreferencesUnitSystem
): Array<GridColumnDefinition<ResolvedVehicleType>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const speedUtils = getSpeedUnitSystemUtils(t, unitSystem);
    const weightUtils = getWeightUnitSystemUtils(t, unitSystem);
    const dimensionsUtils = getDimensionsUnitSystemUtils(t, unitSystem);

    return [
        createStringColumn(ColumnName.NAME, t('name'), t('general'), (e) => e.name),
        createStringColumn(ColumnName.DESCRIPTION, t('description'), t('general'), (e) => e.description),
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.00 "${dimensionsUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => dimensionsUtils.converter(v, { precision: 2 })),
            getCellValue: (e) => e.dimensions?.height,
            getFilterValue: (v: number) => v,
            groupingCriteria: groupingCriteriaFactory((v: number) => dimensionsUtils.formatter(v, { precision: 0 })),
            groupTitle: t('dimensions'),
            name: ColumnName.HEIGHT,
            title: t('height'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DimensionsFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) =>
                dimensionsUtils.formatter(v, { precision: 2 })
            ),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.00 "${dimensionsUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => dimensionsUtils.converter(v, { precision: 2 })),
            getCellValue: (e) => e.dimensions?.width,
            getFilterValue: (v: number) => v,
            groupingCriteria: groupingCriteriaFactory((v: number) => dimensionsUtils.formatter(v, { precision: 0 })),
            groupTitle: t('dimensions'),
            name: ColumnName.WIDTH,
            title: t('width'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DimensionsFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) =>
                dimensionsUtils.formatter(v, { precision: 2 })
            ),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.00 "${dimensionsUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => dimensionsUtils.converter(v, { precision: 2 })),
            getCellValue: (e) => e.dimensions?.length,
            getFilterValue: (v: number) => v,
            groupingCriteria: groupingCriteriaFactory((v: number) => dimensionsUtils.formatter(v, { precision: 0 })),
            groupTitle: t('dimensions'),
            name: ColumnName.LENGTH,
            title: t('length'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DimensionsFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) =>
                dimensionsUtils.formatter(v, { precision: 2 })
            ),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${weightUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            getCellValue: (e) => e.weight,
            getFilterValue: (v: number) => v,
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            groupTitle: t('dimensions'),
            name: ColumnName.WEIGHT,
            title: t('weight'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
        },
        createClassificationColumn(
            t,
            ColumnName.TYPE,
            t('type'),
            t('type'),
            (e) => e.type,
            ClassificationType.VehicleTypeCategory,
            {
                classifications: Object.values(vehicleTypeCategories),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.AXLES,
            t('axles'),
            t('type'),
            (e) => e.axles,
            ClassificationType.Axles,
            {
                classifications: Object.values(axles),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.EMISSIONCLASS,
            t('emission-class'),
            t('type'),
            (e) => e.emissionClass,
            ClassificationType.EmissionClass,
            {
                classifications: Object.values(emissionClasses),
            }
        ),
        {
            align: 'left',
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            dataType: 'boolean',
            exportValueFormatter: undefined,
            getCellValue: (e) => e.hazardousGoods,
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            groupTitle: t('type'),
            name: ColumnName.HAZARDOUSGOODS,
            title: t('hazardous-goods'),
            valueFormatterComponent: BooleanIconFormatter,
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${speedUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => speedUtils.converter(v)),
            getCellValue: (e) => speedUtils.converter(e?.speedLimit?.motorway),
            getFilterValue: (v: number) => speedUtils.converter(v),
            groupingCriteria: groupingCriteriaFactory((v: number) => speedUtils.formatter(v)),
            groupTitle: t('speed-limits'),
            name: ColumnName.SPEEDLIMITMOTORWAY,
            title: t('speed-limit-motorway'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => speedUtils.formatter(v)),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${speedUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => speedUtils.converter(v)),
            getCellValue: (e) => speedUtils.converter(e?.speedLimit?.road),
            getFilterValue: (v: number) => speedUtils.converter(v),
            groupingCriteria: groupingCriteriaFactory((v: number) => speedUtils.formatter(v)),
            groupTitle: t('speed-limits'),
            name: ColumnName.SPEEDLIMITROAD,
            title: t('speed-limit-road'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => speedUtils.formatter(v)),
        },
        {
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0 "${speedUtils.unit}"`,
            exportValueFormatter: exportConvertorFactory((v: number) => speedUtils.converter(v)),
            getCellValue: (e) => speedUtils.converter(e?.speedLimit?.city),
            getFilterValue: (v: number) => speedUtils.converter(v),
            groupingCriteria: groupingCriteriaFactory((v: number) => speedUtils.formatter(v)),
            groupTitle: t('speed-limits'),
            name: ColumnName.SPEEDLIMITCITY,
            title: t('speed-limit-city'),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: number) => speedUtils.formatter(v)),
        },
    ];
};

export const getColumnsMemoized = memoizeOne(getColumns);
