/* eslint-disable react/jsx-one-expression-per-line */
import { Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';

import { DateTimeFormatter, DurationFormatter, adaptiveDateTimeFormatterFactory } from '~/components/Formatters';
import { LabeledIndicator, LabeledIndicatorGroup } from '~/components/Indicators';
import type { InjectedTranslationProps } from '~/components/LanguageSelector/';
import type { ResolvedDriverHoursStatus } from '~/services/ApiClient';
import { ViolationType } from '~/services/ApiClient';
import { mapViolationTypeToColorVariant } from '~/services/Styling';

import {
    getActivityStartDateTimeValue,
    getContinuousDriveRemainingValue,
    getContinuousDriveValue,
    getCurrentActivityDurationValue,
    getDriveBreakDueValue,
    getDriveBreakValue,
    isLastShift,
} from '../../../../getCellValue';

import type { ContinuousDrivingClassKey } from './styles';

export interface ContinuousDrivingProps {
    driverHours: ResolvedDriverHoursStatus;
}

export interface ContinuousDrivingInnerProps
    extends ContinuousDrivingProps,
        InjectedTranslationProps,
        WithStyles<ContinuousDrivingClassKey> {}

export const ContinuousDrivingComponent: React.FC<ContinuousDrivingInnerProps> = ({ classes, driverHours, t }) => {
    const continuousDriveValue = getContinuousDriveValue(driverHours);
    const remainingValue = getContinuousDriveRemainingValue();
    const driveBreakValue = getDriveBreakValue(driverHours);
    const driveBreakDueValue = getDriveBreakDueValue();
    const activityStartDT = getActivityStartDateTimeValue(driverHours);
    const activityDuration = getCurrentActivityDurationValue(driverHours);
    const dateTime = moment(activityStartDT).add(activityDuration).toDate();

    const formattedAccumulatedDuration = <DurationFormatter value={continuousDriveValue?.value} />;
    const formattedRemainingValueDuration = <DurationFormatter value={remainingValue?.value} />;
    const formattedBreakDueValue = <DurationFormatter value={driveBreakDueValue} />;
    const formattedBreakTakenValue = <DurationFormatter value={driveBreakValue} />;

    const taken =
        !driveBreakValue || driveBreakValue.asMilliseconds() === 0 ? (
            <span>{t('none')}</span>
        ) : (
            formattedBreakTakenValue
        );
    const formattedBreakTaken = <Trans i18nKey="taken-break">{taken}</Trans>;

    const formattedBreakDueValueDuration = !driveBreakDueValue
        ? undefined
        : driveBreakDueValue.asMilliseconds() === 0
          ? t('none')
          : formattedBreakDueValue;

    const accumulatedDataId = continuousDriveValue?.violationType ? `:${continuousDriveValue?.violationType}` : '';
    const momentOfViolation = moment(dateTime).subtract(remainingValue?.violation);
    const momentOfViolationDate = momentOfViolation.toDate();

    const AdaptiveDateTimeFormatter = adaptiveDateTimeFormatterFactory();
    const adaptiveDateTime = (
        <span className={classes.capitalize}>
            <AdaptiveDateTimeFormatter t={t} value={momentOfViolationDate} />
        </span>
    );

    const formattedDateTime = <DateTimeFormatter value={momentOfViolationDate} />;
    const formattedRemainingViolation = <DurationFormatter value={remainingValue?.violation} />;

    const formattedRemainingViolationDuration = remainingValue?.violation && formattedRemainingViolation;

    const violationSince = (
        <Tooltip data-id="secondary-text-tooltip" title={formattedDateTime}>
            <span>
                <Trans i18nKey="since-violation">{adaptiveDateTime}</Trans>
            </span>
        </Tooltip>
    );
    const remainingViolated = remainingValue?.violationType === ViolationType.Violation;

    const remainingTitle = remainingViolated ? t('overrun') : t('remaining');
    const remainingPrimaryText = !remainingValue
        ? undefined
        : remainingViolated
          ? formattedRemainingViolationDuration
          : formattedRemainingValueDuration;

    const remainingValueSecondaryText = remainingViolated
        ? violationSince
        : isLastShift()
          ? t('until-shift-ends').toLowerCase()
          : t('before-break').toLowerCase();

    const remainingDataId = remainingValue?.violationType ? `:${remainingValue?.violationType}` : '';

    return (
        <LabeledIndicatorGroup dataId="continuous-driving-group" title={t('continuous-driving')}>
            <LabeledIndicator
                dataId={`accumulated-time-indicator${accumulatedDataId}`}
                primaryText={continuousDriveValue?.value ? formattedAccumulatedDuration : t('not-available')}
                secondaryText={t('since-last-block')}
                title={t('accumulated')}
                variant={mapViolationTypeToColorVariant(continuousDriveValue?.violationType)}
            />

            <LabeledIndicator
                dataId={`remaining-time-indicator${remainingDataId}`}
                primaryText={remainingPrimaryText ?? t('not-available')}
                secondaryText={remainingValueSecondaryText}
                title={remainingTitle}
                variant={mapViolationTypeToColorVariant(remainingValue?.violationType)}
            />

            <LabeledIndicator
                dataId="break-due-indicator"
                primaryText={formattedBreakDueValueDuration ?? t('not-available')}
                secondaryText={formattedBreakDueValueDuration ? formattedBreakTaken : ''}
                title={t('break-due')}
            />
        </LabeledIndicatorGroup>
    );
};
