import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';

import type { SectionNameValues } from './constants';
import { SectionName } from './constants';

export const ALERTS_DETAILSPANE_USERPREFERENCES_KEY = 'alerts-detailspane-state';

export interface AlertsDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionNameValues> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const defaultDetailsPaneUserPreferences: AlertsDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 13,
    sectionOrder: [
        SectionName.Header,
        SectionName.Location,
        SectionName.General,
        SectionName.Acknowledgement,
        SectionName.Recipients,
    ],
};

export const requiredDetailsPaneUserPreferences = {
    defaultState: defaultDetailsPaneUserPreferences,
    key: ALERTS_DETAILSPANE_USERPREFERENCES_KEY,
};
