import { Button } from '@mui/material';
import * as React from 'react';
import { withProps } from 'react-recompose';

import type { FilteringComponentType } from '~/components/Dialogs/SelectEntryDialogFactory';
import { SelectEntryDialogFactory } from '~/components/Dialogs/SelectEntryDialogFactory';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    NamedTrailerReference,
    PojoModel,
    ResolvedVehicleConnection,
    VehicleCategory,
} from '~/services/ApiClient';
import { formatTrailerName } from '~/services/Formatters';

import { canConnectVehicle } from './canConnectVehicle';
import { VehicleEntryRow } from './components/VehicleEntryRow';
import type { VehicleFilter } from './components/VehicleFiltering';
import { VehicleFiltering } from './components/VehicleFiltering';
import { filterVehicles } from './filterVehicles';
import { getVehicleEntryId } from './getVehicleEntryId';

export interface StateProps {
    vehicleCategories: VehicleCategory[];
    vehicles: MonitoringVehicleEntry[];
}

export interface DispatchProps {
    connectVehicle: (vehicleId: number) => void;
}

export interface ConnectVehicleDialogProps {
    onClose: () => void;
    trailer: PojoModel<NamedTrailerReference>;

    vehicleConnection?: ResolvedVehicleConnection;
}

export interface ConnectVehicleDialogInnerProps
    extends ConnectVehicleDialogProps,
        StateProps,
        DispatchProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const ConnectVehicleDialogComponent: React.FC<ConnectVehicleDialogInnerProps> = ({
    connectVehicle,
    displayPreferences,
    onClose,
    t,
    trailer,
    vehicleCategories,
    vehicleConnection,
    vehicles,
}) => {
    const [selectedEntry, setSelectedEntry] = React.useState<MonitoringVehicleEntry | undefined>();
    const selectableVehicles = React.useMemo(() => {
        return vehicleConnection
            ? vehicles.filter(({ vehicle }) => {
                  return vehicle.id !== vehicleConnection?.vehicleReference.id;
              })
            : vehicles;
    }, [vehicleConnection, vehicles]);

    const trailerName = formatTrailerName(trailer, displayPreferences.trailerDisplayFormat);
    const title = vehicleConnection ? (
        <span data-id="dialog-title">{t('replace-vehicle-dialog-title', { trailerName })}</span>
    ) : (
        <span data-id="dialog-title">{t('connect-vehicle-dialog-title', { trailerName })}</span>
    );

    const trailerVehicleConnectionCanManuallyDisconnect = vehicleConnection?.canManuallyDisconnect;
    const canConnectVehicleToEntry = React.useCallback(
        (vehicleEntry: MonitoringVehicleEntry) =>
            canConnectVehicle(vehicleEntry, trailerVehicleConnectionCanManuallyDisconnect),
        [trailerVehicleConnectionCanManuallyDisconnect]
    );

    const SelectEntryDialog = React.useMemo(() => {
        return SelectEntryDialogFactory<MonitoringVehicleEntry, VehicleFilter>(
            getVehicleEntryId,
            canConnectVehicleToEntry
        );
    }, [canConnectVehicleToEntry]);

    const handleConnectClick = React.useCallback(() => {
        if (selectedEntry) {
            connectVehicle(selectedEntry.vehicle.id);
            onClose();
        }
    }, [selectedEntry, connectVehicle, onClose]);

    const actionButtons = (
        <>
            <Button color="secondary" data-id="cancel" onClick={onClose}>
                {t('cancel')}
            </Button>
            <Button
                color="secondary"
                data-id="connect"
                disabled={!selectedEntry}
                onClick={handleConnectClick}
                variant="contained"
            >
                {t('connect')}
            </Button>
        </>
    );

    const FilteringComponent: FilteringComponentType<VehicleFilter> = React.useMemo(() => {
        return withProps({ vehicleCategories })(VehicleFiltering);
    }, [vehicleCategories]);

    const handleSelectEntry = React.useCallback(
        (e: MonitoringVehicleEntry) => {
            setSelectedEntry(e);
        },
        [setSelectedEntry]
    );

    const filterEntries = React.useCallback(
        (entries: MonitoringVehicleEntry[], searchQuery?: string, filter?: VehicleFilter) =>
            filterVehicles({
                driverDisplayFormat: displayPreferences.driverDisplayFormat,
                entries,
                filter,
                searchQuery,
                t,
                vehicleNameDisplayFormat: displayPreferences.vehicleDisplayFormat,
            }),
        [t, displayPreferences.vehicleDisplayFormat, displayPreferences.driverDisplayFormat]
    );

    return (
        <SelectEntryDialog
            actionButtons={actionButtons}
            dataId="connect-vehicle-dialog"
            entries={selectableVehicles}
            EntryRow={VehicleEntryRow}
            entryTypePlural={t('vehicles')}
            entryTypeSingular={t('vehicle')}
            filterEntries={filterEntries}
            FilteringComponent={FilteringComponent}
            onClose={onClose}
            onEntrySelect={handleSelectEntry}
            open
            selectedEntry={selectedEntry}
            title={title}
        />
    );
};
