import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { RetryAction } from '~/components/RetryAction';
import type { Status } from '~/components/RetryAction';

export interface RetryRetrieveSnackbarProps {
    onRetry: () => void;
    status: Status;
}

export interface RetryRetrieveSnackbarInnerProps extends RetryRetrieveSnackbarProps, InjectedTranslationProps {}

export const RetryRetrieveSnackbarComponent: React.FunctionComponent<RetryRetrieveSnackbarInnerProps> = ({
    onRetry,
    status,
    t,
}) => (
    <RetryAction
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        dataIdPrefix="retrieve-user-preferences"
        onRetry={onRetry}
        retryMessage={t('failed-to-retrieve-user-preferences')}
        status={status}
        successMessage={t('retrieve-user-preferences-retry-succeeded')}
    />
);
