const ACTION_TYPE_PREFIXES = {
    CHANGELOG: 'ADMINTRAILERCHANGELOGS_GET',
    CREATE: 'TRAILERS_ADMINISTRATION_CREATE',
    DEFAULT_SETTINGS: 'TRAILERS_ADMINISTRATION_DEFAULT_SETTINGS',
    DELETE: 'TRAILERS_ADMINISTRATION_DELETE',
    LIST: 'TRAILERS_ADMINISTRATION_LIST',
    RESET: 'TRAILERS_ADMINISTRATION_RESET',
    SETTINGS: 'TRAILERS_ADMINISTRATION_SETTINGS',
    UPDATE: 'TRAILERS_ADMINISTRATION_UPDATE',
} as const;

const ACTION_TYPE_KEYS = {
    CHANGELOG_FULFILLED: `${ACTION_TYPE_PREFIXES.CHANGELOG}_FULFILLED`,
    CHANGELOG_PENDING: `${ACTION_TYPE_PREFIXES.CHANGELOG}_PENDING`,
    CHANGELOG_REJECTED: `${ACTION_TYPE_PREFIXES.CHANGELOG}_REJECTED`,
    CREATE_FULFILLED: `${ACTION_TYPE_PREFIXES.CREATE}_FULFILLED`,
    CREATE_PENDING: `${ACTION_TYPE_PREFIXES.CREATE}_PENDING`,
    CREATE_REJECTED: `${ACTION_TYPE_PREFIXES.CREATE}_REJECTED`,
    DEFAULT_SETTINGS_FULFILLED: `${ACTION_TYPE_PREFIXES.DEFAULT_SETTINGS}_FULFILLED`,
    DEFAULT_SETTINGS_PENDING: `${ACTION_TYPE_PREFIXES.DEFAULT_SETTINGS}_PENDING`,
    DEFAULT_SETTINGS_REJECTED: `${ACTION_TYPE_PREFIXES.DEFAULT_SETTINGS}_REJECTED`,
    LIST_FULFILLED: `${ACTION_TYPE_PREFIXES.LIST}_FULFILLED`,
    LIST_PENDING: `${ACTION_TYPE_PREFIXES.LIST}_PENDING`,
    LIST_REJECTED: `${ACTION_TYPE_PREFIXES.LIST}_REJECTED`,
    RESET: ACTION_TYPE_PREFIXES.RESET,
    SETTINGS_FULFILLED: `${ACTION_TYPE_PREFIXES.SETTINGS}_FULFILLED`,
    SETTINGS_PENDING: `${ACTION_TYPE_PREFIXES.SETTINGS}_PENDING`,
    SETTINGS_REJECTED: `${ACTION_TYPE_PREFIXES.SETTINGS}_REJECTED`,
    UPDATE_FULFILLED: `${ACTION_TYPE_PREFIXES.UPDATE}_FULFILLED`,
    UPDATE_PENDING: `${ACTION_TYPE_PREFIXES.UPDATE}_PENDING`,
    UPDATE_REJECTED: `${ACTION_TYPE_PREFIXES.UPDATE}_REJECTED`,
} as const;

export { ACTION_TYPE_KEYS, ACTION_TYPE_PREFIXES };
