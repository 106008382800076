import continentalLogoUrl from '~/assets/continental-logo.svg';
import ivecoLogoUrl from '~/assets/iveco-logo.svg';

import { createAppTheme } from './styles/createAppTheme';

const defaultTheme = createAppTheme({
    functionalItemsColors: {
        driverActivity: {
            available: { contrast: '#FFFFFF', value: '#ffc92a' },
            drive: { contrast: '#FFFFFF', value: '#dc3e3e' },
            rest: { contrast: '#FFFFFF', value: '#35a119' },
            subtotal: { contrast: '#FFFFFF', value: '#f39500' },
            work: { contrast: '#FFFFFF', value: '#1b93e1' },
        },
    },
});

const continentalTheme = createAppTheme({
    palette: {
        primary: { main: '#FFA700' },
        secondary: { main: '#151821' },
    },
    partner: {
        analyticsSolutionName: 'Astrata Dash',
        logoUrl: continentalLogoUrl,
        name: 'Continental',
        url: 'https://www.continental.com',
    },
});

const ivecoTheme = createAppTheme({
    palette: {
        primary: { main: '#003087' },
        secondary: { main: '#B1303B' },
    },
    partner: {
        analyticsSolutionName: 'Astrata Dash',
        logoUrl: ivecoLogoUrl,
        name: 'Iveco',
        url: 'https://www.iveco.com',
    },
});

export const themes = {
    continental: continentalTheme,
    default: defaultTheme,
    iveco: ivecoTheme,
};
