import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const COMPANY_CARD_ADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY =
    'company-card-administration-detailspane-state';
export const defaultCompanyCardAdministrationDetailsPaneUserPreferences: SceneDetailsPaneUserPreferences<SectionName> =
    {
        collapsedSections: [],
        sectionOrder: [
            SectionName.HEADER,
            SectionName.GENERAL,
            SectionName.VALIDITY,
            SectionName.ISSUED,
            SectionName.COMPANY,
        ],
    };

export const requiredCompanyCardAdministrationDetailsPaneUserPreferences = {
    defaultState: defaultCompanyCardAdministrationDetailsPaneUserPreferences,
    key: COMPANY_CARD_ADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
};
