import { MenuItem } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Classification, ClassificationAbstraction, ClassificationType } from '~/services/ApiClient';
import { EnumControlType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import type { BaseProps } from '../model';
import { OptionsInput } from '../OptionsInput';

export interface ClassificationsInputProps extends BaseProps<unknown> {
    classificationType?: ClassificationType;
    idIsString?: boolean;
    values: ClassificationAbstraction[];
}

export interface ClassificationsInputInnerProps extends ClassificationsInputProps, InjectedTranslationProps {}

export const ClassificationsInputComponent: React.FC<ClassificationsInputInnerProps> = (props) => {
    const {
        classificationType,
        className,
        dataId,
        disabled,
        disabledReason,
        errorText,
        fieldName,
        hasError,
        idIsString,
        label,
        markValueChange,
        onBlurChanged,
        onValueChanged,
        readonly,
        required,
        t,
        validationRules,
        values,
    } = props;

    const selectedValueParser = React.useCallback((stringValue: string) => {
        const parseValue = parseInt(stringValue, 10);
        return Number.isNaN(parseValue) ? undefined : parseValue;
    }, []);

    const optionValues = React.useMemo(() => {
        return Object.values(values).map((value: ClassificationAbstraction) => {
            const menuItemValue: number | string = idIsString === true ? value.id : value.id;

            return (
                <MenuItem key={value.key} value={menuItemValue}>
                    {classificationType
                        ? formatClassification(t, classificationType, value as Classification)
                        : value.displayName}
                </MenuItem>
            );
        });
    }, [classificationType, t, values, idIsString]);

    return (
        <OptionsInput
            className={className}
            controlType={EnumControlType.Dropdown}
            dataId={dataId}
            disabled={disabled}
            disabledReason={disabledReason}
            errorText={errorText}
            fieldName={fieldName}
            hasError={hasError}
            idIsString={idIsString}
            label={label}
            markValueChange={markValueChange}
            onBlurChanged={onBlurChanged}
            onValueChanged={onValueChanged}
            readonly={readonly}
            required={required}
            selectedValueParser={selectedValueParser}
            validationRules={validationRules}
            values={optionValues}
        />
    );
};
