import { Backdrop, CircularProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import type { TriggerDataRefresh } from '~/common';
import { AssetSelector, useStaticDataProvider } from '~/common';
import { RefreshSceneBar, RefreshSceneBarState } from '~/common/components/RefreshSceneBar';
import { AskForPageReload } from '~/components/AskForPageReload';
import type { ContextMenuPosition } from '~/components/ContextMenu';
import { DateTimeRangePicker } from '~/components/DateTimeRangePicker';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PageTemplate } from '~/components/PageTemplate';
import { SceneLoader } from '~/components/SceneLoader';
import { debounce } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import { AssetType, TachoFileType } from '~/services/ApiClient';

import { TachoFileListViewHeader, TachoFilesListView } from './components/List';
import { TachoFileContextMenu } from './components/TachoFileContextMenu';
import { rowIdSeparator } from './entryUtils';
import type { GetTachoFilesArgs } from './models';
import { TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY } from './preferences';
import type { TachoFilesReduxProps } from './redux';
import type { TachoFilesClassKeys } from './styles';

export interface TachoFilesProps {}

export interface TachoFilesInnerProps
    extends TachoFilesProps,
        InjectedTranslationProps,
        TachoFilesReduxProps,
        WithStyles<TachoFilesClassKeys> {}
export interface TachoFilesComponentState {
    highlightedTachoFileId?: string;
    tachoFileContextMenu?: {
        fileId: number;
        fileType: TachoFileType;
        position: ContextMenuPosition;
    };
    triggerDataRefresh: TriggerDataRefresh;
}

export const TachoFilesComponent: React.FC<TachoFilesInnerProps> = ({
    authorized,
    canViewDriverCardFiles,
    canViewTachographFiles,
    changeDateTimeRange,
    classes,
    clearData,
    contentLoading,
    dateTimeRange,
    dateTimeThreshold,
    getTachoFiles,
    leftPaneIsOpen,
    loading,
    rejected,
    rmsEnabled,
    selectedDriverIds,
    selectedVehicleIds,
    t,
    tachoFilesEntries,
    toggleLeftPaneVisibility,
}) => {
    const staticDataProvider = useStaticDataProvider();

    const [state, setState] = React.useState<TachoFilesComponentState>({
        triggerDataRefresh: {
            initialLoad: true,
            showBanner: false,
            triggered: false,
        },
    });

    const onCloseRefreshSceneBar = React.useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                showBanner: false,
                triggered: prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const onRefreshData = React.useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            triggerDataRefresh: {
                initialLoad: false,
                showBanner: true,
                triggered: !prevState.triggerDataRefresh.triggered,
            },
        }));
    }, [setState]);

    const dispatchGetTachoFilesDebounced = React.useMemo(
        () =>
            debounce<(args: GetTachoFilesArgs) => void>(({ driverIds, startDateTime, stopDateTime, vehicleIds }) => {
                getTachoFiles(startDateTime, stopDateTime, vehicleIds, driverIds);
            }, 500),
        [getTachoFiles]
    );

    React.useEffect(() => {
        dispatchGetTachoFilesDebounced({
            driverIds: selectedDriverIds,
            startDateTime: dateTimeRange.startDate,
            stopDateTime: dateTimeRange.endDate,
            vehicleIds: selectedVehicleIds,
        });
    }, [
        dateTimeRange.endDate,
        dateTimeRange.startDate,
        dispatchGetTachoFilesDebounced,
        selectedDriverIds,
        selectedVehicleIds,
        state.triggerDataRefresh.triggered,
    ]);

    React.useEffect(() => {
        staticDataProvider.retrieveVehicles();
        staticDataProvider.retrieveDrivers();

        return () => clearData();
    }, [clearData, staticDataProvider]);

    const onRowContextMenu = React.useCallback(
        (evt: MouseEvent, rowId: string) => {
            const parts = rowId.split(rowIdSeparator);
            const fileType = Object.values(TachoFileType).find((it) => it === parts[0]);
            const fileId = +parts[1];

            if (!fileId || !fileType) {
                return;
            }

            setState({
                ...state,
                highlightedTachoFileId: rowId,
                tachoFileContextMenu: {
                    fileId,
                    fileType,
                    position: { clientX: evt.clientX, clientY: evt.clientY },
                },
            });

            evt.preventDefault();
            evt.stopPropagation();
        },
        [state]
    );

    const closeContextMenu = React.useCallback(
        () =>
            setState((prevState) => ({
                ...prevState,
                highlightedTachoFileId: undefined,
                tachoFileContextMenu: undefined,
            })),
        []
    );

    const header = (
        <TachoFileListViewHeader
            contentLoading={contentLoading}
            dataSource={tachoFilesEntries}
            filters={
                <DateTimeRangePicker onChange={changeDateTimeRange} value={dateTimeRange} {...dateTimeThreshold} />
            }
            gridActionsDisabled={selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0}
            onRefreshData={onRefreshData}
            rmsEnabled={rmsEnabled}
            title={t('tacho-files')}
        />
    );

    const getTopBanner = () => {
        if (
            (contentLoading || rejected) &&
            !state.triggerDataRefresh.initialLoad &&
            state.triggerDataRefresh.showBanner
        ) {
            return (
                <RefreshSceneBar
                    disableClose={contentLoading}
                    onClose={onCloseRefreshSceneBar}
                    state={rejected ? RefreshSceneBarState.ERROR : RefreshSceneBarState.REFRESHING}
                    stateAction={rejected ? onRefreshData : undefined}
                />
            );
        }
        return undefined;
    };

    const getLeftPane = (): JSX.Element | undefined => {
        if (!leftPaneIsOpen) {
            return undefined;
        }

        let assetTypes: AssetType[] = [AssetType.Driver, AssetType.Vehicle];
        if (!canViewTachographFiles) {
            assetTypes = [AssetType.Driver];
        }
        if (!canViewDriverCardFiles) {
            assetTypes = [AssetType.Vehicle];
        }

        return <AssetSelector assetTypes={assetTypes} preferencesKey={TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY} />;
    };

    const getViewContent = (): JSX.Element | string => {
        if (selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0) {
            return (
                <div className={classes.messageText} data-id="no-selection">
                    {t('no-assets-selected')}
                </div>
            );
        }
        return (
            <>
                <Backdrop
                    className={classes.backDrop}
                    data-id="back-drop"
                    open={contentLoading && !!state.triggerDataRefresh.initialLoad}
                >
                    <CircularProgress className={classes.loader} data-id="backdrop-loader" size={120} />
                </Backdrop>
                <TachoFilesListView
                    dataSource={tachoFilesEntries}
                    highlightedAssetId={state.highlightedTachoFileId}
                    onRowContextMenu={onRowContextMenu}
                />
            </>
        );
    };

    if (!authorized) {
        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
    }

    if (rejected && state.triggerDataRefresh.initialLoad) {
        return <AskForPageReload />;
    }

    if (loading && !rejected && !contentLoading) {
        return <SceneLoader />;
    }

    const leftPaneTitle = t(leftPaneIsOpen ? 'collapse-asset-selector' : 'expand-asset-selector');

    return (
        <div className={classes.root} data-id="tacho-files">
            <PageTemplate
                header={header}
                leftPaneElement={getLeftPane()}
                leftPaneToggleTitle={leftPaneTitle}
                toggleLeftPaneVisibility={toggleLeftPaneVisibility}
                topPaneElement={getTopBanner()}
            >
                {getViewContent()}
            </PageTemplate>
            <TachoFileContextMenu {...state.tachoFileContextMenu} onClose={closeContextMenu} onUpload={onRefreshData} />
        </div>
    );
};
