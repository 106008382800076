import type { Driver, Trailer, Vehicle } from '~/common';
import { getValueFromHash } from '~/common';
import type { NumericDictionary } from '~/libs/utility';
import { uuidv4 } from '~/libs/utility';

import type { QueryAssetsNearbyPositionsResolved, QueryAssetsNearbyPositionsResponse } from './models';

interface ResolveAssetPositionsProps {
    firstPositionOnly?: boolean;
    source: QueryAssetsNearbyPositionsResponse[];
    staticData: NumericDictionary<Driver | Trailer | Vehicle>;
}

const resolveAssetPositions = ({
    firstPositionOnly,
    source,
    staticData,
}: ResolveAssetPositionsProps): QueryAssetsNearbyPositionsResolved[] =>
    source.flatMap(({ assetReference, positions }) => {
        const asset = getValueFromHash(staticData, assetReference.id);
        if (!asset) {
            return [];
        }

        return firstPositionOnly
            ? [{ asset, id: uuidv4(), ...positions[0] }]
            : positions.map((position) => ({
                  ...position,
                  asset,
                  id: uuidv4(),
              }));
    });

const getAssetNearByListResolver = (
    source: QueryAssetsNearbyPositionsResponse[],
    staticData: NumericDictionary<Driver | Trailer | Vehicle>
): QueryAssetsNearbyPositionsResolved[] => resolveAssetPositions({ source, staticData });

const getAssetNearByMapResolver = (
    source: QueryAssetsNearbyPositionsResponse[],
    staticData: NumericDictionary<Driver | Trailer | Vehicle>
): QueryAssetsNearbyPositionsResolved[] => resolveAssetPositions({ firstPositionOnly: true, source, staticData });

export { getAssetNearByListResolver, getAssetNearByMapResolver };
