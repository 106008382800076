import type { ActionItem } from '@fv/components/Actions';
import * as React from 'react';

import type { SectionDefinition } from '~/components/Sections';
import type { ServicesSecurables } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { VehicleAdministrationEntry } from '../../models';

import { FuelSection } from './components/FuelSection';
import { GeneralSection } from './components/GeneralSection';
import { HeaderSection } from './components/HeaderSection';
import { ServicesSection } from './components/ServicesSection';
import { TachographSection } from './components/TachographSection';
import { SectionName } from './constants';

interface GetSectionArgs {
    collapsedSections: SectionName[];
    entry: VehicleAdministrationEntry;
    headerActions: ActionItem[];
    toggleCollapsedState: (sectionName: SectionName) => () => void;
    vehicleAdministrationServices: ServicesSecurables;
}

export const getSections = (args: GetSectionArgs): SectionDefinition[] => {
    const { collapsedSections, entry, headerActions, toggleCollapsedState, vehicleAdministrationServices } = args;

    return [
        {
            content: () => <HeaderSection entry={entry} headerActions={headerActions} />,
            name: SectionName.VEHICLEADMINISTRATIONHEADER,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <GeneralSection
                    adminVehicle={entry.adminVehicle}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLEADMINISTRATIONGENERAL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLEADMINISTRATIONGENERAL)}
                />
            ),
            name: SectionName.VEHICLEADMINISTRATIONGENERAL,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <TachographSection
                    adminVehicle={entry.adminVehicle}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLEADMINISTRATIONTACHOGRAPH)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLEADMINISTRATIONTACHOGRAPH)}
                />
            ),
            name: SectionName.VEHICLEADMINISTRATIONTACHOGRAPH,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ServicesSection
                    adminVehicle={entry.adminVehicle}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLEADMINISTRATIONSERVICES)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLEADMINISTRATIONSERVICES)}
                    vehicleAdministrationServices={vehicleAdministrationServices}
                />
            ),
            name: SectionName.VEHICLEADMINISTRATIONSERVICES,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <FuelSection
                    adminVehicle={entry.adminVehicle}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLEADMINISTRATIONFUEL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLEADMINISTRATIONFUEL)}
                />
            ),
            name: SectionName.VEHICLEADMINISTRATIONFUEL,
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
