import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Paper, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC, MouseEvent } from 'react';
import { useCallback, useContext } from 'react';

import { DriverNameFormatter } from '~/components/Formatters';
import { CoDriverIcon, DriverIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { DetailsPaneContext } from '~/components/SceneDetailsPane';
import { TitledIconButton } from '~/components/TitledIconButton';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    DriverActivityType,
    DriverName,
    ResolvedVehicle,
} from '~/services/ApiClient';
import { DriverRole } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import { DriverAvatar } from './components/DriverAvatar';
import type { DriverSubpageHeaderClassKey } from './styles';

export interface DriverSubpageHeaderProps {
    activeDriverRole: DriverRole;
    coDriver?: DriverName;
    coDriverActivity?: DriverActivityType;
    driver?: DriverName;
    driverActivity?: DriverActivityType;
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat;
    onActiveDriverRoleChange: (role: DriverRole) => void;
    vehicle: ResolvedVehicle;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface DriverSubpageHeaderInnerProps
    extends DriverSubpageHeaderProps,
        WithStyles<DriverSubpageHeaderClassKey>,
        InjectedTranslationProps {}

export const DriverSubpageHeaderComponent: FC<DriverSubpageHeaderInnerProps> = (props) => {
    const {
        activeDriverRole,
        classes,
        coDriver,
        coDriverActivity,
        driver,
        driverActivity,
        driverDisplayFormat,
        onActiveDriverRoleChange,
        t,
        vehicle,
        vehicleDisplayFormat,
    } = props;

    const { close, goBack } = useContext(DetailsPaneContext);
    const handleChangeActiveDriver = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            const driverRole = e.currentTarget.attributes['data-id'].value.includes('co-driver-avatar')
                ? DriverRole.CODRIVER
                : DriverRole.DRIVER;

            if (driverRole !== activeDriverRole) {
                onActiveDriverRoleChange(driverRole);
            }
        },
        [onActiveDriverRoleChange, activeDriverRole]
    );

    const driverElement = (
        <DriverAvatar
            dataId="driver-avatar"
            driver={driver}
            driverActivity={driverActivity}
            icon={<DriverIcon />}
            onClick={handleChangeActiveDriver}
            order={activeDriverRole === DriverRole.DRIVER ? 0 : -1}
            selected={activeDriverRole === DriverRole.DRIVER}
        />
    );

    const coDriverElement = (coDriver || activeDriverRole === DriverRole.CODRIVER) && (
        <DriverAvatar
            dataId="co-driver-avatar"
            driver={coDriver}
            driverActivity={coDriverActivity}
            icon={<CoDriverIcon />}
            onClick={handleChangeActiveDriver}
            order={activeDriverRole === DriverRole.CODRIVER ? -1 : 1}
            selected={activeDriverRole === DriverRole.CODRIVER}
        />
    );

    const activeDriverData = activeDriverRole === DriverRole.DRIVER ? driver : coDriver;
    const activeDriverName = activeDriverData && (
        <DriverNameFormatter displayNameFormat={driverDisplayFormat} value={activeDriverData} />
    );

    const vehicleName = formatVehicleName(vehicle, vehicleDisplayFormat);
    const activeDriverVehicleInfo =
        activeDriverRole === DriverRole.DRIVER
            ? `${t('driver-of-vehicle', { vehicle: vehicleName })} `
            : `${t('co-driver-of-vehicle', { vehicle: vehicleName })} `;

    return (
        <Paper className={classes.root} data-id="driver-subpage-header" square>
            <div className={classes.actionButtons}>
                <TitledIconButton data-id="back-button" onClick={goBack}>
                    <ArrowBack />
                </TitledIconButton>

                <TitledIconButton data-id="close-button" onClick={close}>
                    <Close />
                </TitledIconButton>
            </div>

            <div className={classes.avatarIcons}>
                <div className={classes.placeHolderElement} />
                {driverElement}
                {coDriverElement || <div className={classes.placeHolderElement} />}
            </div>

            <Typography className={classes.driverName} color="textPrimary" data-id="driver-name" variant="body2">
                {activeDriverName || t('not-logged-in')}
            </Typography>

            <Typography
                className={classes.vehicleInfo}
                color="textSecondary"
                data-id="driver-role-and-vehicle"
                variant="body2"
            >
                {activeDriverVehicleInfo}
            </Typography>
        </Paper>
    );
};
