import { createAction } from 'redux-actions';

import type { RtdsSchedule } from '~/services/ApiClient';
import {
    ApiClient,
    QueryRtdsSchedulesRequest,
    SaveRtdsScheduleRequest,
    SaveRtdsSchedulesRequest,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const queryRtdsSchedulesAction = createAction(
    ActionTypePrefixes.RTDSSCHEDULES_QUERY,
    (vehicleIds: number[] | undefined, driverIds: number[] | undefined) =>
        retryableRequest(() =>
            ApiClient.queryRtdsSchedules(
                createApiModel(QueryRtdsSchedulesRequest, {
                    driverIds,
                    vehicleIds,
                })
            )
        ),
    (vehicleIds: number[] | undefined, driverIds: number[] | undefined) =>
        createApiModel(QueryRtdsSchedulesRequest, {
            driverIds,
            vehicleIds,
        })
);

export const createRtdsSchedulesAction = createAction(
    ActionTypePrefixes.RTDSSCHEDULES_CREATE,
    (items: RtdsSchedule[]): Promise<void> =>
        retryableRequest(() => ApiClient.createRtdsSchedules(createApiModel(SaveRtdsSchedulesRequest, { items })))
);
export const deleteRtdsScheduleAction = createAction(
    ActionTypePrefixes.RTDSSCHEDULES_DELETE,
    (scheduleId: number) => retryableRequest(() => ApiClient.deleteRtdsSchedule(scheduleId)),
    (scheduleId: number): number => scheduleId
);

export const updateRtdsScheduleAction = createAction(
    ActionTypePrefixes.RTDSSCHEDULES_UPDATE,
    (scheduleId: number, item: RtdsSchedule): Promise<void> =>
        retryableRequest(() =>
            ApiClient.updateRtdsSchedule(scheduleId, createApiModel(SaveRtdsScheduleRequest, { item }))
        )
);

export const getRtdsScheduleChangelogsAction = createAction(
    ActionTypePrefixes.RTDSSCHEDULESCHANGELOGS_GET,
    (scheduleId: number) => retryableRequest(() => ApiClient.getRtdsScheduleChangelogs(scheduleId)),
    (scheduleId: number) => scheduleId
);
