import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage/models';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ResolvedVehicleTypeChangelogChanges, VehicleTypeChangelog } from '~/services/ApiClient';

import { getResolvedVehicleTypeChangelogChanges } from './getResolvedVehicleTypeChangelogChanges';

export const resolveVehicleTypeChangelogsEntries = (
    VehicleTypeChangelogs: VehicleTypeChangelog[],
    settingsStoreState: SettingsStoreState
): ChangelogEntry<ResolvedVehicleTypeChangelogChanges>[] => {
    return VehicleTypeChangelogs.map((vehicleTypeChangelog) => {
        return {
            action: vehicleTypeChangelog.action,
            after: getResolvedVehicleTypeChangelogChanges(
                settingsStoreState.vehicleTypeCategories.data,
                settingsStoreState.axles.data,
                settingsStoreState.emissionClass.data,
                vehicleTypeChangelog.after
            ),
            before: getResolvedVehicleTypeChangelogChanges(
                settingsStoreState.vehicleTypeCategories.data,
                settingsStoreState.axles.data,
                settingsStoreState.emissionClass.data,
                vehicleTypeChangelog.before
            ),
            dateTime: vehicleTypeChangelog.dateTime,
            id: vehicleTypeChangelog.id,
            impersonator: vehicleTypeChangelog.impersonator,
            username: vehicleTypeChangelog.username,
        };
    });
};
