import { isEmpty } from '../utility';

import type { BaseItem } from './models';

const getChildrenIds = <T extends BaseItem<T>>(source: T[]): (number | string)[] => {
    return source.reduce((acc: (number | string)[], { children, id }) => {
        acc.push(id);

        if (children && !isEmpty(children)) {
            return [...acc, ...getChildrenIds(children)];
        }

        return acc;
    }, []) as (number | string)[];
};

export { getChildrenIds };
