import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    buttonContainer: { gap: theme.spacing() },
    root: {
        gridTemplateColumns: '1fr 100px 1fr',
        minHeight: theme.custom.dialogContent.minHeight,
        width: '100%',
    },
}));

export { useStyles };
