import * as storage from 'localforage';
import type { PersistConfig } from 'redux-persist';
import { persistReducer } from 'redux-persist';

import type { JwtStoreState } from './reducers';
import { protectedComponentReducer as reducer } from './reducers';

export { withImpersonatorToken } from './components/ImpersonatorToken';
export { protectedComponentHoc } from './components/ProtectedComponent';

export type { InjectedUserDataProps } from './components/UserData';
export { withUserData } from './components/UserData';
export * from './models';
export type { JwtStoreState } from './reducers';

const config: PersistConfig<JwtStoreState> = {
    key: 'protectedComponent',
    storage,
    whitelist: ['jwt', 'impersonatorJwt', 'isAuthenticated'],
};

export const protectedComponentReducer = persistReducer(config, reducer);
