import makeStyles from '@mui/styles/makeStyles';

import type { AssetType } from '~/services/ApiClient';

export type AssetHistoryMapClassKey = 'markerLabel';

export const useStyles = (assetType: AssetType) => {
    return makeStyles((theme) => {
        const color = theme.functionalItemsColors.assetType[assetType];

        return {
            markerLabel: {
                backgroundColor: color.contrast,
                borderColor: color.value,
                color: color.value,
            },
        };
    })();
};
