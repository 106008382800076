import classNames from 'classnames';
import React from 'react';

import { ColorVariants } from '~/services/Styling';

import { useStyles } from './styles';

export interface TextBadgeProps {
    dataId?: string;
    text: JSX.Element | string;
    variant: ColorVariants;
}

export interface TextBadgeInnerProps extends TextBadgeProps {}

const TextBadge: React.FC<TextBadgeInnerProps> = ({ dataId, text, variant }) => {
    const classes = useStyles();

    const className = classNames(classes.root, {
        [classes.disabled]: variant === ColorVariants.Disabled,
        [classes.error]: variant === ColorVariants.Error,
        [classes.success]: variant === ColorVariants.Success,
        [classes.warning]: variant === ColorVariants.Warning,
    });

    return (
        <span className={className} data-id={dataId}>
            {text}
        </span>
    );
};
TextBadge.displayName = 'TextBadge';

export { TextBadge };
