import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type * as i18next from 'i18next';
import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type { ResolvedCompartmentStatus } from '~/services/ApiClient';

import type { TemperatureSensorGroupClassKey } from './styles';

export interface TemperatureSensorGroupFormatterProps extends i18next.WithT {
    sensorNumber: number;
    value?: ResolvedCompartmentStatus;
}

export interface TemperatureSensorGroupFormatterInnerProps
    extends TemperatureSensorGroupFormatterProps,
        WithStyles<TemperatureSensorGroupClassKey> {}

export const TemperatureSensorGroupFormatterComponent: React.FunctionComponent<
    TemperatureSensorGroupFormatterInnerProps
> = (props: TemperatureSensorGroupFormatterInnerProps) => {
    const { classes, sensorNumber, t, value } = props;

    let className = classes.root;

    let displayValue = t('temperature-group-no-data');

    let dataId = 'temperature-group-no-data';

    const currentTemperature = value?.temperatureSensors[sensorNumber]?.value;

    if (!isUndefined(currentTemperature)) {
        const maxTemperature = value?.maxTemperature;
        const minTemperature = value?.minTemperature;

        if (!isUndefined(maxTemperature) && currentTemperature > maxTemperature) {
            displayValue = t('temperature-group-high');
            dataId = 'temperature-group-high';
            className = classNames(classes.root, [classes.high]);
        } else if (!isUndefined(minTemperature) && currentTemperature < minTemperature) {
            displayValue = t('temperature-group-low');
            dataId = 'temperature-group-low';
            className = classNames(classes.root, [classes.low]);
        } else {
            displayValue = t('temperature-group-normal');
            dataId = 'temperature-group-normal';
        }
    }

    return (
        <span className={className} data-id={dataId}>
            {displayValue}
        </span>
    );
};
