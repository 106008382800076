import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type { ActionTypes as AdminVehicleActionTypes } from '~/data/vehicleadministration';
import { ActionTypeKeys as AdminVehicleActionTypeKeys } from '~/data/vehicleadministration';
import { keyBy } from '~/libs/utility';
import type { RetrievableData, RetrievableHashedData } from '~/reducers';
import type {
    ResolvedAdminVehicleRead,
    ResolvedVehicleSettingsEdit,
    ResolvedVehicleSettingsRead,
} from '~/services/ApiClient';
import { ServerResultStatus } from '~/services/ApiClient';

import type { ActionTypes as AdministrationActionTypes } from './data';
import { ActionTypeKeys as AdministrationActionTypeKeys } from './data';
import { resolveAdminVehicles } from './reducers.resolveAdminVehicles';
import { resolveVehicleSettings, resolveVehicleSettingsEdit } from './reducers.resolveVehicleSettings';

export interface AdminVehicleStoreState {
    adminVehicleDefaultSettings: RetrievableData<ResolvedVehicleSettingsRead | undefined>;
    adminVehicles: RetrievableHashedData<ResolvedAdminVehicleRead>;
    adminVehicleSettings: RetrievableData<ResolvedVehicleSettingsEdit | undefined>;
    pendingVehicleSettingsId?: number;
    previousVehicleSettingsId?: number;
    updateVehicleServerResultStatus?: ServerResultStatus;
}

export const defaultStoreState: AdminVehicleStoreState = {
    adminVehicleDefaultSettings: {
        data: undefined,
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    adminVehicles: {
        data: {
            array: [],
            hash: {},
        },
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    adminVehicleSettings: {
        data: undefined,
        fulfilled: false,
        pending: false,
        rejected: false,
    },
    pendingVehicleSettingsId: undefined,
    updateVehicleServerResultStatus: undefined,
};

type ActionTypes = AdministrationActionTypes | AdminVehicleActionTypes;

export const adminVehicleReducer = (
    state: AdminVehicleStoreState = defaultStoreState,
    action: ActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): AdminVehicleStoreState => {
    switch (action.type) {
        case AdministrationActionTypeKeys.ADMINVEHICLE_CLEAR_DATA:
            return {
                ...defaultStoreState,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEGET_FULFILLED: {
            const resolvedVehicles = resolveAdminVehicles(
                action.payload.items,
                staticDataStoreState,
                settingsStoreState
            );
            return {
                ...state,
                adminVehicles: {
                    data: {
                        array: resolvedVehicles,
                        hash: keyBy(resolvedVehicles, 'id'),
                    },
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
            };
        }

        case AdminVehicleActionTypeKeys.ADMINVEHICLEGET_PENDING:
            return {
                ...state,
                adminVehicles: {
                    ...state.adminVehicles,
                    pending: true,
                },
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEGET_REJECTED:
            return {
                ...state,
                adminVehicles: {
                    data: {
                        array: [],
                        hash: {},
                    },
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEUPDATE_RESET:
            return {
                ...state,
                updateVehicleServerResultStatus: undefined,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEUPDATE_PENDING:
            return {
                ...state,
                updateVehicleServerResultStatus: ServerResultStatus.PENDING,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEUPDATE_FULFILLED:
            return {
                ...state,
                updateVehicleServerResultStatus: action.payload,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEUPDATE_REJECTED:
            return {
                ...state,
                updateVehicleServerResultStatus: ServerResultStatus.SERVERERROR,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLECREATE_PENDING:
            return {
                ...state,
                updateVehicleServerResultStatus: ServerResultStatus.PENDING,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLECREATE_FULFILLED:
            return {
                ...state,
                updateVehicleServerResultStatus: action.payload,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLECREATE_REJECTED:
            return {
                ...state,
                updateVehicleServerResultStatus: ServerResultStatus.SERVERERROR,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_PENDING:
            return {
                ...state,
                adminVehicleDefaultSettings: {
                    data: state.adminVehicleDefaultSettings.data,
                    fulfilled: false,
                    pending: true,
                    rejected: false,
                },
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_REJECTED:
            return {
                ...state,
                adminVehicleDefaultSettings: {
                    data: undefined,
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_FULFILLED: {
            const resolvedDefaultVehicleSettings = resolveVehicleSettings(action.payload.item, settingsStoreState);
            return {
                ...state,
                adminVehicleDefaultSettings: {
                    data: resolvedDefaultVehicleSettings,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
            };
        }

        case AdminVehicleActionTypeKeys.ADMINVEHICLESETTINGS_GET_PENDING:
            return {
                ...state,
                adminVehicleSettings: {
                    data: state.adminVehicleSettings?.data,
                    fulfilled: false,
                    pending: true,
                    rejected: false,
                },
                pendingVehicleSettingsId: action.meta,
                previousVehicleSettingsId: state.pendingVehicleSettingsId ?? action.meta,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLESETTINGS_GET_REJECTED:
            if (state.pendingVehicleSettingsId !== action.meta) {
                return state;
            }

            return {
                ...state,
                adminVehicleSettings: {
                    data: undefined,
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                },
                pendingVehicleSettingsId: undefined,
            };

        case AdminVehicleActionTypeKeys.ADMINVEHICLESETTINGS_GET_FULFILLED: {
            if (state.pendingVehicleSettingsId !== action.meta) {
                return state;
            }

            const resolvedVehicleSettings = resolveVehicleSettingsEdit(action.payload.item, settingsStoreState);

            return {
                ...state,
                adminVehicleSettings: {
                    data: resolvedVehicleSettings,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
                pendingVehicleSettingsId: undefined,
                previousVehicleSettingsId: action.meta,
            };
        }

        default:
            return state;
    }
};
