import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ContainerTemperatureGroupInnerProps } from './component';

export type ContainerTemperatureGroupClassKey = 'temperatureGroupContainer' | 'temperatureSensor';

export const ContainerTemperatureGroupStyleRules: StyleRulesCallback<
    Theme,
    ContainerTemperatureGroupInnerProps,
    ContainerTemperatureGroupClassKey
> = (theme: Theme) =>
    createStyles({
        temperatureGroupContainer: {
            alignContent: 'flex-start',
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(0, 2),
        },
        temperatureSensor: {
            flex: (props: ContainerTemperatureGroupInnerProps) => {
                let baseWidthPercentage = 100;

                const numberOfSensors = props.temperatures.length;

                if (numberOfSensors) {
                    if (numberOfSensors === 5 || numberOfSensors === 6) {
                        baseWidthPercentage = 33;
                    } else {
                        baseWidthPercentage = numberOfSensors < 4 ? Math.floor(100 / (numberOfSensors % 4)) : 25;
                    }
                }

                return `1 0 ${baseWidthPercentage}%`;
            },
            padding: theme.spacing(0.3),
        },
    });
