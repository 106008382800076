import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { Subtract } from 'utility-types';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { featureFlagsSelector } from '~/selectors';

import { MAP_USERPREFERENCES_KEY } from './preferences';
import type { MapDispatchProps, MapReduxProps, MapStateProps } from './redux';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';

export { useMapLayers } from './hooks';
export { getMapLayersProps } from './layers';
export { defaultMapUserPreferences, MAP_USERPREFERENCES_KEY, requiredMapUserPreferences } from './preferences';

export interface InjectedMapSharedProps extends MapReduxProps {}

export type { MapUserPreferences } from './preferences';

export const withMapSharedProps = <WrappedProps extends InjectedMapSharedProps>(): ComponentEnhancer<
    WrappedProps,
    Subtract<WrappedProps, InjectedMapSharedProps>
> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedMapSharedProps>>(
        connect<MapStateProps, MapDispatchProps>(
            createSelector(
                keyDataStateSelectorFactory(MAP_USERPREFERENCES_KEY),
                settingDataSelector(SettingsKey.SECURABLES),
                featureFlagsSelector,
                mapStateToProps
            ),
            mapDispatchToProps,
            mergeProps
        )
    );
