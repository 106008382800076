import { createSelectorCreator, defaultMemoize } from 'reselect';

import { formatToSelect, getValueByPath } from '~/common';
import { SettingsKey } from '~/components/EnsureSettings';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import type { UserPreferencesKeyState } from '~/components/UserPreferences';
import { isEmpty, isEqual, isNil, keyBy } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { settingsStoreStateSelector, staticDataStoreStateSelector, userPreferencesStateSelector } from '~/selectors';
import { AssetType } from '~/services/ApiClient';

import { TRAILERADMIN_ASSETSELECTOR_USERPREFERENCES_KEY } from './constants';
import type {
    DropdownSelector,
    FilterGridSceneByAssetProps,
    GetTrailerById,
    ResolvedTrailersAdministration,
    ResolvedTrailersAdministrationCompartmentStatus,
    ResolverDevicesProps,
    TrailersAdministrationState,
} from './models';

// this should be extracted to a common folder, will be removed when devices administration get merged with master
const deepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const trailersAdministrationSceneState = (state: StoreState): TrailersAdministrationState =>
    state.trailersAdministrationScene;

const resolverTrailers = ({ settings, staticData, trailersAdministration }: ResolverDevicesProps) =>
    (trailersAdministration?.list?.items || []).map(
        (trailer): ResolvedTrailersAdministration => ({
            ...trailer,
            batteryType: getValueByPath(settings, `trailerBatteryType.data[${trailer?.batteryType}]`),
            depot: getValueByPath(staticData, `depots.data.hash[${trailer?.depotId}]`),
            ebsManufacturer: getValueByPath(settings, `ebsManufacturer.data[${trailer?.ebsManufacturer}]`),
            reeferBatteryType: getValueByPath(settings, `reeferBatteryType.data[${trailer?.reeferBatteryType}]`),
            reeferDataInterface: getValueByPath(settings, `reeferDataInterface.data[${trailer?.reeferDataInterface}]`),
            reeferManufacturer: getValueByPath(settings, `reeferManufacturers.data[${trailer?.reeferManufacturer}]`),
            settings: {
                ...trailer?.settings,
                compartments: (trailer?.settings?.compartments || []).map(
                    (compartment): ResolvedTrailersAdministrationCompartmentStatus => ({
                        ...compartment,
                        bandwidthLinked: getValueByPath(
                            settings,
                            `trailerBandwidthLinked.data[${compartment?.bandwidthLinked}]`
                        ),
                    })
                ),
                monitoring: getValueByPath(settings, `trailerMonitoring.data[${trailer?.settings?.monitoring}]`),
                offlineAlarm: getValueByPath(settings, `trailerOfflineAlarm.data[${trailer?.settings?.offlineAlarm}]`),
            },
            tpmsManufacturer: getValueByPath(settings, `tpmsManufacturer.data[${trailer?.tpmsManufacturer}]`),
            trailerManufacturer: getValueByPath(settings, `trailerManufacturers.data[${trailer?.manufacturer}]`),
        })
    );

const filterGridSceneByAssetSelector = <T>({ items, selectedAssetIds, type }: FilterGridSceneByAssetProps<T>): T[] => {
    if (isEmpty(items)) {
        return [];
    }

    if (isNil(selectedAssetIds)) {
        return items;
    }

    const itemsByKey = keyBy(items, 'id');

    const filteredByType = selectedAssetIds.reduce(
        (acc, selectedAsset) => (selectedAsset.type === type ? [...acc, selectedAsset.id] : acc),
        []
    );

    return filteredByType.reduce((acc, id) => (itemsByKey[id] ? [...acc, itemsByKey[id]] : acc), []);
};

const getTrailersAdministrationState = deepEqualSelector(
    trailersAdministrationSceneState,
    staticDataStoreStateSelector,
    settingsStoreStateSelector,
    userPreferencesStateSelector,
    (trailersAdministration, staticData, settings, preferences) => {
        const { actualData: assetPrefActual } = preferences[
            TRAILERADMIN_ASSETSELECTOR_USERPREFERENCES_KEY
        ] as UserPreferencesKeyState<SceneAssetSelectorUserPreferences>;

        const items = filterGridSceneByAssetSelector({
            items: resolverTrailers({ settings, staticData, trailersAdministration }),
            selectedAssetIds: assetPrefActual?.selectedAssetIds,
            type: AssetType.Trailer,
        });
        return {
            items,
            trailerAdministrationSecurables: settings[SettingsKey.SECURABLES].data.assetAdministration.trailers,
        };
    }
);

const getTrailerById =
    (selected?: number) =>
    (state: StoreState): GetTrailerById => {
        const { crud, list } = trailersAdministrationSceneState(state);
        return {
            items: (list.items || [])
                .filter(({ id }) => id !== selected)
                .reduce((acc, { trailerNumber }) => (isNil(trailerNumber) ? acc : [...acc, trailerNumber]), []),
            selected: (list?.items || []).find(({ id }) => id === selected),
            ...crud,
        };
    };

const getDefaultSettings = (state: StoreState) => {
    const { defaultSettings } = trailersAdministrationSceneState(state);
    return { defaultSettings };
};

const dropDownValues = (state: StoreState): DropdownSelector => {
    const options = { displayName: 'key' };

    const { depots } = staticDataStoreStateSelector(state);
    const {
        ebsManufacturer,
        reeferBatteryType,
        reeferDataInterface,
        reeferManufacturers,
        tpmsManufacturer,
        trailerBandwidthLinked,
        trailerBatteryType,
        trailerManufacturers,
        trailerMonitoring,
        trailerOfflineAlarm,
    } = settingsStoreStateSelector(state);

    return {
        bandwidthLinked: formatToSelect({ options, source: trailerBandwidthLinked.data }),
        batteryType: formatToSelect({ options, source: trailerBatteryType.data }),
        depots: formatToSelect({ options: { displayName: 'name', key: 'id' }, source: depots.data.array }),
        ebsManufacturer: formatToSelect({ options, source: ebsManufacturer.data }),
        manufacturers: formatToSelect({ options, source: trailerManufacturers.data }),
        monitoring: formatToSelect({ options, source: trailerMonitoring.data }),
        offlineAlarm: formatToSelect({ options, source: trailerOfflineAlarm.data }),
        reeferBatteryType: formatToSelect({ options, source: reeferBatteryType.data }),
        reeferDataInterface: formatToSelect({ options, source: reeferDataInterface.data }),
        reeferManufacturers: formatToSelect({ options, source: reeferManufacturers.data }),
        tpmsManufacturer: formatToSelect({ options, source: tpmsManufacturer.data }),
    };
};

export {
    dropDownValues,
    getDefaultSettings,
    getTrailerById,
    getTrailersAdministrationState,
    trailersAdministrationSceneState,
};
