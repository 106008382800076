import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MapActionsClassKey = 'selected' | 'toggleExtraLayersControlsLeftMargin';

export const MapActionsStyleRules: StyleRulesCallback<Theme, {}, MapActionsClassKey> = (theme: Theme) => {
    return createStyles({
        selected: {
            '&:hover': {
                backgroundColor: theme.palette.action.selectedBackground,
            },
            backgroundColor: theme.palette.action.selectedBackground,
        },
        toggleExtraLayersControlsLeftMargin: {
            display: 'flex',
            gap: theme.spacing(0.5),
            marginLeft: theme.spacing(2),
        },
    });
};
