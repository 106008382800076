import type { Driver, DriverName, PojoModel } from '../ApiClient';
import { DisplayUserPreferencesDriverDisplayFormat } from '../ApiClient';

const composeDriverName = (firstName?: string, lastName?: string, separator: string = ' ') => {
    if (firstName && lastName) {
        return `${firstName}${separator}${lastName}`;
    }
    return `${firstName || lastName || ''}`;
};

export const formatDriverName = (
    driver: DriverName | PojoModel<Driver>,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat
): string => {
    const { alias, firstName, id, lastName } = driver;
    const separator = ', ';
    switch (driverDisplayNameFormat) {
        case DisplayUserPreferencesDriverDisplayFormat.Alias:
            return alias || composeDriverName(firstName, lastName) || `[${id}]`;
        case DisplayUserPreferencesDriverDisplayFormat.AliasId:
            return `${alias || composeDriverName(firstName, lastName)} [${id}]`;
        case DisplayUserPreferencesDriverDisplayFormat.FirstnameLastname:
            return composeDriverName(firstName, lastName) || `[${id}]`;
        case DisplayUserPreferencesDriverDisplayFormat.FirstnameLastnameId:
            return `${composeDriverName(firstName, lastName)} [${id}]`;
        case DisplayUserPreferencesDriverDisplayFormat.Id:
            return `${id}`;
        case DisplayUserPreferencesDriverDisplayFormat.LastnameFirstname:
            return composeDriverName(lastName, firstName, separator) || `[${id}]`;
        case DisplayUserPreferencesDriverDisplayFormat.LastnameFirstnameId:
            return `${composeDriverName(lastName, firstName, separator)} [${id}]`;
        default:
            return `[${id}]`;
    }
};
