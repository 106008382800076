import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MenuBarClassKey = 'icon' | 'menuContainer';

export const MenuBarStyleRules: StyleRulesCallback<Theme, {}, MenuBarClassKey> = (theme: Theme) => {
    return createStyles({
        icon: {
            color: theme.menuBar.iconColor,
            marginBottom: 10,
        },
        menuContainer: {
            alignContent: 'center',
            backgroundColor: theme.menuBar.background,
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            paddingTop: theme.spacing(),
            textAlign: 'center',
            width: theme.custom.menu.width,
        },
    });
};
