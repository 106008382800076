import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, ListItem, ListItemText, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from '~/components/LanguageSelector';
import { ChangelogAction } from '~/services/ApiClient';
import { formatChangelogChangeAuthor, formatDateTimeHourMinute } from '~/services/Formatters';

import { ChangelogEntityType } from '../../../../models';
import { ChangelogChangesTable } from '../../../ChangelogChangesTable';

import type { ChangelogEntityProps } from './models';
import { useStyles } from './styles';
import { computeNumberOfChanges } from './utils';

const ChangelogEntity = <T,>(props: ChangelogEntityProps<T>): JSX.Element => {
    const { attributeDefinitions, changelog, entityType, groupedPosition } = props;

    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const toggle = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const computedChanges = useMemo(
        () => computeNumberOfChanges({ after: changelog.after, attributeDefinitions, before: changelog.before }),
        [attributeDefinitions, changelog.after, changelog.before]
    );

    const getEntityType = () => {
        switch (entityType) {
            case ChangelogEntityType.ASSET_GROUP_ADMIN:
                return 'asset-group-admin';
            case ChangelogEntityType.DEVICE_ADMIN:
                return 'device-admin';
            case ChangelogEntityType.DRIVER_ADMIN:
                return 'driver-admin';
            case ChangelogEntityType.RTDS_SCHEDULE:
                return 'rtds-schedule';
            case ChangelogEntityType.TRAILER_ADMIN:
                return 'trailer-admin';
            case ChangelogEntityType.USER_ROLE:
                return 'user-role';
            case ChangelogEntityType.VEHICLE_ADMIN:
                return 'vehicle-admin';
            case ChangelogEntityType.VEHICLE_TYPE_ADMIN:
                return 'vehicle-type-admin';
            default:
                throw Error(`Unknown entity type: ${entityType}`);
        }
    };

    const getAction = () => {
        switch (changelog.action) {
            case ChangelogAction.Insert:
                return 'insert';
            case ChangelogAction.Update:
                return 'update';
            case ChangelogAction.Delete:
                return 'delete';
            default:
                throw Error(`Unknown action: ${entityType}`);
        }
    };

    const content = useMemo(() => {
        const author = formatChangelogChangeAuthor({
            impersonator: changelog.impersonator,
            t,
            username: changelog.username,
        });
        switch (changelog.action) {
            case ChangelogAction.Update:
                return (
                    <>
                        <Typography className={classes.entryChanges} data-id="changelog-action-update-changes-text">
                            {t('changelog-author-changes', {
                                author,
                                numChanges: computedChanges,
                            })}
                        </Typography>
                        <ChangelogChangesTable attributeDefinitions={attributeDefinitions} changelog={changelog} />
                    </>
                );
            case ChangelogAction.Insert:
            case ChangelogAction.Delete:
                return (
                    <Typography
                        className={classes.entryChanges}
                        data-id={`changelog-action-${changelog.action}-changes-text`}
                    >
                        {t('changelog-author', { author })}
                    </Typography>
                );
            default:
                throw Error(`Unknown changelog action: ${changelog.action}`);
        }
    }, [attributeDefinitions, changelog, classes.entryChanges, computedChanges, t]);

    const formattedTime = formatDateTimeHourMinute(changelog.dateTime);

    return (
        <div className={classes.section} data-id={`changelog-entity:${changelog.action}:${groupedPosition}`}>
            <ListItem onClick={toggle}>
                <ListItemText>{t(`changelog-${getAction()}-${getEntityType()}`)}</ListItemText>
                <span data-id="timestamp">{formattedTime}</span>
                {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expanded} timeout="auto">
                {content}
            </Collapse>
        </div>
    );
};

ChangelogEntity.displayName = 'ChangelogEntity';
export { ChangelogEntity };
