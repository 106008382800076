import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionGroupsContainerClassKey = 'root';

export const PositionGroupsContainerStyleRules: StyleRulesCallback<Theme, {}, PositionGroupsContainerClassKey> = () =>
    createStyles({
        root: {
            '& >*:not(:last-child)': {
                borderBottom: 'none',
            },
            paddingTop: 0,
        },
    });
