import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { AssetAvatar } from '~/components/AssetAvatar';
import { DriverActivityIconFormatter, VehicleNameFormatter } from '~/components/Formatters';
import { TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { DisplayUserPreferencesVehicleDisplayFormat, ResolvedVehicleConnection } from '~/services/ApiClient';
import { formatVehicleCategory } from '~/services/Formatters';

import type { VehicleClassKey } from './styles';

export interface VehicleProps {
    vehicleConnection?: ResolvedVehicleConnection;
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface VehicleInnerProps extends VehicleProps, WithStyles<VehicleClassKey>, InjectedTranslationProps {}

export const VehicleComponent: React.FC<VehicleInnerProps> = ({
    classes,
    t,
    vehicleConnection,
    vehicleDisplayNameFormat,
}) => {
    const formattedVehicleName = vehicleConnection ? (
        <VehicleNameFormatter
            value={vehicleConnection.vehicle ?? vehicleConnection.vehicleReference}
            vehicleDisplayNameFormat={vehicleDisplayNameFormat}
        />
    ) : (
        t('not-connected')
    );

    const vehicleName = (
        <Typography className={classNames({ [classes.disabledTitle]: !vehicleConnection })} data-id="vehicle-name">
            {formattedVehicleName}
        </Typography>
    );

    const vehicleCategoryText = vehicleConnection?.vehicle?.category
        ? formatVehicleCategory(t, vehicleConnection.vehicle.category)
        : t('vehicle');
    const vehicleCategory = (
        <Typography
            className={classes.vehicleCategory}
            color="textSecondary"
            data-id="vehicle-category"
            variant="body2"
        >
            {vehicleCategoryText}
        </Typography>
    );

    const vehicleType = vehicleConnection?.vehicle?.vehicleType ? (
        <>
            <Typography className={classes.delimiter} color="textSecondary" variant="body2">
                •
            </Typography>
            <Typography color="textSecondary" data-id="vehicle-type" variant="body2">
                {vehicleConnection.vehicle.vehicleType.name}
            </Typography>
        </>
    ) : null;

    const vehicleSecondaryDisplayText: JSX.Element = (
        <div className={classes.line}>
            {vehicleCategory}
            {vehicleType}
        </div>
    );

    const driverActivity = vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.activity;

    const driverActivityBadge = driverActivity && (
        <Avatar
            className={classNames(classes.activityAvatar, classes[`activity${driverActivity.key}`])}
            data-id="driver-activity-badge"
        >
            <DriverActivityIconFormatter iconProps={{ fontSize: 'inherit' }} value={driverActivity.key} />
        </Avatar>
    );

    const vehicleAvatar = (
        <AssetAvatar
            badge={driverActivityBadge}
            classes={{ avatar: classes.avatar, badge: classes.badge }}
            icon={<TruckIcon />}
        />
    );

    return (
        <ListItem className={classNames({ [classes.disabled]: !vehicleConnection?.vehicle })}>
            <ListItemAvatar>{vehicleAvatar}</ListItemAvatar>
            <ListItemText primary={vehicleName} secondary={vehicleSecondaryDisplayText} />
        </ListItem>
    );
};
