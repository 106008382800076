import makeStyles from '@mui/styles/makeStyles';

import { themeSpacingToNumber } from '~/common/utils';

const useStyles = makeStyles((theme) => ({
    '@media (max-width: 960px)': {
        description: {
            fontSize: 16,
            lineHeight: '16px',
        },
        title: {
            fontSize: 24,
            lineHeight: '24px',
        },
    },
    '@media (min-width: 961px)': {
        description: {
            lineHeight: '19px',
        },
        title: {
            lineHeight: '72px',
        },
    },
    description: {
        marginBottom: `${(themeSpacingToNumber(theme.spacing(9)) * 100) / document.documentElement.clientHeight}vh`,
        textAlign: 'center',
    },
    illustration: {
        height: '45vh',
        marginBottom: `${(themeSpacingToNumber(theme.spacing(11.5)) * 100) / document.documentElement.clientHeight}vh`,
        objectFit: 'contain',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    title: {
        marginBottom: `${(themeSpacingToNumber(theme.spacing(4)) * 100) / document.documentElement.clientHeight}vh`,
        textAlign: 'center',
    },
}));

export { useStyles };
