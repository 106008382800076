import type { LatLngTuple } from 'leaflet';

export interface Config {
    analyticsMeasurementId: string;
    applicationInsightsInstrumentationKey: string;
    defaultMapBounds: LatLngTuple[];
    featureFlagsEnabled: boolean;
    mapGatewayClientUrl: string;
    maxConversationMessageLength: number;
    maxGridColumnsToGroupBy: number;
    maxRetriesPerFailedRequest: number;
    mopinionFormId: string;
    publicKey: string;
    sisenseUrl: string;
    tachoLinkDownloadUrl: string;
    tachOnlineUrl: string;
    timediscWebUrl: string;
    videoLincUrl: string;
}

export interface SerializedConfig extends Omit<Config, 'featureFlagsEnabled'> {
    featureFlagsMode: 'disabled' | 'enabled';
}

let config: Config;

export function getConfig(): Config {
    return config;
}

export function setConfig(data: Config): void {
    config = data;
}

export async function loadConfig(): Promise<void> {
    const configResponse = await fetch('/config/config.json');
    const serializedConfig: SerializedConfig = await configResponse.json();

    const { featureFlagsMode, ...baseConfig } = serializedConfig;
    const parsedConfig = {
        ...baseConfig,
        featureFlagsEnabled: featureFlagsMode === 'enabled',
    };

    setConfig(parsedConfig);
}
