import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { ServerResultStatus, TrailersAdministrationRead, createApiModel } from '~/services/ApiClient';

import { ACTION_TYPE_KEYS } from './actionTypes';
import type { ActionTypes, TrailersAdministrationState } from './models';
import { resolveTrailerChangelog } from './reducers.resolveTrailerChangelog';

const initialState: TrailersAdministrationState = {
    changelogs: { error: false, items: [], pending: false },
    changelogsTrailerId: undefined,
    crud: { settings: undefined, status: undefined },
    defaultSettings: null,
    list: { error: false, items: [], pending: false },
};

const trailersAdministrationReducer = (
    state = initialState,
    action: ActionTypes,
    settingsStore: SettingsStoreState,
    staticStore: StaticDataStoreState
): TrailersAdministrationState => {
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, list: { ...state.list, error: false, pending: true } };
        case ACTION_TYPE_KEYS.LIST_FULFILLED:
            return {
                ...state,
                list: {
                    error: false,
                    items: (action.payload?.items || []).map((trailer) =>
                        createApiModel(TrailersAdministrationRead, trailer)
                    ),
                    pending: false,
                },
            };
        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, list: { ...state.list, error: true, pending: false } };

        case ACTION_TYPE_KEYS.UPDATE_PENDING:
            return { ...state, crud: { ...state.crud, status: ServerResultStatus.PENDING } };
        case ACTION_TYPE_KEYS.UPDATE_FULFILLED:
            return { ...state, crud: { ...state.crud, status: action.payload } };
        case ACTION_TYPE_KEYS.UPDATE_REJECTED:
            return { ...state, crud: { ...state.crud, status: action.payload } };

        case ACTION_TYPE_KEYS.CREATE_PENDING:
            return { ...state, crud: { ...state.crud, status: ServerResultStatus.PENDING } };
        case ACTION_TYPE_KEYS.CREATE_FULFILLED:
            return { ...state, crud: { ...state.crud, status: action.payload } };
        case ACTION_TYPE_KEYS.CREATE_REJECTED:
            return { ...state, crud: { ...state.crud, status: action.payload } };

        case ACTION_TYPE_KEYS.CHANGELOG_PENDING:
            return {
                ...state,
                changelogs: { ...state.changelogs, pending: true },
                changelogsTrailerId: action.meta,
            };
        case ACTION_TYPE_KEYS.CHANGELOG_FULFILLED:
            if (action.meta !== state.changelogsTrailerId) {
                return state;
            }

            return {
                ...state,
                changelogs: {
                    error: false,
                    items: action.payload.items.map((t) =>
                        resolveTrailerChangelog(
                            t,
                            settingsStore.trailerManufacturers.data,
                            settingsStore.trailerBatteryType.data,
                            settingsStore.ebsManufacturer.data,
                            settingsStore.reeferBatteryType.data,
                            settingsStore.reeferDataInterface.data,
                            settingsStore.reeferManufacturers.data,
                            settingsStore.tpmsManufacturer.data,
                            settingsStore.trailerOfflineAlarm.data,
                            settingsStore.trailerMonitoring.data,
                            settingsStore.trailerBandwidthLinked.data,
                            staticStore.depots.data.hash
                        )
                    ),
                    pending: false,
                },
            };
        case ACTION_TYPE_KEYS.CHANGELOG_REJECTED:
            if (action.meta !== state.changelogsTrailerId) {
                return state;
            }

            return {
                ...state,
                changelogs: {
                    ...state.changelogs,
                    error: true,
                    pending: false,
                },
            };

        case ACTION_TYPE_KEYS.SETTINGS_FULFILLED:
            return { ...state, crud: { ...state.crud, settings: action.payload } };

        case ACTION_TYPE_KEYS.DEFAULT_SETTINGS_FULFILLED:
            return { ...state, defaultSettings: action.payload };

        case ACTION_TYPE_KEYS.RESET:
            return { ...state, crud: { ...state.crud, status: undefined } };

        default:
            return state;
    }
};

export { trailersAdministrationReducer };
