import * as React from 'react';

import type { Position } from '~/common';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatCoordinates } from '~/services/Formatters';

export interface CoordinatesFormatterProps {
    t: SingleTFunction;
    undefinedValueMessage?: string;
    value?: Position;
}

export interface CoordinatesFormatterInnerProps extends CoordinatesFormatterProps {}

const CoordinatesFormatter: React.FunctionComponent<CoordinatesFormatterInnerProps> = ({
    t,
    undefinedValueMessage,
    value,
    // eslint-disable-next-line react/jsx-no-useless-fragment
}) => <>{isUndefined(value) ? undefinedValueMessage : formatCoordinates(t, value)}</>;
CoordinatesFormatter.displayName = 'CoordinatesFormatter';

export { CoordinatesFormatter };
