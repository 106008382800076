import { DriverActivitiesUpdate, createApiModel } from '~/services/ApiClient';

import { mergeDeletedDriverActivities } from './reducers.mergeDeletedDriverActivities';
import { mergeUpdatedDriverActivities } from './reducers.mergeUpdatedDriverActivities';

export const mergeDriverActivitiesUpdates = (
    pendingDriverActivitiesUpdates: DriverActivitiesUpdate,
    nextDriverActivityUpdate: DriverActivitiesUpdate
): DriverActivitiesUpdate =>
    createApiModel(DriverActivitiesUpdate, {
        deletedActivities: mergeDeletedDriverActivities(pendingDriverActivitiesUpdates, nextDriverActivityUpdate),
        updatedActivities: mergeUpdatedDriverActivities(pendingDriverActivitiesUpdates, nextDriverActivityUpdate),
    });
