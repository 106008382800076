import { Tooltip } from '@mui/material';
import * as React from 'react';

import { CheckIcon, CloseIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

export interface RtdsSchedulesBooleanStatesFormatterProps extends InjectedTranslationProps {
    value?: boolean;
}

export interface RtdsSchedulesBooleanStatesFormatterPropsInnerProps extends RtdsSchedulesBooleanStatesFormatterProps {}

export const RtdsSchedulesBooleanStatesFormatterComponent: React.SFC<
    RtdsSchedulesBooleanStatesFormatterPropsInnerProps
> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    if (value) {
        return (
            <Tooltip title={t('schedules-boolean-state-enabled')}>
                <span data-id="rtds-schedules-enabled">
                    <CheckIcon />
                </span>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t('schedules-boolean-state-disabled')}>
                <span data-id="rtds-schedules-disabled">
                    <CloseIcon />
                </span>
            </Tooltip>
        );
    }
};
