export enum ColumnName {
    AXLES = 'axles',
    DESCRIPTION = 'description',
    EMISSIONCLASS = 'emission-class',
    HAZARDOUSGOODS = 'hazardous-goods',
    HEIGHT = 'height',
    LENGTH = 'length',
    NAME = 'name',
    SPEEDLIMITCITY = 'speed-limit-city',
    SPEEDLIMITMOTORWAY = 'speed-limit-motorway',
    SPEEDLIMITROAD = 'speed-limit-road',
    TYPE = 'type',
    WEIGHT = 'weigth',
    WIDTH = 'width',
}
