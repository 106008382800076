import { useTheme } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

import type { DisplayUserPreferences, InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ContainerIcon, DriverIcon, TrailerIcon, TruckIcon } from '~/components/Icons';
import { isEqual } from '~/libs/utility';
import type { ResolvedContainer, ResolvedDriver, ResolvedTrailer, ResolvedVehicle } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { formatContainerName, formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

import { HistoryMap } from '../History';

import { useStyles } from './styles';

export interface AssetHistoryMapProps<TAsset> {
    asset: TAsset;
}

export interface AssetHistoryMapInnerProps<TAsset>
    extends AssetHistoryMapProps<TAsset>,
        InjectedDisplayPreferencesProps {}

export interface AssetDataType {
    [AssetType.Container]: ResolvedContainer;
    [AssetType.Driver]: ResolvedDriver;
    [AssetType.Trailer]: ResolvedTrailer;
    [AssetType.Vehicle]: ResolvedVehicle;
}

export interface AssetTypeDetail<T extends keyof AssetDataType> {
    AssetIcon: React.ComponentType<SvgIconProps>;
    formatAssetName: (asset: AssetDataType[T], displayPreferences: DisplayUserPreferences) => string;
}

export type AssetTypeDetails = {
    [K in keyof AssetDataType]: AssetTypeDetail<K>;
};

const details: AssetTypeDetails = {
    [AssetType.Container]: {
        AssetIcon: ContainerIcon,
        formatAssetName: (container, _displayPreferences) => formatContainerName(container),
    },
    [AssetType.Driver]: {
        AssetIcon: DriverIcon,
        formatAssetName: (driver, displayPreferences) =>
            formatDriverName(driver, displayPreferences.driverDisplayFormat),
    },
    [AssetType.Trailer]: {
        AssetIcon: TrailerIcon,
        formatAssetName: (trailer, displayPreferences) =>
            formatTrailerName(trailer, displayPreferences.trailerDisplayFormat),
    },
    [AssetType.Vehicle]: {
        AssetIcon: TruckIcon,
        formatAssetName: (vehicle, displayPreferences) =>
            formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat),
    },
};

export const AssetHistoryMapComponentFactory = <T extends keyof AssetDataType>(
    assetType: T
): React.ComponentType<AssetHistoryMapInnerProps<AssetDataType[T]>> => {
    const { AssetIcon, formatAssetName } = details[assetType] as AssetTypeDetail<T>;

    const AssetHistoryMapComponent = ({ asset, displayPreferences }: AssetHistoryMapInnerProps<AssetDataType[T]>) => {
        const assetName = formatAssetName(asset, displayPreferences);
        const classes = useStyles(assetType);
        const theme = useTheme();

        return (
            <HistoryMap
                AssetIcon={AssetIcon}
                assetName={assetName}
                markerClasses={{ assetName: classes.markerLabel }}
                routeColor={theme.functionalItemsColors.assetType[assetType].value}
            />
        );
    };

    return memo(AssetHistoryMapComponent, isEqual);
};
