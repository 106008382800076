import { createSelectorCreator, defaultMemoize } from 'reselect';

import type { DetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { DETAILSPANE_USERPREFERENCES_KEY } from '~/components/SceneDetailsPane';
import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { isEqual } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import type { DevicesAdministrationState } from './models';

const deepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const administrationDevicesSceneState = (state: StoreState): DevicesAdministrationState =>
    state.deviceAdministrationScene;

const getAdministrationDevicesState = deepEqualSelector(
    administrationDevicesSceneState,
    keyDataStateSelectorFactory<DetailsPaneUserPreferences>(DETAILSPANE_USERPREFERENCES_KEY),
    (devicesAdministrationState, { keepSubPageOpen }) => ({
        ...devicesAdministrationState,
        devices: devicesAdministrationState.devices,
        keepSubPageOpen,
    })
);

export { administrationDevicesSceneState, getAdministrationDevicesState };
