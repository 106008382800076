import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MenuClassKey = 'content' | 'menu' | 'root';

export const MenuStyleRules: StyleRulesCallback<Theme, {}, MenuClassKey> = (theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            position: 'relative',
            width: `calc(100vw - ${theme.custom.menu.width}px)`,
            zIndex: 0,
        },
        menu: {
            zIndex: 1,
        },
        root: {
            display: 'flex',
            height: '100vh',
            position: 'relative',
        },
    });
