import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { ContextMenuProps } from './models';
import { useStyles } from './styles';

const ContextMenu: FC<ContextMenuProps> = (props) => {
    const { anchorEl, dataId, dataTestId, items, onClose, position } = props;
    const classes = useStyles();
    const [closingObj, setClosingObj] = useState<PopperProps['anchorEl']>(undefined);

    const referenceObject: PopperProps['anchorEl'] = useMemo(() => {
        return (
            anchorEl ??
            (position && {
                clientHeight: 0,
                clientWidth: 0,
                getBoundingClientRect: () => new DOMRect(position.clientX, position.clientY),
            })
        );
    }, [anchorEl, position]);

    useEffect(() => {
        if (closingObj) {
            // Prevent calling onClose when ContextMenu is opened on a different location (e.g. clicking on another row in the grid)
            if (closingObj === referenceObject) {
                onClose();
            }
            setClosingObj(undefined);
        }
    }, [referenceObject, onClose, closingObj, setClosingObj]);

    useEffect(() => {
        if (!referenceObject) {
            return undefined;
        }

        const awayCallback = () => {
            // Store the location of the ContextMenu when it's requested to be closed
            setClosingObj(referenceObject);
        };

        document.addEventListener('contextmenu', awayCallback);
        document.addEventListener('click', awayCallback);

        return () => {
            document.removeEventListener('contextmenu', awayCallback);
            document.removeEventListener('click', awayCallback);
        };
    }, [referenceObject]);

    const content = items.map((item) => {
        const component = (
            <ListItem
                button
                data-id={item.key}
                data-testid={item.key}
                disabled={item.disabled}
                key={item.key}
                onClick={() => {
                    item.onClick();
                    onClose();
                }}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
        );

        return item.tooltip ? (
            <Tooltip
                className={classes.tooltip}
                data-id={`list-item-tooltip:${item.key}`}
                key={item.key}
                placement="right"
                title={item.tooltip}
            >
                <span data-id={`tooltip-span:${item.key}`}>{component}</span>
            </Tooltip>
        ) : (
            component
        );
    });

    return content.length > 0 ? (
        <Popper anchorEl={referenceObject} open={!!referenceObject} popperOptions={{ placement: 'bottom-start' }}>
            <Paper elevation={8}>
                <MenuList data-id={dataId} data-testid={dataTestId} dense>
                    {content}
                </MenuList>
            </Paper>
        </Popper>
    ) : null;
};
ContextMenu.displayName = 'ContextMenu';
export { ContextMenu };
