import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DistanceFormatter, FuelLevelFormatter } from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';
import { formatDateTime, formatNearestCity } from '~/services/Formatters';

import { TooltipSize } from '../../models';

import type { TooltipProps } from './models';
import { Container, LabelTypography, Row, ValueTypography } from './styles';

const Tooltip: FC<TooltipProps> = (props) => {
    const { info, size, unitSystem } = props;

    const { t } = useTranslation();

    const { datetime, fuelLevel, nearestCity, odometer } = info;

    return (
        <Container elevation={6} size={size}>
            <Row>
                <LabelTypography color="textSecondary" size={size}>
                    {t('fuel-level-graph-date-time')}
                </LabelTypography>
                <ValueTypography size={size}>{formatDateTime(datetime)}</ValueTypography>
            </Row>
            <Row>
                <LabelTypography color="textSecondary" size={size}>
                    {t('fuel-level-graph-fuel')}
                </LabelTypography>
                <ValueTypography size={size}>
                    <FuelLevelFormatter t={t} unitSystem={unitSystem} value={fuelLevel} />
                </ValueTypography>
            </Row>
            <Row>
                <LabelTypography color="textSecondary" size={size}>
                    {t('fuel-level-graph-odometer')}
                </LabelTypography>
                <ValueTypography size={size}>
                    <DistanceFormatter t={t} unitSystem={unitSystem} value={odometer} />
                </ValueTypography>
            </Row>
            {!isUndefined(nearestCity) && size !== TooltipSize.Small && (
                <Row>
                    <LabelTypography color="textSecondary" size={TooltipSize.Large}>
                        {t('fuel-level-graph-nearest-city')}
                    </LabelTypography>
                    <ValueTypography size={TooltipSize.Large}>
                        {formatNearestCity(t, unitSystem, nearestCity)}
                    </ValueTypography>
                </Row>
            )}
        </Container>
    );
};

Tooltip.displayName = 'Tooltip';

export { Tooltip };
