import { Avatar, ListItem, styled } from '@mui/material';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    height: theme.spacing(5),
    overflow: 'visible',
    width: theme.spacing(5),
}));

const Container = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
});

const TimeListItem = styled(ListItem)({
    paddingTop: 0,
});

export { Container, StyledAvatar as Avatar, TimeListItem };
