import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MarkAllMessagesAsReadButtonClassKey = 'disabled' | 'root';

export const spinnerSize = 14;

export const MarkAllMessagesAsReadButtonStyleRules: StyleRulesCallback<
    Theme,
    {},
    MarkAllMessagesAsReadButtonClassKey
> = () =>
    createStyles({
        disabled: {
            cursor: 'default',
        },
        root: {
            cursor: 'pointer',
            fontSize: 14,
            height: 24,
            padding: 0,
            width: 24,
        },
    });
