import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type EmptyStateMessageClassKey = 'image' | 'root' | 'title';

export const EmptyStateMessageStyleRules: StyleRulesCallback<Theme, {}, EmptyStateMessageClassKey> = (theme: Theme) =>
    createStyles({
        image: {
            height: 88,
            marginBottom: theme.spacing(),
            width: 88,
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            marginLeft: 24,
            marginRight: 24,
        },
        title: {
            fontStyle: 'italic',
        },
    });
