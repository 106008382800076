import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import type { WithStyles } from '@mui/styles';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { DateTimeSettingItem, NumericMagnitudeSettingItem } from '~/components/MiniSettingsPanelComponent';
import { MiniSettingsPanel, SettingItemType } from '~/components/MiniSettingsPanelComponent';
import type { ConversationUserPreferences } from '~/modules/Communication';
import { conversationTtlMaxValueInMinutes, conversationTtlMinValueInMinutes } from '~/modules/Communication/constants';
import { SendMessageOptions, createApiModel } from '~/services/ApiClient';
import { durationISOStringToMinutes, minutesNumberToDurationISOString } from '~/services/Formatters';

import type { SendMessageFooterClassKey } from './styles';

export interface SendMessageFooterProps {
    onOptionsChange: (options: SendMessageOptions) => void;
    onWarning: (messageText?: string, canDismiss?: boolean) => void;
    options: SendMessageOptions;
    preferences: ConversationUserPreferences;
    summary?: ReactNode;
}

export interface SendMessageFooterInnerProps
    extends SendMessageFooterProps,
        WithStyles<SendMessageFooterClassKey>,
        InjectedTranslationProps {}

export const SendMessageFooterComponent: FC<SendMessageFooterInnerProps> = (props) => {
    const { classes, onOptionsChange, onWarning, options, preferences, summary, t } = props;

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [localOptions, setLocalOptions] = useState(options);

    const sendOptionsAreDefault = useCallback(
        (sendOptions: SendMessageOptions) => preferences.ttl === sendOptions.ttl && !sendOptions.sendAfter,
        [preferences]
    );

    useEffect(() => {
        setLocalOptions(options);
        if (sendOptionsAreDefault(options)) {
            onWarning('');
        }
    }, [options, sendOptionsAreDefault, onWarning]);

    const onOptionsChangeCallback = useCallback(
        (ttl?: string, sendAfter?: Date) =>
            onOptionsChange({
                sendAfter: sendAfter ?? localOptions.sendAfter,
                ttl: ttl ?? localOptions.ttl,
            } as SendMessageOptions),
        [onOptionsChange, localOptions]
    );

    const toggleCollapsedCallback = useCallback(() => {
        if (!isCollapsed && !sendOptionsAreDefault(localOptions)) {
            onWarning(t('advanced-message-settings-warning-options-changed'), true);
        }

        setIsCollapsed(!isCollapsed);
    }, [isCollapsed, sendOptionsAreDefault, localOptions, onWarning, t]);

    const getSettings = useCallback(() => {
        const ttlInMinutes = durationISOStringToMinutes(localOptions.ttl);

        function updateLocalExpireAfter(ttlInMinutesValue: number): void {
            const ttl = minutesNumberToDurationISOString(ttlInMinutesValue);
            if (ttl) {
                setLocalOptions((prevState) => createApiModel(SendMessageOptions, { ...prevState, ttl }));
                onOptionsChangeCallback(ttl, undefined);
            }
        }

        function updateLocalSendAfter(sendAfter: Date | undefined): void {
            setLocalOptions((prevState) => createApiModel(SendMessageOptions, { ...prevState, sendAfter }));
            onOptionsChangeCallback(undefined, sendAfter);
        }

        const expireAfterSetting: NumericMagnitudeSettingItem = {
            errorText: t('advanced-message-settings-expire-after-validation-error', {
                max: conversationTtlMaxValueInMinutes,
                min: conversationTtlMinValueInMinutes,
            }),
            isRequired: true,
            key: 'message-settings:expire-after',
            maxValue: conversationTtlMaxValueInMinutes,
            minValue: conversationTtlMinValueInMinutes,
            onValueChange: updateLocalExpireAfter,
            settingType: SettingItemType.NumericMagnitude,
            title: t('expire-after'),
            unit: t('unit-minutes'),
            value: ttlInMinutes,
        };

        const sendAfterSetting: DateTimeSettingItem = {
            disablePastDatesInPicker: true,
            errorText: t('advanced-message-settings-send-after-validation-error'),
            key: 'message-settings:send-after',
            onValueChange: updateLocalSendAfter,
            settingType: SettingItemType.DateTime,
            title: t('send-after'),
            value: localOptions.sendAfter,
        };

        return [expireAfterSetting, sendAfterSetting];
    }, [localOptions, onOptionsChangeCallback, t]);

    const CollapseIcon = isCollapsed ? ArrowDropUp : ArrowDropDown;

    const summaryComponent = (
        <>
            {summary}
            <CollapseIcon className={classes.collapseIcon} onClick={toggleCollapsedCallback} />
        </>
    );

    const advancedSettingsPanel = (
        <div data-id="message-settings">
            <MiniSettingsPanel settings={getSettings()} />
        </div>
    );

    return (
        <div data-id="message-settings-panel">
            <div className={classes.summary} data-id="message-summary">
                {summaryComponent}
            </div>
            {!isCollapsed && advancedSettingsPanel}
        </div>
    );
};
