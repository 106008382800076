import {
    add as addDates,
    differenceInMinutes,
    endOfDay,
    format,
    formatDuration,
    formatISODuration,
    isSameDay,
    isValid as isValidDate,
    startOfDay,
    sub as subDates,
} from 'date-fns';
import {
    toString as durationToString,
    toUnit as durationToUnit,
    parse as stringToDuration,
    sum as sumDurations,
} from 'duration-fns';
import type { HumanizerOptions } from 'humanize-duration';
import { humanizer } from 'humanize-duration';

import { getI18n } from '~/i18n';
import deDe from '~/locales/momentDuration.de-de.json';
import enGb from '~/locales/momentDuration.en-gb.json';
import esEs from '~/locales/momentDuration.es-es.json';
import frFr from '~/locales/momentDuration.fr-fr.json';
import huHu from '~/locales/momentDuration.hu-hu.json';
import itIt from '~/locales/momentDuration.it-it.json';
import nlNl from '~/locales/momentDuration.nl-nl.json';
import plPl from '~/locales/momentDuration.pl-pl.json';
import ptBr from '~/locales/momentDuration.pt-br.json';

interface EnhancedOptions extends HumanizerOptions {
    short?: boolean;
}

const customHumanizer = humanizer({
    fallbacks: ['short-en-GB'],
    language: 'short-en-GB',
    languages: {
        'short-de-DE': {
            d: () => deDe.durationLabelsShort.dd,
            h: () => deDe.durationLabelsShort.hh,
            m: () => deDe.durationLabelsShort.mm,
            mo: () => deDe.durationLabelsShort.MM,
            ms: () => deDe.durationLabelsShort.SS,
            s: () => deDe.durationLabelsShort.ss,
            w: () => deDe.durationLabelsShort.ww,
            y: () => deDe.durationLabelsShort.yy,
        },
        'short-en-GB': {
            d: (count: number) => (count > 1 ? enGb.durationLabelsShort.dd : enGb.durationLabelsShort.d),
            h: (count: number) => (count > 1 ? enGb.durationLabelsShort.hh : enGb.durationLabelsShort.h),
            m: (count: number) => (count > 1 ? enGb.durationLabelsShort.mm : enGb.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? enGb.durationLabelsShort.MM : enGb.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? enGb.durationLabelsShort.SS : enGb.durationLabelsShort.S),
            s: (count: number) => (count > 1 ? enGb.durationLabelsShort.ss : enGb.durationLabelsShort.s),
            w: (count: number) => (count > 1 ? enGb.durationLabelsShort.ww : enGb.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? enGb.durationLabelsShort.yy : enGb.durationLabelsShort.y),
        },
        'short-es-ES': {
            d: (count: number) => (count > 1 ? esEs.durationLabelsShort.dd : esEs.durationLabelsShort.d),
            h: (count: number) => (count > 1 ? esEs.durationLabelsShort.hh : esEs.durationLabelsShort.h),
            m: (count: number) => (count > 1 ? esEs.durationLabelsShort.mm : esEs.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? esEs.durationLabelsShort.MM : esEs.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? esEs.durationLabelsShort.SS : esEs.durationLabelsShort.S),
            s: (count: number) => (count > 1 ? esEs.durationLabelsShort.ss : esEs.durationLabelsShort.s),
            w: (count: number) => (count > 1 ? esEs.durationLabelsShort.ww : esEs.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? esEs.durationLabelsShort.yy : esEs.durationLabelsShort.y),
        },
        'short-fr-FR': {
            d: (count: number) => (count > 1 ? frFr.durationLabelsShort.dd : frFr.durationLabelsShort.d),
            h: () => frFr.durationLabelsShort.hh,
            m: () => frFr.durationLabelsShort.mm,
            mo: (count: number) => (count > 1 ? frFr.durationLabelsShort.MM : frFr.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? frFr.durationLabelsShort.SS : frFr.durationLabelsShort.S),
            s: () => frFr.durationLabelsShort.ss,
            w: (count: number) => (count > 1 ? frFr.durationLabelsShort.ww : frFr.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? frFr.durationLabelsShort.yy : frFr.durationLabelsShort.y),
        },
        'short-hu-HU': {
            d: (count: number) => (count > 1 ? huHu.durationLabelsShort.dd : huHu.durationLabelsShort.d),
            h: (count: number) => (count > 1 ? huHu.durationLabelsShort.hh : huHu.durationLabelsShort.h),
            m: (count: number) => (count > 1 ? huHu.durationLabelsShort.mm : huHu.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? huHu.durationLabelsShort.MM : huHu.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? huHu.durationLabelsShort.SS : huHu.durationLabelsShort.S),
            s: (count: number) => (count > 1 ? huHu.durationLabelsShort.ss : huHu.durationLabelsShort.s),
            w: (count: number) => (count > 1 ? huHu.durationLabelsShort.ww : huHu.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? huHu.durationLabelsShort.yy : huHu.durationLabelsShort.y),
        },
        'short-it-IT': {
            d: () => itIt.durationLabelsShort.dd,
            h: (count: number) => (count > 1 ? itIt.durationLabelsShort.hh : itIt.durationLabelsShort.h),
            m: () => itIt.durationLabelsShort.mm,
            mo: () => itIt.durationLabelsShort.MM,
            ms: () => itIt.durationLabelsShort.SS,
            s: () => itIt.durationLabelsShort.ss,
            w: () => itIt.durationLabelsShort.ww,
            y: (count: number) => (count > 1 ? itIt.durationLabelsShort.yy : itIt.durationLabelsShort.y),
        },
        'short-nl-NL': {
            d: (count: number) => (count > 1 ? nlNl.durationLabelsShort.dd : nlNl.durationLabelsShort.d),
            h: () => nlNl.durationLabelsShort.hh,
            m: (count: number) => (count > 1 ? nlNl.durationLabelsShort.mm : nlNl.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? nlNl.durationLabelsShort.MM : nlNl.durationLabelsShort.M),
            ms: () => nlNl.durationLabelsShort.SS,
            s: () => nlNl.durationLabelsShort.ss,
            w: (count: number) => (count > 1 ? nlNl.durationLabelsShort.ww : nlNl.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? nlNl.durationLabelsShort.yy : nlNl.durationLabelsShort.y),
        },
        'short-pl-PL': {
            d: (count: number) => (count > 1 ? plPl.durationLabelsShort.dd : plPl.durationLabelsShort.d),
            h: (count: number) => (count > 1 ? plPl.durationLabelsShort.hh : plPl.durationLabelsShort.h),
            m: (count: number) => (count > 1 ? plPl.durationLabelsShort.mm : plPl.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? plPl.durationLabelsShort.MM : plPl.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? plPl.durationLabelsShort.SS : plPl.durationLabelsShort.S),
            s: () => plPl.durationLabelsShort.ss,
            w: (count: number) => (count > 1 ? plPl.durationLabelsShort.ww : plPl.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? plPl.durationLabelsShort.yy : plPl.durationLabelsShort.y),
        },
        'short-pt-BR': {
            d: (count: number) => (count > 1 ? ptBr.durationLabelsShort.dd : ptBr.durationLabelsShort.d),
            h: (count: number) => (count > 1 ? ptBr.durationLabelsShort.hh : ptBr.durationLabelsShort.h),
            m: (count: number) => (count > 1 ? ptBr.durationLabelsShort.mm : ptBr.durationLabelsShort.m),
            mo: (count: number) => (count > 1 ? ptBr.durationLabelsShort.MM : ptBr.durationLabelsShort.M),
            ms: (count: number) => (count > 1 ? ptBr.durationLabelsShort.SS : ptBr.durationLabelsShort.S),
            s: (count: number) => (count > 1 ? ptBr.durationLabelsShort.ss : ptBr.durationLabelsShort.s),
            w: (count: number) => (count > 1 ? ptBr.durationLabelsShort.ww : ptBr.durationLabelsShort.w),
            y: (count: number) => (count > 1 ? ptBr.durationLabelsShort.yy : ptBr.durationLabelsShort.y),
        },
    },
});

const durationHumanizer = (ms: number, options: EnhancedOptions = { short: true }) => {
    const { short, ...restOpts } = options;
    const { language } = getI18n();

    return customHumanizer(ms, {
        language: short ? `short-${language}` : language.split('-')[0],
        maxDecimalPoints: 0,
        ...restOpts,
    });
};

export type { Duration } from 'date-fns';
export type { DurationInput } from 'duration-fns';
export {
    addDates,
    differenceInMinutes,
    durationHumanizer,
    durationToString,
    durationToUnit,
    endOfDay,
    format,
    formatDuration,
    formatISODuration,
    isSameDay,
    isValidDate,
    startOfDay,
    stringToDuration,
    subDates,
    sumDurations,
};
