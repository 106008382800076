import { Grid, Typography } from '@mui/material';
import * as React from 'react';

import type { HeaderProps } from './models';

const maxSizeOfColumn = 12;
const sizeOfMulticolumn = 4;

const Header: React.FC<HeaderProps> = (props) => {
    const { classes, dataId, headers, isMulticolumn } = props;

    return (
        <Grid className={classes.headerBox} container data-id={`${dataId}-list-header`}>
            {headers?.map((e: string, index: number) => (
                <Grid
                    className={classes.headerText}
                    data-id={`${dataId}-list-header-value-${index}`}
                    item
                    key={`key-${dataId}-list-header-value-${e}`}
                    xs={isMulticolumn ? sizeOfMulticolumn : maxSizeOfColumn}
                >
                    <Typography>{e}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default Header;
