import { createContext, useContext } from 'react';

import type { MapContextState } from './models';

const MapContext = createContext<MapContextState | undefined>(undefined);

const useMapContext = () => {
    const context = useContext(MapContext);

    if (context === undefined) {
        throw new Error('useMapContext can only be used inside MapContext.Provider');
    }

    return context;
};

export { MapContext, useMapContext };
