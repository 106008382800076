import {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesTrailerDisplayFormat,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

export interface DisplayUserPreferences {
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat;
    theme: string;
    trailerDisplayFormat: DisplayUserPreferencesTrailerDisplayFormat;
    unitSystem: DisplayUserPreferencesUnitSystem;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export const defaultDisplayUserPreferences: DisplayUserPreferences = {
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat.FirstnameLastname,
    theme: 'default',
    trailerDisplayFormat: DisplayUserPreferencesTrailerDisplayFormat.Alias,
    unitSystem: DisplayUserPreferencesUnitSystem.Metric,
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat.Alias,
};

export const requiredDisplayUserPreferences = {
    defaultState: defaultDisplayUserPreferences,
    key: PredefinedUserPreferencesKeys.DISPLAY,
};
