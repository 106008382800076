import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DriverEventFieldsSectionClassKey =
    | 'centerContents'
    | 'failedToLoad'
    | 'italicText'
    | 'paperBackground'
    | 'retryMessage'
    | 'root';

export const useStyles = makeStyles<Theme, {}, DriverEventFieldsSectionClassKey>((theme) => {
    return {
        centerContents: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
        },
        failedToLoad: {
            display: 'flex',
            flexDirection: 'column',
        },
        italicText: {
            fontStyle: 'italic',
        },
        paperBackground: {
            backgroundColor: theme.palette.background.paper,
        },
        retryMessage: {
            display: 'block',
            fontStyle: 'italic',
            marginBottom: theme.spacing(),
        },
        root: {
            textAlign: 'center',
        },
    };
});
