import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { AssetType, ResolvedRtdsSchedule } from '~/services/ApiClient';

import type { RtdsSchedulesEntry } from '../../models';

import { SectionName } from './constants';
import type { RtdsScheduleDetailsPaneUserPreferences } from './preferences';
import { RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface RtdsScheduleDetailsPaneProps {
    entry: RtdsSchedulesEntry;
    handleClose: () => void;
    openScheduleEditor: (assetType: AssetType, isClone: boolean, schedule?: ResolvedRtdsSchedule) => void;
}

export interface RtdsScheduleDetailsPaneInnerProps extends RtdsScheduleDetailsPaneProps, InjectedTranslationProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    RtdsSchedulesEntry,
    RtdsScheduleDetailsPaneUserPreferences,
    SectionName
>(RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY);

export const RtdsScheduleDetailsPaneComponent: React.FC<RtdsScheduleDetailsPaneInnerProps> = ({
    entry,
    handleClose,
    openScheduleEditor,
    t,
}) => {
    const getSections = () => getSectionsMemoized(entry, openScheduleEditor);
    const header = <DetailsPaneHeader onClose={handleClose} title={t('rtds-schedules-details-pane-header')} />;

    return (
        <DetailsPaneComponent
            alwaysFirstSection={SectionName.RTDSSCHEDULEDETAILS}
            dataId="rtdsschedule-details-pane"
            entry={entry}
            getSections={getSections}
            HeaderReactNode={header}
        />
    );
};
