import { Map } from '@fv/components/Map';
import { Button } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import { divIcon, latLngBounds } from 'leaflet';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Marker } from 'react-leaflet';

import { AddressPopup } from '~/components/AddressPopup';
import { WidgetDialog } from '~/components/Dialogs/WidgetDialog';
import { ConversationMiniMapPinIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SearchLocationBar } from '~/components/Map';
import { useMapLayers } from '~/components/MapSharedProps';
import { useUserPreferences } from '~/components/UserPreferences';
import { isNil } from '~/libs/utility';
import { Position, createApiModel } from '~/services/ApiClient';
import { renderToStaticThemedMarkup } from '~/services/React';

import type { ConversationUserPreferences } from '../../../../../../../../../../preferences';
import { CONVERSATION_USERPREFERENCES_KEY } from '../../../../../../../../../../preferences';

import { MAX_ZOOM } from './const';
import type { PositionDialogStyleRules } from './styles';

export interface PositionDialogProps {
    onClose: () => void;
    open: boolean;
    position: Position | undefined;
    setFieldValue: (position: Position) => void;
}

export interface PositionDialogInnerProps
    extends PositionDialogProps,
        WithStyles<typeof PositionDialogStyleRules, true>,
        InjectedTranslationProps {}

export const PositionDialogComponent: FC<PositionDialogInnerProps> = (props) => {
    const { classes, onClose, open, position, setFieldValue, t, theme } = props;
    const [userPreferences, setUserPreferences] = useUserPreferences<ConversationUserPreferences>(
        CONVERSATION_USERPREFERENCES_KEY
    );

    const [dialogInnerPosition, setDialogInnerPosition] = useState<Position | undefined>(position);
    const normalizedPosition = useMemo(() => {
        if (!dialogInnerPosition) {
            return undefined;
        }

        // leaflet calculation
        const longitude = (((dialogInnerPosition.longitude % 360) + 540) % 360) - 180;
        return createApiModel(Position, { latitude: dialogInnerPosition?.latitude, longitude });
    }, [dialogInnerPosition]);

    const onSelectPositionHandler = useCallback(() => {
        if (normalizedPosition) {
            setFieldValue(normalizedPosition);
        }

        onClose();
    }, [normalizedPosition, onClose, setFieldValue]);

    const addMarkerHandler = useCallback(
        (event) => {
            setDialogInnerPosition(
                createApiModel(Position, { latitude: event.latlng.lat, longitude: event.latlng.lng })
            );
        },
        [setDialogInnerPosition]
    );

    const removeMarkerHandler = useCallback(() => {
        setDialogInnerPosition(undefined);
    }, [setDialogInnerPosition]);

    const tileLayers = useMapLayers({ mapMode: userPreferences.pickerMapMode });

    const map = useMemo(() => {
        const icon = divIcon({
            className: classes.positionIcon,
            html: renderToStaticThemedMarkup(
                theme,
                <ConversationMiniMapPinIcon data-id="position-marker" fontSize="inherit" />
            ),
            iconAnchor: [23, 50],
            iconSize: [46, 56],
        });

        return (
            <div className={classes.mapContainer} data-id="map">
                <Map
                    baseLayer={tileLayers}
                    center={!isNil(position) ? [position?.latitude, position?.longitude] : undefined}
                    dragging
                    mapEvents={{ click: addMarkerHandler }}
                    onBaseLayerChange={({ mapMode }) => setUserPreferences({ pickerMapMode: mapMode })}
                    relocate={{
                        isDisable: !dialogInnerPosition,
                        onClick: (mapRef) => {
                            if (!dialogInnerPosition) {
                                return;
                            }

                            const latLng = { lat: dialogInnerPosition.latitude, lng: dialogInnerPosition.longitude };
                            const bounds = latLngBounds([latLng]);
                            mapRef?.fitBounds(bounds, { maxZoom: MAX_ZOOM });
                        },
                    }}
                >
                    <SearchLocationBar />

                    {dialogInnerPosition && (
                        <Marker icon={icon} position={[dialogInnerPosition.latitude, dialogInnerPosition.longitude]} />
                    )}
                </Map>
            </div>
        );
    }, [
        addMarkerHandler,
        classes.mapContainer,
        classes.positionIcon,
        dialogInnerPosition,
        position,
        theme,
        tileLayers,
        setUserPreferences,
    ]);

    return (
        <WidgetDialog
            dialogActions={
                <>
                    <Button color="secondary" data-id="cancel" onClick={onClose}>
                        {t('cancel')}
                    </Button>
                    <Button
                        color="secondary"
                        data-id="select"
                        disabled={!dialogInnerPosition}
                        onClick={onSelectPositionHandler}
                        variant="contained"
                    >
                        {t('select')}
                    </Button>
                </>
            }
            onClose={onClose}
            open={open}
            testId="position-dialog"
            title={<span data-id="dialog-title">{t('position-dialog-title')}</span>}
        >
            <div className={classes.wrapper}>
                {map}
                {normalizedPosition && <AddressPopup onClose={removeMarkerHandler} position={normalizedPosition} />}
            </div>
        </WidgetDialog>
    );
};
