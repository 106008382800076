import { compareNumbers } from '~/services/Sorting';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export const createNumberColumn = <T>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => number | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => {
    return {
        align: 'right',
        compare: compareNumbers,
        dataType: 'number',
        getCellValue,
        groupTitle,
        name,
        title,
        ...options,
    };
};
