import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '../Dialog';

import type { ConfirmDialogProps } from './models';
import { MessageContainer, Spinner, Text } from './styles';

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const { actionText, onClose, onConfirm, onSuccess, progressText, testId, text, title } = props;

    const [status, setStatus] = useState({ error: false, isLoading: false });
    const { t } = useTranslation();

    const handleConfirm = () => {
        setStatus({ error: false, isLoading: true });

        onConfirm()
            .then(() => {
                setStatus({ error: false, isLoading: false });
                onSuccess();
            })
            .catch(() => {
                setStatus({ error: true, isLoading: false });
            });
    };

    return (
        <Dialog
            confirmationActionText={actionText}
            data-testid={`${testId}-confirm-dialog`}
            isDisabled={status?.isLoading}
            onClose={onClose}
            onConfirm={handleConfirm}
            title={title}
        >
            <>
                <Typography data-testid={`${testId}-confirm-dialog-text`}>{text}</Typography>
                {status.error && (
                    <Typography color="error" data-testid={`${testId}-confirm-dialog-error-message`} variant="caption">
                        {t('generic-error-message')}
                    </Typography>
                )}

                {status.isLoading && (
                    <MessageContainer>
                        <Spinner size={30} />
                        <Text data-testid={`${testId}-confirm-dialog-progress-message`} variant="subtitle2">
                            {progressText}
                        </Text>
                    </MessageContainer>
                )}
            </>
        </Dialog>
    );
};

ConfirmDialog.displayName = 'ConfirmDialog';
export { ConfirmDialog };
