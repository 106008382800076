import type { IntroCarousel } from '~/services/IntroCarousel';
import type { BaseAction, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    LATESTINTROCAROUSEL = 'LATESTINTROCAROUSEL',
}

export enum ActionTypeKeys {
    INTROCAROUSEL_CLOSE = 'INTROCAROUSEL_CLOSE',
    INTROCAROUSEL_OPEN = 'INTROCAROUSEL_OPEN',
    INTROCAROUSEL_OPENUNSEEN = 'INTROCAROUSEL_OPENUNSEEN',
    LATESTINTROCAROUSEL_FULFILLED = 'LATESTINTROCAROUSEL_FULFILLED',
    LATESTINTROCAROUSEL_PENDING = 'LATESTINTROCAROUSEL_PENDING',
    LATESTINTROCAROUSEL_REJECTED = 'LATESTINTROCAROUSEL_REJECTED',
}

export interface LatestIntroCarouselPayload {
    readonly item: IntroCarousel;
}

export type ActionTypes =
    | BaseAction<ActionTypeKeys.INTROCAROUSEL_CLOSE>
    | BaseAction<ActionTypeKeys.INTROCAROUSEL_OPEN>
    | BaseAction<ActionTypeKeys.INTROCAROUSEL_OPENUNSEEN>
    | Fulfilled<ActionTypeKeys.LATESTINTROCAROUSEL_FULFILLED, LatestIntroCarouselPayload>
    | Pending<ActionTypeKeys.LATESTINTROCAROUSEL_PENDING>
    | Rejected<ActionTypeKeys.LATESTINTROCAROUSEL_REJECTED>;
