import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { InfoBoxStyleRules } from '../../styles';

import { VehicleInfoBox as _VehicleInfoBox } from './component';
import type { VehicleInfoBoxInnerProps, VehicleInfoBoxProps } from './models';

export const VehicleInfoBox = compose<VehicleInfoBoxInnerProps, VehicleInfoBoxProps>(
    setDisplayName('InfoBox'),
    withStyles(InfoBoxStyleRules),
    withTranslation()
)(_VehicleInfoBox);
