import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ConversationMiniMapPinIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 46">
            {/* tslint:disable:max-line-length */}
            <path
                d="M 15 39.0369284683536  C 9.2 29.4073405959099  0.5 23.6295878724437  0.5 14  C 0.5 8.98571428571429  3.4 0.499999999999998  15 0.499999999999998  C 26.6 0.499999999999998  29.5 8.98571428571429  29.5 14  C 29.5 23.6295878724437  20.8 29.4073405959099  15 39.0369284683536  Z "
                fill="#1289a7"
                fillRule="nonzero"
                stroke="none"
            />
            <path
                d="M 15 40  C 9 30  0 24  0 14  C 0 8.8  3 0  15 0  C 27 0  30 8.8  30 14  C 30 24  21 30  15 40  Z M 15 38.0738569367072  C 20.6 28.8146811918198  29 23.2591757448874  29 14  C 29 9.17142857142857  26.2 1  15 1  C 3.8 1  1 9.17142857142857  1 14  C 1 23.2591757448874  9.4 28.8146811918198  15 38.0738569367072  Z "
                fill="#ffffff"
                fillRule="nonzero"
                stroke="none"
            />
        </SvgIcon>
    );
};
ConversationMiniMapPinIcon.displayName = 'ConversationMiniMapPin';

export { ConversationMiniMapPinIcon };
