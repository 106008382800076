interface RequiredUserPreferencesProps<T> {
    defaultState?: T;
    key: string;
    processResponse?: (values: T) => T;
}

export interface RequiredUserPreferences<T> {
    defaultState: T;
    key: string;
    processResponse?: (preferences: T) => T;
}

export const requiredUserPreferences = <T>({
    defaultState,
    key,
    processResponse,
}: RequiredUserPreferencesProps<T>): RequiredUserPreferences<T> => ({
    defaultState: defaultState ?? ({} as T),
    key,
    ...(processResponse && { processResponse }),
});
