export enum ColumnName {
    ADDRESS = 'address',
    ALTITUDE = 'altitude',
    AXLELOAD = 'axle-load',
    BATTERYLEVELTRAILER = 'battery-level-trailer',
    BATTERYSTATUSTRAILER = 'battery-status-trailer',
    BATTERYVOLTAGEREEFER = 'battery-voltage-reefer',

    BATTERYVOLTAGETRAILER = 'battery-voltage-trailer',
    CITY = 'city',
    COMPARTMENT1LASTEVENT = 'compartment1-last-event',
    COMPARTMENT1MAXBANDWITHTEMP = 'compartment1-max-bandwith-temp',
    COMPARTMENT1MINBANDWITHTEMP = 'compartment1-min-bandwith-temp',
    COMPARTMENT1RETURNTEMPERATURE = 'compartment1-return-temperature',
    COMPARTMENT1SETPOINTTEMPERATURE = 'compartment1-setpoint-temperature',
    COMPARTMENT1STATUS = 'compartment1-status',
    COMPARTMENT1STATUSMANUFACTURER = 'compartment1-status-manufacturer',
    COMPARTMENT1SUPPLYTEMPERATURE = 'compartment1-supply-temperature',
    COMPARTMENT1TEMPERATURESENSOR1 = 'compartment1-temperature-sensor1',
    COMPARTMENT1TEMPERATURESENSOR2 = 'compartment1-temperature-sensor2',
    COMPARTMENT1TEMPERATURESENSOR3 = 'compartment1-temperature-sensor3',
    COMPARTMENT1TEMPERATURESENSOR4 = 'compartment1-temperature-sensor4',
    COMPARTMENT2LASTEVENT = 'compartment2-last-event',
    COMPARTMENT2MAXBANDWITHTEMP = 'compartment2-max-bandwith-temp',
    COMPARTMENT2MINBANDWITHTEMP = 'compartment2-min-bandwith-temp',
    COMPARTMENT2RETURNTEMPERATURE = 'compartment2-return-temperature',
    COMPARTMENT2SETPOINTTEMPERATURE = 'compartment2-setpoint-temperature',
    COMPARTMENT2STATUS = 'compartment2-status',
    COMPARTMENT2STATUSMANUFACTURER = 'compartment2-status-manufacturer',
    COMPARTMENT2SUPPLYTEMPERATURE = 'compartment2-supply-temperature',
    COMPARTMENT2TEMPERATURESENSOR1 = 'compartment2-temperature-sensor1',
    COMPARTMENT2TEMPERATURESENSOR2 = 'compartment2-temperature-sensor2',
    COMPARTMENT2TEMPERATURESENSOR3 = 'compartment2-temperature-sensor3',
    COMPARTMENT2TEMPERATURESENSOR4 = 'compartment2-temperature-sensor4',
    COMPARTMENT3LASTEVENT = 'compartment3-last-event',
    COMPARTMENT3MAXBANDWITHTEMP = 'compartment3-max-bandwith-temp',
    COMPARTMENT3MINBANDWITHTEMP = 'compartment3-min-bandwith-temp',
    COMPARTMENT3RETURNTEMPERATURE = 'compartment3-return-temperature',
    COMPARTMENT3SETPOINTTEMPERATURE = 'compartment3-setpoint-temperature',
    COMPARTMENT3STATUS = 'compartment3-status',
    COMPARTMENT3STATUSMANUFACTURER = 'compartment3-status-manufacturer',
    COMPARTMENT3SUPPLYTEMPERATURE = 'compartment3-supply-temperature',

    COMPARTMENT3TEMPERATURESENSOR1 = 'compartment3-temperature-sensor1',
    COMPARTMENT3TEMPERATURESENSOR2 = 'compartment3-temperature-sensor2',

    COMPARTMENT3TEMPERATURESENSOR3 = 'compartment3-temperature-sensor3',
    COMPARTMENT3TEMPERATURESENSOR4 = 'compartment3-temperature-sensor4',

    COMPARTMENT4LASTEVENT = 'compartment4-last-event',
    COMPARTMENT4MAXBANDWITHTEMP = 'compartment4-max-bandwith-temp',
    COMPARTMENT4MINBANDWITHTEMP = 'compartment4-min-bandwith-temp',
    COMPARTMENT4RETURNTEMPERATURE = 'compartment4-return-temperature',
    COMPARTMENT4SETPOINTTEMPERATURE = 'compartment4-setpoint-temperature',
    COMPARTMENT4STATUS = 'compartment4-status',
    COMPARTMENT4STATUSMANUFACTURER = 'compartment4-status-manufacturer',
    COMPARTMENT4SUPPLYTEMPERATURE = 'compartment4-supply-temperature',
    COMPARTMENT4TEMPERATURESENSOR1 = 'compartment4-temperature-sensor1',
    COMPARTMENT4TEMPERATURESENSOR2 = 'compartment4-temperature-sensor2',
    COMPARTMENT4TEMPERATURESENSOR3 = 'compartment4-temperature-sensor3',
    COMPARTMENT4TEMPERATURESENSOR4 = 'compartment4-temperature-sensor4',

    COUNTRYCODE = 'country-code',
    DOOR1SEALCODE = 'door1-sealcode',
    DOOR1SENSOR = 'door1-sensor',
    DOOR1TIMESTAMP = 'door1-timestamp',
    DOOR2SEALCODE = 'door2-sealcode',
    DOOR2SENSOR = 'door2-sensor',
    DOOR2TIMESTAMP = 'door2-timestamp',
    EBSMILEAGE = 'ebs-mileage',
    EBSTIMESTAMP = 'ebs-timestamp',
    EVENTTYPE = 'event-type',
    FUELLEVELREEFER = 'fuel-level-reefer',
    HOOKED = 'hooked',

    ISMANUALLYCONNECTED = 'is-manually-connected',
    LATITUDE = 'latitude',
    LONGITUDE = 'longitude',
    NEARESTCITY = 'nearest-city',
    POSITIONDATE = 'position-date',
    POSTALCODE = 'postal-code',
    REEFERALARMS = 'reefer-alarms',
    REEFERALARMSMANUFACTURER = 'reefer-alarms-manufacturer',
    REEFERMANUFACTURER = 'reefer-manufacturer',
    REEFEROPERATIONMODE = 'reefer-operation-mode',
    REEFERPOWERMODE = 'reefer-power-mode',
    REEFERSPEED = 'reefer-speed',

    REEFERSTATUS = 'reefer-status',
    TOTALENGINEHOURSREEFER = 'total-engine-hours-reefer',
    TRAILER = 'trailer',
    TRAILERALIAS = 'trailer-alias',
    TRAILERLASTEVENT = 'trailer-last-event',
    TRAILERMANUFACTURER = 'trailer-manufacturer',
    TRAILERNUMBER = 'trailer-number',
    TRAILERUNITID = 'trailer-unit-id',
    TRAILERVIN = 'trailer-vin',
    TRAILERVRN = 'trailer-vrn',
    TRAILERWEIGHT = 'trailer-weight',
    VEHICLE = 'vehicle',
}
