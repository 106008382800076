import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DoorStatusClassKey = 'avatar' | 'badge' | 'flex';

export const DoorStatusStyleRules: StyleRulesCallback<Theme, {}, DoorStatusClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            alignSelf: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            fontSize: 20,
            height: 40,
            overflow: 'visible',
            width: 40,
        },
        badge: {
            alignItems: 'center',
            backgroundColor: theme.palette.info.main,
            borderColor: theme.palette.info.contrastText,
            borderRadius: '50%',
            borderWidth: 1,
            color: theme.palette.primary.contrastText,
            display: 'flex',
            fontSize: 14,
            height: 22,
            justifyContent: 'center',
            overflow: 'hidden',
            right: 10,
            width: 22,
        },
        flex: {
            display: 'flex',
        },
    });
