import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetDialog } from '~/components/Dialogs';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import {
    DriverActivitiesReportChart,
    DriverActivitiesReportPerspective,
} from '~/components/DriverActivitiesReportChart';
import { TitledIconButton } from '~/components/TitledIconButton';
import { subDates } from '~/libs/dates';
import { DurationFormat, FormatLength, formatDriverName, formatDuration } from '~/services/Formatters';

import type { DriverActivityReportReduxProps } from './redux';
import { useStyles } from './styles';

export interface DriverActivitiesReportDialogProps {
    driverId: number;
    onClose: () => void;
    vehicleId: number | undefined;
}

export interface OwnProps {
    driverId: number;
}

export interface DriverActivitiesReportDialogInnerProps
    extends DriverActivityReportReduxProps,
        DriverActivitiesReportDialogProps,
        InjectedDisplayPreferencesProps {}

const chartDuration = moment.duration(1, 'day');
const durationFormatOptions = {
    durationFormat: DurationFormat.Hour,
    durationFormatLength: FormatLength.Standard,
};

export const DriverActivitiesReportDialogComponent: FC<DriverActivitiesReportDialogInnerProps> = (props) => {
    const {
        displayPreferences,
        driver,
        driverEventEntries,
        driverId,
        getDriverEvents,
        loading,
        onClose,
        rejected,
        vehicleId,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        const now = new Date();
        getDriverEvents(vehicleId, driverId, subDates(now, { days: 1 }), now);
    }, [driverId, getDriverEvents, vehicleId]);

    const content = useMemo(() => {
        if (loading) {
            return (
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            );
        }

        if (rejected) {
            return (
                <Typography className={classes.messageText} data-id="loading-failed" variant="caption">
                    {t('failed-to-retrieve-data')}
                </Typography>
            );
        }

        if (!driverEventEntries.length) {
            return (
                <Typography className={classes.messageText} data-id="no-graph-data" variant="body2">
                    {t('no-historic-data-available', {
                        period: formatDuration(chartDuration, durationFormatOptions),
                    })}
                </Typography>
            );
        }

        return (
            <div className={classes.content}>
                <DriverActivitiesReportChart
                    perspective={DriverActivitiesReportPerspective.Driver}
                    resolvedDriverEvents={driverEventEntries}
                />
            </div>
        );
    }, [classes, driverEventEntries, loading, rejected, t]);

    const title = t('driver-activities-report-dialog-title', {
        driverName: formatDriverName(driver, displayPreferences.driverDisplayFormat),
    });

    return (
        <WidgetDialog
            headerActions={[
                <TitledIconButton color="inherit" data-id="close" onClick={onClose} title={t('close')}>
                    <Close />
                </TitledIconButton>,
            ]}
            open
            testId="driver-activities-report-dialog"
            title={title}
        >
            {content}
        </WidgetDialog>
    );
};
