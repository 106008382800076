import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { DateTimeInputProps } from './component';

export type DateTimeInputClassKey =
    | 'changedNotchedOutline'
    | 'focusChangedNotchedOutline'
    | 'outlinedInput'
    | 'toolbarIcon';

export const DateTimeInputStyleRules: StyleRulesCallback<Theme, DateTimeInputProps, DateTimeInputClassKey> = (
    theme: Theme
) =>
    createStyles({
        changedNotchedOutline: { borderColor: `${theme.palette.warning.main} !important`, borderWidth: '1px' },
        focusChangedNotchedOutline: { borderColor: `${theme.palette.primary.main} !important` },
        outlinedInput: {
            '&:hover:not($disabled):not($focused):not($error) $changedNotchedOutline': {
                borderColor: `${theme.palette.warning.dark} !important`,
            },
            '&$focusChangedNotchedOutline $changedNotchedOutline': {
                borderColor: `${theme.palette.primary.main} !important`,
            },
        },
        toolbarIcon: { color: theme.palette.primary.contrastText },
    });
