import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';

import { ClassificationFormatter, TrailerNameFormatter } from '~/components/Formatters';
import { TrailerIcon } from '~/components/Icons';
import { ClassificationType } from '~/services/ApiClient';

import type { TrailerInnerProps } from './model';
import { Avatar } from './styles';

const TrailerComponent: FC<TrailerInnerProps> = (props) => {
    const { classes, t, trailerConnection, trailerDisplayNameFormat } = props;
    const formattedTrailerName = trailerConnection ? (
        <TrailerNameFormatter
            trailerDisplayNameFormat={trailerDisplayNameFormat}
            value={trailerConnection.trailer ?? trailerConnection.trailerReference}
        />
    ) : (
        t('not-connected')
    );

    const trailerName = (
        <Typography className={classNames({ [classes.disabledTitle]: !trailerConnection })} data-id="trailer-name">
            {formattedTrailerName}
        </Typography>
    );
    const manufacturer = trailerConnection?.trailer?.trailerManufacturer && (
        <>
            <span className={classes.delimiter}>•</span>
            <span data-id="trailer-manufacturer">
                <ClassificationFormatter
                    classificationKey={ClassificationType.TrailerManufacturer}
                    t={t}
                    value={trailerConnection.trailer.trailerManufacturer}
                />
            </span>
        </>
    );

    const secondary = (
        <>
            <span className={classes.trailerType} data-id="trailer-type">
                {t(trailerConnection?.trailer?.reeferManufacturer ? 'reefer' : 'trailer')}
            </span>
            {manufacturer}
        </>
    );

    return (
        <div>
            <ListItem className={classNames({ [classes.disabled]: !trailerConnection?.trailer })}>
                <ListItemAvatar>
                    <Avatar className={classes.trailerIcon} data-id="trailer-avatar">
                        <TrailerIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={trailerName} secondary={secondary} />
            </ListItem>
        </div>
    );
};

TrailerComponent.displayName = 'TrailerComponent';

export { TrailerComponent };
