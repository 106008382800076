import { Avatar, ListItem, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import { DriverActivityIconFormatter, TimeFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { formatDriverActivity } from '~/services/Formatters';

import type { DriverActivityEntry } from '../../../../../../../../models';

import type { ActivityDetailsListItemClassKey } from './styles';

export interface ActivityDetailsListItemProps {
    activityEntry: DriverActivityEntry;
    className?: string;
}

export interface ActivityDetailsListItemInnerProps
    extends ActivityDetailsListItemProps,
        WithStyles<ActivityDetailsListItemClassKey>,
        InjectedTranslationProps {}

export const ActivityDetailsListItemComponent: React.FC<ActivityDetailsListItemInnerProps> = ({
    activityEntry,
    classes,
    className,
    t,
}) => {
    const activityDuration = moment
        .duration({
            from: activityEntry.startDate,
            to: activityEntry.stopDate,
        })
        .format(`h [${t('unit-hour')}] m __`, { usePlural: false });

    const stopTimeClasses = classNames(classes.stopTime, {
        [classes.stopTimeHidden]: !activityEntry.stopDate,
    });

    const activityAvatarClasses = classNames(
        classes.activityIcon,
        classes[`activity${activityEntry.activityType.key}`]
    );

    /**
     * To make sure that activity time will be aligned with other items
     * from list, render hidden current time for stop time when it's undefined.
     * - Using a fixed width would not work as time format may be different based on the locale;
     * - Trying to calculate the width would be more complex to implement and not that efficient;
     */
    return (
        <ListItem className={classNames(classes.root, className)} data-id={`activity-details:${activityEntry.id}`}>
            <div className={classes.activityTimeAndBadge}>
                <Typography className={classes.activityTime} color="textSecondary" variant="caption">
                    <div className={classes.startTime} data-id="start-time">
                        <TimeFormatter value={activityEntry.startDate} />
                    </div>
                    -
                    <div className={stopTimeClasses} data-id="stop-time">
                        <TimeFormatter value={activityEntry.stopDate || new Date()} />
                    </div>
                </Typography>
                <Avatar className={activityAvatarClasses}>
                    <DriverActivityIconFormatter
                        iconProps={{ fontSize: 'inherit' }}
                        value={activityEntry.activityType.key}
                    />
                </Avatar>
                <Typography data-id="name" variant="caption">
                    {formatDriverActivity(t, activityEntry.activityType)}
                </Typography>
            </div>

            <Typography data-id="duration" variant="caption">
                {activityDuration}
            </Typography>
        </ListItem>
    );
};
