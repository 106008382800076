import type { Dispatch } from 'redux';

import { resetPasswordAction, validateResetPasswordAction } from '~/data/resetpassword';

import type { ResetPasswordStoreState } from '../../reducers';

import type { DispatchProps, StateProps } from './models';

export const mapStateToProps = (resetPasswordState: ResetPasswordStoreState): StateProps => {
    return {
        lastServerResultStatus: resetPasswordState.lastServerResultStatus,
        verifyTokenServerResultStatus: resetPasswordState.verifyTokenServerResultStatus,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    resetPassword: (token: string, password: string) => {
        dispatch(resetPasswordAction(token, password));
    },
    validateSecretToken: (token: string) => {
        dispatch(validateResetPasswordAction(token));
    },
});
