import type { Depot } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    DEPOTS = 'DEPOTS',
}

export enum ActionTypeKeys {
    DEPOTS_FULFILLED = 'DEPOTS_FULFILLED',
    DEPOTS_PENDING = 'DEPOTS_PENDING',
    DEPOTS_REJECTED = 'DEPOTS_REJECTED',
}

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.DEPOTS_FULFILLED, Depot[]>
    | Pending<ActionTypeKeys.DEPOTS_PENDING>
    | Rejected<ActionTypeKeys.DEPOTS_REJECTED>;
