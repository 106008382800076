import moment from 'moment';
import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common/reducers';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import { queryRtdsSessionsAction } from '~/data/rtdssessions';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { retrieveVehicleStatusAction } from '~/data/vehiclestatus';
import { isUndefined } from '~/libs/utility';
import type { RetrievableHashedData } from '~/reducers';
import { reportError } from '~/reporting';
import type {
    AssetReference,
    Driver,
    QueryRtdsSessionsRequestRequestMode,
    Securables,
    Vehicle,
} from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { changeDateTimeRangeAction, clearDataAction } from './data';
import type { RtdsSessionEntry } from './models';
import type { RtdsSessionsUserPreferences } from './preferences';
import { RTDSSESSIONS_USERPREFERENCES_KEY } from './preferences';
import type { RtdsSessionsStoreState } from './reducers';

export interface RtdsSessionsReduxProps
    extends Omit<DispatchProps, 'changeLeftPaneVisibility'>,
        StateProps,
        MergedProps {}

export interface MergedProps {
    toggleLeftPaneVisibility: () => void;
}

export interface StateProps {
    authorized: boolean;
    contentLoading: boolean;
    dateTimeRange: DateTimeRange;
    dateTimeThreshold: { maxDate: Date; minDate: Date };
    leftPaneIsOpen: boolean;
    loading: boolean;
    rejected: boolean;
    rtdsSessionEntries: RtdsSessionEntry[];
    selectedDriverIds?: number[];
    selectedVehicleIds?: number[];
}

const getDefaultDateTimeRange = memoizeOne(() => {
    const endDate = moment().endOf('day').toDate();
    const startDate = moment().startOf('day').toDate();
    return { endDate, startDate };
});

export interface DispatchProps {
    changeDateTimeRange: (value: DateTimeRange) => void;
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void;
    clearData: () => void;
    getRtdsSessions: (
        startDateTime: Date,
        stopDateTime: Date,
        requestMode: QueryRtdsSessionsRequestRequestMode,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) => void;
    getVehicleStatus: () => void;
}

const getSelectedAuthorizedVehicles = memoizeOne(
    (vehicles: RetrievableHashedData<Vehicle>, selectedAssetIds?: AssetReference[]) => {
        let authorizedVehicleIds: number[] | undefined;

        if (vehicles.fulfilled && vehicles.data?.hash) {
            const selectedVehiclesIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Vehicle)
                .map((selectedAsset) => selectedAsset.id);
            authorizedVehicleIds = selectedVehiclesIds?.filter((vehicleId) => {
                const authorizedVehicle = vehicles.data.hash[vehicleId];
                return !isUndefined(authorizedVehicle);
            });
        }

        return authorizedVehicleIds;
    }
);

const getSelectedAuthorizedDrivers = memoizeOne(
    (drivers: RetrievableHashedData<Driver>, selectedAssetIds?: AssetReference[]) => {
        let authorizedDriverIds: number[] | undefined;

        if (drivers.fulfilled && drivers.data?.hash) {
            const selectedDriversIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Driver)
                .map((selectedAsset) => selectedAsset.id);

            authorizedDriverIds = selectedDriversIds?.filter((driverId) => {
                const authorizedDriver = drivers.data.hash[driverId];
                return !isUndefined(authorizedDriver);
            });
        }

        return authorizedDriverIds;
    }
);

export const mapStateToProps = (
    securables: Securables,
    rtdsSessionStoreState: RtdsSessionsStoreState,
    staticDataStoreState: StaticDataStoreState,
    rtdsSessionsUserPreferences: RtdsSessionsUserPreferences,
    rtdsSessionsAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences
): StateProps => {
    const { drivers, vehicles, vehicleStatus } = staticDataStoreState;
    const { dateTimeRange, dynamicRtdsSessions } = rtdsSessionStoreState;

    return {
        authorized: securables.rtds.sessions,
        contentLoading: dynamicRtdsSessions.rtdsSessions.pending,
        dateTimeRange: dateTimeRange ?? getDefaultDateTimeRange(),
        dateTimeThreshold: {
            maxDate: moment().endOf('date').toDate(),
            minDate: moment().startOf('date').subtract(89, 'days').toDate(),
        },
        leftPaneIsOpen: rtdsSessionsUserPreferences.leftPaneIsOpen,
        loading:
            !vehicles.fulfilled ||
            !drivers.fulfilled ||
            !dynamicRtdsSessions.rtdsSessions.fulfilled ||
            !vehicleStatus.fulfilled,
        rejected:
            vehicles.rejected ||
            drivers.rejected ||
            dynamicRtdsSessions.rtdsSessions.rejected ||
            vehicleStatus.rejected,
        rtdsSessionEntries: dynamicRtdsSessions.rtdsSessionsEntries,
        selectedDriverIds: getSelectedAuthorizedDrivers(
            drivers,
            rtdsSessionsAssetSelectorUserPreferences.selectedAssetIds
        ),
        selectedVehicleIds: getSelectedAuthorizedVehicles(
            vehicles,
            rtdsSessionsAssetSelectorUserPreferences.selectedAssetIds
        ),
    };
};

export const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
    changeDateTimeRange: (value: DateTimeRange) => {
        dispatch(changeDateTimeRangeAction(value));
    },
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => {
        dispatch(updateUserPreferencesAction(RTDSSESSIONS_USERPREFERENCES_KEY, { leftPaneIsOpen }));
    },
    clearData: () => {
        dispatch(clearDataAction());
    },
    getRtdsSessions: (
        startDateTime: Date,
        stopDateTime: Date,
        requestMode: QueryRtdsSessionsRequestRequestMode,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) => {
        dispatch(queryRtdsSessionsAction(startDateTime, stopDateTime, requestMode, vehicleIds, driverIds)).catch(
            reportError
        );
    },
    getVehicleStatus: () => {
        dispatch(retrieveVehicleStatusAction()).catch(reportError);
    },
});

const toggleLeftPaneVisibilityMemoized = memoizeOne(
    (changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void, leftPaneIsOpen: boolean) => () => {
        changeLeftPaneVisibility(!leftPaneIsOpen);
    }
);

export const mergeProps = (
    { leftPaneIsOpen, selectedDriverIds, selectedVehicleIds, ...restStateProps }: StateProps,
    { changeLeftPaneVisibility, ...restDispatchProps }: DispatchProps,
    { ...restOwnProps }: object
): RtdsSessionsReduxProps => {
    const leftPaneForceOpen =
        selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0 ? false : leftPaneIsOpen;
    return {
        ...restStateProps,
        ...restDispatchProps,
        ...restOwnProps,
        leftPaneIsOpen: leftPaneForceOpen ? true : leftPaneIsOpen,
        selectedDriverIds,
        selectedVehicleIds,
        toggleLeftPaneVisibility: toggleLeftPaneVisibilityMemoized(changeLeftPaneVisibility, leftPaneForceOpen),
    };
};
