import type { StaticDataStoreState } from '~/common';

import type { UserRolesState } from '../userRoles';

import { ACTIONS } from './actionTypes';
import type { ActionTypes, UsersAdministrationState } from './models';
import { resolveAdministrationUserRead } from './reducers.resolveAdministrationUserRead';

const initialState: UsersAdministrationState = {
    error: false,
    items: [],
    pending: false,
};

const usersAdministrationReducer = (
    state = initialState,
    action: ActionTypes,
    staticDataState: StaticDataStoreState,
    userRolesState: UserRolesState
): UsersAdministrationState => {
    switch (action.type) {
        case ACTIONS.LIST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.LIST_FULFILLED:
            return {
                error: false,
                items: resolveAdministrationUserRead({ staticDataState, userRolesState, users: action.payload.items }),
                pending: false,
            };
        case ACTIONS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        default:
            return state;
    }
};

export { usersAdministrationReducer };
