import type { NumericDictionary } from '~/libs/utility';
import type { Classification } from '~/services/ApiClient';

export interface ReduxProps {
    axles: NumericDictionary<Classification>;
    emissionClasses: NumericDictionary<Classification>;
    vehicleTypeCategories: NumericDictionary<Classification>;
}

export const mapStateToProps = (
    vehicleTypeCategories: NumericDictionary<Classification>,
    axles: NumericDictionary<Classification>,
    emissionClasses: NumericDictionary<Classification>
): ReduxProps => {
    return {
        axles,
        emissionClasses,
        vehicleTypeCategories,
    };
};
