import * as React from 'react';

import { TrailerNameFormatter } from '~/components/Formatters';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type { DisplayUserPreferencesTrailerDisplayFormat } from '~/services/ApiClient';

export interface TrailerMarkerNameFormatterProps {
    entry: MonitoringTrailerEntry;
    trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat;
}

export interface TrailerMarkerNameFormatterInnerProps extends TrailerMarkerNameFormatterProps {}

const TrailerMarkerNameFormatter: React.FC<TrailerMarkerNameFormatterInnerProps> = ({
    entry,
    trailerDisplayNameFormat,
}) => {
    return <TrailerNameFormatter trailerDisplayNameFormat={trailerDisplayNameFormat} value={entry.trailer} />;
};
TrailerMarkerNameFormatter.displayName = 'TrailerMarkerNameFormatter';

export { TrailerMarkerNameFormatter };
