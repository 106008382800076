import type { Dispatch } from 'redux';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, throttle } from '~/libs/utility';
import { reportError } from '~/reporting';
import type {
    ConversationNotification,
    DisplayUserPreferences,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedMessage,
    Securables,
    Vehicle,
    VehicleMessagingCapabilities,
} from '~/services/ApiClient';
import {
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    createApiModel,
} from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import {
    applyPendingUpdateAction,
    changeConversationMessageTextFocusAction,
    clearConversationAction,
    clearSendMessageErrorAction,
    deleteConversationMessageAction,
    discardConversationMessageAction,
    markAllConversationMessagesAsReadAction,
    markConversationMessageAsReadAction,
    markConversationMessageAsUnreadAction,
    retrieveConversationAction,
    retrieveConversationMessagesAction,
    retrieveWorkflowFormDefinitionAction,
    sendConversationMessageAction,
    translateConversationMessageAction,
    undeleteConversationMessageAction,
    updateConversationAction,
} from '../../../../data';
import type { ConversationUserPreferences } from '../../../../preferences';
import { numberOfConversationMessagesToRequestWhenRetrievingOlderMessages } from '../../constants';
import type { ConversationWidgetsUserPreferences } from '../../preferences';
import { CONVERSATIONWIDGETS_USERPREFERENCES_KEY } from '../../preferences';
import type { ConversationWidgetStoreState, PendingMessageStatusUpdate, TranslatedMessagesState } from '../../reducers';

import type { ConversationWidgetProps, ConversationWidgetReduxProps } from './component';

export const autoUpdateThrottleTimeout = 2000;

export interface ConversationWidgetStateProps {
    canDeleteMessages: boolean;
    failedToLoadConversation: boolean;
    failedToLoadOlderMessages: boolean;
    failedToSendMessage: boolean;
    includeDeletedMessages: boolean;
    loadingConversation: boolean;
    loadingOlderMessages: boolean;
    markingAllMessagesAsRead: boolean;
    markMessagesAsReadAllowed: boolean;
    messagesSortedDesc: ResolvedMessage[];
    noOlderMessagesToDisplay: boolean;
    pagingAfterCursor?: string;
    pendingMessagesStatusUpdates: NumericDictionary<PendingMessageStatusUpdate>;
    sendTextMessagesAllowed: boolean;
    sendWorkflowMessagesAllowed: boolean;
    shouldFocusInput: boolean;
    supportTextMessaging: boolean;
    supportWorkflowMessaging: boolean;
    textMessagingEnabled: boolean;
    translatedMessages: TranslatedMessagesState;
    unreadTextMessageCount: number;
    unreadWorkflowMessageCount: number;
    vehicle?: Vehicle;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    warnOnMarkAllMessagesAsRead: boolean;
    workflowMessagingEnabled: boolean;
}

export interface ConversationWidgetDispatchProps {
    changeMinimizedState: (isMinimized: boolean) => void;
    clearConversation: () => void;
    clearSendMessageError: () => void;
    close: () => void;
    deleteMessage: (messageId: number) => void;
    discardMessage: (message: OptimisticOutgoingConversationMessage) => void;
    focusMessageText: () => void;
    getConversation: (includeDeletedMessages: boolean) => void;
    getConversationMessages: (includeDeleted: boolean, count: number, afterCursor: string) => void;
    markAllMessagesAsRead: (lastLoadedConversationMessageId: number, dontWarnMeAnymore?: boolean) => void;
    markIncomingMessageAsRead: (messageId: number) => void;
    markIncomingMessageAsUnread: (messageId: number) => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
    retrySendMessage: (message: OptimisticOutgoingConversationMessage) => void;
    translateConversationMessage: (message: ResolvedMessage, targetLanguage: string) => void;
    undeleteMessage: (messageId: number) => void;
    updateConversation: (conversationNotification: ConversationNotification) => void;
}

export const mapStateToProps = (
    vehicleId: number,
    vehiclesHash: NumericDictionary<Vehicle>,
    conversationWidgetState: ConversationWidgetStoreState | undefined,
    translatedMessages: TranslatedMessagesState,
    securables: Securables,
    vehicleMessagingCapabilities: NumericDictionary<VehicleMessagingCapabilities>,
    displayUserPreferencesState: DisplayUserPreferences,
    conversationWidgetsUserPreferencesState: ConversationWidgetsUserPreferences,
    conversationUserPreferences: ConversationUserPreferences
): ConversationWidgetStateProps => {
    const stateProps: ConversationWidgetStateProps = {
        canDeleteMessages: securables.messaging.deleteMessages,
        failedToLoadConversation: false,
        failedToLoadOlderMessages: false,
        failedToSendMessage: false,
        includeDeletedMessages: false,
        loadingConversation: true,
        loadingOlderMessages: false,
        markingAllMessagesAsRead: false,
        markMessagesAsReadAllowed: securables.messaging.markMessagesAsRead,
        messagesSortedDesc: [],
        noOlderMessagesToDisplay: false,
        pendingMessagesStatusUpdates: {},
        sendTextMessagesAllowed: securables.messaging.sendTextMessage,
        sendWorkflowMessagesAllowed: securables.messaging.sendWorkflowMessage,
        shouldFocusInput: false,
        supportTextMessaging: !!vehicleMessagingCapabilities[vehicleId]?.supportsTextMessages,
        supportWorkflowMessaging: !!vehicleMessagingCapabilities[vehicleId]?.supportsWorkflowMessages,
        textMessagingEnabled: !!vehicleMessagingCapabilities[vehicleId]?.textMessagingEnabled,
        translatedMessages,
        unreadTextMessageCount: 0,
        unreadWorkflowMessageCount: 0,
        vehicle: vehiclesHash[vehicleId],
        vehicleDisplayFormat: displayUserPreferencesState.vehicleDisplayFormat,
        warnOnMarkAllMessagesAsRead: conversationWidgetsUserPreferencesState.warnOnMarkAllMessagesAsRead,
        workflowMessagingEnabled: !!vehicleMessagingCapabilities[vehicleId]?.workflowMessagingEnabled,
    };

    if (conversationWidgetState) {
        stateProps.shouldFocusInput = conversationWidgetState.shouldFocusInput;
        stateProps.failedToSendMessage = !!conversationWidgetState.unacknowledgedFailedMessagesCorrelationIds.length;
        stateProps.markingAllMessagesAsRead = conversationWidgetState.markingAllMessagesAsRead;
        stateProps.pendingMessagesStatusUpdates = conversationWidgetState.pendingMessagesStatusUpdates;
        stateProps.loadingConversation = conversationWidgetState.conversation.pending;
        stateProps.failedToLoadConversation = conversationWidgetState.conversation.rejected;
        stateProps.loadingOlderMessages = conversationWidgetState.loadingOlderMessages;
        stateProps.failedToLoadOlderMessages = conversationWidgetState.failedToLoadOlderMessages;
        stateProps.noOlderMessagesToDisplay = conversationWidgetState.noOlderMessagesToDisplay;
        stateProps.pagingAfterCursor = conversationWidgetState.pagingAfterCursor;

        if (conversationWidgetState.resolvedConversation) {
            stateProps.unreadTextMessageCount = conversationWidgetState.resolvedConversation.unreadTextMessageCount;
            stateProps.unreadWorkflowMessageCount =
                conversationWidgetState.resolvedConversation.unreadWorkflowMessageCount;
            stateProps.messagesSortedDesc = conversationWidgetState.resolvedConversation.messages;
        }

        stateProps.includeDeletedMessages =
            securables.messaging.viewDeletedMessages && conversationUserPreferences.showDeletedMessagesInConversations;
    }

    return stateProps;
};

export interface ConversationWidgetPropsReduxOwnProps extends ConversationWidgetProps, InjectedTranslationProps {}

export const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: ConversationWidgetPropsReduxOwnProps
): ConversationWidgetDispatchProps => {
    const refreshThrottled = throttle(
        () => dispatch(applyPendingUpdateAction(ownProps.vehicleId)),
        autoUpdateThrottleTimeout
    );

    return {
        changeMinimizedState: (isMinimized: boolean) => {
            dispatch(
                updateUserPreferencesAction(CONVERSATIONWIDGETS_USERPREFERENCES_KEY, {
                    openedConversations: [
                        {
                            isMinimized,
                            vehicleId: ownProps.vehicleId,
                        },
                    ],
                })
            );
        },
        clearConversation: () => {
            dispatch(clearConversationAction(ownProps.vehicleId));
        },
        clearSendMessageError: () => {
            dispatch(clearSendMessageErrorAction(ownProps.vehicleId));
        },
        close: () => {
            dispatch(updateUserPreferencesAction(CONVERSATIONWIDGETS_USERPREFERENCES_KEY, { openedConversations: [] }));
        },
        deleteMessage: (messageId: number) => {
            dispatch(deleteConversationMessageAction(ownProps.vehicleId, messageId)).catch(reportError);
        },
        discardMessage: (message: OptimisticOutgoingConversationMessage) => {
            dispatch(discardConversationMessageAction(ownProps.vehicleId, message));
        },
        focusMessageText: () => {
            dispatch(changeConversationMessageTextFocusAction(ownProps.vehicleId, true));
        },
        getConversation: (includeDeleted: boolean) => {
            dispatch(
                retrieveConversationAction(ownProps.vehicleId, ownProps.userConversationProfileId, includeDeleted)
            ).catch(reportError);
        },
        getConversationMessages: (includeDeleted: boolean, count: number, afterCursor: string) => {
            dispatch(
                retrieveConversationMessagesAction(
                    ownProps.vehicleId,
                    ownProps.userConversationProfileId,
                    count,
                    afterCursor,
                    includeDeleted
                )
            ).catch(reportError);
        },
        markAllMessagesAsRead: (lastLoadedConversationMessageId: number, dontWarnMeAnymore?: boolean) => {
            dispatch(
                markAllConversationMessagesAsReadAction(
                    ownProps.vehicleId,
                    ownProps.userConversationProfileId,
                    lastLoadedConversationMessageId
                )
            ).catch(reportError);
            if (!isUndefined(dontWarnMeAnymore)) {
                dispatch(
                    updateUserPreferencesAction(CONVERSATIONWIDGETS_USERPREFERENCES_KEY, {
                        warnOnMarkAllMessagesAsRead: !dontWarnMeAnymore,
                    })
                );
            }
        },
        markIncomingMessageAsRead: (messageId: number) => {
            dispatch(markConversationMessageAsReadAction(ownProps.vehicleId, messageId)).catch(reportError);
        },
        markIncomingMessageAsUnread: (messageId: number) => {
            dispatch(markConversationMessageAsUnreadAction(ownProps.vehicleId, messageId)).catch(reportError);
        },
        retrieveWorkflowFormDefinition: (formId: number) => {
            dispatch(retrieveWorkflowFormDefinitionAction(formId, ownProps.i18n.language));
        },
        retrySendMessage: (message: OptimisticOutgoingConversationMessage) => {
            const toBeSendMessage: OptimisticOutgoingConversationMessage = createApiModel(
                OptimisticOutgoingConversationMessage,
                {
                    ...message,
                    dateTime: new Date(),
                    status: OptimisticOutgoingConversationMessageStatus.Sending,
                }
            );
            dispatch(discardConversationMessageAction(ownProps.vehicleId, message));
            dispatch(sendConversationMessageAction(ownProps.vehicleId, toBeSendMessage)).catch(reportError);
        },
        translateConversationMessage: (message: ResolvedMessage, targetLanguage: string) => {
            dispatch(translateConversationMessageAction(message, targetLanguage)).catch(reportError);
        },
        undeleteMessage: (messageId: number) => {
            dispatch(undeleteConversationMessageAction(ownProps.vehicleId, messageId)).catch(reportError);
        },
        updateConversation: (conversationNotification: ConversationNotification) => {
            dispatch(updateConversationAction(conversationNotification));
            refreshThrottled();
        },
    };
};

const toggleMinimizedStateMemoized = memoizeOne(
    (isMinimized: boolean, changeMinimizedState: (isMinimized: boolean) => void) => () => {
        changeMinimizedState(!isMinimized);
    }
);

const markAllMessagesAsReadMemoized = memoizeOne(
    (
        messagesSortedDesc: ResolvedMessage[],
        markAllMessagesAsRead: (lastLoadedConversationMessageId: number, dontWarnMeAnymore?: boolean) => void
    ) => {
        return (dontWarnMeAnymore?: boolean) => {
            const latestMessageId = messagesSortedDesc[0]?.value.id;
            if (isUndefined(latestMessageId)) {
                return;
            }

            markAllMessagesAsRead(latestMessageId, dontWarnMeAnymore);
        };
    }
);

const loadOlderMessagesMemoized = memoizeOne(
    (
        getConversationMessages: (includeDeletedMessages: boolean, count: number, afterCursor?: string) => void,
        includeDeletedMessages: boolean,
        afterCursor?: string
    ) => {
        if (!afterCursor) {
            return () => {
                /* Intentionally empty */
            };
        }

        return () => {
            getConversationMessages(
                includeDeletedMessages,
                numberOfConversationMessagesToRequestWhenRetrievingOlderMessages,
                afterCursor
            );
        };
    }
);

const getConversationMemoized = memoizeOne(
    (getConversation: (includeDeletedMessages: boolean) => void, includeDeletedMessages: boolean) => {
        return () => {
            getConversation(includeDeletedMessages);
        };
    }
);

export const mergeProps = (
    { includeDeletedMessages, pagingAfterCursor, ...restStateProps }: ConversationWidgetStateProps,
    {
        changeMinimizedState,
        getConversation,
        getConversationMessages,
        markAllMessagesAsRead,
        ...restDispatchProps
    }: ConversationWidgetDispatchProps,
    ownProps: ConversationWidgetProps
): ConversationWidgetReduxProps => {
    return {
        ...restStateProps,
        ...restDispatchProps,
        ...ownProps,
        getConversation: getConversationMemoized(getConversation, includeDeletedMessages),
        includeDeletedMessages,
        loadOlderMessages: loadOlderMessagesMemoized(
            getConversationMessages,
            includeDeletedMessages,
            pagingAfterCursor
        ),
        markAllMessagesAsRead: markAllMessagesAsReadMemoized(restStateProps.messagesSortedDesc, markAllMessagesAsRead),
        toggleMinimizedState: toggleMinimizedStateMemoized(ownProps.isMinimized, changeMinimizedState),
    };
};
