import { DurationFormatter } from '@fv/components';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import {
    AddressFormatter,
    DateTimeFormatter,
    DistanceFormatter,
    DriverNameFormatter,
    VehicleNameFormatter,
} from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';
import { AddressFormat, formatDriverActivity } from '~/services/Formatters';

import type { GraphSeries } from '../../models';
import { DriverActivitiesReportPerspective } from '../../models';

import { useStyles } from './styles';

export interface ChartTooltipProps {
    displayPreferences: DisplayUserPreferences;
    payload: GraphSeries;
    perspective: DriverActivitiesReportPerspective;
}

const ChartTooltip: FC<ChartTooltipProps> = (props) => {
    const { displayPreferences, payload, perspective } = props;
    const { meta } = payload;

    const classes = useStyles();
    const { t } = useTranslation();

    if (isUndefined(meta)) {
        return null;
    }

    const showVehicle = perspective !== DriverActivitiesReportPerspective.Vehicle;
    const showDriver = perspective !== DriverActivitiesReportPerspective.Driver;

    return (
        <Paper className={classes.root} elevation={5}>
            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-start-date')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    <DateTimeFormatter value={meta.startDateTime} />
                </Typography>
            </div>

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-stop-date')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    <DateTimeFormatter value={meta.stopDateTime} />
                </Typography>
            </div>

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-duration')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    <DurationFormatter value={meta.duration} />
                </Typography>
            </div>

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-activity')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    {formatDriverActivity(t, meta.activityType)}
                </Typography>
            </div>

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-sub-activity')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    {meta.subActivity?.name}
                </Typography>
            </div>

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-distance')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    <DistanceFormatter t={t} unitSystem={displayPreferences.unitSystem} value={meta.distance} />
                </Typography>
            </div>

            {showVehicle && (
                <div className={classes.valueRow}>
                    <Typography className={classes.label} color="textSecondary" variant="body2">
                        {t('driver-activity-report-tooltip-vehicle')}
                    </Typography>
                    <Typography className={classes.value} variant="body2">
                        <VehicleNameFormatter
                            value={meta.vehicle}
                            vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat}
                        />
                    </Typography>
                </div>
            )}

            {showDriver && (
                <div className={classes.valueRow}>
                    <Typography className={classes.label} color="textSecondary" variant="body2">
                        {t('driver-activity-report-tooltip-driver')}
                    </Typography>
                    <Typography className={classes.value} variant="body2">
                        <DriverNameFormatter
                            displayNameFormat={displayPreferences.driverDisplayFormat}
                            value={meta.driver}
                        />
                    </Typography>
                </div>
            )}

            <div className={classes.valueRow}>
                <Typography className={classes.label} color="textSecondary" variant="body2">
                    {t('driver-activity-report-tooltip-location')}
                </Typography>
                <Typography className={classes.value} variant="body2">
                    <AddressFormatter options={{ format: AddressFormat.SecondLine }} value={meta.address} />
                </Typography>
            </div>
        </Paper>
    );
};

ChartTooltip.displayName = 'ChartTooltip';
export { ChartTooltip };
