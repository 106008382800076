import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const HISTORY_LIST_TRAILERS_USERPREFERENCES_KEY = 'history-grid-trailer-state';

export const defaultTrailersListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.TRAILEREVENTDATE,
        ColumnName.TRAILER,
        ColumnName.DEVICETYPE,
        ColumnName.MSISDN,
        ColumnName.VEHICLE,
        ColumnName.ISMANUALLYCONNECTED,
        ColumnName.EVENTTYPE,
        ColumnName.CITY,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.NEARESTCITY,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.DOOR1SENSOR,
        ColumnName.DOOR2SENSOR,
        ColumnName.DOOR1SEALCODE,
        ColumnName.DOOR2SEALCODE,
        ColumnName.DOOR1TIMESTAMP,
        ColumnName.DOOR2TIMESTAMP,
        ColumnName.BATTERYVOLTAGETRAILER,
        ColumnName.BATTERYLEVELTRAILER,
        ColumnName.BATTERYSTATUSTRAILER,
        ColumnName.BATTERYVOLTAGEREEFER,
        ColumnName.FUELLEVELREEFER,
        ColumnName.TOTALENGINEHOURSREEFER,
        ColumnName.REEFERALARMS,
        ColumnName.REEFERALARMSMANUFACTURER,
        ColumnName.REEFEROPERATIONMODE,
        ColumnName.REEFERPOWERMODE,
        ColumnName.REEFERSPEED,
        ColumnName.REEFERSTATUS,
        ColumnName.HOOKED,
        ColumnName.EBSTIMESTAMP,
        ColumnName.EBSMILEAGE,
        ColumnName.AXLELOAD,

        ColumnName.COMPARTMENT1STATUS,
        ColumnName.COMPARTMENT1STATUSMANUFACTURER,
        ColumnName.COMPARTMENT1SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT1RETURNTEMPERATURE,
        ColumnName.COMPARTMENT1SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR4,
        ColumnName.COMPARTMENT1MINBANDWITHTEMP,
        ColumnName.COMPARTMENT1MAXBANDWITHTEMP,

        ColumnName.COMPARTMENT2STATUS,
        ColumnName.COMPARTMENT2STATUSMANUFACTURER,
        ColumnName.COMPARTMENT2SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT2RETURNTEMPERATURE,
        ColumnName.COMPARTMENT2SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR4,
        ColumnName.COMPARTMENT2MINBANDWITHTEMP,
        ColumnName.COMPARTMENT2MAXBANDWITHTEMP,

        ColumnName.COMPARTMENT3STATUS,
        ColumnName.COMPARTMENT3STATUSMANUFACTURER,
        ColumnName.COMPARTMENT3SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT3RETURNTEMPERATURE,
        ColumnName.COMPARTMENT3SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR4,
        ColumnName.COMPARTMENT3MINBANDWITHTEMP,
        ColumnName.COMPARTMENT3MAXBANDWITHTEMP,

        ColumnName.COMPARTMENT4STATUS,
        ColumnName.COMPARTMENT4STATUSMANUFACTURER,
        ColumnName.COMPARTMENT4SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT4RETURNTEMPERATURE,
        ColumnName.COMPARTMENT4SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR4,
        ColumnName.COMPARTMENT4MINBANDWITHTEMP,
        ColumnName.COMPARTMENT4MAXBANDWITHTEMP,
    ],
    columnWidths: [
        { columnName: ColumnName.TRAILEREVENTDATE, width: 200 },
        { columnName: ColumnName.TRAILER, width: 150 },
        { columnName: ColumnName.DEVICETYPE, width: 75 },
        { columnName: ColumnName.MSISDN, width: 100 },
        { columnName: ColumnName.VEHICLE, width: 200 },
        { columnName: ColumnName.ISMANUALLYCONNECTED, width: 75 },

        { columnName: ColumnName.EVENTTYPE, width: 150 },
        { columnName: ColumnName.CITY, width: 100 },
        { columnName: ColumnName.ADDRESS, width: 100 },
        { columnName: ColumnName.POSTALCODE, width: 100 },
        { columnName: ColumnName.COUNTRYCODE, width: 100 },
        { columnName: ColumnName.NEARESTCITY, width: 100 },
        { columnName: ColumnName.LATITUDE, width: 100 },
        { columnName: ColumnName.LONGITUDE, width: 100 },
        { columnName: ColumnName.DOOR1SENSOR, width: 75 },
        { columnName: ColumnName.DOOR2SENSOR, width: 75 },
        { columnName: ColumnName.DOOR1SEALCODE, width: 75 },
        { columnName: ColumnName.DOOR2SEALCODE, width: 75 },
        { columnName: ColumnName.DOOR1TIMESTAMP, width: 75 },
        { columnName: ColumnName.DOOR2TIMESTAMP, width: 75 },
        { columnName: ColumnName.BATTERYVOLTAGETRAILER, width: 75 },
        { columnName: ColumnName.BATTERYLEVELTRAILER, width: 75 },
        { columnName: ColumnName.BATTERYSTATUSTRAILER, width: 75 },
        { columnName: ColumnName.BATTERYVOLTAGEREEFER, width: 75 },
        { columnName: ColumnName.FUELLEVELREEFER, width: 75 },
        { columnName: ColumnName.TOTALENGINEHOURSREEFER, width: 75 },
        { columnName: ColumnName.REEFERALARMS, width: 75 },
        { columnName: ColumnName.REEFERALARMSMANUFACTURER, width: 75 },
        { columnName: ColumnName.REEFEROPERATIONMODE, width: 75 },
        { columnName: ColumnName.REEFERPOWERMODE, width: 75 },
        { columnName: ColumnName.REEFERSPEED, width: 75 },
        { columnName: ColumnName.REEFERSTATUS, width: 75 },
        { columnName: ColumnName.HOOKED, width: 75 },
        { columnName: ColumnName.EBSTIMESTAMP, width: 100 },
        { columnName: ColumnName.EBSMILEAGE, width: 75 },
        { columnName: ColumnName.AXLELOAD, width: 75 },

        { columnName: ColumnName.COMPARTMENT1STATUS, width: 75 },
        { columnName: ColumnName.COMPARTMENT1STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.COMPARTMENT1SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT1RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT1SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT1TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.COMPARTMENT1TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.COMPARTMENT1TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.COMPARTMENT1TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.COMPARTMENT1MINBANDWITHTEMP, width: 75 },
        { columnName: ColumnName.COMPARTMENT1MAXBANDWITHTEMP, width: 75 },

        { columnName: ColumnName.COMPARTMENT2STATUS, width: 75 },
        { columnName: ColumnName.COMPARTMENT2STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.COMPARTMENT2SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT2RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT2SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT2TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.COMPARTMENT2TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.COMPARTMENT2TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.COMPARTMENT2TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.COMPARTMENT2MINBANDWITHTEMP, width: 75 },
        { columnName: ColumnName.COMPARTMENT2MAXBANDWITHTEMP, width: 75 },

        { columnName: ColumnName.COMPARTMENT3STATUS, width: 75 },
        { columnName: ColumnName.COMPARTMENT3STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.COMPARTMENT3SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT3RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT3SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT3TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.COMPARTMENT3TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.COMPARTMENT3TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.COMPARTMENT3TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.COMPARTMENT3MINBANDWITHTEMP, width: 75 },
        { columnName: ColumnName.COMPARTMENT3MAXBANDWITHTEMP, width: 75 },

        { columnName: ColumnName.COMPARTMENT4STATUS, width: 75 },
        { columnName: ColumnName.COMPARTMENT4STATUSMANUFACTURER, width: 75 },
        { columnName: ColumnName.COMPARTMENT4SUPPLYTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT4RETURNTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT4SETPOINTTEMPERATURE, width: 75 },
        { columnName: ColumnName.COMPARTMENT4TEMPERATURESENSOR1, width: 75 },
        { columnName: ColumnName.COMPARTMENT4TEMPERATURESENSOR2, width: 75 },
        { columnName: ColumnName.COMPARTMENT4TEMPERATURESENSOR3, width: 75 },
        { columnName: ColumnName.COMPARTMENT4TEMPERATURESENSOR4, width: 75 },
        { columnName: ColumnName.COMPARTMENT4MINBANDWITHTEMP, width: 75 },
        { columnName: ColumnName.COMPARTMENT4MAXBANDWITHTEMP, width: 75 },
    ],
    filteringEnabled: true,
    filters: [],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    sorting: [{ columnName: ColumnName.TRAILER, direction: GridSortingDirection.ASC }],
    visibleColumns: [
        ColumnName.TRAILEREVENTDATE,
        ColumnName.TRAILER,
        ColumnName.VEHICLE,
        ColumnName.ISMANUALLYCONNECTED,
        ColumnName.EVENTTYPE,
        ColumnName.CITY,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.NEARESTCITY,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.DOOR1SENSOR,
        ColumnName.DOOR2SENSOR,
        ColumnName.DOOR1SEALCODE,
        ColumnName.DOOR2SEALCODE,
        ColumnName.DOOR1TIMESTAMP,
        ColumnName.DOOR2TIMESTAMP,
        ColumnName.BATTERYVOLTAGETRAILER,
        ColumnName.BATTERYLEVELTRAILER,
        ColumnName.BATTERYSTATUSTRAILER,
        ColumnName.BATTERYVOLTAGEREEFER,
        ColumnName.FUELLEVELREEFER,
        ColumnName.TOTALENGINEHOURSREEFER,
        ColumnName.REEFERALARMS,
        ColumnName.REEFERALARMSMANUFACTURER,
        ColumnName.REEFEROPERATIONMODE,
        ColumnName.REEFERPOWERMODE,
        ColumnName.REEFERSPEED,
        ColumnName.REEFERSTATUS,
        ColumnName.HOOKED,
        ColumnName.EBSTIMESTAMP,
        ColumnName.EBSMILEAGE,
        ColumnName.AXLELOAD,

        ColumnName.COMPARTMENT1STATUS,
        ColumnName.COMPARTMENT1STATUSMANUFACTURER,
        ColumnName.COMPARTMENT1SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT1RETURNTEMPERATURE,
        ColumnName.COMPARTMENT1SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT1TEMPERATURESENSOR4,

        ColumnName.COMPARTMENT2STATUS,
        ColumnName.COMPARTMENT2STATUSMANUFACTURER,
        ColumnName.COMPARTMENT2SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT2RETURNTEMPERATURE,
        ColumnName.COMPARTMENT2SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT2TEMPERATURESENSOR4,

        ColumnName.COMPARTMENT3STATUS,
        ColumnName.COMPARTMENT3STATUSMANUFACTURER,
        ColumnName.COMPARTMENT3SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT3RETURNTEMPERATURE,
        ColumnName.COMPARTMENT3SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT3TEMPERATURESENSOR4,

        ColumnName.COMPARTMENT4STATUS,
        ColumnName.COMPARTMENT4STATUSMANUFACTURER,
        ColumnName.COMPARTMENT4SUPPLYTEMPERATURE,
        ColumnName.COMPARTMENT4RETURNTEMPERATURE,
        ColumnName.COMPARTMENT4SETPOINTTEMPERATURE,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR1,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR2,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR3,
        ColumnName.COMPARTMENT4TEMPERATURESENSOR4,
    ],
};
