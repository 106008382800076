import { entryEquals } from '~/common';
import type {
    MonitoringContainerEntry,
    MonitoringDriverEntry,
    MonitoringTrailerEntry,
    MonitoringVehicleEntry,
} from '~/data/monitoring';

const monitoringContainerEntryEquals = (a?: MonitoringContainerEntry, b?: MonitoringContainerEntry) =>
    entryEquals({ a, b, paths: ['status.revision'] });

const monitoringTrailerEntryEquals = (a?: MonitoringTrailerEntry, b?: MonitoringTrailerEntry) =>
    entryEquals({
        a,
        b,
        paths: [
            'status.revision',
            'vehicleConnection.vehicleReference.id',
            'vehicleConnection.vehicleDriverHoursStatus.revision',
        ],
    });

const monitoringVehicleEntryEquals = (a?: MonitoringVehicleEntry, b?: MonitoringVehicleEntry) =>
    entryEquals({
        a,
        b,
        paths: [
            'inhibitorStatus.revision',
            'vehicleDriverHoursStatus.revision',
            'trailerConnection.trailerReference.id',
            'trailerConnection.trailerStatus.revision',
            'routeStatus.revision',
            'vehicleStatus.revision',
        ],
    });

const monitoringDriverEntryEquals = (a?: MonitoringDriverEntry, b?: MonitoringDriverEntry) =>
    entryEquals({ a, b, paths: ['status.driver', 'status.location.dateTime'] });

export {
    monitoringContainerEntryEquals,
    monitoringDriverEntryEquals,
    monitoringTrailerEntryEquals,
    monitoringVehicleEntryEquals,
};
