import type { CompanyCard, GetAdminCompanyCardsResponse } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export interface MessageActionTypeMeta {
    messageId: number;
}

export const COMPANYCARD_ACTION_TYPE = {
    DELETE: 'DELETE_COMPANYCARD',
    GET: 'GET_COMPANYCARD',
} as const;

export enum ActionTypeKeys {
    COMPANYCARD_FULFILLED = 'COMPANYCARD_FULFILLED',
    COMPANYCARD_PENDING = 'COMPANYCARD_PENDING',
    COMPANYCARD_REJECTED = 'COMPANYCARD_REJECTED',
}

export const ACTION_TYPE_KEYS = {
    LIST_FULFILLED: `${COMPANYCARD_ACTION_TYPE.GET}_FULFILLED`,
    LIST_PENDING: `${COMPANYCARD_ACTION_TYPE.GET}_PENDING`,
    LIST_REJECTED: `${COMPANYCARD_ACTION_TYPE.GET}_REJECTED`,
    RESET: COMPANYCARD_ACTION_TYPE.DELETE,
} as const;

export type ActionTypes =
    | FulfilledMeta<ActionTypeKeys.COMPANYCARD_FULFILLED, Readonly<GetAdminCompanyCardsResponse>, CompanyCard>
    | PendingMeta<ActionTypeKeys.COMPANYCARD_PENDING, CompanyCard>
    | RejectedMeta<ActionTypeKeys.COMPANYCARD_REJECTED, undefined, CompanyCard>;
