import type { Dispatch } from 'redux';

import {
    createAdminVehicleAction,
    updateAdminVehicleAction,
    updateAdminVehicleResetAction,
} from '~/data/vehicleadministration';
import type { NumericDictionary } from '~/libs/utility';
import { reportError } from '~/reporting';
import type {
    AdminVehicleEdit,
    Classification,
    CompanyCard,
    DeviceType,
    ResolvedAdminVehicleRead,
    ServerResultStatus,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';

import type { AdminVehicleStoreState } from '../../reducers';

export interface VehicleEditorDialogReduxProps extends Omit<DispatchProps, ''>, StateProps {}

export interface StateProps {
    categories: NumericDictionary<VehicleCategory>;
    companyCards: NumericDictionary<CompanyCard>;
    devicesTypes: NumericDictionary<DeviceType>;
    existingVehicles: ResolvedAdminVehicleRead[];
    fuelTankTypes: NumericDictionary<Classification>;
    imageCapturings: NumericDictionary<Classification>;
    navigations: NumericDictionary<Classification>;
    startInhibitors: NumericDictionary<Classification>;
    tachoGenerations: NumericDictionary<Classification>;
    tachographs: NumericDictionary<Classification>;
    tachoProtocols: NumericDictionary<Classification>;
    tellTalesServices: NumericDictionary<Classification>;
    updateVehicleServerResultStatus?: ServerResultStatus;
    vehicleTypes: NumericDictionary<VehicleType>;
    workflows: NumericDictionary<Classification>;
}

export const mapStateToProps = (
    vehicleAdministrationEditStoreState: AdminVehicleStoreState,
    categories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>,
    tachographs: NumericDictionary<Classification>,
    tachoProtocols: NumericDictionary<Classification>,
    tachoGenerations: NumericDictionary<Classification>,
    workflows: NumericDictionary<Classification>,
    navigations: NumericDictionary<Classification>,
    imageCapturings: NumericDictionary<Classification>,
    startInhibitors: NumericDictionary<Classification>,
    tellTalesServices: NumericDictionary<Classification>,
    companyCards: NumericDictionary<CompanyCard>,
    devicesTypes: NumericDictionary<DeviceType>,
    fuelTankTypes: NumericDictionary<Classification>
): StateProps => ({
    categories,
    companyCards,
    devicesTypes,
    existingVehicles: vehicleAdministrationEditStoreState.adminVehicles.data.array,
    fuelTankTypes,
    imageCapturings,
    navigations,
    startInhibitors,
    tachoGenerations,
    tachographs,
    tachoProtocols,
    tellTalesServices,
    updateVehicleServerResultStatus: vehicleAdministrationEditStoreState.updateVehicleServerResultStatus,
    vehicleTypes,
    workflows,
});

export interface DispatchProps {
    createAdminVehicle: (item: AdminVehicleEdit) => void;
    updateAdminVehicle: (vehicleId: number, item: AdminVehicleEdit) => void;
    updateAdminVehicleReset: () => void;
}

export const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
    createAdminVehicle: (item: AdminVehicleEdit) => {
        dispatch(createAdminVehicleAction(item)).catch(reportError);
    },
    updateAdminVehicle: (vehicleId: number, item: AdminVehicleEdit) => {
        dispatch(updateAdminVehicleAction(vehicleId, item)).catch(reportError);
    },
    updateAdminVehicleReset: () => {
        dispatch(updateAdminVehicleResetAction());
    },
});

export const mergeProps = (
    { ...restStateProps }: StateProps,
    { updateAdminVehicle, updateAdminVehicleReset, ...restDispatchProps }: DispatchProps
): VehicleEditorDialogReduxProps => {
    return {
        ...restStateProps,
        ...restDispatchProps,
        updateAdminVehicle,
        updateAdminVehicleReset,
    };
};
