import { Checkbox, FormControlLabel } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { BooleanFieldWorkflowFormElement } from '~/services/ApiClient';

import type { CheckboxWorkflowFieldClassKey } from './styles';

export interface CheckboxWorkflowFieldProps {
    dataId?: string;
    disabled?: boolean;
    field: BooleanFieldWorkflowFormElement;

    label?: string;
}

export interface CheckboxWorkflowFieldInnerProps
    extends CheckboxWorkflowFieldProps,
        WithStyles<CheckboxWorkflowFieldClassKey> {}

export const CheckboxWorkflowFieldComponent: React.FC<CheckboxWorkflowFieldInnerProps> = ({
    classes,
    dataId,
    disabled,
    field,
    label,
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue={false}
            name={`${field.id}`}
            render={({ field: fieldFormProps }) => (
                <FormControlLabel
                    classes={{ label: classes.label }}
                    control={
                        <Checkbox
                            {...fieldFormProps}
                            checked={fieldFormProps.value}
                            data-id={dataId}
                            disabled={field.isReadOnly || disabled}
                            onChange={(e) => {
                                fieldFormProps.onChange(e.target.checked);
                            }}
                        />
                    }
                    label={label}
                    labelPlacement="end"
                />
            )}
            rules={{ required: field.isRequired }}
        />
    );
};
