import type { ClassificationAbstraction } from '~/services/ApiClient';

import type { OptionItem } from './Option/models';

export const classificationAbstractionToOptionInput = (
    e: ClassificationAbstraction,
    branded?: boolean
): OptionItem => ({
    branded,
    displayName: e.displayName,
    id: e.id as string,
    key: e.key,
    values: e?.rowValues,
});
