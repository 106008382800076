import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { Subtract } from 'utility-types';

export interface CustomLinkProps extends Subtract<LinkProps, { location?: unknown; replace?: boolean }> {}

export interface CustomLinkInnerProps extends RouteComponentProps, CustomLinkProps {}

export const CustomLinkComponent: React.FunctionComponent<CustomLinkInnerProps> = ({
    history,
    location,
    match,
    staticContext,
    to,
    ...restProps
}) => {
    const shouldUseReplace = history.location.pathname === to;

    return <Link {...restProps} replace={shouldUseReplace} to={to} />;
};
