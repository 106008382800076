import { createSelector } from 'reselect';

import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';

import { GROUP_ADMINISTRATION_SCENE } from './consts';
import { formatAssetGroups } from './utils';

const getAssetGroupsAdminState = (state: StoreState) => state.assetGroupAdminScene;

const getAssetGroupsAdminStateFiltered = createSelector(
    getAssetGroupsAdminState,
    keyDataStateSelectorFactory<{ selectedIds: (number | string)[] }>(GROUP_ADMINISTRATION_SCENE),
    staticDataStoreStateSelector,
    (state, userPreferences, staticData) => {
        const { selectedIds } = userPreferences;
        const { assetGroups } = staticData;

        return {
            ...state,
            assetGroups: formatAssetGroups(assetGroups.data),
            filtered: selectedIds && state.items.filter((item) => selectedIds.includes(item.id)),
        };
    }
);

interface AssetGroupAdminEditorStateSelectorArgs {
    assetGroupId?: number;
    parentAssetGroupId?: number;
}

const getAssetGroupAdminEditorState = createSelector(
    getAssetGroupsAdminState,
    (_: StoreState, args: AssetGroupAdminEditorStateSelectorArgs) => args,
    (state, { assetGroupId, parentAssetGroupId }) => ({
        assetGroup: isUndefined(assetGroupId) ? undefined : state.items.find((x) => x.id === assetGroupId),
        assetGroupNames: state.items.filter((x) => x.id !== assetGroupId).map((x) => x.name),
        parentAssetGroup: isUndefined(parentAssetGroupId)
            ? undefined
            : state.items.find((x) => x.id === parentAssetGroupId),
    })
);

const createAssetGroupAdminEditorStateSelector =
    (args: AssetGroupAdminEditorStateSelectorArgs) => (state: StoreState) =>
        getAssetGroupAdminEditorState(state, args);

export { createAssetGroupAdminEditorStateSelector, getAssetGroupsAdminState, getAssetGroupsAdminStateFiltered };
