import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import { TimeFormatter } from '~/components/Formatters';

import { graphDurationInSeconds } from '../../constants';
import type { Cursor } from '../../models';
import { zoomPopupWidth } from '../../styles';

import type { TicksClassKey } from './styles';

export interface TicksProps {
    cursor: Cursor;
}

export interface TicksInnerProps extends TicksProps, WithStyles<TicksClassKey> {}

const getFifteenthMinuteTickTime = (startDate: Date, tickNumber: number): Date =>
    moment(startDate).add(tickNumber, 'm').toDate();

export const TicksComponent: React.FC<TicksInnerProps> = ({ classes, cursor }) => {
    const cursorDurationInSeconds = moment(cursor.stop).diff(moment(cursor.start), 'seconds');
    const graphEnd = moment(cursor.start)
        .add(graphDurationInSeconds / 2 + cursorDurationInSeconds / 2, 'seconds')
        .toDate();

    const startDate: Date = moment(graphEnd).subtract(graphDurationInSeconds, 'seconds').toDate();

    const startDateMinute: number = startDate.getMinutes();
    const ticks: JSX.Element[] = [];

    for (let tick = 0; tick < graphDurationInSeconds / 60; tick++) {
        const tickClasses = classNames(
            classes.tick,
            { [classes.fiveMinuteTick]: (startDateMinute + tick) % 5 === 0 },
            { [classes.fifteenMinuteTick]: (startDateMinute + tick) % 15 === 0 }
        );

        const tickLabel = (startDateMinute + tick) % 15 === 0 && (
            <Typography className={classes.tickLabel} color="textSecondary" variant="caption">
                <TimeFormatter value={getFifteenthMinuteTickTime(startDate, tick)} />
            </Typography>
        );

        const currentTick = (
            <div
                className={tickClasses}
                key={tick}
                style={{ left: (zoomPopupWidth / (graphDurationInSeconds / 60)) * tick }}
            >
                {tickLabel}
            </div>
        );

        ticks.push(currentTick);
    }

    return <div className={classes.root}>{ticks}</div>;
};
