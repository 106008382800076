import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type LocationSectionClassKeys = 'additionalInfo' | 'buttonsListItem' | 'delimiter' | 'infoIcon';

export const useStyles = makeStyles<Theme, {}, LocationSectionClassKeys>((theme: Theme) => ({
    additionalInfo: {
        alignItems: 'center',
        display: 'flex',
        lineHeight: '14px',
        marginLeft: '55px',
        marginTop: theme.spacing(),
    },
    buttonsListItem: {
        marginTop: theme.spacing(2),
    },
    delimiter: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    infoIcon: {
        fontSize: 14,
        marginRight: theme.spacing(0.5),
    },
}));
