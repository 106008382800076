import type { LayersControlEvent } from 'leaflet';
import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureGroup, LayersControl } from 'react-leaflet';

import { MAP_MODES } from '../../consts';
import { useMapContext } from '../../context';
import { MapEventControls } from '../MapEventControls';
import { MapGatewayLayer } from '../MapGatewayLayer';

import type { BaseLayerPickerProps } from './models';

const BaseLayerPicker: FC<BaseLayerPickerProps> = (props) => {
    const { baseLayer, onBaseLayerChange } = props;

    const { t } = useTranslation();
    const [currentBaseLayer, setCurrentCurrentBaseLayer] = useState(baseLayer);

    const { availableModes } = useMapContext();

    const availableBaseLayers = availableModes.map((mapMode) => ({
        mapMode,
        name: mapMode === MAP_MODES.SATELLITE ? t('satellite') : mapMode === MAP_MODES.HYBRID ? t('hybrid') : t('map'),
    }));

    const handleBaseLayerChanged = (evt: LayersControlEvent) => {
        const selectedBaseLayer = availableBaseLayers.find((x) => x.name === evt.name);
        if (selectedBaseLayer) {
            const newBaseLayer = { ...currentBaseLayer, mapMode: selectedBaseLayer.mapMode };
            setCurrentCurrentBaseLayer(newBaseLayer);
            onBaseLayerChange?.(newBaseLayer);
        }
    };

    return (
        <>
            <LayersControl position="bottomleft">
                {availableBaseLayers.map((availableLayer) => (
                    <LayersControl.BaseLayer
                        checked={availableLayer.mapMode === currentBaseLayer.mapMode}
                        key={availableLayer.mapMode}
                        name={availableLayer.name}
                    >
                        <FeatureGroup />
                    </LayersControl.BaseLayer>
                ))}
            </LayersControl>
            <MapGatewayLayer {...currentBaseLayer} />
            <MapEventControls baselayerchange={handleBaseLayerChanged} />
        </>
    );
};

BaseLayerPicker.displayName = 'BaseLayerPicker';
export { BaseLayerPicker };
