import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LocationSectionActionButtonsClassKey =
    | 'copyCoordinatesButton'
    | 'iconMargin'
    | 'locateButton'
    | 'showHistoryButton';

export const LocationSectionActionButtonsStyleRules: StyleRulesCallback<
    Theme,
    {},
    LocationSectionActionButtonsClassKey
> = (theme: Theme) =>
    createStyles({
        copyCoordinatesButton: {
            marginLeft: theme.spacing(-1),
        },
        iconMargin: {
            marginRight: theme.spacing(),
        },
        locateButton: {
            marginLeft: theme.spacing(-1),
        },
        showHistoryButton: {
            marginLeft: theme.spacing(),
        },
    });
