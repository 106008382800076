import { List } from '@mui/material';
import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import { generatePath } from 'react-router';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { CoDriverIcon, DriverIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { InjectedDetailsPaneContextProps } from '~/components/SceneDetailsPane';
import { ReorderableSection } from '~/components/Sections';
import type { MonitoringPerspective, MonitoringVehicleEntry, MonitoringViewMode } from '~/data/monitoring';
import { AssetSubpage } from '~/data/monitoring';
import { DriverRole } from '~/services/ApiClient';

import { MONITORING_PATH_STRUCTURE } from '../../../../../../consts';

import { DriverSummary } from './components/DriverSummary';
import { StaffHeaderInfo } from './components/StaffHeaderInfo';

export interface DriverInfoSectionProps {
    assetId: number;
    dragHandleElement: JSX.Element;
    entry: MonitoringVehicleEntry;
    isCollapsed: boolean;
    perspective: MonitoringPerspective;
    toggleCollapsed: () => void;
    viewMode: MonitoringViewMode;
}

export interface DriverInfoSectionInnerProps
    extends DriverInfoSectionProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        InjectedDetailsPaneContextProps,
        RouteComponentProps {}

export class DriverInfoSectionComponent extends React.Component<DriverInfoSectionInnerProps> {
    private openDriverSubpage = (driverRole: DriverRole) => {
        const { assetId, perspective, viewMode } = this.props;
        this.props.history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                id: assetId,
                perspective,
                subpage: driverRole === DriverRole.DRIVER ? AssetSubpage.DRIVER : AssetSubpage.CODRIVER,
                viewMode,
            })
        );
    };

    public render(): React.ReactNode {
        const { displayPreferences, dragHandleElement, entry, isCollapsed, t, toggleCollapsed } = this.props;
        const driverStatus = entry.vehicleDriverHoursStatus && entry.vehicleDriverHoursStatus.driverHoursStatus;
        const coDriverStatus = entry.vehicleDriverHoursStatus && entry.vehicleDriverHoursStatus.coDriverHoursStatus;
        const driver = driverStatus && driverStatus.driver;
        const coDriver = coDriverStatus && coDriverStatus.driver;

        const driverElement = (
            <DriverSummary
                activity={entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity}
                activityStartDateTime={driverStatus && driverStatus.activityStartDateTime}
                dataId="driver-summary"
                driver={!isCollapsed ? driver : undefined}
                driverDisplayFormat={displayPreferences.driverDisplayFormat}
                icon={<DriverIcon />}
                onOpenDriverSubpage={this.openDriverSubpage}
                role={DriverRole.DRIVER}
                subActivity={entry.vehicleDriverHoursStatus?.driverHoursStatus?.subActivity}
                vehicleId={entry.vehicle.id}
            />
        );

        const coDriverElement = coDriverStatus && coDriver && (
            <DriverSummary
                activity={entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.activity}
                activityStartDateTime={coDriverStatus.activityStartDateTime}
                dataId="co-driver-summary"
                driver={!isCollapsed ? coDriver : undefined}
                driverDisplayFormat={displayPreferences.driverDisplayFormat}
                icon={<CoDriverIcon />}
                onOpenDriverSubpage={this.openDriverSubpage}
                role={DriverRole.CODRIVER}
                subActivity={entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.subActivity}
                vehicleId={entry.vehicle.id}
            />
        );

        const staffHeaderInfo = (
            <StaffHeaderInfo
                coDriver={coDriver}
                coDriverActivity={entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.activity}
                coDriverActivityStartDateTime={coDriverStatus && coDriverStatus.activityStartDateTime}
                coDriverSubActivity={entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.subActivity}
                driver={driver}
                driverActivity={entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity}
                driverActivityStartDateTime={driverStatus && driverStatus.activityStartDateTime}
                driverDisplayFormat={displayPreferences.driverDisplayFormat}
                driverSubActivity={entry.vehicleDriverHoursStatus?.driverHoursStatus?.subActivity}
            />
        );

        return (
            <ReorderableSection
                dataId="staff"
                dragHandleElement={dragHandleElement}
                isCollapsed={isCollapsed}
                rightHeaderElement={staffHeaderInfo}
                title={t('staff')}
                toggleCollapsed={toggleCollapsed}
            >
                <List disablePadding>
                    {driverElement}
                    {coDriverElement}
                </List>
            </ReorderableSection>
        );
    }
}
