import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationSummaryClassKey = 'avatar' | 'body' | 'disabled' | 'root';

export const ConversationSummaryStyleRules: StyleRulesCallback<Theme, {}, ConversationSummaryClassKey> = (
    theme: Theme
) =>
    createStyles({
        avatar: {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            color: theme.functionalItemsColors.assetType.vehicle.value,
            marginRight: theme.spacing(),
        },
        body: {
            flexGrow: 1,
        },
        disabled: {
            opacity: theme.palette.action.disabledOpacity,
        },
        root: {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
            cursor: 'pointer',
            display: 'flex',
            padding: theme.spacing(),
        },
    });
