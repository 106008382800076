import type { ColumnSelectorGroup } from '~/components/ColumnSelector';
import type { GridColumnDefinition } from '~/components/Grid';
import { groupBy, sortBy } from '~/libs/utility';

export const buildColumnGroups = <T>(columns: Array<GridColumnDefinition<T>>): ColumnSelectorGroup[] => {
    const columnsByGroupTitle = groupBy(columns, 'groupTitle');
    const groups = Object.values(columnsByGroupTitle).map((groupColumnDefinitions) => {
        const groupColumns = groupColumnDefinitions.map((c) => ({ name: c.name, title: c.title }));

        return {
            columns: sortBy(groupColumns, 'title'),
            title: groupColumnDefinitions[0].groupTitle,
        };
    });

    return sortBy(groups, 'title');
};
