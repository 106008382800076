import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import type { History } from 'history';

import { getConfig } from '~/services/Config';

export function initializeAppInsights(history?: History): void {
    const reactPlugin = new ReactPlugin();
    const { applicationInsightsInstrumentationKey } = getConfig();

    if (applicationInsightsInstrumentationKey) {
        const appInsights = new ApplicationInsights({
            config: {
                extensionConfig: { [reactPlugin.identifier]: { history } },
                // https://github.com/microsoft/applicationinsights-react-js/issues/32
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                extensions: [reactPlugin],
                instrumentationKey: applicationInsightsInstrumentationKey,
            },
        });
        appInsights.loadAppInsights();
    }
}
