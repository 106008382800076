import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { dynamicMonitoringStateSelector } from '~/data/monitoring';
import { settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import type { DriverSubpageInnerProps, DriverSubpageOuterProps } from './component';
import { DriverSubpageComponent } from './component';
import {
    DRIVERSUBPAGE_PREFERENCES_KEY,
    defaultDriverSubpageUserPreferences,
    requiredDriverSubpageUserPreferences,
} from './preferences';
import { mapStateToProps, propsSelector } from './redux';

export { defaultDriverSubpageUserPreferences, DRIVERSUBPAGE_PREFERENCES_KEY, requiredDriverSubpageUserPreferences };

export const DriverSubpage = compose<DriverSubpageInnerProps, DriverSubpageOuterProps>(
    setDisplayName('DriverSubpage'),
    withSceneAnalytics('driversubpage'),
    withDisplayPreferences(),
    connect(
        createSelector(
            propsSelector,
            staticDataStoreStateSelector,
            dynamicMonitoringStateSelector,
            settingsStoreStateSelector,
            mapStateToProps
        )
    )
)(DriverSubpageComponent);
