import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { HistoryTrailerEquals } from '~/scenes/History/historyTrailerEntryEquals';
import type { Classification, DeviceType, Securables } from '~/services/ApiClient';

import type { HistoryTrailerEntry } from '../../../../models';

import { filterHistoryDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { HISTORY_LIST_TRAILERS_USERPREFERENCES_KEY, defaultTrailersListUserPreferences } from './preferences';

export * from './preferences';

export type TrailerListViewProps = Omit<SceneListViewProps<HistoryTrailerEntry>, 'columns' | 'gridDataId'>;

export type TrailerListViewHeaderProps = Omit<
    SceneListViewHeaderProps<HistoryTrailerEntry>,
    'columns' | 'excelSheetTitle'
>;

const TrailerListViewComponent = SceneListViewFactory<HistoryTrailerEntry>(
    HISTORY_LIST_TRAILERS_USERPREFERENCES_KEY,
    defaultTrailersListUserPreferences,
    getRowId,
    HistoryTrailerEquals,
    filterHistoryDataSourceMemoized
);

const TrailerListViewHeaderComponents = SceneListViewHeaderFactory<HistoryTrailerEntry>(
    HISTORY_LIST_TRAILERS_USERPREFERENCES_KEY,
    defaultTrailersListUserPreferences,
    filterHistoryDataSourceMemoized,
    'history'
);

interface ReduxProps {
    batteryStatuses: NumericDictionary<Classification>;

    compartmentStatuses: NumericDictionary<Classification>;

    deviceTypes: NumericDictionary<DeviceType>;

    doorStatuses: NumericDictionary<Classification>;

    hookedStatuses: NumericDictionary<Classification>;

    reeferStatuses: NumericDictionary<Classification>;

    securables: Securables;

    trailerEventTypes: NumericDictionary<Classification>;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    batteryStatuses: settingDataSelector(SettingsKey.TRAILER_BATTERY_STATUS),

    compartmentStatuses: settingDataSelector(SettingsKey.COMPARTMENT_STATUS),

    deviceTypes: settingDataSelector(SettingsKey.DEVICETYPES),

    doorStatuses: settingDataSelector(SettingsKey.DOOR_STATUS),

    hookedStatuses: settingDataSelector(SettingsKey.HOOKED_STATUS),

    reeferStatuses: settingDataSelector(SettingsKey.REEFER_STATUS),

    securables: settingDataSelector(SettingsKey.SECURABLES),

    trailerEventTypes: settingDataSelector(SettingsKey.TRAILER_EVENT_TYPE),
});

export const TrailerListView = compose<SceneListViewProps<HistoryTrailerEntry>, TrailerListViewProps>(
    setDisplayName('TrailerListView'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences.trailerDisplayFormat,
            ownProps.displayPreferences.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem,
            ownProps.deviceTypes,
            ownProps.trailerEventTypes,
            ownProps.hookedStatuses,
            ownProps.doorStatuses,
            ownProps.batteryStatuses,
            ownProps.reeferStatuses,
            ownProps.compartmentStatuses
        ),
        gridDataId: 'monitoring-grid-trailer',
    }))
)(TrailerListViewComponent);

export const TrailerListViewHeader = compose<SceneListViewHeaderProps<HistoryTrailerEntry>, TrailerListViewHeaderProps>(
    setDisplayName('TrailerListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences.trailerDisplayFormat,
            ownProps.displayPreferences.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem,
            ownProps.deviceTypes,
            ownProps.trailerEventTypes,
            ownProps.hookedStatuses,
            ownProps.doorStatuses,
            ownProps.batteryStatuses,
            ownProps.reeferStatuses,
            ownProps.compartmentStatuses
        ),
        excelSheetTitle: ownProps.t('history'),
    }))
)(TrailerListViewHeaderComponents);
