import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type OverlayMenuClassKey =
    | 'avatar'
    | 'customerCareUsername'
    | 'externalListIcon'
    | 'footerContainer'
    | 'footerContent'
    | 'footerLogo'
    | 'footerLogoContainer'
    | 'footerLogoLink'
    | 'header'
    | 'linearProgressContainer'
    | 'logo'
    | 'logoContainer'
    | 'menuItems'
    | 'profile'
    | 'root'
    | 'signOut';

export const OverlayMenuStyleRules: StyleRulesCallback<Theme, {}, OverlayMenuClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            height: theme.spacing(6),
            marginBottom: theme.spacing(),
            width: theme.spacing(6),
        },
        customerCareUsername: {
            marginTop: theme.spacing(),
        },
        externalListIcon: {
            flexDirection: 'row-reverse',
        },
        footerContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        },
        footerContent: {
            flexGrow: 1,
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
            whiteSpace: 'nowrap',
        },
        footerLogo: {
            maxWidth: '100%',
        },
        footerLogoContainer: {
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(),
        },
        footerLogoLink: {
            display: 'inline-flex',
        },
        header: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(3),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(),
            marginTop: theme.spacing(2),
        },
        linearProgressContainer: {
            height: theme.spacing(0.5),
        },
        logo: {
            width: '100%',
        },
        logoContainer: {
            display: 'flex',
            marginRight: theme.spacing(),
        },
        menuItems: {
            flexGrow: 1,
        },
        profile: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: 306,
        },
        signOut: {
            marginBottom: theme.spacing(),
            marginLeft: theme.spacing(),
            marginTop: theme.spacing(),
        },
    });
