import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import GetApp from '@mui/icons-material/GetApp';
import Print from '@mui/icons-material/Print';
import RotateLeft from '@mui/icons-material/RotateLeft';
import RotateRight from '@mui/icons-material/RotateRight';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Toolbar,
    Typography,
} from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { FileTypeIconFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneLoader } from '~/components/SceneLoader';
import { TitledIconButton } from '~/components/TitledIconButton';

import type {
    DocumentViewerFile,
    DocumentViewerPaging,
    DocumentViewerRotation,
    DocumentViewerScaling,
} from '../../models';
import { DocumentViewerScaleMode, DocumentViewerState } from '../../models';

import { useStyles } from './styles';

export interface DocumentViewerShellProps {
    children?: ReactNode;
    currentFile: DocumentViewerFile;
    downloadAllFiles?: () => void;
    downloadFile?: () => void;
    files: DocumentViewerFile[];
    onClose: () => void;
    onCurrentFileChange?: (file: DocumentViewerFile) => void;
    paging?: DocumentViewerPaging;
    printFile?: () => void;
    rotation?: DocumentViewerRotation;
    scaling?: DocumentViewerScaling;
    state: DocumentViewerState;
}

export interface DocumentViewerShellInnerProps extends DocumentViewerShellProps, InjectedTranslationProps {}

export const DocumentViewerShellComponent: FC<DocumentViewerShellInnerProps> = (props) => {
    const {
        children,
        currentFile,
        downloadAllFiles,
        downloadFile,
        files,
        onClose,
        onCurrentFileChange,
        paging,
        printFile,
        rotation,
        scaling,
        state,
        t,
    } = props;

    const classes = useStyles();

    const fabClasses = {
        disabled: classes.fabDisabled,
        root: classes.fab,
    };
    const fileIndex = files.indexOf(currentFile);
    const onNextFile = onCurrentFileChange
        ? () => onCurrentFileChange(files[(fileIndex + 1) % files.length])
        : undefined;
    const onPreviousFile = onCurrentFileChange
        ? () => onCurrentFileChange(files[(fileIndex - 1 + files.length) % files.length])
        : undefined;

    const fileNavigationVisible = files.length > 1;

    const pager = paging && (
        <>
            <TitledIconButton
                color="inherit"
                data-id="previous-page"
                disabled={paging.pageNumber <= 1}
                onClick={() => paging.onPageNumberChange(paging.pageNumber - 1)}
                title={t('previous-page')}
            >
                <ArrowLeft />
            </TitledIconButton>
            <span data-id="pager">{`${paging.pageNumber} / ${paging.numberOfPages}`}</span>
            <TitledIconButton
                color="inherit"
                data-id="next-page"
                disabled={paging.pageNumber >= paging.numberOfPages}
                onClick={() => paging.onPageNumberChange(paging.pageNumber + 1)}
                title={t('next-page')}
            >
                <ArrowRight />
            </TitledIconButton>
        </>
    );

    const noPreviewMessage = (
        <Box margin="auto">
            <Paper>
                <DialogTitle>{t('preview-not-available')}</DialogTitle>
                <DialogContent>{t('preview-not-available-download-confirmation')}</DialogContent>
                <DialogActions>
                    <Button color="secondary" data-id="close" onClick={onClose}>
                        {t('close')}
                    </Button>
                    <Button
                        color="secondary"
                        data-id="download"
                        disabled={!downloadFile}
                        onClick={downloadFile}
                        variant="contained"
                    >
                        {t('download')}
                    </Button>
                </DialogActions>
            </Paper>
        </Box>
    );

    const [fileMenuAnchorRef, setFileMenuAnchorRef] = useState<HTMLButtonElement | null>(null);
    const [fileMenuOpen, setFileMenuOpen] = useState<boolean>(false);
    const [downloadMenuAnchorRef, setDownloadMenuAnchorRef] = useState<HTMLButtonElement | undefined>(undefined);

    const fileSelector = (
        <>
            <Button
                className={classes.menuButton}
                color="inherit"
                endIcon={<ArrowDropDown />}
                onClick={() => setFileMenuOpen(true)}
                ref={setFileMenuAnchorRef}
                startIcon={<FileTypeIconFormatter value={currentFile.type.key} />}
            >
                <Typography data-id="filename">{currentFile.filename}</Typography>
            </Button>
            <Menu anchorEl={fileMenuAnchorRef} onClose={() => setFileMenuOpen(false)} open={fileMenuOpen}>
                {files.map((f) => (
                    <MenuItem
                        dense
                        key={f.filename}
                        onClick={() => {
                            if (onCurrentFileChange) {
                                onCurrentFileChange(f);
                            }

                            setFileMenuOpen(false);
                        }}
                    >
                        <ListItemIcon className={classes.menuIcon}>
                            <FileTypeIconFormatter value={f.type.key} />
                        </ListItemIcon>
                        <ListItemText primary={f.filename} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
    const fileTitle = (
        <>
            <FileTypeIconFormatter value={currentFile.type.key} />
            <Typography className={classes.filename} data-id="filename">
                {currentFile.filename}
            </Typography>
        </>
    );

    const downloadItem = (
        <>
            <TitledIconButton
                color="inherit"
                data-id="download"
                disabled={!downloadFile && !downloadAllFiles}
                onClick={(evt) => setDownloadMenuAnchorRef(evt.currentTarget)}
                placement="bottom-end"
                title={t('download')}
            >
                <GetApp />
            </TitledIconButton>
            <Menu
                anchorEl={downloadMenuAnchorRef}
                onClose={() => setDownloadMenuAnchorRef(undefined)}
                open={!!downloadMenuAnchorRef}
            >
                <MenuItem
                    data-id="current-file"
                    dense
                    disabled={!downloadFile}
                    onClick={() => {
                        if (downloadFile) {
                            downloadFile();
                        }
                        setDownloadMenuAnchorRef(undefined);
                    }}
                >
                    <ListItemText primary={t('current-file')} />
                </MenuItem>
                <MenuItem
                    data-id="all-files"
                    dense
                    disabled={!downloadAllFiles}
                    onClick={() => {
                        if (downloadAllFiles) {
                            downloadAllFiles();
                        }
                        setDownloadMenuAnchorRef(undefined);
                    }}
                >
                    <ListItemText primary={t('all-files')} />
                </MenuItem>
            </Menu>
        </>
    );

    return (
        <Dialog
            fullScreen={false}
            maxWidth={false}
            onClose={onClose}
            open
            PaperProps={{
                className: classes.dialogPaper,
                elevation: 0,
                square: true,
            }}
        >
            <AppBar color="secondary">
                <Toolbar className={classes.toolbar} data-id="toolbar">
                    <Box alignItems="center" display="flex" width="100%">
                        <Box alignItems="center" display="flex" flex="1">
                            {fileNavigationVisible ? fileSelector : fileTitle}
                        </Box>
                        {pager && (
                            <Box flex="1" textAlign="center">
                                {pager}
                            </Box>
                        )}
                        <Box flex="1" textAlign="right">
                            <TitledIconButton
                                color="inherit"
                                data-id="rotate-counter-clockwise"
                                disabled={!rotation}
                                onClick={() => rotation?.applyRotation(-90)}
                                placement="bottom-end"
                                title={t('rotate-counter-clockwise')}
                            >
                                <RotateLeft />
                            </TitledIconButton>
                            <TitledIconButton
                                color="inherit"
                                data-id="rotate-clockwise"
                                disabled={!rotation}
                                onClick={() => rotation?.applyRotation(90)}
                                placement="bottom-end"
                                title={t('rotate-clockwise')}
                            >
                                <RotateRight />
                            </TitledIconButton>
                            {downloadItem}
                            <TitledIconButton
                                color="inherit"
                                data-id="print"
                                disabled={!printFile}
                                onClick={printFile}
                                placement="bottom-end"
                                title={t('print')}
                            >
                                <Print />
                            </TitledIconButton>
                            <TitledIconButton
                                color="inherit"
                                data-id="close"
                                disabled={!onClose}
                                onClick={onClose}
                                placement="bottom-end"
                                title={t('close')}
                            >
                                <Close />
                            </TitledIconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {fileNavigationVisible && (
                <>
                    <Box
                        alignItems="center"
                        display="flex"
                        height="100%"
                        left={0}
                        p={1}
                        position="fixed"
                        top={0}
                        zIndex={1}
                    >
                        <Fab
                            classes={fabClasses}
                            color="inherit"
                            data-id="previous-document"
                            disabled={!onPreviousFile}
                            onClick={onPreviousFile}
                            title={t('previous-document')}
                        >
                            <ChevronLeft />
                        </Fab>
                    </Box>

                    <Box
                        alignItems="center"
                        display="flex"
                        height="100%"
                        p={1}
                        position="fixed"
                        right={0}
                        top={0}
                        zIndex={1}
                    >
                        <Fab
                            classes={fabClasses}
                            color="inherit"
                            data-id="next-document"
                            disabled={!onNextFile}
                            onClick={onNextFile}
                            title={t('next-document')}
                        >
                            <ChevronRight />
                        </Fab>
                    </Box>
                </>
            )}

            <Box bottom={0} display="flex" flexDirection="column" p={1} position="fixed" right={0} zIndex={1}>
                <Fab
                    classes={fabClasses}
                    color="inherit"
                    data-id="toggle-fullscreen"
                    disabled={!scaling}
                    onClick={
                        scaling &&
                        (scaling.scale === DocumentViewerScaleMode.FitToScreen
                            ? scaling.fitToScreenWidth
                            : scaling.fitToScreen)
                    }
                    title={t(scaling?.scale === DocumentViewerScaleMode.FitToScreen ? 'maximize' : 'restore')}
                >
                    {scaling?.scale === DocumentViewerScaleMode.FitToScreen ? <Fullscreen /> : <FullscreenExit />}
                </Fab>
                <Fab
                    classes={fabClasses}
                    color="inherit"
                    data-id="zoom-in"
                    disabled={!scaling?.zoomIn}
                    onClick={scaling?.zoomIn}
                    title={t('zoom-in')}
                >
                    <ZoomIn />
                </Fab>
                <Fab
                    classes={fabClasses}
                    color="inherit"
                    data-id="zoom-out"
                    disabled={!scaling?.zoomOut}
                    onClick={scaling?.zoomOut}
                    title={t('zoom-out')}
                >
                    <ZoomOut />
                </Fab>
            </Box>

            <Box display="flex" flex={1}>
                {state === DocumentViewerState.Loading && <SceneLoader />}
                {state === DocumentViewerState.NoPreview && noPreviewMessage}
                <Box data-id="contents" display={state === DocumentViewerState.NoPreview ? 'none' : 'flex'} flex={1}>
                    {children}
                </Box>
            </Box>
        </Dialog>
    );
};
