import { Map } from '@fv/components/Map';
import type { LatLngBoundsExpression, Map as LeafletMap } from 'leaflet';
import { LatLngBounds } from 'leaflet';
import memoizeOne from 'memoize-one';
import { useCallback, useEffect, useRef } from 'react';
import { ScaleControl } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import type { MapViewport } from '~/common';
import { isEqual, isNil } from '~/libs/utility';
import type { RequiredAndNonNullable } from '~/types';

import { changeViewportAction, showAllSelectedAssetsAction } from '../../data';

import { EntryMarker, MarkerCluster } from './components';
import { ZOOM_LEVELS } from './constants';
import type { SceneMapViewInnerProps } from './models';
import { mapStateSelector } from './selectors';
import { ensureBoundsArea, getAllBounds } from './services';
import { useStyles } from './styles';

const SceneMapViewComponent = <T,>(props: SceneMapViewInnerProps<T>) => {
    const {
        dataId,
        dataSource,
        entryEquals,
        filterDataSource,
        getAssetIdentifier,
        getAssetPosition,
        mapKey,
        mapLayersProps,
        markerClusterRadius,
        onAssetContextMenu,
        onAssetSelect,
        selectedAsset,
        showTooltip = true,
        size,
    } = props;

    const classes = useStyles();
    const refSelectedAsset = useRef<T>();
    const mapRef = useRef<LeafletMap | null>();
    const getAllBoundsMemoized = memoizeOne(getAllBounds);
    const filterDataSourceMemoized = memoizeOne(filterDataSource);
    const dispatch = useDispatch();

    const changeViewport = (viewport: RequiredAndNonNullable<MapViewport>) => {
        dispatch(changeViewportAction(viewport));
    };
    const { panToAsset, searchQuery, showAllSelectedAssets, viewport } = useSelector(mapStateSelector(mapKey));

    const getFilteredDataSource = useCallback(
        () => (searchQuery ? filterDataSourceMemoized(dataSource, searchQuery) : dataSource),
        [dataSource, filterDataSourceMemoized, searchQuery]
    );

    const getBounds = (): LatLngBoundsExpression | undefined => {
        if (isNil(viewport)) {
            return undefined;
        }

        if (selectedAsset) {
            const position = getAssetPosition(selectedAsset);
            if (position) {
                return ensureBoundsArea(new LatLngBounds([[position.latitude, position.longitude]]));
            }
        }

        return getAllBoundsMemoized(getAssetPosition, getFilteredDataSource());
    };

    const getSelectedAssetMarker = () => {
        if (selectedAsset) {
            const selectedAssetPosition = getAssetPosition(selectedAsset);

            if (selectedAssetPosition) {
                return (
                    <EntryMarker
                        entry={selectedAsset}
                        entryEquals={entryEquals}
                        getAssetIdentifier={getAssetIdentifier}
                        key={`${mapKey}:selected:${getAssetIdentifier(selectedAsset)}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        mapKey={mapKey}
                        onAssetContextMenu={onAssetContextMenu}
                        onAssetSelect={onAssetSelect}
                        position={[selectedAssetPosition.latitude, selectedAssetPosition.longitude]}
                        selected
                    />
                );
            }
        }

        return null;
    };

    const followSelectedAsset = useCallback(() => {
        const currentPosition = selectedAsset && getAssetPosition(selectedAsset);
        if (!selectedAsset || !mapRef.current || !currentPosition) {
            return;
        }

        mapRef.current.flyTo([currentPosition.latitude, currentPosition.longitude], mapRef.current.getZoom(), {
            animate: true,
        });
    }, [getAssetPosition, selectedAsset]);

    const panToSelectedAssetOnPanFlagTrue = useCallback(
        (animate: boolean = false) => {
            if (!mapRef.current || !panToAsset) {
                return;
            }

            const selectedAssetPosition = selectedAsset && getAssetPosition(selectedAsset);

            if (selectedAssetPosition) {
                mapRef.current.flyTo(
                    [selectedAssetPosition.latitude, selectedAssetPosition.longitude],
                    ZOOM_LEVELS.CLOSE,
                    { animate }
                );
            }
        },
        [getAssetPosition, panToAsset, selectedAsset]
    );

    const showAllSelectedAssetsOnShowFlagTrue = useCallback(() => {
        if (mapRef.current && showAllSelectedAssets) {
            mapRef.current.flyToBounds(getAllBoundsMemoized(getAssetPosition, getFilteredDataSource()), {
                duration: 0.5,
            });
        }
    }, [getAllBoundsMemoized, getAssetPosition, getFilteredDataSource, showAllSelectedAssets]);

    useEffect(() => {
        if (!isNil(selectedAsset) && !isEqual(refSelectedAsset.current, selectedAsset)) {
            followSelectedAsset();
            refSelectedAsset.current = selectedAsset;
        }
    }, [followSelectedAsset, selectedAsset]);

    useEffect(() => {
        if (panToAsset) {
            const DELAY = 100;

            const timer = window.setTimeout(() => {
                panToSelectedAssetOnPanFlagTrue();
            }, DELAY);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [panToSelectedAssetOnPanFlagTrue, panToAsset]);

    useEffect(() => {
        showAllSelectedAssetsOnShowFlagTrue();
    }, [showAllSelectedAssets, showAllSelectedAssetsOnShowFlagTrue]);

    useEffect(() => {
        if (mapRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                mapRef.current?.invalidateSize();
            });

            resizeObserver.observe(mapRef.current.getContainer());

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [size]);

    return (
        <div data-id={dataId} style={size}>
            <Map
                {...viewport}
                baseLayer={mapLayersProps}
                bounds={getBounds()}
                className={classes.map}
                disableBaseLayerControls
                dragging
                mapEvents={{
                    contextmenu: ({ originalEvent }) => originalEvent.preventDefault(),
                    moveend: () => {
                        if (!mapRef.current) {
                            return;
                        }

                        const zoom = mapRef.current.getZoom();
                        const { lat, lng } = mapRef.current.getCenter();

                        changeViewport({ center: [lat, lng], zoom });
                    },
                }}
                ref={(map) => {
                    mapRef.current = map;
                }}
                touchZoom
                whenReady={() => {
                    dispatch(showAllSelectedAssetsAction());
                }}
                worldCopyJump
            >
                <MarkerCluster mapKey={mapKey} markerClusterRadius={markerClusterRadius}>
                    {getFilteredDataSource().map((entry) => {
                        const position = getAssetPosition(entry);

                        if ((selectedAsset && entryEquals(selectedAsset, entry)) || !position) {
                            return null;
                        }

                        return (
                            <EntryMarker
                                entry={entry}
                                entryEquals={entryEquals}
                                getAssetIdentifier={getAssetIdentifier}
                                key={`${mapKey}:${getAssetIdentifier(entry)}`}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                mapKey={mapKey}
                                onAssetContextMenu={onAssetContextMenu}
                                onAssetSelect={onAssetSelect}
                                position={[position.latitude, position.longitude]}
                                showTooltip={showTooltip}
                            />
                        );
                    })}
                </MarkerCluster>

                {getSelectedAssetMarker()}

                <ScaleControl imperial metric position="bottomleft" />
            </Map>
        </div>
    );
};

SceneMapViewComponent.displayName = 'SceneMapViewComponent';
export { SceneMapViewComponent };
