import type * as i18next from 'i18next';
import * as React from 'react';
import { defaultProps } from 'react-recompose';

import { isUndefined } from '~/libs/utility';
import type { ViolationField } from '~/services/ApiClient';
import { ViolationType } from '~/services/ApiClient';

import { ViolationGroupFormatter } from '../ViolationGroup';
import type { ViolationGroupFormatterProps } from '../ViolationGroup';

export interface ViolationFieldGroupFormatterProps<T> extends i18next.WithT {
    value?: ViolationField<T>;
}

export interface ViolationFieldGroupFormatterInnerProps<T> extends ViolationFieldGroupFormatterProps<T> {}

export const ViolationFieldGroupFormatterComponent = <T,>(
    props: ViolationFieldGroupFormatterInnerProps<T>
): JSX.Element => {
    const getViolationType = (): ViolationType => {
        return !isUndefined(props.value) ? props.value.violationType : ViolationType.None;
    };

    const violationGroupFormatterProps: Partial<ViolationGroupFormatterProps<T>> = {
        getViolationType,
        t: props.t,
        value: props.value?.value,
    };
    const InnerViolationGroupComponent = defaultProps(violationGroupFormatterProps)(ViolationGroupFormatter<T>());

    return <InnerViolationGroupComponent />;
};
