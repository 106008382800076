import { defaultProps } from 'react-recompose';

import { TemperatureSensorFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem, ResolvedCompartmentStatus } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import { getTemperatureUnitSystemUtils, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaIncludingUndefinedFactory } from '~/services/GroupingCriteria';
import { compareFactory, compareNumbers } from '~/services/Sorting';

import { TemperatureSensorGroupFormatter } from '../Formatters/TemperatureSensorGroupFormatter';

import type { CreateColumnOptions, GridColumnDefinition } from './models';
import { temperatureSensorGroupingCriteria } from './violationGroupCriteria';

export const createTemperatureSensorColumn = <T>(
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    sensorNumber: number,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => ResolvedCompartmentStatus | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => {
    const temperatureUtils = getTemperatureUnitSystemUtils(t, unitSystem);
    const getSensorValue = (status?: ResolvedCompartmentStatus) => status?.temperatureSensors[sensorNumber]?.value;
    const temperatureSensorValueFormatterFactory = <T2>(formatter: (i: number) => T2) => {
        return (status?: ResolvedCompartmentStatus) => {
            const value = getSensorValue(status);
            return !isUndefined(value) ? formatter(value) : '';
        };
    };
    const compareSensorValue = compareFactory(getSensorValue, compareNumbers);

    const temperatureSensorGroupingCriteriaWrapper = (value?: ResolvedCompartmentStatus) => {
        return temperatureSensorGroupingCriteria(value, sensorNumber);
    };

    return {
        ...options,
        align: 'right',
        compare: compareSensorValue,
        dataType: 'object',
        excelCellFormat: `0.0 "${temperatureUtils.unit}"`,
        exportValueFormatter: exportConvertorFactory(
            temperatureSensorValueFormatterFactory(temperatureUtils.converter)
        ),
        formatUndefinedValue: true,
        getCellValue,
        groupingCriteria: groupingCriteriaIncludingUndefinedFactory(temperatureSensorGroupingCriteriaWrapper),
        groupTitle,
        groupValueFormatterComponent: defaultProps({ sensorNumber, t })(TemperatureSensorGroupFormatter),
        name,
        title,
        valueFormatterComponent: defaultProps({ sensorNumber, t, unitSystem })(TemperatureSensorFormatter),
        valueTextFormatter: valueTextFormatterFactory(
            temperatureSensorValueFormatterFactory(temperatureUtils.formatter)
        ),
    };
};
