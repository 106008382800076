import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { themeSpacingToNumber } from '~/common';

import { zoomPopupWidth } from '../../styles';

export type TicksClassKey = 'fifteenMinuteTick' | 'fiveMinuteTick' | 'root' | 'tick' | 'tickLabel';

export const TicksStyleRules: StyleRulesCallback<Theme, {}, TicksClassKey> = (theme: Theme) =>
    createStyles({
        fifteenMinuteTick: {
            height: 15,
        },
        fiveMinuteTick: {
            height: 10,
        },
        root: {
            height: 32,
            position: 'relative',
            width: zoomPopupWidth,
        },
        tick: {
            background: theme.palette.action.selectedBackground,
            height: 5,
            position: 'absolute',
            width: 1,
        },
        tickLabel: {
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '14px',
            position: 'absolute',
            top: themeSpacingToNumber(theme.spacing(0.5)) + 15,
            width: 1,
        },
    });
