import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import type { SceneDetailsPaneReduxProps } from './models';
import type { SceneDetailsPaneUserPreferences } from './preferences.sceneDetailsPane';

export interface StateProps<T extends SceneDetailsPaneUserPreferences<V>, V> {
    userPreferences: T;
}

export interface DispatchProps<S> {
    changeCollapsedSections: (collapsedSections: S[]) => void;
    changeSectionOrder: (sectionOrder: S[]) => void;
}

export const mapStateToPropsFactory =
    <T extends SceneDetailsPaneUserPreferences<V>, V>() =>
    (detailsPaneUserPreferences: T): StateProps<T, V> => {
        return { userPreferences: detailsPaneUserPreferences };
    };

export const mapDispatchToPropsFactory =
    <S>(preferencesKey: string) =>
    (dispatch: Dispatch): DispatchProps<S> => ({
        changeCollapsedSections: (collapsedSections: S[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { collapsedSections }));
        },
        changeSectionOrder: (sectionOrder: S[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { sectionOrder }));
        },
    });

const toggleCollapsedStateMemoized = memoizeOne(
    <S>(collapsedSections: S[] = [], changeCollapsedSections: (collapsedSections: S[]) => void) =>
        memoize((sectionName: S) => {
            const isCollapsed = collapsedSections.includes(sectionName);
            const nextCollapsedSections = isCollapsed
                ? collapsedSections.filter((section: S) => section !== sectionName)
                : [...collapsedSections, sectionName];
            return () => changeCollapsedSections(nextCollapsedSections);
        })
);

export const mergePropsFactory =
    <P extends SceneDetailsPaneUserPreferences<S>, S>() =>
    (
        { userPreferences, ...restStateProps }: StateProps<P, S>,
        { changeCollapsedSections, ...restDispatchProps }: DispatchProps<S>,
        ownProps: object
    ): SceneDetailsPaneReduxProps<P, S> => {
        return {
            ...restStateProps,
            ...restDispatchProps,
            ...ownProps,
            toggleCollapsedState: toggleCollapsedStateMemoized(
                userPreferences.collapsedSections,
                changeCollapsedSections
            ),
            userPreferences,
        };
    };
