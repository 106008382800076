import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';

import { VehicleDetailsPaneComponent } from './component';
import { DRIVERSUBPAGE_PREFERENCES_KEY, requiredDriverSubpageUserPreferences } from './components/DriverSubpage';
import type { VehicleDetailsPaneInnerProps, VehicleDetailsPaneProps } from './models';
import {
    DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    defaultVehicleDetailsPaneUserPreferences,
    requiredVehicleDetailsPaneUserPreferences,
} from './preferences';
import { mapDispatchToProps, mapStateToProps } from './redux';

export { requiredHistorySubpageUserPreferences } from '../HistorySubpage';

export {
    defaultVehicleDetailsPaneUserPreferences,
    DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    DRIVERSUBPAGE_PREFERENCES_KEY,
    requiredDriverSubpageUserPreferences,
    requiredVehicleDetailsPaneUserPreferences,
};
export { VehicleHistorySubpage } from './sections';

export { vehicleDetailsPaneReducer } from './storestate';

export const VehicleDetailsPane = compose<VehicleDetailsPaneInnerProps, VehicleDetailsPaneProps>(
    setDisplayName('VehicleDetailsPane'),
    withSceneAnalytics('vehicledetailspane'),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps), mapDispatchToProps),
    withRouter
)(VehicleDetailsPaneComponent);
