export enum ActionTypePrefixes {
    ADMINDRIVERCHANGELOGS = 'ADMINDRIVERCHANGELOGS',
    ADMINDRIVERCREATE = 'ADMINDRIVERCREATE',
    ADMINDRIVERDELETE = 'ADMINDRIVERDELETE',
    ADMINDRIVERGET = 'ADMINDRIVERGET',
    ADMINDRIVERUPDATE = 'ADMINDRIVERUPDATE',
}

export enum ActionTypeKeys {
    ADMINDRIVER_CLEAR_DATA = 'ADMINDRIVER_CLEAR_DATA',
    ADMINDRIVERCHANGELOGS_FULFILLED = 'ADMINDRIVERCHANGELOGS_FULFILLED',
    ADMINDRIVERCHANGELOGS_PENDING = 'ADMINDRIVERCHANGELOGS_PENDING',
    ADMINDRIVERCHANGELOGS_REJECTED = 'ADMINDRIVERCHANGELOGS_REJECTED',
    ADMINDRIVERCREATE_FULFILLED = 'ADMINDRIVERCREATE_FULFILLED',
    ADMINDRIVERCREATE_PENDING = 'ADMINDRIVERCREATE_PENDING',
    ADMINDRIVERCREATE_REJECTED = 'ADMINDRIVERCREATE_REJECTED',
    ADMINDRIVERDELETE_FULFILLED = 'ADMINDRIVERDELETE_FULFILLED',
    ADMINDRIVERDELETE_PENDING = 'ADMINDRIVERDELETE_PENDING',
    ADMINDRIVERDELETE_REJECTED = 'ADMINDRIVERDELETE_REJECTED',
    ADMINDRIVERDELETE_RESET = 'ADMINDRIVERDELETE_RESET',
    ADMINDRIVERGET_FULFILLED = 'ADMINDRIVERGET_FULFILLED',
    ADMINDRIVERGET_PENDING = 'ADMINDRIVERGET_PENDING',
    ADMINDRIVERGET_REJECTED = 'ADMINDRIVERGET_REJECTED',
    ADMINDRIVERUPDATE_FULFILLED = 'ADMINDRIVERUPDATE_FULFILLED',
    ADMINDRIVERUPDATE_PENDING = 'ADMINDRIVERUPDATE_PENDING',
    ADMINDRIVERUPDATE_REJECTED = 'ADMINDRIVERUPDATE_REJECTED',
    ADMINDRIVERUPDATE_RESET = 'ADMINDRIVERUPDATE_RESET',
}

export const Actions = { ...ActionTypePrefixes, ...ActionTypeKeys };
