import { SearchingBox } from '@fv/components/Searching';
import type { FC } from 'react';

import { useTree } from '../../context';

const Header: FC = () => {
    const [
        { expandedAll, hideSelectAll, partiallySelected, searchEnabled, searchingItemsName, selectedAll },
        { changeSearchQuery, closeSearch, onCollapse, openSearch, selectAll },
    ] = useTree();

    return (
        <SearchingBox
            changeSearchQuery={changeSearchQuery}
            closeSearch={closeSearch}
            expandedAll={expandedAll}
            handleCollapse={onCollapse}
            hideBulkSelectActions={hideSelectAll}
            onToggleSelectAll={selectAll}
            openSearch={openSearch}
            partiallySelected={partiallySelected}
            searchEnabled={searchEnabled}
            searchingItemsName={searchingItemsName}
            selectedAll={selectedAll}
        />
    );
};

Header.displayName = 'Header';
export { Header };
