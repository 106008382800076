import type { RefCallback, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

import { difference, union } from '../utility';

interface UseIntersectionObserverArgs extends Omit<IntersectionObserverInit, 'root'> {
    root: RefObject<Element | null>;
}

type IntersectionRegistration = { element: Element | null; ref: RefCallback<Element> };

type ItemsVisibility = { hidden: string[]; visible: string[] };

const useIntersectionObserver = ({ root, ...restArgs }: UseIntersectionObserverArgs) => {
    const [itemsVisibility, setItemsVisibility] = useState<ItemsVisibility>({ hidden: [], visible: [] });
    const registrations = useRef<Record<string, IntersectionRegistration>>({});
    const observer = useRef<IntersectionObserver>();

    const register = (key: string) => {
        if (registrations.current[key]) {
            return registrations.current[key].ref;
        }
        registrations.current[key] = {
            element: null,
            ref: (element: Element | null) => {
                const currentElement = registrations.current[key].element;
                if (currentElement !== element) {
                    if (currentElement) {
                        observer.current?.unobserve(currentElement);
                    }
                    registrations.current[key].element = element;
                    if (element) {
                        observer.current?.observe(element);
                    }
                }
            },
        };
        return registrations.current[key].ref;
    };

    useEffect(() => {
        const handleIntersection: IntersectionObserverCallback = (entries) => {
            const registrationEntries = Object.entries(registrations.current);
            const mutations = entries.reduce(
                (acc, entry) => {
                    const registrationEntry = registrationEntries.find(([, value]) => value.element === entry.target);
                    if (registrationEntry) {
                        const [key] = registrationEntry;
                        if (entry.isIntersecting) {
                            acc.visible.push(key);
                        } else {
                            acc.hidden.push(key);
                        }
                    }
                    return acc;
                },
                { hidden: [] as string[], visible: [] as string[] }
            );
            setItemsVisibility(({ hidden, visible }) => ({
                hidden: union(difference(hidden, mutations.visible), mutations.hidden),
                visible: union(difference(visible, mutations.hidden), mutations.visible),
            }));
        };

        observer.current = new IntersectionObserver(handleIntersection, { ...restArgs, root: root.current });
        Object.values(registrations.current).forEach((element) => {
            if (element.element) {
                observer.current?.observe(element.element);
            }
        });

        return () => observer.current?.disconnect();

        // eslint-disable-next-line react-hooks/exhaustive-deps -- disabling because the restArgs will be a different object on each render
    }, []);

    return { hiddenItems: itemsVisibility.hidden, register, visibleItems: itemsVisibility.visible };
};

export type { UseIntersectionObserverArgs };
export { useIntersectionObserver };
