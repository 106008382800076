import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const PreTripIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} height="24" viewBox="0 0 576 512" width="24">
            {/* tslint:disable:max-line-length */}
            <path d="M480 96H96v160h100.95c3.03 0 5.8 1.71 7.15 4.42l19.9 39.8 49.69-99.38c5.9-11.79 22.72-11.79 28.62 0L329.89 256H400c8.84 0 16 7.16 16 16s-7.16 16-16 16h-89.89L288 243.78l-49.69 99.38c-5.9 11.79-22.72 11.79-28.62 0L182.11 288H96v128h384V96zm48-96H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zm-16 448H64V64h448v384z" />
        </SvgIcon>
    );
};
PreTripIcon.displayName = 'PreTripIcon';

export { PreTripIcon };
