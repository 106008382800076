import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SecondaryDoorStatusClassKey = 'badgeBackground' | 'doorStatusBadge' | 'doorStatusBadgeIcon';

export const SecondaryDoorStatusStyleRules: StyleRulesCallback<Theme, {}, SecondaryDoorStatusClassKey> = (
    theme: Theme
) =>
    createStyles({
        badgeBackground: {
            backgroundColor: theme.palette.info.main,
        },
        doorStatusBadge: {
            height: 14,
            width: 14,
        },
        doorStatusBadgeIcon: {
            height: 22,
            width: 22,
        },
    });
