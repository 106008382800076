import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        height: 64,
        marginBottom: theme.spacing(),
        width: 64,
    },
    header: { backgroundColor: theme.palette.background.default, padding: theme.spacing() },
    infoBox: { alignItems: 'center', display: 'flex', flexDirection: 'column', padding: theme.spacing(1, 1, 2) },
    profileSection: { backgroundColor: 'unset' },
}));
