import type { QueryRtdsSessionsRequest, QueryRtdsSessionsResponse } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    RTDSSESSIONS_QUERY = 'RTDSSESSIONS_QUERY',
}

export enum ActionTypeKeys {
    RTDSSESSIONS_QUERY_FULFILLED = 'RTDSSESSIONS_QUERY_FULFILLED',
    RTDSSESSIONS_QUERY_PENDING = 'RTDSSESSIONS_QUERY_PENDING',
    RTDSSESSIONS_QUERY_REJECTED = 'RTDSSESSIONS_QUERY_REJECTED',
}

export type ActionTypes =
    | FulfilledMeta<
          ActionTypeKeys.RTDSSESSIONS_QUERY_FULFILLED,
          Readonly<QueryRtdsSessionsResponse>,
          QueryRtdsSessionsRequest
      >
    | PendingMeta<ActionTypeKeys.RTDSSESSIONS_QUERY_PENDING, QueryRtdsSessionsRequest>
    | RejectedMeta<ActionTypeKeys.RTDSSESSIONS_QUERY_REJECTED, undefined, QueryRtdsSessionsRequest>;
