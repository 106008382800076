import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { CollapsibleContent } from '~/components/CollapsibleContent';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import { groupBy, isUndefined, orderBy } from '~/libs/utility';
import type { ResolvedContainer, ResolvedContainerStatus } from '~/services/ApiClient';
import { ClassificationType, ContainerTemperatureStatusKeys } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { ContainerTemperatureGroup } from './components/TemperatureGroup';
import { TemperaturesGraphDialog } from './components/TemperaturesGraphDialog';
import type { ContainerTemperaturesSectionClassKey } from './styles';

export interface ContainerTemperaturesSectionOutterProps {
    container: ResolvedContainer;
    containerStatus?: ResolvedContainerStatus;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}
export interface ContainerTemperaturesSectionInnerProps
    extends InjectedTranslationProps,
        ContainerTemperaturesSectionOutterProps,
        WithStyles<ContainerTemperaturesSectionClassKey> {}

const temperatureTypeOrder = (key: ContainerTemperatureStatusKeys): number => {
    switch (key) {
        case ContainerTemperatureStatusKeys.AMBIENT:
            return 1;
        case ContainerTemperatureStatusKeys.CONTENT:
            return 0;
        case ContainerTemperatureStatusKeys.HEATER:
            return 2;
        case ContainerTemperatureStatusKeys.SETPOINT:
            return 3;
        case ContainerTemperatureStatusKeys.UNKNOWN:
            return 4;
        default:
            return 10;
    }
};

export const ContainerTemperaturesSectionComponent: React.FC<ContainerTemperaturesSectionInnerProps> = ({
    classes,
    container,
    containerStatus,
    dragHandleElement,
    isCollapsed,
    t,
    toggleCollapsed,
}) => {
    const [dialogOpened, setDialogOpened] = React.useState<boolean>(false);

    const openDialog = React.useCallback(() => {
        setDialogOpened(true);
    }, [setDialogOpened]);
    const closeDialog = React.useCallback(() => {
        setDialogOpened(false);
    }, [setDialogOpened]);

    const [collapsedTemperatureGroups, setCollapsedTemperatureGroups] = React.useState<string[]>([]);

    const toggleCollapsedTemperatureGroup = React.useCallback(
        (temperatureGroup: string) => () => {
            if (collapsedTemperatureGroups.includes(temperatureGroup)) {
                setCollapsedTemperatureGroups(collapsedTemperatureGroups.filter((c) => c !== temperatureGroup));
            } else {
                setCollapsedTemperatureGroups([...collapsedTemperatureGroups, temperatureGroup]);
            }
        },
        [collapsedTemperatureGroups]
    );
    const temperaturesContent = React.useMemo(() => {
        if (!containerStatus?.temperatures?.length) {
            return <SectionContentMessage dataId="no-data-messages">{t('no-temperatures')}</SectionContentMessage>;
        }

        const groupedTemperatures = groupBy(containerStatus.temperatures, 'type.key');
        const groupedTemperaturesContent = orderBy(
            Object.keys(groupedTemperatures),
            [temperatureTypeOrder],
            ['asc']
        ).map((temperatureType) => {
            const temperatureGroup = orderBy(groupedTemperatures[temperatureType], 'id');

            const temperatureTypeClassification = temperatureGroup[0].type;

            const isGroupCollapsed = collapsedTemperatureGroups.includes(temperatureType);

            const groupTitle = formatClassification(
                t,
                ClassificationType.ContainerTemperatureType,
                temperatureTypeClassification
            );

            return (
                <CollapsibleContent
                    dataId={`temperatrue-group:${temperatureType}`}
                    header={groupTitle}
                    isCollapsed={isGroupCollapsed}
                    key={temperatureType}
                    toggleCollapsed={toggleCollapsedTemperatureGroup(temperatureType)}
                >
                    <ContainerTemperatureGroup onClick={openDialog} temperatures={temperatureGroup} />
                </CollapsibleContent>
            );
        });

        return <div className={classes.temperatureContainer}>{groupedTemperaturesContent}</div>;
    }, [containerStatus, classes, t, collapsedTemperatureGroups, toggleCollapsedTemperatureGroup, openDialog]);

    const graphDialog =
        dialogOpened && !isUndefined(containerStatus) ? (
            <TemperaturesGraphDialog container={container} containerStatus={containerStatus} onClose={closeDialog} />
        ) : null;

    return (
        <ReorderableSection
            dataId="temperatures"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t('temperatures')}
            toggleCollapsed={toggleCollapsed}
        >
            {temperaturesContent}
            {graphDialog}
        </ReorderableSection>
    );
};
