import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type CustomerOptionChildClassKey = 'root';

export const CustomerOptionChildStyleRules: StyleRulesCallback<Theme, {}, CustomerOptionChildClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            overflow: 'hidden',
            paddingLeft: theme.spacing(),
            width: '100%',
        },
    });
