import Divider from '@mui/material/Divider';
import type { FC } from 'react';

import { useAssetSelector } from '../../context';

import { DefaultHeader, SearchHeader } from './components';

const Header: FC = () => {
    const [
        {
            checkableItemsCount,
            expandedAll,
            hideExpandAllButton,
            searchEnabled,
            searchingItemsName,
            selectedAll,
            selectedCheckableCount,
            singleSelectionMode,
            totalItems,
        },
        { changeSearchQuery, onCheck, onCollapse, toggleSearch },
    ] = useAssetSelector();

    return (
        <>
            <div data-testid="asset-selector-header">
                {searchEnabled ? (
                    <SearchHeader changeSearchQuery={changeSearchQuery} toggleSearch={toggleSearch} />
                ) : (
                    <DefaultHeader
                        checkedKeysCount={selectedCheckableCount}
                        expandedAll={expandedAll}
                        handleCheck={onCheck}
                        handleCollapse={onCollapse}
                        hideExpandAllButton={hideExpandAllButton || !checkableItemsCount}
                        hideSelectAllCheckbox={!checkableItemsCount}
                        itemsCount={totalItems}
                        searchingItemsName={searchingItemsName}
                        selectedAll={selectedAll}
                        singleSelectionMode={singleSelectionMode}
                        toggleSearch={toggleSearch}
                    />
                )}
            </div>
            <Divider />
        </>
    );
};

Header.displayName = 'Header';
export { Header };
