import { useTheme } from '@mui/material';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { TimeSeriesChartDefinition } from '~/components/TimeSeriesDialog';
import { TimeSeriesDialogFactory } from '~/components/TimeSeriesDialog';
import { flatten, groupBy, isUndefined } from '~/libs/utility';
import type { ResolvedContainer, ResolvedContainerStatus } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification, formatContainerName, getTemperatureUnitSystemUtils } from '~/services/Formatters';
import { compareFactory, stringComparer } from '~/services/Sorting';

import { getChartData } from './getChartData';
import type { ContainerTemperatureGraphEntryModel } from './models';

export interface TemperaturesGraphDialogProps {
    container: ResolvedContainer;
    containerStatus: ResolvedContainerStatus;
    onClose: () => void;
}

const ContainerTemperatureStatusTimeSeriesDialog = TimeSeriesDialogFactory<ContainerTemperatureGraphEntryModel>();

export interface TemperaturesGraphDialogInnerProps
    extends TemperaturesGraphDialogProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const TemperaturesGraphDialogComponent: React.FC<TemperaturesGraphDialogInnerProps> = ({
    container,
    containerStatus,
    displayPreferences,
    onClose,
    t,
}) => {
    const theme = useTheme();

    const containerName = formatContainerName(container);
    const title = t('measurements-of-asset', { assetName: containerName });

    const [temperatureSensors] = React.useState(() =>
        flatten(
            Object.values(groupBy(containerStatus.temperatures, 'type.key')).map((sensorsForType) =>
                sensorsForType.map((sensor, idx) => ({
                    id: sensor.id,
                    title: `${formatClassification(t, ClassificationType.ContainerTemperatureType, sensor.type)} ${
                        idx + 1
                    }`,
                }))
            )
        )
            .sort(compareFactory((x) => x.title, stringComparer))
            .slice(0, 8)
    );

    const getDataCallback = React.useCallback(
        (startDate: Date, stopDate: Date) => getChartData(container.id, startDate, stopDate),
        [container.id]
    );

    const charts: TimeSeriesChartDefinition<ContainerTemperatureGraphEntryModel>[] = React.useMemo(() => {
        const temperatureUtils = getTemperatureUnitSystemUtils(t, displayPreferences.unitSystem);

        return [
            {
                filterData: (items) => items,
                getItemDate: (item) => item.dateTime,
                name: `container`,
                series: temperatureSensors.map((s, idx) => ({
                    color: theme.functionalItemsColors.graph[idx].value,
                    getValue: (item) => {
                        const value = item.temperatures[s.id]?.value;
                        return isUndefined(value) ? undefined : temperatureUtils.converter(value);
                    },
                    name: s.id,
                    title: s.title,
                    type: 'line',
                    unit: temperatureUtils.unit,
                })),
                title: t('container'),
            },
        ];
    }, [t, theme, temperatureSensors, displayPreferences.unitSystem]);

    return (
        <ContainerTemperatureStatusTimeSeriesDialog
            charts={charts}
            getData={getDataCallback}
            onClose={onClose}
            title={title}
        />
    );
};
