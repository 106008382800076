import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { DynamicMonitoringStoreState } from '~/data/monitoring';
import { buildMonitoringTrailerEntries } from '~/data/monitoring';
import { connectTrailerAction } from '~/data/vehicleconnections';
import { reportError } from '~/reporting';
import { memoizeOne } from '~/services/Memoize';

import type { ConnectTrailerDialogProps, DispatchProps, StateProps } from './component';

const buildMonitoringTrailerEntriesMemoized = memoizeOne(buildMonitoringTrailerEntries);

export const mapStateToProps = (
    staticData: StaticDataStoreState,
    dynamicMonitoring: DynamicMonitoringStoreState,
    settingsState: SettingsStoreState
): StateProps => {
    return {
        trailerManufacturers: Object.values(settingsState.trailerManufacturers.data),
        trailers: buildMonitoringTrailerEntriesMemoized({
            compartmentStatus: settingsState.compartmentStatus.data,
            deviceTypes: settingsState.deviceTypes.data,
            doorStatus: settingsState.doorStatus.data,
            driverActivityTypes: settingsState.driverActivityTypes.data,
            driverSubActivityTypes: settingsState.driverSubActivityTypes.data,
            hookedStatus: settingsState.hookedStatus.data,
            reeferAlarm: settingsState.reeferAlarm.data,
            reeferManufacturers: settingsState.reeferManufacturers.data,
            reeferOperationMode: settingsState.reeferOperationMode.data,
            reeferPowerMode: settingsState.reeferPowerMode.data,
            reeferSpeed: settingsState.reeferSpeed.data,
            reeferStatus: settingsState.reeferStatus.data,
            trailerBatteryStatus: settingsState.trailerBatteryStatus.data,
            trailerEventType: settingsState.trailerEventType.data,
            trailerManufacturers: settingsState.trailerManufacturers.data,
            trailers: staticData.trailers.data.array,
            trailersStatus: dynamicMonitoring.trailerStatus.data,
            vehicleCategories: settingsState.vehicleCategories.data,
            vehicleConnectionsByTrailer: dynamicMonitoring.vehicleConnectionsByTrailer,
            vehicleDriverHoursStatus: dynamicMonitoring.vehicleDriverHoursStatus.data,
            vehiclesHash: staticData.vehicles.data.hash,
            vehicleTypes: settingsState.vehicleTypes.data,
        }),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: ConnectTrailerDialogProps): DispatchProps => {
    return {
        connectTrailer: (trailerId: number) => {
            dispatch(connectTrailerAction(ownProps.vehicle.id, trailerId)).catch(reportError);
        },
    };
};
