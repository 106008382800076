import type { ApiModel, DeepPojoModel, NamedVehicleReference, PojoModel, ResolvedVehicle, Vehicle } from '../ApiClient';
import { DisplayUserPreferencesVehicleDisplayFormat } from '../ApiClient';

const composeVehicleName = (alias?: string, unitId?: string) => {
    if (alias && unitId) {
        return `${alias} / ${unitId}`;
    }
    return alias || unitId;
};

export const formatVehicleName = (
    vehicle:
        | DeepPojoModel<Omit<ResolvedVehicle, keyof ApiModel>>
        | PojoModel<NamedVehicleReference>
        | PojoModel<Vehicle>
        | ResolvedVehicle,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat
): string => {
    const { alias, id, unitId, vin } = vehicle;
    const msisdn =
        'device' in vehicle
            ? vehicle.device && vehicle.device.msisdn
            : 'msisdn' in vehicle
              ? vehicle.msisdn
              : undefined;
    let vehicleName: string | undefined;
    switch (vehicleDisplayNameFormat) {
        case DisplayUserPreferencesVehicleDisplayFormat.Alias:
            vehicleName = alias || unitId;
            break;
        case DisplayUserPreferencesVehicleDisplayFormat.AliasUnitid:
            vehicleName = composeVehicleName(alias, unitId);
            break;
        case DisplayUserPreferencesVehicleDisplayFormat.Msisdn:
            vehicleName = msisdn;
            break;
        case DisplayUserPreferencesVehicleDisplayFormat.Unitid:
            vehicleName = unitId || alias;
            break;
        case DisplayUserPreferencesVehicleDisplayFormat.Vin:
            vehicleName = vin;
            break;
        default:
            throw new Error(`Unknown vehicle format '${vehicleDisplayNameFormat}'`);
    }
    return vehicleName || `[${msisdn || id}]`;
};
