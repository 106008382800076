import type { Impersonator } from '~/common';
import type { SingleTFunction } from '~/components/LanguageSelector';

type FormatChangelogChangeAuthorArgs = {
    impersonator?: Impersonator;
    t: SingleTFunction;
    username?: string;
};

export const formatChangelogChangeAuthor = ({ impersonator, t, username }: FormatChangelogChangeAuthorArgs): string => {
    const usernameWithFallback = username ?? `[${t('unknown')}]`;

    if (impersonator) {
        return `${usernameWithFallback} [${impersonator.username}@${impersonator.customerName}]`;
    }

    return usernameWithFallback;
};
