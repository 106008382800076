import { formatDate, formatDateTime, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareDateObjects } from '~/services/Sorting';

import { DateFormatter, DateTimeFormatter } from '../Formatters';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export const createDateTimeColumn = <T>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Date | undefined,
    options?: CreateColumnOptions
): GridColumnDefinition<T> => ({
    ...options,
    compare: compareDateObjects,
    dataType: 'date',
    excelCellFormat: 'm/d/yy h:mm',
    getCellValue,
    groupingCriteria: groupingCriteriaFactory(formatDate),
    groupTitle,
    groupValueFormatterComponent: DateFormatter,
    minWidth: 150,
    name,
    title,
    valueFormatterComponent: DateTimeFormatter,
    valueTextFormatter: valueTextFormatterFactory(formatDateTime),
});
