import * as React from 'react';
import type { Subtract } from 'utility-types';

import { FileIcon, FileImageIcon, FilePdfIcon } from '~/components/Icons';
import type { CustomIconProps } from '~/components/Icons/customIconProps';
import { FileTypeKeys } from '~/services/ApiClient';

export interface IconFormatterProps {
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
    value: string;
}

export interface IconFormatterInnerProps extends IconFormatterProps {}

const IconFormatter: React.FC<IconFormatterInnerProps> = ({ iconProps, value }) => {
    switch (value) {
        case FileTypeKeys.JPG:
        case FileTypeKeys.PNG:
        case FileTypeKeys.GIF:
        case FileTypeKeys.TIF: {
            return <FileImageIcon {...iconProps} />;
        }
        case FileTypeKeys.PDF: {
            return <FilePdfIcon {...iconProps} />;
        }
        default: {
            return <FileIcon {...iconProps} />;
        }
    }
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
