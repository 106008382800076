import { isUndefined } from '~/libs/utility';
import type { ResolvedReeferStatus } from '~/services/ApiClient';

import type { ReeferStatusResolverArgs } from './models';

export const reeferStatusResolver = (args: ReeferStatusResolverArgs): ResolvedReeferStatus => {
    const { reeferAlarm, reeferOperationMode, reeferPowerMode, reeferSpeed, reeferStatus, reeferStatusData } = args;
    const { alarms, operationMode, powerMode, speed, status } = reeferStatusData;

    const resolvedAlarms = alarms ? alarms.map((alarm) => reeferAlarm[alarm]) : undefined;

    return {
        ...reeferStatusData,
        alarms: resolvedAlarms,
        operationMode: !isUndefined(operationMode) ? reeferOperationMode[operationMode] : undefined,
        powerMode: !isUndefined(powerMode) ? reeferPowerMode[powerMode] : undefined,
        speed: !isUndefined(speed) ? reeferSpeed[speed] : undefined,
        status: !isUndefined(status) ? reeferStatus[status] : undefined,
    };
};
