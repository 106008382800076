import { isUndefined } from '~/libs/utility';
import { SceneRoutes } from '~/routes';

import type { ConstructHistoryUrlArgs } from './models';

export const constructHistoryUrl = ({
    eventType,
    selectedAssetId,
    selectedAssetType,
}: ConstructHistoryUrlArgs): string => {
    return `${SceneRoutes.HISTORY}/${eventType}${isUndefined(selectedAssetType) ? '' : `/${selectedAssetType}`}${
        isUndefined(selectedAssetId) ? '' : `/${selectedAssetId}`
    }`;
};
