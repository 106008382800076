import Typography from '@mui/material/Typography';
import type { FC } from 'react';

import { isEmpty } from '~/libs/utility';

import type { SectionListProps } from './models';
import { SubtypeListContainer } from './styles';

const SectionList: FC<SectionListProps> = (props) => {
    const { emptyText, items } = props;

    if (isEmpty(items)) {
        return <span>{emptyText}</span>;
    }

    return (
        <SubtypeListContainer>
            {items.map((item) => (
                <Typography
                    data-testid={`section-list-item:${item.key ?? item.text}`}
                    key={`section-list-item:${item.key ?? item.text}`}
                    variant="caption"
                >
                    {item.text}
                </Typography>
            ))}
        </SubtypeListContainer>
    );
};

SectionList.displayName = 'SectionList';
export { SectionList };
