import type { FC } from 'react';

import type { MonitoringTrailerEntry } from '~/data/monitoring';

import { MarkerIcon } from '../../../../../MarkerIcon';

import { TrailerIcon } from './styles';

export interface TrailerMarkerIconProps {
    entry: MonitoringTrailerEntry;
}

export interface TrailerMarkerIconInnerProps extends TrailerMarkerIconProps {}

const TrailerMarkerIcon: FC<TrailerMarkerIconInnerProps> = ({ entry }) => (
    <MarkerIcon
        AssetIcon={TrailerIcon}
        courseOverGround={undefined}
        driverActivity={entry.vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.activity}
        speedOverGround={undefined}
    />
);
TrailerMarkerIcon.displayName = 'TrailerMarkerIcon';

export { TrailerMarkerIcon };
