import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LatestMessagesSectionContentClassKey =
    | 'centerContents'
    | 'content'
    | 'failedToLoad'
    | 'failedToLoadButton'
    | 'italicText'
    | 'leftButtonAdornment'
    | 'openConversationButton'
    | 'paperBackground'
    | 'root';

export const LatestMessagesSectionContentStyleRules: StyleRulesCallback<
    Theme,
    {},
    LatestMessagesSectionContentClassKey
> = (theme: Theme) =>
    createStyles({
        centerContents: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
        },
        content: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            height: 135,
            margin: theme.spacing(0, 2),
        },
        failedToLoad: {
            display: 'flex',
            flexDirection: 'column',
        },
        failedToLoadButton: {
            alignSelf: 'center',
            marginTop: theme.spacing(),
        },
        italicText: {
            fontStyle: 'italic',
        },
        leftButtonAdornment: {
            marginRight: theme.spacing(),
        },
        openConversationButton: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        paperBackground: {
            backgroundColor: theme.palette.background.paper,
        },
        root: { backgroundColor: theme.palette.background.paper },
    });
