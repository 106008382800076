import type { SelectChangeEvent } from '@mui/material';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { SettingItemHeaderProps } from '../SettingItemHeader';
import { SettingItemHeader } from '../SettingItemHeader';

import type { SelectItem } from './model';
import type { SelectSettingItemClassKeys } from './styles';

export interface SelectSettingItemProps extends Omit<SettingItemHeaderProps, 'action'> {
    actionDataId: string;
    disabled?: boolean;
    onChange: (event: SelectChangeEvent<number | string>) => void;
    options: SelectItem[];
    selectedOption?: number | string;
    tooltipWhenDisabled?: string;
    tooltipWhenOptionDisabled?: string;
}

export interface SelectSettingItemInnerProps extends SelectSettingItemProps, WithStyles<SelectSettingItemClassKeys> {}

export const SelectSettingItemComponent: React.FC<SelectSettingItemInnerProps> = ({
    actionDataId,
    classes,
    disabled,
    onChange,
    options,
    selectedOption,
    tooltipWhenDisabled,
    tooltipWhenOptionDisabled,
    ...restProps
}) => {
    const action = React.useMemo(
        () => (
            <FormControl className={classes.action} data-id={actionDataId} margin="dense" variant="outlined">
                <Select onChange={onChange} value={selectedOption} variant="outlined">
                    {options.map((item) => {
                        const selectedItemDisabled = item.value === selectedOption && item.isDisabled;

                        const optionItem =
                            selectedItemDisabled && tooltipWhenOptionDisabled ? (
                                <Tooltip title={tooltipWhenOptionDisabled}>
                                    <span className={classes.selectedDisabled}>{item.label}</span>
                                </Tooltip>
                            ) : item.tooltip ? (
                                <Tooltip title={item.tooltip}>
                                    <span>{item.label}</span>
                                </Tooltip>
                            ) : (
                                item.label
                            );

                        return (
                            <MenuItem
                                data-id={`option:${item.label}`}
                                disabled={item.isDisabled}
                                key={item.value}
                                value={item.value}
                            >
                                {optionItem}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        ),
        [actionDataId, classes, onChange, options, selectedOption, tooltipWhenOptionDisabled]
    );

    const disabledActionWithTooltip = React.useMemo(
        () =>
            disabled &&
            tooltipWhenDisabled && (
                <Tooltip title={tooltipWhenDisabled}>
                    <span>{action}</span>
                </Tooltip>
            ),
        [action, disabled, tooltipWhenDisabled]
    );

    return <SettingItemHeader {...restProps} action={disabledActionWithTooltip || action} />;
};
