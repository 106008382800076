import { Tooltip } from '@mui/material';
import * as React from 'react';

import { InlineBadge } from '~/components/InlineBadge';
import { useTranslation } from '~/components/LanguageSelector';

export interface UnreadMessageCountBadgeProps {
    className?: string;
    unreadTextMessageCount: number;
    unreadWorkflowMessageCount: number;
}

export const UnreadMessageCountBadgeComponent: React.FC<UnreadMessageCountBadgeProps> = ({
    className,
    unreadTextMessageCount,
    unreadWorkflowMessageCount,
}) => {
    const { t } = useTranslation();

    const unreadMessageCount = unreadTextMessageCount + unreadWorkflowMessageCount;

    if (unreadMessageCount > 0) {
        const tooltipContent = (
            <div>
                {t('unread-text-message-count', { count: unreadTextMessageCount })}
                <br />
                {t('unread-workflow-message-count', { count: unreadWorkflowMessageCount })}
            </div>
        );

        return (
            <Tooltip title={tooltipContent}>
                <span className={className}>
                    <InlineBadge dataId="unread-message-count" value={unreadMessageCount} />
                </span>
            </Tooltip>
        );
    }

    return null;
};
