import type { StaticDataStoreState } from '~/common';

import { ACTIONS, ACTION_KEYS } from './actionTypes';
import type { ActionTypes, AlertsState } from './models';
import { addAssetNamesToAlerts, getComparedCache, getUpdatedAlerts } from './utils';

const initialState: AlertsState = {
    alertCount: {
        count: 0,
        error: false,
        pending: false,
    },
    alerts: {
        error: false,
        items: [],
        pending: false,
    },
    alertsCache: [],
};

export const alertsSceneReducer = (
    state = initialState,
    action: ActionTypes,
    staticDataState: StaticDataStoreState
): AlertsState => {
    switch (action.type) {
        case ACTIONS.POST_PENDING:
            return { ...state, alerts: { ...state.alerts, pending: true } };
        case ACTIONS.POST_FULFILLED:
            return {
                ...state,
                alerts: {
                    error: false,
                    items: addAssetNamesToAlerts({
                        alerts: action.payload.items,
                        containers: staticDataState.containers.data.hash,
                        drivers: staticDataState.drivers.data.hash,
                        trailers: staticDataState.trailers.data.hash,
                        vehicles: staticDataState.vehicles.data.hash,
                    }),
                    pending: false,
                },
            };
        case ACTIONS.POST_REJECTED:
            return { ...state, alerts: { ...state.alerts, error: true } };
        case ACTIONS.DELETE_PENDING:
            return state;
        case ACTIONS.DELETE_FULFILLED:
            return state;
        case ACTIONS.DELETE_REJECTED:
            return state;
        case ACTION_KEYS.CLEAR_ALERTS_DATA:
            return {
                ...initialState,
                alertCount: state.alertCount,
            };
        case ACTION_KEYS.SAVE_INTO_CACHE:
            return {
                ...state,
                alertsCache: getComparedCache(state.alertsCache, action.payload),
            };
        case ACTION_KEYS.CLEAR_CACHE:
            return {
                ...state,
                alertsCache: [],
            };
        case ACTION_KEYS.APPLY_UPDATE:
            if (state.alertsCache.length === 0) {
                return state;
            }
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    items: addAssetNamesToAlerts({
                        alerts: getUpdatedAlerts(state.alerts.items, state.alertsCache),
                        containers: staticDataState.containers.data.hash,
                        drivers: staticDataState.drivers.data.hash,
                        trailers: staticDataState.trailers.data.hash,
                        vehicles: staticDataState.vehicles.data.hash,
                    }),
                },
                alertsCache: [],
            };
        case ACTIONS.GET_ACTIVE_FULFILLED:
            return {
                ...state,
                alertCount: {
                    ...state.alertCount,
                    count: action.payload.count,
                    pending: false,
                },
            };
        case ACTIONS.GET_ACTIVE_REJECTED:
            return {
                ...state,
                alertCount: {
                    ...state.alertCount,
                    error: true,
                    pending: false,
                },
            };
        case ACTIONS.GET_ACTIVE_PENDING:
            return {
                ...state,
                alertCount: {
                    ...state.alertCount,
                    pending: true,
                },
            };
        case ACTION_KEYS.CHANGE_ACTIVE_COUNT:
            return {
                ...state,
                alertCount: {
                    ...state.alertCount,
                    count: action.payload,
                },
            };
        default:
            return state;
    }
};
