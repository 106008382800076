import type { AssetReference, AssetType } from '~/services/ApiClient';

import type { CustomSceneUserPreferencesType } from './models';

export enum SceneAssetSelectorViewMode {
    ASSETS = 1,
    DEPOTS = 2,
    GROUPS = 3,
}

export interface SceneAssetSelectorUserPreferences {
    collapsedAssetTypes: AssetType[];
    expandedDepotIds: number[];
    expandedGroupIds: number[];
    selectedAssetIds?: AssetReference[];
    selectedParentGroupId?: number;
    viewMode: SceneAssetSelectorViewMode;
}

export const defaultSceneAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences = {
    collapsedAssetTypes: [],
    expandedDepotIds: [],
    expandedGroupIds: [],
    selectedAssetIds: undefined,
    viewMode: SceneAssetSelectorViewMode.ASSETS,
};

export const requiredSceneAssetSelectorUserPreferencesFactory = <T>(
    preferencesKey: string,
    defaultState?: T,
    processResponse?: (values: T) => T
): CustomSceneUserPreferencesType<T> => ({
    defaultState: defaultState ?? defaultSceneAssetSelectorUserPreferences,
    key: preferencesKey,
    ...(processResponse && { processResponse }),
});
