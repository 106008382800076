import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type SectionContentMessageClassKey = 'message' | 'root';

export const useStyles = makeStyles<Theme, {}, SectionContentMessageClassKey>((theme: Theme) => ({
    message: {
        alignItems: 'center',
        display: 'flex',
        fontStyle: 'italic',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    root: {
        backgroundColor: theme.palette.background.default,
        height: 135,
    },
}));
