import ArrowBack from '@mui/icons-material/ArrowBack';
import { Avatar, Divider, Typography } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TitledIconButton } from '~/components/TitledIconButton';
import { isUndefined } from '~/libs/utility';

import type { ProfileSubpageShellProps } from './models';
import { useStyles } from './styles';

const ProfileSubpageShell: FC<ProfileSubpageShellProps> = (props) => {
    const { children, hasChildren, icon, onBack, primaryTitle, secondaryTitle } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const header = useMemo(() => {
        return (
            <header className={classes.header} data-id="profile-subpage-header">
                {onBack && (
                    <TitledIconButton data-id="back-icon" onClick={onBack} title={t('back')}>
                        <ArrowBack />
                    </TitledIconButton>
                )}
                <div className={classes.infoBox}>
                    <Avatar className={classes.avatar} data-id="avatar">
                        {icon}
                    </Avatar>
                    <Typography color="textPrimary" data-id="primary-title" variant="subtitle2">
                        {primaryTitle}
                    </Typography>
                    <Typography color="textSecondary" data-id="secondary-title" variant="body2">
                        {secondaryTitle}
                    </Typography>
                </div>
            </header>
        );
    }, [icon, classes, onBack, primaryTitle, secondaryTitle, t]);

    return (
        <section className={classes.profileSection} data-id="profile-subpage-section">
            {header}
            {isUndefined(hasChildren) || hasChildren === true ? (
                <>
                    <Divider />
                    {children}
                </>
            ) : null}
        </section>
    );
};

ProfileSubpageShell.displayName = 'ProfileSubpageShell';
export default ProfileSubpageShell;
