import type { Reducer } from 'redux';

import type { ActionTypes } from '~/data/settings';
import { ActionTypeKeys } from '~/data/settings';
import type { RetrievableData } from '~/reducers';

import type { SettingsDataType, SettingsDetails } from './settings';

export type SettingsStoreState = {
    [k in keyof SettingsDataType]: RetrievableData<SettingsDataType[k]>;
};

export const settingsReducerFactory = (settings: SettingsDetails): Reducer<SettingsStoreState> => {
    const defaultStoreState = Object.keys(settings).reduce(
        (accumulator, key) => ({
            ...accumulator,
            [key]: {
                data: settings[key].defaultState,
                fulfilled: false,
                pending: false,
                rejected: false,
            },
        }),
        {}
    ) as SettingsStoreState;

    return (state: SettingsStoreState = defaultStoreState, action: ActionTypes): SettingsStoreState => {
        switch (action.type) {
            case ActionTypeKeys.SETTINGS_PENDING: {
                const actualSettingState = state[action.meta];

                if (!actualSettingState) {
                    return state;
                }

                return {
                    ...state,
                    [action.meta]: {
                        ...actualSettingState,
                        pending: true,
                    },
                };
            }
            case ActionTypeKeys.SETTINGS_FULFILLED: {
                const actualSettingState = state[action.meta];

                if (!actualSettingState) {
                    return state;
                }

                return {
                    ...state,
                    [action.meta]: {
                        data: settings[action.meta].processPayload(action.payload),
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                };
            }
            case ActionTypeKeys.SETTINGS_REJECTED: {
                const actualSettingState = state[action.meta];

                if (!actualSettingState) {
                    return state;
                }

                return {
                    ...state,
                    [action.meta]: {
                        ...defaultStoreState[action.meta],
                        rejected: true,
                    },
                };
            }
            case ActionTypeKeys.SETTINGS_UPDATE_FULFILLED: {
                const actualSettingState = state[action.meta];

                if (!actualSettingState) {
                    return state;
                }

                return {
                    ...state,
                    [action.meta]: {
                        data: action.payload,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                };
            }

            default:
                return state;
        }
    };
};
