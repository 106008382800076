import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import type { Subtract } from 'utility-types';

export interface CustomNavLinkProps extends Subtract<NavLinkProps, { location?: unknown; replace?: boolean }> {}

export interface CustomNavLinkInnerProps extends RouteComponentProps, CustomNavLinkProps {}

export const CustomNavLinkComponent: React.FunctionComponent<CustomNavLinkInnerProps> = ({
    history,
    location,
    match,
    staticContext,
    to,
    ...restProps
}) => {
    const shouldUseReplace = history.location.pathname === to;

    return <NavLink {...restProps} replace={shouldUseReplace} to={to} />;
};
