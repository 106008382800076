import type { Action } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';

import type { RtdsScheduleChangelogsStoreState } from './components/DetailsPane/components/RtdsScheduleChangelogsSubpage';
import { rtdsScheduleChangelogsReducer } from './components/DetailsPane/components/RtdsScheduleChangelogsSubpage';
import type { RtdsSchedulesStoreState } from './reducers';
import { rtdsSchedulesReducer } from './reducers';

export interface RtdsSchedulesSceneStoreState {
    rtdsScheduleChangelogsRoot: RtdsScheduleChangelogsStoreState;
    rtdsSchedulesRoot: RtdsSchedulesStoreState;
}

export const rtdsSchedulesSceneReducer = (
    state: RtdsSchedulesSceneStoreState | undefined,
    action: Action,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSchedulesSceneStoreState => {
    const rtdsSchedulesRoot = rtdsSchedulesReducer(
        state?.rtdsSchedulesRoot,
        action,
        staticDataStoreState,
        settingsStoreState
    );

    const rtdsScheduleChangelogsRoot = rtdsScheduleChangelogsReducer(
        state?.rtdsScheduleChangelogsRoot,
        action,
        settingsStoreState
    );

    return { rtdsScheduleChangelogsRoot, rtdsSchedulesRoot };
};
