import { createAction } from 'redux-actions';

import { logEvent, setUserId, setUserProperties } from '~/services/Analytics';
import type { PojoModel } from '~/services/ApiClient';
import {
    ApiException,
    AuthenticateTokenRequest,
    AuthenticationApiClient,
    AuthenticationRequest,
    ImpersonateUserRequest,
    createApiModel,
    resolveWhenStatusCodeIn,
} from '~/services/ApiClient';
import { verifyToken } from '~/services/Jwt';

import type {
    AuthenticationSuccessPayload,
    EndImpersonationSuccessPayload,
    ImpersonateUserPayload,
    LogoutSuccessPayload,
    VerifyTokenSuccessPayload,
} from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes, LoginTypes } from './actionTypes';

export type Credentials = PojoModel<AuthenticationRequest>;

export interface TokenRequest {
    impersonatorToken?: string;
    token: string;
}

export interface JwtDecodedToken {
    act?: {
        sub: string;
    };
    data: {
        cid: number;
        customerName: string;
        fullName: string;
        username: string;
    };
    exp: number;
    sub: string;
}

export interface Identity {
    customer: string;
    username: string;
}

async function loginExecutor(credentials: Credentials): Promise<AuthenticationSuccessPayload> {
    const request = createApiModel(AuthenticationRequest, credentials);
    let success = true;
    try {
        return await AuthenticationApiClient.authenticate(request);
    } catch (ex) {
        success = false;
        if (ApiException.isApiException(ex)) {
            return resolveWhenStatusCodeIn<AuthenticationSuccessPayload>(400)(ex);
        }
        throw ex;
    } finally {
        logEvent('authentication', 'login', 'Login', { value: success ? 1 : 0 });
    }
}

function loginTokenExecutor(accessToken: string): Promise<AuthenticationSuccessPayload> {
    const request = createApiModel(AuthenticateTokenRequest, { token: accessToken });
    return AuthenticationApiClient.authenticateToken(request).catch(resolveWhenStatusCodeIn(400));
}

export const loginMetaCreator = ({ customerCareUsername }: Credentials): LoginTypes =>
    customerCareUsername ? LoginTypes.CUSTOMERCARE : LoginTypes.USER;

export const loginAction = createAction<Promise<AuthenticationSuccessPayload>, LoginTypes, Credentials>(
    ActionTypePrefixes.AUTHENTICATION,
    loginExecutor,
    loginMetaCreator
);

export const loginTokenAction = createAction<Promise<AuthenticationSuccessPayload>, LoginTypes, string>(
    ActionTypePrefixes.AUTHENTICATION,
    loginTokenExecutor,
    () => LoginTypes.USER
);

export const logoutAction = createAction(ActionTypePrefixes.LOGOUT, () => {
    logEvent('authentication', 'logout', 'logout');
    setUserProperties({
        actor: undefined,
        cid: undefined,
        customerName: undefined,
        username: undefined,
    });
    setUserId(undefined);

    return Promise.resolve<LogoutSuccessPayload>({});
});

export const endImpersonationAction = createAction(ActionTypePrefixes.IMPERSONATIONENDED, () => {
    logEvent('authentication', 'endimpersonation', 'endimpersonation');

    return Promise.resolve<EndImpersonationSuccessPayload>({});
});

async function verifyTokenExecutor(request: TokenRequest): Promise<VerifyTokenSuccessPayload> {
    const result = {
        decodedImpersonatorJwt: request.impersonatorToken
            ? await verifyToken<JwtDecodedToken>(request.impersonatorToken)
            : undefined,
        decodedJwt: await verifyToken<JwtDecodedToken>(request.token),
    };

    setUserProperties({
        actor: result.decodedJwt.act && result.decodedJwt.act.sub,
        cid: result.decodedJwt.data.cid,
        customerName: result.decodedJwt.data.customerName,
        username: result.decodedJwt.data.username,
    });
    setUserId(result.decodedJwt.sub);

    return result;
}

export const verifyTokenAction = createAction(ActionTypePrefixes.VERIFYTOKEN, verifyTokenExecutor);

const impersonateUserExecutor = async (
    customer: string,
    username: string | undefined
): Promise<ImpersonateUserPayload> => {
    const request = createApiModel(ImpersonateUserRequest, { customer, username });
    const { token } = await AuthenticationApiClient.impersonateUser(request);

    return { decoded: await verifyToken<JwtDecodedToken>(token), token };
};

export const impersonateUserAction = createAction(ActionTypePrefixes.IMPERSONATEUSER, impersonateUserExecutor);

export const tokenExpiredAction = createAction(ActionTypeKeys.TOKENEXPIRED, () => {
    logEvent('authentication', 'token-expired', 'token expired');
});
