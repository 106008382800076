import type { ComponentClass } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { ensureUserPreferences } from '~/components/UserPreferences';

import { ChangelogSubpage as ChangelogSubpageComponent } from './component';
import type { ChangelogSubpageProps } from './models';
import { requiredChangelogSubpageUserPreferences } from './preferences';

export * from './components';
export * from './models';

export const ChangelogSubpage = <T>(): ComponentClass<ChangelogSubpageProps<T>> =>
    compose<ChangelogSubpageProps<T>, ChangelogSubpageProps<T>>(
        setDisplayName('ChangelogSubpage'),
        ensureUserPreferences([requiredChangelogSubpageUserPreferences])
    )(ChangelogSubpageComponent);
