import { isUndefined } from '../utility';

import { CRUD_ACTIONS } from './consts';
import type { CrudActions } from './models';

const isEditDialog = (action?: CrudActions) => {
    return (
        !isUndefined(action) &&
        (action === CRUD_ACTIONS.CREATE || action === CRUD_ACTIONS.EDIT || action === CRUD_ACTIONS.CLONE)
    );
};

const isDeleteDialog = (action?: CrudActions) => {
    return !isUndefined(action) && action === CRUD_ACTIONS.DELETE;
};

export { isDeleteDialog, isEditDialog };
