import { formatFuel, formatVolumeLiquid } from '@fv/components';
import type { FuelValue } from '@fv/converters';
import { convertFuel, convertVolumeLiquid, getFuelUnit, getVolumeLiquidUnit } from '@fv/converters';
import type { Duration as MomentDuration } from 'moment';
import moment from 'moment';
import type { FC } from 'react';

import { isNumber, isUndefined } from '~/libs/utility';
import { durationToDays, exportConvertorFactory } from '~/services/Convertors';
import { exportFormatterFactory, formatDate, valueTextFormatterFactory } from '~/services/Formatters';
import { formatDuration } from '~/services/Formatters/formatDuration';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareDateObjects, compareFactory, compareNumbers, compareShifts, compareStrings } from '~/services/Sorting';

import type { DefaultOptions, TypeMap } from './models';

const DurationFormatter: FC<{ value: MomentDuration }> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    if (isNumber(value)) {
        return <>{formatDuration(moment.duration(value))}</>;
    }

    return <>{formatDuration(value as unknown as MomentDuration | number)}</>;
};

const DateFormatter: FC<{ value: Date }> = ({ value }) => {
    if (!value) {
        return null;
    }

    return <>{formatDate(value)}</>;
};

const DEFAULT_OPTIONS: DefaultOptions = {
    date: () => ({
        compare: compareDateObjects,
        dataType: 'date',
        excelCellFormat: 'm/d/yy',
        valueFormatterComponent: DateFormatter,
    }),
    duration: ({ t }) => ({
        align: 'right',
        compare: compareNumbers,
        dataType: 'duration',
        excelCellFormat: `[h]"${t('unit-hour')}" mm"${t('unit-minute')}"`,
        exportValueFormatter: exportFormatterFactory(durationToDays),
        valueFormatterComponent: DurationFormatter,
    }),
    fuel: ({ precision, unitSystem }) => {
        const FuelFormatter: FC<{ value: TypeMap['fuel'] }> = ({ value }) => {
            if (!value) {
                return null;
            }
            return <>{formatFuel({ precision, unitSystem, value })}</>;
        };

        return {
            align: 'right',
            compare: compareFactory(
                (value: FuelValue) => convertFuel({ precision, unitSystem, value }),
                compareNumbers
            ),
            dataType: 'number',
            excelCellFormat: (value) => (value ? `0.0 "${getFuelUnit(unitSystem, value.type)}"` : '0.0'),
            exportValueFormatter: exportConvertorFactory((value) => convertFuel({ precision, unitSystem, value })),
            getFilterValue: (value) => value && convertFuel({ precision, unitSystem, value }),
            groupingCriteria: groupingCriteriaFactory((value) => formatFuel({ precision, unitSystem, value })),
            valueFormatterComponent: FuelFormatter,
            valueTextFormatter: valueTextFormatterFactory((value) => formatFuel({ precision, unitSystem, value })),
        };
    },
    shift: () => ({ compare: compareShifts, dataType: 'string' }),
    string: () => ({ compare: compareStrings, dataType: 'string' }),
    volume_liquid: ({ precision, unitSystem }) => {
        const VolumeLiquidFormatter: FC<{ value: number }> = ({ value }) => {
            if (isUndefined(value)) {
                return null;
            }
            return <>{formatVolumeLiquid({ precision, unitSystem, value })}</>;
        };

        return {
            align: 'right',
            compare: compareNumbers,
            dataType: 'number',
            excelCellFormat: `0.0 "${getVolumeLiquidUnit(unitSystem)}"`,
            exportValueFormatter: exportConvertorFactory((value) =>
                convertVolumeLiquid({ precision, unitSystem, value })
            ),
            getFilterValue: (value) =>
                isUndefined(value) ? undefined : convertVolumeLiquid({ precision, unitSystem, value }),
            groupingCriteria: groupingCriteriaFactory((value) => formatVolumeLiquid({ precision, unitSystem, value })),
            valueFormatterComponent: VolumeLiquidFormatter,
            valueTextFormatter: valueTextFormatterFactory((value) =>
                formatVolumeLiquid({ precision, unitSystem, value })
            ),
        };
    },
};

export { DEFAULT_OPTIONS };
