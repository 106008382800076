import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type BooleanFieldClassKey = 'defaultCheckbox' | 'fieldContainer' | 'root';
export const BooleanFieldStyleRules: StyleRulesCallback<Theme, {}, BooleanFieldClassKey> = (theme: Theme) =>
    createStyles({
        defaultCheckbox: {
            marginTop: theme.spacing(-1),
        },
        fieldContainer: {
            display: 'flex',
            width: 442,
        },
        root: {
            marginBottom: theme.spacing(2),
        },
    });
