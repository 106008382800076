import { Typography } from '@mui/material';
import type { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import type { ClassNameMap, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { DateFormatter } from '~/components/Formatters';

import type { TimelineItemClassKey, TimelineSegmentType } from '../TimelineItem';
import { TimelineItem } from '../TimelineItem';

import type { DaySeparatorClassKey } from './styles';

export interface DaySeparatorProps {
    date: Date;
    ListItemProps?: Omit<Partial<MuiListItemProps>, 'button'>;
    segmentType: TimelineSegmentType;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
}

export interface DaySeparatorInnerProps extends DaySeparatorProps, WithStyles<DaySeparatorClassKey> {}

export const DaySeparatorComponent: React.FC<DaySeparatorInnerProps> = ({
    classes,
    date,
    ListItemProps,
    segmentType,
    timelineClasses,
}) => {
    const mergedTimelineClasses = {
        ...timelineClasses,
        content: classNames(timelineClasses?.content, classes.content),
    };

    return (
        <TimelineItem
            classes={mergedTimelineClasses}
            dataId={`day-separator:${date.getTime()}`}
            ListItemProps={ListItemProps}
            segmentType={segmentType}
        >
            <Typography data-id="date" variant="subtitle2">
                <DateFormatter format="PPPP" value={date} />
            </Typography>
        </TimelineItem>
    );
};
