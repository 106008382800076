import type * as i18next from 'i18next';

import type { GridColumnDefinition } from '~/components/Grid';
import { createDateTimeColumn, exportToExcel } from '~/components/Grid';
import { flatten } from '~/libs/utility';
import { compareNumbers } from '~/services/Sorting';

import type { ChartState, TimeSeriesChartDefinition } from '../../models';

export function exportTimeSeriesChartToExcel<T>(
    chart: TimeSeriesChartDefinition<T>,
    chartState: ChartState,
    exportItems: Array<T>,
    fileName: string,
    sheetName: string,
    t: i18next.TFunction
): void {
    // Columns definitions
    const dateColumn = createDateTimeColumn('timestamp', t('timestamp'), t('general'), chart.getItemDate);

    const seriesColumnsNested = chart.series
        .filter((serie) => chartState.visibleSeries.includes(serie.name))
        .map((serie): GridColumnDefinition<T>[] => {
            switch (serie.type) {
                case 'line': {
                    return [
                        {
                            align: 'right',
                            compare: compareNumbers,
                            dataType: 'number',
                            excelCellFormat: `0.0 "${serie.unit}"`,
                            getCellValue: serie.getValue,
                            groupTitle: t('general'),
                            name: serie.name,
                            title: serie.title,
                        },
                    ];
                }
                case 'area': {
                    return [
                        {
                            align: 'right',
                            compare: compareNumbers,
                            dataType: 'number',
                            excelCellFormat: `0.0 "${serie.unit}"`,
                            getCellValue: (i: T) => {
                                const values = serie.getValue(i);
                                return values && values[0];
                            },
                            groupTitle: t('general'),
                            name: `${serie.name}--min`,
                            title: `${serie.title} (min)`,
                        },
                        {
                            align: 'right',
                            compare: compareNumbers,
                            dataType: 'number',
                            excelCellFormat: `0.0 "${serie.unit}"`,
                            getCellValue: (i: T) => {
                                const values = serie.getValue(i);
                                return values && values[1];
                            },
                            groupTitle: t('general'),
                            name: `${serie.name}--max`,
                            title: `${serie.title} (max)`,
                        },
                    ];
                }
                default:
                    throw new Error('Unknown series type');
            }
        });

    const columnDefinitions = [dateColumn, ...flatten(seriesColumnsNested)];
    const visibleColumns = columnDefinitions.map((c) => c.name);
    const columnOrder = columnDefinitions.map((c) => c.name);

    exportToExcel(
        exportItems,
        columnDefinitions,
        [{ columnName: 'timestamp', direction: 'asc' }],
        columnOrder,
        visibleColumns,
        fileName,
        sheetName
    );
}
