import type { AnimatedMarkerProps } from '@fv/components';
import { AnimatedMarker, AnimatedMarkerWithPopup } from '@fv/components';
import type { LeafletMouseEvent } from 'leaflet';
import { useMemo } from 'react';

import { AssetMarker, AssetMarkerPopup } from '~/components/Map';

import { markerRiseOffset, selectedMarkerZIndexOffset } from '../../constants';

import type { EntryMarkerProps } from './models';
import { getPopupKey } from './utils';

const EntryMarker = <T,>(props: EntryMarkerProps<T>) => {
    const {
        entry,
        entryEquals,
        getAssetIdentifier,
        mapKey,
        onAssetContextMenu,
        onAssetSelect,
        position,
        selected = false,
        showTooltip,
    } = props;

    const newProps: AnimatedMarkerProps<T> = useMemo(
        () => ({
            entry,
            entryEquals,
            eventHandlers: {
                click: () => onAssetSelect(entry),
                contextmenu:
                    onAssetContextMenu &&
                    (({ originalEvent }: LeafletMouseEvent) =>
                        onAssetContextMenu(originalEvent, getAssetIdentifier(entry))),
            },
            iconOptions: { className: '', iconAnchor: [12, 12], iconSize: [24, 24] },
            position,
            riseOffset: markerRiseOffset,
            riseOnHover: !selected,
            updateAnimationDuration: 2000,
            zIndexOffset: selected ? selectedMarkerZIndexOffset : 0,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [position, selected]
    );

    if (showTooltip) {
        return (
            <AnimatedMarkerWithPopup
                {...newProps}
                popupContent={<AssetMarkerPopup entry={entry} mapKey={mapKey} />}
                popupKey={getPopupKey(entry)}
            >
                {({ animating, duration }) => (
                    <AssetMarker
                        animationDuration={duration}
                        entry={entry}
                        mapKey={mapKey}
                        selected={selected}
                        updateAnimationVisible={animating}
                    />
                )}
            </AnimatedMarkerWithPopup>
        );
    }

    return (
        <AnimatedMarker {...newProps}>
            {({ animating, duration }) => (
                <AssetMarker
                    animationDuration={duration}
                    entry={entry}
                    mapKey={mapKey}
                    selected={selected}
                    updateAnimationVisible={animating}
                />
            )}
        </AnimatedMarker>
    );
};

EntryMarker.displayName = 'EntryMarker';
export { EntryMarker };
