import type { DriverActivitiesReportStoreState } from '~/data/monitoring';
import type { RetrievableData } from '~/reducers';
import type { DriverEvent } from '~/services/ApiClient';

import type { ActionTypes } from './data/actionTypes';
import { ActionTypeKeys } from './data/actionTypes';

export const defaultDriverEventsState: RetrievableData<DriverEvent[]> = {
    data: [],
    fulfilled: false,
    pending: false,
    rejected: false,
};

export const defaultStoreState: DriverActivitiesReportStoreState = {
    driverEvents: defaultDriverEventsState,
};

export const driverActivitiesReportStoreReducer = (
    state: DriverActivitiesReportStoreState = defaultStoreState,
    action: ActionTypes
): DriverActivitiesReportStoreState => {
    switch (action.type) {
        case ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_PENDING:
            return {
                ...state,
                driverEvents: {
                    ...defaultDriverEventsState,
                    pending: true,
                },
            };
        case ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_FULFILLED:
            return {
                ...state,
                driverEvents: {
                    ...defaultDriverEventsState,
                    data: action.payload.items,
                    fulfilled: true,
                },
            };
        case ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_REJECTED:
            return {
                ...state,
                driverEvents: {
                    ...defaultDriverEventsState,
                    rejected: true,
                },
            };
        default:
            return state;
    }
};
