import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PositionWorkflowFieldValue } from '~/services/ApiClient';
import { formatCoordinates } from '~/services/Formatters';

import { GeolocationPreview } from './components/GeolocationPreview';
import type { GeolocationFieldInnerProps } from './models';
import { useStyles } from './styles';

const GeolocationField: FC<GeolocationFieldInnerProps> = (props) => {
    const { dataId, label, value } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const paperRef = useRef<HTMLDivElement>(null);

    const handlePositionInfoClick = () => {
        setOpen(true);
    };

    const handleClosePreview = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (paperRef.current) {
            paperRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [open]);

    const positionValue = value as PositionWorkflowFieldValue | undefined;
    const formattedPosition = positionValue?.value ? formatCoordinates(t, positionValue?.value) : undefined;

    return (
        <>
            <TextField
                data-id={dataId}
                fullWidth
                InputProps={{
                    classes: {
                        disabled: classes.readOnlyUnderlineDisabled,
                        inputAdornedEnd: classes.inputAdornedEnd,
                        root: classes.readOnlyRoot,
                        underline: classes.readOnlyUnderline,
                    },
                    endAdornment: positionValue?.value && (
                        <InfoOutlined
                            className={classes.messageIconInfo}
                            data-id="info-icon"
                            onClick={handlePositionInfoClick}
                        />
                    ),
                    readOnly: true,
                }}
                label={label}
                size="small"
                value={formattedPosition}
                variant="filled"
            />

            <div className={classes.popperRef}>
                {open && (
                    <Paper className={classes.paper} elevation={4} ref={paperRef}>
                        {positionValue?.value && (
                            <GeolocationPreview onClose={handleClosePreview} position={positionValue.value} />
                        )}
                    </Paper>
                )}
            </div>
        </>
    );
};

GeolocationField.displayName = 'GeolocationField';
export default GeolocationField;
