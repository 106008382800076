import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SceneListViewClassKey = 'root';
export const firstColumnPaddingLeft = 40;
export const SceneListViewStyleRules: StyleRulesCallback<Theme, {}, SceneListViewClassKey> = (theme: Theme) => {
    return createStyles({
        root: {
            [`& thead > tr > th:first-child, tbody > tr > td:first-child`]: {
                // !important needed to overwrite inline styles injected by MUI
                // paddingLeft: `${firstColumnPaddingLeft}px !important`,
            },
            backgroundColor: theme.palette.background.paper,
            flexGrow: 1,
        },
    });
};
