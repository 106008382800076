import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import { AssetConnectionStatus } from '~/services/ApiClient';

import { getVehicleSearchText } from './getVehicleSearchText';
import type { FilterVehiclesArgs } from './models';

export const filterVehicles = (args: FilterVehiclesArgs): MonitoringVehicleEntry[] => {
    const { driverDisplayFormat, entries, filter, searchQuery, t, vehicleNameDisplayFormat } = args;
    if ((!filter || Object.values(filter).every((v) => isUndefined(v))) && !searchQuery) {
        return entries;
    }

    const searchQueryLowerCase = searchQuery?.toLowerCase();
    return entries.filter((entry: MonitoringVehicleEntry) => {
        const filterByVehicleCategoryCondition = !filter?.vehicleCategory
            ? true
            : entry.vehicle.category?.id === filter.vehicleCategory.id;

        const filterBySearchQuery = searchQueryLowerCase
            ? getVehicleSearchText({
                  driverNameDisplayFormat: driverDisplayFormat,
                  entry,
                  t,
                  vehicleNameDisplayFormat,
              }).includes(searchQueryLowerCase)
            : true;

        const filterByConnectionStatusCondition = !filter?.connectionStatus
            ? true
            : filter.connectionStatus === AssetConnectionStatus.CONNECTED
              ? !isUndefined(entry.trailerConnection)
              : isUndefined(entry.trailerConnection);

        return filterByVehicleCategoryCondition && filterBySearchQuery && filterByConnectionStatusCondition;
    });
};
