import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleEditorDialogFormClassKey =
    | 'actionButtons'
    | 'backDrop'
    | 'body'
    | 'enumerationInput'
    | 'form'
    | 'formContent'
    | 'leftPane'
    | 'root'
    | 'sectionsListItem'
    | 'sectionTitle'
    | 'textInput'
    | 'toggleInput'
    | 'vehicleIcon';
export const VehicleEditorDialogFormStyleRules: StyleRulesCallback<Theme, {}, VehicleEditorDialogFormClassKey> = (
    theme: Theme
) =>
    createStyles({
        actionButtons: {
            height: '36px',
        },
        backDrop: {
            color: theme.palette.primary.main,
            position: 'absolute',
            zIndex: theme.zIndex.drawer,
        },
        body: {
            display: 'flex',
            overflow: 'hidden',
        },
        enumerationInput: {
            width: 400,
        },
        form: {
            backgroundColor: theme.palette.common.white,
            display: 'inline',
            overflowX: 'hidden',
            overflowY: 'scroll',
            width: 788,
        },
        formContent: {
            marginLeft: theme.spacing(2),
        },
        leftPane: {
            borderRight: `1px solid ${theme.palette.divider}`,
            display: 'inline',
            float: 'left',
            overflow: 'hidden',
            width: 220,
        },
        root: {
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.fontSize,
            height: '100%',
        },
        sectionsListItem: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        sectionTitle: {
            flexGrow: 1,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '24px',
            marginBottom: theme.spacing(3),
            paddingTop: theme.spacing(1),
        },
        textInput: {
            marginBottom: theme.spacing(3),
            width: 400,
        },
        toggleInput: {
            marginLeft: 'auto',
        },
        vehicleIcon: {
            color: theme.functionalItemsColors.assetType.vehicle.value,
        },
    });
