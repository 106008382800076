import type { Action, ActionMeta, BaseAction } from '~/types';

import type { Feature } from '../models';

export enum ActionTypeKeys {
    FEATURES_DISABLEALL = 'FEATURES_DISABLEALL',
    FEATURES_ENABLEALL = 'FEATURES_ENABLEALL',
    FEATURES_RESETTODEFAULT = 'FEATURES_RESETTODEFAULT',
    FEATURES_SETFEATUREFLAGS = 'FEATURES_SETFEATUREFLAGS',
    FEATURES_TOGGLE = 'FEATURES_TOGGLE',
}

export interface SetFeatureFlagsPayload {
    enabled: boolean;
}

export type ActionTypes =
    | Action<ActionTypeKeys.FEATURES_SETFEATUREFLAGS, SetFeatureFlagsPayload>
    | ActionMeta<ActionTypeKeys.FEATURES_TOGGLE, undefined, Feature>
    | BaseAction<ActionTypeKeys.FEATURES_DISABLEALL>
    | BaseAction<ActionTypeKeys.FEATURES_ENABLEALL>
    | BaseAction<ActionTypeKeys.FEATURES_RESETTODEFAULT>;
