import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ResetPasswordNoticeClassKeys = 'link' | 'root';

export const useStyles = makeStyles<Theme, {}, ResetPasswordNoticeClassKeys>((theme) => ({
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
        color: theme.palette.primary.main,
        display: 'block',
        margin: theme.spacing(3, 0, 0),
        textAlign: 'center',
        textDecoration: 'none',
    },
    root: {
        margin: theme.spacing(3, 5),
    },
}));
