import type { LocationState } from 'history';
import * as React from 'react';
import type { RouteComponentProps, StaticContext } from 'react-router';

import type { LogoutReason } from '~/components/AuthenticationData';
import { LoginTypes } from '~/data/authentication';

export interface LogoutStateProps {
    isAuthenticated: boolean;
    loginType?: LoginTypes;
}

export interface LogoutLocationState {
    from?: LocationState;
    reason?: LogoutReason;
}

export interface LogoutDispatchProps {
    logout: () => Promise<void>;
}

export interface LogoutProps extends RouteComponentProps<{}, StaticContext, LogoutLocationState | undefined> {}

export interface LogoutInnerProps extends LogoutProps, LogoutStateProps, LogoutDispatchProps {}

export class LogoutComponent extends React.Component<LogoutInnerProps> {
    private redirectToLogin = () => {
        const loginPagePath = this.props.loginType === LoginTypes.CUSTOMERCARE ? '/customer-care' : '/login';
        this.props.history.push(loginPagePath, this.props.location.state);
    };

    public async componentDidMount(): Promise<void> {
        if (this.props.isAuthenticated) {
            await this.props.logout();
        }

        this.redirectToLogin();
    }

    public render() {
        return null;
    }
}
