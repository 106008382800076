import moment from 'moment';

import type { ResolvedMessage } from '~/services/ApiClient';
import { IncomingMessage, OptimisticOutgoingConversationMessage, OutgoingMessage } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { ListItem } from './listItem';

export const buildListItem = (message: ResolvedMessage): ((prevMessage?: ResolvedMessage) => ListItem) => {
    return memoizeOne((prevMessage?: ResolvedMessage): ListItem => {
        const firstMessageOfDay =
            !prevMessage || !moment(message.value.dateTime).isSame(prevMessage.value.dateTime, 'day');
        const firstMessageOfGroup =
            firstMessageOfDay ||
            !(
                (prevMessage?.value instanceof IncomingMessage && message.value instanceof IncomingMessage) ||
                ((prevMessage?.value instanceof OutgoingMessage ||
                    prevMessage?.value instanceof OptimisticOutgoingConversationMessage) &&
                    (message.value instanceof OutgoingMessage ||
                        message.value instanceof OptimisticOutgoingConversationMessage) &&
                    prevMessage?.value.author === message.value.author)
            );

        return {
            firstMessageOfDay,
            firstMessageOfGroup,
            message,
        };
    });
};
