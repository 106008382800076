import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { PercentageFormatter, ViolationFieldFormatterFactory } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { ViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';

import { SectionName } from '../../constants';

import { useStyles } from './styles';

export interface DutySectionProps {
    dailyDuty?: ViolationField<Duration>;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    monthlyDuty?: ViolationField<Duration>;
    monthlyEffectivityPercentage?: number;
    toggleCollapsed: () => void;
    weeklyDuty?: ViolationField<Duration>;
}

export interface DutySectionInnerProps extends DutySectionProps, InjectedTranslationProps {}

export const DutySectionComponent: React.FC<DutySectionInnerProps> = ({
    dailyDuty,
    dragHandleElement,
    isCollapsed,
    monthlyDuty,
    monthlyEffectivityPercentage,
    t,
    toggleCollapsed,
    weeklyDuty,
}) => {
    const classes = useStyles();

    const ViolationMomentDurationFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(moment.duration(v))),
        []
    );

    const keyValueItems: KeyValueItem[] = [
        {
            label: formatDriverSubpageListItemLabel(t, 'duty-daily-duty'),
            value: dailyDuty ? (
                <ViolationMomentDurationFormatter
                    fieldName={formatDriverSubpageListItemLabel(t, 'duty-daily-duty')}
                    t={t}
                    value={dailyDuty}
                />
            ) : undefined,
            valueDataId: 'daily-duty',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'duty-weekly-duty'),
            value: weeklyDuty ? (
                <ViolationMomentDurationFormatter
                    fieldName={formatDriverSubpageListItemLabel(t, 'duty-weekly-duty')}
                    t={t}
                    value={weeklyDuty}
                />
            ) : undefined,
            valueDataId: 'weekly-duty',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'duty-monthly-duty'),
            value: monthlyDuty ? (
                <ViolationMomentDurationFormatter
                    fieldName={formatDriverSubpageListItemLabel(t, 'duty-monthly-duty')}
                    t={t}
                    value={monthlyDuty}
                />
            ) : undefined,
            valueDataId: 'monthly-dut',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'duty-monthly-driver-duty'),
            value: !isUndefined(monthlyEffectivityPercentage) ? (
                <span className={classes.simpleValue}>
                    <PercentageFormatter precision={2} value={monthlyEffectivityPercentage} />
                </span>
            ) : undefined,
            valueDataId: 'monthly-driver-duty',
        },
    ];

    return (
        <ReorderableSection
            dataId={SectionName.DUTY}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.DUTY}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="property-list" />
        </ReorderableSection>
    );
};
