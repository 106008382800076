import type { AssetGroup } from '~/services/ApiClient';

import type { CalculationResult } from './models';

interface CalculateUnderlyingVehicleIdsAndSubgroupsArgs {
    assetGroup: AssetGroup;
    predicate?: (vehicleId: number) => boolean;
    result: CalculationResult;
}

export const calculateUnderlyingVehicleIdsAndSubgroups = ({
    assetGroup,
    predicate,
    result,
}: CalculateUnderlyingVehicleIdsAndSubgroupsArgs): CalculationResult => {
    let sumOfSubgroups = result.subgroups;
    let concatenatedVehicles = assetGroup.vehicleIds
        ? result.vehicleIds.concat(assetGroup.vehicleIds)
        : result.vehicleIds;
    if (assetGroup.assetGroups) {
        assetGroup.assetGroups.forEach((group) => {
            const recursion = calculateUnderlyingVehicleIdsAndSubgroups({
                assetGroup: group,
                predicate,
                result: { driverIds: [], subgroups: sumOfSubgroups, vehicleIds: concatenatedVehicles },
            });
            concatenatedVehicles = recursion.vehicleIds;
            sumOfSubgroups = recursion.subgroups + 1;
        });
    }

    const enabledAssetIds = predicate ? concatenatedVehicles.filter(predicate) : concatenatedVehicles;

    return {
        driverIds: [],
        enabledAssetIds,
        subgroups: sumOfSubgroups,
        vehicleIds: concatenatedVehicles,
    };
};
