import { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { InactivityMonitor, withScreenAnalytics } from '~/components/Analytics';
import { LandingPage } from '~/components/Navigation';
import { NotAuthorizedPageSnackbar } from '~/components/NotAuthorizedPageSnackbar';
import { ReloadPage } from '~/components/ReloadPage';
import { SaveUserPreferencesStatusMonitor } from '~/components/SaveUserPreferencesMonitor';
import { SignalRConnectionMonitor } from '~/components/SignalRConnectionMonitor';
import { SynchronizationClock } from '~/components/SynchronizationClock';
import { HandleUserPreferencesStateUpdate, LoadUserPreferencesStatusMonitor } from '~/components/UserPreferences';
import { CustomerCareLogin, EndImpersonation, Logout, SingleSignOn, UserLogin } from '~/scenes/Authentication';
import { ResetPassword } from '~/scenes/ResetPassword';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';
import { NotificationsClient } from '~/services/SignalR';

import { GeozoneNotifications } from './components/GeozoneNotifications';
import { SceneLoader } from './components/SceneLoader';
import { DecoratedMainApp } from './MainApp';

const DecoratedSingleSignOn = withScreenAnalytics('singlesignon')(SingleSignOn);
const DecoratedUserLogin = withScreenAnalytics('userlogin')(UserLogin);
const DecoratedCustomerCareLogin = withScreenAnalytics('customercarelogin')(CustomerCareLogin);
const DecoratedLogout = withScreenAnalytics('logout')(Logout);
const DecoratedEndImpersonation = withScreenAnalytics('endimpersonation')(EndImpersonation);

const FeatureFlags = lazy(() => import('~/scenes/FeatureFlags'));

export interface AppInnerProps {}

export interface AppState {
    hasError: boolean;
}

export class App extends Component<AppInnerProps, AppState> {
    private reloadPage = () => window.location.reload();

    public constructor(props: AppInnerProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(): void {
        this.setState({ hasError: true });
    }

    public render(): JSX.Element {
        if (this.state.hasError) {
            return <ReloadPage handleReload={this.reloadPage} />;
        }
        const resetPasswordPaths = ['/reset-password', `/reset-password/:token=[A-Za-z0-9\\-]+`];

        return (
            <>
                <SynchronizationClock />

                <Suspense fallback={<SceneLoader />}>
                    <Switch>
                        <Route component={LandingPage} exact path="/" />

                        <Route component={DecoratedUserLogin} path="/login" />
                        <Route component={DecoratedCustomerCareLogin} path="/customer-care" />
                        <Route component={DecoratedLogout} path="/logout" />
                        <Route component={DecoratedEndImpersonation} path="/end-impersonation" />
                        <Route component={DecoratedSingleSignOn} path="/single-sign-on" />
                        <Route component={FeatureFlags} path="/feature-flags" />
                        <Route component={NotAuthorizedPageSnackbar} path="/unauthorized" />
                        <Route component={ResetPassword} path={resetPasswordPaths} />

                        <Route component={DecoratedMainApp} />
                    </Switch>
                </Suspense>

                <SignalRConnectionMonitor signalRConnection={NotificationsClient} />
                <HandleUserPreferencesStateUpdate />
                <LoadUserPreferencesStatusMonitor />
                <SaveUserPreferencesStatusMonitor savePreferencesService={saveUserPreferencesService} />
                <InactivityMonitor />
                <GeozoneNotifications />
            </>
        );
    }
}
