import GpsFixed from '@mui/icons-material/GpsFixed';
import { Button } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC, HTMLAttributes, RefObject } from 'react';
import { forwardRef, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';

import { CustomNavLink } from '~/components/CustomNavLink';
import { CompassIcon, HistoryIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { MonitoringPerspective } from '~/data/monitoring';
import { AssetSubpage, MonitoringViewMode } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type { Position } from '~/services/ApiClient';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import { MONITORING_PATH_STRUCTURE } from '../../../../consts';

import type { LocationSectionActionButtonsClassKey } from './styles';

export interface LocationSectionActionButtonsProps {
    assetId: number;
    onLocate: () => void;
    perspective: MonitoringPerspective;
    position?: Position;
    showHistoryButton: boolean;
    viewMode: MonitoringViewMode;
}

export interface StateProps {
    canViewHistory: boolean;
    canViewMap: boolean;
}

export interface LocationSectionActionButtonsInnerProps
    extends LocationSectionActionButtonsProps,
        InjectedTranslationProps,
        StateProps,
        WithStyles<LocationSectionActionButtonsClassKey> {}

const renderCustomLinkComponent = (assetId: number, perspective: MonitoringPerspective, onLocate: () => void) =>
    forwardRef((props: HTMLAttributes<HTMLAnchorElement>, ref: RefObject<HTMLAnchorElement>) => {
        return (
            <CustomNavLink
                {...props}
                innerRef={ref}
                onClick={onLocate}
                to={generatePath(MONITORING_PATH_STRUCTURE, {
                    id: assetId,
                    perspective,
                    viewMode: MonitoringViewMode.MAP,
                })}
            />
        );
    });

export const LocationSectionActionButtonsComponent: FC<LocationSectionActionButtonsInnerProps> = (props) => {
    const {
        assetId,
        canViewHistory,
        canViewMap,
        classes,
        onLocate,
        perspective,
        position,
        showHistoryButton,
        t,
        viewMode,
    } = props;

    const LocateButtonComponent = useMemo(() => {
        return renderCustomLinkComponent(assetId, perspective, onLocate);
    }, [assetId, perspective, onLocate]);

    const history = useHistory();

    const openHistorySubpage = () => {
        history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                id: assetId,
                perspective,
                subpage: AssetSubpage.HISTORY,
                viewMode,
            })
        );
    };

    const copyCoordinates = () => {
        if (!isUndefined(position)) {
            copyCoordinatesToClipboard(position);
        }
    };

    return (
        <>
            {canViewMap && (
                <Button
                    className={classes.locateButton}
                    color="secondary"
                    component={LocateButtonComponent}
                    data-id="locate"
                >
                    <GpsFixed className={classes.iconMargin} />
                    {t('locate')}
                </Button>
            )}
            {showHistoryButton && canViewHistory && (
                <Button
                    className={classes.showHistoryButton}
                    color="secondary"
                    data-id="show-history"
                    onClick={openHistorySubpage}
                >
                    <HistoryIcon className={classes.iconMargin} />
                    {t('history')}
                </Button>
            )}
            <Button
                className={classes.copyCoordinatesButton}
                color="secondary"
                data-id="copy-coordinates"
                disabled={isUndefined(position)}
                onClick={copyCoordinates}
            >
                <CompassIcon className={classes.iconMargin} />
                {t('copy-coordinates')}
            </Button>
        </>
    );
};
