import { ACTIONS } from './actionTypes';
import type { ActionTypes, UserRolesState } from './models';

const initialState: UserRolesState = {
    error: false,
    items: [],
    pending: false,
    roleRequestIsPending: false,
};

export const userRolesSceneReducer = (state = initialState, action: ActionTypes): UserRolesState => {
    switch (action.type) {
        case ACTIONS.POST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.POST_FULFILLED:
            return {
                ...state,
                error: false,
                items: action.payload?.roles || [],
                pending: false,
            };
        case ACTIONS.POST_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.POST_QUERY_ROLE_PENDING:
            return { ...state, error: false, pending: true, roleRequestIsPending: true };
        case ACTIONS.POST_QUERY_ROLE_FULFILLED:
            return {
                ...state,
                error: false,
                pending: false,
                roleRequest: action.payload,
                roleRequestIsPending: false,
            };
        case ACTIONS.POST_QUERY_ROLE_REJECTED:
            return { ...state, error: true, pending: false, roleRequestIsPending: false };
        case ACTIONS.RESET_PENDING:
            return { ...state, error: false, items: [], pending: false, roleRequest: undefined };
        case ACTIONS.CREATE_PENDING:
            return { ...state, pending: true };
        case ACTIONS.CREATE_FULFILLED:
            return { ...state, error: false, pending: false };
        case ACTIONS.CREATE_REJECTED:
            return { ...state, error: true, pending: false };
        default:
            return state;
    }
};
