import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ZoomPopupClassKey = 'activityDetails' | 'root';

export const zoomPopupWidth = 320;

export const ZoomPopupStyleRules: StyleRulesCallback<Theme, {}, ZoomPopupClassKey> = (theme: Theme) =>
    createStyles({
        activityDetails: {
            marginBottom: theme.spacing(),
        },
        root: {
            background: theme.palette.common.white,
            overflow: 'hidden',
            paddingBottom: theme.spacing(),
            paddingTop: theme.spacing(),
            width: zoomPopupWidth,
        },
    });
