import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    LoginStatus,
    NumberViolationField,
    ViolationField,
} from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';
import { parseOptionalDuration, parseOptionalDurationViolationField } from '~/services/Parsers';

export const getDriverIdValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.driver &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.driver.id;

export const getDriverNameValue =
    (driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat) =>
    (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined => {
        const driver =
            monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.driver;

        if (!driver) {
            return '';
        }

        return formatDriverName(driver, driverDisplayNameFormat);
    };

export const getAvailableDailyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.availableDailyDrive
    );

export const getAvailableWeeklyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.availableWeeklyDrive
    );

export const getAvailableBiWeeklyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.availableBiWeeklyDrive
    );

export const getShiftDurationValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.shiftDuration
    );

export const getDailyDutyValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.dailyDuty
    );

export const getActivityDurationValue = (monitoringVehicleEntry: MonitoringVehicleEntry): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.currentActivityDuration
    );

export const getContinuousWorkAvailableValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.continuousWorkWait
    );

export const getOperationalWeekDurationValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.operationalWeekDuration
    );

export const getLoginManualStatusValue = (monitoringVehicleEntry: MonitoringVehicleEntry): LoginStatus | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.loginStatus;

export const getContinuousDrivingValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.continuousDrive
    );

export const getDriveBreakValue = (monitoringVehicleEntry: MonitoringVehicleEntry): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.driveBreak
    );

export const getDailyDrivingValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.dailyDrive
    );

export const getWeeklyDrivingValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weeklyDrive
    );

export const getExtendedDrivingCountValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): NumberViolationField | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.extendedDayDriveCount;

export const getContinuousLaborShortValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.continuousLabourShort
    );

export const getShortLaborBreakValue = (monitoringVehicleEntry: MonitoringVehicleEntry): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.labourShortBreak
    );

export const getContinuousLaborLongValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.continuousLabourLong
    );

export const getLongLaborBreakValue = (monitoringVehicleEntry: MonitoringVehicleEntry): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.labourLongBreak
    );

export const getNightLaborValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.nightLabour
    );

export const getWeeklyLaborValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weekLabour
    );

export const getAverageWeeklyLaborValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weekLabourAverage
    );

export const getWeeklyDutyValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weeklyDuty
    );

export const getMonthlyDutyValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): undefined | ViolationField<moment.Duration> =>
    parseOptionalDurationViolationField(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.monthlyDuty
    );

export const getMonthlyEffectivityPercentageValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): number | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.monthlyEffectivityPercentage;

export const getFirstSplitDailyRestValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.splitDailyRest
    );

export const getReducedDailyRestCountValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): NumberViolationField | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.reducedDayRestCount;

export const getDailyRestingValue = (monitoringVehicleEntry: MonitoringVehicleEntry): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.dailyRest
    );

export const getWeeklyRestCompensationValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weeklyRestCompensation
    );

export const getTotalWeeklyRestCompensationValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.totalWeeklyRestCompensation
    );

export const getWeeklyRestDueValue = (monitoringVehicleEntry: MonitoringVehicleEntry): Date | undefined =>
    monitoringVehicleEntry.vehicleDriverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus &&
    monitoringVehicleEntry.vehicleDriverHoursStatus.driverHoursStatus.weeklyRestDueDateTime;
