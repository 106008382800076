import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { AddressFormatter, DriverNameFormatter, VehicleNameFormatter } from '~/components/Formatters';
import { TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { DriverName } from '~/services/ApiClient';
import { AddressFormat, formatVehicleCategory } from '~/services/Formatters';

import type { VehicleEntryRowClassKey } from './styles';

export interface VehicleEntryRowProps {
    entry: MonitoringVehicleEntry;
}

export interface VehicleEntryRowInnerProps
    extends VehicleEntryRowProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps,
        WithStyles<VehicleEntryRowClassKey> {}

export const VehicleEntryRowComponent: React.FC<VehicleEntryRowInnerProps> = ({
    classes,
    displayPreferences,
    entry,
    t,
}) => {
    const { vehicle, vehicleDriverHoursStatus, vehicleStatus } = entry;

    const delimiter = <span className={classes.delimiter}>•</span>;

    const vehicleCategory = <span data-id="vehicle-category">{formatVehicleCategory(t, vehicle.category)}</span>;

    const addressLine = vehicleStatus?.address && (
        <>
            {delimiter}
            <span data-id="address">
                <AddressFormatter options={{ format: AddressFormat.FirstLine }} value={vehicleStatus.address} />
                {', '}
                <AddressFormatter options={{ format: AddressFormat.SecondLine }} value={vehicleStatus.address} />
            </span>
        </>
    );

    const drivers = [
        vehicleDriverHoursStatus?.driverHoursStatus?.driver,
        vehicleDriverHoursStatus?.coDriverHoursStatus?.driver,
    ]
        .filter(Boolean)
        .map((d: DriverName) => (
            <DriverNameFormatter displayNameFormat={displayPreferences.driverDisplayFormat} key={d.id} value={d} />
        ))
        .reduce<React.ReactNode>((accumulator, itm) => (accumulator ? [accumulator, ', ', itm] : [itm]), undefined);
    const driverElement = drivers && (
        <>
            {delimiter}
            <span data-id="drivers">{drivers}</span>
        </>
    );

    const trailerIsConnected = (
        <>
            {delimiter}
            <span data-id="trailer-connection">{t(entry.trailerConnection ? 'connected' : 'not-connected')}</span>
        </>
    );

    const secondary = (
        <>
            {vehicleCategory}
            {addressLine}
            {driverElement}
            {trailerIsConnected}
        </>
    );

    const vehicleName = (
        <Typography className={classes.primaryText} data-id="vehicle-name" variant="body2">
            <VehicleNameFormatter value={vehicle} vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat} />
        </Typography>
    );
    return (
        <ListItem className={classes.root} component="div">
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    <TruckIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.content} primary={vehicleName} secondary={secondary} />
        </ListItem>
    );
};
