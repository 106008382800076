import type { Duration } from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViolationFieldFormatterFactory } from '~/components/Formatters';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import type { DurationViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';
import { parseOptionalDurationViolationField } from '~/services/Parsers';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';
import { commonDurationFormatter } from '../utils';

const LaborSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const {
        averageWeeklyLabour,
        continuousLabourLong,
        continuousLabourShort,
        longLabourBreak,
        nightLabour,
        shortLabourBreak,
        weeklyLabour,
    } = props.driverStatus;
    const { t } = useTranslation();

    const ViolationDurationFormatter = useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    return (
        <ReorderableSection
            dataId={SectionName.LABOR}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.LABOR}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList
                list={[
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-short'),
                        value: continuousLabourShort && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-short')}
                                t={t}
                                value={parseOptionalDurationViolationField(
                                    continuousLabourShort as DurationViolationField
                                )}
                            />
                        ),
                        valueDataId: 'continuous-labor-short',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-short-labor-break'),
                        value: commonDurationFormatter(shortLabourBreak),
                        valueDataId: 'short-labor-break',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-long'),
                        value: continuousLabourLong && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-long')}
                                t={t}
                                value={parseOptionalDurationViolationField(
                                    continuousLabourLong as DurationViolationField
                                )}
                            />
                        ),
                        valueDataId: 'continuous-labor-long',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-long-labor-break'),
                        value: commonDurationFormatter(longLabourBreak),
                        valueDataId: 'long-labor-break',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-night-labor'),
                        value: nightLabour && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'labor-night-labor')}
                                t={t}
                                value={parseOptionalDurationViolationField(nightLabour as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'night-labor',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-weekly-labor'),
                        value: weeklyLabour && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'labor-weekly-labor')}
                                t={t}
                                value={parseOptionalDurationViolationField(weeklyLabour as DurationViolationField)}
                            />
                        ),
                        valueDataId: 'weekly-labor',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'labor-average-weekly-labor'),
                        value: averageWeeklyLabour && (
                            <ViolationDurationFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'labor-average-weekly-labor')}
                                t={t}
                                value={parseOptionalDurationViolationField(
                                    averageWeeklyLabour as DurationViolationField
                                )}
                            />
                        ),
                        valueDataId: 'average-weekly-labor',
                    },
                ]}
                listDataId="property-list"
            />
        </ReorderableSection>
    );
};

LaborSection.displayName = 'LaborSection';
export default LaborSection;
