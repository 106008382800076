import type { PolylineOptions } from 'leaflet';

const DEFAULT_SHAPE_OPTIONS: PolylineOptions = {
    bubblingMouseEvents: false,
    color: '#3388FF',
    fill: true,
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    lineCap: 'round',
    lineJoin: 'round',
    noClip: false,
    opacity: 1,
    pane: 'overlayPane',
    smoothFactor: 1,
    stroke: true,
    weight: 3,
};

enum SHAPE_TYPE {
    CIRCLE = 'CIRCLE',
    POLYGON = 'POLYGON',
}

export { DEFAULT_SHAPE_OPTIONS, SHAPE_TYPE };
