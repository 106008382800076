import type { RouteComponentProps } from 'react-router';
import type { Dispatch } from 'redux';

import type { StaticDataStoreState, UserPreferences } from '~/common';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import { updateEtherealAction } from '~/components/EtherealState/data';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector/preferences';
import type { DetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import type { SceneMapRootStoreState } from '~/components/SceneMap';
import { panToAssetAction } from '~/components/SceneMap';
import { retrieveContainerStatusAction, updateContainerStatusAction } from '~/data/containerstatus';
import { retrieveDriverHoursByVehicle, updateDriverHoursByVehicle } from '~/data/driverhours';
import { retrieveInhibitorStatusesAction, updateInhibitorStatusAction } from '~/data/inhibitorstatus/actions';
import { retrieveVehicleRouteStatusesAction, updateRouteStatusAction } from '~/data/routestatus';
import { retrieveTrailerStatusAction, updateTrailerStatusAction } from '~/data/trailerstatus';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { retrieveVehicleConnectionsAction, updateVehicleConnectionsAction } from '~/data/vehicleconnections';
import { retrieveVehicleStatusAction, updateVehicleStatusAction } from '~/data/vehiclestatus';
import type { NumericDictionary } from '~/libs/utility';
import { merge, throttle } from '~/libs/utility';
import type { RetrievableData, RetrievableHashedData } from '~/reducers';
import { reportError } from '~/reporting';
import type {
    ContainerStatus,
    DriverStatus,
    InhibitorStatus,
    MonitoringSecurables,
    RouteStatus,
    TrailerStatus,
    VehicleConnectionsNotification,
    VehicleDriverHoursStatus,
    VehicleStatus,
} from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import {
    applyPendingUpdatesAction,
    clearDataAction,
    retrieveDriverStatusAction,
    updateDriverStatusAction,
} from './actions';
import { buildMonitoringAssetEntries, buildSelectedAssetEntry } from './builders';
import {
    MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY,
    MONITORING_USERPREFERENCES_KEY,
    MonitoringPerspective,
    MonitoringViewMode,
} from './consts';
import type {
    BuildAssetProps,
    MonitoringDispatchProps,
    MonitoringReduxProps,
    MonitoringStateProps,
    MonitoringUrlParams,
} from './models';
import { DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY } from './preferences';
import type { DynamicMonitoringStoreState } from './reducers';
import { getPerspectiveValue } from './services';

export const autoUpdateThrottleTimeout = 10000;

const buildSelectedAssetEntryMemoized = memoizeOne(buildSelectedAssetEntry);
const buildMonitoringAssetEntriesMemoized = memoizeOne(buildMonitoringAssetEntries);

const getSupportedPerspectives = memoizeOne((securables: MonitoringSecurables) => {
    const supportedPerspectives = [];

    if (securables.vehicles) {
        supportedPerspectives.push(MonitoringPerspective.VEHICLE);
    }
    if (securables.trailers) {
        supportedPerspectives.push(MonitoringPerspective.TRAILER);
    }
    if (securables.containers) {
        supportedPerspectives.push(MonitoringPerspective.CONTAINER);
    }
    if (securables.driverStatus) {
        supportedPerspectives.push(MonitoringPerspective.DRIVER);
    }

    return supportedPerspectives;
});

const mergeDriverStatuses = (
    staticDriverStatus: RetrievableHashedData<DriverStatus>,
    dynamicDriverStatus: RetrievableData<NumericDictionary<DriverStatus>>
) => {
    const mergedDriverStatuses = merge(staticDriverStatus.data.hash, dynamicDriverStatus.data);

    return {
        array: Object.values(mergedDriverStatuses),
        hash: mergedDriverStatuses,
    };
};

export const mapStateToProps = (
    staticDataStoreState: StaticDataStoreState,
    dynamicMonitoringStoreState: DynamicMonitoringStoreState,
    vehicleAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    trailerAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    containerAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    driverAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    monitoringUserPreferences: UserPreferences,
    detailsPaneUserPreferences: DetailsPaneUserPreferences,
    settingsState: SettingsStoreState,
    sceneMapRootStoreState: SceneMapRootStoreState,
    dateTimeRangeEthereal: DateTimeRange
): MonitoringStateProps => {
    const {
        assetGroups,
        containers,
        depots,
        drivers,
        driverStatus: staticDriverStatus,
        trailers,
        vehicles,
    } = staticDataStoreState;
    const { endDate, startDate } = dateTimeRangeEthereal;

    const {
        containerStatus,
        driverStatus: dynamicDriverStatus,
        inhibitorStatus: vehicleInhibitorStatuses,
        routeStatus,
        trailerStatus,
        vehicleConnections,
        vehicleConnectionsByTrailer,
        vehicleDriverHoursStatus,
        vehicleStatus,
    } = dynamicMonitoringStoreState;

    const {
        compartmentStatus,
        containerEventType,
        containerTemperatureType,
        deviceTypes,
        doorStatus,
        driverActivityTypes,
        driverSubActivityTypes,
        hookedStatus,
        inhibitorStatus,
        reeferAlarm,
        reeferManufacturers,
        reeferOperationMode,
        reeferPowerMode,
        reeferSpeed,
        reeferStatus,
        routeStatusTypes,
        securables: { data: securables },
        trailerBatteryStatus,
        trailerEventType,
        trailerManufacturers,
        vehicleCategories,
        vehicleTypes,
    } = settingsState;

    const canViewVehicles = securables.monitoring.vehicles;
    const canViewTrailers = securables.monitoring.trailers;
    const canViewContainers = securables.monitoring.containers;
    const canViewMessages = securables.messaging.view;
    const canViewRouting = securables.routing.navigation;
    const canStartInhibitor = securables.services.inhibitorService;
    const canViewMap = securables.monitoring.map;
    const canViewDriverStatus = securables.monitoring.driverStatus;
    const mergedDriverStatuses = mergeDriverStatuses(staticDriverStatus, dynamicDriverStatus);

    const supportedPerspectives = getSupportedPerspectives(securables.monitoring);

    const { isHistoryMode } = sceneMapRootStoreState;

    const { leftPaneIsOpen } = monitoringUserPreferences;

    const { keepSubPageOpen } = detailsPaneUserPreferences;

    const props: BuildAssetProps = {
        commonProps: {
            canViewTrailers,
            compartmentStatus: compartmentStatus.data,
            deviceTypes: deviceTypes.data,
            doorStatus: doorStatus.data,
            driverActivityTypes: driverActivityTypes.data,
            driverSubActivityTypes: driverSubActivityTypes.data,
            hookedStatus: hookedStatus.data,
            reeferAlarm: reeferAlarm.data,
            reeferManufacturers: reeferManufacturers.data,
            reeferOperationMode: reeferOperationMode.data,
            reeferPowerMode: reeferPowerMode.data,
            reeferSpeed: reeferSpeed.data,
            reeferStatus: reeferStatus.data,
            trailerBatteryStatus: trailerBatteryStatus.data,
            trailerEventType: trailerEventType.data,
            trailerManufacturers: trailerManufacturers.data,
            trailersStatus: trailerStatus.data,
            vehicleCategories: vehicleCategories.data,
            vehicleDriverHoursStatus: vehicleDriverHoursStatus.data,
            vehicleTypes: vehicleTypes.data,
        },
        containerProps: {
            containerEventType: containerEventType.data,
            containers: containers.data.array,
            containerStatus: containerStatus.data,
            containerTemperatureType: containerTemperatureType.data,
        },
        driverProps: {
            driversHash: drivers.data.hash,
            driverStatuses: mergedDriverStatuses.array,
            vehiclesHash: vehicles.data.hash,
        },
        perspective: MonitoringPerspective.CONTAINER,
        trailerProps: {
            trailers: trailers.data.array,
            vehicleConnectionsByTrailer,
            vehiclesHash: vehicles.data.hash,
        },
        vehicleProps: {
            inhibitorStatuses: inhibitorStatus.data,
            routeStatusTypes: routeStatusTypes.data,
            trailersHash: trailers.data.hash,
            vehicleConnections: vehicleConnections.data,
            vehicleInhibitorStatuses: vehicleInhibitorStatuses.data,
            vehicleRouteStatuses: routeStatus.vehicle.data,
            vehicles: vehicles.data.array,
            vehicleStatuses: vehicleStatus.data,
        },
    };

    const buildSelectedAsset = (perspective: MonitoringPerspective, selectedAssetId?: number) => {
        const MappedAssetsHash = {
            [MonitoringPerspective.CONTAINER]: containers.data.hash,
            [MonitoringPerspective.DRIVER]: mergedDriverStatuses.hash,
            [MonitoringPerspective.TRAILER]: trailers.data.hash,
            [MonitoringPerspective.VEHICLE]: vehicles.data.hash,
        };

        return buildSelectedAssetEntryMemoized({
            ...props,
            assetsHash: MappedAssetsHash[perspective] || [],
            perspective,
            selectedAssetId,
        });
    };

    const buildAssets = (perspective: MonitoringPerspective) => {
        const perspectivePreferences = getPerspectiveValue<SceneAssetSelectorUserPreferences>(perspective, {
            [MonitoringPerspective.CONTAINER]: containerAssetSelectorUserPreferences,
            [MonitoringPerspective.DRIVER]: driverAssetSelectorUserPreferences,
            [MonitoringPerspective.TRAILER]: trailerAssetSelectorUserPreferences,
            [MonitoringPerspective.VEHICLE]: vehicleAssetSelectorUserPreferences,
        });

        return buildMonitoringAssetEntriesMemoized({
            ...props,
            perspective,
            selectedAssets: perspectivePreferences.selectedAssetIds,
        });
    };

    return {
        assetGroups: assetGroups.data,
        buildAssets,
        buildSelectedAsset,
        canStartInhibitor,
        canViewMap,
        canViewMessages,
        canViewRouting,
        dateTimeRange: { endDate, startDate },
        depots: depots.data.array,
        inhibitorStatuses: vehicleInhibitorStatuses.data,
        isHistoryMode,
        keepSubPageOpen,
        leftPaneIsOpen,
        loading:
            !vehicles.fulfilled ||
            (canViewVehicles && !vehicleStatus.fulfilled) ||
            !assetGroups.fulfilled ||
            !depots.fulfilled ||
            (canViewVehicles && canViewDriverStatus && !vehicleDriverHoursStatus.fulfilled) ||
            !vehicleConnections.fulfilled ||
            (canViewTrailers && (!trailers.fulfilled || !trailerStatus.fulfilled)) ||
            (canViewRouting && !routeStatus.vehicle.fulfilled) ||
            (canStartInhibitor && !inhibitorStatus.fulfilled) ||
            (canViewContainers && (!containers.fulfilled || !containerStatus.fulfilled)) ||
            (canViewDriverStatus && !staticDriverStatus.fulfilled) ||
            !drivers.fulfilled,
        rejected:
            vehicles.rejected ||
            (canViewVehicles && vehicleStatus.rejected) ||
            assetGroups.rejected ||
            depots.rejected ||
            (canViewVehicles && canViewDriverStatus && vehicleDriverHoursStatus.rejected) ||
            vehicleConnections.rejected ||
            (canViewTrailers && (trailers.rejected || trailerStatus.rejected)) ||
            (canViewRouting && routeStatus.vehicle.rejected) ||
            (canStartInhibitor && inhibitorStatus.rejected) ||
            (canViewContainers && (containers.rejected || containerStatus.rejected)) ||
            (canViewDriverStatus && staticDriverStatus.rejected) ||
            drivers.rejected,
        supportedPerspectives,
        vehicleStatuses: vehicleStatus.data,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): MonitoringDispatchProps => {
    const refreshThrottled = throttle(() => dispatch(applyPendingUpdatesAction()), autoUpdateThrottleTimeout);

    return {
        changeDateTimeRange: (dateTimeRange: DateTimeRange) => {
            dispatch(updateEtherealAction(MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY, dateTimeRange));
        },
        changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => {
            dispatch(updateUserPreferencesAction(MONITORING_USERPREFERENCES_KEY, { leftPaneIsOpen }));
        },
        changeMiniMapZoomLevel: (zoomLevel: number) => {
            dispatch(
                updateUserPreferencesAction(DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY, {
                    miniMapZoomLevel: zoomLevel,
                })
            );
        },
        clearData: () => {
            dispatch(clearDataAction());
        },
        getContainerStatus: () => {
            dispatch(retrieveContainerStatusAction()).catch(reportError);
        },
        getDriverStatuses: () => {
            dispatch(retrieveDriverStatusAction()).catch(reportError);
        },
        getInhibitorStatuses: () => {
            dispatch(retrieveInhibitorStatusesAction()).catch(reportError);
        },
        getTrailerStatus: () => {
            dispatch(retrieveTrailerStatusAction()).catch(reportError);
        },
        getVehicleConnections: () => {
            dispatch(retrieveVehicleConnectionsAction()).catch(reportError);
        },
        getVehicleDriverHoursStatus: () => {
            dispatch(retrieveDriverHoursByVehicle()).catch(reportError);
        },
        getVehicleRouteStatuses: () => {
            dispatch(retrieveVehicleRouteStatusesAction()).catch(reportError);
        },
        getVehicleStatus: () => {
            dispatch(retrieveVehicleStatusAction()).catch(reportError);
        },
        locateOnMap: () => {
            dispatch(
                panToAssetAction(
                    'messaging',
                    'locate-from-conversation',
                    'Locate on map from conversation',
                    'location-conversation'
                )
            );
        },
        updateContainerStatus: (containerStatus: ContainerStatus) => {
            dispatch(updateContainerStatusAction(containerStatus));
            refreshThrottled();
        },
        updateDriverStatus: (driverStatus: DriverStatus) => {
            dispatch(updateDriverStatusAction(driverStatus));
            refreshThrottled();
        },
        updateInhibitorStatus: (inhibitorStatus: InhibitorStatus) => {
            dispatch(updateInhibitorStatusAction(inhibitorStatus));
            refreshThrottled();
        },
        updateRouteStatus: (routeStatus: RouteStatus) => {
            dispatch(updateRouteStatusAction(routeStatus));
            refreshThrottled();
        },
        updateTrailerStatus: (trailerStatus: TrailerStatus) => {
            dispatch(updateTrailerStatusAction(trailerStatus));
            refreshThrottled();
        },
        updateVehicleConnections: (vehicleConnectionsNotification: VehicleConnectionsNotification) => {
            dispatch(updateVehicleConnectionsAction(vehicleConnectionsNotification));
            refreshThrottled();
        },
        updateVehicleDriverHoursStatus: (vehicleDriverHoursStatus: VehicleDriverHoursStatus) => {
            dispatch(updateDriverHoursByVehicle(vehicleDriverHoursStatus));
            refreshThrottled();
        },
        updateVehicleStatus: (vehicleStatus: VehicleStatus) => {
            if (!vehicleStatus.address) {
                setTimeout(() => {
                    dispatch(updateVehicleStatusAction(vehicleStatus));
                    refreshThrottled();
                }, 5000);
            } else {
                dispatch(updateVehicleStatusAction(vehicleStatus));
                refreshThrottled();
            }
        },
    };
};

const toggleLeftPaneVisibilityMemoized = memoizeOne(
    (changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void, leftPaneIsOpen: boolean) => () => {
        changeLeftPaneVisibility(!leftPaneIsOpen);
    }
);

export const mergeProps = (
    { buildAssets, buildSelectedAsset, leftPaneIsOpen, supportedPerspectives, ...restStateProps }: MonitoringStateProps,
    { changeLeftPaneVisibility, ...restDispatchProps }: MonitoringDispatchProps,
    { match: { params }, ...restOwnProps }: RouteComponentProps<MonitoringUrlParams>
): MonitoringReduxProps => {
    const perspective = params.perspective ?? supportedPerspectives[0] ?? MonitoringPerspective.VEHICLE;
    const viewMode = params.viewMode ?? MonitoringViewMode.LIST;
    const selectedAssetId = params.selectedAssetId ? Number(params.selectedAssetId) : undefined;
    const { subpage } = params;
    const leftPaneForceOpen = !buildAssets(perspective).length ? false : leftPaneIsOpen;

    return {
        ...restStateProps,
        ...restOwnProps,
        ...restDispatchProps,
        dataSource: buildAssets(perspective),
        leftPaneIsOpen: !buildAssets(perspective).length ? true : leftPaneIsOpen,
        perspective,
        selectedAsset: buildSelectedAsset(perspective, selectedAssetId),
        selectedAssetId,
        subpage,
        supportedPerspectives,
        toggleLeftPaneVisibility: toggleLeftPaneVisibilityMemoized(changeLeftPaneVisibility, leftPaneForceOpen),
        viewMode,
    };
};
