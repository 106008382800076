import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DriverActivitiesReportDialogClassKeys = 'content' | 'loaderContainer' | 'messageText';

export const useStyles = makeStyles<Theme, {}, DriverActivitiesReportDialogClassKeys>((theme) => ({
    content: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        minHeight: theme.custom.loader.minHeight,
    },
    messageText: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        fontStyle: 'italic',
        justifyContent: 'center',
        minHeight: theme.custom.dialogContent.minHeight,
    },
}));
