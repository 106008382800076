import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ActivityDetailsListItemClassKey =
    | 'activityAvailable'
    | 'activityDrive'
    | 'activityIcon'
    | 'activityRest'
    | 'activityTime'
    | 'activityTimeAndBadge'
    | 'activityWork'
    | 'root'
    | 'startTime'
    | 'stopTime'
    | 'stopTimeHidden';

export const ActivityDetailsListItemStyleRules: StyleRulesCallback<Theme, {}, ActivityDetailsListItemClassKey> = (
    theme: Theme
) =>
    createStyles({
        activityAvailable: {
            backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
            color: theme.functionalItemsColors.driverActivity.available.contrast,
        },
        activityDrive: {
            backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
            color: theme.functionalItemsColors.driverActivity.drive.contrast,
        },
        activityIcon: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontSize: 14,
            height: 22,
            margin: theme.spacing(0, 0.5, 0, 1),
            width: 22,
        },
        activityRest: {
            backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
            color: theme.functionalItemsColors.driverActivity.rest.contrast,
        },
        activityTime: {
            alignItems: 'center',
            display: 'flex',
        },
        activityTimeAndBadge: {
            alignItems: 'center',
            display: 'flex',
        },
        activityWork: {
            backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
            color: theme.functionalItemsColors.driverActivity.work.contrast,
        },
        root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing()}`,
        },
        startTime: {
            marginRight: theme.spacing(0.25),
        },
        stopTime: {
            marginLeft: theme.spacing(0.25),
        },
        stopTimeHidden: {
            visibility: 'hidden',
        },
    });
