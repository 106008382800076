const MONITORING_LIST_DRIVERS_USERPREFERENCES_KEY = 'monitoring-grid-driver-state';

enum ColumnName {
    ALTITUDE = 'altitude',
    AVAILABLE_DAILY_DRIVING = 'available-daily-driving',
    AVAILABLE_TWO_WEEKLY_DRIVING = 'available-two-weekly-driving',
    AVAILABLE_WEEKLY_DRIVING = 'available-weekly-driving',
    AVERAGE_WEEKLY_LABOUR = 'average-weekly-labour',
    CARD_STATUS = 'card-status',
    CITY = 'city',
    CODRIVER_ID = 'co-driver-id',
    CODRIVER_NAME = 'co-driver-name',
    CONTINUOUS_DRIVING = 'continuous-driving',
    CONTINUOUS_LABOUR_LONG = 'continuous-labour-long',
    CONTINUOUS_LABOUR_SHORT = 'continuous-labour-short',
    CONTINUOUS_WORK_WAIT = 'continuous-work-wait',
    DAILY_DRIVING = 'daily-driving',
    DAILY_DUTY = 'daily-duty',
    DRIVE_BREAK = 'drive-break',
    DRIVER = 'driver',
    DRIVER_ACTIVITY = 'driver-activity',
    DRIVER_ID = 'driver-id',
    DURATION = 'duration',
    EXTENDED_DAILY_DRIVING_COUNT = 'extended-daily-driving-count',
    HOUSE_NUMBER = 'house-number',
    LAST_EVENT = 'last-event',
    LATITUDE = 'latitude',
    LONG_LABOUR_BREAK = 'long-labour-break',
    LONGITUDE = 'longitude',
    MONTHLY_DRIVE_OVER_DUTY = 'monthly-drive-over-duty',
    MONTHLY_DUTY = 'monthly-duty',
    NIGHT_LABOUR = 'night-labour',
    OPERATION_WEEK_DURATION = 'operation-week-duration',
    POI = 'poi',
    POSITION_DATETIME = 'position-datetime',
    POSTAL_CODE = 'postal-code',
    REDUCED_DAILY_REST_COUNT = 'reduced-daily-rest-count',
    REGULAR_WEEKLY_REST_REQUIRED = 'regular-weekly-rest-required',
    SHIFT_DURATION = 'shift-duration',
    SHORT_LABOUR_BREAK = 'short-labour-break',
    SPLIT_DAILY_REST = 'split-daily-rest',
    STREET = 'street',
    SUBACTIVITY = 'subactivity',
    TOTAL_WEEKLY_REST_COMPENSATION = 'total-weekly-rest-compensation',
    VEHICLE = 'vehicle',
    WEEKLY_DRIVING = 'weekly-driving',
    WEEKLY_DUTY = 'weekly-duty',
    WEEKLY_LABOUR = 'weekly-labour',
    WEEKLY_REST_COMPENSATION = 'weekly-rest-compensation',
    WEEKLY_REST_COMPENSATION_ON_VIOLATION = 'weekly-rest-compensation-on-violation',
    WEEKLY_REST_VIOLATION = 'weekly-rest-violation',
}

enum ColumnGroup {
    DRIVER_HOURS = 'driver-hours',
    GENERAL = 'general',
    LOCATION = 'location',
    STAFF = 'staff',
}

enum Abbreviation {
    AVAILABLE_DAILY_DRIVING = 'available-daily-driving-time-abbreviation',
    AVAILABLE_TWO_WEEKLY_DRIVING = 'available-bi-weekly-driving-time-abbreviation',
    AVAILABLE_WEEKLY_DRIVING = 'available-weekly-driving-time-abbreviation',
    AVERAGE_WEEKLY_LABOUR = 'average-weekly-labor-abbreviation',
    CONTINUOUS_DRIVING = 'continuous-driving-abbreviation',
    CONTINUOUS_LABOUR_LONG = 'continuous-labor-long-abbreviation',
    CONTINUOUS_LABOUR_SHORT = 'continuous-labor-short-abbreviation',
    CONTINUOUS_WORK_WAIT = 'continuous-work-available-abbreviation',
    DAILY_DRIVING = 'daily-driving-abbreviation',
    DAILY_DUTY = 'daily-duty-abbreviation',
    DRIVE_BREAK = 'drive-break-abbreviation',
    EXTENDED_DAILY_DRIVING_COUNT = 'extended-driving-count-abbreviation',
    LONG_LABOUR_BREAK = 'long-labor-break-abbreviation',
    MONTHLY_DRIVE_OVER_DUTY = 'monthly-driver-duty-abbreviation',
    MONTHLY_DUTY = 'monthly-duty-abbreviation',
    NIGHT_LABOUR = 'night-labor-abbreviation',
    OPERATION_WEEK_DURATION = 'operational-week-duration-abbreviation',
    REDUCED_DAILY_REST_COUNT = 'reduced-daily-rest-count-abbreviation',
    REGULAR_WEEKLY_REST_REQUIRED = 'weekly-rest-due-abbreviation',
    SHORT_LABOUR_BREAK = 'short-labor-break-abbreviation',
    SPLIT_DAILY_REST = 'first-split-daily-rest-abbreviation',
    TOTAL_WEEKLY_REST_COMPENSATION = 'total-weekly-rest-compensation-abbreviation',
    WEEKLY_DRIVING = 'weekly-driving-abbreviation',
    WEEKLY_DUTY = 'weekly-duty-abbreviation',
    WEEKLY_LABOUR = 'weekly-labor-abbreviation',
    WEEKLY_REST_COMPENSATION = 'weekly-rest-compensation-abbreviation',
    WEEKLY_REST_COMPENSATION_ON_VIOLATION = 'weekly-rest-compensation-on-violation-abbreviation',
    WEEKLY_REST_VIOLATION = 'weekly-rest-violation-abbreviation',
}

export { Abbreviation, ColumnGroup, ColumnName, MONITORING_LIST_DRIVERS_USERPREFERENCES_KEY };
