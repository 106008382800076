import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';

import { ACTION_TYPE_KEYS } from './actionTypes';
import type { ActionTypes, AdminCompanyCardsState } from './models';
import { resolveAdminCompanyCard } from './reducers.resolveAdminCompanyCard';

const initialState: AdminCompanyCardsState = {
    error: false,
    items: [],
    pending: false,
};

export const adminCompanyCardsSceneReducer = (
    state = initialState,
    action: ActionTypes,
    settings: SettingsStoreState
): AdminCompanyCardsState => {
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED:
            return {
                error: false,
                items: (action.payload?.items || []).map((item) => resolveAdminCompanyCard(item, settings)),
                pending: false,
            };
        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTION_TYPE_KEYS.RESET:
            return { error: false, items: [], pending: false };
        default:
            return state;
    }
};
