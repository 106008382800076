import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { MessageStatusFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { ResolvedConversationSummary } from '~/services/ApiClient';
import {
    IncomingMessage,
    IncomingMessageStatus,
    OutgoingMessage,
    OutgoingMessageStatus,
    WorkflowFormDefinitionStatus,
    WorkflowMessageBody,
} from '~/services/ApiClient';
import { FormatNewLineToBr, formatMessagePrimaryAttribute } from '~/services/Formatters';

import { UnreadMessageCountBadge } from '../../../../../../../UnreadMessageCountBadge';

import type { ConversationSummarySecondLineClassKey } from './styles';

export interface ConversationSummarySecondLineProps {
    conversationSummary: ResolvedConversationSummary;
    retrieveWorkflowFormDefinition: (formId: number) => void;
}

export interface ConversationSummarySecondLineInnerProps
    extends ConversationSummarySecondLineProps,
        InjectedTranslationProps,
        WithStyles<ConversationSummarySecondLineClassKey> {}

export const ConversationSummarySecondLineComponent: React.FC<ConversationSummarySecondLineInnerProps> = ({
    classes,
    conversationSummary,
    retrieveWorkflowFormDefinition,
    t,
}) => {
    const statusIndicator = conversationSummary.lastMessage && (
        <div className={classes.statusIndicator}>
            <MessageStatusFormatter
                iconProps={{ fontSize: 'inherit' }}
                t={t}
                value={conversationSummary.lastMessage.status}
            />
        </div>
    );

    React.useEffect(() => {
        if (
            conversationSummary.lastMessage?.body.workflowFormDefinitionStatus ===
                WorkflowFormDefinitionStatus.REQUIRED &&
            conversationSummary.lastMessage?.body.value instanceof WorkflowMessageBody
        ) {
            retrieveWorkflowFormDefinition(conversationSummary.lastMessage?.body.value.formDefinitionId);
        }
    }, [conversationSummary.lastMessage, retrieveWorkflowFormDefinition]);

    const messageClassName = classNames(classes.message, {
        [classes.messageFailed]:
            conversationSummary.lastMessage?.value instanceof OutgoingMessage &&
            conversationSummary.lastMessage.value.status === OutgoingMessageStatus.Failed,
        [classes.messageUnread]:
            conversationSummary.lastMessage?.value instanceof IncomingMessage &&
            conversationSummary.lastMessage.value.status === IncomingMessageStatus.Received,
        [classes.noMessageSentYet]: !conversationSummary.lastMessage,
    });

    const formattedMessageBody =
        conversationSummary.lastMessage && formatMessagePrimaryAttribute(t, conversationSummary.lastMessage.body);
    const tooltipMessage =
        conversationSummary.lastMessage && conversationSummary.lastMessage.value instanceof OutgoingMessage
            ? `${conversationSummary.lastMessage.value.author}: ${formattedMessageBody}`
            : conversationSummary.lastMessage
              ? formattedMessageBody
              : t('no-message-sent-yet');

    const message =
        conversationSummary.lastMessage && conversationSummary.lastMessage.value instanceof OutgoingMessage ? (
            <div>
                <span className={classes.author}>{`${conversationSummary.lastMessage.value.author}: `}</span>
                <span className={classes.messageBody}>{formattedMessageBody}</span>
            </div>
        ) : conversationSummary.lastMessage ? (
            <span className={classes.messageBody}>{formattedMessageBody}</span>
        ) : (
            t('no-message-sent-yet')
        );

    const unreadMessagesCounter = (
        <UnreadMessageCountBadge
            className={classes.unreadMessageCount}
            unreadTextMessageCount={conversationSummary.unreadTextMessageCount}
            unreadWorkflowMessageCount={conversationSummary.unreadWorkflowMessageCount}
        />
    );

    const lastMessageDataId =
        conversationSummary.lastMessage?.body?.value instanceof WorkflowMessageBody ? 'workflow-message' : 'message';

    return (
        <div className={classes.root}>
            {statusIndicator}

            <Typography
                className={messageClassName}
                color="textPrimary"
                data-id={lastMessageDataId}
                noWrap
                variant="caption"
            >
                <TitledTruncatedText
                    title={message && tooltipMessage ? <FormatNewLineToBr text={tooltipMessage} /> : undefined}
                >
                    {message}
                </TitledTruncatedText>
            </Typography>

            {unreadMessagesCounter}
        </div>
    );
};
