import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ChangelogChangesTableClassKeys = 'cell' | 'container' | 'header' | 'table';

export const useStyles = makeStyles<Theme, {}, ChangelogChangesTableClassKeys>((theme) => ({
    cell: {
        fontSize: '12px',
        padding: theme.spacing(0, 1),
    },
    container: {
        margin: theme.spacing(1, 2, 1, 2),
        width: 'auto',
    },
    header: {
        fontWeight: 'bold',
    },
    table: {
        tableLayout: 'fixed',
    },
}));
