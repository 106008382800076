import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmationDialog } from '~/components/Dialogs';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { disconnectTrailerAction } from '~/data/vehicleconnections';
import { reportError } from '~/reporting';
import type { NamedTrailerReference, PojoModel, ResolvedVehicleConnection } from '~/services/ApiClient';

export interface DisconnectVehicleFromTrailerDialogProps {
    onClose: () => void;
    trailer: PojoModel<NamedTrailerReference>;
    vehicleConnection: ResolvedVehicleConnection;
}

export interface DisconnectVehicleFromTrailerDialogInnerProps
    extends DisconnectVehicleFromTrailerDialogProps,
        InjectedTranslationProps {}

export const DisconnectVehicleFromTrailerDialogComponent: React.FC<DisconnectVehicleFromTrailerDialogInnerProps> = ({
    onClose,
    t,
    trailer,
    vehicleConnection,
}) => {
    const dispatch = useDispatch();
    const handleConfirm = () => {
        dispatch(disconnectTrailerAction(vehicleConnection.vehicleReference.id, trailer.id)).catch(reportError);
        onClose();
    };

    return (
        <ConfirmationDialog
            confirmationActionText={t('disconnect')}
            dataId="disconnect-vehicle-confirmation-dialog"
            onCancel={onClose}
            onConfirm={handleConfirm}
            open
            title={t('disconnect-vehicle')}
        >
            <span>{t('confirm-disconnect-vehicle')}</span>
        </ConfirmationDialog>
    );
};
