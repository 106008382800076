import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { DriverStatus } from '~/services/ApiClient';
import type { Action, BaseAction, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    MONITORING_DRIVERSTATUS = 'DRIVERSTATUS',
}

export enum ActionTypeKeys {
    MONITORING_APPLY_PENDING = 'MONITORING_APPLY_PENDING',
    MONITORING_CHANGE_DATETIMERANGE = 'MONITORING_CHANGE_DATETIMERANGE',
    MONITORING_CLEAR_DATA = 'MONITORING_CLEAR_DATA',
    MONITORING_DRIVERSTATUS_FULFILLED = 'DRIVERSTATUS_FULFILLED',
    MONITORING_DRIVERSTATUS_PENDING = 'DRIVERRSTATUS_PENDING',
    MONITORING_DRIVERSTATUS_REJECTED = 'DRIVERSTATUS_REJECTED',
    MONITORING_DRIVERSTATUS_UPDATE = 'DRIVERSTATUS_UPDATE',
}

export interface DriverStatusPayload {
    readonly items: DriverStatus[];
}

export type ActionTypes =
    | Action<ActionTypeKeys.MONITORING_CHANGE_DATETIMERANGE, DateTimeRange>
    | Action<ActionTypeKeys.MONITORING_DRIVERSTATUS_UPDATE, DriverStatus>
    | BaseAction<ActionTypeKeys.MONITORING_APPLY_PENDING>
    | BaseAction<ActionTypeKeys.MONITORING_CLEAR_DATA>
    | Fulfilled<ActionTypeKeys.MONITORING_DRIVERSTATUS_FULFILLED, DriverStatusPayload>
    | Pending<ActionTypeKeys.MONITORING_DRIVERSTATUS_PENDING>
    | Rejected<ActionTypeKeys.MONITORING_DRIVERSTATUS_REJECTED>;
