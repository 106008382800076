import { Tooltip } from '@mui/material';
import type { FC } from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';

export interface FileTypeFormatterProps {
    fontSize?: 'inherit' | 'large' | 'small';
    t: SingleTFunction;
    value?: Classification;
}

export interface FileTypeFormatterInnerProps extends FileTypeFormatterProps {}

const FileTypeFormatter: FC<FileTypeFormatterInnerProps> = (props) => {
    const { fontSize, t, value } = props;

    const iconProps = !isUndefined(fontSize) ? { iconProps: { fontSize } } : undefined;

    return value ? (
        <Tooltip title={formatClassification(t, ClassificationType.AttachmentType, value)}>
            <span data-id={`file-type:${value.key}`}>
                <IconFormatter value={value.key} {...(iconProps && iconProps)} />
            </span>
        </Tooltip>
    ) : null;
};
FileTypeFormatter.displayName = 'FileTypeFormatter';

export { FileTypeFormatter };
