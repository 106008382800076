import makeStyles from '@mui/styles/makeStyles';

import type { SceneBarTemplateProps } from './component';
import { SceneBarState } from './models';

export const useStyles = makeStyles((theme) => ({
    closeIcon: (props: SceneBarTemplateProps) => ({
        color: props.state === SceneBarState.ERROR ? theme.palette.background.paper : theme.palette.text.primary,
        fontSize: 12,
    }),
    errorIcon: {
        color: theme.palette.background.paper,
        fontSize: 12,
    },
    message: (props: SceneBarTemplateProps) => ({
        color: props.state === SceneBarState.ERROR ? theme.palette.background.paper : theme.palette.text.primary,
        marginLeft: theme.spacing(1),
    }),
    warningIcon: {
        color: theme.palette.warning.dark,
        fontSize: 12,
    },
    wrapper: (props: SceneBarTemplateProps) => ({
        '& > div': {
            alignItems: 'center',
            display: 'flex',
        },
        alignItems: 'center',
        backgroundColor:
            props.state === SceneBarState.WARNING
                ? theme.palette.warning.light
                : props.state === SceneBarState.NORMAL
                  ? theme.palette.background.paper
                  : theme.palette.error.light,
        display: 'flex',
        height: theme.spacing(3.5),
        justifyContent: 'space-between',
        padding: theme.spacing(0.5, 2),
        width: '100%',
    }),
}));
