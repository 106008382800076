import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { wrapDisplayName } from 'react-recompose';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { themes } from '~/theme';

export const ensureThemeHoc =
    () =>
    <BaseProps extends {}>(
        BaseComponent: React.ComponentType<BaseProps>
    ): React.FC<BaseProps & InjectedDisplayPreferencesProps> => {
        const Hoc = (props: BaseProps & InjectedDisplayPreferencesProps) => {
            const { displayPreferences, ...restProps } = props;
            const theme = themes[displayPreferences.theme] ?? themes.default;

            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <BaseComponent {...(restProps as unknown as BaseProps)} />
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        };
        Hoc.displayName = wrapDisplayName(BaseComponent, 'ensureTheme');

        return Hoc;
    };
