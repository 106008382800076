import type { SingleTFunction } from '~/components/LanguageSelector';
import type { AssetRecipient, Depot } from '~/services/ApiClient';
import { AssetRecipientType, AssetType } from '~/services/ApiClient';

const getIds = (depot: Depot, assetType: AssetType) => {
    switch (assetType) {
        case AssetType.Driver:
            return depot.driverIds || [];
        case AssetType.Vehicle:
            return depot.vehicleIds || [];
        default:
            throw Error('Unkown asset type');
    }
};

interface GetAssetLengthArgs {
    assetType: AssetType;
    depot: Depot;
    t: SingleTFunction;
}

const getAssetsLength = ({ assetType, depot, t }: GetAssetLengthArgs) => {
    switch (assetType) {
        case AssetType.Driver:
            return t('number-of-drivers', { numberOfDrivers: getIds(depot, assetType).length });
        case AssetType.Vehicle:
            return t('number-of-vehicles', { numberOfVehicles: getIds(depot, assetType).length });
        default:
            return t('unknown');
    }
};

interface GetAssetEnabledArgs {
    assetPredicate?: (assetId: number) => boolean;
    assetType: AssetType;
    depot: Depot;
}

const getAssetsEnabled = ({ assetPredicate, assetType, depot }: GetAssetEnabledArgs) => {
    switch (assetType) {
        case AssetType.Driver:
            return assetPredicate ? depot.driverIds?.filter(assetPredicate) : depot.driverIds;
        case AssetType.Vehicle:
            return assetPredicate ? depot.vehicleIds?.filter(assetPredicate) : depot.vehicleIds;
        default:
            throw Error('Unkown asset type');
    }
};

interface MapDepotOptionsArgs {
    assetPredicate?: (assetId: number) => boolean;
    assetType: AssetType;
    depots: Depot[];
    t: SingleTFunction;
}

export const mapDepotOptions = ({ assetPredicate, assetType, depots, t }: MapDepotOptionsArgs): AssetRecipient[] =>
    depots.map((depot) => {
        const assetsEnabled = getAssetsEnabled({ assetPredicate, assetType, depot });

        return {
            enabledAssetIds: assetsEnabled,
            ids: getIds(depot, assetType),
            name: depot.name,
            secondaryTextFirst: getAssetsLength({ assetType, depot, t }),
            secondaryTextSecond: t('number-of-assets-eligibles', {
                assetType: t(assetType),
                numberOfAssets: assetsEnabled?.length,
            }),
            secondaryTextThird: undefined,
            type: AssetRecipientType.DEPOT,
        };
    });
