export const CONVERSATIONWIDGETS_USERPREFERENCES_KEY = 'conversation-widgets';

export interface OpenedConversation {
    isMinimized: boolean;
    vehicleId: number;
}

export interface ConversationWidgetsUserPreferences {
    openedConversations: OpenedConversation[];
    warnOnMarkAllMessagesAsRead: boolean;
}

export const defaultConversationWidgetsUserPreferences: ConversationWidgetsUserPreferences = {
    openedConversations: [],
    warnOnMarkAllMessagesAsRead: true,
};

export const requiredConversationWidgetsUserPreferences = {
    defaultState: defaultConversationWidgetsUserPreferences,
    key: CONVERSATIONWIDGETS_USERPREFERENCES_KEY,
};
