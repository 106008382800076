import type { ActionTypes } from '~/data/userpreferences';
import { ActionTypeKeys } from '~/data/userpreferences';
import type { BaseAction } from '~/types';

import { defaultProcessResponse } from './reducers.defaultProcessResponse';

export interface UserPreferencesKeyState<T extends {} = {}> {
    actualData: T;
    defaultData: T;
    fulfilled: boolean;
    pending: boolean;
    processResponse?: (preferences: object) => T;
    rejected: boolean;
    updated: boolean;
}

export interface UserPreferencesState {
    [key: string]: UserPreferencesKeyState;
}

const defaultStoreState: UserPreferencesState = {};

export const userPreferenceReducer = (
    state: UserPreferencesState = defaultStoreState,
    action: ActionTypes | BaseAction<''>
): UserPreferencesState => {
    let actualKeyState: UserPreferencesKeyState;
    let newState: UserPreferencesState;

    switch (action.type) {
        case ActionTypeKeys.USERPREFERENCES_INITIALIZESTATE:
            if (state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    actualData: action.payload.defaultUserPreferences.value,
                    defaultData: action.payload.defaultUserPreferences.value,
                    fulfilled: false,
                    pending: false,
                    processResponse: action.payload.processResponse,
                    rejected: false,
                    updated: false,
                },
            };

        case ActionTypeKeys.USERPREFERENCES_LOAD_PENDING:
            newState = { ...state };

            action.meta.forEach((prefKey) => {
                actualKeyState = state[prefKey];

                if (!actualKeyState) {
                    return;
                }

                newState[prefKey] = {
                    ...actualKeyState,
                    pending: true,
                };
            });

            return newState;

        case ActionTypeKeys.USERPREFERENCES_LOAD_FULFILLED: {
            newState = { ...state };
            action.meta.forEach((prefKey) => {
                actualKeyState = state[prefKey];

                if (!actualKeyState) {
                    return;
                }

                if (actualKeyState.updated) {
                    return;
                }

                const userPreference = action.payload?.items.find((it) => it.key === prefKey);

                const actualData = userPreference
                    ? actualKeyState.processResponse
                        ? actualKeyState.processResponse(userPreference.value)
                        : defaultProcessResponse(actualKeyState.defaultData, userPreference.value)
                    : actualKeyState.defaultData;

                newState[prefKey] = {
                    ...actualKeyState,
                    actualData,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                    updated: false,
                };
            });

            return newState;
        }
        case ActionTypeKeys.USERPREFERENCES_LOAD_REJECTED:
            newState = { ...state };
            action.meta.forEach((prefKey) => {
                actualKeyState = state[prefKey];

                if (!actualKeyState) {
                    return;
                }

                newState[prefKey] = {
                    ...actualKeyState,
                    fulfilled: false,
                    pending: false,
                    rejected: true,
                    updated: false,
                };
            });

            return newState;

        case ActionTypeKeys.USERPREFERENCES_UPDATE:
            actualKeyState = state[action.meta];

            if (!actualKeyState) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...actualKeyState,
                    actualData: {
                        ...actualKeyState.actualData,
                        ...action.payload.value,
                    },
                    updated: true,
                },
            };

        case ActionTypeKeys.USERPREFERENCES_DELETE_FULFILLED:
            actualKeyState = state[action.meta];

            if (!actualKeyState) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...actualKeyState,
                    fulfilled: false,
                    pending: false,
                    rejected: false,
                    updated: false,
                },
            };

        case ActionTypeKeys.USERPREFERENCES_DELETE_ALL_FULFILLED:
            return Object.keys(state).reduce((accumulator, key) => {
                return {
                    ...accumulator,
                    [key]: {
                        ...state[key],
                        fulfilled: false,
                        pending: false,
                        rejected: false,
                        updated: false,
                    },
                };
            }, {});

        default:
            return state;
    }
};
