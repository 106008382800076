import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ALL } from '../../../../consts';

import type { DefaultHeaderProps } from './models';
import { Checkbox, Container, IconButton, Search, Title } from './styles';

const DefaultHeader: FC<DefaultHeaderProps> = (props) => {
    const {
        checkedKeysCount = 0,
        expandedAll,
        handleCheck,
        handleCollapse,
        hideExpandAllButton,
        hideSelectAllCheckbox,
        itemsCount = 0,
        searchingItemsName,
        selectedAll,
        singleSelectionMode,
        toggleSearch,
    } = props;

    const { t } = useTranslation();

    const handleToggleSelectAll = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        handleCheck({ event, id: ALL, value: checked });
    };

    const handleToggleCollapseAll = (event: ChangeEvent<unknown>) => {
        handleCollapse({ event, id: ALL, value: !expandedAll });
    };

    const collapseAllTitle = expandedAll ? t('collapse-all') : t('expand-all');
    const selectAllTitle = selectedAll ? t('clear-selection') : t('select-all');

    const isChecked = selectedAll || checkedKeysCount > 0;
    const isIndeterminate = isChecked && checkedKeysCount < itemsCount;

    return (
        <Container data-testid="search-close">
            {!hideExpandAllButton && (
                <Tooltip title={collapseAllTitle}>
                    <IconButton
                        aria-label={collapseAllTitle}
                        data-expanded={expandedAll}
                        data-testid={expandedAll ? 'collapse-all' : 'expand-all'}
                        disableRipple
                        onClick={handleToggleCollapseAll}
                        size="large"
                    >
                        {expandedAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                    </IconButton>
                </Tooltip>
            )}

            {!hideSelectAllCheckbox && !singleSelectionMode && (
                <Tooltip title={selectAllTitle}>
                    <Checkbox
                        aria-label={selectAllTitle}
                        checked={isChecked}
                        data-testid={selectedAll ? 'unselect-all' : 'select-all'}
                        indeterminate={isIndeterminate}
                        onChange={handleToggleSelectAll}
                    />
                </Tooltip>
            )}

            <Title>
                <Typography color="textPrimary" variant="subtitle1">
                    {searchingItemsName}
                </Typography>
            </Title>

            <Search data-testid="search-icon" onClick={() => toggleSearch(true)} />
        </Container>
    );
};

DefaultHeader.displayName = 'DefaultHeader';
export { DefaultHeader };
