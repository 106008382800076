import * as React from 'react';

import { isUndefined } from '~/libs/utility';

export interface PrimitiveValueFormatterProps {
    value?: number | string;
}

export interface PrimitiveValueFormatterInnerProps extends PrimitiveValueFormatterProps {}

const PrimitiveValueFormatter: React.FunctionComponent<PrimitiveValueFormatterInnerProps> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{value}</>;
};
PrimitiveValueFormatter.displayName = 'PrimitiveValueFormatter';

export { PrimitiveValueFormatter };
