import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';

import { AskForPageReload } from '~/components/AskForPageReload';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneLoader } from '~/components/SceneLoader';
import type { RetrievableData } from '~/reducers';
import type { NonEmptyArray } from '~/types';

import type { SettingsDetails } from './settings';
import { settingsDetails } from './settings';

export interface EnsureSettingsStateProps {
    settingsState: { [key: string]: RetrievableData<unknown> };
}

export interface EnsureSettingsDispatchProps {
    retrieveSetting: (key: string, retrieveData: () => Promise<unknown>) => void;
}

export interface EnsureSettingsInnerProps
    extends EnsureSettingsStateProps,
        EnsureSettingsDispatchProps,
        InjectedTranslationProps {}

export const ensureSettingsHoc =
    (requiredSettings: NonEmptyArray<keyof SettingsDetails>, showLoadingWhileRetrieving: boolean) =>
    <P extends {}>(WrappedComponent: React.ComponentType<P>): React.ComponentType<EnsureSettingsInnerProps & P> => {
        return class extends React.Component<EnsureSettingsInnerProps & P> {
            public static displayName = wrapDisplayName(WrappedComponent, 'EnsureSettings');

            private dataIsBeingLoaded(): boolean {
                return !!requiredSettings.find((key: keyof SettingsDetails) => {
                    const { fulfilled, pending } = this.props.settingsState[key];
                    return (pending && !fulfilled) || !fulfilled;
                });
            }

            private shouldAskForPageReload(): boolean {
                return !!requiredSettings.find((key: keyof SettingsDetails) => {
                    return this.props.settingsState[key].rejected;
                });
            }

            public componentDidMount(): void {
                requiredSettings.forEach((key: keyof SettingsDetails) => {
                    const { fulfilled, pending } = this.props.settingsState[key];

                    if (!pending && !fulfilled) {
                        const retrieveData = settingsDetails[key].retrieveDataFactory(this.props);
                        this.props.retrieveSetting(key, retrieveData);
                    }
                });
            }

            public render() {
                if (this.shouldAskForPageReload()) {
                    return <AskForPageReload />;
                }

                if (this.dataIsBeingLoaded()) {
                    return showLoadingWhileRetrieving ? <SceneLoader /> : null;
                }

                return <WrappedComponent {...(this.props as P)} />;
            }
        };
    };
