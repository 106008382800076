interface RecursiveFindArgs<T, U = unknown> {
    key: string;
    source: T[];
    value: U;
    where: keyof T;
}

const deepArrayFinder = <T, U>({ key, source, value, where }: RecursiveFindArgs<T, U>): T | undefined => {
    let found;

    source.some(function iterator(item) {
        if (item[key] === value) {
            found = item;
            return true;
        }

        return Array.isArray(item[where]) && (item[where] as T[]).some(iterator);
    });

    return found;
};

export { deepArrayFinder };
