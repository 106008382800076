import type { Context } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { filterTreeByName } from '../../../common/utils';
import { keyBy, omit } from '../utility';

import type {
    GenericOnHandlersArgsTree,
    OnCheckArgsTree,
    SelectAllArgs,
    TreeProviderInternalSetter,
    TreeProviderProps,
    TreeProviderSetter,
    TreeProviderState,
} from './models';
import { getChildrenIds } from './utils';

const TreeState = createContext<TreeProviderState<unknown> | undefined>(undefined);
const TreeSetter = createContext<TreeProviderInternalSetter | undefined>(undefined);

const TreeProvider = <T,>(props: TreeProviderProps<T>) => {
    const { children, dataTestId, methods: propsMethods, state: propsState } = props;

    const [methods] = useState<TreeProviderSetter>(propsMethods);
    const [state, setState] = useState<TreeProviderState<T>>({
        checkedKeys: {},
        dataTestId,
        expandedAll: false,
        expandedKeys: propsState.expandedKeys ? propsState.expandedKeys : {},
        hideSelectAll: propsState.hideSelectAll,
        items: propsState.items,
        partiallySelected: propsState.partiallySelected,
        readOnly: propsState.readOnly,
        searchEnabled: propsState.searchEnabled,
        searchingItemsName: propsState.searchingItemsName,
        selectedAll: propsState.selectedAll,
        totalItems: getChildrenIds(propsState.items).length,
    });

    useEffect(() => {
        setState({
            ...state,
            checkedKeys: {},
            expandedKeys: propsState.expandedKeys ? propsState.expandedKeys : {},
            items: state.query ? filterTreeByName(propsState.items, state.query) : propsState.items,
            readOnly: propsState.readOnly,
            totalItems: getChildrenIds(propsState.items).length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsState.items]);

    useEffect(() => {
        methods?.onChange?.(Object.keys(state.checkedKeys));
        methods?.handleExpandedKeys?.(state.expandedKeys);
    }, [methods, state.checkedKeys, state.expandedKeys]);

    const handleCheck = (args: OnCheckArgsTree<T>) => {
        const { id, isLeaf, source, value } = args;
        propsMethods?.onChangeSwitch(args.event);

        if (isLeaf) {
            setState(({ checkedKeys, ...rest }) => ({
                ...rest,
                checkedKeys: value ? { ...checkedKeys, [id]: { id, value } } : omit(checkedKeys, [id]),
            }));

            return;
        }

        const ids = [id, ...getChildrenIds(source)];
        const newValues = keyBy(
            ids.map((childId) => ({ id: childId, value })),
            'id'
        );

        setState(({ checkedKeys, ...rest }) => ({
            ...rest,
            checkedKeys: value ? { ...checkedKeys, ...newValues } : omit(checkedKeys, ids),
        }));
    };

    const handleCollapse = (args: GenericOnHandlersArgsTree) => {
        const { id, value } = args;

        if (id === 'all') {
            const ids = getChildrenIds(propsState.items);
            const newValues = keyBy(
                ids.map((childId) => ({ id: childId, value })),
                'id'
            );

            setState(({ expandedKeys, ...rest }) => ({
                ...rest,
                expandedAll: value,
                expandedKeys: value ? newValues : {},
            }));

            return;
        }

        setState(({ expandedKeys, ...rest }) => {
            return {
                ...rest,
                expandedAll: value,
                expandedKeys: value ? { ...expandedKeys, [id]: { id, value } } : omit(expandedKeys, [id]),
            };
        });
    };

    const handleSearch = (query: string) => {
        if (query) {
            setState((prev) => ({
                ...prev,
                items: filterTreeByName(propsState.items, query),
                query,
            }));
        } else {
            setState((prev) => ({
                ...prev,
                items: propsState.items,
                query: undefined,
            }));
        }
    };

    const toggleSearch = (isOpen: boolean) => {
        setState((prev) => ({ ...prev, items: propsState.items, searchEnabled: isOpen }));
    };

    const handleSelectAll = (args: SelectAllArgs) => {
        const { value } = args;

        const ids = getChildrenIds(propsState.items);
        const newValues = keyBy(
            ids.map((childId) => ({ id: childId, value })),
            'id'
        );

        setState(({ checkedKeys, ...rest }) => ({ ...rest, checkedKeys: value ? newValues : {}, selectedAll: value }));
    };

    const memoizedMethods = useMemo<TreeProviderInternalSetter>(
        () => ({
            changeSearchQuery: handleSearch,
            closeSearch: () => toggleSearch(false),
            onCheck: handleCheck,
            onCollapse: handleCollapse,
            openSearch: () => toggleSearch(true),
            selectAll: handleSelectAll,
            ...methods,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [methods]
    );

    return (
        <TreeState.Provider value={state}>
            <TreeSetter.Provider value={memoizedMethods}>{children}</TreeSetter.Provider>
        </TreeState.Provider>
    );
};
TreeProvider.displayName = 'TreeProvider';

const useTreeState = <T,>() => {
    const context = useContext(TreeState as Context<TreeProviderState<T> | undefined>);

    if (context === undefined) {
        throw new Error('useTreeState can only be used inside UserProvider');
    }

    return context;
};

const useTreeSetter = () => {
    const context = useContext(TreeSetter);

    if (context === undefined) {
        throw new Error('useTreeSetter can only be used inside UserProvider');
    }

    return context;
};

const useTree = <T,>(): [TreeProviderState<T>, TreeProviderInternalSetter<T>] => [useTreeState<T>(), useTreeSetter()];

export { TreeProvider, useTree, useTreeSetter, useTreeState };
