import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { isUndefined } from '~/libs/utility';

import type { GridColumnDefinition } from '../../models';

import { createFormatterComponent } from './formatterComponent';
import type { TableGroupRowContentClassKey } from './styles';

export interface TableGroupRowContentProps extends TableGroupRow.ContentProps {}

export interface TableGroupRowContentInnerProps
    extends TableGroupRowContentProps,
        WithStyles<TableGroupRowContentClassKey> {}

export const createTableGroupRowContent: <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
) => React.FunctionComponent<TableGroupRowContentInnerProps> = (columnDefinitions) => (props) => {
    const { children, classes, column, row } = props;

    const value = row?.value;
    const columnDefinition = columnDefinitions.find((c) => c.name === column.name);
    const formatUndefinedValue = columnDefinition && columnDefinition.formatUndefinedValue;
    const FormatterComponent = createFormatterComponent(columnDefinition);

    const naToShow = !formatUndefinedValue && isUndefined(value);
    const content = naToShow ? '-' : FormatterComponent ? <FormatterComponent value={value} /> : children;

    return (
        <TableGroupRow.Content {...props} className={classes.columnTitle} data-id="group-row" row={{ ...row, value }}>
            {content}
        </TableGroupRow.Content>
    );
};
