import type { MapModes } from '@fv/components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';

import { getMapLayersProps } from '../layers';

interface UseMapLayersArgs {
    mapMode: MapModes;
}

const useMapLayers = (args: UseMapLayersArgs) => {
    const { mapMode } = args;
    const { mapLayers } = useSelector(settingDataSelector(SettingsKey.SECURABLES));

    return useMemo(
        () =>
            getMapLayersProps({
                mapLayersSecurables: mapLayers,
                mapMode,
            }),
        [mapMode, mapLayers]
    );
};

export { useMapLayers };
