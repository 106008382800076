import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type HeaderTemplateClassKey =
    | 'centerWrapper'
    | 'filters'
    | 'header'
    | 'headerBar'
    | 'leftWrapper'
    | 'rightWrapper'
    | 'toolbar';

export const HeaderTemplateStyleRules: StyleRulesCallback<Theme, {}, HeaderTemplateClassKey> = (theme: Theme) =>
    createStyles({
        centerWrapper: {
            flexBasis: '34%',
        },
        filters: {
            marginRight: theme.spacing(2),
        },
        header: {
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            zIndex: 1,
        },
        headerBar: {
            backgroundColor: theme.palette.primary.dark,
            zIndex: theme.zIndex.appBar + 1,
        },
        leftWrapper: {
            alignItems: 'center',
            display: 'flex',
            flexBasis: '33%',
            justifyContent: 'space-between',
            overflow: 'hidden',
        },
        rightWrapper: {
            display: 'flex',
            flexBasis: '33%',
            flexShrink: 0,
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
        },
        toolbar: {
            minHeight: theme.spacing(7),
            paddingRight: theme.spacing(),
        },
    });
