import { createAction } from 'redux-actions';

import { ApiClient, ApiException, ServerResultStatus, retryableRequest } from '~/services/ApiClient';

import { ACTIONS_KEYS } from './actionTypes';
import { resolveAssetGroupsAdminChangelog } from './utils';

const queryAssetGroupsAdminList = createAction(ACTIONS_KEYS.LIST, async () => {
    try {
        const { items } = await ApiClient.getAdminAssetGroups();

        return items;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});

const getAdminAssetGroupChangelogs = async (assetGroupId: number) => {
    const { items } = await retryableRequest(() => ApiClient.getAssetGroupChangelogs(assetGroupId));
    return resolveAssetGroupsAdminChangelog(items);
};

const resetState = createAction(ACTIONS_KEYS.RESET);

export { getAdminAssetGroupChangelogs, queryAssetGroupsAdminList, resetState };
