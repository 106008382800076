import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ChangelogEntityClassKeys = 'entryChanges' | 'section';

export const useStyles = makeStyles<Theme, {}, ChangelogEntityClassKeys>((theme) => ({
    entryChanges: {
        color: theme.palette.grey.A700,
        fontSize: '12px',
        margin: theme.spacing(),
        marginLeft: theme.spacing(2),
    },
    section: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.A100,
        borderCollapse: 'collapse',
    },
}));
