import { parse } from 'qs';
import * as React from 'react';

import { ReloadPage } from '~/components/ReloadPage';
import { SceneLoader } from '~/components/SceneLoader';

import { locationReplace } from '../../locationReplace';

import type { AuthenticateInnerProps, AuthenticateSearchParams, AuthenticateState } from './models';

export class AuthenticateComponent extends React.Component<AuthenticateInnerProps, AuthenticateState> {
    private handleReload = () => {
        this.props.history.push('/');
    };

    private initiateAuthenticate = () => {
        if (this.params.accessToken) {
            this.props.authenticateToken(this.params.accessToken);
            this.setState({ authenticateTriggered: true });
        }
    };

    private params: AuthenticateSearchParams;

    constructor(props: AuthenticateInnerProps) {
        super(props);
        this.state = { authenticateTriggered: false };
        this.params = parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};
    }

    private isAbsoluteUrl(url: string): boolean {
        return /^https?:\/\//.test(url);
    }

    private redirect(url: string) {
        if (this.isAbsoluteUrl(url)) {
            locationReplace(url);
        } else {
            this.props.history.replace(url);
        }
    }

    public componentDidMount(): void {
        if (this.props.isAuthenticated) {
            this.props.logout();
            return;
        }

        this.initiateAuthenticate();
    }

    public componentDidUpdate(): void {
        if (this.props.isAuthenticated) {
            this.redirect(this.params.redirectUrl || '/');
            return;
        }

        if (!this.state.authenticateTriggered) {
            this.initiateAuthenticate();
        }
    }

    public render(): React.ReactNode {
        if (!this.params.accessToken) {
            return <ReloadPage handleReload={this.handleReload} />;
        }

        if (!this.state.authenticateTriggered || this.props.authenticating) {
            return <SceneLoader />;
        }

        return <ReloadPage handleReload={this.handleReload} />;
    }
}
