import { isUndefined } from '~/libs/utility';
import type { ResolvedVehicle } from '~/services/ApiClient';

import type { VehicleResolverArgs } from './models';

export const vehicleResolver = ({
    categories,
    deviceTypes,
    vehicle,
    vehicleTypes,
}: VehicleResolverArgs): ResolvedVehicle => ({
    ...vehicle,
    category: categories[vehicle?.category],
    deviceType: vehicle?.device && deviceTypes[vehicle.device.type],
    vehicleType: !isUndefined(vehicle?.vehicleTypeId) ? vehicleTypes[vehicle.vehicleTypeId] : undefined,
});
