import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssetSelectorState } from '../../context';

import { TitleContainer } from './styles';

const Footer: FC = () => {
    const { selectedCheckableCount, singleSelectionMode, totalItems } = useAssetSelectorState();
    const { t } = useTranslation();

    return (
        <>
            <Divider />

            <TitleContainer data-testid="asset-selector-footer">
                <Typography
                    align="right"
                    color="textSecondary"
                    data-testid="asset-selector-footer-message"
                    display="block"
                    variant="caption"
                >
                    {singleSelectionMode
                        ? t('components.common.eligible-total-assets', {
                              total: totalItems,
                          })
                        : t('components.common.selected-of', {
                              selected: selectedCheckableCount,
                              total: totalItems,
                          })}
                </Typography>
            </TitleContainer>
        </>
    );
};

export { Footer };
