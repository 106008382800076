import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import { useTheme } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';

import type { TableFixedColumnsCellClassKey } from './styles';

export interface TableFixedColumnsCellProps extends TableFixedColumns.CellProps {
    highlighted: boolean;
    selected: boolean;
}

export interface TableFixedColumnsCellInnerProps
    extends TableFixedColumnsCellProps,
        WithStyles<TableFixedColumnsCellClassKey> {}

export const TableFixedColumnsCellComponent: FC<TableFixedColumnsCellInnerProps> = (props) => {
    const { classes, highlighted, selected, ...restProps } = props;

    const className = classNames({ [classes.highlighted]: highlighted });
    const theme = useTheme();

    return (
        <TableFixedColumns.Cell
            {...restProps}
            className={className}
            style={{ backgroundColor: selected ? theme.palette.grid.selected : theme.palette.common.white }}
        />
    );
};
