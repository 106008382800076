import * as React from 'react';

import { ContainerIcon, DriverIcon, TrailerIcon, TruckIcon } from '~/components/Icons';
import { AssetType } from '~/services/ApiClient';

export interface AssetTypeIconFormatterProps {
    value: AssetType;
}

export interface AssetTypeIconFormatterInnerProps extends AssetTypeIconFormatterProps {}

// TODO accept assetReference to check with instanceof
const AssetTypeIconFormatter: React.FC<AssetTypeIconFormatterInnerProps> = ({ value }) => {
    switch (value) {
        case AssetType.Container:
            return <ContainerIcon />;
        case AssetType.Driver:
            return <DriverIcon />;
        case AssetType.Trailer:
            return <TrailerIcon />;
        case AssetType.Vehicle:
            return <TruckIcon />;
        default:
            return null;
    }
};
AssetTypeIconFormatter.displayName = 'AssetTypeIconFormatter';

export { AssetTypeIconFormatter };
