import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { useTranslation } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedVehicleType } from '~/services/ApiClient';

import { SectionName } from '../../constants';

export interface GeneralSectionProps {
    adminVehicleType: ResolvedVehicleType;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface GeneralSectionInnerProps extends GeneralSectionProps {}

const GeneralSection: React.FunctionComponent<GeneralSectionInnerProps> = ({
    adminVehicleType,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const { t } = useTranslation();
    const keyValueItems: KeyValueItem[] = [
        {
            label: t('name'),
            value: adminVehicleType.name ? <span>{adminVehicleType.name}</span> : undefined,
            valueDataId: 'name',
        },
        {
            label: t('description'),
            value: adminVehicleType.description ? <span>{adminVehicleType.description}</span> : undefined,
            valueDataId: 'description',
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-types-administration-general-section"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(SectionName.VEHICLETYPESADMINISTRATION_GENERAL)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="general-list" />
        </ReorderableSection>
    );
};
GeneralSection.displayName = 'GeneralSection';

export { GeneralSection };
