import type { PositionStatus } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    POSITIONSTATUSES = 'POSITIONSTATUSES',
}

export enum ActionTypeKeys {
    POSITIONSTATUSES_FULFILLED = 'POSITIONSTATUSES_FULFILLED',
    POSITIONSTATUSES_PENDING = 'POSITIONSTATUSES_PENDING',
    POSITIONSTATUSES_REJECTED = 'POSITIONSTATUSES_REJECTED',
}

export type PositionStatusesPayload = PositionStatus[];

export interface PositionStatusesMeta {
    positionGroupId: number;
    startDate: Date;
    stopDate: Date;
}

export type ActionTypes =
    | FulfilledMeta<ActionTypeKeys.POSITIONSTATUSES_FULFILLED, PositionStatusesPayload, PositionStatusesMeta>
    | PendingMeta<ActionTypeKeys.POSITIONSTATUSES_PENDING, PositionStatusesMeta>
    | RejectedMeta<ActionTypeKeys.POSITIONSTATUSES_REJECTED, undefined, PositionStatusesMeta>;
