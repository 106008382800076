import type { MapModes } from '@fv/components';
import { MAP_MODES } from '@fv/components';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY = 'history-detailspane-vehicle-state';

export interface HistoryVehicleDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapMode: MapModes;
    miniMapZoomLevel: number;
}

export const defaultHistoryVehicleDetailsPaneUserPreferences: HistoryVehicleDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapMode: MAP_MODES.ROAD,
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MINIMAP, SectionName.LOCATION, SectionName.METADATA],
};

export const processResponse = (
    preferences: Partial<HistoryVehicleDetailsPaneUserPreferences>
): HistoryVehicleDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultHistoryVehicleDetailsPaneUserPreferences);
};

export const requiredHistoryVehicleDetailsPaneUserPreferences = {
    defaultState: defaultHistoryVehicleDetailsPaneUserPreferences,
    key: HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    processResponse,
};
