import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesTrailerDisplayFormat,
    NamedTrailerReference,
    PojoModel,
    Trailer,
} from '~/services/ApiClient';
import { formatTrailerName } from '~/services/Formatters';

export interface TrailerNameFormatterProps {
    trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat;
    value?: PojoModel<NamedTrailerReference> | PojoModel<Trailer>;
}

export interface TrailerNameFormatterInnerProps extends TrailerNameFormatterProps {}

const TrailerNameFormatter: React.FunctionComponent<TrailerNameFormatterInnerProps> = ({
    trailerDisplayNameFormat,
    value,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatTrailerName(value, trailerDisplayNameFormat)}</>;
};
TrailerNameFormatter.displayName = 'TrailerNameFormatter';

export { TrailerNameFormatter };
