import Lens from '@mui/icons-material/Lens';
import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import type { TooltipPayload, TooltipProps } from 'recharts';

import { isUndefined } from '~/libs/utility';

import { useChartTooltipStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ChartTooltip: FC<TooltipProps> = (p) => {
    const classes = useChartTooltipStyles({});
    const sortedPayload = p.itemSorter && p.payload ? [...p.payload].sort(p.itemSorter) : p.payload;
    const getStyle = (v: TooltipPayload) => ({ color: v.color, opacity: v.strokeOpacity });
    const formattedLabel = p.labelFormatter && !isUndefined(p.label) ? p.labelFormatter(p.label) : p.label;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const values = sortedPayload?.map((v, idx) => (
        <div className={classes.valueRow} key={v.name}>
            <Lens fontSize="inherit" style={getStyle(v)} />
            <Typography className={classes.label} color="textSecondary" variant="body2">
                {v.name}
            </Typography>
            <Typography className={classes.value} variant="body2">
                {p.formatter ? p.formatter(v.value as number | number[], v.name, v, idx) : v.value}
                {v.unit}
            </Typography>
        </div>
    ));

    return (
        <Paper className={classes.root} elevation={5}>
            {!isUndefined(formattedLabel) && <Typography variant="subtitle2">{formattedLabel}</Typography>}
            {values}
        </Paper>
    );
};
ChartTooltip.displayName = 'ChartTooltip';

export { ChartTooltip };
