import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'USER_ROLE';

export const USER_ROLES_ACTION_TYPE = {
    CREATE: `[${MODULE_PREFIX}] CREATE_USER_ROLE`,
    DELETE: `[${MODULE_PREFIX}] DELETE_USER_ROLES`,
    GET: `[${MODULE_PREFIX}] GET_USER_ROLES`,
    POST: `[${MODULE_PREFIX}] POST`,
    POST_QUERY_ROLE: `[${MODULE_PREFIX}] POST_QUERY_ROLE`,
    RESET: `[${MODULE_PREFIX}] RESET`,
    UPDATE_PERMISSION: `[${MODULE_PREFIX}] UPDATE_PERMISSION_ROLE`,
    UPDATE_ROLE: `[${MODULE_PREFIX}]UPDATE_ROLE`,
} as const;

export const ACTIONS = actionGenerator(USER_ROLES_ACTION_TYPE, MODULE_PREFIX);
