import memoizeOne from 'memoize-one';

import type { SectionDefinition } from '~/components/Sections';

import { SectionName } from '../../consts';

import { DailyOverviewSection } from './DailyOverview';
import { DriveSection } from './Drive';
import { DutySection } from './Duty';
import { GeneralSection } from './General';
import { LaborSection } from './Labor';
import type { GetSectionsProps } from './models';
import { RestSection } from './Rest';

const getSections = (props: GetSectionsProps): SectionDefinition[] => {
    const { collapsedSections, driverStatus, toggleCollapsed } = props;

    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <DailyOverviewSection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.DAILY_OVERVIEW)}
                    toggleCollapsed={toggleCollapsed(SectionName.DAILY_OVERVIEW)}
                />
            ),
            name: SectionName.DAILY_OVERVIEW,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <GeneralSection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.GENERAL)}
                    toggleCollapsed={toggleCollapsed(SectionName.GENERAL)}
                />
            ),
            name: SectionName.GENERAL,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <DriveSection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVE)}
                    toggleCollapsed={toggleCollapsed(SectionName.DRIVE)}
                />
            ),
            name: SectionName.DRIVE,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <LaborSection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.LABOR)}
                    toggleCollapsed={toggleCollapsed(SectionName.LABOR)}
                />
            ),
            name: SectionName.LABOR,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <DutySection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.DUTY)}
                    toggleCollapsed={toggleCollapsed(SectionName.DUTY)}
                />
            ),
            name: SectionName.DUTY,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <RestSection
                    dragHandleElement={dragHandleElement}
                    driverStatus={driverStatus}
                    isCollapsed={collapsedSections.includes(SectionName.REST)}
                    toggleCollapsed={toggleCollapsed(SectionName.REST)}
                />
            ),
            name: SectionName.REST,
        },
    ];
};

const getSectionsMemoized = memoizeOne(getSections);

export { getSections, getSectionsMemoized };
