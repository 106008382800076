import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { AssetAvatar } from '~/components/AssetAvatar';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneHeader } from '~/components/SceneDetailsPane';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import { formatIgnitionStatusTitle, formatVehicleCategory, formatVehicleName } from '~/services/Formatters';

import { IgnitionStatusBadge } from '../IgnitionStatusBadge';

import type { VehicleHeaderClassKey } from './styles';

export interface VehicleHeaderProps {
    entry: MonitoringVehicleEntry;
}

export interface VehicleHeaderInnerProps
    extends VehicleHeaderProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps,
        WithStyles<VehicleHeaderClassKey> {}

export const VehicleHeaderComponent: React.FC<VehicleHeaderInnerProps> = ({
    classes,
    displayPreferences,
    entry,
    t,
}) => {
    const { vehicle, vehicleStatus: status } = entry;

    const tooltipTitle = formatIgnitionStatusTitle(t, status?.ignitionStatus);

    const ignitionStatusBadge =
        status && !isUndefined(status.ignitionStatus) ? (
            <IgnitionStatusBadge ignitionStatus={status.ignitionStatus} />
        ) : undefined;

    const vehicleAvatar = (
        <AssetAvatar badge={ignitionStatusBadge} classes={classes} icon={<TruckIcon />} tooltip={tooltipTitle} />
    );
    const formattedVehicleCategory = formatVehicleCategory(t, vehicle.category);
    const secondaryIdentifier = !isUndefined(vehicle.vehicleType)
        ? `${formattedVehicleCategory} - ${vehicle.vehicleType.name}`
        : formattedVehicleCategory;

    return (
        <SceneDetailsPaneHeader
            avatar={vehicleAvatar}
            primaryIdentifier={formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat)}
            secondaryIdentifier={secondaryIdentifier}
        />
    );
};
