import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { DurationFormatter, ViolationFieldFormatterFactory, ViolationType } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { ViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';

import { SectionName } from '../../constants';

export interface DriveSectionProps {
    availableBiWeeklyDrive?: Duration;
    availableDailyDrive?: Duration;
    availableWeeklyDrive?: Duration;
    continuousDrive?: ViolationField<Duration>;
    dailyDrive?: ViolationField<Duration>;
    dragHandleElement: JSX.Element;
    driveBreak?: Duration;
    extendedDayDriveCount?: ViolationField<number>;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    weeklyDrive?: ViolationField<Duration>;
}

export interface DriveSectionInnerProps extends DriveSectionProps, InjectedTranslationProps {}

export const DriveSectionComponent: React.FC<DriveSectionInnerProps> = ({
    availableBiWeeklyDrive,
    availableDailyDrive,
    availableWeeklyDrive,
    continuousDrive,
    dailyDrive,
    dragHandleElement,
    driveBreak,
    extendedDayDriveCount,
    isCollapsed,
    t,
    toggleCollapsed,
    weeklyDrive,
}) => {
    const ViolationDurationFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    const ViolationNumberFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: number) => v.toString()),
        []
    );

    const availableDailyDrivingValue = availableDailyDrive && (
        <DurationFormatter
            threshold={moment.duration()}
            value={availableDailyDrive}
            violationType={ViolationType.ABOVE}
        />
    );
    const availableWeeklyDrivingValue = availableWeeklyDrive && (
        <DurationFormatter
            threshold={moment.duration()}
            value={availableWeeklyDrive}
            violationType={ViolationType.ABOVE}
        />
    );
    const availableBiWeeklyDrivingValue = availableBiWeeklyDrive && (
        <DurationFormatter
            threshold={moment.duration()}
            value={availableBiWeeklyDrive}
            violationType={ViolationType.ABOVE}
        />
    );
    const continuousDrivingValue = continuousDrive && (
        <ViolationDurationFormatter
            fieldName={formatDriverSubpageListItemLabel(t, 'drive-continuous-driving')}
            t={t}
            value={continuousDrive}
        />
    );
    const driveBreakValue = driveBreak && <DurationFormatter value={driveBreak} />;
    const dailyDrivingValue = dailyDrive && (
        <ViolationDurationFormatter
            fieldName={formatDriverSubpageListItemLabel(t, 'drive-daily-driving')}
            t={t}
            value={dailyDrive}
        />
    );
    const weeklyDrivingValue = weeklyDrive && (
        <ViolationDurationFormatter
            fieldName={formatDriverSubpageListItemLabel(t, 'drive-weekly-driving')}
            t={t}
            value={weeklyDrive}
        />
    );
    const extendedDrivingCountValue = !isUndefined(extendedDayDriveCount) ? (
        <ViolationNumberFormatter
            fieldName={formatDriverSubpageListItemLabel(t, 'drive-extended-driving-count')}
            t={t}
            value={extendedDayDriveCount}
        />
    ) : undefined;

    const keyValueItems: KeyValueItem[] = [
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-continuous-driving'),
            value: continuousDrivingValue,
            valueDataId: 'continuous-driving',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-drive-break'),
            value: driveBreakValue,
            valueDataId: 'drive-break',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-daily-driving'),
            value: dailyDrivingValue,
            valueDataId: 'daily-driving',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-weekly-driving'),
            value: weeklyDrivingValue,
            valueDataId: 'weekly-driving',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-extended-driving-count'),
            value: extendedDrivingCountValue,
            valueDataId: 'extended-driving-count',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-available-daily-driving'),
            value: availableDailyDrivingValue,
            valueDataId: 'available-daily-driving-time',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-available-weekly-driving'),
            value: availableWeeklyDrivingValue,
            valueDataId: 'available-weekly-driving-time',
        },
        {
            label: formatDriverSubpageListItemLabel(t, 'drive-available-bi-weekly-driving'),
            value: availableBiWeeklyDrivingValue,
            valueDataId: 'available-biweekly-driving-time',
        },
    ];

    return (
        <ReorderableSection
            dataId={SectionName.DRIVE}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.DRIVE}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="property-list" />
        </ReorderableSection>
    );
};
