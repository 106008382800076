import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { SummarySettingItemProps } from './component';

export type SummarySettingItemClassKeys = 'action';

export const SummarySettingItemStyleRules: StyleRulesCallback<
    Theme,
    SummarySettingItemProps,
    SummarySettingItemClassKeys
> = () =>
    createStyles({
        action: {
            cursor: (props) => (props.onClick ? 'pointer' : undefined),
            height: 24,
            width: 24,
        },
    });
