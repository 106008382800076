import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { ControllerProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil, isUndefined } from '~/libs/utility';
import type { EnumFieldWorkflowFormElement } from '~/services/ApiClient';
import { EnumControlType } from '~/services/ApiClient';

import type { FieldValueDictionary } from '../../../../../../../../models';

import type { EnumerationWorkflowFieldClassKey } from './styles';

export interface EnumerationWorkflowFieldProps {
    dataId: string;
    disabled?: boolean;
    field: EnumFieldWorkflowFormElement;
    label?: string;
}

export interface EnumerationWorkflowFieldInnerProps
    extends EnumerationWorkflowFieldProps,
        InjectedTranslationProps,
        WithStyles<EnumerationWorkflowFieldClassKey> {}

export const EnumerationWorkflowFieldComponent: React.FC<EnumerationWorkflowFieldInnerProps> = ({
    classes,
    dataId,
    disabled,
    field,
    label,
    t,
}) => {
    let render: ControllerProps['render'];

    const {
        control,
        formState: { errors },
    } = useFormContext<FieldValueDictionary>();

    const parse = (stringValue: string) => {
        const value = parseInt(stringValue, 10);
        return Number.isNaN(value) ? undefined : value;
    };

    const helperText = (value?: string) => {
        return (
            field.isRequired &&
            !field.isReadOnly && (
                <FormHelperText error={!!errors[field.id]}>
                    {errors[field.id]
                        ? t('wf-field-error-an-option-must-be-selected')
                        : isNil(value) || value === ''
                          ? t('wf-field-error-please-select-an-option')
                          : undefined}
                </FormHelperText>
            )
        );
    };

    switch (field.controlType) {
        case EnumControlType.RadioButtons: {
            const radioButtonValues = field.options.map((option) => {
                return (
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={<Radio />}
                        disabled={field.isReadOnly}
                        key={option.id}
                        label={option.text}
                        value={option.id}
                    />
                );
            });

            // eslint-disable-next-line react/no-unstable-nested-components
            render = ({ field: fieldFormProps }) => {
                return (
                    <FormControl data-id={dataId} disabled={disabled} fullWidth size="small" variant="standard">
                        {!isUndefined(label) && (
                            <FormLabel component="legend" error={!!errors[field.id]} required={field.isRequired}>
                                {label}
                            </FormLabel>
                        )}
                        <RadioGroup
                            {...fieldFormProps}
                            data-id={`radio-button:${field.id}`}
                            onChange={(e) => {
                                fieldFormProps.onChange(parse(e.target.value));
                            }}
                            value={fieldFormProps.value}
                        >
                            {radioButtonValues}
                        </RadioGroup>
                        {helperText(fieldFormProps.value)}
                    </FormControl>
                );
            };
            break;
        }
        case EnumControlType.Dropdown:
        case EnumControlType.List: {
            const dropdownListValues = field.options.map((option) => {
                return (
                    <MenuItem key={option.id} value={option.id}>
                        {option.text}
                    </MenuItem>
                );
            });
            // eslint-disable-next-line react/no-unstable-nested-components
            render = ({ field: fieldFormProps }) => {
                return (
                    <FormControl data-id={dataId} disabled={disabled} fullWidth size="small" variant="outlined">
                        <InputLabel error={!!errors[field.id]} id={`select-field:${field.id}`}>
                            {label}
                        </InputLabel>
                        <Select
                            labelId={`select-field:${field.id}`}
                            variant="outlined"
                            {...fieldFormProps}
                            disabled={field.isReadOnly}
                            error={!!errors[field.id]}
                            label={label}
                            onChange={(e) => {
                                fieldFormProps.onChange(parse(e.target.value as string));
                            }}
                            required={field.isRequired}
                        >
                            {dropdownListValues}
                        </Select>
                        {helperText(fieldFormProps.value)}
                    </FormControl>
                );
            };
            break;
        }
        default:
            throw new Error('Unsupported value provided');
    }

    return (
        <Controller
            control={control}
            defaultValue=""
            name={`${field.id}`}
            render={render}
            rules={{ required: field.isRequired }}
        />
    );
};
