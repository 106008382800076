import type { TrailerEvent } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    TRAILEREVENT = 'TRAILEREVENT',
}

export enum ActionTypeKeys {
    TRAILEREVENT_FULFILLED = 'TRAILEREVENT_FULFILLED',
    TRAILEREVENT_PENDING = 'TRAILEREVENT_PENDING',
    TRAILEREVENT_REJECTED = 'TRAILEREVENT_REJECTED',
}

export interface TrailerEventsPayload {
    readonly items: TrailerEvent[];
}

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.TRAILEREVENT_FULFILLED, TrailerEventsPayload>
    | Pending<ActionTypeKeys.TRAILEREVENT_PENDING>
    | Rejected<ActionTypeKeys.TRAILEREVENT_REJECTED>;
