import { MAP_MODES } from '@fv/components';
import Map from '@mui/icons-material/Map';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';

import { IconDivider } from '~/components/IconDivider';
import { HybridMapIcon, SatelliteIcon, TrafficInfoOnIcon, TruckAttributesOnIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { InjectedMapSharedProps } from '~/components/MapSharedProps';
import { TitledIconButton } from '~/components/TitledIconButton';

import type { MapActionsClassKey } from './styles';

export interface MapActionsProps {}

export interface DispatchProps {
    changeMapModeToHybrid: () => void;
    changeMapModeToMap: () => void;
    changeMapModeToSatellite: () => void;
    showAllSelectedAssets: () => void;
}

export interface MapActionsInnerProps
    extends DispatchProps,
        InjectedMapSharedProps,
        InjectedTranslationProps,
        WithStyles<MapActionsClassKey> {}

export const MapActionsComponent: FC<MapActionsInnerProps> = (props) => {
    const {
        changeMapModeToHybrid,
        changeMapModeToMap,
        changeMapModeToSatellite,
        classes,
        mapLayersSecurables,
        mapMode,
        showAllSelectedAssets,
        t,
        toggleTrafficIncidentsOverlayEnabled,
        toggleTruckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
    } = props;

    const trafficIconContext = trafficIncidentsOverlayEnabled
        ? { state: 'on', tooltip: t('hide-traffic-information') }
        : { state: 'off', tooltip: t('show-traffic-information') };

    const truckIconContext = truckAttributesOverlayEnabled
        ? { state: 'on', tooltip: t('hide-truck-limitations') }
        : { state: 'off', tooltip: t('show-truck-limitations') };

    const changeViewModeControls = mapLayersSecurables.satelliteAllowed && (
        <>
            <TitledIconButton
                area-selected={`${mapMode === MAP_MODES.ROAD}`}
                className={classNames({ [classes.selected]: mapMode === MAP_MODES.ROAD })}
                color="inherit"
                data-id="map-view:map"
                onClick={changeMapModeToMap}
                placement="bottom-end"
                title={t('map')}
            >
                <Map />
            </TitledIconButton>

            <TitledIconButton
                area-selected={`${mapMode === MAP_MODES.HYBRID}`}
                className={classNames({ [classes.selected]: mapMode === MAP_MODES.HYBRID })}
                color="inherit"
                data-id="map-view:hybrid"
                onClick={changeMapModeToHybrid}
                placement="bottom-end"
                title={t('hybrid')}
            >
                <HybridMapIcon />
            </TitledIconButton>

            <TitledIconButton
                area-selected={`${mapMode === MAP_MODES.SATELLITE}`}
                className={classNames({ [classes.selected]: mapMode === MAP_MODES.SATELLITE })}
                color="inherit"
                data-id="map-view:satellite"
                onClick={changeMapModeToSatellite}
                placement="bottom-end"
                title={t('satellite')}
            >
                <SatelliteIcon />
            </TitledIconButton>
        </>
    );

    const toggleTrafficIncidentsOverlayControl = mapLayersSecurables.trafficIncidentsAllowed && (
        <TitledIconButton
            area-selected={trafficIncidentsOverlayEnabled}
            className={classNames({ [classes.selected]: trafficIncidentsOverlayEnabled })}
            color="inherit"
            data-id={`traffic-incidents:${trafficIconContext.state}`}
            onClick={toggleTrafficIncidentsOverlayEnabled}
            placement="bottom-end"
            title={trafficIconContext.tooltip}
        >
            <TrafficInfoOnIcon />
        </TitledIconButton>
    );

    const toggleTruckAttributesOverlayControl = mapLayersSecurables.truckAttributesAllowed && (
        <TitledIconButton
            area-selected={truckAttributesOverlayEnabled}
            className={classNames({ [classes.selected]: truckAttributesOverlayEnabled })}
            color="inherit"
            data-id={`truck-attributes:${truckIconContext.state}`}
            onClick={toggleTruckAttributesOverlayEnabled}
            placement="bottom-end"
            title={truckIconContext.tooltip}
        >
            <TruckAttributesOnIcon />
        </TitledIconButton>
    );

    const toggleExtraLayersControls = (toggleTrafficIncidentsOverlayControl || toggleTruckAttributesOverlayControl) && (
        <div className={classNames({ [classes.toggleExtraLayersControlsLeftMargin]: changeViewModeControls })}>
            {toggleTrafficIncidentsOverlayControl}
            {toggleTruckAttributesOverlayControl}
        </div>
    );

    return (
        <>
            <TitledIconButton
                color="inherit"
                data-id="show-all-selected-assets"
                onClick={showAllSelectedAssets}
                placement="bottom-end"
                title={t('show-all-selected-assets')}
            >
                <ZoomOutMap />
            </TitledIconButton>

            <IconDivider />

            {changeViewModeControls}
            {toggleExtraLayersControls}
        </>
    );
};
