import { createAction } from 'redux-actions';

import type { AdminVehicleEdit, GetAdminVehiclesResponse } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    SaveAdminVehicleRequest,
    ServerResultStatus,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveAdminVehicleExecutor = (): Promise<GetAdminVehiclesResponse> =>
    retryableRequest(() => ApiClient.getAdminVehicles());

const retrieveVehicleAdministrationAction = createAction<Promise<GetAdminVehiclesResponse>>(
    ActionTypePrefixes.ADMINVEHICLEGET,
    retrieveAdminVehicleExecutor
);

const updateAdminVehicleExecutor = async (vehicleId: number, item: AdminVehicleEdit): Promise<ServerResultStatus> => {
    try {
        await ApiClient.updateAdminVehicle(vehicleId, createApiModel(SaveAdminVehicleRequest, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

const createAdminVehicleExecutor = async (item: AdminVehicleEdit): Promise<ServerResultStatus> => {
    try {
        await ApiClient.createAdminVehicle(createApiModel(SaveAdminVehicleRequest, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

const updateAdminVehicleAction = createAction(ActionTypePrefixes.ADMINVEHICLEUPDATE, updateAdminVehicleExecutor);
const createAdminVehicleAction = createAction(ActionTypePrefixes.ADMINVEHICLECREATE, createAdminVehicleExecutor);
const updateAdminVehicleResetAction = createAction(ActionTypeKeys.ADMINVEHICLEUPDATE_RESET);
const getVehicleChangelogsAction = createAction(
    ActionTypePrefixes.ADMINVEHICLECHANGELOGS_GET,
    (vehicleId: number) => retryableRequest(() => ApiClient.getVehicleAdministrationChangelogs(vehicleId)),
    (vehicleId: number) => vehicleId
);
const getVehicleDefaultSettingsAction = createAction(ActionTypePrefixes.ADMINVEHICLEDEFAULTSETTINGS_GET, () =>
    retryableRequest(() => ApiClient.getVehicleDefaultSettings())
);
const getVehicleSettingsAction = createAction(
    ActionTypePrefixes.ADMINVEHICLESETTINGS_GET,
    (vehicleId: number) => retryableRequest(() => ApiClient.getVehicleAdministrationSettings(vehicleId)),
    (vehicleId: number) => vehicleId
);

const deleteAdminVehicle = createAction(ActionTypePrefixes.ADMINVEHICLE_DELETE, async (vehicleId: number) => {
    try {
        await ApiClient.deleteAdminVehicle(vehicleId);
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
});

export {
    createAdminVehicleAction,
    createAdminVehicleExecutor,
    deleteAdminVehicle,
    getVehicleChangelogsAction,
    getVehicleDefaultSettingsAction,
    getVehicleSettingsAction,
    retrieveAdminVehicleExecutor,
    retrieveVehicleAdministrationAction,
    updateAdminVehicleAction,
    updateAdminVehicleExecutor,
    updateAdminVehicleResetAction,
};
