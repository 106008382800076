import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, ResolvedTrailer, Trailer } from '~/services/ApiClient';

export const trailerResolver = (
    { init, reeferManufacturer, toJSON, trailerManufacturer, ...trailerData }: Trailer,
    trailerManufacturers: NumericDictionary<Classification>,
    reeferManufacturers: NumericDictionary<Classification>
): ResolvedTrailer => ({
    ...trailerData,
    reeferManufacturer: !isUndefined(reeferManufacturer) ? reeferManufacturers[reeferManufacturer] : undefined,
    trailerManufacturer: trailerManufacturers[trailerManufacturer],
});
