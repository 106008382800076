export const INTROCAROUSEL_USERPREFERENCES_KEY = 'intro-carousel';

export interface IntroCarouselUserPreferences {
    seenRevision?: number;
}

export const defaultIntroCarouselUserPreferences: IntroCarouselUserPreferences = {};

export const requiredIntroCarouselUserPreferences = {
    defaultState: defaultIntroCarouselUserPreferences,
    key: INTROCAROUSEL_USERPREFERENCES_KEY,
};
