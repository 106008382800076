import { Checkbox, FormControlLabel } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { ChartSeries } from '../../../../../../models';

import type { ChartLegendItemClassKey } from './styles';

export interface ChartLegendItemProps {
    onChange: (selected: boolean) => void;
    selected: boolean;
    series: ChartSeries<never>;
}

export interface ChartLegendItemInnerProps extends ChartLegendItemProps, WithStyles<ChartLegendItemClassKey> {}

export const ChartLegendItemComponent: React.FC<ChartLegendItemInnerProps> = ({
    classes,
    onChange,
    selected,
    series,
}) => {
    const onChangeHandler = React.useCallback(
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            onChange(checked);
        },
        [onChange]
    );

    return (
        <FormControlLabel
            control={<Checkbox checked={selected} className={classes.checkbox} color="default" />}
            data-id={`item:${series.name}`}
            label={series.title}
            onChange={onChangeHandler}
        />
    );
};
