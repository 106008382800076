import React, { useEffect } from 'react';

import { logEvent } from '~/services/Analytics';

export const withAnalyticsEvent =
    <BaseProps extends {}>(eventCategory: string, event: ((p: BaseProps) => string) | string) =>
    (BaseComponent: React.ComponentType<BaseProps>): React.ComponentType<BaseProps> => {
        const TheBaseComponent = BaseComponent as React.ComponentType<{}>;

        const Hoc = (props: BaseProps) => {
            const eventName = typeof event === 'function' ? event(props) : event;
            useEffect(() => {
                logEvent(eventCategory, eventName);
            }, [eventName]);

            return <TheBaseComponent {...props} />;
        };
        Hoc.displayName = `withAnalyticsEvent(${BaseComponent.name})`;

        return Hoc;
    };
