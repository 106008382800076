import type { VehiclesResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    VEHICLES = 'VEHICLES',
}

export enum ActionTypeKeys {
    VEHICLES_FULFILLED = 'VEHICLES_FULFILLED',
    VEHICLES_PENDING = 'VEHICLES_PENDING',
    VEHICLES_REJECTED = 'VEHICLES_REJECTED',
}

export type VehiclesPayload = Readonly<VehiclesResponse>;

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.VEHICLES_FULFILLED, VehiclesPayload>
    | Pending<ActionTypeKeys.VEHICLES_PENDING>
    | Rejected<ActionTypeKeys.VEHICLES_REJECTED>;
