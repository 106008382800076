import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type AssetAvatarClassKey = 'avatar' | 'avatarContainer' | 'badge';

export const AssetAvatarStyleRules: StyleRulesCallback<Theme, {}, AssetAvatarClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.functionalItemsColors.assetType.vehicle.value,
            color: theme.functionalItemsColors.assetType.vehicle.contrast,
        },
        avatarContainer: {
            position: 'relative',
        },
        badge: {
            bottom: 0,
            position: 'absolute',
            right: 0,
        },
    });
