import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification, ClassificationType } from '~/services/ApiClient';
import { formatClassifications } from '~/services/Formatters';

export interface ClassificationsFormatterProps {
    classificationKey: ClassificationType;
    t: SingleTFunction;
    value?: Classification[];
}

export interface ClassificationsFormatterInnerProps extends ClassificationsFormatterProps {}

const ClassificationsFormatter: React.FunctionComponent<ClassificationsFormatterInnerProps> = ({
    classificationKey,
    t,
    value,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatClassifications(t, classificationKey, value)}</>;
};
ClassificationsFormatter.displayName = 'ClassificationsFormatter';

export { ClassificationsFormatter };
