import MUIMyLocation from '@mui/icons-material/MyLocation';
import { styled } from '@mui/system';
import { MapContainer as _MapContainer } from 'react-leaflet';

import type { ContextMenuItem } from './models';

interface MapContainerProps {
    contextmenu?: boolean;
    contextmenuItems?: ContextMenuItem[];
    height?: number;
}

const MapContainer = styled(_MapContainer)<MapContainerProps>(({ height }) => ({
    height: height ?? '100%',
    width: '100%',
}));

const MyLocation = styled(MUIMyLocation)({ height: '0.75em' });

export { MapContainer, MyLocation };
