import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TextWorkflowFieldClassKey = 'centerAlignment' | 'leftAlignment' | 'rightAlignment';

export const TextWorkflowFieldStyleRules: StyleRulesCallback<{}, {}, TextWorkflowFieldClassKey> = () =>
    createStyles({
        centerAlignment: {
            textAlign: 'center',
        },
        leftAlignment: {
            textAlign: 'left',
        },
        rightAlignment: {
            textAlign: 'right',
        },
    });
