import { Paper as _Paper, Tab as _Tab, Tabs as _Tabs } from '@mui/material';
import { styled } from '@mui/styles';

const Paper = styled(_Paper)({
    zIndex: 0,
});

const TabContainer = styled(_Tabs)({
    flexContainer: { height: 56 },
});

const Tab = styled(_Tab)({
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '33%',
});

export { Paper, Tab, TabContainer };
