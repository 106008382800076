import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DeleteVehicleTypeDialogClassKeys = 'message' | 'messageContainer' | 'spinner';

export const useStyles = makeStyles<Theme, {}, DeleteVehicleTypeDialogClassKeys>((theme) => ({
    message: {
        fontStyle: 'italic',
        fontWeight: 'normal',
    },
    messageContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    spinner: {
        marginRight: theme.spacing(2),
    },
}));
