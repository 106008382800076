import type { ActionTypes, JwtDecodedToken } from '~/data/authentication';
import { ActionTypeKeys } from '~/data/authentication';
import { isUndefined } from '~/libs/utility';
import { AuthenticationResponse } from '~/services/ApiClient';

export interface JwtStoreState {
    decodedImpersonatorJwt?: JwtDecodedToken;
    decodedJwt?: JwtDecodedToken;
    impersonatorJwt?: string;
    isAuthenticated: boolean;
    jwt?: string;
    tokenExpired: boolean;
    verified: boolean;
}

const defaultStoreState: JwtStoreState = {
    isAuthenticated: false,
    tokenExpired: false,
    verified: false,
};

export const protectedComponentReducer = (
    state: JwtStoreState = defaultStoreState,
    action: ActionTypes
): JwtStoreState => {
    switch (action.type) {
        case ActionTypeKeys.AUTHENTICATION_FULFILLED: {
            const nextState: JwtStoreState = {
                ...state,
                decodedImpersonatorJwt: undefined,
                decodedJwt: undefined,
                impersonatorJwt: undefined,
                jwt: undefined,
                tokenExpired: false,
                verified: false,
            };

            if (action.payload instanceof AuthenticationResponse) {
                nextState.jwt = action.payload.token;
                nextState.impersonatorJwt = action.payload.impersonatorToken;
                nextState.isAuthenticated = true;
            }

            return nextState;
        }
        case ActionTypeKeys.LOGOUT_FULFILLED:
        case ActionTypeKeys.VERIFYTOKEN_REJECTED:
            return {
                ...state,
                decodedImpersonatorJwt: undefined,
                decodedJwt: undefined,
                impersonatorJwt: undefined,
                isAuthenticated: false,
                jwt: undefined,
                verified: false,
            };
        case ActionTypeKeys.VERIFYTOKEN_FULFILLED:
            return {
                ...state,
                decodedImpersonatorJwt: action.payload.decodedImpersonatorJwt,
                decodedJwt: action.payload.decodedJwt,
                tokenExpired: false,
                verified: true,
            };
        case ActionTypeKeys.IMPERSONATEUSER_FULFILLED: {
            const nextState: JwtStoreState = {
                ...state,
                decodedJwt: action.payload.decoded,
                jwt: action.payload.token,
            };

            if (isUndefined(state.impersonatorJwt)) {
                nextState.impersonatorJwt = state.jwt;
                nextState.decodedImpersonatorJwt = state.decodedJwt;
            }

            return nextState;
        }
        case ActionTypeKeys.TOKENEXPIRED:
            return {
                ...state,
                tokenExpired: true,
            };
        case ActionTypeKeys.IMPERSONATIONENDED_FULFILLED: {
            return {
                ...state,
                decodedImpersonatorJwt: undefined,
                decodedJwt: state.decodedImpersonatorJwt,
                impersonatorJwt: undefined,
                jwt: state.impersonatorJwt,
            };
        }
        default:
            return state;
    }
};

export function isAuthenticated({ decodedImpersonatorJwt, decodedJwt }: JwtStoreState): boolean {
    const currentTimestamp = Math.round(new Date().getTime() / 1000);

    return (
        !!decodedJwt &&
        currentTimestamp <= decodedJwt.exp &&
        (!decodedImpersonatorJwt || currentTimestamp <= decodedImpersonatorJwt.exp)
    );
}
