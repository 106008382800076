import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';

import type { SettingsGroupInnerProps } from './models';
import { useStyles } from './styles';

const SettingsGroup: FC<SettingsGroupInnerProps> = (props) => {
    const { actions, children, title } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.title} color="textPrimary" component="div" variant="subtitle1">
                    {title}
                </Typography>
                {actions && <div className={classes.actions}>{actions}</div>}
            </div>
            <Paper className={classes.content}>{children}</Paper>
        </div>
    );
};

SettingsGroup.displayName = 'SettingsGroup';
export default SettingsGroup;
