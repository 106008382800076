import type { SettingsStoreState } from '~/components/EnsureSettings';

import { ACTIONS } from './actionTypes';
import type { ActionTypes, GeozoneAdministrationRead, GeozonesAdministrationState } from './models';
import {
    resolveAdministrationGeozonesRead,
    resolveGeozonesCategories,
    resolvedAssignmentReferences,
    updateGeozonesStateItems,
} from './utils';

const initialState: GeozonesAdministrationState = {
    categories: [],
    error: false,
    items: [],
    pending: false,
};

const geozonesAdministrationReducer = (
    state = initialState,
    action: ActionTypes,
    settingsState: SettingsStoreState
): GeozonesAdministrationState => {
    switch (action.type) {
        case ACTIONS.LIST_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.LIST_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.LIST_FULFILLED: {
            return {
                ...state,
                categories: resolveGeozonesCategories(action.payload.items),
                error: false,
                items: resolveAdministrationGeozonesRead({
                    geozones: action.payload.items,
                    settingsState,
                }),
                pending: false,
            };
        }

        case ACTIONS.UPDATE_GEOZONES: {
            const items = updateGeozonesStateItems({
                current: state.items,
                settingsState,
                ...action.payload,
            });

            return {
                ...state,
                categories: resolveGeozonesCategories(items as unknown as GeozoneAdministrationRead[]),
                items,
            };
        }

        case ACTIONS.GET_GEOZONES_ASSIGNMENTS_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.GET_GEOZONES_ASSIGNMENTS_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.GET_GEOZONES_ASSIGNMENTS_FULFILLED:
            return {
                ...state,
                assignmentReferences: resolvedAssignmentReferences(action.payload.item),
                error: false,
                pending: false,
            };

        default:
            return state;
    }
};

export { geozonesAdministrationReducer };
