import type { StaticDataStoreState } from '~/common';

import { ACTIONS } from './actionTypes';
import type { ActionTypes, AssetGroupsAdminState } from './models';
import { resolveAssetGroupsAdmin } from './utils';

const initialState: AssetGroupsAdminState = {
    error: false,
    items: [],
    pending: false,
};

const assetGroupsAdminReducer = (
    state = initialState,
    action: ActionTypes,
    staticDataState: StaticDataStoreState
): AssetGroupsAdminState => {
    switch (action.type) {
        case ACTIONS.LIST_PENDING:
            return { ...state, pending: true };
        case ACTIONS.LIST_FULFILLED:
            return {
                error: false,
                items: resolveAssetGroupsAdmin({
                    drivers: staticDataState.drivers.data.hash,
                    items: action.payload,
                    trailers: staticDataState.trailers.data.hash,
                    vehicles: staticDataState.vehicles.data.hash,
                }),
                pending: false,
            };
        case ACTIONS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case ACTIONS.RESET_FULFILLED:
            return initialState;

        default:
            return state;
    }
};

export { assetGroupsAdminReducer };
