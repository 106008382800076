import makeStyles from '@mui/styles/makeStyles';

export type WidgetDialogClassKey =
    | 'customContentStyle'
    | 'dialog'
    | 'dialogActions'
    | 'dialogContent'
    | 'footer'
    | 'header'
    | 'headerAction'
    | 'headerActions'
    | 'leftFooterAdornment'
    | 'title';

const widgetDialogDimensions = {
    maxHeight: 700,
    width: 1008,
};

const useStyles = makeStyles((theme) => ({
    customContentStyle: { ...widgetDialogDimensions },
    dialog: { display: 'contents' },
    dialogActions: { padding: 0 },
    dialogContent: { display: 'flex', flexDirection: 'column', padding: 0 },
    footer: { display: 'flex', justifyContent: 'flex-end', margin: theme.spacing(2, 3) },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        height: 56,
        justifyContent: 'space-between',
        padding: `6px 12px 6px ${theme.spacing(3)}`,
        position: 'relative',
    },
    headerAction: { margin: '0 4px' },
    headerActions: { alignSelf: 'center', display: 'flex' },
    leftFooterAdornment: { alignSelf: 'center', color: theme.palette.text.secondary, flexGrow: 1 },
    title: { alignSelf: 'center', flexGrow: 1 },
}));

export { useStyles, widgetDialogDimensions };
