import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SendMessageFooterClassKey = 'collapseIcon' | 'summary';

export const SendMessageFooterStyleRules: StyleRulesCallback<Theme, {}, SendMessageFooterClassKey> = (theme: Theme) =>
    createStyles({
        collapseIcon: {
            cursor: 'pointer',
        },
        summary: {
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
            display: 'flex',
            justifyContent: 'flex-end',
        },
    });
