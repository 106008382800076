import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type FilterOptionsCellClassKey =
    | 'checkBox'
    | 'endAdornment'
    | 'hasPopupIcon'
    | 'input'
    | 'inputRoot'
    | 'popper'
    | 'renderOptionMargin';

export const FilterOptionsCellStyleRules: StyleRulesCallback<Theme, {}, FilterOptionsCellClassKey> = (theme: Theme) =>
    createStyles({
        checkBox: { paddingLeft: 0 },
        endAdornment: { background: theme.palette.background.paper },
        hasPopupIcon: { minWidth: '100%', overflow: 'hidden' },
        input: { fontSize: 14 },
        inputRoot: {
            margin: theme.spacing(),
            paddingRight: '0',
            textOverflow: 'ellipsis',
        },
        popper: { inset: '50px auto !important', minWidth: 'max-content', transform: 'initial !important' },
        renderOptionMargin: { marginRight: theme.spacing() },
    });
