import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { Box as _Box, Button as _Button, Popover as _Popover, Typography as _Typography, styled } from '@mui/material';
import { DateRange } from 'react-date-range';

const StyledSpan = styled('span')(({ theme }) => ({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1),
    whiteSpace: 'nowrap',
}));

const StyledPopover = styled(_Popover)({ display: 'flex', flexDirection: 'column' });

const StyledDateRange = styled(DateRange)(({ theme }) => ({
    '& .rdrDayToday .rdrDayNumber span:after': {
        background: theme.palette.secondary.main,
    },
}));

const StyledTimePickers = styled(_Box)(({ theme }) => ({
    alignItems: 'baseline',
    display: 'flex',
    marginRight: theme.spacing(8),
}));

const StyledButton = styled(_Button)({
    height: '36px',
});

const StyledTypography = styled(_Typography)(({ theme }) => ({
    margin: theme.spacing(0, 1),
}));

const TimePickerContainer = styled(_Box)(({ theme }) => ({
    display: 'flex',
    height: '60px',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 1.5, 0, 1.5),
}));

const StyledIconCalendar = styled(CalendarToday)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledIconRow = styled(ArrowDropDownIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export {
    StyledButton,
    StyledDateRange,
    StyledIconCalendar,
    StyledIconRow,
    StyledPopover,
    StyledSpan,
    StyledTimePickers,
    StyledTypography,
    TimePickerContainer,
};
