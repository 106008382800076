import _ChevronRight from '@mui/icons-material/ChevronRight';
import _ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import _Switch from '@mui/material/Switch';
import _Typography from '@mui/material/Typography';

interface ContainerProps {
    isLeaf: boolean;
    paddingLeft?: number;
}

const Container = styled('div')<ContainerProps>(({ isLeaf, paddingLeft, theme }) => ({
    paddingLeft: !paddingLeft ? theme.spacing() : isLeaf ? theme.spacing(7.25) : theme.spacing(4.25),
    paddingTop: !paddingLeft ? '0px' : isLeaf ? '0px' : theme.spacing(0.5),
}));

const ActionContainer = styled('div')({ alignItems: 'center', display: 'flex' });
const ExpandMore = styled(_ChevronRight)({ ':hover': { backgroundColor: 'whitespace', cursor: 'pointer' } });
const ExpandLess = styled(_ExpandMore)({ ':hover': { backgroundColor: 'whitespace', cursor: 'pointer' } });
const Switch = styled(_Switch)({ marginLeft: 'auto', marginRight: 0 });
const Typography = styled(_Typography)({ paddingBottom: '2px', paddingLeft: '10px', whiteSpace: 'nowrap' });

export { ActionContainer, Container, ExpandLess, ExpandMore, Switch, Typography };
