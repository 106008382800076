import * as React from 'react';

import { PercentageFormatter, VoltageFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedReeferStatus } from '~/services/ApiClient';
import { ReeferPowerModeKeys } from '~/services/ApiClient';

export interface ReeferValueProps {
    reefer: ResolvedReeferStatus;
}

export interface ReeferValueInnerProps extends InjectedTranslationProps, ReeferValueProps {}

export const ReeferValueComponent: React.FunctionComponent<ReeferValueInnerProps> = ({
    reefer,
    t,
}: ReeferValueInnerProps) => {
    const notAvailable = <>{t('not-available')}</>;
    if (reefer.powerMode?.key === ReeferPowerModeKeys.DIESEL) {
        return reefer.fuelLevel ? <PercentageFormatter precision={0} value={reefer.fuelLevel} /> : notAvailable;
    } else if (reefer.powerMode?.key === ReeferPowerModeKeys.ELECTRIC) {
        return reefer.batteryVoltage ? (
            <VoltageFormatter precision={1} t={t} value={reefer.batteryVoltage} />
        ) : (
            notAvailable
        );
    }
    return notAvailable;
};
