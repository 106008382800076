import type { DriverEventsPayload } from '~/data/driverevents';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    DRIVERACTIVITIESREPORT_DRIVEREVENTS = 'DRIVERACTIVITIESREPORT_DRIVEREVENTS',
}

export enum ActionTypeKeys {
    DRIVERACTIVITIESREPORT_DRIVEREVENTS_FULFILLED = 'DRIVERACTIVITIESREPORT_DRIVEREVENTS_FULFILLED',
    DRIVERACTIVITIESREPORT_DRIVEREVENTS_PENDING = 'DRIVERACTIVITIESREPORT_DRIVEREVENTS_PENDING',
    DRIVERACTIVITIESREPORT_DRIVEREVENTS_REJECTED = 'DRIVERACTIVITIESREPORT_DRIVEREVENTS_REJECTED',
}

export type ActionTypes =
    | Fulfilled<ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_FULFILLED, DriverEventsPayload>
    | Pending<ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_PENDING>
    | Rejected<ActionTypeKeys.DRIVERACTIVITIESREPORT_DRIVEREVENTS_REJECTED>;
