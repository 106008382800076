import * as React from 'react';
import { useSelector } from 'react-redux';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ContainerIcon, DriverIcon, TrailerIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneAssetSelectorProps } from '~/components/SceneAssetSelector';
import { SceneAssetSelector } from '~/components/SceneAssetSelector';
import type { AssetDataSet } from '~/components/SceneAssetSelector/models';
import { filterAssetGroups } from '~/components/SceneAssetSelector/services';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type { AssetGroup } from '~/services/ApiClient';
import { AssetReference, AssetType, createApiModel } from '~/services/ApiClient';
import { formatContainerName, formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

export type AssetSelectorProps = Omit<SceneAssetSelectorProps, 'assetDataSets' | 'assetGroups' | 'depots'>;

export interface AssetSelectorComponentProps extends AssetSelectorProps {
    assetTypes: AssetType[];
}

export interface AssetSelectorInnerProps
    extends AssetSelectorComponentProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const AssetSelectorComponent: React.FC<AssetSelectorInnerProps> = (props) => {
    const {
        assetTypes,
        className,
        displayPreferences,
        onAssetContextMenu,
        onSelectedAssetIdsChange,
        preferencesKey,
        selectedAssetIds,
        singleSelectionMode,
        t,
    } = props;

    const staticDataState = useSelector((store: StoreState) => staticDataStoreStateSelector(store));

    const predicates = assetTypes.map((assetType: AssetType) => {
        switch (assetType) {
            case AssetType.Container:
                return (_g: AssetGroup) => true;

            case AssetType.Driver:
                return (g: AssetGroup) => g.allowDrivers || false;

            case AssetType.Trailer:
                return (g: AssetGroup) => g.allowTrailers;

            case AssetType.Vehicle:
                return (g: AssetGroup) => g.allowVehicles;

            default:
                throw Error(`Provided asset type ${assetType} is not supported.`);
        }
    });

    const predicate = React.useMemo(
        () => (predicates.length === 1 ? predicates[0] : (g: AssetGroup) => !!predicates.find((p) => p(g))),
        [predicates]
    );

    const assetGroups = React.useMemo(
        () => filterAssetGroups(staticDataState.assetGroups.data, predicate),
        [predicate, staticDataState.assetGroups.data]
    );

    const assetDataSets = React.useMemo(
        () =>
            assetTypes.map((assetType: AssetType) => {
                switch (assetType) {
                    case AssetType.Vehicle: {
                        const vehiclesDataSet: AssetDataSet = {
                            assets: staticDataState.vehicles.data.array.map((v) => ({
                                displayName: formatVehicleName(v, displayPreferences.vehicleDisplayFormat),
                                reference: createApiModel(AssetReference, { id: v.id, type: AssetType.Vehicle }),
                            })),
                            displayName: t('vehicles'),
                            icon: <TruckIcon />,
                            type: AssetType.Vehicle,
                        };
                        return vehiclesDataSet;
                    }

                    case AssetType.Trailer: {
                        const trailersDataSet: AssetDataSet = {
                            assets: staticDataState.trailers.data.array.map((trailer) => ({
                                displayName: formatTrailerName(trailer, displayPreferences.trailerDisplayFormat),
                                reference: createApiModel(AssetReference, { id: trailer.id, type: AssetType.Trailer }),
                            })),
                            displayName: t('trailers'),
                            icon: <TrailerIcon />,
                            type: AssetType.Trailer,
                        };
                        return trailersDataSet;
                    }

                    case AssetType.Container: {
                        const containersDataSet: AssetDataSet = {
                            assets: staticDataState.containers.data.array.map((container) => ({
                                displayName: formatContainerName(container),
                                reference: createApiModel(AssetReference, {
                                    id: container.id,
                                    type: AssetType.Container,
                                }),
                            })),
                            displayName: t('containers'),
                            icon: <ContainerIcon />,
                            type: AssetType.Container,
                        };
                        return containersDataSet;
                    }

                    case AssetType.Driver: {
                        const driversDataSet: AssetDataSet = {
                            assets: staticDataState.drivers.data.array.map((driver) => ({
                                displayName: formatDriverName(driver, displayPreferences.driverDisplayFormat),
                                reference: createApiModel(AssetReference, { id: driver.id, type: AssetType.Driver }),
                            })),
                            displayName: t('drivers'),
                            icon: <DriverIcon />,
                            type: AssetType.Driver,
                        };
                        return driversDataSet;
                    }

                    default:
                        throw Error(`Provided asset type ${assetType} is not supported.`);
                }
            }),
        [
            assetTypes,
            displayPreferences.driverDisplayFormat,
            displayPreferences.trailerDisplayFormat,
            displayPreferences.vehicleDisplayFormat,
            staticDataState.containers.data.array,
            staticDataState.drivers.data.array,
            staticDataState.trailers.data.array,
            staticDataState.vehicles.data.array,
            t,
        ]
    );

    return (
        <SceneAssetSelector
            assetDataSets={assetDataSets}
            assetGroups={assetGroups}
            className={className}
            depots={staticDataState.depots.data.array}
            key={preferencesKey}
            onAssetContextMenu={onAssetContextMenu}
            onSelectedAssetIdsChange={onSelectedAssetIdsChange}
            preferencesKey={preferencesKey}
            selectedAssetIds={selectedAssetIds}
            singleSelectionMode={singleSelectionMode}
        />
    );
};
