import { memo } from 'react';

import { isEqual } from '../utility';

import { Footer, Header, List } from './components';
import { AssetSelectorProvider } from './context';
import type { AssetSelectorProps, BaseItem } from './models';
import { Container } from './styles';

const AssetSelector = <T extends BaseItem<T>>(props: AssetSelectorProps<T>) => {
    const { width, ...rest } = props;

    return (
        <AssetSelectorProvider {...rest}>
            <Container data-testid="asset-selector-container" width={width}>
                <Header />
                <List />
                <Footer />
            </Container>
        </AssetSelectorProvider>
    );
};

AssetSelector.displayName = 'AssetSelector';
const MemoAssetSelector = memo(AssetSelector, (prev, next) =>
    isEqual(
        {
            checkedKeys: prev.checkedKeys,
            expandedKeys: prev.expandedKeys,
            items: prev.items,
            selectedRow: prev.selectedRow,
        },
        {
            checkedKeys: next.checkedKeys,
            expandedKeys: next.expandedKeys,
            items: next.items,
            selectedRow: next.selectedRow,
        }
    )
);
export { MemoAssetSelector as AssetSelector };
