import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ScheduleEditorFormClassKey =
    | 'body'
    | 'checkboxContainer'
    | 'element'
    | 'elementEnumerationRecurrence'
    | 'elementNumericRecurrence'
    | 'elementRecurrenceMonthlyRadioButtonText'
    | 'elementRecurrenceMonthlyText'
    | 'itemCheckbox'
    | 'lastActivities'
    | 'radioButtonsMarginBottom'
    | 'radioFieldContainer'
    | 'recipientsListPadding'
    | 'recurrenceDailyWeeklyContainer'
    | 'recurrenceItem'
    | 'recurrenceMonthlyContainer'
    | 'recurrenceMonthlyFieldsContainer'
    | 'root';
export const ScheduleEditorFormStyleRules: StyleRulesCallback<Theme, {}, ScheduleEditorFormClassKey> = (theme: Theme) =>
    createStyles({
        body: {
            backgroundColor: theme.palette.common.white,
            marginRight: theme.spacing(35),
            marginTop: theme.spacing(4),
            transform: 'translateX(+124px)',
            width: 750,
        },
        checkboxContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        element: {
            marginBottom: theme.spacing(3),
            width: 750,
        },
        elementEnumerationRecurrence: { marginTop: theme.spacing(-1), width: 120 },
        elementNumericRecurrence: {
            marginTop: theme.spacing(-1),
            width: 70,
        },
        elementRecurrenceMonthlyRadioButtonText: {
            width: 60,
        },
        elementRecurrenceMonthlyText: {
            display: 'inline-block',
            marginBottom: theme.spacing(3),
            marginRight: theme.spacing(2),
            verticalAlign: 'middle',
            width: 70,
        },
        itemCheckbox: { flex: '1 0 50%' },
        lastActivities: {
            marginLeft: theme.spacing(-10),
            width: 140,
        },
        radioButtonsMarginBottom: { marginBottom: theme.spacing(1) },
        radioFieldContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        recipientsListPadding: {
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
            fontStyle: 'italic',
            opacity: 1,
            padding: theme.spacing(1, 3),
        },
        recurrenceDailyWeeklyContainer: { display: 'flex', marginBottom: theme.spacing(3) },
        recurrenceItem: { marginRight: theme.spacing() },
        recurrenceMonthlyContainer: { display: 'flex', marginBottom: theme.spacing(1) },
        recurrenceMonthlyFieldsContainer: {
            display: 'flex',
            marginBottom: theme.spacing(2.5),
            marginLeft: theme.spacing(-5.5),
        },
        root: {
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            fontSize: theme.typography.fontSize,
            height: '100%',
        },
    });
