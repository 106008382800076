import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 60,
        padding: theme.spacing(),
        position: 'relative',
    },
    title: {
        flexGrow: 1,
        padding: theme.spacing(0, 1),
    },
}));
