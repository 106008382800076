import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViolationFieldFormatterFactory } from '~/components/Formatters';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import { formatDriverSubpageListItemLabel } from '~/services/Formatters';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';
import { commonDurationFormatter } from '../utils';

const RestSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const { reducedDailyRestCount, splitDailyRest, totalWeeklyRestCompensation, weeklyRestCompensation } =
        props.driverStatus;
    const { t } = useTranslation();

    const ViolationNumberFormatter = useMemo(() => ViolationFieldFormatterFactory((v: number) => v.toString()), []);

    return (
        <ReorderableSection
            dataId={SectionName.REST}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            title={t(`driver-subpage-${SectionName.REST}`)}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList
                list={[
                    {
                        label: formatDriverSubpageListItemLabel(t, 'rest-first-split-daily-rest'),
                        value: commonDurationFormatter(splitDailyRest),
                        valueDataId: 'first-split-daily-rest',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'rest-reduced-daily-rest-count'),
                        value: !isUndefined(reducedDailyRestCount) ? (
                            <ViolationNumberFormatter
                                fieldName={formatDriverSubpageListItemLabel(t, 'rest-reduced-daily-rest-count')}
                                t={t}
                                value={reducedDailyRestCount}
                            />
                        ) : undefined,
                        valueDataId: 'reduced-daily-rest-count',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'rest-weekly-rest-compensation'),
                        value: commonDurationFormatter(weeklyRestCompensation),
                        valueDataId: 'weekly-rest-compensation',
                    },
                    {
                        label: formatDriverSubpageListItemLabel(t, 'rest-total-weekly-rest-compensation'),
                        value: commonDurationFormatter(totalWeeklyRestCompensation),
                        valueDataId: 'total-weekly-rest-compensation',
                    },
                ]}
                listDataId="property-list"
            />
        </ReorderableSection>
    );
};

RestSection.displayName = 'RestSection';
export default RestSection;
