import { TextField, Tooltip } from '@mui/material';
import type { ChangeEvent, FC, FocusEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { BaseProps } from '../model';

import * as utility from './utility';

export interface DurationInputProps extends BaseProps<string | undefined> {
    autoFocus?: boolean;
    requiredText?: string;
}

export interface DurationInputInnerProps extends DurationInputProps, InjectedTranslationProps {}

export const DurationInputComponent: FC<DurationInputInnerProps> = ({
    autoFocus,
    dataId,
    defaultValue,
    disabled,
    disabledReason,
    errorText,
    hasError,
    label,
    onBlurChanged,
    readonly,
    required,
    requiredText,
    t,
}) => {
    const dayToken = useMemo(() => t('unit-day'), [t]);
    const requiredMessage = requiredText ?? t('wf-field-error-required');
    const helperText = hasError && !isUndefined(errorText) ? errorText : required ? requiredMessage : undefined;
    const [inputValue, setInputValue] = useState<string>();
    const notifyChange = useCallback(
        (value: string, callbackFn?: (v: string | undefined) => void) => {
            if (!callbackFn) {
                return;
            }
            const parsedDurationString = utility.formatsToGenericFormatString(value, dayToken, required);
            if (parsedDurationString === undefined /*! parsedDurationValue.isValid() */) {
                if (!required && value === '') {
                    callbackFn(undefined);
                } else {
                    callbackFn(utility.invalidDurationValue); // invalid duration value
                }
            } else {
                setInputValue(parsedDurationString);
                const parsedDurationValue = utility.formatsToGenericFormat(parsedDurationString, dayToken);
                if (!parsedDurationValue) {
                    callbackFn(undefined); // empty duration value
                } else {
                    callbackFn(parsedDurationValue.toISOString()); // pass ISO string value
                }
            }
        },
        [dayToken, required]
    );

    const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.currentTarget.value);
    }, []);

    const onBlurHandler = useCallback(
        (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            notifyChange(event.currentTarget.value, onBlurChanged);
        },
        [onBlurChanged, notifyChange]
    );

    const baseComponent = (
        <TextField
            autoFocus={autoFocus}
            data-id={dataId}
            defaultValue={utility.convertFromISOValue(defaultValue, dayToken)}
            disabled={disabled}
            error={hasError}
            fullWidth
            helperText={helperText}
            InputProps={{
                readOnly: readonly,
            }}
            label={label}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            required={required}
            size="small"
            value={inputValue}
            variant="outlined"
        />
    );

    return disabledReason && disabled ? (
        <Tooltip data-id={`input-tooltip-${dataId}`} placement="right" title={disabledReason}>
            <span>{baseComponent}</span>
        </Tooltip>
    ) : (
        baseComponent
    );
};
