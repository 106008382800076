import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ExclamationIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H24V24H0Z" fill="none" />
            <path d="M 11 4L 13 4L 13 15L 11 15L 11 4 Z M 13 18L 13 20L 11 20L 11 18L 13 18 Z" />
        </SvgIcon>
    );
};
ExclamationIcon.displayName = 'ExclamationIcon';

export { ExclamationIcon };
