import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatVoltage } from '~/services/Formatters';

export interface VoltageFormatterProps {
    precision?: number;
    t: SingleTFunction;
    value?: number;
}

export interface VoltageFormatterInnerProps extends VoltageFormatterProps {}

const VoltageFormatter: React.FunctionComponent<VoltageFormatterInnerProps> = ({ precision, t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatVoltage({ precision, t, value })}</>;
};
VoltageFormatter.displayName = 'VoltageFormatter';

export { VoltageFormatter };
